import { Button, Form, Select } from 'antd';
import FormActions from './components/FormActions';

export default function InternalLinkForm({
  currentEvent,
  setCurrentEvent,
  qoos,
  blockEventHandler,
  eventIndex
}) {
  const initialValues = {
    internalLinkToQoo:
      typeof currentEvent?.internalLinkToQoo === 'object'
        ? currentEvent?.internalLinkToQoo.title
        : currentEvent?.internalLinkToQoo
  };

  return (
    <Form onFinish={blockEventHandler} initialValues={initialValues}>
      <Form.Item
        className='block-event__form-item'
        name='internalLinkToQoo'
        rules={[
          {
            required: true,
            message: 'Please select a Qoo!'
          }
        ]}>
        <Select
          className='block-event__element'
          placeholder='Select Qoo...'
          value={
            typeof currentEvent?.internalLinkToQoo === 'object'
              ? currentEvent?.internalLinkToQoo.title
              : currentEvent?.internalLinkToQoo
          }
          onChange={(value) => {
            setCurrentEvent({
              ...currentEvent,
              internalLinkToQoo: value
            });
          }}>
          {qoos?.map((qoo) => (
            <Select.Option key={qoo?._id} value={qoo?._id}>
              {qoo?.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <FormActions eventIndex={eventIndex} setCurrentEvent={setCurrentEvent} />
    </Form>
  );
}
