import { CloseOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { ChromePicker } from 'react-color';
import ColorPickerDropdown from '../../components/elements/ColorPickerDropdown';
import { SET_COLOR__PALETTE, EDIT_COLOR_PALETTE_ARRAY, REMOVE_COLOR_FROM_PALETTE, ASSIGN_COLOR } from '../../reducers/theme-in-preview/actionTypes';

const ColorsTab = ({ themeInPreview, dispatch, isNew, presetSelected, setPresetSelected }) => {
  const initColorAssignment = {
    qooBackground: null,
    blockBackground: null,
    buttonColor: null,
    buttonBorderColor: null,
    buttonTextColor: null,
    fontColor: null
  };

  const [selectedField, setSelectedField] = useState(null);

  useEffect(() => {
    if (presetSelected) {
      setSelectedField(null);
      setPresetSelected(false);
    }
  }, [presetSelected]);

  return (
    <div className='colors-tab'>
      <div className='colors-tab__color-picking'>
        <h3 className='pre-title pre-title--dark'>Color palette</h3>
        <div className="colors-tab__color-palette">
          {themeInPreview && themeInPreview.colorPalette.colors && themeInPreview.colorPalette.colors.length > 0 && themeInPreview.colorPalette.colors.map((color, index) => {
            return (
              <div
                className="colors-tab__color-palette-field-container"
                key={`${color}-${index}`}
                style={{
                  border: (selectedField || selectedField === 0) && selectedField === index ? '2px solid #4962E2' : ''
                }}
              >
                <div
                  className="colors-tab__color-palette-field"
                  style={{
                    backgroundColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
                    border: (selectedField || selectedField === 0) && selectedField === index ? '2px solid #FFF' : '1px solid #BDC7CF',
                  }}
                  onClick={() => setSelectedField(index)}
                >

                </div>
                {(selectedField || selectedField === 0) && selectedField === index &&
                  <div
                    className="colors-tab__delete-color-btn"
                    onClick={() => {
                      dispatch({
                        type: REMOVE_COLOR_FROM_PALETTE,
                        payload: index
                      });
                      setSelectedField(null);
                    }}
                  >
                    <CloseOutlined
                      style={{ fontSize: '10px', color: '#2A3A49' }}
                    />
                  </div>
                }
              </div>
            );
          })}

          {themeInPreview && themeInPreview.colorPalette.colors && themeInPreview.colorPalette.colors.length < 4 &&
            <div
              className="colors-tab__color-palette-field"
              style={{
                border: (selectedField || selectedField === 0) && selectedField === themeInPreview.colorPalette.colors.length ? '2px solid #4962E2' : '1px dashed #D5DFE8',
                cursor: 'pointer'
              }}
              onClick={() => setSelectedField(themeInPreview.colorPalette.colors.length)}
            >
              <span>+</span>
            </div>
          }
        </div>
        {(selectedField || selectedField === 0) &&
          <div className='colors-tab__color-picker'>
            <ChromePicker
              presetColors={[]}
              width={260}
              color={themeInPreview.colorPalette.colors[selectedField] ? themeInPreview.colorPalette.colors[selectedField].rgb : 'rgba(255,255,255, 1)'}
              onChange={(color) => {
                dispatch({
                  type: EDIT_COLOR_PALETTE_ARRAY,
                  payload: {
                    index: selectedField,
                    color: {
                      hex: color.hex,
                      rgb: color.rgb
                    }
                  }
                });
              }}
            />
          </div>
        }
      </div>
      {!isNew &&
        <>
          <div className={isNew ? "colors-tab__assignment" : "colors-tab__assignment colors-tab__assignment--editor"}>
            <div className="colors-tab__assignment-row">
              <span className="colors-tab__row-title">Qoo Background</span>
              <ColorPickerDropdown
                colors={themeInPreview.colorPalette.colors}
                colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
                selectedColor={themeInPreview.colorAssignment.qooBackground}
                leftRadius
                rightRadius
                callback={(color) => {
                  dispatch({
                    type: ASSIGN_COLOR,
                    payload: {
                      updates: {
                        qooBackground: color
                      }
                    }
                  });
                }}
              />
            </div>
            <div className="colors-tab__assignment-row">
              <span className="colors-tab__row-title">Button Color</span>
              <ColorPickerDropdown
                colors={themeInPreview.colorPalette.colors}
                colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
                selectedColor={themeInPreview.colorAssignment.buttonColor}
                leftRadius
                callback={(color) => {
                  dispatch({
                    type: ASSIGN_COLOR,
                    payload: {
                      updates: {
                        buttonColor: color
                      }
                    }
                  });
                }}
              />
            </div>
            <div className="colors-tab__assignment-row">
              <span className="colors-tab__row-title">Button Text Color</span>
              <ColorPickerDropdown
                colors={themeInPreview.colorPalette.colors}
                colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
                selectedColor={themeInPreview.colorAssignment.buttonTextColor}
                leftRadius
                rightRadius
                callback={(color) => {
                  dispatch({
                    type: ASSIGN_COLOR,
                    payload: {
                      updates: {
                        buttonTextColor: color
                      }
                    }
                  });
                }}
              />
            </div>
            <div className="colors-tab__assignment-row">
              <span className="colors-tab__row-title">Font Color</span>
              <ColorPickerDropdown
                colors={themeInPreview.colorPalette.colors}
                colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
                selectedColor={themeInPreview.colorAssignment.fontColor}
                leftRadius
                rightRadius
                callback={(color) => {
                  dispatch({
                    type: ASSIGN_COLOR,
                    payload: {
                      updates: {
                        fontColor: color
                      }
                    }
                  });
                }}
              />
            </div>
          </div>
        </>
      }


    </div>
  );
};

export default ColorsTab;