import React, { useState } from 'react';
import { SERVER_URL } from '../../config';
import { notification, Input } from 'antd';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import Axios from 'axios';
import MyImagesGrid from '../../components/grids/MyImagesGrid';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';

const MyImages = ({ images, user, setStateForRefetchingImages }) => {
  const [searchFilter, setSearchFilter] = useState('');

  const tableData = images.data.filter((image) => {
    const textMatch = searchFilter
      ? image?.originalname?.toLowerCase().includes(searchFilter.toLowerCase())
      : true;
    return textMatch;
  });

  const deleteDataHandler = async (id) => {
    const selectedImage = { images: [id] };
    try {
      await Axios.post(`${SERVER_URL}/images-delete`, selectedImage, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.data.token}` }
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      setStateForRefetchingImages((prev) => prev + 1);
    } catch (err) {
      console.log('err: ', err);
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  return (
    <>
      <div className="table-header">
        <Input
          className="table-header__search"
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
          placeholder="Search Images"
          prefix={<SearchIcon />}
        />
      </div>
      <MyImagesGrid data={tableData} deleteHandler={deleteDataHandler} user={user} />
    </>
  );
};

export default MyImages;
