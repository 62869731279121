import React from 'react';
import { Select, Input, Checkbox } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import { UPDATE_HEADERFOOTER_PROPERTY } from '../../reducers/theme-in-preview/actionTypes';
import ColorPickerDropdown from '../../components/elements/ColorPickerDropdown';
import { ReactComponent as AlignLeft } from '../../icons/align-text-left.svg';
import { ReactComponent as AlignCenter } from '../../icons/align-text-center.svg';
import { ReactComponent as AlignRight } from '../../icons/align-text-right.svg';
import { ISIIcons } from '../../icons/ISIIcons';

const ISITab = ({ themeInPreview, dispatch }) => {

  let tinyMCEContentStyleString = '';
  themeInPreview.selectedFonts.forEach(
    (font) =>
    (tinyMCEContentStyleString += `
      @font-face {
        font-family: ${font.name};
        src: url(${font.importUrl});
        font-weight: ${font.weight};
        font-style: ${font.style};
      } 
    `),
  );
  tinyMCEContentStyleString += 'body { color:#2A3A49; font-size: 1em;}';
  tinyMCEContentStyleString += 'p {margin: 1em 0;} p:first-child {margin: 0;}';

  let tinyMCEFontFormatsString = '';
  themeInPreview.selectedFonts.forEach(
    (font) => (tinyMCEFontFormatsString += `${font.name}=${font.name.toLowerCase().replace(' -', '')}; `),
  );

  return (
    <>
      <div className='isi-page-correction'>
        <div className="new-theme-builder__hf-section">
          <p className="new-theme-builder__intro-text">
            Please style your Info Spotlight Title:
          </p>

          <div className="new-theme-builder__hf-style-row">
            <div className="new-theme-builder__hf-style-label new-theme-builder__hf-style-label--fl-start">
              <span className="new-theme-builder__small-text">Title Background</span>
            </div>
            <div className="new-theme-builder__hf-toolbar">
              <ColorPickerDropdown
                colors={themeInPreview.colorPalette.colors}
                colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
                selectedColor={themeInPreview.headerFooter.footer.ISITitleBackgroundColor}
                disabled={themeInPreview.headerFooter.footer.noISI}
                leftRadius
                rightRadius
                callback={(color) => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'footer',
                      updates: {
                        ISITitleBackgroundColor: color
                      }
                    }
                  });
                }}
              />
            </div>
          </div>
          <div className="new-theme-builder__hf-style-row">
            <div className="new-theme-builder__hf-style-label new-theme-builder__hf-style-label--fl-start">
              <span className="new-theme-builder__small-text">Title Text</span>
            </div>
            <div className="new-theme-builder__hf-toolbar">
              <Select
                className='dropdown-small new-theme-builder__hf-footer-font-select'
                placeholder='Choose font'
                disabled={themeInPreview.headerFooter.footer.noISI}
                value={themeInPreview.headerFooter.footer.ISITitleFont?._id}
                onChange={(value) => {
                  const chosenFont = themeInPreview.selectedFonts.find(font => font._id === value);
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'footer',
                      updates: {
                        ISITitleFont: chosenFont
                      }
                    }
                  });
                }}
              >
                {themeInPreview.selectedFonts.length > 0 && themeInPreview.selectedFonts.map((font) => {
                  return (
                    <Select.Option
                      key={font._id}
                      value={font._id}
                    >
                      <div
                        style={{
                          fontFamily: font.name,
                          fontWeight: font.weight,
                          fontStyle: font.style,
                        }}
                      >
                        {font.name}
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
              <Select
                className='dropdown-small new-theme-builder__hf-menu-font-size-select'
                placeholder='Size'
                disabled={themeInPreview.headerFooter.footer.noISI}
                value={themeInPreview.headerFooter.footer.ISITitleFontSize}
                onChange={(value) => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'footer',
                      updates: {
                        ISITitleFontSize: value
                      }
                    }
                  });
                }}
              >
                <Select.Option value='S'>Small</Select.Option>
                <Select.Option value='M'>Medium</Select.Option>
                <Select.Option value='L'>Large</Select.Option>
              </Select>
              <ColorPickerDropdown
                colors={themeInPreview.colorPalette.colors}
                colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
                selectedColor={themeInPreview.headerFooter.footer.ISITitleTextColor}
                disabled={themeInPreview.headerFooter.footer.noISI}
                leftRadius
                rightRadius
                callback={(color) => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'footer',
                      updates: {
                        ISITitleTextColor: color
                      }
                    }
                  });
                }}
              />
              {!themeInPreview.headerFooter.footer.noISI &&
                <div className='h-f-create-new__toolbar-group'>
                  <div
                    className={
                      themeInPreview.headerFooter.footer.ISITitleTextAlign === 'left'
                        ? 'h-f-create-new__text-align-btn h-f-create-new__text-align-btn--selected'
                        : 'h-f-create-new__text-align-btn'
                    }
                    onClick={() => {
                      dispatch({
                        type: UPDATE_HEADERFOOTER_PROPERTY,
                        payload: {
                          section: 'footer',
                          updates: {
                            ISITitleTextAlign: 'left'
                          }
                        }
                      });
                    }}
                  >
                    <AlignLeft />
                  </div>
                  <div
                    className={
                      themeInPreview.headerFooter.footer.ISITitleTextAlign === 'center'
                        ? 'h-f-create-new__text-align-btn h-f-create-new__text-align-btn--selected'
                        : 'h-f-create-new__text-align-btn'
                    }
                    onClick={() => {
                      dispatch({
                        type: UPDATE_HEADERFOOTER_PROPERTY,
                        payload: {
                          section: 'footer',
                          updates: {
                            ISITitleTextAlign: 'center'
                          }
                        }
                      });
                    }}
                  >
                    <AlignCenter />
                  </div>
                  <div
                    className={
                      themeInPreview.headerFooter.footer.ISITitleTextAlign === 'right'
                        ? 'h-f-create-new__text-align-btn h-f-create-new__text-align-btn--selected'
                        : 'h-f-create-new__text-align-btn'
                    }
                    onClick={() => {
                      dispatch({
                        type: UPDATE_HEADERFOOTER_PROPERTY,
                        payload: {
                          section: 'footer',
                          updates: {
                            ISITitleTextAlign: 'right'
                          }
                        }
                      });
                    }}
                  >
                    <AlignRight />
                  </div>
                </div>
              }

            </div>
          </div>
          <div className="new-theme-builder__hf-style-row">
            <div className="new-theme-builder__hf-style-label new-theme-builder__hf-style-label--fl-start">
            </div>
            <div className="new-theme-builder__hf-toolbar">
              <Input
                className='new-theme-builder__hf-isititle-input'
                placeholder='Enter title here'
                disabled={themeInPreview.headerFooter.footer.noISI}
                value={themeInPreview.headerFooter.footer.ISITitleTextContent}
                onChange={(e) => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'footer',
                      updates: {
                        ISITitleTextContent: e.target.value,
                      }
                    }
                  });
                }}
              />
            </div>
          </div>
          <div className="new-theme-builder__hf-style-row">
            <div className="new-theme-builder__hf-style-label new-theme-builder__hf-style-label--fl-start">
              <span className="new-theme-builder__small-text">Icons</span>
            </div>
            <div className="new-theme-builder__hf-toolbar">
              <Select
                className='dropdown-small new-theme-builder__hf-isiicon-select'
                placeholder='Choose icon'
                disabled={themeInPreview.headerFooter.footer.noISI}
                value={themeInPreview.headerFooter.footer.ISIIcon}
                onChange={(value) => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'footer',
                      updates: {
                        ISIIcon: value
                      }
                    }
                  });
                }}
              >
                {ISIIcons.map((icon, index) => {
                  return (
                    <Select.Option
                      key={index}
                      value={index}
                    >
                      <div
                        style={{
                          height: '32px',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {icon.open}
                        {icon.close}
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
        <div className="new-theme-builder__hf-section">
          <p className="new-theme-builder__intro-text">
            Please style your Info Spotlight:
          </p>
          <div className="new-theme-builder__hf-style-row">
            <div className="new-theme-builder__hf-style-label new-theme-builder__hf-style-label--fl-start">
              <span className="new-theme-builder__small-text">Background</span>
            </div>
            <div className="new-theme-builder__hf-toolbar">
              <ColorPickerDropdown
                colors={themeInPreview.colorPalette.colors}
                colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
                selectedColor={themeInPreview.headerFooter.footer.ISIFooterBackgroundColor}
                disabled={themeInPreview.headerFooter.footer.noISI}
                leftRadius
                rightRadius
                callback={(color) => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'footer',
                      updates: {
                        ISIFooterBackgroundColor: color
                      }
                    }
                  });
                }}
              />
            </div>
          </div>
          <div className="new-theme-builder__hf-style-row">
            <div className="new-theme-builder__hf-style-label new-theme-builder__hf-style-label--fl-start">
              <span className="new-theme-builder__small-text">Text</span>
            </div>
            <div className="new-theme-builder__hf-toolbar">
              {!themeInPreview.headerFooter.footer.noISI &&
                <Editor
                  apiKey='j1zjwgyada24ar7u2uwpe3dxyhx6pduzftls0ex2z129yg13'
                  init={{
                    // inline: true,
                    // fixed_toolbar_container: '#testtt',

                    // height: `${item.block.size[0] * 95}`,
                    menubar: false,
                    plugins: [
                      'importcss code link charmap wordcount charmap emoticons paste lists advlist autoresize',
                    ],
                    toolbar1:
                      'fontselect fontsizeselect forecolor bold italic underline | link bullist numlist subscript superscript',
                    // toolbar2:
                    //   '',
                    // toolbar1: 'fontselect fontsizeselect | forecolor | bold italic underline | alignleft aligncenter alignright alignjustify | numlist bullist ',
                    // toolbar2: 'forecolor | bold italic underline | alignleft aligncenter alignright alignjustify | numlist bullist ',
                    // toolbar1: 'fontselect',
                    // toolbar2: 'fontsizeselect',
                    // toolbar3: 'undo redo',
                    // toolbar4: 'forecolor',
                    // toolbar5: 'bold italic underline',
                    // toolbar6: 'alignleft aligncenter alignright alignjustify',
                    // toolbar7: 'numlist bullist',
                    // plugins: 'autoresize',
                    height: '220',
                    // min_height: 95,
                    // autoresize_bottom_margin: 0,
                    // toolbar: false,
                    statusbar: false,
                    contextmenu: '',
                    content_style: tinyMCEContentStyleString,
                    font_formats: tinyMCEFontFormatsString,
                    placeholder: 'Type here',
                    quickbars_insert_toolbar: false,
                    // toolbar_mode: 'wrap',
                    fontsize_formats: 'Small=0.75em Medium=1em Large=1.5em',
                    color_map: [
                      ...themeInPreview.colorPalette.colors.flatMap((color, index) => [color.hex, color.hex]),
                      ...themeInPreview.colorPalette.colorsBlackWhiteGrey.flatMap((color, index) => [
                        color.hex,
                        color.hex,
                      ]),
                    ],
                    custom_colors: false,
                    browser_spellcheck: true,
                  }}
                  onInit={(event, editor) => {
                    editor.editorCommands.execCommand(
                      'FontName',
                      false,
                      themeInPreview.bodyTextFont.name.toLowerCase().replace(' -', ''),
                    );
                    editor.contentAreaContainer.style.fontSize = '1em';
                    editor.editorCommands.execCommand('fontSize', false, '1em');
                    editor.on('ExecCommand', (e) => {
                      if (e.command === 'mceApplyTextcolor') {
                        document.querySelector(
                          '.new-theme-builder__hf-toolbar .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap',
                        ).style.background = e.value;
                      }
                      if (e.command === 'mceRemoveTextcolor') {
                        document.querySelector(
                          '.new-theme-builder__hf-toolbar .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap',
                        ).style.background = '#2A3A49';
                      }
                    });
                    editor.formatter.formatChanged(
                      'forecolor',
                      function (isNew, args) {
                        if (args.node.style.color) {
                          document.querySelector(
                            '.new-theme-builder__hf-toolbar .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap',
                          ).style.background = args.node.style.color;
                        } else {
                          document.querySelector(
                            '.new-theme-builder__hf-toolbar .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap',
                          ).style.background = '#2A3A49';
                        }
                      },
                      true,
                    );
                    editor.on('keydown', (e) => {
                      e.stopPropagation();
                    });
                  }}
                  value={themeInPreview.headerFooter.footer.ISIFooterTextContent}
                  onEditorChange={(value) => {
                    dispatch({
                      type: UPDATE_HEADERFOOTER_PROPERTY,
                      payload: {
                        section: 'footer',
                        updates: {
                          ISIFooterTextContent: value
                        }
                      }
                    });
                  }}
                />
              }
            </div>
          </div>
          <div className="new-theme-builder__hf-style-row">
            <div className="new-theme-builder__hf-style-label">
            </div>
            <div className="new-theme-builder__hf-toolbar">
              {!themeInPreview.headerFooter.footer.noISI &&
                <Checkbox
                  checked={themeInPreview.headerFooter.footer.previewISIFooter}
                  onClick={() => {
                    dispatch({
                      type: UPDATE_HEADERFOOTER_PROPERTY,
                      payload: {
                        section: 'footer',
                        updates: {
                          previewISIFooter: !themeInPreview.headerFooter.footer.previewISIFooter,
                        }
                      }
                    });
                  }}
                >
                  Preview Info Spotlight in Open Mode
                </Checkbox>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ISITab;