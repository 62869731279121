import { Button } from 'antd';
import React from 'react';

export default function FormActions({ eventIndex, setCurrentEvent }) {
  return (
    <div className='block-event__actions'>
      <Button type='primary' className='block-event__button' htmlType='submit'>
        {eventIndex !== undefined ? 'Edit' : 'Add'} Event
      </Button>
      <Button
        className='block-event__button'
        onClick={() => {
          setCurrentEvent({});
        }}>
        Cancel
      </Button>
    </div>
  );
}
