import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { EDIT_QOOLECTION_PROPERTY, SELECT_QOOLECTION_WITH_HISTORY, EDIT_QOOLECTION_PROPERTY_WITHOUT_HISTORY } from '../../reducers/qoolection/actionTypes';
import { Editor } from '@tinymce/tinymce-react';

const MenuTextPanel = ({ qoolection, dispatch, theme, updateQoolection, temporaryQoolection }) => {
  const [showEditor, setShowEditor] = useState(false);

  useEffect(() => {
    if (qoolection.menuText) {
      setShowEditor(true);
    }
  }, [qoolection.menuText, setShowEditor]);

  let tinyMCEContentStyleString = '';
  theme.selectedFonts.forEach(
    (font) =>
    (tinyMCEContentStyleString += `
      @font-face {
        font-family: ${font.name};
        src: url(${font.importUrl});
        font-weight: ${font.weight};
        font-style: ${font.style};
      } 
    `),
  );
  tinyMCEContentStyleString += 'body { color:#2A3A49; font-size: 1em;}';

  let tinyMCEFontFormatsString = '';
  theme.selectedFonts.forEach(
    (font) => (tinyMCEFontFormatsString += `${font.name}=${font.name.toLowerCase().replace(' -', '')}; `),
  );

  const handleBlur = async () => {
    const newQoolection = await updateQoolection(temporaryQoolection);
    dispatch({
      type: SELECT_QOOLECTION_WITH_HISTORY,
      payload: newQoolection
    });
  };

  return (
    <>
      {!showEditor &&
        <div className="qoo-menu-panel__row">
          <Button
            block={true}
            className='menu-panel__add-txt-btn'
            onClick={() => setShowEditor(true)}
          >
            Add Supplementary Text
          </Button>
        </div>
      }
      {showEditor &&
        <div className="qoo-menu-panel__row">
          <div className="menu-panel__heading-row">
            <span
              onClick={() => {
                dispatch({
                  type: EDIT_QOOLECTION_PROPERTY,
                  payload: {
                    menuText: null
                  }
                });
                setShowEditor(false);
              }}
            >Remove</span>
          </div>
          <div className="menu-panel__editor menu-panel__editor--supplementary-text">
            <Editor
              apiKey='j1zjwgyada24ar7u2uwpe3dxyhx6pduzftls0ex2z129yg13'
              init={{
                // inline: true,
                // fixed_toolbar_container: '#testtt',

                // height: `${item.block.size[0] * 95}`,
                menubar: false,
                plugins: [
                  'importcss code link charmap lists wordcount charmap emoticons paste lists advlist autoresize',
                ],
                toolbar1:
                  'fontselect fontsizeselect forecolor | bold italic underline alignleft aligncenter alignright alignjustify link',
                // toolbar1: 'fontselect fontsizeselect | forecolor | bold italic underline | alignleft aligncenter alignright alignjustify | numlist bullist ',
                // toolbar2: 'forecolor | bold italic underline | alignleft aligncenter alignright alignjustify | numlist bullist ',
                // toolbar1: 'fontselect',
                // toolbar2: 'fontsizeselect',
                // toolbar3: 'undo redo',
                // toolbar4: 'forecolor',
                // toolbar5: 'bold italic underline',
                // toolbar6: 'alignleft aligncenter alignright alignjustify',
                // toolbar7: 'numlist bullist',
                // plugins: 'autoresize',
                height: '220',
                // min_height: 95,
                // autoresize_bottom_margin: 0,
                // toolbar: false,
                statusbar: false,
                contextmenu: '',
                content_style: tinyMCEContentStyleString,
                font_formats: tinyMCEFontFormatsString,
                placeholder: 'Type here',
                quickbars_insert_toolbar: false,
                // toolbar_mode: 'wrap',
                fontsize_formats: 'Small=0.75em Medium=1em Large=1.5em',
                color_map: [
                  ...theme.colorPalette.colors.flatMap((color, index) => [color.hex, color.hex]),
                  ...theme.colorPalette.colorsBlackWhiteGrey.flatMap((color, index) => [
                    color.hex,
                    color.hex,
                  ]),
                ],
                browser_spellcheck: true,
              }}
              onInit={(event, editor) => {
                editor.editorCommands.execCommand(
                  'FontName',
                  false,
                  theme.bodyTextFont.name.toLowerCase().replace(' -', ''),
                );
                editor.contentAreaContainer.style.fontSize = '1em';
                editor.editorCommands.execCommand('fontSize', false, '1em');
                editor.on('ExecCommand', (e) => {
                  if (e.command === 'mceApplyTextcolor') {
                    document.querySelector(
                      '.menu-panel__editor .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap',
                    ).style.background = e.value;
                  }
                  if (e.command === 'mceRemoveTextcolor') {
                    document.querySelector(
                      '.menu-panel__editor .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap',
                    ).style.background = '#2A3A49';
                  }
                });
                editor.formatter.formatChanged(
                  'forecolor',
                  function (isNew, args) {
                    if (args.node.style.color) {
                      document.querySelector(
                        '.menu-panel__editor .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap',
                      ).style.background = args.node.style.color;
                    } else {
                      document.querySelector(
                        '.menu-panel__editor .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap',
                      ).style.background = '#2A3A49';
                    }
                  },
                  true,
                );
                editor.on('keydown', (e) => {
                  e.stopPropagation();
                });
              }}
              value={temporaryQoolection.menuText}
              onEditorChange={(value) => {
                dispatch({
                  type: EDIT_QOOLECTION_PROPERTY_WITHOUT_HISTORY,
                  payload: {
                    menuText: value
                  }
                });
              }}
              onBlur={handleBlur}
            />
          </div>
        </div>
      }
    </>
  );
};

export default MenuTextPanel;