import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Input, Button, Tag } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { ReactComponent as SortIcon } from '../../icons/arrow-down.svg';
import { ReactComponent as ClockIcon } from '../../icons/ph_clock-light.svg';
import { ReactComponent as EditIcon } from '../../icons/ph_note-pencil-light.svg';
import { ReactComponent as RemoveIcon } from '../../icons/ph_trash-light.svg';
import NoData from '../elements/NoData';

const BlocksTable = ({
  user,
  data,
  deleteHandler,
  historyHandler,
  editHandler,
  columnKeys,
  title,
  editPath,
  viewPath,
  filters,
  setFilters,
  setOffset,
  total,
  current,
  limit,
  themes,
  audiences,
  categories,
  statuses,
  blockFormats
}) => {
  const [sortDirection, setSortDirection] = useState(false);

  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
  //     return (
  //       <div style={{ padding: 8 }}>
  //         <Input
  //           ref={(node) => {
  //             searchInput = node;
  //           }}
  //           placeholder={`Search ${dataIndex}`}
  //           value={selectedKeys[0]}
  //           onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //           onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           style={{ width: 188, marginBottom: 8, display: 'block' }}
  //         />
  //         <Button type='primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} size='small' style={{ width: 90, marginRight: 8 }}>
  //           Search
  //         </Button>
  //         <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
  //           Reset
  //         </Button>
  //       </div>
  //     );
  //   },
  //   filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
  //   onFilter: (value, record) => {
  //     const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
  //     if (!filterRecord || !value) return false;
  //     return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
  //   },
  //   onFilterDropdownVisibleChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => {
  //         searchInput.select();
  //       });
  //     }
  //   },
  // });

  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  // };

  // const handleReset = (clearFilters) => {
  //   clearFilters();
  // };

  // const columns = columnKeys.map((item) => ({
  //   key: item,
  //   title: item,
  //   dataIndex: item,
  //   ...getColumnSearchProps(item),
  // }));

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
      // ...getColumnSearchProps("name"),
    },
    {
      title: 'Theme',
      dataIndex: 'theme',
      // filters: themes.data?.items && themes.data.items.length > 0 && themes.data.items.map(theme => {
      //   return {
      //     text: theme.name,
      //     value: theme._id
      //   };
      // }),
      // filteredValue: filters?.theme,
      // onFilter: (value, record) => record.theme && record.theme._id === value,
      // // ...getColumnSearchProps("title"),
      render: (theme) => <span>{theme.name && theme.name}</span>
    },
    {
      title: 'Type',
      // dataIndex: ["blockType", "name"],
      dataIndex: 'blockFormat',
      // ...getColumnSearchProps(["blockType", "name"]),
      // filters: blockFormats && blockFormats.map(blockFormat => {
      //   return {
      //     text: blockFormat,
      //     value: blockFormat,
      //   };
      // }),
      // filteredValue: filters?.blockFormat,
      // onFilter: (value, record) => record.theme && record.theme._id === value,
      // // ...getColumnSearchProps("title"),
      render: (blockFormat) => (
        <span>{blockFormat?.charAt(0).toUpperCase() + blockFormat?.substring(1)}</span>
      )
    },
    {
      title: 'Category',
      dataIndex: 'category',
      // filters: categories.data?.items && categories.data.items.length > 0 && categories.data.items.map(category => {
      //   return {
      //     text: category.name,
      //     value: category._id
      //   };
      // }),
      // filteredValue: filters?.category,
      // onFilter: (value, record) => record.category && record.category.some(c => c._id === value),
      render: (category) => {
        return (
          <span>
            {category &&
              category.map((c) => (
                <Tag key={c._id} className="table-tag--category">
                  {c.name}
                </Tag>
              ))}
          </span>
        );
      }
    },
    {
      title: 'Audience',
      dataIndex: 'audience',
      // filters: audiences.data?.items && audiences.data.items.length > 0 && audiences.data.items.map(audience => {
      //   return {
      //     text: audience.name,
      //     value: audience._id
      //   };
      // }),
      // filteredValue: filters?.audience,
      // onFilter: (value, record) => record.audience && record.audience.some(a => a._id === value),
      render: (audience) => (
        <span>
          {audience &&
            audience.map((a) => (
              <Tag key={a._id} className="table-tag--audience">
                {a.name}
              </Tag>
            ))}
        </span>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // filters: statuses && statuses.map((status) => {
      //   return {
      //     text: status.charAt(0).toUpperCase() + status.substring(1),
      //     value: status,
      //   };
      // }),
      // filteredValue: filters?.status,
      // onFilter: (value, record) => record.status && record.status.indexOf(value) === 0,
      render: (status) => (
        <span>
          {
            /* {status && status.map(s => <Tag key={s.name}>{s.name}</Tag>)} */
            status && (
              <Tag
                className={
                  status === 'draft'
                    ? 'table-tag--status-draft'
                    : status === 'waiting-approval'
                    ? 'table-tag--status-waiting-approval'
                    : status === 'in-approval'
                    ? 'table-tag--status-in-approval'
                    : status === 'approved'
                    ? 'table-tag--status-approved'
                    : status === 'change_required'
                    ? 'table-tag--status-change_required'
                    : status === 'shared'
                    ? 'table-tag--status-shared'
                    : status === 'published'
                    ? 'table-tag--status-published'
                    : status === 'retired'
                    ? 'table-tag--status-retired'
                    : status === 'template'
                    ? 'table-tag--status-template'
                    : 'table-tag'
                }>
                {status.charAt(0).toUpperCase() + status.substring(1)}
              </Tag>
            )
          }
        </span>
      )
    },
    {
      title: 'Version',
      dataIndex: 'version',
      render: (version) => (
        <span>
          <div>
            {version?.major || 0}.{version?.minor || 0}.{version?.patch || 0}
          </div>
        </span>
      )
    }
    // {
    //   title: (
    //     <div onClick={() => setSortDirection(!sortDirection)}>Date Created {sortDirection ?
    //       <SortIcon style={{ marginLeft: "8px", transform: "rotate(180deg)", transition: ".2s ease-in-out transform" }} /> :
    //       <SortIcon style={{ marginLeft: "8px", transition: ".2s ease-in-out transform" }} />
    //     }</div>
    //   ),
    //   dataIndex: 'createdAt',
    //   // ...getColumnSearchProps("title"),
    //   sorter: {
    //     compare: (a, b) => String(a.createdAt).localeCompare(String(b.createdAt))
    //   },
    //   sortDirections: ['descend', 'ascend', 'descend'],
    //   defaultSortOrder: 'descend',
    //   render: createdAt => (
    //     <span>
    //       {
    //         createdAt && createdAt.split('T')[0].split('-')[1] + '/' + createdAt.split('T')[0].split('-')[2] + '/' + createdAt.split('T')[0].split('-')[0]
    //       }
    //     </span>
    //   )
    // },
    // {
    //   title: "Date Modified",
    //   dataIndex: 'updatedAt',
    //   // ...getColumnSearchProps("title"),
    //   render: updatedAt => (
    //     <span>
    //       {
    //         updatedAt && updatedAt.split('T')[0].split('-')[1] + '/' + updatedAt.split('T')[0].split('-')[2] + '/' + updatedAt.split('T')[0].split('-')[0]
    //       }
    //     </span>
    //   )
    // },
  ];

  columns.push({
    title: 'Action',
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px',
            color: '#90989E'
          }}>
          <div
            onClick={() => user.handleRefreshToken()}
            style={{
              margin: '2px',
              padding: '4px',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Link
              to={editPath + record._id}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <EditIcon title="Edit Block" style={{ textDecoration: 'none', color: '#90989E' }} />
            </Link>
          </div>

          {viewPath && (
            <div
              style={{
                margin: '2px',
                padding: '4px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <Link to={viewPath + record._id}>
                <EyeOutlined title="Preview Block" style={{ textDecoration: 'none' }} />
              </Link>
            </div>
          )}

          <div
            style={{
              margin: '2px',
              padding: '4px',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Popconfirm
              placement="left"
              title={`This action will delete ${record?.name || 'this Block'}`}
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText="Ok"
              cancelText="Cancel">
              <RemoveIcon title="Delete Block" />
            </Popconfirm>
          </div>

          <div
            style={{
              margin: '2px',
              padding: '4px',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <ClockIcon
              title="View Block History"
              style={{ textDecoration: 'none' }}
              onClick={() => historyHandler(record._id)}
            />
          </div>
        </div>
      );
    }
  });

  const locale = {
    emptyText: <NoData />
  };

  return (
    <div className="table-manage">
      <Table
        size="middle"
        bordered
        dataSource={data}
        columns={columns}
        rowKey="_id"
        pagination={{
          defaultPageSize: limit,
          position: ['bottomCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          // hideOnSinglePage: true,
          total,
          current
        }}
        onChange={(pagination, filters, sorter) => {
          setOffset(pagination.current - 1);
          setFilters((previous) => ({ ...previous, ...filters }));
        }}
        locale={locale}
      />
    </div>
  );
};

export default BlocksTable;
