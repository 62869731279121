import React from 'react';
import { Layout } from 'antd';
import HeaderComponent from '../../components/base/HeaderComponent';

const { Content } = Layout;

const Analyze = () => {
  return (
    <Layout className='manage-layout'>
      <div style={{ textAlign: 'center', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        <HeaderComponent text='Analyze' />
        <Content className='analyze-page__content'>
          <img src={process.env.PUBLIC_URL + '/Analyze.png'} alt='analyze' />
        </Content>
      </div>
    </Layout>
  );
};

export default Analyze;