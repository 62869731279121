import { Button, Modal, Form, Input, Select, notification } from 'antd';
import React from 'react';
import Axios from 'axios';
import { REPLACE_EDITED_BLOCK } from '../../reducers/qoo-in-preview/actionTypes';
import { SELECT_BLOCK } from '../../reducers/block-in-preview/actionTypes';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';

const SaveBlockModal = ({
  saveBlockModalVisible,
  setSaveBlockModalVisible,
  audiences,
  categories,
  blockInPreview,
  SERVER_URL,
  token,
  dispatch,
  blockInPreviewDispatch,
}) => {
  const [form] = Form.useForm();

  const saveChanges = async (submitData) => {
    const method = blockInPreview.isNew ? 'post' : 'put';
    const route = blockInPreview.isNew
      ? `${SERVER_URL}/blocks-create-new-from-existing`
      : `${SERVER_URL}/blocks/${blockInPreview._id}`;

    try {
      const response = await Axios[method](route, submitData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      notification.success({
        message: `Block ${blockInPreview.isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration,
      });

      setSaveBlockModalVisible(false);

      dispatch({
        type: REPLACE_EDITED_BLOCK,
        payload: {
          originalBlockId: blockInPreview._id,
          block: response.data.item,
          x: blockInPreview.column,
          y: blockInPreview.row,
        },
      });

      blockInPreviewDispatch({
        type: SELECT_BLOCK,
        payload: null,
      });
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration,
      });
    }
  };

  return (
    <Modal visible={saveBlockModalVisible} onCancel={() => setSaveBlockModalVisible(false)} footer={null} width={560}>
      <div className='save-block-modal'>
        {blockInPreview.isNew && <h2 className='save-block-modal__title'>Create New Block</h2>}
        {!blockInPreview.isNew && (
          <>
            <h2 className='save-block-modal__title'>Are existing Block Properties still relevant?​ </h2>
            <p className='save-block-modal__text'>
              Before saving the edited Block, please review and update the existing Block Properties to make sure they
              remain relevant for the updated Block content.
            </p>
          </>
        )}

        <Form
          layout='vertical'
          className='save-block-modal__form'
          form={form}
          initialValues={{ ...blockInPreview }}
          onFinish={(values) => saveChanges({ ...blockInPreview, ...values })}
        >
          <Form.Item
            label='Name'
            rules={[{ required: true, message: 'Please enter name!' }]}
            name='name'
            labelAlign='left'
          >
            <Input placeholder='Name' />
          </Form.Item>
          <Form.Item
            label='Category'
            //rules={[{ required: true, message: 'Please enter category!' }]}
            name='category'
            labelAlign='left'
          >
            <Select mode='multiple' placeholder='Category' className='dropdown-medium' size='large'>
              {categories.map((category) => {
                return (
                  <Select.Option value={category._id} key={category._id}>
                    {category.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label='Audience'
            //rules={[{ required: true, message: 'Please enter audience!' }]}
            name='audience'
            labelAlign='left'
          >
            <Select mode='multiple' placeholder='Audience' className='dropdown-medium' size='large'>
              {audiences.map((audience) => {
                return (
                  <Select.Option value={audience._id} key={audience._id}>
                    {audience.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label='Notes' name='notes' labelAlign='left'>
            <Input.TextArea rows={4} placeholder='Notes' />
          </Form.Item>
          <Button type='primary' htmlType='submit' block>
            Save Block
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

export default SaveBlockModal;
