import React, { useContext, createContext, useState } from 'react';
import { UserContext } from '../../App';
import NavMenu from './NavMenu';
import { Layout } from 'antd';

const { Content } = Layout;

export const LayoutContext = createContext();

const AdminLayout = ({ children }) => {
  const user = useContext(UserContext);
  const [editInProgress, setEditInProgress] = useState(false);

  return (
    <LayoutContext.Provider value={{ editInProgress, setEditInProgress }} >
      <Layout className='main-layout'>
        <NavMenu isCollapsed={user.isNavMenuCollapsed} setCollapsed={user.setIsNavMenuCollapsed} user={user} />
        <Layout>
          <Content className='main-content'>
            {children}
          </Content>
        </Layout>
      </Layout>
    </LayoutContext.Provider>
  );
};

export default AdminLayout;
