import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification, Layout, Input, Select, Modal } from 'antd';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import useAxios from '../../hooks/useAxios';
import FootnotesTable from '../../components/tables/FootnotesTable';
import { SERVER_URL } from '../../config';
import Footnote from '../../components/endnotes/Footnote';

const TABLE_COLUMN_KEYS = ['_id', '__v'];

const Footnotes = ({ footnotes, setStateForRefetchingFootnotes }) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [searchFilter, setSearchFilter] = useState('');
  const [footnoteToEdit, setFootnoteToEdit] = useState();

  let filteredFootnotes;

  if (footnotes.data.items && footnotes.data.items.length > 0) {
    filteredFootnotes = footnotes.data.items.filter((footnotes) => {
      const textMatch = searchFilter
        ? footnotes.title.toLowerCase().includes(searchFilter.toLowerCase())
        : true;
      return textMatch;
    });
  }

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/footnotes/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      setStateForRefetchingFootnotes((prev) => prev + 1);
      // history.push('/admin');
      // history.push('/admin/library');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const editDataHandler = async (id, updates) => {
    try {
      await Axios.put(`${SERVER_URL}/footnotes/${id}`, updates, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item updated.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      history.push('/admin');
      history.push('/admin/library');
    } catch (err) {
      notification.error({
        message: 'Problem with update. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  let columnKeys;
  if (footnotes.data && footnotes.data.items && footnotes.data.items.length > 0) {
    const keys = Object.keys(footnotes.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (footnotes.data && footnotes.data.items && footnotes.data.items.length > 0) {
    tableData = footnotes.data.items;
  }
  if (filteredFootnotes && filteredFootnotes.length > 0) {
    tableData = filteredFootnotes;
  }

  return (
    <>
      <div className="table-header">
        <Input
          className="table-header__search"
          placeholder="Search Footnotes"
          prefix={<SearchIcon />}
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
      </div>
      <FootnotesTable
        data={tableData}
        deleteHandler={deleteDataHandler}
        // editHandler={editDataHandler}
        // editPath='/admin/edit-block/'
        columnKeys={columnKeys}
        title="Footnotes"
        setFootnoteToEdit={setFootnoteToEdit}
      />
      {footnoteToEdit && (
        <Modal
          title="Edit Footnote"
          open={footnoteToEdit}
          footer={null}
          onCancel={() => setFootnoteToEdit(null)}>
          <Footnote
            token={currentuser.data.token}
            setStateForRefetchingFootnotes={setStateForRefetchingFootnotes}
            endnoteForEdit={footnoteToEdit}
            setFootnoteToEdit={setFootnoteToEdit}
            library
          />
        </Modal>
      )}
    </>
  );
};

export default Footnotes;
