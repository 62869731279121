import React from 'react';
import { Collapse } from 'antd';
import { ReactComponent as ExpandIcon } from '../../icons/ph_arrows-out-simple-light.svg';
import { ReactComponent as CollapseIcon } from '../../icons/ph_arrows-in-simple-light.svg';

const { Panel } = Collapse;

const PanelsPanel = ({ inSidebar, collapseSidebar, dockToRightSidebar, rightPanel }) => {
  return (
    <div className={inSidebar ? "layers-panel layers-panel--in-sidebar" : "layers-panel"}>
      <Collapse
        ghost
        defaultActiveKey={rightPanel === 'panels' ? '1' : null}
      >
        <Panel
          header={(
            <h3 className='pre-title'>
              Panels
            </h3>
          )}
          key='1'
          collapsible='header'
          extra={inSidebar ? (
            <CollapseIcon onClick={() => collapseSidebar('panels')} />
          ) : (
            <ExpandIcon onClick={dockToRightSidebar} />
          )}
          showArrow={false}
        >
          <div className="layers-panel__list" >

          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default PanelsPanel;