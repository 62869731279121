import React from 'react';
import { Pagination } from 'antd';
import QoolectionCard from '../../pages/qoolections/QoolectionCard';
import NoData from '../elements/NoData';

const QoolectionsGrid = ({
  qoolections,
  deleteDataHandler,
  editDataHandler,
  historyHandler,
  editPath,
  SERVER_URL,
  currentuser,
  setOffset,
  total,
  current,
  setQoolectionInModalPreview,
  setQoolectionForApproval,
  duplicateQoolection
}) => {
  return (
    <div className="qoolections-grid-container">
      <div className="qoolections-grid">
        {qoolections.length > 0 ? (
          qoolections.map((qoolection) => {
            return (
              <QoolectionCard
                key={qoolection._id}
                qoolection={qoolection}
                editDataHandler={() => editDataHandler(qoolection._id)}
                deleteDataHandler={() => deleteDataHandler(qoolection._id)}
                historyHandler={() => historyHandler(qoolection._id)}
                previewHandler={() => setQoolectionInModalPreview(qoolection)}
                setQoolectionForApproval={setQoolectionForApproval}
                duplicateQoolection={duplicateQoolection}
              />
            );
          })
        ) : (
          <NoData />
        )}
      </div>
      <div className="qoolections-grid__pagination">
        <Pagination
          current={current}
          total={total}
          onChange={(page) => setOffset(page - 1)}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default QoolectionsGrid;
