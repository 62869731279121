import { Button, Checkbox, Form, Input } from 'antd';
import React from 'react';

const FormElement = ({ block, width }) => {

  const formFieldStyles = {
    width: '100%',
    fontSize: `${width / 570 * 16}px`,
    height: '3em',
    border:
      block.blockProperties.formFieldBorderThickness &&
        block.blockProperties.formFieldBorderType &&
        block.blockProperties.formFieldBorderColor
        ? `${block.blockProperties.formFieldBorderThickness}em ${block.blockProperties.formFieldBorderType} ${block.blockProperties.formFieldBorderColor.hex}`
        : '1px solid #D5DFE8',
    backgroundColor: block.blockProperties.formFieldBackgroundColor?.hex,
    fontFamily: block.blockProperties.formFont?.name,
    pointerEvents: 'none'
  };

  return (
    // <Form
    //   style={{
    //     width: '100%',
    //     height: '100%',
    //     paddingTop: block.blockProperties.formPaddingTop,
    //     paddingBottom: block.blockProperties.formPaddingBottom,
    //     paddingLeft: block.blockProperties.formPaddingLeft,
    //     paddingRight: block.blockProperties.formPaddingRight,
    //     backgroundColor: block.blockProperties.formBackgroundColor?.hex,
    //     overflow: 'hidden',
    //     border:
    //       block.blockProperties.formBorderThickness &&
    //         block.blockProperties.formBorderType &&
    //         block.blockProperties.formBorderColor
    //         ? `${block.blockProperties.formBorderThickness}em ${block.blockProperties.formBorderType} ${block.blockProperties.formBorderColor.hex}`
    //         : 'none',
    //     fontFamily: font.name
    //   }}
    //   layout='vertical'
    // // onFinish={(values) => console.log(values)}
    // >
    <div
      style={{
        fontSize: `${width / 570 * 16}px`,
        width: '100%',
        height: '100%',
        paddingTop: `${(width / 570) * block.blockProperties.formPaddingTop}px`,
        paddingBottom: `${(width / 570) * block.blockProperties.formPaddingBottom}px`,
        paddingLeft: `${(width / 570) * block.blockProperties.formPaddingLeft}px`,
        paddingRight: `${(width / 570) * block.blockProperties.formPaddingRight}px`,
        backgroundColor: block.blockProperties.formBackgroundColor?.hex,
        overflow: 'hidden',
        border:
          block.blockProperties.formBorderThickness &&
            block.blockProperties.formBorderType &&
            block.blockProperties.formBorderColor
            ? `${block.blockProperties.formBorderThickness}em ${block.blockProperties.formBorderType} ${block.blockProperties.formBorderColor.hex}`
            : 'none',
        fontFamily: block.blockProperties.formFont?.name,
        color: block.blockProperties.formFontColor?.hex
      }}
    // layout='vertical'
    // onFinish={(values) => console.log(values)}
    >
      {block.blockProperties.formFields && block.blockProperties.formFields.length > 0 && block.blockProperties.formFields.map((formField, index) => {
        switch (formField.fieldElement) {
          case 'text':
            return (
              <div
                key={index}
                style={{
                  marginBottom: `${width / 570 * 16}px`,
                  // lineHeight: 1.5715
                }}
              >
                {/* <p style={{ lineHeight: '1' }}>{formField.fieldName}</p>*/}
                <input
                  type='text'
                  className='preview-input'
                  style={formFieldStyles}
                  placeholder={formField.fieldName}
                />
              </div>
              // <Form.Item
              //   key={index}
              //   label={formField.fieldName}
              //   name={formField.fieldName}
              //   rules={[
              //     {
              //       required: formField.required,
              //       message: 'Field is required!',
              //     },
              //   ]}
              // >
              //   <input
              //     type='text'
              //     className='preview-input'
              //     style={formFieldStyles}
              //   />
              // </Form.Item>
            );
          case 'email':
            return (
              <div
                key={index}
                style={{
                  marginBottom: `${width / 570 * 16}px`,
                  // lineHeight: 1.5715
                }}
              >
                {/*  <p style={{ lineHeight: '1' }}>{formField.fieldName}</p> */}
                <input
                  type='email'
                  className='preview-input'
                  style={formFieldStyles}
                  placeholder={formField.fieldName}
                />
              </div>
              // <Form.Item
              //   key={index}
              //   label={formField.fieldName}
              //   name={formField.fieldName}
              //   rules={[
              //     {
              //       required: formField.required,
              //       message: 'Field is required!',
              //     },
              //   ]}
              // >
              //   <input
              //     type='email'
              //     className='preview-input'
              //     style={formFieldStyles}
              //   />
              // </Form.Item>
            );
          case 'textarea':
            return (
              <div
                key={index}
                style={{
                  marginBottom: `${width / 570 * 16}px`,
                  // lineHeight: 1.5715
                }}
              >
                {/*  <p style={{ lineHeight: '1' }}>{formField.fieldName}</p> */}
                <textarea
                  className='preview-input'
                  style={{
                    ...formFieldStyles,
                    height: '5em',
                    resize: 'none'
                  }}
                  placeholder={formField.fieldName}
                />
              </div>
              // <Form.Item
              //   key={index}
              //   label={formField.fieldName}
              //   name={formField.fieldName}
              //   rules={[
              //     {
              //       required: formField.required,
              //       message: 'Field is required!',
              //     },
              //   ]}
              // >
              //   <textarea
              //     className='preview-input'
              //     style={{
              //       ...formFieldStyles,
              //       height: '5em',
              //       resize: 'none'
              //     }}
              //   />
              // </Form.Item>
            );

          case 'checkbox':
            return (
              <div
                key={index}
                style={{
                  marginBottom: `${width / 570 * 16}px`,
                  // lineHeight: 1.5715
                }}
              >
                {/* <p style={{ lineHeight: '1' }}>{formField.fieldName}</p>*/}
                <Checkbox checked
                  style={{
                    transform: `scale(${width / 570 === 1 ? 1 : width / 570 < 1 && (width / 570) * 1.3})`,
                    transformOrigin: 'left center',
                  }}>
                  {formField.fieldName}
                </Checkbox>
              </div>
              // <Form.Item
              //   key={index}
              //   // label={formField.fieldName}
              //   name={formField.fieldName}
              // >
              //   <Checkbox checked>
              //     {formField.fieldName}
              //   </Checkbox>
              // </Form.Item>
            );
        }
      })}

      <div
        style={{
          display: 'flex',
          justifyContent: block.blockProperties.formButtonPosition ? block.blockProperties.formButtonPosition : ''
        }}
      >
        <button
          type='submit'
          style={{
            flexGrow: block.blockProperties.formButtonPosition ? '0' : '1',
            backgroundColor: block.blockProperties.formButtonBackgroundColor?.hex,
            color: 'white',
            height: '2em',
            padding: '0 2em',
            // height: `${width / 570 * 32}px`,
            // padding: `0 ${width / 570 * 30}px`,
            border: block.blockProperties.formButtonBorderThickness &&
              block.blockProperties.formButtonBorderType &&
              block.blockProperties.formButtonBorderColor
              ? `${block.blockProperties.formButtonBorderThickness}em ${block.blockProperties.formButtonBorderType} ${block.blockProperties.formButtonBorderColor.hex}`
              : 'none',
            borderRadius: block.blockProperties.formButtonBorderRadius,
            boxShadow: block.blockProperties.formButtonHasShadow
              ? `${block.blockProperties.formButtonInnerShadow ? 'inset' : ''} 0em 0.3em 0.5em rgba(0,0,0,0.3)`
              : ''
          }}
        >
          Send
        </button>
      </div>
    </div>
    // </Form>
  );
};

export default FormElement;