import {
  SELECT_QOOLECTION,
  SELECT_QOOLECTION_WITH_HISTORY,
  ADD_QOO,
  REMOVE_QOO,
  CLEAR_ALL_QOOS,
  EDIT_QOOLECTION_PROPERTY,
  EDIT_QOOLECTION_PROPERTY_WITHOUT_HISTORY,
  ADD_MENU_LINK,
  REMOVE_MENU_LINK,
  EDIT_MENU_LINK,
  UNDO,
  REDO,
  EDIT_QOOLECTION_COLLABORATOR_ACCESS
} from './actionTypes';
import _ from 'lodash';

const qoolectionReducer = (state, action) => {
  // console.log('state: ', state);
  const stateQoolectionMenuLinks = _.get(state, 'qoolection.menuLinks', []);

  switch (action.type) {
    case SELECT_QOOLECTION:
      return {
        qoolectionHistory: [],
        qoolectionFuture: [],
        qoolection: action.payload,
        temporaryQoolection: action.payload
      };

    case SELECT_QOOLECTION_WITH_HISTORY:
      return {
        qoolectionHistory: [...state.qoolectionHistory, state.qoolection],
        qoolectionFuture: [],
        qoolection: action.payload,
        temporaryQoolection: action.payload
      };

    case ADD_QOO:
      return {
        qoolectionHistory: [...state.qoolectionHistory, state.qoolection],
        qoolectionFuture: [],
        qoolection: {
          ...state.qoolection,
          qoos: [...state.qoolection.qoos, action.payload.qoo]
        },
        temporaryQoolection: {
          ...state.qoolection,
          qoos: [...state.qoolection.qoos, action.payload.qoo]
        }
      };

    case REMOVE_QOO:
      return {
        qoolectionHistory: [...state.qoolectionHistory, state.qoolection],
        qoolectionFuture: [],
        qoolection: {
          ...state.qoolection,
          qoos: state.qoolection.qoos.filter(
            (qoo, index) => !(qoo._id === action.payload.qooId && index === action.payload.qooIndex)
          )
        },
        temporaryQoolection: {
          ...state.qoolection,
          qoos: state.qoolection.qoos.filter(
            (qoo, index) => !(qoo._id === action.payload.qooId && index === action.payload.qooIndex)
          )
        }
      };

    case CLEAR_ALL_QOOS:
      return {
        qoolectionHistory: [...state.qoolectionHistory, state.qoolection],
        qoolectionFuture: [],
        qoolection: {
          ...state.qoolection,
          qoos: []
        },
        temporaryQoolection: {
          ...state.qoolection,
          qoos: []
        }
      };

    case EDIT_QOOLECTION_PROPERTY:
      return {
        qoolectionHistory: [...state.qoolectionHistory, state.qoolection],
        qoolectionFuture: [],
        qoolection: {
          ...state.qoolection,
          ...action.payload
        },
        temporaryQoolection: {
          ...state.qoolection,
          ...action.payload
        }
      };

    case EDIT_QOOLECTION_PROPERTY_WITHOUT_HISTORY:
      return {
        qoolectionHistory: state.qoolectionHistory,
        qoolectionFuture: state.qoolectionFuture,
        qoolection: state.qoolection,
        temporaryQoolection: {
          ...state.qoolection,
          ...action.payload
        }
      };

    case ADD_MENU_LINK:
      const previousMenuLinks =
        stateQoolectionMenuLinks.length > 0 ? [...stateQoolectionMenuLinks] : [];

      return {
        qoolectionHistory: state.qoolectionHistory,
        qoolectionFuture: [],
        qoolection: state.qoolection,
        temporaryQoolection: {
          ...state.qoolection,
          menuLinks: [...previousMenuLinks, {}]
        }
      };

    case REMOVE_MENU_LINK:
      const tempMenuLinksForRemoving =
        stateQoolectionMenuLinks.length > 0 ? [...stateQoolectionMenuLinks] : [];
      tempMenuLinksForRemoving.splice(action.payload.linkIndex, 1);

      return {
        qoolectionHistory: [...state.qoolectionHistory, state.qoolection],
        qoolectionFuture: [],
        qoolection: {
          ...state.qoolection,
          menuLinks: tempMenuLinksForRemoving
        },
        temporaryQoolection: {
          ...state.qoolection,
          menuLinks: tempMenuLinksForRemoving
        }
      };

    case EDIT_MENU_LINK:
      return {
        qoolectionHistory: state.qoolectionHistory,
        qoolectionFuture: [],
        qoolection: state.qoolection,
        temporaryQoolection: {
          ...state.qoolection,
          menuLinks: stateQoolectionMenuLinks.map((item, index) => {
            if (index === action.payload.linkIndex) {
              return {
                ...item,
                ...action.payload.updates
              };
            } else {
              return item;
            }
          })
        }
      };

    case UNDO:
      const oldHistory = [...state.qoolectionHistory];
      const newPresentForUndo = oldHistory.pop();

      return {
        qoolectionHistory: oldHistory,
        qoolection: action.payload,
        qoolectionFuture: [state.qoolection, ...state.qoolectionFuture],
        temporaryQoolection: action.payload
      };

    case REDO:
      const oldFuture = [...state.qoolectionFuture];
      const newPresentForRedo = oldFuture.shift();

      return {
        qoolection: action.payload,
        qoolectionHistory: [...state.qoolectionHistory, state.qoolection],
        qoolectionFuture: oldFuture,
        temporaryQoolection: action.payload
      };

    // case EDIT_QOOLECTION_COLLABORATOR_ACCESS:
    //   console.log('REDUCER state: ', state);
    //   console.log('REDUCER action.payload: ', action.payload);
    //   return {
    //     qoolection: {
    //       ...state.qoolection,
    //       collaborators: state?.qoolection?.collaborators?.find(
    //         (collaborator) => collaborator.user._id === action.payload.userId
    //       )
    //         ? state?.qoolection?.collaborators?.map((collaborator) => {
    //             console.log('collaborator.user._id: ', collaborator.user._id);
    //             if (collaborator.user._id === action.payload.userId) {
    //               return {
    //                 ...collaborator,
    //                 accessRight: action.payload.accessRight
    //               };
    //             } else {
    //               return collaborator;
    //             }
    //           })
    //         : [
    //             ...state?.qoolection?.collaborators,
    //             {
    //               user: action.payload.userId,
    //               accessRight: action.payload.accessRight
    //             }
    //           ]
    //     }
    //   };

    default:
      return state;
  }
};

export default qoolectionReducer;
