import { Button, Form, InputNumber, Select } from 'antd';
import FormActions from './components/FormActions';

export default function ReminderForm({
  currentEvent,
  setCurrentEvent,
  qoos,
  remindersForBlockEvent,
  blockEventHandler,
  eventIndex
}) {
  const initialValues = {
    reminder: currentEvent?.reminder
  };

  return (
    <Form onFinish={blockEventHandler} initialValues={initialValues}>
      <Select
        className='block-event__element'
        placeholder='Select Reminder Type...'
        value={currentEvent?.reminderType}
        onChange={(value) =>
          setCurrentEvent({
            ...currentEvent,
            reminderType: value
          })
        }>
        <Select.Option value={'SMS'}>SMS</Select.Option>
      </Select>
      <Form.Item
        className='block-event__form-item'
        name='reminder'
        rules={[
          {
            required: true,
            message: 'Please select a Reminder!'
          }
        ]}>
        <Select
          className='block-event__element'
          placeholder='Select Reminder...'
          showSearch
          optionFilterProp='children'
          value={currentEvent?.reminder}
          onChange={(value) =>
            setCurrentEvent({
              ...currentEvent,
              reminder: value
            })
          }>
          {remindersForBlockEvent?.data?.items?.map((reminder) => (
            <Select.Option key={reminder?._id} value={reminder?._id}>
              {reminder?.reminder}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <InputNumber
        className='block-event__element'
        min={0}
        max={3600}
        type='number'
        placeholder='Send Delay (in minutes)'
        value={currentEvent?.reminderSendDelay}
        onChange={(value) =>
          setCurrentEvent({
            ...currentEvent,
            reminderSendDelay: value
          })
        }
      />
      <InputNumber
        className='block-event__element'
        min={0}
        max={3600}
        type='number'
        placeholder='Resend Delay (in minutes)'
        value={currentEvent?.reminderSendResendDelay}
        onChange={(value) =>
          setCurrentEvent({
            ...currentEvent,
            reminderSendResendDelay: value
          })
        }
      />
      <InputNumber
        className='block-event__element'
        min={0}
        max={100}
        type='number'
        placeholder='Maximum Resends'
        value={currentEvent?.reminderMaximumResends}
        onChange={(value) =>
          setCurrentEvent({
            ...currentEvent,
            reminderMaximumResends: value
          })
        }
      />
      STOP REMINDER CONTROL
      <Select
        className='block-event__element'
        placeholder='Dont send if initial Qoo is...'
        value={currentEvent?.stopReminderIfInitialQoo}
        onChange={(value) =>
          setCurrentEvent({
            ...currentEvent,
            stopReminderIfInitialQoo: value
          })
        }>
        {qoos?.map((qoo) => (
          <Select.Option key={qoo?._id} value={qoo?._id}>
            {qoo.title}
          </Select.Option>
        ))}
      </Select>
      <FormActions eventIndex={eventIndex} setCurrentEvent={setCurrentEvent} />
    </Form>
  );
}
