import React from 'react';
import { Tooltip } from 'antd';
import ThemeDisplay from './ThemeDisplay';
import { ReactComponent as InfoIcon } from '../../icons/info-theme-icon.svg';

const ThemePreview = ({ themeInPreview, showMenu, showInfo }) => {
  return (
    <div className="theme-preview">
      <div className="theme-preview__phone-container">
        <div className="theme-preview__phone">
          <div className="theme-preview__top-bar"></div>
          <div className="theme-preview__black-bg"></div>
          <div className="theme-preview__qoo-content" style={{ marginBottom: '10px' }}>
            <ThemeDisplay themeInPreview={themeInPreview} width={308} showMenu={showMenu} />
          </div>
        </div>
        {showInfo && !themeInPreview.headerFooter.header.noHeader && (
          <Tooltip
            color="#4962E2"
            title={
              <div className="theme-preview__info">
                <p>
                  A Header is the top part of each Qoo (page) where you might place your brand’s
                  logo or a menu button.
                </p>
                <p>
                  A Menu can be used to provide quick access to additional information, for example,
                  references and footnotes, a contact Qoo, or other unique content.
                </p>
              </div>
            }
            placement="bottom">
            <InfoIcon
              style={{
                position: 'absolute',
                right: '-22px',
                top: '60px'
              }}
            />
          </Tooltip>
        )}
        {showInfo && !themeInPreview.headerFooter.footer.noFooter && (
          <Tooltip
            color="#4962E2"
            title={
              <div className="theme-preview__info">
                <p>
                  A Footer is the bottom part of each Qoo (page) where you might place one or two
                  quick links (for example, to references, study design, website), or some static
                  text (for example, a copyright).
                </p>
              </div>
            }
            placement="top">
            <InfoIcon
              style={{
                position: 'absolute',
                right: '-22px',
                bottom: themeInPreview.headerFooter.footer.noISI ? '56px' : '40px'
              }}
            />
          </Tooltip>
        )}
        {showInfo &&
          !themeInPreview.headerFooter.footer.noISI &&
          themeInPreview.headerFooter.footer.shownISI !== 'title-only' && (
            <Tooltip
              color="#4962E2"
              title={
                <div className="theme-preview__info">
                  <p>
                    The Info Spotlight preview is what shows up first, before you click on a button
                    (for example, a + icon) to see the entire Info Spotlight.
                  </p>
                </div>
              }
              placement="top">
              <InfoIcon
                style={{
                  position: 'absolute',
                  right: '-22px',
                  bottom: themeInPreview.headerFooter.footer.noFooter
                    ? themeInPreview.headerFooter.footer.shownISI === 'more-text'
                      ? '70px'
                      : '40px'
                    : themeInPreview.headerFooter.footer.shownISI === 'more-text'
                    ? '108px'
                    : '82px'
                }}
              />
            </Tooltip>
          )}
        {showInfo && !themeInPreview.headerFooter.footer.noISI && (
          <Tooltip
            color="#4962E2"
            title={
              <div className="theme-preview__info">
                <p>
                  The Info Spotlight lets you show important information that will be easily
                  accessible at the bottom of a Qoo via a collapsible/expandable bar. It's optional.
                </p>
              </div>
            }
            placement="top">
            <InfoIcon
              style={{
                position: 'absolute',
                right: '-22px',
                bottom: themeInPreview.headerFooter.footer.noFooter
                  ? themeInPreview.headerFooter.footer.shownISI === 'more-text'
                    ? '124px'
                    : themeInPreview.headerFooter.footer.shownISI === 'less-text'
                    ? '76px'
                    : '56px'
                  : themeInPreview.headerFooter.footer.shownISI === 'more-text'
                  ? '172px'
                  : themeInPreview.headerFooter.footer.shownISI === 'less-text'
                  ? '124px'
                  : '76px'
              }}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ThemePreview;
