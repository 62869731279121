import {
  SET_HEADERFOOTER,
  UPDATE_HEADERFOOTER_PROPERTY,
  UPDATE_HEADER_PROPERTY,
  UPDATE_FOOTER_PROPERTY,
  ADD_HEADER_COMPONENT,
  REMOVE_HEADER_COMPONENT,
  UPDATE_MENU_OVERLAY_PROPERTY,
  ADD_FOOTER_COMPONENT,
  REMOVE_FOOTER_COMPONENT

} from './actionTypes';

const headerFooterReducer = (state, action) => {
  switch (action.type) {
    case SET_HEADERFOOTER:
      return {
        ...action.payload.headerFooter
      };
    case UPDATE_HEADERFOOTER_PROPERTY:
      return {
        ...state,
        ...action.payload.updates
      };
    case UPDATE_HEADER_PROPERTY:
      return {
        ...state,
        header: {
          ...state.header,
          ...action.payload.updates
        }
      };
    case ADD_HEADER_COMPONENT:
      return {
        ...state,
        header: {
          ...state.header,
          components: [...state.header.components, action.payload.component]
        }
      };
    case REMOVE_HEADER_COMPONENT:
      return {
        ...state,
        header: {
          ...state.header,
          components: state.header.components.filter((component) => component !== action.payload.component)
        }
      };
    case UPDATE_MENU_OVERLAY_PROPERTY:
      return {
        ...state,
        header: {
          ...state.header,
          menuOverlayLayout: {
            ...state.header.menuOverlayLayout,
            ...action.payload.updates
          }
        }
      };
    case UPDATE_FOOTER_PROPERTY:
      return {
        ...state,
        footer: {
          ...state.footer,
          ...action.payload.updates
        }
      };
    case ADD_FOOTER_COMPONENT:
      return {
        ...state,
        footer: {
          ...state.footer,
          components: [...state.footer.components, action.payload.component]
        }
      };
    case REMOVE_FOOTER_COMPONENT:
      return {
        ...state,
        footer: {
          ...state.footer,
          components: state.footer.components.filter((component) => component !== action.payload.component)
        }
      };

    default:
      return state;
  }
};

export default headerFooterReducer;
