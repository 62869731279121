import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { calculateHF } from '../../utils/calculateHF';
import QooHeader from './QooHeader';
import QooFooter from './QooFooter';
import QooBlocksLayout from './QooBlocksLayout';

const QooDisplay = ({ qooInPreview, width, height, fadeOutHF, inQooBuilder }) => {
  const qooContainerRef = useRef();
  const [qooDimensions, setQooDimensions] = useState(null);

  useLayoutEffect(() => {
    // ovo je za phone preview u create Qoo
    // za konacan publish Qoo-a ce vrv trebati w/h u odnosu na window.screen.width/height
    if (qooContainerRef.current && !width && !height) {
      const w = qooContainerRef.current.clientWidth;
      const h = qooContainerRef.current.clientHeight;

      if (h / w < 2.2) {
        setQooDimensions({
          height: 0.85 * h,
          width: (0.85 * h) / 1.88
        });
      } else {
        setQooDimensions({
          height: 1.88 * w,
          width: w
        });
      }
    }
  }, []);

  useEffect(() => {
    if (width && height) {
      setQooDimensions({
        width,
        height
      });
    }
  }, []);

  let gridAlign, headerHeight, footerHeight;
  if (qooInPreview && qooInPreview.theme) {
    ({ gridAlign, headerHeight, footerHeight } = calculateHF(qooInPreview?.noHeaderFooter, {
      ...qooInPreview?.theme.headerFooter,
      header: {
        ...qooInPreview?.theme.headerFooter.header,
        noHeader: !qooInPreview.showHeader
      },
      footer: {
        ...qooInPreview?.theme.headerFooter.footer,
        noISI: !qooInPreview.showISI,
        noFooter: !qooInPreview.showFooter,
        shownISI:
          qooInPreview.ISISize === 'S'
            ? 'title-only'
            : qooInPreview.ISISize === 'M'
            ? 'less-text'
            : qooInPreview.ISISize === 'L'
            ? 'more-text'
            : qooInPreview?.theme.headerFooter.footer.shownISI
      }
    }));
  }

  return (
    <div
      ref={qooContainerRef}
      style={{
        height: height ? `${height}px` : '100%',
        backgroundColor: '#000',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      {qooDimensions && (
        <div
          style={{
            position: 'relative',
            boxSizing: 'content-box',
            width: qooDimensions.width,
            height: qooDimensions.height,
            display: 'flex',
            alignItems: gridAlign ? gridAlign : 'center',
            backgroundColor: qooInPreview.qooBackgroundImage
              ? ''
              : qooInPreview.qooBackgroundColor
              ? qooInPreview.qooBackgroundColor.hex
              : qooInPreview.theme.colorAssignment.qooBackground
              ? qooInPreview.theme.colorAssignment.qooBackground.hex
              : '#fff',
            backgroundImage: qooInPreview.qooBackgroundImage
              ? `url(${qooInPreview.qooBackgroundImage})`
              : '',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            border:
              qooInPreview.qooBorderColor &&
              qooInPreview.qooBorderThickness &&
              qooInPreview.qooBorderType
                ? `${qooInPreview.qooBorderThickness}em ${qooInPreview.qooBorderType} ${qooInPreview.qooBorderColor.hex}`
                : '1px solid #D5DFE8'
          }}>
          {qooInPreview.blocksLayout && qooInPreview.blocksLayout.length > 0 && (
            <QooBlocksLayout
              layout={qooInPreview.blocksLayout}
              gridWidth={qooDimensions.width}
              gridRowHeight={qooDimensions.width / 12}
            />
          )}
          {qooInPreview.showHeader &&
            qooInPreview.theme.headerFooter &&
            !qooInPreview.theme.headerFooter.header.noHeader && (
              <QooHeader
                headerHeight={headerHeight}
                header={qooInPreview.theme.headerFooter.header}
                gridRowHeight={qooDimensions.width / 12}
                gridWidth={qooDimensions.width}
                fadeOutHF={fadeOutHF}
              />
            )}
          {(qooInPreview.showISI || qooInPreview.showFooter) &&
            ((qooInPreview.theme?.headerFooter &&
              !qooInPreview.theme?.headerFooter?.footer?.noISI) ||
              !qooInPreview.theme?.headerFooter?.footer?.noFooter) && (
              <QooFooter
                footerHeight={footerHeight}
                footer={qooInPreview.theme.headerFooter.footer}
                gridRowHeight={qooDimensions.width / 12}
                gridWidth={qooDimensions.width}
                dontShowISI={!qooInPreview.showISI}
                dontShowFooter={!qooInPreview.showFooter}
                ISISizeInQoo={qooInPreview.ISISize}
                fadeOutHF={fadeOutHF}
                inQooBuilder={inQooBuilder ? inQooBuilder : false}
              />
            )}
        </div>
      )}
    </div>
  );
};

export default QooDisplay;
