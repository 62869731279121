import React, { useState } from 'react';
import { Button, Modal, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../utils/getCroppedImg';
import { notificationSuccess, notificationError } from '../../config/notificationOptions';
import Axios from 'axios';
import { ReactComponent as RotateLeftIcon } from '../../icons/carbon_rotate-clockwise.svg';
import { ReactComponent as RotateRightIcon } from '../../icons/carbon_rotate-counterclockwise.svg';

const ImageCropModal = ({
  imageCropModalVisible,
  setImageCropModalVisible,
  image,
  SERVER_URL,
  token,
  setNewImage
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState(false);

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const uploadImage = async (data) => {
    try {
      const res = await Axios.post(`${SERVER_URL}/upload-image`, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });

      notification.success({
        message: 'Image uploaded',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration,
      });

      setNewImage(res.data.image.mdUrl);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration,
      });
    }
  };

  const cropImage = async () => {
    setLoading(true);

    try {
      const { file, url } = await getCroppedImg(image, croppedAreaPixels, rotation);
      const data = new FormData();
      data.append('image', file);

      await uploadImage(data);
      setLoading(false);
      setImageCropModalVisible(false);
    } catch (error) {
      console.log(error);
      notification.error({
        message: error.message,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration,
      });
      setLoading(false);
    }

  };

  return (
    <Modal
      visible={imageCropModalVisible}
      onCancel={() => setImageCropModalVisible(false)}
      footer={null}
      width={1200}
      style={{ top: 120 }}
    >
      <div className="image-crop-modal">
        <div className="image-crop-modal__cropper">
          <Cropper
            image={image}
            aspect={1}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={cropComplete}
          />
        </div>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '32px'
            }}
          >
            <LoadingOutlined
              style={{
                fontSize: '32px',
              }}
            />
          </div>
        ) : (
          <>
            <div className="image-crop-modal__tools">
              <h3 style={{ marginTop: '15px', marginBottom: '10px' }} className='pre-title text-background__label'>Rotate image 90°</h3>
              <div className='text-padding'>
                <div className='text-padding__icons'>
                  <div
                    className='h-f-create-new__text-align-btn'
                    onClick={() => setRotation(previous => {
                      if (previous < 270) {
                        return previous + 90;
                      } else {
                        return 0;
                      }
                    })}
                  >
                    <RotateLeftIcon />
                  </div>
                  <div
                    className='h-f-create-new__text-align-btn'
                    onClick={() => setRotation(previous => {
                      if (previous > 0) {
                        return previous - 90;
                      } else {
                        return 270;
                      }
                    })}
                  >
                    <RotateRightIcon />
                  </div>
                </div>
              </div>
            </div>
            <div className="image-crop-modal__bottom">
              <Button
                className='btn btn-small'
                onClick={() => setImageCropModalVisible(false)}
              >
                Cancel
              </Button>
              <Button
                type='primary'
                className='btn btn-small'
                onClick={cropImage}
              >
                Crop Image
              </Button>
            </div>
          </>
        )}

      </div>
    </Modal>
  );
};

export default ImageCropModal;