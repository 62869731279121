import { LoadingOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import ExportToExcel from '../csv/ExportExcel';
import NoData from '../elements/NoData';

function HomebaseTable({ dataSource, columns, loading, setLoading, dataForExcel, excelFileName }) {
  // const [rows, setRows] = useState(3);

  useEffect(() => {
    setLoading(true);
    dataSource && setLoading(false);
  }, [dataSource, setLoading]);

  const locale = {
    emptyText: <NoData noMargin />
  };

  return (
    <div className="homebase__table">
      <ExportToExcel
        csvData={dataForExcel}
        fileName={excelFileName}
        propsToRemove={['_id', 'updatedAt', '__v']}
        disabled={dataForExcel && Object.keys(dataForExcel).length > 0 ? false : true}
        format="custom_name"
      />
      <Table
        size="middle"
        scroll={{ y: 200 }}
        bordered
        // dataSource={dataSource?.slice(0, rows)}
        dataSource={dataSource}
        columns={columns}
        rowKey="_id"
        // pagination={{
        //   defaultPageSize: 5,
        //   position: ['bottomCenter'],
        //   showSizeChanger: false,
        //   hideOnSinglePage: true
        // }}
        pagination={false}
        loading={{
          spinning: loading,
          indicator: <LoadingOutlined spin style={{ color: '#4962E2' }} />
        }}
        locale={locale}
      />
      {/* {rows === dataSource?.length ? (
        <span className="homebase__view-more-btn" type="secondary" onClick={() => setRows(3)}>
          View less
        </span>
      ) : (
        <span className="homebase__view-more-btn" onClick={() => setRows(dataSource?.length)}>
          View more
        </span>
      )} */}
    </div>
  );
}

export default HomebaseTable;
