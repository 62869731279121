import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as DotsMenuIcon } from '../../icons/ph_dots-three.svg';
import { ReactComponent as EditIcon } from '../../icons/ph_note-pencil-light.svg';
import { ReactComponent as DuplicateIcon } from '../../icons/ph_copy-light.svg';
import { ReactComponent as RemoveIcon } from '../../icons/ph_trash-light.svg';
import { useHistory } from 'react-router-dom';

const QooCardDropdownMenu = ({user, qoo, duplicateQoo, removeQoo, qoolection, isCreateNew }) => {
  const dropdownRef = useRef();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleClickOutside = (e) => {
    if (
      dropdownVisible === true &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [dropdownVisible]);

  const handleRemove = () => {
    setDropdownVisible(false);
    removeQoo(qoo._id);
  };

  const handleDuplicate = async () => {
    setDropdownVisible(false);
    duplicateQoo(qoo);
  };

  const history = useHistory();

  return (
    <div
      className={
        dropdownVisible
          ? 'qoolection__qoo-card-menu-icon qoolection__qoo-card-menu-icon--visible'
          : 'qoolection__qoo-card-menu-icon'
      }
      onClick={() => setDropdownVisible(true)}>
      <DotsMenuIcon />
      {dropdownVisible && (
        <div ref={dropdownRef} className="qoolection__qoo-card-dropdown">
          <div
            className="qoolection__qoo-card-dropdown-item"
            onClick={async () => {
              await user.handleRefreshToken();  
              history.push(`/admin/edit-qoo/${qoo._id}`, {
                fromQoolection: {
                  qoolection,
                  isCreateNew
                }
              
              })
            }
            }>
            <EditIcon />
            <span>Edit</span>
          </div>
          {!qoo.isBlankQoo && (
            <div
              className="qoolection__qoo-card-dropdown-item"
              onClick={(e) => {
                e.stopPropagation();
                handleDuplicate();
              }}>
              <DuplicateIcon />
              <span>Duplicate</span>
            </div>
          )}
          <div
            className="qoolection__qoo-card-dropdown-item"
            onClick={(e) => {
              e.stopPropagation();
              handleRemove();
            }}>
            <RemoveIcon />
            <span>Remove</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default QooCardDropdownMenu;
