import React from 'react';
import { Collapse } from 'antd';
import { ReactComponent as ExpandIcon } from '../../icons/ph_arrows-out-simple-light.svg';
import { ReactComponent as CollapseIcon } from '../../icons/ph_arrows-in-simple-light.svg';
import { ReactComponent as ReferenceIcon } from '../../icons/references.svg';
import { ReactComponent as FootnoteIcon } from '../../icons/Footnotes.svg';
import { ReactComponent as AbbreviationIcon } from '../../icons/Abbreviations.svg';
import { ReactComponent as TrashIcon } from '../../icons/ph_trash-light.svg';
import { ReactComponent as EditIcon } from '../../icons/ph_pencil-simple-light.svg';

const { Panel } = Collapse;

const EndnotesPanel = ({ endnotes, inSidebar, collapseSidebar, dockToRightSidebar, rightPanel, setHoveredEndnote, removeCallback, editCallback }) => {

  return (
    <div className={inSidebar ? "layers-panel layers-panel--in-sidebar" : "layers-panel"}>
      <Collapse
        ghost
        defaultActiveKey={rightPanel === 'endnotes' ? '1' : null}
      >
        <Panel
          header={(
            <h3 className='pre-title'>
              Endnotes
            </h3>
          )}
          key='1'
          collapsible='header'
          extra={inSidebar ? (
            <CollapseIcon onClick={() => collapseSidebar('endnotes')} />
          ) : (
            <ExpandIcon onClick={dockToRightSidebar} />
          )}
          showArrow={false}
        >
          <div className="layers-panel__list" >
            {endnotes?.length > 0 && endnotes.map((endnote, index, arr) => {
              return (
                <div
                  key={endnote._id}
                  className='layers-panel__item'

                  onMouseEnter={() => setHoveredEndnote(endnote._id)}
                  onMouseLeave={() => setHoveredEndnote(null)}
                >

                  <div className="layers-panel__block-type-icon">
                    {endnote.abbreviation
                      ? <AbbreviationIcon /> : endnote.reference
                        ? <ReferenceIcon /> : <FootnoteIcon />
                    }
                  </div>
                  <span className="layers-panel__block-type-name">
                    {endnote.abbreviation ? endnote.abbreviation : endnote.title}
                  </span>
                  <EditIcon
                    className='layers-panel__delete-icon'
                    onClick={() => editCallback(endnote)}
                  />
                  <TrashIcon
                    className='layers-panel__delete-icon'
                    onClick={() => removeCallback(endnote)}
                  />
                </div>
              );
            })}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default EndnotesPanel;