import React, { useContext, useState, useEffect } from 'react';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';
import { Table, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../icons/table-edit.svg';
import { ReactComponent as DeleteIcon } from '../../icons/table-delete.svg';
import { ReactComponent as SortIcon } from '../../icons/arrow-down.svg';
import NoData from '../elements/NoData';

const MyFilesTable = ({ data, editPath, editHandler, deleteHandler, title }) => {
  const user = useContext(UserContext);
  const [sortDirection, setSortDirection] = useState(false);

  let searchInput;

  const columns = [
    {
      title: 'Title',
      dataIndex: 'originalname'
    },
    // {
    //   title: "Author",
    //   dataIndex: "uploadedBy",
    //   render: (text, record) => (
    //     <span>{record.uploadedBy.firstName} {record.uploadedBy.lastName}</span>
    //   )
    // },
    {
      title: (
        <div onClick={() => setSortDirection(!sortDirection)}>
          Date Added{' '}
          {sortDirection ? (
            <SortIcon
              style={{
                marginLeft: '8px',
                transform: 'rotate(180deg)',
                transition: '.2s ease-in-out transform'
              }}
            />
          ) : (
            <SortIcon style={{ marginLeft: '8px', transition: '.2s ease-in-out transform' }} />
          )}
        </div>
      ),
      dataIndex: 'createdAt',
      // ...getColumnSearchProps("title"),
      sorter: {
        compare: (a, b) => String(a.createdAt).localeCompare(String(b.createdAt))
      },
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      render: (createdAt) => (
        <span>
          {createdAt &&
            createdAt.split('T')[0].split('-')[1] +
              '/' +
              createdAt.split('T')[0].split('-')[2] +
              '/' +
              createdAt.split('T')[0].split('-')[0]}
        </span>
      )
    }
  ];

  columns.push({
    title: 'Actions',
    render: (text, record) => (
      <div
        style={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px'
        }}>
        {/* <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <Link to={editPath + record._id}>
            <EditIcon title={`Edit ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: '#90989E' }} />
          </Link>
        </div> */}

        <div style={{ margin: '2px', padding: '4px' }}>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement="left"
            title="Delete PDF file?"
            onConfirm={() => {
              console.log('record._id: ', record._id);
              deleteHandler(record._id);
            }}
            okText="Ok"
            cancelText="Cancel">
            <DeleteIcon
              title={`Delete ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: '#90989E', cursor: 'pointer' }}
            />
          </Popconfirm>
        </div>
      </div>
    )
  });

  const locale = {
    emptyText: <NoData />
  };

  return (
    <div className="table-manage">
      <Table
        size="middle"
        bordered
        dataSource={data}
        columns={columns}
        rowKey="_id"
        pagination={{
          defaultPageSize: 10,
          position: ['bottomCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true
        }}
        locale={locale}
      />
    </div>
  );
};

export default MyFilesTable;
