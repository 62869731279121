import React, { useContext, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { UserContext } from '../App';
import { SERVER_URL } from '../config';
import useAxios from '../hooks/useAxios';
import HeaderComponent from '../components/base/HeaderComponent';
import HomebaseWidget from '../components/homebase/HomebaseWidget';
import HomebaseTable from '../components/tables/HomebaseTable';
import HomebaseStatsList from '../components/homebase/HomebaseStatsList';
import QoolectionCard from './qoolections/QoolectionCard';
import QoolectionPhonePreviewModal from './qoolections/QoolectionPhonePreviewModal';
import { LoadingOutlined } from '@ant-design/icons';

const { Content } = Layout;

const Home = () => {
  const user = useContext(UserContext);
  // console.log('user: ', user);

  const [qoolectionForPreview, setQoolectionForPreview] = useState(null);
  const [studyData, fetchStudyData] = useAxios('', null, user.data.token, 'get');
  const [clientBranches, fetchClientBranches] = useAxios('', null, user.data.token, 'get');
  // const [personnel, fetchPersonnel] = useAxios('', null, user.data.token, 'get');
  const [qoolections, fetchQoolections] = useAxios('', null, user.data.token, 'get');

  /* Fetch Study Data (Participants) */
  useEffect(() => {
    if (user?.study && user.study !== undefined) {
      fetchStudyData(`${SERVER_URL}/get-study-data/${user?.study}`, null, user.data.token, 'get');
    }
  }, [fetchStudyData, user]);
  // console.log('studyData: ', studyData);

  /* Fetch Client Branches (Sites) */
  useEffect(() => {
    const isTopLevelUser = user?.data?.clientBranch?.level === 1 ? true : false;
    const filter = { _id: user?.data?.clientBranch?._id };
    fetchClientBranches(
      isTopLevelUser
        ? `${SERVER_URL}/client-branches`
        : `${SERVER_URL}/client-branches?filter=${JSON.stringify(filter)}`,
      null,
      user.data.token,
      'get'
    );
  }, [fetchClientBranches, user]);
  // console.log('clientBranches: ', clientBranches);

  /* Fetch Users */
  // useEffect(() => {
  //   fetchPersonnel(`${SERVER_URL}/get-all-client-monitors`, null, user.data.token, 'get');
  // }, [fetchPersonnel, user]);
  // console.log('personnel: ', personnel);

  /* Fetch Qoolections for Active Qoolections Section */
  const [activeQoolectionsLoading, setActiveQoolectionsLoading] = useState(false);
  useEffect(() => {
    if (user?.study && user.study !== undefined) {
      fetchQoolections(
        `${SERVER_URL}/get-active-qoolections-for-user/${user?.study}`,
        null,
        user.data.token,
        'get'
      );
    }
  }, [fetchQoolections, user]);

  /* Handle Active Qoolections Loading */
  useEffect(() => {
    setActiveQoolectionsLoading(true);
    qoolections?.data?.items && setActiveQoolectionsLoading(false);
  }, [qoolections]);
  // console.log('qoolections: ', qoolections);

  /* Site Status Table Data */
  const [siteStatusDataLoading, setSiteStatusDataLoading] = useState(false);
  const siteStatusDataSource = clientBranches.data?.items.map((clientBranch) => ({
    branchName: clientBranch.branchName,
    // noOfPersonnel: personnel?.data?.filter((employee) => employee.clientBranch === clientBranch._id).length,
    status: studyData.data?.items?.some(
      (studyParticipant) =>
        studyParticipant.site._id === clientBranch._id && studyParticipant.studyStatus === 'pending'
    )
      ? 'Active'
      : 'Inactive',
    participants: studyData.data?.items?.filter(
      (studyParticipant) => studyParticipant.site._id === clientBranch._id
    ).length,
    completed: studyData.data?.items?.filter(
      (studyParticipant) =>
        studyParticipant.site._id === clientBranch._id &&
        studyParticipant.studyStatus === 'finished'
    ).length
  }));
  // console.log('siteStatusDataSource: ', siteStatusDataSource);

  /* Site Status Table Columns */
  const siteStatusColumns = [
    {
      title: 'Site',
      dataIndex: 'branchName'
    },
    // {
    //   title: '# Personnel',
    //   dataIndex: 'noOfPersonnel'
    // },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Participants',
      dataIndex: 'participants'
    },
    {
      title: 'Completed',
      dataIndex: 'completed'
    }
  ];

  /* Find answers for intensity for each patient */
  const patientAnswersForIntensity = studyData.data?.items.map((studyParticipant) =>
    studyParticipant.patientData?.filter(
      (event) =>
        event.question?.question === 'Rate the Intensity of your last vomiting/retching episode:'
    )
  );
  // console.log('patientAnswersForIntensity: ', patientAnswersForIntensity);

  /* Calculate average intensity for each patient */
  const averageIntensityPerPatient = patientAnswersForIntensity?.map((singlePatientAnswers) => {
    let sumOfSinglePatientAnswers = 0;
    for (let i = 0; i < singlePatientAnswers?.length; i++) {
      sumOfSinglePatientAnswers += Number(singlePatientAnswers[i]?.answer);
    }
    return {
      patientId: singlePatientAnswers && singlePatientAnswers[0]?.patient,
      averageIntensity:
        sumOfSinglePatientAnswers / singlePatientAnswers?.length !== 'NaN'
          ? (sumOfSinglePatientAnswers / singlePatientAnswers?.length).toFixed(2)
          : '-'
    };
  });
  // console.log('averageIntensityPerPatient: ', averageIntensityPerPatient);

  /* All Average Intensity values for each patient */
  const allAverages = averageIntensityPerPatient
    ?.filter(
      (averageIntensitySinglePatient) => averageIntensitySinglePatient.averageIntensity !== 'NaN'
    )
    .map((averageIntensitySinglePatient) => Number(averageIntensitySinglePatient.averageIntensity));
  // console.log('allAverages: ', allAverages);

  /* Average Intensity for Q Stats Calculation */
  // const averageIntensityForAll = allAverages?.reduce((a, b) => a + b, 0) / allAverages?.length;
  // console.log('averageIntensityForAll: ', averageIntensityForAll);

  /* Participant Status Table Data */
  const [participantStatusDataLoading, setParticipantStatusDataLoading] = useState(false);
  const participantStatusDataSource = studyData.data?.items.map((studyParticipant) => ({
    participantId: studyParticipant.deviceId,
    status: studyParticipant.studyStatus,
    vomited: studyParticipant.patientData?.some(
      (event) => event.answer === 'Yes' && event.qoo?.title === 'Main: Qoo 1'
    )
      ? 'Yes'
      : 'No',
    medicated: studyParticipant.patientEvent?.some(
      (event) => event.eventType === 'EndStudy' && event.qoo?.title === 'Main: Qoo 2'
    )
      ? 'Yes'
      : 'No',
    intensity: Number(
      averageIntensityPerPatient
        .filter(
          (averageIntensityForSinglePatient) =>
            averageIntensityForSinglePatient.patientId === studyParticipant._id
        )
        .map(
          (averageIntensityForSinglePatient) => averageIntensityForSinglePatient.averageIntensity
        )[0]
    ),
    site: studyParticipant.site.branchName
  }));
  // console.log('participantStatusDataSource: ', participantStatusDataSource);

  /* Participant Status Table Columns */
  const participantStatusColumns = [
    {
      title: 'Participant ID',
      dataIndex: 'participantId'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => <span style={{ textTransform: 'capitalize' }}>{status}</span>
    },
    {
      title: 'Vomited?',
      dataIndex: 'vomited'
    },
    {
      title: 'Medicated?',
      dataIndex: 'medicated'
    },
    {
      title: 'Intensity',
      dataIndex: 'intensity'
    },
    {
      title: 'Site',
      dataIndex: 'site'
    }
  ];

  // const targetParticipants = studyData?.data?.items?.length;
  const targetParticipants = user?.data?.client?.totalPatientLicences;
  const vomitedParticipants = studyData?.data?.items?.filter((studyParticipant) =>
    studyParticipant.patientData?.some(
      (event) => event.answer === 'Yes' && event.qoo?.title === 'Main: Qoo 1'
    )
  ).length;
  const medicatedParticipants = studyData?.data?.items?.filter((studyParticipant) =>
    studyParticipant.patientEvent?.some(
      (event) => event.eventType === 'EndStudy' && event.qoo?.title === 'Main: Qoo 2'
    )
  ).length;

  // const noOfActiveSites = clientBranches?.data?.items?.map((clientBranch) => {
  //   return {
  //     branch: clientBranch._id,
  //     active: studyData?.data?.items?.filter(
  //       (patient) =>
  //         patient.site._id === clientBranch._id &&
  //         patient?.pairingStatus === 'finished' &&
  //         patient?.studyStatus !== 'finished'
  //     ).length
  //   };
  // });
  // console.log('noOfActiveSites: ', noOfActiveSites);

  // Q Stats Data
  const [qStatsActiveLoading, setQStatsActiveLoading] = useState(false);
  const qStatsActive = {
    targetParticipants: targetParticipants,
    // activeParticipants: studyData?.data?.items?.filter(
    //   (patient) => patient?.pairingStatus === 'finished' && patient?.studyStatus !== 'finished'
    // ).length,
    // activeSites: noOfActiveSites?.filter((site) => site?.active > 0).length,
    // averageIntensity:
    //   averageIntensityForAll.toFixed(1) !== 'NaN' ? averageIntensityForAll.toFixed(1) : '-'
    inDemoParticipants: studyData?.data?.items?.filter(
      (participant) => participant.studyStatus === 'in-demo'
    ).length,
    inStudyParticipants: studyData?.data?.items?.filter(
      (participant) => participant.studyStatus === 'in-study'
    ).length,
    completedParticipants: studyData?.data?.items?.filter(
      (participant) => participant.studyStatus === 'completed'
    ).length
  };
  // console.log('qStatsActive: ', qStatsActive);

  const [qStatsOutcomesLoading, setQStatsOutcomesLoading] = useState(false);
  const qStatsOutcomes = {
    vomitedParticipants: vomitedParticipants,
    vomited: Math.round((vomitedParticipants / targetParticipants) * 100),
    medicatedParticipants: medicatedParticipants,
    medicated: Math.round((medicatedParticipants / targetParticipants) * 100)
  };
  // console.log('qStatsOutcomes: ', qStatsOutcomes);

  // formatted data for XLSX export
  const siteStatusDataForExcel = siteStatusDataSource?.map((site) => ({
    site: site.branchName,
    status: site.status,
    participants: site.participants,
    completed: site.completed
  }));
  // console.log('participantStatusDataSource: ', participantStatusDataSource);

  return (
    <Layout>
      <HeaderComponent
        text={`Welcome to QClinical, ${user.data.firstName} ${user.data.lastName}`}
      />
      <Content className="homebase__wrapper">
        <div className="homebase__content">
          <section className="homebase__left">
            <HomebaseWidget
              title={'Site status'}
              tooltip={
                <div className="homebase__tooltip">
                  <p>
                    <strong>Participants</strong> shows the # of clinical trial participants that
                    have enrolled in the site and passed Demo Mode for QClinical.
                  </p>
                  <p>
                    <strong>Completed</strong> shows the # of clinical trial participants that have
                    completed the QClinical portion of the trial.
                  </p>
                </div>
              }>
              <HomebaseTable
                dataSource={siteStatusDataSource}
                columns={siteStatusColumns}
                loading={siteStatusDataLoading}
                setLoading={setSiteStatusDataLoading}
                dataForExcel={siteStatusDataForExcel}
                excelFileName={'QClinical-Site-Status_' + new Date().getTime()}
              />
            </HomebaseWidget>
            <HomebaseWidget
              title={'Participant status'}
              tooltip={
                <div className="homebase__tooltip">
                  <p>
                    <strong>Vomited</strong> shows the # of clinical trial participants that have
                    reported at least 1 vomiting episode in QClinical.
                  </p>
                  <p>
                    <strong>Medicated</strong> shows the # of clinical trial participants that have
                    reported taking their study medication.
                  </p>
                  <p>
                    <strong>Intensity</strong> shows the intensity of their last vomiting episode,
                    as reported on the Intensity of Attack ePRO question.
                  </p>
                </div>
              }>
              <HomebaseTable
                dataSource={participantStatusDataSource?.map((singleParticipant) => {
                  return {
                    ...singleParticipant,
                    intensity:
                      singleParticipant.intensity < 1.5
                        ? 'None'
                        : singleParticipant.intensity >= 1.5 && singleParticipant.intensity < 2.5
                        ? 'Low'
                        : singleParticipant.intensity >= 2.5 && singleParticipant.intensity < 3.5
                        ? 'Moderate'
                        : singleParticipant.intensity >= 3.5
                        ? 'Severe'
                        : '-'
                  };
                })}
                columns={participantStatusColumns}
                loading={participantStatusDataLoading}
                setLoading={setParticipantStatusDataLoading}
                dataForExcel={participantStatusDataSource}
                excelFileName={'QClinical-Participant-Status_' + new Date().getTime()}
              />
            </HomebaseWidget>
          </section>
          <section className="homebase__right">
            <HomebaseWidget title={'Q Stats'}>
              <div className="homebase__list-group">
                <HomebaseStatsList
                  group={'basic'}
                  data={qStatsActive}
                  loading={qStatsActiveLoading}
                  setLoading={setQStatsActiveLoading}
                />
                <HomebaseStatsList
                  group={'outcomes'}
                  data={qStatsOutcomes}
                  loading={qStatsOutcomesLoading}
                  setLoading={setQStatsOutcomesLoading}
                />
              </div>
            </HomebaseWidget>
            <HomebaseWidget title={'Active Qoolections'}>
              {activeQoolectionsLoading ? (
                <LoadingOutlined spin style={{ color: '#4962E2' }} />
              ) : !activeQoolectionsLoading && qoolections?.data?.items?.length === 0 ? (
                <span>No Qoolections are active</span>
              ) : (
                <div className="homebase__qoolections">
                  {qoolections?.data?.items?.map((qoolection) => (
                    <div
                      key={qoolection._id}
                      onClick={() => setQoolectionForPreview(qoolection)}
                      style={{ cursor: 'pointer' }}>
                      <QoolectionCard
                        qoolection={qoolection}
                        width={84}
                        height={160}
                        homebase={true}
                      />
                    </div>
                  ))}
                </div>
              )}
            </HomebaseWidget>
          </section>
        </div>
        {qoolectionForPreview && (
          <QoolectionPhonePreviewModal
            visible={qoolectionForPreview}
            closeModal={() => setQoolectionForPreview(null)}
            qoolection={qoolectionForPreview}
          />
        )}
      </Content>
    </Layout>
  );
};

export default Home;
