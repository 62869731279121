import React, { useEffect, useState, useContext } from 'react';
import { Upload, notification, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Axios from 'axios';
import LogoCard from './LogoCard';
import { ReactComponent as Plus } from '../../icons/plus.svg';
import { notificationError } from '../../config/notificationOptions';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';

const LogoTab = ({ themeInPreview, dispatch, isNew }) => {
  const [logos, setLogos] = useState({ isLoading: true });
  const [rerenderState, setRerenderState] = useState(0);
  const currentuser = useContext(UserContext);
  const token = currentuser.data.token;

  const isSuperAdmin = currentuser.data.role.some(
      (item) => item === 'superadmin'
  );
  const getLogos = async () => {
    const filter = currentuser.data.role.includes('superadmin') ? {uploadedBy: currentuser.data.id} : {team: currentuser.selectedTeam, study: currentuser.study}
    if(isSuperAdmin || (!isSuperAdmin && currentuser.study)){
      const res = await Axios.get(`${SERVER_URL}/logos?filter=${JSON.stringify(filter)}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });

      const log = await res.data;
      setLogos(log);
    }

  };

  useEffect(() => {
    getLogos();
  }, [rerenderState, currentuser]);

  return (
    <>
      {logos.isLoading && (
        <div className='loader-container'>
          <LoadingOutlined spin style={{ fontSize: '60px' }} />
        </div>
      )}
      {!logos.isLoading && (
        <div className='logo-tab'>
          {!isNew &&
            <h3 className='pre-title pre-title--dark'>Logos</h3>
          }

          <div className='logo-tab__logos'>
            <Upload
              action={`${SERVER_URL}/upload-logo`}
              accept='.png,.svg'
              name='logo'
              // getValueFromEvent={normFile}
              headers={{
                type: 'logo',
                resize: true,
                // resizemob: false,
                height: 500,
                Authorization: `Bearer ${token}`,
                uri: 'public/images/logo/',
                team: currentuser.selectedTeam,
                study: currentuser.study,
                // urimob: imageSavePath + 'mob/',
              }}
              beforeUpload={async (file) => {
                const isAllowedFormat = ['image/svg+xml', 'image/png'].includes(file.type);
                if (!isAllowedFormat) {
                  console.log('ONLY SVG PNG');
                  notification.error({
                    message: 'You can only upload SVG or PNG file!',
                    placement: 'bottomRight',
                    icon: notificationError.icon,
                    className: notificationError.className,
                    duration: notificationError.duration,
                  });
                }
                const isAllowedSize = file.size / 1024 / 1024 < 5;
                if (!isAllowedSize) {
                  notification.error({
                    message: 'Image must smaller than 5MB!',
                    placement: 'bottomRight',
                    icon: notificationError.icon,
                    className: notificationError.className,
                    duration: notificationError.duration,
                  });
                }
                return isAllowedFormat && isAllowedSize;
              }}
              onChange={({ file }) => {
                if (file.status === 'done') {
                  if (file.response && file.response.file) {
                    message.success(`${file.response.file.originalname} file uploaded successfully.`);
                    setRerenderState((previous) => previous + 1);
                  }
                } else if (file.status === 'error') {
                  message.error(`${file.response.file.originalname} file upload failed.`);
                }
              }}
              showUploadList={false}
            >
              <div className='logo-tab__upload'>
                <Plus />
              </div>
            </Upload>
            {logos &&
              logos.length > 0 &&
              logos.map((logo) => {
                return (
                  <LogoCard
                    key={logo._id}
                    logo={logo}
                    selected={themeInPreview?.headerFooter?.header?.logo === logo.logoUrl}
                    dispatch={dispatch}
                    setRerenderState={setRerenderState}
                    SERVER_URL={SERVER_URL}
                    token={token}
                  />
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default LogoTab;
