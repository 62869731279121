import ROUTES from './routeConfig';
import BLOCK_TYPES_ICONS from './blockTypesIcons';
import HOMEBASE_STATS from './homebaseStats';

const SERVER_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PROD_URL
    : process.env.REACT_APP_DEV_URL;
const SESSION_ACTIVITY_TIMEOUT =
  process.env.SESSION_ACTIVITY_TIMEOUT || 3600000;

export {
  ROUTES,
  SERVER_URL,
  BLOCK_TYPES_ICONS,
  HOMEBASE_STATS,
  SESSION_ACTIVITY_TIMEOUT,
};
