import React, { useContext, useEffect, useReducer, useState } from 'react';
import { UserContext } from '../../App';
import { notification, Layout, Select, Input } from 'antd';
import HeaderComponent from '../../components/base/HeaderComponent';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import CustomSwitch from '../../components/elements/CustomSwitch';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import Table from '../../components/tables/QoolectionsTable';
import { LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import QoolectionsGrid from '../../components/grids/QoolectionsGrid';
import QoolectionPreviewModal from './QoolectionPhonePreviewModal';
import EventModal from './EventModal';
import ApprovalModal from './ApprovalModal';
import QoolectionHistoryModal from './QoolectionHistoryModal';
import SendQoolectionModal from './SendQoolectionModal';
import ExportQoolectionPdfModal from './ExportQoolectionPdfModal';
import CollaboratorsModal from './collaborators/CollaboratorsModal';
// import { EDIT_QOOLECTION_COLLABORATOR_ACCESS } from '../../reducers/qoolection/actionTypes';
// import qoolectionReducer from '../../reducers/qoolection/qoolectionReducer';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'qoos'];
const statuses = [
  'draft',
  'waiting-approval',
  'in-approval',
  'approved',
  'change_required',
  'shared',
  'published',
  'retired'
];

const Qoolections = () => {
  const history = useHistory();
  const [stateForRefetchingData, setStateForRefetchingData] = useState(1);

  const currentuser = useContext(UserContext);
  // console.log('currentuser: ', currentuser);
  const [qoolections, fetchQoolections] = useAxios(
    '',
    [],
    currentuser.data.token,
    'get',
    stateForRefetchingData
  );
  const [listView, setListView] = useState(true);
  const [filters, setFilters] = useState(null);
  const [offset, setOffset] = useState(0);
  const [themes, fetchThemes] = useAxios('', [], currentuser.data.token, 'get');
  const [categories, fetchCategories] = useAxios('', [], currentuser.data.token, 'get');
  const [audiences, fetchAudiences] = useAxios('', [], currentuser.data.token, 'get');
  const [qoolectionTypes, fetchQoolectionTypes] = useAxios('', {}, currentuser.data.token, 'get');
  const [contacts, fetchContacts] = useAxios('', [], currentuser.data.token, 'get');
  const [qoolectionInModalPreview, setQoolectionInModalPreview] = useState(null);
  const [qoolectionForApproval, setQoolectionForApproval] = useState(null);
  const [qoolectionForChecking, setQoolectionForChecking] = useState(null);
  const [qoolectionForExport, setQoolectionForExport] = useState(null);
  const [qoolectionForSending, setQoolectionForSending] = useState(null);
  const [qoolectionHistory, setQoolectionHistory] = useState([]);
  const [qoolectionHistoryModalVisible, setQoolectionHistoryModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approveQoolection, setApproveQoolection] = useState(false);
  const [veevaConnected, setVeevaConnected] = useState(false);
  const [collaboratorsQoolection, setCollaboratorsQoolection] = useState();
  const [collaboratorEligableUsers, setCollaboratorEligableUsers] = useState();
  const [eventErrors, setEventErrors] = useState(false);
  const [eventErrorList, setEventErrorList] = useState([]);
  // console.log('collaboratorEligableUsers: ', collaboratorEligableUsers);

  useEffect(() => {
    fetchThemes(`${SERVER_URL}/themes`, []);
    fetchCategories(`${SERVER_URL}/categories`, {});
    fetchAudiences(`${SERVER_URL}/audiences`, {});
    fetchQoolectionTypes(`${SERVER_URL}/qoolection-types`, {});
    // fetchContacts(`${SERVER_URL}/contacts`, []);
  }, [
    qoolections,
    fetchThemes,
    fetchCategories,
    fetchAudiences,
    fetchQoolectionTypes,
    fetchContacts,
    collaboratorsQoolection
  ]);

  const fetchUsersEligableForCollaborators = async (qoolectionId) => {
    try {
      const response = await Axios.get(
        `${SERVER_URL}/get-all-collaborator-users-for-qoolection/${qoolectionId}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      // console.log('response: ', response);
      setCollaboratorEligableUsers(response);
      // return response;
    } catch (error) {
      console.log('error in fetchUsersEligableForCollaborators: ', error);
    }
  };

  useEffect(() => {
    // fetchCollaboratorEligableUsers(
    //   // `${SERVER_URL}/users?filter=${JSON.stringify(collaboratorUsersFilter)}`
    //   `${SERVER_URL}/get-all-collaborator-users-for-qoolection/${
    //     collaboratorsQoolection?._id ||
    //     (qoolections?.data?.items?.length > 0 && qoolections?.data?.items[0]?._id)
    //   }`
    // );
    if (collaboratorsQoolection) {
      fetchUsersEligableForCollaborators(collaboratorsQoolection?._id);
    }
  }, [collaboratorsQoolection]);

  // console.log('collaboratorEligableUsers: ', collaboratorEligableUsers);

  const limit = 10;
  const isSuperAdmin = currentuser.data.role.some((item) => item === 'superadmin');
  useEffect(() => {
    let filter;
    if (filters) {
      const removedNullValues = Object.fromEntries(
        Object.entries(filters).filter(
          ([key, value]) => value != null && (!Array.isArray(value) || value.length !== 0)
        )
      );
      filter = JSON.stringify(removedNullValues);
    }
    const route = filters
      ? `${SERVER_URL}/qoolections?filter=${filter}&offset=${offset * limit}&limit=${limit}&team=${
          currentuser.selectedTeam
        }&study=${currentuser.study}`
      : `${SERVER_URL}/qoolections?offset=${offset * limit}&limit=${limit}&team=${
          currentuser.selectedTeam
        }&study=${currentuser.study}`;
    if ((!isSuperAdmin && currentuser.study) || isSuperAdmin) {
      fetchQoolections(route, [], currentuser.data.token, 'get', stateForRefetchingData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchQoolections, filters, offset, stateForRefetchingData, currentuser]);

  let columnKeys;
  if (qoolections.data && qoolections.data.items && qoolections.data.items.length > 0) {
    const keys = Object.keys(qoolections.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (qoolections.data && qoolections.data.items && qoolections.data.items.length > 0) {
    tableData = qoolections.data.items.map((item) => item);
  }

  const handleChangeDebounced = _.debounce(async (e) => {
    setOffset(0);
    setFilters((previous) => ({ ...previous, title: e.target.value }));
  }, 500);

  const handleChange = (e) => {
    e.persist();
    handleChangeDebounced(e);
  };

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/qoolections/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      // notification.success({
      //   message: 'Item is deleted.',
      //   placement: 'bottomRight',
      //   icon: notificationSuccess.icon,
      //   className: notificationSuccess.className,
      //   duration: notificationSuccess.duration,
      // });
      setStateForRefetchingData((previous) => previous + 1);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const editDataHandler = (qoolectionId) => {
    history.push('/admin/edit-qoolection/' + qoolectionId);
  };

  const duplicateQoolection = async (qoolection) => {
    const submitData = {
      ...qoolection,
      title: `${qoolection.title}(2)`
    };
    delete submitData.owner;
    delete submitData.status;
    delete submitData.updatedAt;
    delete submitData.createdAt;
    delete submitData.veevaBinderId;
    delete submitData.veevaDocumentId;
    delete submitData.publishedUrl;
    delete submitData.publishedToken;
    delete submitData.publishedQRCode;
    delete submitData.publishedQRCodeURL;
    delete submitData.approvalProcess;
    delete submitData.timeInApproval;
    delete submitData.timeApproved;
    delete submitData.timePublished;
    delete submitData.timeRetired;
    delete submitData.version;
    delete submitData.__v;
    delete submitData._id;
    submitData.timeDrafted = new Date();

    try {
      const response = await Axios.post(
        `${SERVER_URL}/qoolection-duplicate`,
        { ...qoolection },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      notification.success({
        message: 'Qoolection created.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });

      // Redirects on duplicate action
      const qoolectionId = response.data.item._id;
      history.push('/admin/edit-qoolection/' + qoolectionId);

      //setStateForRefetchingData(previous => previous + 1);
      //setOffset(0);
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const historyHandler = async (id) => {
    try {
      const qoolectionHistory = await Axios.get(`${SERVER_URL}/history-qoolection/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      setQoolectionHistory(qoolectionHistory.data);
      setQoolectionHistoryModalVisible(true);
    } catch (err) {
      console.log(err);
    }
  };

  const historyRestore = async (id) => {
    try {
      const qoolectionHistory = await Axios.put(
        `${SERVER_URL}/history-restore-qoolection/${id}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      setQoolectionHistoryModalVisible(false);
      setStateForRefetchingData((previous) => previous + 1);
    } catch (err) {
      console.log(err);
    }
  };

  const changeStatusForLocalQoolection = async (qoolectionId, status = null) => {
    if (!status) throw new Error('No status!!!');
    try {
      await Axios.get(`${SERVER_URL}/qoolections-change-status/${qoolectionId}/${status}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: `New status for qoolection is ${status}.`,
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      setStateForRefetchingData((previous) => previous + 1);
    } catch (err) {
      const msg = err.response ? err.response.data.message : err.message ? err.message : err;
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const sendQoolectionHandler = async (qoolectionId, clients, sendList) => {
    const sendData = {
      qoolectionId,
      clients,
      sendList
    };
    try {
      await Axios.post(`${SERVER_URL}/send-published-qoolection`, sendData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Sent',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      setQoolectionForSending(null);
    } catch (err) {
      const msg = err.response ? err.response.data.message : err.message ? err.message : err;
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  // const [state, dispatch] = useReducer(qoolectionReducer, { qoolection: collaboratorsQoolection });
  // const { qoolection } = state;
  // useEffect(() => {
  //   state.qoolection = collaboratorsQoolection;
  // }, [state, collaboratorsQoolection]);
  // console.log('state: ', state);

  // const updateQoolectionCollaboratorAccess = (userId, accessRight) => {
  //   // console.log('userId: ', userId);
  //   // console.log('accessRight: ', accessRight);

  //   dispatch({
  //     type: EDIT_QOOLECTION_COLLABORATOR_ACCESS,
  //     payload: {
  //       userId: userId,
  //       accessRight: accessRight
  //     }
  //   });
  // };

  const updateUserCollaboratorAccess = async (qoolectionId, userId, accessRight) => {
    const addCollaboratorData = {
      qoolection: qoolectionId,
      user: userId,
      accessRight: accessRight
    };

    try {
      if (accessRight === 'none') {
        await Axios.put(
          `${SERVER_URL}/remove-user-as-collaborator-to-qoolection`,
          { qoolection: qoolectionId, user: userId },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` }
          }
        );
      } else {
        await Axios.put(
          `${SERVER_URL}/add-user-as-collaborator-to-qoolection`,
          addCollaboratorData,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` }
          }
        );
      }

      setStateForRefetchingData((previous) => previous + 1);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const checkEventsInQoolection = async (qoolection) => {
    setEventErrorList([]);
    setEventErrors(true);
    try {
      const response = await Axios.get(`${SERVER_URL}/check-qoolection-events/${qoolection._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });

      const errors = response.data?.errors || [];
      if (errors.length <= 0) {
        setEventErrors(false);
        setQoolectionForApproval(qoolection);
      }
      setQoolectionForChecking(qoolection);
      setEventErrorList(errors);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}>
        <HeaderComponent text="Manage Qoolections" />
        <div className="table-header">
          <CustomSwitch listView={listView} setListView={setListView} />
          <Input
            className="table-header__search"
            placeholder="Search"
            prefix={<SearchIcon />}
            onChange={(e) => handleChange(e)}
            allowClear={true}
          />
          <div className="table-header__grid-filters">
            <Select
              className="dropdown-small"
              placeholder="Filter by Theme"
              mode="multiple"
              value={filters?.theme || []}
              onChange={(value) => setFilters((previous) => ({ ...previous, theme: value }))}
              maxTagCount={1}
              showArrow>
              {themes?.data?.items?.length > 0 &&
                themes.data.items.map((theme) => {
                  return (
                    <Select.Option key={theme._id} value={theme._id}>
                      {theme.name}
                    </Select.Option>
                  );
                })}
            </Select>
            <Select
              className="dropdown-small"
              placeholder="Filter by Type"
              mode="multiple"
              value={filters?.type || []}
              onChange={(value) => setFilters((previous) => ({ ...previous, type: value }))}
              maxTagCount={1}
              showArrow>
              {qoolectionTypes?.data?.items?.length > 0 &&
                qoolectionTypes.data.items.map((qoolectionType) => {
                  return (
                    <Select.Option key={qoolectionType._id} value={qoolectionType._id}>
                      {qoolectionType.name}
                    </Select.Option>
                  );
                })}
            </Select>
            <Select
              className="dropdown-small"
              placeholder="Filter by Category"
              mode="multiple"
              value={filters?.category || []}
              onChange={(value) => setFilters((previous) => ({ ...previous, category: value }))}
              maxTagCount={1}
              showArrow>
              {categories?.data?.items?.length > 0 &&
                categories.data.items.map((category) => {
                  return (
                    <Select.Option key={category._id} value={category._id}>
                      {category.name}
                    </Select.Option>
                  );
                })}
            </Select>
            <Select
              className="dropdown-small"
              placeholder="Filter by Audience"
              mode="multiple"
              value={filters?.audience || []}
              onChange={(value) => setFilters((previous) => ({ ...previous, audience: value }))}
              maxTagCount={1}
              showArrow>
              {audiences?.data?.items?.length > 0 &&
                audiences.data.items.map((audience) => {
                  return (
                    <Select.Option key={audience._id} value={audience._id}>
                      {audience.name}
                    </Select.Option>
                  );
                })}
            </Select>
            <Select
              className="dropdown-small"
              placeholder="Filter by Status"
              mode="multiple"
              value={filters?.status || []}
              onChange={(value) => setFilters((previous) => ({ ...previous, status: value }))}
              maxTagCount={1}
              showArrow>
              {statuses.map((status) => {
                return (
                  <Select.Option key={status} value={status}>
                    {status}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        </div>
        {qoolections.isLoading && (
          <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
        )}
        {/* {loading && <Spin spinning={loading} size="large"></Spin>} */}
        {!qoolections.isLoading &&
          qoolections.data &&
          qoolections.data.items &&
          qoolections.data.items.length > 0 &&
          listView && (
            <Table
              user={currentuser}
              data={tableData}
              deleteDataHandler={deleteDataHandler}
              // editHandler={editDataHandler}
              historyHandler={historyHandler}
              columnKeys={columnKeys}
              title="qoolections"
              editPath="/admin/edit-qoolection/"
              filters={filters}
              setFilters={setFilters}
              setOffset={setOffset}
              total={qoolections.data.count}
              current={offset + 1}
              limit={limit}
              qoolectionTypes={qoolectionTypes}
              themes={themes}
              audiences={audiences}
              categories={categories}
              statuses={statuses}
              setQoolectionInModalPreview={setQoolectionInModalPreview}
              setQoolectionForApproval={checkEventsInQoolection}
              setQoolectionForSending={setQoolectionForSending}
              setQoolectionForExport={setQoolectionForExport}
              duplicateQoolection={duplicateQoolection}
              changeStatusForLocalQoolection={changeStatusForLocalQoolection}
              setCollaboratorsQoolection={setCollaboratorsQoolection}
            />
          )}
        {!qoolections.isLoading &&
          qoolections.data &&
          qoolections.data.items &&
          qoolections.data.items.length === 0 && <h2>NO DATA</h2>}
        {!qoolections.isLoading &&
          qoolections.data &&
          qoolections.data.items &&
          qoolections.data.items.length > 0 &&
          !listView && (
            <QoolectionsGrid
              qoolections={qoolections?.data?.items}
              deleteDataHandler={deleteDataHandler}
              editDataHandler={editDataHandler}
              // editPath='/admin/edit-qoolection/'
              historyHandler={historyHandler}
              setOffset={setOffset}
              total={qoolections.data.count}
              current={offset + 1}
              setQoolectionInModalPreview={setQoolectionInModalPreview}
              setQoolectionForApproval={checkEventsInQoolection}
              duplicateQoolection={duplicateQoolection}
            />
          )}
      </div>
      {qoolectionInModalPreview && (
        <QoolectionPreviewModal
          visible={qoolectionInModalPreview}
          closeModal={() => setQoolectionInModalPreview(null)}
          qoolection={qoolectionInModalPreview}
        />
      )}

      {eventErrors && (
        <EventModal
          qoolection={qoolectionForChecking}
          visible={eventErrors}
          closeModal={() => {
            setEventErrors(false);
            setEventErrorList([]);
          }}
          eventErrors={eventErrors}
          eventErrorList={eventErrorList}
          setEventErrorList={setEventErrorList}
          setEventErrors={setEventErrors}
        />
      )}

      {qoolectionForExport && (
        <ExportQoolectionPdfModal
          visible={qoolectionForExport}
          closeModal={() => setQoolectionForExport(null)}
          qoolection={qoolectionForExport}
          qoolectionForApproval={qoolectionForApproval}
          SERVER_URL={SERVER_URL}
          currentuser={currentuser}
          setLoading={setLoading}
          loading={loading}
          qoolections={qoolections}
          approve={approveQoolection}
          setStateForRefetchingData={setStateForRefetchingData}
          veevaConnected={veevaConnected}
        />
      )}

      {qoolectionForApproval && !eventErrors && (
        <ApprovalModal
          qoolection={qoolectionForApproval}
          visible={qoolectionForApproval}
          closeModal={() => setQoolectionForApproval(null)}
          SERVER_URL={SERVER_URL}
          currentuser={currentuser}
          setLoading={setLoading}
          setQoolectionForExport={setQoolectionForExport}
          setQoolectionForApproval={setQoolectionForApproval}
          setApproveQoolection={setApproveQoolection}
          setVeevaConnected={setVeevaConnected}
        />
      )}
      {qoolectionForSending && contacts?.data?.items && (
        <SendQoolectionModal
          qoolection={qoolectionForSending}
          visible={qoolectionForSending}
          sendQoolectionHandler={sendQoolectionHandler}
          closeModal={() => setQoolectionForSending(null)}
          SERVER_URL={SERVER_URL}
          currentuser={currentuser}
          contacts={contacts.data.items}
        />
      )}
      {qoolectionHistoryModalVisible && (
        <QoolectionHistoryModal
          qoolectionHistoryModalVisible={qoolectionHistoryModalVisible}
          setQoolectionHistoryModalVisible={setQoolectionHistoryModalVisible}
          qoolectionHistory={qoolectionHistory}
          onHistoryRestore={historyRestore}
        />
      )}

      {collaboratorsQoolection && (
        <CollaboratorsModal
          visible={collaboratorsQoolection}
          users={collaboratorEligableUsers}
          collaborators={collaboratorsQoolection?.collaborators}
          closeModal={() => setCollaboratorsQoolection(null)}
          qoolection={collaboratorsQoolection}
          updateUserCollaboratorAccess={updateUserCollaboratorAccess}
        />
      )}
    </Layout>
  );
};

export default Qoolections;
