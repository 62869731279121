import { Button } from 'antd';
import React from 'react';
import LogoTab from './LogoTab';
import StepNavigation from './StepNavigation';

const NewThemeStep1 = ({
  step,
  setStep,
  themeInPreview,
  dispatch,
  completedStep,
  setCompletedStep
}) => {
  return (
    <div className="new-theme-builder__step-1">
      <p className="new-theme-builder__intro-text">
        QClinical is all about simplifying content creation. That is why we encourage you to start
        with the Theme Builder. Here, you can set up how your Qoolections will appear. You only need
        to do this once and you're set to begin creating as many Qoolections as you want.
      </p>
      <p className="new-theme-builder__intro-text">
        In the first 3 steps of this Theme Builder, you will set up your Brand Kit, which is
        composed of logos, a color palette, and typography. In the subsequent 5 steps, you will
        focus on the layout of your Qoolections.
      </p>
      <h2 className="new-theme-builder__step-subtitle">
        <span>Step 1: </span>
        <span>Let's start building your theme. First, we will tackle logos.</span>
      </h2>
      <StepNavigation step={step} setStep={setStep} completedStep={completedStep} />
      <p className="new-theme-builder__intro-text">
        Please upload one or more logos that will be used in your Qoolections. We recommend
        uploading logos as PNGs with transparent backgrounds for best results.
      </p>
      <LogoTab isNew={true} themeInPreview={themeInPreview} dispatch={dispatch} />
      <div className="new-theme-builder__bottom-row">
        <Button
          className="new-theme-builder__step-btn"
          type="primary"
          onClick={() => {
            if (!completedStep) {
              setCompletedStep(1);
            }
            setStep(2);
          }}>
          Next step
        </Button>
      </div>
    </div>
  );
};

export default NewThemeStep1;
