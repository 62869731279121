export const blockEventTypes = [
  {
    value: 'VerifyQuestion',
    text: 'Verify Question',
    group: 'verificationGroup'
  },
  {
    value: 'SubmitData',
    text: 'Submit Data',
    group: 'qaGroup'
  },
  {
    value: 'QA',
    text: 'Question/Answer',
    group: 'qaGroup'
  },
  {
    value: 'SelectOption',
    text: 'Select Option',
    group: 'qaGroup'
  },
  {
    value: 'ChangeInitialQoo',
    text: 'Change Initial Qoo',
    group: 'commonGroup'
  },
  {
    value: 'ChangeInitialQoolection',
    text: 'Change Initial Qoolection',
    group: 'commonGroup'
  },
  {
    value: 'Reminder',
    text: 'Reminder',
    group: 'commonGroup'
  },
  {
    value: 'LockStudy',
    text: 'Lock Study',
    group: 'commonGroup'
  },
  {
    value: 'EndStudy',
    text: 'End Study',
    group: 'commonGroup'
  },
  {
    value: 'StartStudy',
    text: 'Start Study',
    group: 'commonGroup'
  },
  {
    value: 'InternalLink',
    text: 'Internal Link',
    group: 'forwardGroup'
  },
  {
    value: 'ExternalLink',
    text: 'External Link',
    group: 'forwardGroup'
  },
  {
    value: 'RefreshApp',
    text: 'Refresh App',
    group: 'forwardGroup'
  }
];
