import React, { useEffect, useState, useReducer, useRef, useContext } from 'react';
import {
  Layout,
  Form,
  Input,
  Select,
  Button,
  Row,
  Collapse,
  Checkbox,
  Switch,
  Popconfirm,
  Radio,
  InputNumber,
  notification,
  Slider,
  Upload,
  Dropdown,
  Col
} from 'antd';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import BlockTypesList from '../../components/elements/BlockTypesList';
import BlocksSelector from './BlocksSelector';
import QooPreviewGrid from './QooPreviewGrid';
import qooInPreviewReducer from '../../reducers/qoo-in-preview/qooInPreviewReducer';
import {
  SET_QOO,
  UNDO,
  REDO,
  REPLACE_BLOCK_WITH_CLONE,
  EDIT_CLONED_BLOCK,
  SELECT_QOO,
  EDIT_QOO_PROPERTY,
  ADD_BLOCK,
  EDIT_SINGLE_ELEMENT,
  EDIT_SINGLE_ELEMENT_EVENTS,
  REMOVE_BLOCK,
  MOVE_LAYER_TO_FRONT_Q,
  MOVE_LAYER_UP_WITH_ID_Q,
  MOVE_LAYER_DOWN_WITH_ID_Q,
  MOVE_LAYER_TO_BOTTOM_Q,
  EDIT_MENU_LINK,
  REMOVE_MENU_LINK,
  ADD_MENU_LINK,
  ADD_FORM_FIELD_Q,
  EDIT_FORM_FIELD_Q,
  REMOVE_FORM_FIELD_Q,
  ADD_SURVEY_OPTION_Q,
  EDIT_SURVEY_OPTION_Q,
  REMOVE_SURVEY_OPTION_Q,
  ADD_ENDNOTES_Q,
  REMOVE_ENDNOTE_Q,
  EDIT_ENDNOTE_Q,
  REMOVE_SINGLE_ELEMENT_EVENT,
  ADD_BLOCKS,
  BLOCKS_LAYOUT_CHANGE,
  EDIT_BLOCK_WIDTH,
  REMOVE_QUESTION_FROM_QOO,
  DELETE_NAVIGATION_BUTTON,
  ADD_NAVIGATION_BUTTON,
  EDIT_NAVIGATION_BUTTON,
  EDIT_ANSWER_TEXT
} from '../../reducers/qoo-in-preview/actionTypes';
import PhonePreviewModal from './PhonePreviewModal';
import EditBlockModal from './EditBlockModal';
import SideBarInner from '../../components/elements/SideBarInner';
import HeaderInner from '../../components/elements/HeaderInner';
import DeleteModal from '../../components/elements/DeleteModal';
import { ReactComponent as Save } from '../../icons/save.svg';
import { ReactComponent as Close } from '../../icons/close.svg';
import { ReactComponent as Phone } from '../../icons/ph_device-mobile-speaker.svg';
import { ReactComponent as UndoIcon } from '../../icons/ph_arrow-u-up-left-light.svg';
import { ReactComponent as RedoIcon } from '../../icons/ph_arrow-u-up-right-light.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../icons/ph_minus.svg';
import { ReactComponent as InfoIcon } from '../../icons/info-icon.svg';
import { ReactComponent as BtnWidthIcon } from '../../icons/btn-width.svg';
import { ReactComponent as BtnHeightIcon } from '../../icons/btn-height.svg';
import { ReactComponent as LinkWebsite } from '../../icons/link-website.svg';
import { ReactComponent as LinkSearch } from '../../icons/link-search.svg';
import { ReactComponent as LinkEmail } from '../../icons/link-email.svg';
import { ReactComponent as LinkPhone } from '../../icons/link-phone.svg';
import { ReactComponent as AlignTopIcon } from '../../icons/ph_align-top-light.svg';
import { ReactComponent as AlignBottomIcon } from '../../icons/ph_align-bottom-light.svg';
import { ReactComponent as AlignLeftIcon } from '../../icons/ph_align-left-light.svg';
import { ReactComponent as AlignRightIcon } from '../../icons/ph_align-right-light.svg';
import { ReactComponent as DownCaret } from '../../icons/down-caret.svg';
import { ReactComponent as CheckboxGreyIcon } from '../../icons/fluent_checkbox-checked-24-regular-grey.svg';
import { ReactComponent as RadioIcon } from '../../icons/radix-icons_radiobutton.svg';
import { ReactComponent as DeleteIcon } from '../../icons/close-small.svg';
import { ReactComponent as SliderIcon } from '../../icons/ph_sliders-light.svg';
import { ReactComponent as MediaIcon } from '../../icons/block-types-icons/image-square-light.svg';
import { ReactComponent as PlayIcon } from '../../icons/ph_play-light.svg';
import { ReactComponent as LinkIcon } from '../../icons/link.svg';
import { ReactComponent as EditIcon } from '../../icons/ph_pencil-simple-light.svg';
import { ReactComponent as QuestionIcon } from '../../icons/Vector.svg';
import BlockPreviewBox from '../blocks/BlockPreviewBox';
import ColorPickerDropdown from '../../components/elements/ColorPickerDropdown';
import BgImagesList from '../blocks/BgImagesList';
import {
  addBlockData,
  answerButtonTemplates,
  buttonTemplates,
  dividerTemplates,
  formTemplates,
  navigationButtonTemplates,
  surveyTemplates
} from '../blocks/addBlockData';
import IconsLibrary from '../blocks/IconsLibrary';
import QooHeader from './QooHeader';
import QooFooter from './QooFooter';
import {
  SELECT_BLOCK,
  EDIT_BLOCK_FIELD,
  EDIT_BLOCK_PROPERTY,
  EDIT_BLOCK_PROPERTY_WITHOUT_HISTORY,
  EDIT_INNER_BLOCK_IN_LAYOUT,
  ADD_BLOCK_TO_LAYOUT,
  REMOVE_BLOCK_FROM_LAYOUT,
  MOVE_LAYER_TO_FRONT,
  MOVE_LAYER_UP_WITH_ID,
  MOVE_LAYER_DOWN_WITH_ID,
  MOVE_LAYER_TO_BOTTOM,
  ADD_FORM_FIELD,
  EDIT_FORM_FIELD,
  REMOVE_FORM_FIELD,
  ADD_SURVEY_OPTION,
  REMOVE_SURVEY_OPTION,
  EDIT_SURVEY_OPTION,
  REMOVE_ENDNOTE,
  ADD_ENDNOTES,
  EDIT_ENDNOTE,
  EDIT_BLOCK_EVENTS
} from '../../reducers/block-in-preview/actionTypes';
import SaveBlockModal from './SaveBlockModal';
import LayersPanel from '../blocks/LayersPanel';
import ContextMenu from '../../components/elements/ContextMenu';
import { notificationError, notificationSuccess } from '../../config/notificationOptions';
import MenuPreview from './MenuPreview';
import { calculateHF } from '../../utils/calculateHF';
import FormField from '../blocks/FormField';
import FormTemplate from '../../components/elements/FormTemplate';
import { ReactComponent as TextIcon } from '../../icons/ph_text-t.svg';
import { ReactComponent as EmailIcon } from '../../icons/ph_envelope-simple.svg';
import { ReactComponent as TextAreaIcon } from '../../icons/ph_selection-all.svg';
import { ReactComponent as CheckboxIcon } from '../../icons/fluent_checkbox-checked-24-regular.svg';
import { ReactComponent as PlusIconButton } from '../../icons/ph_plus.svg';
import { ReactComponent as AlignCenterIcon } from '../../icons/ph_align-left-center.svg';
import ImageListMedia from '../blocks/ImageListMedia';
import VideoList from '../blocks/VideoList';
import ImageCropModal from '../blocks/ImageCropModal';
import _ from 'lodash';
import Shapes from '../../components/elements/Shapes';
import BlockDisplay from '../blocks/BlockDisplay';
import QooDisplay from './QooDisplay';
import QooPhonePreviewModal from './QooPhonePreviewModal';
import { ReactComponent as EndnotesIcon } from '../../icons/references.svg';
import { ReactComponent as PanelsIcon } from '../../icons/ph_selection-background-light.svg';
import { ReactComponent as LayersIcon } from '../../icons/ph_stack-light.svg';
import EndnotesPanel from '../blocks/EndnotesPanel';
import PanelsPanel from '../blocks/PanelsPanel';
import EndnoteModal from '../blocks/EndnoteModal';
import DeleteEndnoteModal from '../blocks/DeleteEndnoteModal';
import BlockInPreviewGrid from '../blocks/BlockInPreviewGrid';
import buttonActionTypes from '../../params/blocks/buttonActionTypes';
import publishedMenuItemsTypes from '../../params/publishedMenuItemsTypes';
import { removeUrlProtocolFromUrl } from '../../utils/common';
import { UserContext } from '../../App';
import { useHistory, useParams } from 'react-router-dom';
import { LayoutContext } from '../../components/base/Layout';
import BlockEventsForm from './BlockEventsForm';
import { blockEventTypes } from '../../params/blocks/blockEventTypeGroups';
import { SERVER_URL } from '../../config';
import { SELECT_QOOLECTION_WITH_HISTORY } from '../../reducers/qoolection/actionTypes';
import TemplateModal from './TemplateModal';

const { Panel } = Collapse;
const { Content } = Layout;

const QooBuilder = ({
  token,
  SERVER_URL,
  qoo,
  onSubmit,
  cancelFunction,
  audiences,
  categories,
  blockInPreview,
  blockInPreviewHistory,
  blockInPreviewFuture,
  blockInPreviewDispatch,
  qooTypes,
  qoolection
}) => {
  const gridRef = useRef();
  const qooBuilderRef = useRef();
  const contextMenuRef = useRef();
  const currentuser = useContext(UserContext);
  const [contextMenuCoords, setContextMenuCoords] = useState(null);
  const [form] = Form.useForm();

  const initState = {
    qooInPreview: null,
    qooInPreviewHistory: [qoo],
    qooInPreviewFuture: []
  };

  const [state, dispatch] = useReducer(qooInPreviewReducer, initState);
  const { qooInPreview, qooInPreviewHistory, qooInPreviewFuture } = state;
  // console.log('state: ', state);
  const { qooId } = useParams();

  const layoutContext = useContext(LayoutContext);

  // Edit in progress for confirmation box about discard changes
  useEffect(() => {
    layoutContext.setEditInProgress(qooInPreviewHistory.length > 0);
  }, [qooInPreviewHistory.length]);

  const [selectedBlockType, setSelectedBlockType] = useState({
    name: 'browse'
  });
  const [allBlockTypes, fetchAllBlockTypes] = useAxios('', {}, token, 'get');
  const [qooInfoForm] = Form.useForm();
  const [draggingItem, setDraggingItem] = useState(null);
  const [phonePreview, setPhonePreview] = useState(false);
  const [previewDevice, setPreviewDevice] = useState('iphone8');
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [advancedMode, setAdvancedMode] = useState(false);
  const [backgroundImageOpen, setBackgroundImageOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [blockBackgroundImageOpen, setBlockBackgroundImageOpen] = useState(false);
  const [selectedBtnDimension, setSelectedBtnDimension] = useState('buttonWidth');
  const [selectedPaddingSide, setSelectedPaddingSide] = useState('textPaddingTop');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [saveBlockModalVisible, setSaveBlockModalVisible] = useState(false);
  const [editBlockModalVisible, setEditBlockModalVisible] = useState(false);
  const [selectedPaddingSideForm, setSelectedPaddingSideForm] = useState('formPaddingTop');
  const [selectedFormFieldElement, setSelectedFormFieldElement] = useState(null);
  const [formFieldName, setFormFieldName] = useState('');
  const [mediaType, setMediaType] = useState('image');
  const [editImageOption, setEditImageOption] = useState();
  const [imageCropModalVisible, setImageCropModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [mediaOrientation, setMediaOrientation] = useState();
  const [blockTemplates, fetchBlockTemplates] = useAxios('', {}, token, 'get');
  const [blocks, fetchBlocks] = useAxios('', {}, token, 'get');
  const [qooTemplates, fetchQooTemplates] = useAxios('', {}, token, 'get');
  const [qoos, fetchQoos] = useAxios('', {}, token, 'get');
  const [setInitialQoos, fetchSetInitialQoos] = useAxios('', {}, token, 'get');
  const [qoolections, fetchQoolections] = useAxios('', {}, token, 'get');
  const [myWorkOrTemplates, setMyWorkOrTemplates] = useState('my-work');
  const [qoosOrBlocks, setQoosOrBlocks] = useState('qoos');
  const [searchFilter, setSearchFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState(undefined);
  const [audienceFilter, setAudienceFilter] = useState(undefined);
  const [blockTypeFilter, setBlockTypeFilter] = useState(undefined);
  const [qooTypeFilter, setQooTypeFilter] = useState(undefined);
  const [rightPanelsView, setRightPanelsView] = useState('single');
  const [rightPanel, setRightPanel] = useState(null);
  const [hoveredEndnote, setHoveredEndnote] = useState(null);
  const [addingEndnote, setAddingEndnote] = useState(null);
  const [endnoteToDelete, setEndnoteToDelete] = useState(null);
  const [endnoteForEdit, setEndnoteForEdit] = useState(null);
  const [currentEvent, setCurrentEvent] = useState({});
  const [eventIndex, setEventIndex] = useState(undefined);
  const [isFirstQoo, setIsFirstQoo] = useState(
    qoo.blocksLayout.length > 0 &&
      !qoo.blocksLayout.some((item) => item.block.name === 'previousButton')
  );
  const history = useHistory();
  const [isLastQoo, setIsLastQoo] = useState(
    qoo.blocksLayout.length > 0 &&
      qoo.blocksLayout.some((item) => item.block.name === 'submitButton')
  );
  const [selectedQuestion, setSelectedQuestion] = useState(
    qoo?.blocksLayout.length === 0 ? 'Choose question' : qoo?.blocksLayout[0]?.block.question
  );
  const [questions, fetchQuestions] = useAxios(
    '',
    null,
    currentuser.data.token,
    'get'
    // stateForRefetchingQuestions
  );
  const [showButtonAsActive, setShowButtonAsActive] = useState(false);

  const [questionsForBlockEvent, fetchQuestionsForBlockEvent] = useAxios('', {}, token, 'get');
  const [remindersForBlockEvent, fetchRemindersForBlockEvent] = useAxios('', {}, token, 'get');
  const [publishedQoolections, fetchPublishedQoolections] = useAxios('', {}, token, 'get');
  const [sendingTemplateData, setSendingTemplateData] = useState(false);
  const iconFetchUrl = process.env.REACT_APP_PROD_URL;
  const { selectedTeam, study } = currentuser;
  const getAllQoos = () => {
    return _.get(qoos, 'data.items', []);
  };

  const getQoosForSelectComponent = () => {
    return getAllQoos().map((qoo) => {
      const qooId = _.get(qoo, '_id', '');
      const title = _.get(qoo, 'title', '');

      return {
        value: qooId,
        label: title
      };
    });
  };

  const getAllPublishedQoolections = () => {
    return _.get(publishedQoolections, 'data.items', []);
  };

  const getPublishedQoolectionsForSelectComponent = () => {
    let publishedQoolections = getAllPublishedQoolections();

    return publishedQoolections.map((qoo) => {
      const qooId = _.get(qoo, '_id', '');
      const title = _.get(qoo, 'title', '');

      return {
        value: qooId,
        label: title
      };
    });
  };

  const initGridOptions = {
    style: 'dots',
    color: {
      hex: '#BDC7CF',
      rgb: { r: 189, g: 199, b: 207, a: 1 }
    },
    size: '6x8',
    previewDevice: 'iphonex'
  };
  const [gridOptions, setGridOptions] = useState(initGridOptions);

  useEffect(() => {
    fetchAllBlockTypes(`${SERVER_URL}/block-types`, {});
  }, [fetchAllBlockTypes]);

  useEffect(() => {
    if (qoo && qoo.title) {
      qooInfoForm.setFieldsValue({
        title: qoo.title,
        theme: qoo.theme,
        qooType: qoo.qooType,
        category: qoo.category,
        audience: qoo.audience
      });
      dispatch({
        type: SET_QOO,
        payload: qoo
      });
    }
  }, [qoo]);

  useEffect(() => {
    let filter = { client: currentuser.data.client?._id };
    fetchQuestions(
      `${SERVER_URL}/questions?filter=${JSON.stringify(filter)}`,
      null,
      currentuser.data.token,
      'get'
      // stateForRefetchingQuestions
    );
  }, [fetchQuestions, currentuser.data.token]);

  const handleClickOutside = (e) => {
    const layersPanelDiv = document.querySelector('.layers-panel');

    if (layersPanelDiv && layersPanelDiv.contains(e.target)) {
      return;
    }
    if (contextMenuRef.current && contextMenuRef.current.contains(e.target)) {
      return;
    }
    if (contextMenuRef.current && !contextMenuRef.current.contains(e.target)) {
      setContextMenuCoords(null);
    }
    if (gridRef.current && !gridRef.current.contains(e.target)) {
      setSelectedBlock(null);
      setSelectedBlockType({ name: 'general' });
    }
  };

  useEffect(() => {
    const outside = document.querySelector('.qoo-builder');

    if (outside && selectedBlock && !blockInPreview) {
      outside.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  });

  const handleUndoRedoShortcut = (e) => {
    if (e.key == 'z' && e.ctrlKey) {
      handleUndo();
    } else if (e.key == 'y' && e.ctrlKey) {
      handleRedo();
    }
  };

  const handleDeleteShortcut = (e) => {
    if (
      (e.key == 'Delete' || (navigator.userAgent.indexOf('Mac') != -1 && e.key == 'Backspace')) &&
      document.activeElement.tagName !== 'INPUT'
    ) {
      setDeleteModalVisible(true);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleUndoRedoShortcut);
    return () => document.removeEventListener('keydown', handleUndoRedoShortcut);
  });
  useEffect(() => {
    if (selectedBlock) {
      document.addEventListener('keydown', handleDeleteShortcut);
      return () => document.removeEventListener('keydown', handleDeleteShortcut);
    }
  });
  const isSuperAdmin = currentuser.data.role.some((item) => item === 'superadmin');
  useEffect(() => {
    const filters = {
      name: qoosOrBlocks === 'blocks' ? searchFilter : null,
      title: qoosOrBlocks === 'qoos' ? searchFilter : null,
      category: categoryFilter,
      audience: audienceFilter,
      blockFormat: qoosOrBlocks === 'blocks' ? blockTypeFilter : null,
      qooType: qoosOrBlocks === 'qoos' ? qooTypeFilter : null,
      ownId:
        myWorkOrTemplates === 'templates'
          ? null
          : qoosOrBlocks === 'qoos'
          ? qooInPreview?._id
          : null
    };
    const removedNullValues = Object.fromEntries(
      Object.entries(filters).filter(
        ([key, value]) => value != null && (!Array.isArray(value) || value.length !== 0)
      )
    );
    const filter = JSON.stringify(removedNullValues);

    switch (myWorkOrTemplates) {
      case 'templates':
        if (qoosOrBlocks === 'blocks') {
          fetchBlockTemplates(`${SERVER_URL}/blocks-templates?filter=${filter}`, []);
          if (!isSuperAdmin && currentuser.study) {
            fetchBlocks(
              `${SERVER_URL}/blocks?filter=${filter}&team=${currentuser.selectedTeam}&study=${currentuser.study}`,
              []
            );
          } else if (isSuperAdmin) {
            fetchBlocks(`${SERVER_URL}/blocks?filter=${filter}`, []);
          }
        } else {
          if (!isSuperAdmin && currentuser.study) {
            fetchQoos(
              `${SERVER_URL}/qoos?filter=${filter}&team=${currentuser.selectedTeam}&study=${currentuser.study}`,
              []
            );
          } else if (isSuperAdmin) {
            fetchQoos(`${SERVER_URL}/qoos?filter=${filter}`, []);
          }
          fetchQooTemplates(`${SERVER_URL}/qoos-templates?filter=${filter}`, []);
        }
        break;
      case 'my-work':
        if (qoosOrBlocks === 'blocks') {
          if (!isSuperAdmin && currentuser.study) {
            fetchBlocks(
              `${SERVER_URL}/blocks?filter=${filter}&team=${currentuser.selectedTeam}&study=${currentuser.study}`,
              []
            );
          } else if (isSuperAdmin) {
            fetchBlocks(`${SERVER_URL}/blocks?filter=${filter}`, []);
          }
        } else {
          if (qoosOrBlocks === 'qoos') {
            if (!isSuperAdmin && currentuser.study) {
              fetchQoos(
                `${SERVER_URL}/qoos?filter=${filter}&team=${currentuser.selectedTeam}&study=${currentuser.study}`,
                []
              );
            } else if (isSuperAdmin) {
              fetchQoos(`${SERVER_URL}/qoos?filter=${filter}`, []);
            }
          }
        }
        break;
      default:
        break;
    }

    fetchPublishedQoolections(`${SERVER_URL}/published-qoolections-for-patient`, []);

    // #QCDEMO#
    // loading of all the qoos should be discussed or, if it is needed,
    // done on-demand, asynchronously in qoos dropdown
    // TODO: remove this later

    // fetchQoos(`${SERVER_URL}/qoos`, []);
    // fetchQoolections(`${SERVER_URL}/qoolections`, []);
  }, [
    qoosOrBlocks,
    myWorkOrTemplates,
    searchFilter,
    categoryFilter,
    audienceFilter,
    blockTypeFilter,
    qooTypeFilter,
    currentuser
  ]);

  // const gridSize = gridOptions.size === '6x8' ? [6, 11] : gridOptions.size === '6x9' ? [6, 12] : [6, 13];
  const gridSize = [12, 22];
  const squaresNr = gridSize[0] * gridSize[1];
  const emptyArray = [...Array(squaresNr)];

  const isDataFetched =
    !allBlockTypes.isLoading &&
    allBlockTypes.data &&
    allBlockTypes.data.length > 0 &&
    !categories.isLoading &&
    !audiences.isLoading;

  const handleUndo = () => {
    if (blockInPreview) {
      blockInPreviewDispatch({
        type: UNDO
      });
    } else {
      dispatch({
        type: UNDO
      });
    }
  };

  const handleRedo = () => {
    if (blockInPreview) {
      blockInPreviewDispatch({
        type: REDO
      });
    } else {
      dispatch({
        type: REDO
      });
    }
  };

  let highlightedBlock;
  if (selectedBlock && qooInPreview && !blockInPreview) {
    highlightedBlock = qooInPreview.blocksLayout.find((item) => item.block._id === selectedBlock);
  } else if (selectedBlock && blockInPreview) {
    highlightedBlock = blockInPreview.blocksLayout.find((item) => item.block._id === selectedBlock);
  }

  const headingFont = qooInPreview?.theme.selectedFonts.find(
    (font) => font._id === qooInPreview.theme.headingFont
  );
  const subheadingFont = qooInPreview?.theme.selectedFonts.find(
    (font) => font._id === qooInPreview.theme.subheadingFont
  );
  const bodyTextFont = qooInPreview?.theme.selectedFonts.find(
    (font) => font._id === qooInPreview.theme.bodyTextFont
  );

  const addNavigationButton = async (type) => {
    try {
      const buttonBlockType = allBlockTypes.data.find(
        (blockType) => blockType.name === 'button'
      )?._id;
      const blockData = [
        {
          ...addBlockData['navigationButton'],
          name: type,
          blockProperties: navigationButtonTemplates[0],
          blockType: buttonBlockType,
          question: selectedQuestion
        }
      ];
      const route = `${SERVER_URL}/epro-blocks`;
      const response = await Axios.post(route, blockData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` }
      });

      dispatch({
        type: ADD_NAVIGATION_BUTTON,
        payload: response.data.items.map((block) => ({
          block: block,
          x: 0,
          y: 20,
          w: 6,
          h: block.size[0]
        }))
      });
    } catch (err) {
      console.log(err);
    }
  };

  const duplicateQoo = async (qoo, addingBlankQoo) => {
    await currentuser.handleRefreshToken();
    const submitData = {
      ...qoo,
      version: {
        ...qoo.version,
        patch: 1
      }
    };

    if (!addingBlankQoo) {
      delete submitData._id;
      submitData.title = `${qoo.title}(2)`;
    }

    const route = addingBlankQoo
      ? `${SERVER_URL}/qoos`
      : `${SERVER_URL}/qoos-create-new-from-existing`;
    try {
      const response = await Axios.post(route, submitData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Qoo created',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      addQoo(response.data.item);
      history.push(`/admin/edit-qoo/${response.data.item._id}`);
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const addQoo = (qoo) => {
    dispatch({
      type: SET_QOO,
      payload: qoo
    });
  };

  const addBlockHandler = async () => {
    const question = questions.data.items.find((q) => q._id === selectedQuestion);

    if (question) {
      const route = `${SERVER_URL}/epro-blocks`;
      const numberOfAnswers = question.numberOfAnswers;

      const buttonBlockType = allBlockTypes.data.find(
        (blockType) => blockType.name === 'button'
      )?._id;
      const textBlockType = allBlockTypes.data.find((blockType) => blockType.name === 'text')?._id;

      // let numberOfButtons = qooInPreview.blocksLayout.filter(block => block.block.name === 'button').length;
      // if(numberOfButtons + question.numberOfAnswers > 4){
      //   dispatch({
      //     type: EDIT_BLOCK_WIDTH,
      //     payload: qooInPreview.blocksLayout.filter(item => item.block.name === 'button').map((block) => ({
      //       _id: block.block._id,
      //       w: block.w / 2,
      //     }))
      //   })
      // }

      let blockData = [
        {
          ...addBlockData['surveyQuestion'],
          question: selectedQuestion,
          blockProperties: {
            ...addBlockData['surveyQuestion'].blockProperties,
            textContent: `<p><strong><span style=\"font-size: 2em; color: #000000; font-family: 'Noto Sans';\">${question.question}</span></strong></p>`
          },
          blockType: textBlockType
        },
        {
          ...addBlockData['surveyDescription'],
          question: selectedQuestion,
          blockProperties: {
            ...addBlockData['surveyDescription'].blockProperties,
            textContent: `<p><span style=\"font-size: 2em; color: #000000; font-family: 'Noto Sans'; \">${question.description}</span></p>`
          },
          blockType: textBlockType
        }
      ];

      for (let i = 0; i < numberOfAnswers; i++) {
        blockData.push({
          ...addBlockData['button'],
          size: [2, 12],
          blockProperties: {
            ...answerButtonTemplates[0],
            buttonText: `<p><span id=${
              i + 1
            } style=\"font-size: 1.5em; color: #000000; font-family: 'Noto Sans'; \">Choice ${
              i + 1
            }</span></p>`
          },
          blockType: buttonBlockType,
          question: selectedQuestion
        });
      }
      blockData = [
        ...blockData,
        {
          ...addBlockData['navigationButton'],
          name: 'previousButton',
          blockProperties: navigationButtonTemplates[0],
          blockType: buttonBlockType,
          question: selectedQuestion
        },
        {
          ...addBlockData['navigationButton'],
          name: 'nextButton',
          blockProperties: navigationButtonTemplates[1],
          blockType: buttonBlockType,
          question: selectedQuestion
        }
      ];
      try {
        const response = await Axios.post(route, blockData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` }
        });

        const items = response.data.items;
        let buttonStartPosition;

        dispatch({
          type: ADD_BLOCKS,
          payload: items
            .filter((item) => item.name === 'question' || item.name === 'description')
            .map((block, index) => {
              if (index === 1) {
                buttonStartPosition = (index + 1) * 2 + block.size[0];
              }
              return {
                block: block,
                x: 0,
                y: (index + 1) * 2,
                w: block.size[1],
                h: block.size[0]
              };
            })
        });
        buttonStartPosition += 1;
        let k = 0;
        dispatch({
          type: ADD_BLOCKS,
          payload: items
            .filter((item) => item.name === 'button')
            .map((block, index) => {
              // block.blockProperties.buttonText = `<p><span style=\"font-size: 1.5em; color: #000000; font-family: 'Noto Sans'; \">Choice ${index +1}</span></p>`
              if (numberOfAnswers > 5) {
                if (numberOfAnswers % 2 === 0 && index === numberOfAnswers / 2) {
                  k = 0;
                } else if (numberOfAnswers % 2 > 0 && index === Math.ceil(numberOfAnswers / 2)) {
                  k = 0;
                }
              }
              k++;

              return {
                block: block,
                x: index <= k ? 0 : 6,
                y:
                  index < Math.ceil(numberOfAnswers / 2)
                    ? buttonStartPosition + index * 2
                    : buttonStartPosition + (k - 1) * 2,
                w: question.numberOfAnswers > 5 ? 6 : block.size[1],
                h: block.size[0]
              };
            })
        });

        dispatch({
          type: ADD_BLOCKS,
          payload: items
            .filter(
              (item) =>
                item.name === 'previousButton' ||
                item.name === 'nextButton' ||
                item.name === 'submitButton'
            )
            .map((block, index) => ({
              block: block,
              x: index === 1 ? 6 : 0,
              y: 20,
              w: block.size[1],
              h: block.size[0]
            }))
        });
        setIsLastQoo(false);
        setIsFirstQoo(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const addEmptyBlock = async (type, properties) => {
    const defaultFont =
      type === 'heading'
        ? headingFont
        : type === 'subheading'
        ? subheadingFont
        : type === 'body'
        ? bodyTextFont
        : null;

    const blockData = type
      ? {
          ...addBlockData[`${type}`],
          blockProperties: properties
            ? { ...properties }
            : {
                ...addBlockData[`${type}`].blockProperties,
                textContent: addBlockData[`${type}`].blockProperties.textContent.replace(
                  '**default**',
                  defaultFont.name.toLowerCase().replace(' -', '')
                )
              },
          blockType: selectedBlockType._id
        }
      : {
          ...addBlockData.empty,
          blockType: selectedBlockType._id
        };
    const route = `${SERVER_URL}/blocks`;

    try {
      const response = await Axios.post(route, blockData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` }
      });

      if (blockInPreview) {
        blockInPreviewDispatch({
          type: ADD_BLOCK_TO_LAYOUT,
          payload: {
            block: response.data.item,
            x: 0,
            y: 2,
            w: response.data.item.size[1],
            h: response.data.item.size[0]
          }
        });
      } else {
        dispatch({
          type: ADD_BLOCK,
          payload: {
            block: response.data.item,
            x: 0,
            y: 2,
            w: response.data.item.size[1],
            h: response.data.item.size[0]
          }
        });
      }

      setSelectedBlock(response.data.item._id);
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const updateSingleElementProperties = (blockId, updates, buttonReferenceType = '') => {
    // console.log('updateSingleElementProperties', qooInPreview);
    if (blockInPreview) {
      blockInPreviewDispatch({
        type: EDIT_INNER_BLOCK_IN_LAYOUT,
        payload: {
          blockId,
          updates
        }
      });
    } else {
      if (updates.buttonLinkTo) {
        // Reset buttonAction reference value on reference type change.
        updates.buttonAction = '';
      }

      dispatch({
        type: EDIT_SINGLE_ELEMENT,
        payload: {
          blockId,
          updates
        }
      });
    }
  };

  const updateSingleElementEvents = (blockId, updates, buttonReferenceType = '') => {
    // console.log('BLOCKINPREVIEW: ', blockInPreview)
    // console.log('QOOINPREVIEW: ', qooInPreview)
    if (blockInPreview) {
      // blockInPreviewDispatch({
      //   type: EDIT_BLOCK_EVENTS,
      //   payload: {
      //     blockId,
      //     updates,
      //   },
      // });
    } else {
      if (updates.buttonLinkTo) {
        // Reset buttonAction reference value on reference type change.
        updates.buttonAction = '';
      }

      // console.log('UPDATES: ', updates)

      dispatch({
        type: EDIT_SINGLE_ELEMENT_EVENTS,
        payload: {
          blockId,
          updates,
          eventIndex
        }
      });
    }
  };

  const deleteSingleElementEvent = (blockId, updates, buttonReferenceType = '') => {
    dispatch({
      type: REMOVE_SINGLE_ELEMENT_EVENT,
      payload: {
        blockId,
        updates
      }
    });
  };

  const handleRightClick = (e) => {
    e.preventDefault();

    const x = e.clientX - qooBuilderRef.current.getBoundingClientRect().left;
    const y = e.clientY - qooBuilderRef.current.getBoundingClientRect().top;

    setContextMenuCoords({ x, y });
  };

  const moveLayerUp = (blockId) => {
    if (blockInPreview) {
      blockInPreviewDispatch({
        type: MOVE_LAYER_UP_WITH_ID,
        payload: {
          blockId
        }
      });
    } else {
      dispatch({
        type: MOVE_LAYER_UP_WITH_ID_Q,
        payload: {
          blockId
        }
      });
    }
  };

  const moveLayerToFront = (blockId) => {
    if (blockInPreview) {
      blockInPreviewDispatch({
        type: MOVE_LAYER_TO_FRONT,
        payload: {
          blockId
        }
      });
    } else {
      dispatch({
        type: MOVE_LAYER_TO_FRONT_Q,
        payload: {
          blockId
        }
      });
    }
  };
  const moveLayerDown = (blockId) => {
    if (blockInPreview) {
      blockInPreviewDispatch({
        type: MOVE_LAYER_DOWN_WITH_ID,
        payload: {
          blockId
        }
      });
    } else {
      dispatch({
        type: MOVE_LAYER_DOWN_WITH_ID_Q,
        payload: {
          blockId
        }
      });
    }
  };

  const moveLayerToBottom = (blockId) => {
    if (blockInPreview) {
      blockInPreviewDispatch({
        type: MOVE_LAYER_TO_BOTTOM,
        payload: {
          blockId
        }
      });
    } else {
      dispatch({
        type: MOVE_LAYER_TO_BOTTOM_Q,
        payload: {
          blockId
        }
      });
    }
  };

  let gridAlign, headerHeight, footerHeight;
  if (qooInPreview && qooInPreview.theme) {
    ({ gridAlign, headerHeight, footerHeight } = calculateHF(qooInPreview?.noHeaderFooter, {
      ...qooInPreview?.theme.headerFooter,
      header: {
        ...qooInPreview?.theme.headerFooter.header,
        noHeader: !qooInPreview.showHeader
      },
      footer: {
        ...qooInPreview?.theme.headerFooter.footer,
        noISI: !qooInPreview.showISI,
        noFooter: !qooInPreview.showFooter,
        shownISI:
          qooInPreview.ISISize === 'S'
            ? 'title-only'
            : qooInPreview.ISISize === 'M'
            ? 'less-text'
            : qooInPreview.ISISize === 'L'
            ? 'more-text'
            : qooInPreview?.theme.headerFooter.footer.shownISI
      }
    }));
  }
  const generalQooType =
    qooTypes.length !== 0 && qooTypes.filter((item) => item.name === 'General');

  const handleChangeDebounced = _.debounce(async (e) => {
    setSearchFilter(e.target.value);
  }, 500);

  const handleSearchInputChange = (e) => {
    e.persist();
    handleChangeDebounced(e);
  };

  const convertToTemplate = async (updates) => {
    setSendingTemplateData(true);
    const route = `${SERVER_URL}/qoos-templates/${qooId}`;
    try {
      const response = await Axios.put(route, updates, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      dispatch({
        type: SELECT_QOO,
        payload: {
          ...response.data.item,
          column: qooInPreview.column,
          row: qooInPreview.row
        }
      });
      setSendingTemplateData(false);
    } catch (error) {
      setSendingTemplateData(false);
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  let allEndnotes;
  if (qooInPreview) {
    const qooReferences = _.get(qooInPreview, 'endnotes.references', []);
    const qooFootnotes = _.get(qooInPreview, 'endnotes.footnotes', []);
    const qooAbbreviations = _.get(qooInPreview, 'endnotes.abbreviations', []);

    const allReferences = [
      ...qooReferences.map((reference) => reference.item),
      ...qooInPreview?.blocksLayout
        .filter((item) => (item.block ? item.block.isMulti : false))
        .map((item) => item.block.endnotes.references.map((reference) => reference.item))
        .flat()
    ];
    const allFootnotes = [
      ...qooFootnotes.map((footnote) => footnote.item),
      ...qooInPreview?.blocksLayout
        .filter((item) => (item.block ? item.block.isMulti : false))
        .map((item) => item.block.endnotes.footnotes.map((footnote) => footnote.item))
        .flat()
    ];
    const allAbbreviations = [
      ...qooAbbreviations.map((abbreviation) => abbreviation.item),
      ...qooInPreview?.blocksLayout
        .filter((item) => (item.block ? item.block.isMulti : false))
        .map((item) => item.block.endnotes.abbreviations.map((abbreviation) => abbreviation.item))
        .flat()
    ];

    allEndnotes = {
      references: _.uniqWith(allReferences, (a, b) => a._id === b._id),
      footnotes: _.uniqWith(allFootnotes, (a, b) => a._id === b._id),
      abbreviations: _.uniqWith(allAbbreviations, (a, b) => a._id === b._id)
    };
  }

  const [eventTypes, setEventTypes] = useState(blockEventTypes);

  // console.log('currentEvent: ', currentEvent);

  function blockEventHandler() {
    // console.log('Current Event: ', eventIndex, currentEvent);
    // if eventIndex exists it means we are editing an existing blockEvent, otherwise we add a new one
    // eventIndex || eventIndex === 0
    //   ? (highlightedBlock.block.blockEvents[eventIndex] = currentEvent)
    //   : updateSingleElementEvents(highlightedBlock?.block._id, {
    //     ...currentEvent,
    //   });
    updateSingleElementEvents(highlightedBlock?.block._id, {
      ...currentEvent
    });
    // restart everything after action completes
    setEventIndex(undefined);
    setCurrentEvent({});
  }

  function deleteBlockEventHandler(index) {
    deleteSingleElementEvent(highlightedBlock?.block._id, index);
  }

  function filterEventTypes() {
    if (highlightedBlock?.block.blockEvents && highlightedBlock?.block.blockEvents.length > 0) {
      for (let i = 0; i < highlightedBlock.block.blockEvents.length; i++) {
        if (
          highlightedBlock.block.blockEvents[i].eventType === 'InternalLink' ||
          highlightedBlock.block.blockEvents[i].eventType === 'ExternalLink' ||
          highlightedBlock.block.blockEvents[i].eventType === 'RefreshApp' ||
          highlightedBlock.block.blockEvents[i].eventType === 'CloseApp'
        ) {
          if (i === eventIndex) {
            return false;
          }
          return true;
        }
      }
    }
  }

  const filteredEventTypes =
    filterEventTypes() === true
      ? eventTypes.filter((eventType) => eventType.group !== 'forwardGroup')
      : eventTypes;

  useEffect(() => {
    // Question filter related to the current Qoo
    const blocksLayout = qoo.blocksLayout || [];
    let questionFilter = {};
    if (blocksLayout && blocksLayout.length > 0) {
      for (const b of blocksLayout) {
        if (b.block?.name === 'question') {
          questionFilter['_id'] = b.block.question;
        }
      }
    }

    fetchQuestionsForBlockEvent(
      `${SERVER_URL}/questions?filter=${JSON.stringify(questionFilter)}`,
      {},
      token,
      'get'
    );
    fetchRemindersForBlockEvent(`${SERVER_URL}/reminders`, {}, token, 'get');
  }, [fetchQuestionsForBlockEvent, fetchRemindersForBlockEvent]);

  useEffect(() => {
    if (qoolection)
      fetchSetInitialQoos(`${SERVER_URL}/qoos-in-same-qoolection/${qoolection}`, {}, token, 'get');
  }, [qoolection]);

  useEffect(() => {
    setCurrentEvent({});
    setShowButtonAsActive(false);
  }, [highlightedBlock]);

  const filteredQoosForBlockEvents = setInitialQoos?.data?.qoos?.filter(
    (setInitialQoo) => setInitialQoo._id !== qoo?._id
  );

  // console.log(
  //   'highlightedBlock.block.blockEvents: ',
  //   highlightedBlock?.block.blockEvents
  // );
  let eProQuestion = qooInPreview?.blocksLayout.filter((block) => block.block.name === 'question');

  // console.log('blockInPreview: ', blockInPreview, _.isEqual(qooInPreview, qooInPreviewHistory[0]), qooInPreviewHistory.length);

  // console.log('qooooooooooooooooooooooooooooo  ', qooInPreview);
  let showQooType = true;
  if (currentuser.data.role.includes('creator') && qooInPreview?.qooType?.name === 'ePro') {
    showQooType = false;
  }

  console.log('cccccccccc', currentEvent);

  return (
    <Layout>
      <SideBarInner>
        {selectedBlockType && qooInPreview && (
          <Collapse
            ghost
            expandIcon={({ isActive }) => (isActive ? <MinusIcon /> : <PlusIcon />)}
            expandIconPosition='right'
            className='block-properties'
            defaultActiveKey={['1', '5', '11', '13', '15', '20', '23', '25', '32', '36', '41']}>
            {(selectedBlockType.name === 'browse' || selectedBlockType.name === 'my-blocks') && (
              <Panel header={<h3 className='pre-title'>Browse</h3>} key='1'>
                <div className='button-props-panel__btn-effects-choices choice-btn-group'>
                  {/* <div
                    className={
                      myWorkOrTemplates === 'templates'
                        ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                        : 'button-props-panel__btn-effects-choice choice-btn'
                    }
                    onClick={() => {
                      setMyWorkOrTemplates('templates');
                    }}>
                    Templates
                  </div> */}
                  <div
                    className={
                      myWorkOrTemplates === 'my-work'
                        ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                        : 'button-props-panel__btn-effects-choice choice-btn'
                    }
                    onClick={() => {
                      setMyWorkOrTemplates('my-work');
                    }}>
                    My Work
                  </div>
                  {/*<div*/}
                  {/*    className={*/}
                  {/*      myWorkOrTemplates === 'epro'*/}
                  {/*          ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'*/}
                  {/*          : 'button-props-panel__btn-effects-choice choice-btn'*/}
                  {/*    }*/}
                  {/*    onClick={() => {*/}
                  {/*      setMyWorkOrTemplates('epro');*/}
                  {/*    }}*/}
                  {/*>*/}
                  {/*  ePro*/}
                  {/*</div>*/}
                </div>
                <Select
                  className='dropdown-small fieldType'
                  style={{ width: '100%' }}
                  value={qoosOrBlocks}
                  onChange={(value) => setQoosOrBlocks(value)}>
                  <Select.Option value='qoos' className='fieldType'>
                    Qoos
                  </Select.Option>
                  <Select.Option value='blocks' className='fieldType'>
                    Blocks
                  </Select.Option>
                </Select>
                {qoosOrBlocks === 'qoos' && (
                  <Select
                    placeholder='Select Qoo Type'
                    className='dropdown-small fieldType'
                    style={{ width: '100%', marginTop: '10px' }}
                    value={qooTypeFilter}
                    onChange={(value) => setQooTypeFilter(value)}
                    mode='multiple'>
                    {qooTypes &&
                      qooTypes.length > 0 &&
                      qooTypes.map((qooType) => {
                        return (
                          <Select.Option value={qooType._id} key={qooType._id}>
                            {qooType.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                )}
                {qoosOrBlocks === 'blocks' && (
                  <Select
                    placeholder='Select Block Type'
                    className='dropdown-small fieldType'
                    style={{ width: '100%', marginTop: '10px' }}
                    mode='multiple'
                    value={blockTypeFilter}
                    onChange={(value) => setBlockTypeFilter(value)}>
                    <Select.Option value='basic' className='fieldType'>
                      Basic
                    </Select.Option>
                    <Select.Option value='dynamic' className='fieldType'>
                      Dynamic
                    </Select.Option>
                    <Select.Option value='survey' className='fieldType'>
                      Survey
                    </Select.Option>
                    <Select.Option value='form' className='fieldType'>
                      Form
                    </Select.Option>
                    <Select.Option value='graph' className='fieldType'>
                      Graph
                    </Select.Option>
                    <Select.Option value='table' className='fieldType'>
                      Table
                    </Select.Option>
                  </Select>
                )}
                <Select
                  placeholder='Select Category'
                  className='dropdown-small'
                  style={{ width: '100%', marginTop: '10px' }}
                  mode='multiple'
                  value={categoryFilter}
                  onChange={(value) => setCategoryFilter(value)}>
                  {categories &&
                    categories.length > 0 &&
                    categories.map((category) => {
                      return (
                        <Select.Option
                          value={category._id}
                          key={category._id}
                          label={category.name}>
                          {category.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                <Select
                  placeholder='Select Audience'
                  className='dropdown-small'
                  style={{ width: '100%', marginTop: '10px' }}
                  value={audienceFilter}
                  onChange={(value) => setAudienceFilter(value)}
                  mode='multiple'>
                  {audiences &&
                    audiences.length > 0 &&
                    audiences.map((audience) => {
                      return (
                        <Select.Option
                          value={audience._id}
                          key={audience._id}
                          label={audience.name}>
                          {audience.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                <Input
                  placeholder={
                    myWorkOrTemplates === 'templates'
                      ? 'Search Templates'
                      : qoosOrBlocks === 'qoos'
                      ? 'Search Qoos'
                      : 'Search Blocks'
                  }
                  prefix={<LinkSearch style={{ marginRight: '5px' }} />}
                  allowClear
                  style={{ marginTop: '10px' }}
                  onChange={(e) => handleSearchInputChange(e)}
                />
                <div className='qoolection-qoos-list'>
                  {qoosOrBlocks === 'blocks' &&
                    myWorkOrTemplates === 'templates' &&
                    blockTemplates?.data?.items?.length > 0 &&
                    blockTemplates.data.items.map((template, i) => {
                      return (
                        <BlockDisplay
                          key={`${template._id}_${i}`}
                          block={template}
                          width={148}
                          height={197}
                        />
                      );
                    })}
                  {qoosOrBlocks === 'blocks' &&
                    myWorkOrTemplates === 'my-work' &&
                    blocks?.data?.items?.length > 0 &&
                    blocks?.data?.items?.map((block, i) => {
                      return (
                        <div
                          key={`${block._id}_${i}`}
                          draggable={true}
                          unselectable='on'
                          onDragStart={(e) => {
                            e.dataTransfer.setData('text/plain', '');
                            setDraggingItem(block);
                            e.dataTransfer.setDragImage(
                              document.getElementById(`draggable-${block._id}`),
                              0,
                              0
                            );
                          }}
                          onDragEnd={(e) => setDraggingItem(null)}>
                          <BlockDisplay block={block} width={148} height={197} isDraggable={true} />
                        </div>
                      );
                    })}
                  {qoosOrBlocks === 'blocks' &&
                    myWorkOrTemplates === 'my-work' &&
                    blocks?.data?.items?.length > 0 &&
                    blocks?.data?.items?.map((block, i) => {
                      return (
                        <div
                          key={`${block._id}_${i}`}
                          draggable={true}
                          unselectable='on'
                          onDragStart={(e) => {
                            e.dataTransfer.setData('text/plain', '');
                            setDraggingItem(block);
                            e.dataTransfer.setDragImage(
                              document.getElementById(`draggable-${block._id}`),
                              0,
                              0
                            );
                          }}
                          onDragEnd={(e) => setDraggingItem(null)}>
                          <BlockDisplay block={block} width={148} height={197} isDraggable={true} />
                        </div>
                      );
                    })}
                  {qoosOrBlocks === 'qoos' &&
                    myWorkOrTemplates === 'templates' &&
                    qooTemplates?.data?.items?.length > 0 &&
                    qooTemplates.data.items.map((template, i) => {
                      return (
                        <div
                          key={`${qoo._id}_${i}`}
                          style={{ cursor: 'grab' }}
                          onClick={() => setSelectedTemplate(template)}>
                          <QooDisplay
                            key={template._id}
                            qooInPreview={template}
                            width={96}
                            height={182}
                            inQooBuilder={true}
                          />
                        </div>
                      );
                    })}

                  {selectedTemplate && (
                    <TemplateModal
                      visible={selectedTemplate}
                      closeModal={() => setSelectedTemplate(null)}
                      qoo={selectedTemplate}
                      useTemplate={() => duplicateQoo(selectedTemplate)}
                    />
                  )}
                  {qoosOrBlocks === 'qoos' &&
                    myWorkOrTemplates === 'my-work' &&
                    qoos?.data?.items?.length > 0 &&
                    qoos?.data?.items?.map((qoo, i) => {
                      return (
                        <QooDisplay
                          key={`${qoo._id}_${i}`}
                          qooInPreview={qoo}
                          width={96}
                          height={182}
                          inQooBuilder={true}
                        />
                      );
                    })}

                  {selectedTemplate && (
                    <TemplateModal
                      visible={selectedTemplate}
                      closeModal={() => setSelectedTemplate(null)}
                      qoo={selectedTemplate}
                      useTemplate={() => duplicateQoo(selectedTemplate)}
                    />
                  )}
                  {qoosOrBlocks === 'qoos' &&
                    myWorkOrTemplates === 'my-work' &&
                    qoos?.data?.items?.length > 0 &&
                    qoos?.data?.items?.map((qoo, i) => {
                      return (
                        <QooDisplay
                          key={`${qoo._id}_${i}`}
                          qooInPreview={qoo}
                          width={96}
                          height={182}
                        />
                      );
                    })}
                </div>
              </Panel>
            )}
            {/* {selectedBlockType.name === 'my-blocks' && !blockInPreview && (
              <Panel header={<h3 className='pre-title'>My blocks</h3>} key='12'>
                <BlocksSelector
                  blockType={selectedBlockType}
                  token={token}
                  SERVER_URL={SERVER_URL}
                  setDraggingItem={setDraggingItem}
                />
              </Panel>
            )} */}
            {selectedBlockType.name === 'general' && (
              <>
                {/* <Panel
                  header={(<h3 className='pre-title'>Layers</h3>)}
                  key='2'
                >

                </Panel> */}
                {!blockInPreview && qooInPreview && (
                  <>
                    {/* <Panel header={<h3 className='pre-title'>Header / Footer</h3>} key='3'>
                      <div className='header-footers-edit-row'>
                        <Checkbox
                          checked={qooInPreview.noHeaderFooter}
                          onChange={(e) => {
                            dispatch({
                              type: EDIT_QOO_PROPERTY,
                              payload: {
                                noHeaderFooter: e.target.checked,
                              },
                            });
                          }}
                        >
                          No header/footer
                        </Checkbox>
                        {qooInPreview.headerFooter && (
                          <span
                            className='header-footers-edit-row__edit-btn'
                            onClick={() => setEditHFModalVisible(true)}
                          >
                            Edit
                          </span>
                        )}
                      </div>
                      <div className='header-footers'>
                        {headerFooters &&
                          headerFooters.length > 0 &&
                          headerFooters.map((headerFooter) => {
                            return (
                              <HeaderFooterPreviewQoo
                                key={headerFooter._id}
                                headerFooter={headerFooter}
                                extraSmall={true}
                                selected={qooInPreview.headerFooter?._id === headerFooter._id}
                                callback={() => {
                                  dispatch({
                                    type: EDIT_QOO_PROPERTY,
                                    payload: {
                                      headerFooter,
                                    },
                                  });
                                }}
                              />
                            );
                          })}
                      </div>
                      {editHFModalVisible && (
                        <EditHFModal
                          editHFModalVisible={editHFModalVisible}
                          setEditHFModalVisible={setEditHFModalVisible}
                          theme={qooInPreview.theme}
                          headerFooter={qooInPreview.headerFooter}
                          SERVER_URL={SERVER_URL}
                          token={token}
                          qooDispatch={dispatch}
                          qooDispatchType={EDIT_QOO_PROPERTY}
                          setRerenderToFetchHF={setRerenderToFetchHF}
                        />
                      )}
                    </Panel>

                    <Panel
                      header={(<h3 className='pre-title'>Menu</h3>)}
                      key='4'
                    >
                      <div className="qoo-menu-panel">

                        <div className="qoo-menu-panel__row">
                          <Checkbox
                            checked={qooInPreview.previewMenu}
                            onChange={(e) => {
                              dispatch({
                                type: EDIT_QOO_PROPERTY,
                                payload: {
                                  previewMenu: e.target.checked
                                }
                              });
                            }}
                          >
                            Preview menu
                          </Checkbox>
                        </div>
                        <div className="qoo-menu-panel__row">
                          <div className="qoo-menu-panel__inputs">
                            {qooInPreview.menuLinks && qooInPreview.menuLinks.length > 0 && qooInPreview.menuLinks.map((item, index) => {
                              return (
                                <div className="qoo-menu-panel__link-item">
                                  <div className="qoo-menu-panel__top-inputs">
                                    <Input
                                      className='input-small'
                                      placeholder={`Link ${index + 1}`}
                                      value={item.text}
                                      onChange={(e) => {
                                        dispatch({
                                          type: EDIT_MENU_LINK,
                                          payload: {
                                            linkIndex: index,
                                            updates: {
                                              text: e.target.value
                                            }
                                          }
                                        });
                                      }}
                                    />
                                    <Close
                                      className="qoo-menu-panel__remove-icon"
                                      onClick={() => {
                                        dispatch({
                                          type: REMOVE_MENU_LINK,
                                          payload: {
                                            linkIndex: index
                                          }
                                        });
                                      }}
                                    />
                                  </div>

                                  <div className="qoo-menu-panel__bottom-inputs">
                                    <Select
                                      className='dropdown-small qoo-menu-panel__link-dropdown'
                                      placeholder='Link to'
                                      value={item.linkTo}
                                      onChange={(value) => {
                                        dispatch({
                                          type: EDIT_MENU_LINK,
                                          payload: {
                                            linkIndex: index,
                                            updates: {
                                              linkTo: value
                                            }
                                          }
                                        });
                                      }}
                                    >
                                      <Select.Option value='web link' label='Web Link'>Web Link</Select.Option>
                                      <Select.Option value='qoo link' label='Link Qoo'>Link Qoo</Select.Option>
                                      <Select.Option value='footnotes' label='Footnotes'>Footnotes</Select.Option>
                                      <Select.Option value='plain text' label='Plain text'>Plain text</Select.Option>
                                      <Select.Option value='' label='None'>None</Select.Option>
                                    </Select>
                                    <Input
                                      className='input-small'
                                      // placeholder='https://www.website.com'
                                      disabled={!item.linkTo}
                                      placeholder={item.linkTo ?
                                        item.linkTo === 'web link' ? 'https://www.website.com' : (
                                          item.linkTo === 'plain text' ? 'Type here' : (
                                            item.linkTo === 'qoo link' ? 'Search Qoos' : (
                                              item.linkTo === 'footnotes' ? 'Search Footnotes' : ''
                                            )
                                          )
                                        ) : ''
                                      }
                                      value={item.link}
                                      onChange={(e) => {
                                        dispatch({
                                          type: EDIT_MENU_LINK,
                                          payload: {
                                            linkIndex: index,
                                            updates: {
                                              link: e.target.value
                                            }
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                            <Button
                              block={true}
                              type='primary'
                              size='small'
                              onClick={() => {
                                dispatch({
                                  type: ADD_MENU_LINK
                                });
                              }}
                            >
                              Add Link
                            </Button>
                          </div>

                        </div>

                      </div>

                    </Panel> */}
                    <Panel header={<h3 className='pre-title'>Qoo Background</h3>} key='5'>
                      <div className='text-background'>
                        <div className='text-background__color'>
                          <div className='text-background__bg-color'>
                            <h3 className='pre-title text-background__label'>Color</h3>
                            <ColorPickerDropdown
                              colors={qooInPreview.theme.colorPalette.colors}
                              colorsBlackWhiteGrey={
                                qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                              }
                              selectedColor={
                                qooInPreview.qooBackgroundColor
                                  ? qooInPreview.qooBackgroundColor
                                  : qooInPreview.theme.colorAssignment.qooBackground
                                  ? qooInPreview.theme.colorAssignment.qooBackground
                                  : null
                              }
                              leftRadius
                              rightRadius
                              callback={(color) => {
                                dispatch({
                                  type: EDIT_QOO_PROPERTY,
                                  payload: {
                                    qooBackgroundColor: color
                                  }
                                });
                              }}
                            />
                          </div>
                          <div className='text-background__border'>
                            <h3 className='pre-title text-background__label'>Border</h3>
                            <div className='text-background__border-inputs'>
                              <ColorPickerDropdown
                                colors={qooInPreview.theme.colorPalette.colors}
                                colorsBlackWhiteGrey={
                                  qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                }
                                selectedColor={qooInPreview.qooBorderColor}
                                leftRadius
                                line
                                callback={(color) => {
                                  dispatch({
                                    type: EDIT_QOO_PROPERTY,
                                    payload: {
                                      qooBorderColor: color
                                    }
                                  });
                                }}
                              />
                              <InputNumber
                                className='text-background__border-thickness'
                                controls
                                min={1}
                                max={10}
                                value={qooInPreview.qooBorderThickness * 16}
                                onChange={(value) => {
                                  dispatch({
                                    type: EDIT_QOO_PROPERTY,
                                    payload: {
                                      qooBorderThickness: value / 16
                                    }
                                  });
                                }}
                              />
                              <Select
                                className='dropdown-small text-background__border-type'
                                placeholder='Type'
                                value={qooInPreview.qooBorderType}
                                onChange={(value) => {
                                  dispatch({
                                    type: EDIT_QOO_PROPERTY,
                                    payload: {
                                      qooBorderType: value
                                    }
                                  });
                                }}>
                                <Select.Option value='solid' label='Solid'>
                                  Solid
                                </Select.Option>
                                <Select.Option value='dotted' label='Dotted'>
                                  Dotted
                                </Select.Option>
                                <Select.Option value='dashed' label='Dashed'>
                                  Dashed
                                </Select.Option>
                                <Select.Option value='double' label='Double'>
                                  Double
                                </Select.Option>
                                <Select.Option value='' label='None'>
                                  None
                                </Select.Option>
                              </Select>
                            </div>
                          </div>
                        </div>
                        <div className='text-background__checkbox'>
                          <Checkbox
                            checked={backgroundImageOpen}
                            onChange={() => setBackgroundImageOpen((previous) => !previous)}>
                            Background image
                          </Checkbox>
                        </div>
                        {backgroundImageOpen && (
                          <div className='text-background__images'>
                            <Button
                              block
                              size='small'
                              onClick={() => {
                                dispatch({
                                  type: EDIT_QOO_PROPERTY,
                                  payload: {
                                    qooBackgroundImage: null
                                  }
                                });
                              }}>
                              Remove background image
                            </Button>
                            <BgImagesList
                              token={token}
                              SERVER_URL={SERVER_URL}
                              callback={(imageUrl) => {
                                dispatch({
                                  type: EDIT_QOO_PROPERTY,
                                  payload: {
                                    qooBackgroundImage: imageUrl
                                  }
                                });
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </Panel>
                    <Panel header={<h3 className='pre-title'>Grid Options</h3>} key='6'>
                      <div className='grid-options'>
                        <div className='grid-options__row'>
                          <h4 className='grid-options__label'>Grid style</h4>
                          <div className='grid-options__radio'>
                            <Radio.Group
                              value={gridOptions.style}
                              onChange={(e) =>
                                setGridOptions((previous) => ({
                                  ...previous,
                                  style: e.target.value
                                }))
                              }>
                              <Radio value='dots'>Dots</Radio>
                              <Radio value='lines'>Lines</Radio>
                            </Radio.Group>
                          </div>
                        </div>
                        <div className='grid-options__row'>
                          <h4 className='grid-options__label'>Grid color</h4>
                          <ColorPickerDropdown
                            colors={qooInPreview.theme.colorPalette.colors}
                            colorsBlackWhiteGrey={
                              qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                            }
                            selectedColor={gridOptions.color}
                            leftRadius
                            rightRadius
                            callback={(color) =>
                              setGridOptions((previous) => ({
                                ...previous,
                                color
                              }))
                            }
                            notNullable={true}
                          />
                        </div>
                      </div>
                    </Panel>
                    {/* <Panel header={<h3 className="pre-title">Qoo Layout</h3>} key="7">
                      <div className="grid-options">
                        <div className="grid-options__row grid-options__row--spb">
                          <h4 className="grid-options__label">Header</h4>
                          <Switch
                            checked={qooInPreview.showHeader}
                            disabled={qooInPreview.theme.headerFooter.header.noHeader}
                            onChange={(e) => {
                              dispatch({
                                type: EDIT_QOO_PROPERTY,
                                payload: {
                                  showHeader: !qooInPreview.showHeader
                                }
                              });
                            }}
                          />
                        </div>
                        <div className="grid-options__row grid-options__row--spb">
                          <h4 style={{ minWidth: '120px' }} className="grid-options__label">
                            Info Spotlight
                          </h4>
                          <Switch
                            checked={qooInPreview.showISI}
                            disabled={qooInPreview.theme.headerFooter.footer.noISI}
                            onChange={(e) => {
                              dispatch({
                                type: EDIT_QOO_PROPERTY,
                                payload: qooInPreview.showISI
                                  ? {
                                      showISI: !qooInPreview.showISI,
                                      ISISize: null
                                    }
                                  : {
                                      showISI: !qooInPreview.showISI
                                    }
                              });
                            }}
                          />
                        </div>
                        <div className="grid-options__row grid-options__row--spb">
                          <h4 className="grid-options__label">Footer</h4>
                          <Switch
                            checked={qooInPreview.showFooter}
                            disabled={
                              qooInPreview.theme.headerFooter.footer.noFooter ||
                              qooInPreview.ISISize === 'S'
                            }
                            onChange={(e) => {
                              dispatch({
                                type: EDIT_QOO_PROPERTY,
                                payload: {
                                  showFooter: !qooInPreview.showFooter
                                }
                              });
                            }}
                          />
                        </div>
                        <div className="grid-options__row grid-options__row--spb">
                          <h4 style={{ minWidth: '120px' }} className="grid-options__label">
                            Info Spotlight size
                          </h4>
                          <div className="grid-options__ISI-size">
                            <div
                              className={
                                qooInPreview.theme.headerFooter.footer.noFooter ||
                                !qooInPreview.showFooter ||
                                qooInPreview.theme.headerFooter.footer.noISI ||
                                !qooInPreview.showISI
                                  ? 'choice-btn choice-btn--sm  choice-btn--disabled'
                                  : qooInPreview.ISISize === 'S'
                                  ? 'choice-btn choice-btn--sm  choice-btn--selected'
                                  : 'choice-btn choice-btn--sm'
                              }
                              onClick={() => {
                                if (
                                  !qooInPreview.theme.headerFooter.footer.noFooter &&
                                  qooInPreview.showFooter &&
                                  !qooInPreview.theme.headerFooter.footer.noISI &&
                                  qooInPreview.showISI
                                ) {
                                  dispatch({
                                    type: EDIT_QOO_PROPERTY,
                                    payload: {
                                      ISISize: 'S'
                                    }
                                  });
                                }
                              }}>
                              S
                            </div>
                            <div
                              className={
                                qooInPreview.theme.headerFooter.footer.noISI ||
                                !qooInPreview.showISI
                                  ? 'choice-btn choice-btn--sm  choice-btn--disabled'
                                  : qooInPreview.ISISize === 'M'
                                  ? 'choice-btn choice-btn--sm  choice-btn--selected'
                                  : 'choice-btn choice-btn--sm'
                              }
                              onClick={() => {
                                if (
                                  qooInPreview.showISI &&
                                  !qooInPreview.theme.headerFooter.footer.noISI
                                ) {
                                  dispatch({
                                    type: EDIT_QOO_PROPERTY,
                                    payload: {
                                      ISISize: 'M'
                                    }
                                  });
                                }
                              }}>
                              M
                            </div>
                            <div
                              className={
                                qooInPreview.theme.headerFooter.footer.noISI ||
                                !qooInPreview.showISI
                                  ? 'choice-btn choice-btn--sm  choice-btn--disabled'
                                  : qooInPreview.ISISize === 'L'
                                  ? 'choice-btn choice-btn--sm  choice-btn--selected'
                                  : 'choice-btn choice-btn--sm'
                              }
                              onClick={() => {
                                if (
                                  qooInPreview.showISI &&
                                  !qooInPreview.theme.headerFooter.footer.noISI
                                ) {
                                  dispatch({
                                    type: EDIT_QOO_PROPERTY,
                                    payload: {
                                      ISISize: 'L'
                                    }
                                  });
                                }
                              }}>
                              L
                            </div>
                          </div>
                        </div>
                      </div>
                    </Panel>
                    <Panel header={<h3 className="pre-title">Info</h3>} key="8">
                      <div className="add-info-form">
                        <div className="add-info-form__item">
                          <Input
                            placeholder="Name"
                            value={qooInPreview.title}
                            onChange={(e) => {
                              dispatch({
                                type: EDIT_QOO_PROPERTY,
                                payload: {
                                  title: e.target.value
                                }
                              });
                            }}
                          />
                        </div>
                        <div className="add-info-form__item">
                          <Select
                            mode="multiple"
                            placeholder="Categories"
                            className="dropdown-small"
                            value={qooInPreview.category}
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                            onChange={(value) => {
                              dispatch({
                                type: EDIT_QOO_PROPERTY,
                                payload: {
                                  category: value
                                }
                              });
                            }}>
                            {categories &&
                              categories.length > 0 &&
                              categories.map((category) => {
                                return (
                                  <Select.Option
                                    value={category._id}
                                    key={category._id}
                                    label={category.name}>
                                    {category.name}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </div>
                        <div className="add-info-form__item">
                          <Select
                            mode="multiple"
                            placeholder="Audiences"
                            className="dropdown-small"
                            value={qooInPreview.audience}
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                            onChange={(value) => {
                              dispatch({
                                type: EDIT_QOO_PROPERTY,
                                payload: {
                                  audience: value
                                }
                              });
                            }}>
                            {audiences &&
                              audiences.length > 0 &&
                              audiences.map((audience) => {
                                return (
                                  <Select.Option
                                    value={audience._id}
                                    key={audience._id}
                                    label={audience.name}>
                                    {audience.name}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </div>
                        <div className="add-info-form__item">
                          <Input.TextArea
                            rows={4}
                            placeholder="Notes"
                            value={qooInPreview.notes}
                            onChange={(e) => {
                              dispatch({
                                type: EDIT_QOO_PROPERTY,
                                payload: {
                                  notes: e.target.value
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Panel> */}
                  </>
                )}
                {blockInPreview && (
                  <>
                    <Panel header={<h3 className='pre-title'>Block background</h3>} key='9'>
                      <div className='text-background'>
                        <div className='text-background__color'>
                          <div className='text-background__bg-color'>
                            <h3 className='pre-title text-background__label'>Color</h3>
                            <ColorPickerDropdown
                              colors={qooInPreview.theme.colorPalette.colors}
                              colorsBlackWhiteGrey={
                                qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                              }
                              selectedColor={blockInPreview.blockProperties.blockBackgroundColor}
                              leftRadius
                              rightRadius
                              callback={(color) => {
                                blockInPreviewDispatch({
                                  type: EDIT_BLOCK_PROPERTY,
                                  payload: {
                                    blockBackgroundColor: color
                                  }
                                });
                              }}
                            />
                          </div>
                          <div className='text-background__border'>
                            <h3 className='pre-title text-background__label'>Border</h3>
                            <div className='text-background__border-inputs'>
                              <ColorPickerDropdown
                                colors={qooInPreview.theme.colorPalette.colors}
                                colorsBlackWhiteGrey={
                                  qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                }
                                selectedColor={blockInPreview.blockProperties.blockBorderColor}
                                leftRadius
                                line
                                callback={(color) => {
                                  blockInPreviewDispatch({
                                    type: EDIT_BLOCK_PROPERTY,
                                    payload: {
                                      blockBorderColor: color
                                    }
                                  });
                                }}
                              />
                              <InputNumber
                                className='text-background__border-thickness'
                                controls
                                min={1}
                                max={10}
                                value={blockInPreview.blockProperties.blockBorderThickness * 16}
                                onChange={(value) => {
                                  blockInPreviewDispatch({
                                    type: EDIT_BLOCK_PROPERTY,
                                    payload: {
                                      blockBorderThickness: value / 16
                                    }
                                  });
                                }}
                              />
                              <Select
                                className='dropdown-small text-background__border-type'
                                placeholder='Type'
                                value={blockInPreview.blockProperties.blockBorderType}
                                onChange={(value) => {
                                  blockInPreviewDispatch({
                                    type: EDIT_BLOCK_PROPERTY,
                                    payload: {
                                      blockBorderType: value
                                    }
                                  });
                                }}>
                                <Select.Option value='solid' label='Solid'>
                                  Solid
                                </Select.Option>
                                <Select.Option value='dotted' label='Dotted'>
                                  Dotted
                                </Select.Option>
                                <Select.Option value='dashed' label='Dashed'>
                                  Dashed
                                </Select.Option>
                                <Select.Option value='double' label='Double'>
                                  Double
                                </Select.Option>
                                <Select.Option value='' label='None'>
                                  None
                                </Select.Option>
                              </Select>
                            </div>
                          </div>
                        </div>
                        <div className='text-background__checkbox'>
                          <Checkbox
                            checked={blockBackgroundImageOpen}
                            onChange={() => setBlockBackgroundImageOpen((previous) => !previous)}>
                            Background image
                          </Checkbox>
                        </div>
                        {blockBackgroundImageOpen && (
                          <div className='text-background__images'>
                            <Button
                              block
                              size='small'
                              onClick={() => {
                                blockInPreviewDispatch({
                                  type: EDIT_BLOCK_PROPERTY,
                                  payload: {
                                    blockBackgroundImage: null
                                  }
                                });
                              }}>
                              Remove background image
                            </Button>
                            <BgImagesList
                              token={token}
                              SERVER_URL={SERVER_URL}
                              callback={(imageUrl) => {
                                blockInPreviewDispatch({
                                  type: EDIT_BLOCK_PROPERTY,
                                  payload: {
                                    blockBackgroundImage: imageUrl
                                  }
                                });
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </Panel>
                    <Panel header={<h3 className='pre-title'>Info</h3>} key='10'>
                      <div className='add-info-form'>
                        <div className='add-info-form__item'>
                          <h4 className='add-info-form__label'>Name *</h4>
                          <Input
                            placeholder='Name'
                            value={blockInPreview.name}
                            onChange={(e) => {
                              blockInPreviewDispatch({
                                type: EDIT_BLOCK_FIELD,
                                payload: {
                                  name: e.target.value
                                }
                              });
                            }}
                          />
                        </div>
                        <div className='add-info-form__item'>
                          <Select
                            mode='multiple'
                            placeholder='Categories'
                            className='dropdown-small'
                            value={blockInPreview.category}
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                            onChange={(value) => {
                              blockInPreviewDispatch({
                                type: EDIT_BLOCK_FIELD,
                                payload: {
                                  category: value
                                }
                              });
                            }}>
                            {categories &&
                              categories.length > 0 &&
                              categories.map((category) => {
                                return (
                                  <Select.Option
                                    value={category._id}
                                    key={category._id}
                                    label={category.name}>
                                    {category.name}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </div>
                        <div className='add-info-form__item'>
                          <Select
                            mode='multiple'
                            placeholder='Audiences'
                            className='dropdown-small'
                            value={blockInPreview.audience}
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                            onChange={(value) => {
                              blockInPreviewDispatch({
                                type: EDIT_BLOCK_FIELD,
                                payload: {
                                  audience: value
                                }
                              });
                            }}>
                            {audiences &&
                              audiences.length > 0 &&
                              audiences.map((audience) => {
                                return (
                                  <Select.Option
                                    value={audience._id}
                                    key={audience._id}
                                    label={audience.name}>
                                    {audience.name}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </div>
                        <div className='add-info-form__item'>
                          <Input.TextArea
                            rows={4}
                            placeholder='Notes'
                            value={blockInPreview.notes}
                            onChange={(e) => {
                              blockInPreviewDispatch({
                                type: EDIT_BLOCK_FIELD,
                                payload: {
                                  notes: e.target.value
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Panel>
                  </>
                )}
              </>
            )}
            {selectedBlockType.name === 'info' && !blockInPreview && qooInPreview && (
              <>
                <Panel header={<h3 className='pre-title'>Info</h3>} key='11'>
                  <div className='add-info-form'>
                    <div className='add-info-form__item'>
                      <Input
                        id='qoo_builder_info_name'
                        placeholder='Name'
                        value={qooInPreview.title}
                        onChange={(e) => {
                          dispatch({
                            type: EDIT_QOO_PROPERTY,
                            payload: {
                              title: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                    {showQooType && (
                      <div className='add-info-form__item'>
                        <Select
                          id='qoo_builder_info_qoo_type'
                          placeholder='Type'
                          className='dropdown-small'
                          value={qooInPreview?.qooType?._id || qooInPreview?.qooType}
                          onChange={(value) => {
                            dispatch({
                              type: EDIT_QOO_PROPERTY,
                              payload: {
                                qooType: value
                              }
                            });
                          }}>
                          {generalQooType && (
                            <Select.Option
                              value={generalQooType[0]._id}
                              key={generalQooType[0]._id}>
                              {generalQooType[0].name}
                            </Select.Option>
                          )}
                          {qooTypes &&
                            qooTypes.length > 0 &&
                            qooTypes
                              .filter(
                                (item) =>
                                  item.name !== 'General' &&
                                  (currentuser.data.role.includes('superadmin')
                                    ? true
                                    : item.name !== 'ePro')
                              )
                              .sort((a, b) => (a.name < b.name ? -1 : 1))
                              .map((qooType) => {
                                return (
                                  <Select.Option value={qooType._id} key={qooType._id}>
                                    {qooType.name}
                                  </Select.Option>
                                );
                              })}
                        </Select>
                      </div>
                    )}
                    <div className='add-info-form__item'>
                      <Select
                        mode='multiple'
                        placeholder='Categories'
                        className='dropdown-small'
                        value={qooInPreview.category}
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={(value) => {
                          dispatch({
                            type: EDIT_QOO_PROPERTY,
                            payload: {
                              category: value
                            }
                          });
                        }}>
                        {categories &&
                          categories.length > 0 &&
                          categories.map((category) => {
                            return (
                              <Select.Option
                                value={category._id}
                                key={category._id}
                                label={category.name}>
                                {category.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </div>
                    <div className='add-info-form__item'>
                      <Select
                        mode='multiple'
                        placeholder='Audiences'
                        className='dropdown-small'
                        value={qooInPreview.audience}
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={(value) => {
                          dispatch({
                            type: EDIT_QOO_PROPERTY,
                            payload: {
                              audience: value
                            }
                          });
                        }}>
                        {audiences &&
                          audiences.length > 0 &&
                          audiences.map((audience) => {
                            return (
                              <Select.Option
                                value={audience._id}
                                key={audience._id}
                                label={audience.name}>
                                {audience.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </div>
                    <div className='add-info-form__item'>
                      <Input.TextArea
                        rows={4}
                        placeholder='Notes'
                        value={qooInPreview.notes}
                        onChange={(e) => {
                          dispatch({
                            type: EDIT_QOO_PROPERTY,
                            payload: {
                              notes: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                </Panel>
              </>
            )}
            {selectedBlockType.name === 'ePro' && currentuser.data.role.includes('superadmin') && (
              <>
                <Panel key='13' header={<h3 className='pre-title'>Layout</h3>}>
                  <div className='add-info-form__item'>
                    <Select
                      id='qoo_builder_info_qoo_type'
                      placeholder='Choose Question'
                      className='dropdown-small'
                      value={selectedQuestion}
                      onChange={(value) => {
                        setSelectedQuestion(value);
                      }}>
                      {questions.data &&
                        questions.data.items.length > 0 &&
                        questions.data.items.map((question, index) => (
                          <Select.Option value={question._id} key={index}>
                            {question.question}
                          </Select.Option>
                        ))}
                    </Select>
                    {!qooInPreview.blocksLayout.some((item) => item.block.name === 'question') && (
                      <Button
                        style={{ width: '25%', marginTop: '20px' }}
                        onClick={addBlockHandler}
                        type='primary'>
                        Add
                      </Button>
                    )}
                  </div>
                  {eProQuestion.length > 0 && (
                    <div>
                      <h3 className='pre-title'>Question</h3>
                      {eProQuestion.length > 0 &&
                        eProQuestion.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: '10px'
                            }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                              }}>
                              <QuestionIcon />
                              <div
                                style={{
                                  fontSize: '8px',
                                  fontWeight: 'normal',
                                  marginBottom: '-8px',
                                  marginLeft: '10px'
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: item.block.blockProperties.textContent
                                }}></div>
                            </div>
                            <div style={{ cursor: 'pointer' }}>
                              <DeleteIcon
                                onClick={() => {
                                  setIsFirstQoo(false);
                                  setIsLastQoo(false);
                                  setSelectedQuestion('Choose question');
                                  dispatch({
                                    type: REMOVE_QUESTION_FROM_QOO,
                                    payload: {
                                      questionId: item.block.question
                                    }
                                  });
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      <h3 style={{ marginTop: '10px', marginBottom: '10px' }} className='pre-title'>
                        Answers
                      </h3>
                      {qooInPreview.blocksLayout
                        .filter((block) => block.block.name === 'button')
                        .map((item, index) => {
                          let plainText = item.block.blockProperties.buttonText.replace(
                            /<[^>]+>/g,
                            ''
                          );
                          return (
                            <Input
                              onChange={(e) => {
                                dispatch({
                                  type: EDIT_ANSWER_TEXT,
                                  payload: {
                                    _id: item.block._id,
                                    text: e.target.value
                                  }
                                });
                              }}
                              style={{ marginBottom: '10px' }}
                              value={plainText}
                            />
                          );
                        })}
                    </div>
                  )}
                </Panel>
                <Panel key='14' header={<h3 className='pre-title'>Navigation</h3>}>
                  <div className='add-info-form__item'>
                    <div>
                      <Checkbox
                        checked={isFirstQoo}
                        onChange={() => {
                          const block = qooInPreview.blocksLayout.find(
                            (block) => block.block.name === 'previousButton'
                          );
                          if (qooInPreview.blocksLayout.length > 0) {
                            !isFirstQoo
                              ? dispatch({
                                  type: DELETE_NAVIGATION_BUTTON,
                                  payload: {
                                    _id: block?.block._id,
                                    buttonToEdit: isLastQoo ? 'submitButton' : 'nextButton'
                                  }
                                })
                              : addNavigationButton('previousButton');
                          }
                          setIsFirstQoo((previousValue) => !previousValue);
                        }}>
                        This is first qoo
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        checked={isLastQoo}
                        onChange={(e) => {
                          const block = qooInPreview.blocksLayout.find(
                            (block) =>
                              block.block.name === 'nextButton' ||
                              block.block.name === 'submitButton'
                          );
                          if (qooInPreview.blocksLayout.length > 0 && block) {
                            dispatch({
                              type: EDIT_NAVIGATION_BUTTON,
                              payload: {
                                _id: block?.block._id,
                                isLastQoo: !isLastQoo
                              }
                            });
                          }
                          setIsLastQoo((previousValue) => !previousValue);
                        }}>
                        This is last qoo
                      </Checkbox>
                    </div>
                  </div>
                </Panel>
              </>
            )}
            {selectedBlockType.name === 'text' && (
              <>
                <Panel header={<h3 className='pre-title'>Text</h3>} key='13'>
                  <div className='text-formatting'>
                    <div
                      className='text-formatting__item text-formatting__item--heading'
                      style={{
                        fontFamily: headingFont.name,
                        fontStyle: headingFont.style,
                        fontWeight: headingFont.weight
                      }}
                      onClick={() => addEmptyBlock('heading')}>
                      Add a heading
                    </div>
                    <div
                      className='text-formatting__item text-formatting__item--subheading'
                      style={{
                        fontFamily: subheadingFont.name,
                        fontStyle: subheadingFont.style,
                        fontWeight: subheadingFont.weight
                      }}
                      onClick={() => addEmptyBlock('subheading')}>
                      Add a subheading
                    </div>
                    <div
                      className='text-formatting__item text-formatting__item--body'
                      style={{
                        fontFamily: bodyTextFont.name,
                        fontStyle: bodyTextFont.style,
                        fontWeight: bodyTextFont.weight
                      }}
                      onClick={() => addEmptyBlock('body')}>
                      Add some body text
                    </div>
                  </div>
                </Panel>

                <Panel header={<h3 className='pre-title'>Text padding</h3>} key='14'>
                  <div className='text-padding'>
                    <div className='text-padding__icons'>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'text'
                            ? 'text-padding__btn text-padding__btn--disabled'
                            : selectedPaddingSide === 'textPaddingTop'
                            ? 'text-padding__btn text-padding__btn--selected'
                            : 'text-padding__btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'text'
                          ) {
                            setSelectedPaddingSide('textPaddingTop');
                          }
                        }}>
                        <AlignTopIcon />
                        <span>Top</span>
                      </div>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'text'
                            ? 'text-padding__btn text-padding__btn--disabled'
                            : selectedPaddingSide === 'textPaddingBottom'
                            ? 'text-padding__btn text-padding__btn--selected'
                            : 'text-padding__btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'text'
                          ) {
                            setSelectedPaddingSide('textPaddingBottom');
                          }
                        }}>
                        <AlignBottomIcon />
                        <span>Bottom</span>
                      </div>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'text'
                            ? 'text-padding__btn text-padding__btn--disabled'
                            : selectedPaddingSide === 'textPaddingLeft'
                            ? 'text-padding__btn text-padding__btn--selected'
                            : 'text-padding__btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'text'
                          ) {
                            setSelectedPaddingSide('textPaddingLeft');
                          }
                        }}>
                        <AlignLeftIcon />
                        <span>Left</span>
                      </div>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'text'
                            ? 'text-padding__btn text-padding__btn--disabled'
                            : selectedPaddingSide === 'textPaddingRight'
                            ? 'text-padding__btn text-padding__btn--selected'
                            : 'text-padding__btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'text'
                          ) {
                            setSelectedPaddingSide('textPaddingRight');
                          }
                        }}>
                        <AlignRightIcon />
                        <span>Right</span>
                      </div>
                    </div>
                    <div className='text-padding__slider'>
                      <Slider
                        min={0}
                        max={100}
                        disabled={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'text'
                        }
                        tooltipVisible={false}
                        value={highlightedBlock?.block.blockProperties[`${selectedPaddingSide}`]}
                        onChange={(value) =>
                          updateSingleElementProperties(highlightedBlock?.block._id, {
                            [`${selectedPaddingSide}`]: value
                          })
                        }
                      />
                      {highlightedBlock && highlightedBlock?.block.blockType.name === 'text' && (
                        <span>
                          {highlightedBlock?.block.blockProperties[`${selectedPaddingSide}`]}
                        </span>
                      )}
                    </div>
                  </div>
                </Panel>
                {/* <Panel header={<h3 className='pre-title'>Compliance</h3>} key='10'></Panel> */}
              </>
            )}
            {selectedBlockType.name === 'button' && (
              <>
                <Panel header={<h3 className='pre-title'>Buttons</h3>} key='15'>
                  <div className='buttons-templates'>
                    {buttonTemplates.length > 0 &&
                      buttonTemplates.map((template) => {
                        return (
                          <div
                            key={template.id}
                            className='buttons-templates__container'
                            style={{ fontSize: `${(310 / 570) * 16}px` }}
                            onClick={() =>
                              addEmptyBlock('button', {
                                ...template,
                                buttonText: template.buttonText.replace(
                                  '**default**',
                                  bodyTextFont.name.toLowerCase().replace(' -', '')
                                )
                              })
                            }>
                            <div
                              className='buttons-templates__button'
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: `${template.buttonWidth}%`,
                                height: `${template.buttonHeight}%`,
                                backgroundColor: template.buttonBackgroundColor?.hex,
                                border: template.buttonBorderType
                                  ? `${template.buttonBorderThickness} ${template.buttonBorderType} ${template.buttonBorderColor?.hex}`
                                  : '',
                                borderRadius: template.buttonBorderRadius
                              }}
                              dangerouslySetInnerHTML={{
                                __html: template.buttonText.replace(
                                  '**default**',
                                  bodyTextFont.name.toLowerCase().replace(' -', '')
                                )
                              }}></div>
                          </div>
                        );
                      })}
                  </div>
                </Panel>
                <Panel header={<h3 className='pre-title'>Button size &amp; color</h3>} key='16'>
                  <div className='button-props-panel__section-wrapper button-props-panel__section-wrapper'>
                    <h3 className='pre-title button-props-panel__label'>SIZE</h3>
                    <div className='button-props-panel__btn-sizes'>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'button'
                            ? 'button-props-panel__btn-size button-props-panel__btn-size--disabled'
                            : selectedBtnDimension === 'buttonWidth'
                            ? 'button-props-panel__btn-size button-props-panel__btn-size--active'
                            : 'button-props-panel__btn-size'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'button'
                          ) {
                            setSelectedBtnDimension('buttonWidth');
                          }
                        }}>
                        <div className='button-props-panel__btn-size-icon'>
                          <BtnWidthIcon />
                        </div>
                        <span className='button-props-panel__btn-size-label'>Width</span>
                      </div>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'button'
                            ? 'button-props-panel__btn-size button-props-panel__btn-size--disabled'
                            : selectedBtnDimension === 'buttonHeight'
                            ? 'button-props-panel__btn-size button-props-panel__btn-size--active'
                            : 'button-props-panel__btn-size'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'button'
                          ) {
                            setSelectedBtnDimension('buttonHeight');
                          }
                        }}>
                        <div className='button-props-panel__btn-size-icon'>
                          <BtnHeightIcon />
                        </div>
                        <span className='button-props-panel__btn-size-label'>Height</span>
                      </div>
                    </div>
                    <div className='button-props-panel__slider'>
                      <Slider
                        min={50}
                        max={100}
                        disabled={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'button'
                        }
                        tooltipVisible={false}
                        value={
                          highlightedBlock?.block.blockProperties[`${selectedBtnDimension}`] || 75
                        }
                        onChange={(value) =>
                          updateSingleElementProperties(highlightedBlock?.block._id, {
                            [`${selectedBtnDimension}`]: value
                          })
                        }
                      />
                      {highlightedBlock && highlightedBlock?.block.blockType.name === 'button' && (
                        <span>
                          {highlightedBlock?.block.blockProperties[`${selectedBtnDimension}`]}%
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='button-props-panel__section-wrapper button-props-panel__section-wrapper'>
                    <div className='button-props-panel__color'>
                      <div className='button-props-panel__btn-color'>
                        <h3 className='pre-title button-props-panel__label'>Color</h3>
                        <ColorPickerDropdown
                          colors={qooInPreview.theme.colorPalette.colors}
                          colorsBlackWhiteGrey={
                            qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                          }
                          selectedColor={
                            highlightedBlock?.block.blockProperties.buttonBackgroundColor
                          }
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'button'
                          }
                          leftRadius
                          rightRadius
                          callback={(color) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              buttonBackgroundColor: color
                            })
                          }
                        />
                      </div>
                      <div className='button-props-panel__btn-color'>
                        <h3 className='pre-title button-props-panel__label'>Active Color</h3>
                        <ColorPickerDropdown
                          colors={qooInPreview.theme.colorPalette.colors}
                          colorsBlackWhiteGrey={
                            qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                          }
                          selectedColor={
                            highlightedBlock?.block.blockProperties.buttonBackgroundColorActive
                          }
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'button'
                          }
                          leftRadius
                          rightRadius
                          callback={(color) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              buttonBackgroundColorActive: color
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className='button-props-panel__section-wrapper button-props-panel__section-wrapper'>
                    <div className='button-props-panel__border'>
                      <h3 className='pre-title button-props-panel__label'>Border</h3>
                      <div className='button-props-panel__border-inputs'>
                        <ColorPickerDropdown
                          colors={qooInPreview.theme.colorPalette.colors}
                          colorsBlackWhiteGrey={
                            qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                          }
                          selectedColor={highlightedBlock?.block.blockProperties.buttonBorderColor}
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'button'
                          }
                          leftRadius
                          callback={(color) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              buttonBorderColor: color
                            })
                          }
                        />
                        <InputNumber
                          className='button-props-panel__border-thickness'
                          min={1}
                          max={10}
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'button'
                          }
                          value={
                            highlightedBlock?.block.blockProperties.buttonBorderThickness.replace(
                              'em',
                              ''
                            ) * 16
                          }
                          onChange={(value) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              buttonBorderThickness: `${value / 16}em`
                            })
                          }
                        />
                        <Select
                          className='dropdown-small button-props-panel__border-type'
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'button'
                          }
                          value={highlightedBlock?.block.blockProperties.buttonBorderType}
                          onChange={(value) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              buttonBorderType: value
                            })
                          }>
                          <Select.Option value='solid' label='Solid'>
                            Solid
                          </Select.Option>
                          <Select.Option value='dotted' label='Dotted'>
                            Dotted
                          </Select.Option>
                          <Select.Option value='dashed' label='Dashed'>
                            Dashed
                          </Select.Option>
                          <Select.Option value='double' label='Double'>
                            Double
                          </Select.Option>
                          <Select.Option value='' label='None'>
                            None
                          </Select.Option>
                        </Select>
                      </div>
                    </div>
                    <div className='button-props-panel__border'>
                      <h3 className='pre-title button-props-panel__label'>Active Border</h3>
                      <div className='button-props-panel__border-inputs'>
                        <ColorPickerDropdown
                          colors={qooInPreview.theme.colorPalette.colors}
                          colorsBlackWhiteGrey={
                            qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                          }
                          selectedColor={
                            highlightedBlock?.block.blockProperties.buttonBorderColorActive
                          }
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'button'
                          }
                          leftRadius
                          callback={(color) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              buttonBorderColorActive: color
                            })
                          }
                        />
                        <InputNumber
                          className='button-props-panel__border-thickness'
                          min={1}
                          max={10}
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'button'
                          }
                          value={
                            highlightedBlock?.block.blockProperties.buttonBorderThicknessActive?.replace(
                              'em',
                              ''
                            ) * 16
                          }
                          onChange={(value) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              buttonBorderThicknessActive: `${value / 16}em`
                            })
                          }
                        />
                        <Select
                          className='dropdown-small button-props-panel__border-type'
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'button'
                          }
                          value={highlightedBlock?.block.blockProperties.buttonBorderTypeActive}
                          onChange={(value) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              buttonBorderTypeActive: value
                            })
                          }>
                          <Select.Option value='solid' label='Solid'>
                            Solid
                          </Select.Option>
                          <Select.Option value='dotted' label='Dotted'>
                            Dotted
                          </Select.Option>
                          <Select.Option value='dashed' label='Dashed'>
                            Dashed
                          </Select.Option>
                          <Select.Option value='double' label='Double'>
                            Double
                          </Select.Option>
                          <Select.Option value='' label='None'>
                            None
                          </Select.Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className='button-props-panel__section-wrapper button-props-panel__section-wrapper'>
                    <div className='button-props-panel__btn-shape'>
                      <h3 className='pre-title button-props-panel__label'>Shape</h3>
                      <div className='button-props-panel__slider'>
                        <Slider
                          min={0}
                          max={30000}
                          step={625}
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'button'
                          }
                          tooltipVisible={false}
                          value={
                            highlightedBlock?.block.blockProperties.buttonBorderRadius.replace(
                              'em',
                              ''
                            ) * 10000
                          }
                          onChange={(value) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              buttonBorderRadius: `${value / 10000}em`
                            })
                          }
                        />
                        {highlightedBlock &&
                          highlightedBlock?.block.blockType.name === 'button' && (
                            <span>
                              {highlightedBlock?.block.blockProperties.buttonBorderRadius.replace(
                                'em',
                                ''
                              ) * 16}
                              px
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                  <div
                    className='block-event__element'
                    style={{ display: 'flex', gap: '0.625rem', justifyContent: 'flex-end' }}>
                    <Switch
                      checked={showButtonAsActive}
                      onChange={(checked) => setShowButtonAsActive(!showButtonAsActive)}
                    />
                    <span>Show Active State</span>
                  </div>
                </Panel>
                <Panel header={<h3 className='pre-title'>Shadow</h3>} key='17'>
                  <div className='button-props-panel__btn-effects'>
                    <div className='button-props-panel__btn-effects-choices choice-btn-group'>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'button'
                            ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--disabled'
                            : highlightedBlock?.block.blockProperties.buttonHasShadow &&
                              !highlightedBlock?.block.blockProperties.buttonInnerShadow
                            ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                            : 'button-props-panel__btn-effects-choice choice-btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'button'
                          ) {
                            const updates = highlightedBlock?.block.blockProperties.buttonHasShadow
                              ? highlightedBlock?.block.blockProperties.buttonInnerShadow
                                ? {
                                    buttonInnerShadow: false,
                                    buttonHasShadow: true
                                  }
                                : {
                                    buttonHasShadow: false
                                  }
                              : {
                                  buttonInnerShadow: false,
                                  buttonHasShadow: true
                                };
                            updateSingleElementProperties(highlightedBlock?.block._id, updates);
                          }
                        }}>
                        Drop Shadow
                      </div>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'button'
                            ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--disabled'
                            : highlightedBlock?.block.blockProperties.buttonHasShadow &&
                              highlightedBlock?.block.blockProperties.buttonInnerShadow
                            ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                            : 'button-props-panel__btn-effects-choice choice-btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'button'
                          ) {
                            const updates = highlightedBlock?.block.blockProperties.buttonHasShadow
                              ? highlightedBlock?.block.blockProperties.buttonInnerShadow
                                ? {
                                    buttonHasShadow: false
                                  }
                                : {
                                    buttonInnerShadow: true,
                                    buttonHasShadow: true
                                  }
                              : {
                                  buttonInnerShadow: true,
                                  buttonHasShadow: true
                                };

                            updateSingleElementProperties(highlightedBlock?.block._id, updates);
                          }
                        }}>
                        Inner Shadow
                      </div>
                    </div>
                    {advancedMode && (
                      <>
                        <div className='button-props-panel__btn-effects-color'>
                          <h3 className='pre-title button-props-panel__label'>Color</h3>
                          <ColorPickerDropdown
                            colors={qooInPreview.theme.colorPalette.colors}
                            colorsBlackWhiteGrey={
                              qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                            }
                            selectedColor={
                              highlightedBlock?.block.blockProperties.buttonShadowColor || null
                            }
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock?.block.blockType.name !== 'button'
                            }
                            leftRadius
                            rightRadius
                            callback={(color) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                buttonShadowColor: color
                              })
                            }
                          />
                        </div>
                        <div className='button-props-panel__btn-effects-color'>
                          <h3 className='pre-title button-props-panel__label'>X position</h3>
                          <div className='button-props-panel__slider'>
                            <Slider
                              min={-100}
                              max={100}
                              disabled={
                                !highlightedBlock ||
                                highlightedBlock?.block.blockType.name !== 'button'
                              }
                              tooltipVisible={false}
                              value={
                                highlightedBlock?.block.blockProperties.buttonShadowX
                                  ? highlightedBlock?.block.blockProperties.buttonShadowX.replace(
                                      'em',
                                      ''
                                    ) * 100
                                  : 0
                              }
                              onChange={(value) =>
                                updateSingleElementProperties(highlightedBlock?.block._id, {
                                  buttonShadowX: `${value / 100}em`
                                })
                              }
                            />
                            {highlightedBlock &&
                              highlightedBlock?.block.blockType.name === 'button' && (
                                <span>
                                  {highlightedBlock?.block.blockProperties.buttonShadowX
                                    ? Math.round(
                                        highlightedBlock?.block.blockProperties.buttonShadowX.replace(
                                          'em',
                                          ''
                                        ) * 100
                                      )
                                    : 0}
                                </span>
                              )}
                          </div>
                          <h3 className='pre-title button-props-panel__label'>Y position</h3>
                          <div className='button-props-panel__slider'>
                            <Slider
                              min={-100}
                              max={100}
                              disabled={
                                !highlightedBlock ||
                                highlightedBlock?.block.blockType.name !== 'button'
                              }
                              tooltipVisible={false}
                              value={
                                highlightedBlock?.block.blockProperties.buttonShadowY
                                  ? highlightedBlock?.block.blockProperties.buttonShadowY.replace(
                                      'em',
                                      ''
                                    ) * 100
                                  : 0
                              }
                              onChange={(value) =>
                                updateSingleElementProperties(highlightedBlock?.block._id, {
                                  buttonShadowY: `${value / 100}em`
                                })
                              }
                            />
                            {highlightedBlock &&
                              highlightedBlock?.block.blockType.name === 'button' && (
                                <span>
                                  {highlightedBlock?.block.blockProperties.buttonShadowY
                                    ? Math.round(
                                        highlightedBlock?.block.blockProperties.buttonShadowY.replace(
                                          'em',
                                          ''
                                        ) * 100
                                      )
                                    : 0}
                                </span>
                              )}
                          </div>
                          <h3 className='pre-title button-props-panel__label'>Blur</h3>
                          <div className='button-props-panel__slider'>
                            <Slider
                              min={0}
                              max={100}
                              disabled={
                                !highlightedBlock ||
                                highlightedBlock?.block.blockType.name !== 'button'
                              }
                              tooltipVisible={false}
                              value={
                                highlightedBlock?.block.blockProperties.buttonShadowBlur
                                  ? highlightedBlock?.block.blockProperties.buttonShadowBlur.replace(
                                      'em',
                                      ''
                                    ) * 100
                                  : 0
                              }
                              onChange={(value) =>
                                updateSingleElementProperties(highlightedBlock?.block._id, {
                                  buttonShadowBlur: `${value / 100}em`
                                })
                              }
                            />
                            {highlightedBlock &&
                              highlightedBlock?.block.blockType.name === 'button' && (
                                <span>
                                  {highlightedBlock?.block.blockProperties.buttonShadowBlur
                                    ? Math.round(
                                        highlightedBlock?.block.blockProperties.buttonShadowBlur.replace(
                                          'em',
                                          ''
                                        ) * 100
                                      )
                                    : 0}
                                </span>
                              )}
                          </div>
                          <h3 className='pre-title button-props-panel__label'>Opacity</h3>
                          <div className='button-props-panel__slider'>
                            <Slider
                              min={0}
                              max={100}
                              disabled={
                                !highlightedBlock ||
                                highlightedBlock?.block.blockType.name !== 'button'
                              }
                              tooltipVisible={false}
                              value={
                                highlightedBlock?.block.blockProperties.buttonShadowOpacity
                                  ? highlightedBlock?.block.blockProperties.buttonShadowOpacity *
                                    100
                                  : 0
                              }
                              onChange={(value) =>
                                updateSingleElementProperties(highlightedBlock?.block._id, {
                                  buttonShadowOpacity: value / 100
                                })
                              }
                            />
                            {highlightedBlock &&
                              highlightedBlock?.block.blockType.name === 'button' && (
                                <span>
                                  {highlightedBlock?.block.blockProperties.buttonShadowOpacity
                                    ? Math.round(
                                        highlightedBlock?.block.blockProperties
                                          .buttonShadowOpacity * 100
                                      )
                                    : 0}
                                </span>
                              )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Panel>
                <Panel header={<h3 className='pre-title'>Events</h3>} key='18'>
                  {highlightedBlock?.block?.blockEvents?.length > 0 && (
                    <div className='block-event__list'>
                      {highlightedBlock?.block?.blockEvents?.map((blockEvent, index) => (
                        <div
                          key={'block_envent' + index}
                          className='block-event__list-item'
                          style={{
                            borderBottom: '1px solid #E4E4E4',
                            paddingBottom: '0.625rem',
                            marginBottom: '0.625rem'
                          }}>
                          <span className='block-event__list-item-title'>
                            {blockEvent.eventType.replace(/([a-z])([A-Z])/g, '$1 $2')}
                          </span>
                          <div className='block-event__list-item-actions'>
                            <EditIcon
                              onClick={() => {
                                setEventIndex(index);
                                setCurrentEvent(blockEvent);
                              }}
                            />
                            <DeleteIcon onClick={() => deleteBlockEventHandler(index)} />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <>
                    {highlightedBlock && (
                      <>
                        <h3
                          className='pre-title button-props-panel__label qoo-link__label'
                          style={{ marginTop: '0' }}>
                          Event Form
                        </h3>
                        <Select
                          className='block-event__element'
                          style={{ marginBottom: '1.25rem' }}
                          value={currentEvent.eventType}
                          placeholder='Select Event Type'
                          onChange={(value) =>
                            value === 'EndStudy'
                              ? setCurrentEvent({
                                  // ...currentEvent,
                                  eventType: value,
                                  endStudy: true
                                })
                              : value === 'LockStudy'
                              ? setCurrentEvent({
                                  // ...currentEvent,
                                  eventType: value,
                                  lockStudy: true
                                })
                              : value === 'StartStudy'
                              ? setCurrentEvent({
                                  // ...currentEvent,
                                  eventType: value,
                                  startStudy: true
                                })
                              : value === 'RefreshApp'
                              ? setCurrentEvent({
                                  // ...currentEvent,
                                  eventType: value,
                                  refreshApp: true
                                })
                              : setCurrentEvent({
                                  // ...currentEvent,
                                  eventType: value
                                })
                          }>
                          {filteredEventTypes.map((eventType) => (
                            <Select.Option value={eventType.value}>{eventType.text}</Select.Option>
                          ))}
                        </Select>
                        {currentEvent.eventType && (
                          <BlockEventsForm
                            publishedQoolections={publishedQoolections}
                            qoos={filteredQoosForBlockEvents}
                            questionsForBlockEvent={questionsForBlockEvent}
                            remindersForBlockEvent={remindersForBlockEvent}
                            highlightedBlock={highlightedBlock}
                            currentEvent={currentEvent}
                            setCurrentEvent={setCurrentEvent}
                            blockEventHandler={blockEventHandler}
                            eventIndex={eventIndex}
                          />
                        )}
                      </>
                    )}
                  </>
                  {/* <div className='button-props-panel__link'>
                    <Select
                      placeholder='Choose Link Type...'
                      className='button-props-panel__link-selected'
                      disabled={
                        !highlightedBlock ||
                        highlightedBlock?.block.blockType.name !== 'button'
                      }
                      value={highlightedBlock?.block.blockEvents.buttonLinkTo}
                      onChange={(value) =>
                        updateSingleElementProperties(
                          highlightedBlock?.block._id,
                          { buttonLinkTo: value }
                        )
                      }
                    >
                      <Select.Option
                        value={buttonActionTypes.externalLink.key}
                        label={buttonActionTypes.externalLink.title}
                      >
                        {buttonActionTypes.externalLink.title}
                      </Select.Option>
                      <Select.Option
                        value={buttonActionTypes.qoo.key}
                        label={buttonActionTypes.qoo.title}
                      >
                        {buttonActionTypes.qoo.title}
                      </Select.Option>
                      <Select.Option
                        value={buttonActionTypes.email.key}
                        label={buttonActionTypes.email.title}
                      >
                        {buttonActionTypes.email.title}
                      </Select.Option>
                      <Select.Option
                        value={buttonActionTypes.phone.key}
                        label={buttonActionTypes.phone.title}
                      >
                        {buttonActionTypes.phone.title}
                      </Select.Option>
                      <Select.Option
                        value={buttonActionTypes.location.key}
                        label={buttonActionTypes.location.title}
                      >
                        {buttonActionTypes.location.title}
                      </Select.Option>
                    </Select>
                    {highlightedBlock?.block.blockProperties.buttonLinkTo ===
                      'external-link' && (
                      <>
                        <span className='button-props-panel__link-type-label'>
                          Website
                        </span>
                        <Input
                          placeholder='www.website.com'
                          className='button-props-panel__link-type-input'
                          prefix={<LinkWebsite />}
                          value={
                            highlightedBlock?.block.blockProperties.buttonAction
                          }
                          onChange={(e) =>
                            updateSingleElementProperties(
                              highlightedBlock?.block._id,
                              {
                                buttonAction: e.target.value,
                              },
                              buttonActionTypes.externalLink.key
                            )
                          }
                        />
                      </>
                    )}
                    {highlightedBlock?.block.blockProperties.buttonLinkTo ===
                      'qoo' && (
                      <Select
                        className='dropdown-small qoo-menu-panel__link-dropdown flex-fill-by-width'
                        placeholder='Select Qoo'
                        options={getQoosForSelectComponent()}
                        filterOption={(input, option) =>
                          (option?.label?.toLowerCase() ?? '').includes(
                            input?.toLowerCase()
                          )
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        showArrow={true}
                        showSearch={true}
                        allowClear={true}
                        value={
                          highlightedBlock?.block.blockProperties
                            .buttonAction || undefined
                        }
                        onChange={(qooId) => {
                          updateSingleElementProperties(
                            highlightedBlock?.block._id,
                            {
                              buttonAction: qooId,
                            },
                            buttonActionTypes.qoo.key
                          );
                        }}
                      />
                    )}
                    {highlightedBlock?.block.blockProperties.buttonLinkTo ===
                      'email' && (
                      <Input
                        placeholder='name@email.com'
                        className='button-props-panel__link-type-input'
                        prefix={<LinkEmail />}
                        value={
                          highlightedBlock?.block.blockProperties.buttonAction
                        }
                        onChange={(e) =>
                          updateSingleElementProperties(
                            highlightedBlock?.block._id,
                            { buttonAction: e.target.value },
                            buttonActionTypes.email.key
                          )
                        }
                      />
                    )}
                    {highlightedBlock?.block.blockProperties.buttonLinkTo ===
                      'phone' && (
                      <Input
                        placeholder='+14844731432'
                        className='button-props-panel__link-type-input'
                        prefix={<LinkPhone />}
                        value={
                          highlightedBlock?.block.blockProperties.buttonAction
                        }
                        onChange={(e) =>
                          updateSingleElementProperties(
                            highlightedBlock?.block._id,
                            { buttonAction: e.target.value },
                            buttonActionTypes.phone.key
                          )
                        }
                      />
                    )}
                    {highlightedBlock?.block.blockProperties.buttonLinkTo ===
                      'location' && (
                      <>
                        <span className='button-props-panel__link-type-label'>
                          Link to location
                        </span>
                        <Input
                          placeholder='https://goo.gl/maps/kjaKpSpvd4sJAmBm7'
                          className='button-props-panel__link-type-input'
                          prefix={<LinkIcon />}
                          value={
                            highlightedBlock?.block.blockProperties.buttonAction
                          }
                          onChange={(e) =>
                            updateSingleElementProperties(
                              highlightedBlock?.block._id,
                              { buttonAction: e.target.value },
                              buttonActionTypes.location.key
                            )
                          }
                        />
                      </>
                    )}
                  </div>
                  <h3 className='pre-title button-props-panel__label qoo-link__label'>
                    {buttonActionTypes.buttonValue.title}
                  </h3>
                  <Input
                    placeholder={buttonActionTypes.buttonValue.placeholder}
                    className='button-props-panel__link-type-input'
                    value={_.get(
                      highlightedBlock,
                      'block.blockProperties.buttonValue',
                      ''
                    )}
                    onChange={(e) => {
                      updateSingleElementProperties(
                        highlightedBlock?.block._id,
                        { buttonValue: e.target.value },
                        buttonActionTypes.buttonValue.key
                      );
                    }}
                  />
                  <h3 className='pre-title button-props-panel__label qoo-link__label'>
                    {buttonActionTypes.sendTextMessage.title}
                  </h3>
                  <Select
                    style={{ width: '100%' }}
                    className='dropdown-small qoo-menu-panel__link-dropdown flex-fill-by-width'
                    placeholder={
                      buttonActionTypes.buttonSendPublishedQoolectionId
                        .placeholder
                    }
                    options={getPublishedQoolectionsForSelectComponent()}
                    filterOption={(input, option) =>
                      (option?.label?.toLowerCase() ?? '').includes(
                        input?.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    showArrow={true}
                    showSearch={true}
                    allowClear={true}
                    value={
                      highlightedBlock?.block.blockProperties
                        .buttonSendPublishedQoolectionId || undefined
                    }
                    onChange={(qoolectionId) => {
                      updateSingleElementProperties(
                        highlightedBlock?.block._id,
                        {
                          buttonSendPublishedQoolectionId: qoolectionId,
                        },
                        buttonActionTypes.buttonSendPublishedQoolectionId.key
                      );
                    }}
                  />
                  <h3 className='pre-title button-props-panel__label qoo-link__label'>
                    {buttonActionTypes.buttonSendDelay.title}
                  </h3>
                  <InputNumber
                    type='number'
                    min={0}
                    value={
                      highlightedBlock?.block.blockProperties.buttonSendDelay ||
                      0
                    }
                    onChange={(minutes) => {
                      updateSingleElementProperties(
                        highlightedBlock?.block._id,
                        {
                          buttonSendDelay: minutes,
                        },
                        buttonActionTypes.buttonSendDelay.key
                      );
                    }}
                  />
                  <h3 className='pre-title button-props-panel__label qoo-link__label'>
                    {buttonActionTypes.buttonSendResendPeriod.title}
                  </h3>
                  <InputNumber
                    type='number'
                    min={0}
                    value={
                      highlightedBlock?.block.blockProperties
                        .buttonSendResendPeriod || 0
                    }
                    onChange={(minutes) => {
                      updateSingleElementProperties(
                        highlightedBlock?.block._id,
                        {
                          buttonSendResendPeriod: minutes,
                        },
                        buttonActionTypes.buttonSendResendPeriod.key
                      );
                    }}
                  />
                  <h3 className='pre-title button-props-panel__label qoo-link__label'>
                    {buttonActionTypes.buttonSendResendMaximumNumber.title}
                  </h3>
                  <InputNumber
                    type='number'
                    min={0}
                    value={
                      highlightedBlock?.block.blockProperties
                        .buttonSendResendMaximumNumber || 0
                    }
                    onChange={(minutes) => {
                      updateSingleElementProperties(
                        highlightedBlock?.block._id,
                        {
                          buttonSendResendMaximumNumber: minutes,
                        },
                        buttonActionTypes.buttonSendResendMaximumNumber.key
                      );
                    }}
                  /> */}
                </Panel>
                {/* <Panel header={<h3 className='pre-title'>Compliance</h3>} key='19'></Panel> */}
              </>
            )}
            {selectedBlockType.name === 'icon' && (
              <>
                <Panel header={<h3 className='pre-title'>Icons library</h3>} key='20'>
                  <IconsLibrary selectIcon={addEmptyBlock} iconFetchUrl={iconFetchUrl} />
                </Panel>
                <Panel header={<h3 className='pre-title'>Icon size &amp; color</h3>} key='21'>
                  <div className='icon-size-color'>
                    <h3 className='pre-title'>Size</h3>
                    <div className='icon-size-color__slider'>
                      <Slider
                        min={50}
                        max={100}
                        disabled={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'icon'
                        }
                        tooltipVisible={false}
                        value={highlightedBlock?.block.blockProperties.iconSize}
                        onChange={(value) =>
                          updateSingleElementProperties(highlightedBlock?.block._id, {
                            iconSize: value
                          })
                        }
                      />
                      {highlightedBlock && highlightedBlock?.block.blockType.name === 'icon' && (
                        <span>{highlightedBlock?.block.blockProperties.iconSize}%</span>
                      )}
                    </div>
                    <h3 className='pre-title icon-size-color__color-title'>Color</h3>
                    <ColorPickerDropdown
                      colors={qooInPreview.theme.colorPalette.colors}
                      colorsBlackWhiteGrey={qooInPreview.theme.colorPalette.colorsBlackWhiteGrey}
                      selectedColor={highlightedBlock?.block.blockProperties.iconColor}
                      disabled={
                        !highlightedBlock || highlightedBlock?.block.blockType.name !== 'icon'
                      }
                      leftRadius
                      rightRadius
                      callback={(color) =>
                        updateSingleElementProperties(highlightedBlock?.block._id, {
                          iconColor: color
                        })
                      }
                    />
                  </div>
                </Panel>
                {/*<Panel header={<h3 className='pre-title'>Icon background</h3>} key='22'>
                  {highlightedBlock && highlightedBlock?.block.blockType.name === 'icon' && (
                    <div className='icon-background'>
                      <div className='text-background__color'>
                        <div className='text-background__bg-color'>
                          <h3 className='pre-title text-background__label'>Color</h3>
                          <ColorPickerDropdown
                            colors={qooInPreview.theme.colorPalette.colors}
                            colorsBlackWhiteGrey={qooInPreview.theme.colorPalette.colorsBlackWhiteGrey}
                            selectedColor={highlightedBlock?.block.blockProperties.iconBackgroundColor}
                            leftRadius
                            rightRadius
                            callback={(color) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, { iconBackgroundColor: color })
                            }
                          />
                        </div>
                        <div className='text-background__border'>
                          <h3 className='pre-title text-background__label'>Border</h3>
                          <div className='text-background__border-inputs'>
                            <ColorPickerDropdown
                              colors={qooInPreview.theme.colorPalette.colors}
                              colorsBlackWhiteGrey={qooInPreview.theme.colorPalette.colorsBlackWhiteGrey}
                              selectedColor={highlightedBlock?.block.blockProperties.iconBorderColor}
                              leftRadius
                              callback={(color) =>
                                updateSingleElementProperties(highlightedBlock?.block._id, { iconBorderColor: color })
                              }
                            />
                            <InputNumber
                              className='input-small text-background__border-thickness'
                              min={0}
                              max={10}
                              value={highlightedBlock?.block.blockProperties.iconBorderThickness * 16}
                              onChange={(value) =>
                                updateSingleElementProperties(highlightedBlock?.block._id, {
                                  iconBorderThickness: value / 16,
                                })
                              }
                            />
                            <Select
                              className='dropdown-small text-background__border-type'
                              value={highlightedBlock?.block.blockProperties.iconBorderType}
                              onChange={(value) =>
                                updateSingleElementProperties(highlightedBlock?.block._id, { iconBorderType: value })
                              }
                            >
                              <Select.Option value='solid' label='Solid'>
                                Solid
                              </Select.Option>
                              <Select.Option value='dotted' label='Dotted'>
                                Dotted
                              </Select.Option>
                              <Select.Option value='dashed' label='Dashed'>
                                Dashed
                              </Select.Option>
                              <Select.Option value='double' label='Double'>
                                Double
                              </Select.Option>
                              <Select.Option value='' label='None'>
                                None
                              </Select.Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                            </Panel>*/}
              </>
            )}
            {selectedBlockType.name === 'divider' && (
              <>
                <Panel header={<h3 className='pre-title'>Dividers</h3>} key='23'>
                  <div className='divider-templates'>
                    {dividerTemplates.length > 0 &&
                      dividerTemplates.map((template) => {
                        return (
                          <div
                            className='divider-templates__container'
                            key={template.id}
                            onClick={() => addEmptyBlock('divider', { ...template })}>
                            <div
                              style={{
                                width: `${template.dividerLength}%`,
                                borderBottom: `${template.dividerThickness}em ${template.dividerType} ${template.dividerColor.hex}`
                              }}></div>
                          </div>
                        );
                      })}
                  </div>
                </Panel>
                <Panel header={<h3 className='pre-title'>Divider size &amp; color</h3>} key='24'>
                  <div className='divider-props-panel'>
                    <h3 className='pre-title'>Length</h3>
                    <div className='divider-props-panel__slider'>
                      <Slider
                        min={50}
                        max={100}
                        disabled={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'divider'
                        }
                        tooltipVisible={false}
                        value={highlightedBlock?.block.blockProperties.dividerLength || 75}
                        onChange={(value) =>
                          updateSingleElementProperties(highlightedBlock?.block._id, {
                            dividerLength: value
                          })
                        }
                      />
                      {highlightedBlock && highlightedBlock?.block.blockType.name === 'divider' && (
                        <span>{highlightedBlock?.block.blockProperties.dividerLength}%</span>
                      )}
                    </div>
                    <h3 className='pre-title'>Color</h3>
                    <div className='divider-props-panel__border-inputs'>
                      <ColorPickerDropdown
                        colors={qooInPreview.theme.colorPalette.colors}
                        colorsBlackWhiteGrey={qooInPreview.theme.colorPalette.colorsBlackWhiteGrey}
                        selectedColor={highlightedBlock?.block.blockProperties.dividerColor}
                        disabled={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'divider'
                        }
                        leftRadius
                        callback={(color) =>
                          updateSingleElementProperties(highlightedBlock?.block._id, {
                            dividerColor: color
                          })
                        }
                      />
                      <InputNumber
                        className='divider-props-panel__border-thickness'
                        min={1}
                        max={10}
                        disabled={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'divider'
                        }
                        value={highlightedBlock?.block.blockProperties.dividerThickness * 16}
                        onChange={(value) =>
                          updateSingleElementProperties(highlightedBlock?.block._id, {
                            dividerThickness: value / 16
                          })
                        }
                      />
                      <Select
                        className='dropdown-small divider-props-panel__border-type'
                        disabled={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'divider'
                        }
                        value={highlightedBlock?.block.blockProperties.dividerType}
                        onChange={(value) =>
                          updateSingleElementProperties(highlightedBlock?.block._id, {
                            dividerType: value
                          })
                        }>
                        <Select.Option value='solid' label='Solid'>
                          Solid
                        </Select.Option>
                        <Select.Option value='dotted' label='Dotted'>
                          Dotted
                        </Select.Option>
                        <Select.Option value='dashed' label='Dashed'>
                          Dashed
                        </Select.Option>
                        <Select.Option value='double' label='Double'>
                          Double
                        </Select.Option>
                        <Select.Option value='' label='None'>
                          None
                        </Select.Option>
                      </Select>
                    </div>
                  </div>
                </Panel>
              </>
            )}
            {selectedBlockType.name === 'form' && (
              <>
                <Panel header={<h3 className='pre-title'>Forms</h3>} key='25'>
                  <div className='forms-templates'>
                    {formTemplates &&
                      formTemplates.length > 0 &&
                      formTemplates.map((template) => {
                        return (
                          <div
                            key={template.id}
                            className='forms-templates__container'
                            onClick={() => addEmptyBlock('form', { ...template })}>
                            <FormTemplate template={template} />
                          </div>
                        );
                      })}
                  </div>
                </Panel>
                <Panel header={<h3 className='pre-title'>Form padding</h3>} key='26'>
                  <div className='text-padding'>
                    <div className='text-padding__icons'>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            ? 'text-padding__btn text-padding__btn--disabled'
                            : selectedPaddingSideForm === 'formPaddingTop'
                            ? 'text-padding__btn text-padding__btn--selected'
                            : 'text-padding__btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'form'
                          ) {
                            setSelectedPaddingSideForm('formPaddingTop');
                          }
                        }}>
                        <AlignTopIcon />
                        <span>Top</span>
                      </div>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            ? 'text-padding__btn text-padding__btn--disabled'
                            : selectedPaddingSideForm === 'formPaddingBottom'
                            ? 'text-padding__btn text-padding__btn--selected'
                            : 'text-padding__btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'form'
                          ) {
                            setSelectedPaddingSideForm('formPaddingBottom');
                          }
                        }}>
                        <AlignBottomIcon />
                        <span>Bottom</span>
                      </div>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            ? 'text-padding__btn text-padding__btn--disabled'
                            : selectedPaddingSideForm === 'formPaddingLeft'
                            ? 'text-padding__btn text-padding__btn--selected'
                            : 'text-padding__btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'form'
                          ) {
                            setSelectedPaddingSideForm('formPaddingLeft');
                          }
                        }}>
                        <AlignLeftIcon />
                        <span>Left</span>
                      </div>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            ? 'text-padding__btn text-padding__btn--disabled'
                            : selectedPaddingSideForm === 'formPaddingRight'
                            ? 'text-padding__btn text-padding__btn--selected'
                            : 'text-padding__btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'form'
                          ) {
                            setSelectedPaddingSideForm('formPaddingRight');
                          }
                        }}>
                        <AlignRightIcon />
                        <span>Right</span>
                      </div>
                    </div>
                    <div className='text-padding__slider'>
                      <Slider
                        min={0}
                        max={100}
                        disabled={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                        }
                        tooltipVisible={false}
                        value={
                          highlightedBlock?.block?.blockProperties[`${selectedPaddingSideForm}`]
                        }
                        onChange={(value) =>
                          updateSingleElementProperties(highlightedBlock?.block._id, {
                            [`${selectedPaddingSideForm}`]: value
                          })
                        }
                      />
                      {highlightedBlock && highlightedBlock?.block.blockType.name === 'form' && (
                        <span>
                          {highlightedBlock?.block?.blockProperties[`${selectedPaddingSideForm}`]}
                        </span>
                      )}
                    </div>
                  </div>
                </Panel>

                <Panel header={<h3 className='pre-title'>Form fields</h3>} key='27'>
                  {highlightedBlock?.block.blockProperties.formFields?.length > 0 &&
                    highlightedBlock?.block.blockProperties.formFields.map((formField, index) => {
                      return (
                        <FormField
                          key={index}
                          formField={formField}
                          index={index}
                          blockId={highlightedBlock?.block._id}
                          dispatch={dispatch}
                          editCallback={
                            blockInPreview
                              ? (value) => {
                                  blockInPreviewDispatch({
                                    type: EDIT_FORM_FIELD,
                                    payload: {
                                      required: value,
                                      blockId: highlightedBlock?.block._id,
                                      index
                                    }
                                  });
                                }
                              : (value) => {
                                  dispatch({
                                    type: EDIT_FORM_FIELD_Q,
                                    payload: {
                                      required: value,
                                      blockId: highlightedBlock?.block._id,
                                      index
                                    }
                                  });
                                }
                          }
                          removeCallback={
                            blockInPreview
                              ? () => {
                                  blockInPreviewDispatch({
                                    type: REMOVE_FORM_FIELD,
                                    payload: {
                                      blockId: highlightedBlock?.block._id,
                                      index
                                    }
                                  });
                                }
                              : () => {
                                  dispatch({
                                    type: REMOVE_FORM_FIELD_Q,
                                    payload: {
                                      blockId: highlightedBlock?.block._id,
                                      index
                                    }
                                  });
                                }
                          }
                        />
                      );
                    })}

                  <div className='add-info-form'>
                    <div
                      className='add-info-form__item'
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between'
                      }}>
                      <div
                        style={{
                          width: '100%',
                          marginRight: '10px',
                          marginTop: '10px'
                        }}>
                        <h4 className='add-info-form__label'>Add field</h4>
                        <Select
                          placeholder='Choose type'
                          className='dropdown-small fieldType'
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                          }
                          style={{ width: '100%', marginBottom: '5px' }}
                          value={selectedFormFieldElement}
                          onChange={(value) => setSelectedFormFieldElement(value)}>
                          <Select.Option value='text' className='fieldType'>
                            <TextIcon />
                            <span>Text</span>
                          </Select.Option>
                          <Select.Option value='email' className='fieldType'>
                            <EmailIcon />
                            <span>Email</span>
                          </Select.Option>
                          <Select.Option value='textarea' className='fieldType'>
                            <TextAreaIcon />
                            <span>Text area</span>
                          </Select.Option>
                          <Select.Option value='checkbox' className='fieldType'>
                            <CheckboxIcon />
                            <span>Checkbox</span>
                          </Select.Option>
                        </Select>
                        <Input
                          placeholder='Field name'
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                          }
                          value={formFieldName}
                          onChange={(e) => setFormFieldName(e.target.value)}
                        />
                      </div>
                      <Button
                        className='add-button'
                        disabled={
                          !highlightedBlock ||
                          highlightedBlock?.block.blockType.name !== 'form' ||
                          !selectedFormFieldElement ||
                          !formFieldName
                        }
                        onClick={() => {
                          if (blockInPreview) {
                            blockInPreviewDispatch({
                              type: ADD_FORM_FIELD,
                              payload: {
                                blockId: highlightedBlock?.block._id,
                                formField: {
                                  fieldElement: selectedFormFieldElement,
                                  fieldName: formFieldName
                                }
                              }
                            });
                          } else {
                            dispatch({
                              type: ADD_FORM_FIELD_Q,
                              payload: {
                                blockId: highlightedBlock?.block._id,
                                formField: {
                                  fieldElement: selectedFormFieldElement,
                                  fieldName: formFieldName
                                }
                              }
                            });
                          }
                          setSelectedFormFieldElement(null);
                          setFormFieldName('');
                        }}>
                        <div className='add-button-text'>
                          <PlusIconButton />
                          <span style={{ marginLeft: '5px' }}>Add</span>
                        </div>
                      </Button>
                    </div>
                  </div>
                </Panel>

                <Panel header={<h3 className='pre-title'>Field style</h3>} key='28'>
                  <div className='text-background button-props-panel__section-wrapper'>
                    <div className='text-background__color'>
                      <div className='text-background__bg-color'>
                        <h3 className='pre-title text-background__label'>Default</h3>
                        <ColorPickerDropdown
                          colors={qooInPreview.theme.colorPalette.colors}
                          colorsBlackWhiteGrey={
                            qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                          }
                          selectedColor={
                            highlightedBlock?.block.blockProperties.formFieldBackgroundColor
                          }
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                          }
                          leftRadius
                          rightRadius
                          callback={(color) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              formFieldBackgroundColor: color
                            })
                          }
                        />
                      </div>
                      <div className='text-background__border'>
                        <h3
                          className='pre-title text-background__label'
                          style={{ color: 'transparent' }}>
                          Border
                        </h3>
                        <div className='text-background__border-inputs'>
                          <ColorPickerDropdown
                            colors={qooInPreview.theme.colorPalette.colors}
                            colorsBlackWhiteGrey={
                              qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                            }
                            selectedColor={
                              highlightedBlock?.block.blockProperties.formFieldBorderColor
                            }
                            disabled={
                              !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            }
                            leftRadius
                            rightRadius
                            callback={(color) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                formFieldBorderColor: color
                              })
                            }
                          />
                          <InputNumber
                            className='text-background__border-thickness'
                            controls
                            min={1}
                            max={10}
                            disabled={
                              !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            }
                            defaultValue={0}
                            value={
                              highlightedBlock?.block.blockProperties.formFieldBorderThickness * 16
                            }
                            onChange={(value) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                formFieldBorderThickness: value / 16
                              })
                            }
                          />
                          <Select
                            className='dropdown-small text-background__border-type'
                            placeholder='Type'
                            disabled={
                              !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            }
                            value={highlightedBlock?.block.blockProperties.formFieldBorderType}
                            onChange={(value) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                formFieldBorderType: value
                              })
                            }>
                            <Select.Option value='solid' label='Solid'>
                              Solid
                            </Select.Option>
                            <Select.Option value='dotted' label='Dotted'>
                              Dotted
                            </Select.Option>
                            <Select.Option value='dashed' label='Dashed'>
                              Dashed
                            </Select.Option>
                            <Select.Option value='double' label='Double'>
                              Double
                            </Select.Option>
                            <Select.Option value='' label='None'>
                              None
                            </Select.Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='text-background button-props-panel__section-wrapper'>
                    <div className='text-background__color'>
                      <div className='text-background__bg-color'>
                        <h3 className='pre-title text-background__label'>Focused</h3>
                        <ColorPickerDropdown
                          colors={qooInPreview.theme.colorPalette.colors}
                          colorsBlackWhiteGrey={
                            qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                          }
                          selectedColor={
                            highlightedBlock?.block.blockProperties.formFieldFocusedBackgroundColor
                          }
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                          }
                          leftRadius
                          rightRadius
                          callback={(color) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              formFieldFocusedBackgroundColor: color
                            })
                          }
                        />
                      </div>
                      <div className='text-background__border'>
                        <h3
                          className='pre-title text-background__label'
                          style={{ color: 'transparent' }}>
                          Border
                        </h3>
                        <div className='text-background__border-inputs'>
                          <ColorPickerDropdown
                            colors={qooInPreview.theme.colorPalette.colors}
                            colorsBlackWhiteGrey={
                              qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                            }
                            selectedColor={
                              highlightedBlock?.block.blockProperties.formFieldFocusedBorderColor
                            }
                            disabled={
                              !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            }
                            leftRadius
                            rightRadius
                            callback={(color) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                formFieldFocusedBorderColor: color
                              })
                            }
                          />
                          <InputNumber
                            className='text-background__border-thickness'
                            controls
                            min={1}
                            max={10}
                            disabled={
                              !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            }
                            defaultValue={0}
                            value={
                              highlightedBlock?.block.blockProperties
                                .formFieldFocusedBorderThickness * 16
                            }
                            onChange={(value) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                formFieldFocusedBorderThickness: value / 16
                              })
                            }
                          />
                          <Select
                            className='dropdown-small text-background__border-type'
                            placeholder='Type'
                            disabled={
                              !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            }
                            value={
                              highlightedBlock?.block.blockProperties.formFieldFocusedBorderType
                            }
                            onChange={(value) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                formFieldFocusedBorderType: value
                              })
                            }>
                            <Select.Option value='solid' label='Solid'>
                              Solid
                            </Select.Option>
                            <Select.Option value='dotted' label='Dotted'>
                              Dotted
                            </Select.Option>
                            <Select.Option value='dashed' label='Dashed'>
                              Dashed
                            </Select.Option>
                            <Select.Option value='double' label='Double'>
                              Double
                            </Select.Option>
                            <Select.Option value='' label='None'>
                              None
                            </Select.Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-background button-props-panel__section-wrapper'>
                    <div className='text-background__border'>
                      <h3 className='pre-title text-background__label'>Input text</h3>
                      <div className='text-background__border-inputs'>
                        <Dropdown
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                          }
                          trigger={['click']}
                          overlay={
                            <div className='h-f-create-new__fontlist-dropdown-overlay'>
                              {qooInPreview.theme.selectedFonts.length > 0 &&
                                qooInPreview.theme.selectedFonts.map((font) => {
                                  return (
                                    <div
                                      key={font._id}
                                      className='h-f-create-new__fontlist-dropdown-font'
                                      style={{
                                        fontFamily: font.name,
                                        fontWeight: font.weight,
                                        fontStyle: font.style
                                      }}
                                      onClick={() =>
                                        updateSingleElementProperties(highlightedBlock?.block._id, {
                                          formFont: font
                                        })
                                      }>
                                      {font.name}
                                    </div>
                                  );
                                })}
                            </div>
                          }>
                          <div className='h-f-create-new__fonts-dropdown'>
                            <span
                              className='h-f-create-new__fonts-dropdown-text'
                              style={{
                                fontFamily: highlightedBlock?.block.blockProperties.formFont?.name,
                                fontStyle: highlightedBlock?.block.blockProperties.formFont?.style,
                                fontWeight: highlightedBlock?.block.blockProperties.formFont?.weight
                              }}>
                              {highlightedBlock?.block.blockProperties.formFont
                                ? highlightedBlock?.block.blockProperties.formFont.name
                                : 'Choose font'}
                            </span>
                            <DownCaret className='h-f-create-new__fonts-dropdown__arrow' />
                          </div>
                        </Dropdown>
                        <ColorPickerDropdown
                          colors={qooInPreview.theme.colorPalette.colors}
                          colorsBlackWhiteGrey={
                            qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                          }
                          selectedColor={highlightedBlock?.block.blockProperties.formFontColor}
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                          }
                          rightRadius
                          callback={(color) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              formFontColor: color
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Panel>

                <Panel header={<h3 className='pre-title'>Button options</h3>} key='29'>
                  <div className='text-padding' style={{ marginBottom: '20px' }}>
                    <h3
                      className='pre-title text-background__label'
                      style={{ marginBottom: '10px' }}>
                      Position
                    </h3>
                    <div className='text-padding__icons'>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            ? 'text-padding__btn text-padding__btn--disabled'
                            : highlightedBlock?.block.blockProperties.formButtonPosition ===
                              'flex-start'
                            ? 'text-padding__btn text-padding__btn--selected'
                            : 'text-padding__btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'form'
                          ) {
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              formButtonPosition: 'flex-start'
                            });
                          }
                        }}>
                        <AlignLeftIcon />
                        <span>Left</span>
                      </div>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            ? 'text-padding__btn text-padding__btn--disabled'
                            : highlightedBlock?.block.blockProperties.formButtonPosition ===
                              'center'
                            ? 'text-padding__btn text-padding__btn--selected'
                            : 'text-padding__btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'form'
                          ) {
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              formButtonPosition: 'center'
                            });
                          }
                        }}>
                        <AlignCenterIcon />
                        <span>Center</span>
                      </div>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            ? 'text-padding__btn text-padding__btn--disabled'
                            : highlightedBlock?.block.blockProperties.formButtonPosition ===
                              'flex-end'
                            ? 'text-padding__btn text-padding__btn--selected'
                            : 'text-padding__btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'form'
                          ) {
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              formButtonPosition: 'flex-end'
                            });
                          }
                        }}>
                        <AlignRightIcon />
                        <span>Right</span>
                      </div>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            ? 'text-padding__btn text-padding__btn--disabled'
                            : !highlightedBlock?.block.blockProperties.formButtonPosition
                            ? 'text-padding__btn text-padding__btn--selected'
                            : 'text-padding__btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'form'
                          ) {
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              formButtonPosition: ''
                            });
                          }
                        }}>
                        <AlignBottomIcon />
                        <span>Wide</span>
                      </div>
                    </div>
                  </div>

                  <div className='text-background button-props-panel__section-wrapper'>
                    <div className='text-background__color'>
                      <div className='text-background__bg-color'>
                        <h3 className='pre-title text-background__label'>Color</h3>
                        <ColorPickerDropdown
                          colors={qooInPreview.theme.colorPalette.colors}
                          colorsBlackWhiteGrey={
                            qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                          }
                          selectedColor={
                            highlightedBlock?.block.blockProperties.formButtonBackgroundColor
                          }
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                          }
                          leftRadius
                          rightRadius
                          callback={(color) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              formButtonBackgroundColor: color
                            })
                          }
                        />
                      </div>
                      <div className='text-background__border'>
                        <h3 className='pre-title text-background__label'>Border</h3>
                        <div className='text-background__border-inputs'>
                          <ColorPickerDropdown
                            colors={qooInPreview.theme.colorPalette.colors}
                            colorsBlackWhiteGrey={
                              qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                            }
                            selectedColor={
                              highlightedBlock?.block.blockProperties.formButtonBorderColor
                            }
                            disabled={
                              !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            }
                            leftRadius
                            line
                            callback={(color) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                formButtonBorderColor: color
                              })
                            }
                          />
                          <InputNumber
                            className='text-background__border-thickness'
                            controls
                            min={1}
                            max={10}
                            disabled={
                              !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            }
                            defaultValue={0}
                            value={
                              highlightedBlock?.block.blockProperties.formButtonBorderThickness * 16
                            }
                            onChange={(value) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                formButtonBorderThickness: value / 16
                              })
                            }
                          />
                          <Select
                            className='dropdown-small text-background__border-type'
                            placeholder='Type'
                            disabled={
                              !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            }
                            value={highlightedBlock?.block.blockProperties.formButtonBorderType}
                            onChange={(value) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                formButtonBorderType: value
                              })
                            }>
                            <Select.Option value='solid' label='Solid'>
                              Solid
                            </Select.Option>
                            <Select.Option value='dotted' label='Dotted'>
                              Dotted
                            </Select.Option>
                            <Select.Option value='dashed' label='Dashed'>
                              Dashed
                            </Select.Option>
                            <Select.Option value='double' label='Double'>
                              Double
                            </Select.Option>
                            <Select.Option value='' label='None'>
                              None
                            </Select.Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='button-props-panel__slider'>
                    <Slider
                      min={0}
                      max={30000}
                      step={625}
                      disabled={
                        !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                      }
                      tooltipVisible={false}
                      value={
                        highlightedBlock?.block.blockProperties.formButtonBorderRadius?.replace(
                          'em',
                          ''
                        ) * 10000
                      }
                      onChange={(value) =>
                        updateSingleElementProperties(highlightedBlock?.block._id, {
                          formButtonBorderRadius: `${value / 10000}em`
                        })
                      }
                    />
                    {highlightedBlock && highlightedBlock?.block.blockType.name === 'form' && (
                      <span>
                        {highlightedBlock?.block.blockProperties.formButtonBorderRadius?.replace(
                          'em',
                          ''
                        ) * 16}
                        px
                      </span>
                    )}
                  </div>

                  <div className='button-props-panel__btn-effects'>
                    <h3
                      className='pre-title text-background__label'
                      style={{ marginBottom: '10px' }}>
                      Effects
                    </h3>
                    <div className='button-props-panel__btn-effects-choices choice-btn-group'>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--disabled'
                            : highlightedBlock?.block.blockProperties.formButtonHasShadow &&
                              !highlightedBlock?.block.blockProperties.formButtonInnerShadow
                            ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                            : 'button-props-panel__btn-effects-choice choice-btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'form'
                          ) {
                            updateSingleElementProperties(
                              highlightedBlock?.block._id,
                              highlightedBlock?.block.blockProperties.formButtonHasShadow
                                ? highlightedBlock?.block.blockProperties.formButtonInnerShadow
                                  ? {
                                      formButtonInnerShadow: false,
                                      formButtonHasShadow: true
                                    }
                                  : {
                                      formButtonHasShadow: false
                                    }
                                : {
                                    formButtonInnerShadow: false,
                                    formButtonHasShadow: true
                                  }
                            );
                          }
                        }}>
                        Shadow
                      </div>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--disabled'
                            : highlightedBlock?.block.blockProperties.formButtonHasShadow &&
                              highlightedBlock?.block.blockProperties.formButtonInnerShadow
                            ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                            : 'button-props-panel__btn-effects-choice choice-btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'form'
                          ) {
                            updateSingleElementProperties(
                              highlightedBlock?.block._id,
                              highlightedBlock?.block.blockProperties.formButtonHasShadow
                                ? highlightedBlock?.block.blockProperties.formButtonInnerShadow
                                  ? {
                                      formButtonHasShadow: false
                                    }
                                  : {
                                      formButtonInnerShadow: true,
                                      formButtonHasShadow: true
                                    }
                                : {
                                    formButtonInnerShadow: true,
                                    formButtonHasShadow: true
                                  }
                            );
                          }
                        }}>
                        Inner Shadow
                      </div>
                    </div>
                  </div>
                </Panel>

                <Panel header={<h3 className='pre-title'>Form background</h3>} key='30'>
                  <div className='text-background'>
                    <div className='text-background__color'>
                      <div className='text-background__bg-color'>
                        <h3 className='pre-title text-background__label'>Color</h3>
                        <ColorPickerDropdown
                          colors={qooInPreview.theme.colorPalette.colors}
                          colorsBlackWhiteGrey={
                            qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                          }
                          selectedColor={
                            highlightedBlock?.block.blockProperties.formBackgroundColor
                          }
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                          }
                          leftRadius
                          rightRadius
                          callback={(color) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              formBackgroundColor: color
                            })
                          }
                        />
                      </div>
                      <div className='text-background__border'>
                        <h3 className='pre-title text-background__label'>Border</h3>
                        <div className='text-background__border-inputs'>
                          <ColorPickerDropdown
                            colors={qooInPreview.theme.colorPalette.colors}
                            colorsBlackWhiteGrey={
                              qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                            }
                            selectedColor={highlightedBlock?.block.blockProperties.formBorderColor}
                            disabled={
                              !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            }
                            leftRadius
                            line
                            callback={(color) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                formBorderColor: color
                              })
                            }
                          />
                          <InputNumber
                            className='text-background__border-thickness'
                            controls
                            min={1}
                            max={10}
                            disabled={
                              !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            }
                            defaultValue={0}
                            value={highlightedBlock?.block.blockProperties.formBorderThickness * 16}
                            onChange={(value) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                formBorderThickness: value / 16
                              })
                            }
                          />
                          <Select
                            className='dropdown-small text-background__border-type'
                            placeholder='Type'
                            disabled={
                              !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                            }
                            value={highlightedBlock?.block.blockProperties.formBorderType}
                            onChange={(value) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                formBorderType: value
                              })
                            }>
                            <Select.Option value='solid' label='Solid'>
                              Solid
                            </Select.Option>
                            <Select.Option value='dotted' label='Dotted'>
                              Dotted
                            </Select.Option>
                            <Select.Option value='dashed' label='Dashed'>
                              Dashed
                            </Select.Option>
                            <Select.Option value='double' label='Double'>
                              Double
                            </Select.Option>
                            <Select.Option value='' label='None'>
                              None
                            </Select.Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>

                <Panel header={<h3 className='pre-title'>Link</h3>} key='31'>
                  <div className='add-info-form' style={{ marginTop: 0 }}>
                    <div className='add-info-form_item'>
                      <h4 className='add-info-form_label'>Email</h4>
                      <Input
                        className='emailInput'
                        placeholder='name@email.com'
                        prefix={<EmailIcon />}
                        disabled={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                        }
                      />
                    </div>
                    <Checkbox
                      style={{ marginTop: '10px' }}
                      disabled={
                        !highlightedBlock || highlightedBlock?.block.blockType.name !== 'form'
                      }>
                      Text
                    </Checkbox>
                  </div>
                </Panel>
              </>
            )}
            {selectedBlockType.name === 'survey' && (
              <>
                <Panel header={<h3 className='pre-title'>Surveys</h3>} key='32'>
                  <div className='surveys-templates'>
                    {surveyTemplates?.length > 0 &&
                      surveyTemplates.map((template) => {
                        return (
                          <div
                            className='surveys-templates__container'
                            onClick={() =>
                              addEmptyBlock('survey', {
                                ...template,
                                surveyQuestion: template.surveyQuestion.replace(
                                  '**default**',
                                  bodyTextFont.name.toLowerCase().replace(' -', '')
                                )
                              })
                            }>
                            <span>Checkbox type</span>
                          </div>
                        );
                      })}
                  </div>
                </Panel>
                <Panel header={<h3 className='pre-title'>Question type</h3>} key='33'>
                  <Select
                    className='dropdown-small fieldType'
                    placeholder='Type'
                    disabled={
                      !highlightedBlock || highlightedBlock?.block.blockType.name !== 'survey'
                    }
                    style={{ width: '100%', marginBottom: '15px' }}
                    value={highlightedBlock?.block.blockProperties.surveyType}
                    onChange={(value) =>
                      updateSingleElementProperties(highlightedBlock?.block._id, {
                        surveyType: value
                      })
                    }>
                    <Select.Option value='radio' className='fieldType'>
                      <RadioIcon />
                      <span>Multiple choice</span>
                    </Select.Option>
                    <Select.Option value='checkbox' className='fieldType'>
                      <CheckboxGreyIcon />
                      <span>Checkbox</span>
                    </Select.Option>
                  </Select>
                  {highlightedBlock?.block.blockProperties.surveyOptions?.length > 0 &&
                    highlightedBlock?.block.blockProperties.surveyOptions.map((option, index) => (
                      <Input
                        style={{ marginBottom: '10px' }}
                        key={index}
                        value={option}
                        onChange={(e) => {
                          if (blockInPreview) {
                            blockInPreviewDispatch({
                              type: EDIT_SURVEY_OPTION,
                              payload: {
                                blockId: highlightedBlock?.block._id,
                                index,
                                value: e.target.value
                              }
                            });
                          } else {
                            dispatch({
                              type: EDIT_SURVEY_OPTION_Q,
                              payload: {
                                blockId: highlightedBlock?.block._id,
                                index,
                                value: e.target.value
                              }
                            });
                          }
                        }}
                        suffix={
                          <DeleteIcon
                            className='removeOptionIcon'
                            onClick={() => {
                              if (blockInPreview) {
                                blockInPreviewDispatch({
                                  type: REMOVE_SURVEY_OPTION,
                                  payload: {
                                    blockId: highlightedBlock?.block._id,
                                    index
                                  }
                                });
                              } else {
                                dispatch({
                                  type: REMOVE_SURVEY_OPTION_Q,
                                  payload: {
                                    blockId: highlightedBlock?.block._id,
                                    index
                                  }
                                });
                              }
                            }}
                          />
                        }
                      />
                    ))}
                  <Button
                    className='add-button'
                    style={{ width: '100%', marginTop: '5px' }}
                    disabled={
                      !highlightedBlock || highlightedBlock?.block.blockType.name !== 'survey'
                    }
                    onClick={() => {
                      if (blockInPreview) {
                        blockInPreviewDispatch({
                          type: ADD_SURVEY_OPTION,
                          payload: {
                            blockId: highlightedBlock?.block._id,
                            surveyOption: `Choice ${
                              highlightedBlock?.block.blockProperties.surveyOptions?.length + 1
                            }`
                          }
                        });
                      } else {
                        dispatch({
                          type: ADD_SURVEY_OPTION_Q,
                          payload: {
                            blockId: highlightedBlock?.block._id,
                            surveyOption: `Choice ${
                              highlightedBlock?.block.blockProperties.surveyOptions?.length + 1
                            }`
                          }
                        });
                      }
                    }}>
                    Add an option
                  </Button>
                  <div className='surveyRequired'>
                    <span>Required to answer</span>
                    <Switch
                      disabled={
                        !highlightedBlock || highlightedBlock?.block.blockType.name !== 'survey'
                      }
                      checked={highlightedBlock?.block.blockProperties.surveyAnswerRequired}
                      onChange={(checked) => {
                        dispatch({
                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                          payload: {
                            blockId: highlightedBlock?.block._id,
                            updates: {
                              surveyAnswerRequired: checked
                            }
                          }
                        });
                      }}
                    />
                  </div>
                </Panel>

                <Panel header={<h3 className='pre-title'>Question style</h3>} key='34'>
                  <div className='text-background button-props-panel__section-wrapper'>
                    <div className='text-background__color'>
                      <div className='text-background__bg-color'>
                        <h3 className='pre-title text-background__label'>Default</h3>
                        <h3 className='pre-title text-background__label'>Color</h3>
                        <ColorPickerDropdown
                          colors={qooInPreview.theme.colorPalette.colors}
                          colorsBlackWhiteGrey={
                            qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                          }
                          selectedColor={
                            highlightedBlock?.block.blockProperties.surveyOptionBackgroundColor
                          }
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'survey'
                          }
                          leftRadius
                          rightRadius
                          callback={(color) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              surveyOptionBackgroundColor: color
                            })
                          }
                        />
                      </div>
                      <div className='text-background__border'>
                        <h3
                          className='pre-title text-background__label'
                          style={{ marginTop: '26px' }}>
                          Border
                        </h3>
                        <div className='text-background__border-inputs'>
                          <ColorPickerDropdown
                            colors={qooInPreview.theme.colorPalette.colors}
                            colorsBlackWhiteGrey={
                              qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                            }
                            selectedColor={
                              highlightedBlock?.block.blockProperties.surveyOptionBorderColor
                            }
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock?.block.blockType.name !== 'survey'
                            }
                            leftRadius
                            line
                            callback={(color) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                surveyOptionBorderColor: color
                              })
                            }
                          />
                          <InputNumber
                            className='text-background__border-thickness'
                            controls
                            min={1}
                            max={10}
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock?.block.blockType.name !== 'survey'
                            }
                            defaultValue={0}
                            value={
                              highlightedBlock?.block.blockProperties.surveyOptionBorderThickness *
                              16
                            }
                            onChange={(value) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                surveyOptionBorderThickness: value / 16
                              })
                            }
                          />
                          <Select
                            className='dropdown-small text-background__border-type'
                            placeholder='Type'
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock?.block.blockType.name !== 'survey'
                            }
                            value={highlightedBlock?.block.blockProperties.surveyOptionBorderType}
                            onChange={(value) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                surveyOptionBorderType: value
                              })
                            }>
                            <Select.Option value='solid' label='Solid'>
                              Solid
                            </Select.Option>
                            <Select.Option value='dotted' label='Dotted'>
                              Dotted
                            </Select.Option>
                            <Select.Option value='dashed' label='Dashed'>
                              Dashed
                            </Select.Option>
                            <Select.Option value='double' label='Double'>
                              Double
                            </Select.Option>
                            <Select.Option value='' label='None'>
                              None
                            </Select.Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='text-background button-props-panel__section-wrapper'>
                    <div className='text-background__color'>
                      <div className='text-background__bg-color'>
                        <h3 className='pre-title text-background__label'>Active</h3>
                        <h3 className='pre-title text-background__label'>Color</h3>
                        <ColorPickerDropdown
                          colors={qooInPreview.theme.colorPalette.colors}
                          colorsBlackWhiteGrey={
                            qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                          }
                          selectedColor={
                            highlightedBlock?.block.blockProperties
                              .surveyOptionActiveBackgroundColor
                          }
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'survey'
                          }
                          leftRadius
                          rightRadius
                          callback={(color) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              surveyOptionActiveBackgroundColor: color
                            })
                          }
                        />
                      </div>
                      <div className='text-background__border'>
                        <h3
                          className='pre-title text-background__label'
                          style={{ marginTop: '26px' }}>
                          Border
                        </h3>
                        <div className='text-background__border-inputs'>
                          <ColorPickerDropdown
                            colors={qooInPreview.theme.colorPalette.colors}
                            colorsBlackWhiteGrey={
                              qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                            }
                            selectedColor={
                              highlightedBlock?.block.blockProperties.surveyOptionActiveBorderColor
                            }
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock?.block.blockType.name !== 'survey'
                            }
                            leftRadius
                            line
                            callback={(color) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                surveyOptionActiveBorderColor: color
                              })
                            }
                          />
                          <InputNumber
                            className='text-background__border-thickness'
                            controls
                            min={1}
                            max={10}
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock?.block.blockType.name !== 'survey'
                            }
                            defaultValue={0}
                            value={
                              highlightedBlock?.block.blockProperties
                                .surveyOptionActiveBorderThickness * 16
                            }
                            onChange={(value) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                surveyOptionActiveBorderThickness: value / 16
                              })
                            }
                          />
                          <Select
                            className='dropdown-small text-background__border-type'
                            placeholder='Type'
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock?.block.blockType.name !== 'survey'
                            }
                            value={
                              highlightedBlock?.block.blockProperties.surveyOptionActiveBorderType
                            }
                            onChange={(value) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                surveyOptionActiveBorderType: value
                              })
                            }>
                            <Select.Option value='solid' label='Solid'>
                              Solid
                            </Select.Option>
                            <Select.Option value='dotted' label='Dotted'>
                              Dotted
                            </Select.Option>
                            <Select.Option value='dashed' label='Dashed'>
                              Dashed
                            </Select.Option>
                            <Select.Option value='double' label='Double'>
                              Double
                            </Select.Option>
                            <Select.Option value='' label='None'>
                              None
                            </Select.Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                    <Checkbox
                      disabled={
                        !highlightedBlock || highlightedBlock?.block.blockType.name !== 'survey'
                      }
                      checked={highlightedBlock?.block.blockProperties.surveyPreviewActive}
                      onChange={(e) =>
                        updateSingleElementProperties(highlightedBlock?.block._id, {
                          surveyPreviewActive: e.target.checked
                        })
                      }>
                      Preview Active
                    </Checkbox>
                  </div>

                  <div className='button-props-panel__slider'>
                    <Slider
                      min={0}
                      max={30000}
                      step={625}
                      disabled={
                        !highlightedBlock || highlightedBlock?.block.blockType.name !== 'survey'
                      }
                      tooltipVisible={false}
                      value={
                        highlightedBlock?.block.blockProperties.surveyOptionBorderRadius?.replace(
                          'em',
                          ''
                        ) * 10000
                      }
                      onChange={(value) =>
                        updateSingleElementProperties(highlightedBlock?.block._id, {
                          surveyOptionBorderRadius: `${value / 10000}em`
                        })
                      }
                    />
                    {highlightedBlock && highlightedBlock?.block.blockType.name === 'survey' && (
                      <span>
                        {highlightedBlock?.block.blockProperties.surveyOptionBorderRadius?.replace(
                          'em',
                          ''
                        ) * 16}
                        px
                      </span>
                    )}
                  </div>

                  <div className='text-background button-props-panel__section-wrapper'>
                    <div className='text-background__border'>
                      <h3 className='pre-title text-background__label'>Option text</h3>
                      <div className='text-background__border-inputs'>
                        <Dropdown
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'survey'
                          }
                          trigger={['click']}
                          overlay={
                            <div className='h-f-create-new__fontlist-dropdown-overlay'>
                              {qooInPreview.theme.selectedFonts.length > 0 &&
                                qooInPreview.theme.selectedFonts.map((font) => {
                                  return (
                                    <div
                                      key={font._id}
                                      className='h-f-create-new__fontlist-dropdown-font'
                                      style={{
                                        fontFamily: font.name,
                                        fontWeight: font.weight,
                                        fontStyle: font.style
                                      }}
                                      onClick={() =>
                                        updateSingleElementProperties(highlightedBlock?.block._id, {
                                          surveyOptionFont: font
                                        })
                                      }>
                                      {font.name}
                                    </div>
                                  );
                                })}
                            </div>
                          }>
                          <div className='h-f-create-new__fonts-dropdown'>
                            <span
                              className='h-f-create-new__fonts-dropdown-text'
                              style={{
                                fontFamily:
                                  highlightedBlock?.block.blockProperties.surveyOptionFont?.name,
                                fontStyle:
                                  highlightedBlock?.block.blockProperties.surveyOptionFont?.style,
                                fontWeight:
                                  highlightedBlock?.block.blockProperties.surveyOptionFont?.weight
                              }}>
                              {highlightedBlock?.block.blockProperties.surveyOptionFont
                                ? highlightedBlock?.block.blockProperties.surveyOptionFont.name
                                : 'Choose font'}
                            </span>
                            <DownCaret className='h-f-create-new__fonts-dropdown__arrow' />
                          </div>
                        </Dropdown>
                        <ColorPickerDropdown
                          colors={qooInPreview.theme.colorPalette.colors}
                          colorsBlackWhiteGrey={
                            qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                          }
                          selectedColor={
                            highlightedBlock?.block.blockProperties.surveyOptionFontColor
                          }
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'survey'
                          }
                          rightRadius
                          callback={(color) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              surveyOptionFontColor: color
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Panel>
                <Panel header={<h3 className='pre-title'>Question background</h3>} key='35'>
                  <div className='text-background'>
                    <div className='text-background__color'>
                      <div className='text-background__bg-color'>
                        <h3 className='pre-title text-background__label'>Color</h3>
                        <ColorPickerDropdown
                          colors={qooInPreview.theme.colorPalette.colors}
                          colorsBlackWhiteGrey={
                            qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                          }
                          selectedColor={
                            highlightedBlock?.block.blockProperties.surveyBackgroundColor
                          }
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'survey'
                          }
                          leftRadius
                          rightRadius
                          callback={(color) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              surveyBackgroundColor: color
                            })
                          }
                        />
                      </div>
                      <div className='text-background__border'>
                        <h3 className='pre-title text-background__label'>Border</h3>
                        <div className='text-background__border-inputs'>
                          <ColorPickerDropdown
                            colors={qooInPreview.theme.colorPalette.colors}
                            colorsBlackWhiteGrey={
                              qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                            }
                            selectedColor={
                              highlightedBlock?.block.blockProperties.surveyBorderColor
                            }
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock?.block.blockType.name !== 'survey'
                            }
                            leftRadius
                            line
                            callback={(color) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                surveyBorderColor: color
                              })
                            }
                          />
                          <InputNumber
                            className='text-background__border-thickness'
                            controls
                            min={1}
                            max={10}
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock?.block.blockType.name !== 'survey'
                            }
                            defaultValue={0}
                            value={
                              highlightedBlock?.block.blockProperties.surveyBorderThickness * 16
                            }
                            onChange={(value) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                surveyBorderThickness: value / 16
                              })
                            }
                          />
                          <Select
                            className='dropdown-small text-background__border-type'
                            placeholder='Type'
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock?.block.blockType.name !== 'survey'
                            }
                            value={highlightedBlock?.block.blockProperties.surveyBorderType}
                            onChange={(value) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                surveyBorderType: value
                              })
                            }>
                            <Select.Option value='solid' label='Solid'>
                              Solid
                            </Select.Option>
                            <Select.Option value='dotted' label='Dotted'>
                              Dotted
                            </Select.Option>
                            <Select.Option value='dashed' label='Dashed'>
                              Dashed
                            </Select.Option>
                            <Select.Option value='double' label='Double'>
                              Double
                            </Select.Option>
                            <Select.Option value='' label='None'>
                              None
                            </Select.Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>
              </>
            )}
            {selectedBlockType.name === 'media' && (
              <>
                <Panel header={<h3 className='pre-title'>Media gallery</h3>} key='36'>
                  <Input
                    placeholder='Search Media'
                    onChange={(event) => {
                      setSearchTerm(event.target.value);
                    }}
                    prefix={<LinkSearch style={{ marginRight: '5px' }} />}
                  />
                  <Select
                    placeholder='Choose media type'
                    className='dropdown-small fieldType'
                    style={{ width: '100%', marginTop: '10px' }}
                    value={mediaType}
                    onChange={(value) => setMediaType(value)}>
                    <Select.Option value='image' className='fieldType'>
                      <MediaIcon style={{ marginRight: '5px' }} />
                      Images
                    </Select.Option>
                    <Select.Option value='video' className='fieldType'>
                      <PlayIcon style={{ marginRight: '5px' }} />
                      Video
                    </Select.Option>
                  </Select>
                  <Select
                    placeholder='Choose Orientation'
                    className='dropdown-small'
                    style={{ width: '100%', marginTop: '10px' }}
                    value={mediaOrientation}
                    onChange={(value) => setMediaOrientation(value)}
                    allowClear>
                    <Select.Option value='portrait' className='fieldType'>
                      Portrait
                    </Select.Option>
                    <Select.Option value='landscape' className='fieldType'>
                      Landscape
                    </Select.Option>
                    <Select.Option value='square' className='fieldType'>
                      Square
                    </Select.Option>
                  </Select>
                  {mediaType === 'image' && (
                    <ImageListMedia
                      mediaType={mediaType}
                      token={token}
                      SERVER_URL={SERVER_URL}
                      callback={(imageUrl) => {
                        addEmptyBlock('media', {
                          mediaType: 'image',
                          mediaPadding: 0,
                          mediaImageSrc: imageUrl,
                          mediaImageIntensity: 1,
                          mediaImageBrightness: 1,
                          mediaImageContrast: 1,
                          mediaImageSaturate: 1
                        });
                      }}
                      searchKeyword={searchTerm}
                      orientation={mediaOrientation}
                    />
                  )}
                  {mediaType === 'video' && (
                    <VideoList
                      token={token}
                      SERVER_URL={SERVER_URL}
                      callback={(videoUrl, videoThumbnail) => {
                        addEmptyBlock('media', {
                          mediaType: 'video',
                          mediaPadding: 0,
                          mediaVideoSrc: videoUrl,
                          mediaVideoThumbnail: videoThumbnail,
                          autoplay: false,
                          loop: false,
                          muted: false,
                          volume: 1
                        });
                      }}
                      searchKeyword={searchTerm}
                      orientation={mediaOrientation}
                    />
                  )}
                </Panel>

                {highlightedBlock &&
                  highlightedBlock?.block.blockType.name === 'media' &&
                  highlightedBlock?.block.blockProperties.mediaType === 'image' && (
                    <>
                      <Panel header={<h3 className='pre-title'>Edit image</h3>} key='37'>
                        <div className='button-props-panel__btn-effects-choices choice-btn-group'>
                          <div
                            onClick={() => setEditImageOption('filter')}
                            className={
                              editImageOption === 'filter'
                                ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                                : 'button-props-panel__btn-effects-choice choice-btn'
                            }>
                            Filter
                          </div>
                          <div
                            onClick={() => setEditImageOption('adjust')}
                            className={
                              editImageOption === 'adjust'
                                ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                                : 'button-props-panel__btn-effects-choice choice-btn'
                            }>
                            Adjust
                          </div>
                        </div>
                        {editImageOption === 'filter' && (
                          <div>
                            <h3 className='pre-title text-background__label'>Intensity</h3>
                            <Row gutter={16} style={{ marginBottom: '20px', marginTop: '5px' }}>
                              <Col md={20}>
                                <Slider
                                  style={{ width: '100%' }}
                                  min={0}
                                  max={100}
                                  tooltipVisible={false}
                                  value={
                                    highlightedBlock?.block.blockProperties.mediaImageIntensity *
                                    100
                                  }
                                  onChange={(value) =>
                                    updateSingleElementProperties(highlightedBlock?.block._id, {
                                      mediaImageIntensity: value / 100
                                    })
                                  }
                                />
                              </Col>
                              <Col
                                md={4}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end'
                                }}>
                                <span>
                                  {Math.round(
                                    highlightedBlock?.block.blockProperties.mediaImageIntensity *
                                      100
                                  )}
                                  %
                                </span>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {editImageOption === 'adjust' && (
                          <div style={{ marginTop: '20px' }}>
                            <h3 className='pre-title text-background__label'>Brightness</h3>
                            <Row gutter={16} style={{ marginBottom: '20px', marginTop: '5px' }}>
                              <Col md={20}>
                                <Slider
                                  style={{ width: '100%' }}
                                  min={0}
                                  max={300}
                                  tooltipVisible={false}
                                  value={
                                    highlightedBlock?.block.blockProperties.mediaImageBrightness *
                                    100
                                  }
                                  onChange={(value) =>
                                    updateSingleElementProperties(highlightedBlock?.block._id, {
                                      mediaImageBrightness: value / 100
                                    })
                                  }
                                />
                              </Col>
                              <Col
                                md={4}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end'
                                }}>
                                <span>
                                  {Math.round(
                                    highlightedBlock?.block.blockProperties.mediaImageBrightness *
                                      100
                                  )}
                                  %
                                </span>
                              </Col>
                            </Row>
                            <h3 className='pre-title text-background__label'>Contrast</h3>
                            <Row gutter={16} style={{ marginBottom: '20px', marginTop: '5px' }}>
                              <Col md={20}>
                                <Slider
                                  style={{ width: '100%' }}
                                  min={0}
                                  max={300}
                                  tooltipVisible={false}
                                  value={
                                    highlightedBlock?.block.blockProperties.mediaImageContrast * 100
                                  }
                                  onChange={(value) =>
                                    updateSingleElementProperties(highlightedBlock?.block._id, {
                                      mediaImageContrast: value / 100
                                    })
                                  }
                                />
                              </Col>
                              <Col
                                md={4}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end'
                                }}>
                                <span>
                                  {Math.round(
                                    highlightedBlock?.block.blockProperties.mediaImageContrast * 100
                                  )}
                                  %
                                </span>
                              </Col>
                            </Row>
                            <h3 className='pre-title text-background__label'>Saturation</h3>
                            <Row gutter={16} style={{ marginBottom: '20px', marginTop: '5px' }}>
                              <Col md={20}>
                                <Slider
                                  style={{ width: '100%' }}
                                  min={0}
                                  max={300}
                                  tooltipVisible={false}
                                  value={
                                    highlightedBlock?.block.blockProperties.mediaImageSaturate * 100
                                  }
                                  onChange={(value) =>
                                    updateSingleElementProperties(highlightedBlock?.block._id, {
                                      mediaImageSaturate: value / 100
                                    })
                                  }
                                />
                              </Col>
                              <Col
                                md={4}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end'
                                }}>
                                <span>
                                  {Math.round(
                                    highlightedBlock?.block.blockProperties.mediaImageSaturate * 100
                                  )}
                                  %
                                </span>
                              </Col>
                            </Row>
                            <span
                              className='defaultText'
                              onClick={(value) =>
                                updateSingleElementProperties(highlightedBlock?.block._id, {
                                  mediaImageIntensity: 1,
                                  mediaImageBrightness: 1,
                                  mediaImageContrast: 1,
                                  mediaImageSaturate: 1
                                })
                              }>
                              Reset to default
                            </span>
                          </div>
                        )}
                      </Panel>

                      <Panel header={<h3 className='pre-title'>Crop</h3>} key='38'>
                        <Row gutter={8}>
                          <Col md={12}>
                            <Button
                              onClick={() => setImageCropModalVisible(true)}
                              style={{ width: '100%' }}
                              type='primary'>
                              Crop
                            </Button>
                          </Col>
                        </Row>
                        <h3
                          style={{ marginTop: '15px', marginBottom: '10px' }}
                          className='pre-title text-background__label'>
                          Crop to shape
                        </h3>
                        <Select
                          style={{ width: '100%' }}
                          className='dropdown-small'
                          placeholder='Choose shape'
                          allowClear={true}
                          value={highlightedBlock?.block.blockProperties.mediaImageClipPath}
                          onChange={(value) =>
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              mediaImageClipPath: value
                            })
                          }>
                          <Select.Option value='circle(50% at 50% 50%)'>Circle</Select.Option>
                          <Select.Option value='polygon(50% 0%, 0% 100%, 100% 100%)'>
                            Triangle
                          </Select.Option>
                          <Select.Option value='polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)'>
                            Octagon
                          </Select.Option>
                          <Select.Option value='polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%)'>
                            Left Arrow
                          </Select.Option>
                          <Select.Option value='polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%)'>
                            Right Arrow
                          </Select.Option>
                          <Select.Option value='polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)'>
                            Star
                          </Select.Option>
                        </Select>

                        {imageCropModalVisible && (
                          <ImageCropModal
                            imageCropModalVisible={imageCropModalVisible}
                            setImageCropModalVisible={setImageCropModalVisible}
                            image={highlightedBlock?.block.blockProperties.mediaImageSrc}
                            SERVER_URL={SERVER_URL}
                            token={token}
                            setNewImage={(imgUrl) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                mediaImageSrc: imgUrl
                              })
                            }
                          />
                        )}
                      </Panel>
                    </>
                  )}
                {highlightedBlock &&
                  highlightedBlock?.block.blockType.name === 'media' &&
                  highlightedBlock?.block.blockProperties.mediaType === 'video' && (
                    <>
                      <Panel header={<h3 className='pre-title'>Playback</h3>} key='39'>
                        <Row gutter={16} style={{ marginBottom: '10px' }}>
                          <Col md={20}>Autoplay</Col>
                          <Col md={4}>
                            <Switch
                              checked={highlightedBlock?.block.blockProperties.autoplay}
                              onChange={(checked) =>
                                updateSingleElementProperties(highlightedBlock?.block._id, {
                                  autoplay: checked
                                })
                              }
                            />
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col md={20}>Loop video</Col>
                          <Col md={4}>
                            <Switch
                              checked={highlightedBlock?.block.blockProperties.loop}
                              onChange={(checked) =>
                                updateSingleElementProperties(highlightedBlock?.block._id, {
                                  loop: checked
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </Panel>

                      <Panel header={<h3 className='pre-title'>Audio</h3>} key='40'>
                        <h3 className='pre-title text-background__label'>Volume</h3>
                        <Row gutter={16} style={{ marginBottom: '20px', marginTop: '5px' }}>
                          <Col md={20}>
                            <Slider
                              style={{ width: '100%' }}
                              min={0}
                              max={100}
                              value={highlightedBlock?.block.blockProperties.volume * 100}
                              onChange={(value) =>
                                updateSingleElementProperties(highlightedBlock?.block._id, {
                                  volume: value / 100
                                })
                              }
                            />
                          </Col>
                          <Col
                            md={4}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }}>
                            <span>
                              {Math.round(highlightedBlock?.block.blockProperties.volume * 100)}
                            </span>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col md={20}>Mute video</Col>
                          <Col md={4}>
                            <Switch
                              checked={highlightedBlock?.block.blockProperties.muted}
                              onChange={(checked) =>
                                updateSingleElementProperties(highlightedBlock?.block._id, {
                                  muted: checked
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </Panel>
                    </>
                  )}
              </>
            )}
            {selectedBlockType.name === 'shape' && (
              <>
                <Panel header={<h3 className='pre-title'>Shapes</h3>} key='41'>
                  <Shapes addEmptyBlock={addEmptyBlock} />
                </Panel>
                <Panel header={<h3 className='pre-title'>Shape color & border</h3>} key='42'>
                  <div className='button-props-panel__section-wrapper button-props-panel__section-wrapper'>
                    <div className='button-props-panel__color'>
                      <div className='button-props-panel__btn-color'>
                        <h3 className='pre-title button-props-panel__label'>Color</h3>
                        <ColorPickerDropdown
                          colors={qooInPreview.theme.colorPalette.colors}
                          colorsBlackWhiteGrey={
                            qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                          }
                          selectedColor={highlightedBlock?.block.blockProperties.shapeColor}
                          disabled={
                            !highlightedBlock || highlightedBlock?.block.blockType.name !== 'shape'
                          }
                          leftRadius
                          rightRadius
                          callback={(color) => {
                            updateSingleElementProperties(highlightedBlock?.block._id, {
                              shapeColor: color
                            });
                          }}
                        />
                      </div>
                      <div className='button-props-panel__border'>
                        <h3 className='pre-title button-props-panel__label'>Border</h3>
                        <div className='button-props-panel__border-inputs'>
                          <ColorPickerDropdown
                            colors={qooInPreview.theme.colorPalette.colors}
                            colorsBlackWhiteGrey={
                              qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                            }
                            selectedColor={highlightedBlock?.block.blockProperties.shapeBorderColor}
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock?.block.blockType.name !== 'shape'
                            }
                            leftRadius
                            callback={(color) => {
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                shapeBorderColor: color
                              });
                            }}
                          />
                          <InputNumber
                            className='input-small button-props-panel__border-thickness'
                            min={0}
                            max={10}
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock?.block.blockType.name !== 'shape'
                            }
                            value={
                              highlightedBlock?.block.blockProperties.shapeBorderThickness?.replace(
                                'em',
                                ''
                              ) * 16
                            }
                            onChange={(value) => {
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                shapeBorderThickness: `${value / 16}em`
                              });
                            }}
                          />
                          <Select
                            className='dropdown-small button-props-panel__border-type'
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock?.block.blockType.name !== 'shape'
                            }
                            value={highlightedBlock?.block.blockProperties.shapeBorderType}
                            onChange={(value) => {
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                shapeBorderType: value
                              });
                            }}>
                            <Select.Option value='solid' label='Solid'>
                              Solid
                            </Select.Option>
                            <Select.Option value='dotted' label='Dotted'>
                              Dotted
                            </Select.Option>
                            <Select.Option value='dashed' label='Dashed'>
                              Dashed
                            </Select.Option>
                            <Select.Option value='' label='None'>
                              None
                            </Select.Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>
                <Panel header={<h3 className='pre-title'>Shape Shadow</h3>} key='43'>
                  <div className='button-props-panel__btn-effects'>
                    <div className='button-props-panel__btn-effects-choices choice-btn-group'>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'shape'
                            ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--disabled'
                            : highlightedBlock?.block.blockProperties.shapeHasShadow &&
                              !highlightedBlock?.block.blockProperties.shapeInnerShadow
                            ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                            : 'button-props-panel__btn-effects-choice choice-btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'shape'
                          ) {
                            // dispatch({
                            //   type: EDIT_INNER_BLOCK_IN_LAYOUT,
                            //   payload: {
                            //     blockId: highlightedBlock?.block._id,
                            //     updates: highlightedBlock?.block.blockProperties.shapeHasShadow
                            //       ? highlightedBlock?.block.blockProperties.shapeInnerShadow
                            //         ? {
                            //           shapeInnerShadow: false,
                            //           shapeHasShadow: true,
                            //         }
                            //         : {
                            //           shapeHasShadow: false,
                            //         }
                            //       : {
                            //         shapeInnerShadow: false,
                            //         shapeHasShadow: true,
                            //       },
                            //   },
                            // });
                          }
                        }}>
                        Drop shadow
                      </div>
                      <div
                        className={
                          !highlightedBlock || highlightedBlock?.block.blockType.name !== 'shape'
                            ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--disabled'
                            : highlightedBlock?.block.blockProperties.shapeHasShadow &&
                              highlightedBlock?.block.blockProperties.shapeInnerShadow
                            ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                            : 'button-props-panel__btn-effects-choice choice-btn'
                        }
                        onClick={() => {
                          if (
                            highlightedBlock &&
                            highlightedBlock?.block.blockType.name === 'shape'
                          ) {
                            // dispatch({
                            //   type: EDIT_INNER_BLOCK_IN_LAYOUT,
                            //   payload: {
                            //     blockId: highlightedBlock?.block._id,
                            //     updates: highlightedBlock?.block.blockProperties.shapeHasShadow
                            //       ? highlightedBlock?.block.blockProperties.shapeInnerShadow
                            //         ? {
                            //           shapeHasShadow: false,
                            //         }
                            //         : {
                            //           shapeInnerShadow: true,
                            //           shapeHasShadow: true,
                            //         }
                            //       : {
                            //         shapeInnerShadow: true,
                            //         shapeHasShadow: true,
                            //       },
                            //   },
                            // });
                          }
                        }}>
                        Inner Shadow
                      </div>
                    </div>
                    {advancedMode && (
                      <>
                        <div className='button-props-panel__btn-effects-color'>
                          <h3 className='pre-title button-props-panel__label'>Color</h3>
                          <ColorPickerDropdown
                            colors={blockInPreview.theme.colorPalette.colors}
                            colorsBlackWhiteGrey={
                              blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                            }
                            selectedColor={
                              highlightedBlock?.block.blockProperties.shapeShadowColor || null
                            }
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock?.block.blockType.name !== 'shape'
                            }
                            leftRadius
                            rightRadius
                            callback={(color) => {
                              dispatch({
                                type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                payload: {
                                  blockId: highlightedBlock?.block._id,
                                  updates: {
                                    shapeShadowColor: color
                                  }
                                }
                              });
                            }}
                          />
                        </div>
                        <div className='button-props-panel__btn-effects-color'>
                          <h3 className='pre-title button-props-panel__label'>X position</h3>
                          <div className='button-props-panel__slider'>
                            <Slider
                              min={-100}
                              max={100}
                              disabled={
                                !highlightedBlock ||
                                highlightedBlock?.block.blockType.name !== 'shape'
                              }
                              tooltipVisible={false}
                              value={
                                highlightedBlock?.block.blockProperties.shapeShadowX
                                  ? highlightedBlock?.block.blockProperties.shapeShadowX.replace(
                                      'em',
                                      ''
                                    ) * 100
                                  : 0
                              }
                              onChange={(value) => {
                                dispatch({
                                  type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                  payload: {
                                    blockId: highlightedBlock?.block._id,
                                    updates: {
                                      shapeShadowX: `${value / 100}em`
                                    }
                                  }
                                });
                              }}
                            />
                            {highlightedBlock &&
                              highlightedBlock?.block.blockType.name === 'shape' && (
                                <span>
                                  {highlightedBlock?.block.blockProperties.shapeShadowX
                                    ? Math.round(
                                        highlightedBlock?.block.blockProperties.shapeShadowX.replace(
                                          'em',
                                          ''
                                        ) * 100
                                      )
                                    : 0}
                                </span>
                              )}
                          </div>
                          <h3 className='pre-title button-props-panel__label'>Y position</h3>
                          <div className='button-props-panel__slider'>
                            <Slider
                              min={-100}
                              max={100}
                              disabled={
                                !highlightedBlock ||
                                highlightedBlock?.block.blockType.name !== 'shape'
                              }
                              tooltipVisible={false}
                              value={
                                highlightedBlock?.block.blockProperties.shapeShadowY
                                  ? highlightedBlock?.block.blockProperties.shapeShadowY.replace(
                                      'em',
                                      ''
                                    ) * 100
                                  : 0
                              }
                              onChange={(value) => {
                                dispatch({
                                  type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                  payload: {
                                    blockId: highlightedBlock?.block._id,
                                    updates: {
                                      shapeShadowY: `${value / 100}em`
                                    }
                                  }
                                });
                              }}
                            />
                            {highlightedBlock &&
                              highlightedBlock?.block.blockType.name === 'shape' && (
                                <span>
                                  {highlightedBlock?.block.blockProperties.shapeShadowY
                                    ? Math.round(
                                        highlightedBlock?.block.blockProperties.shapeShadowY.replace(
                                          'em',
                                          ''
                                        ) * 100
                                      )
                                    : 0}
                                </span>
                              )}
                          </div>
                          <h3 className='pre-title button-props-panel__label'>Blur</h3>
                          <div className='button-props-panel__slider'>
                            <Slider
                              min={0}
                              max={100}
                              disabled={
                                !highlightedBlock ||
                                highlightedBlock?.block.blockType.name !== 'shape'
                              }
                              tooltipVisible={false}
                              value={
                                highlightedBlock?.block.blockProperties.shapeShadowBlur
                                  ? highlightedBlock?.block.blockProperties.shapeShadowBlur.replace(
                                      'em',
                                      ''
                                    ) * 100
                                  : 0
                              }
                              onChange={(value) => {
                                dispatch({
                                  type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                  payload: {
                                    blockId: highlightedBlock?.block._id,
                                    updates: {
                                      shapeShadowBlur: `${value / 100}em`
                                    }
                                  }
                                });
                              }}
                            />
                            {highlightedBlock &&
                              highlightedBlock?.block.blockType.name === 'shape' && (
                                <span>
                                  {highlightedBlock?.block.blockProperties.shapeShadowBlur
                                    ? Math.round(
                                        highlightedBlock?.block.blockProperties.shapeShadowBlur.replace(
                                          'em',
                                          ''
                                        ) * 100
                                      )
                                    : 0}
                                </span>
                              )}
                          </div>
                          <h3 className='pre-title button-props-panel__label'>Opacity</h3>
                          <div className='button-props-panel__slider'>
                            <Slider
                              min={0}
                              max={100}
                              disabled={
                                !highlightedBlock ||
                                highlightedBlock?.block.blockType.name !== 'shape'
                              }
                              tooltipVisible={false}
                              value={
                                highlightedBlock?.block.blockProperties.shapeShadowOpacity
                                  ? highlightedBlock?.block.blockProperties.shapeShadowOpacity * 100
                                  : 0
                              }
                              onChange={(value) => {
                                dispatch({
                                  type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                  payload: {
                                    blockId: highlightedBlock?.block._id,
                                    updates: {
                                      shapeShadowOpacity: value / 100
                                    }
                                  }
                                });
                              }}
                            />
                            {highlightedBlock &&
                              highlightedBlock?.block.blockType.name === 'shape' && (
                                <span>
                                  {highlightedBlock?.block.blockProperties.shapeShadowOpacity
                                    ? Math.round(
                                        highlightedBlock?.block.blockProperties.shapeShadowOpacity *
                                          100
                                      )
                                    : 0}
                                </span>
                              )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Panel>
              </>
            )}
            {/* {selectedBlockType.name !== 'info' &&
              selectedBlockType.name !== 'shape' &&
              selectedBlockType.name !== 'browse' && (
                <div className='sidebar-inner__category-field'>
                  <div className='advanced-mode'>
                    <h3 className='pre-title pre-title--dark'>Advanced mode</h3>
                    <Switch
                      checked={advancedMode}
                      onChange={(checked) => setAdvancedMode(checked)}
                    />
                  </div>
                </div>
              )} */}
            {advancedMode &&
              highlightedBlock &&
              highlightedBlock?.block.blockType.name === 'media' && (
                <>
                  <Panel header={<h3 className='pre-title'>Padding</h3>} key='44'>
                    {highlightedBlock && highlightedBlock?.block.blockType.name === 'media' && (
                      <Row gutter={16}>
                        <Col md={20}>
                          <Slider
                            style={{ width: '100%' }}
                            min={0}
                            max={100}
                            tooltipVisible={false}
                            value={highlightedBlock?.block?.blockProperties.mediaPadding}
                            onChange={(value) =>
                              updateSingleElementProperties(highlightedBlock?.block._id, {
                                mediaPadding: value
                              })
                            }
                          />
                        </Col>
                        <Col
                          md={4}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }}>
                          <span>{highlightedBlock?.block.blockProperties.mediaPadding}</span>
                        </Col>
                      </Row>
                    )}
                  </Panel>

                  <Panel header={<h3 className='pre-title'>Background</h3>} key='45'>
                    {highlightedBlock && highlightedBlock?.block.blockType.name === 'media' && (
                      <div className='text-background'>
                        <div className='text-background__color'>
                          <div className='text-background__bg-color'>
                            <h3 className='pre-title text-background__label'>Color</h3>
                            <ColorPickerDropdown
                              colors={qooInPreview.theme.colorPalette.colors}
                              colorsBlackWhiteGrey={
                                qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                              }
                              leftRadius
                              rightRadius
                              selectedColor={
                                highlightedBlock?.block.blockProperties.mediaBackgroundColor
                              }
                              callback={(color) =>
                                updateSingleElementProperties(highlightedBlock?.block._id, {
                                  mediaBackgroundColor: color
                                })
                              }
                            />
                          </div>
                          <div className='text-background__border'>
                            <h3 className='pre-title text-background__label'>Border</h3>
                            <div className='text-background__border-inputs'>
                              <ColorPickerDropdown
                                colors={qooInPreview.theme.colorPalette.colors}
                                colorsBlackWhiteGrey={
                                  qooInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                }
                                leftRadius
                                line
                                selectedColor={
                                  highlightedBlock?.block.blockProperties.mediaBorderColor
                                }
                                callback={(color) =>
                                  updateSingleElementProperties(highlightedBlock?.block._id, {
                                    mediaBorderColor: color
                                  })
                                }
                              />
                              <InputNumber
                                className='text-background__border-thickness'
                                controls
                                min={1}
                                max={10}
                                defaultValue={0}
                                value={
                                  highlightedBlock?.block.blockProperties.mediaBorderThickness * 16
                                }
                                onChange={(value) =>
                                  updateSingleElementProperties(highlightedBlock?.block._id, {
                                    mediaBorderThickness: value / 16
                                  })
                                }
                              />
                              <Select
                                className='dropdown-small text-background__border-type'
                                placeholder='Type'
                                value={highlightedBlock?.block.blockProperties.mediaBorderType}
                                onChange={(value) =>
                                  updateSingleElementProperties(highlightedBlock?.block._id, {
                                    mediaBorderType: value
                                  })
                                }>
                                <Select.Option value='solid' label='Solid'>
                                  Solid
                                </Select.Option>
                                <Select.Option value='dotted' label='Dotted'>
                                  Dotted
                                </Select.Option>
                                <Select.Option value='dashed' label='Dashed'>
                                  Dashed
                                </Select.Option>
                                <Select.Option value='double' label='Double'>
                                  Double
                                </Select.Option>
                                <Select.Option value='' label='None'>
                                  None
                                </Select.Option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Panel>
                </>
              )}
          </Collapse>
        )}
      </SideBarInner>
      <Layout>
        {isDataFetched && (
          <>
            <HeaderInner>
              <div className='edit-block-inner-header'>
                <BlockTypesList
                  currentuser={currentuser}
                  blockTypes={allBlockTypes.data}
                  // isNew={isNew}
                  selectedBlockType={selectedBlockType}
                  setSelectedBlockType={setSelectedBlockType}
                  setSelectedBlock={setSelectedBlock}
                  blockOrQooInPreview={qooInPreview}
                  qooBuilder={true}
                  endnoteCallback={(endnoteType) => setAddingEndnote(endnoteType)}
                  highlightedBlock={highlightedBlock}
                />
                {selectedBlockType && (
                  <div className='edit-block-header-buttons'>
                    <div className='edit-block-undo-redo'>
                      <UndoIcon
                        className={
                          blockInPreview
                            ? blockInPreviewHistory.length < 1
                              ? 'edit-block-undo-redo__btn edit-block-undo-redo__btn--disabled'
                              : 'edit-block-undo-redo__btn'
                            : qooInPreviewHistory.length < 1
                            ? 'edit-block-undo-redo__btn edit-block-undo-redo__btn--disabled'
                            : 'edit-block-undo-redo__btn'
                        }
                        onClick={
                          blockInPreview
                            ? blockInPreviewHistory.length < 1
                              ? null
                              : handleUndo
                            : qooInPreviewHistory.length < 1
                            ? null
                            : handleUndo
                        }
                      />
                      <RedoIcon
                        className={
                          blockInPreview
                            ? blockInPreviewFuture.length < 1
                              ? 'edit-block-undo-redo__btn edit-block-undo-redo__btn--disabled'
                              : 'edit-block-undo-redo__btn'
                            : qooInPreviewFuture.length < 1
                            ? 'edit-block-undo-redo__btn edit-block-undo-redo__btn--disabled'
                            : 'edit-block-undo-redo__btn'
                        }
                        onClick={
                          blockInPreview
                            ? blockInPreviewFuture.length < 1
                              ? null
                              : handleRedo
                            : qooInPreviewFuture.length < 1
                            ? null
                            : handleRedo
                        }
                      />
                    </div>
                    <div className='edit-block-save-cnc'>
                      <Button
                        className='edit-block-save-cnc__preview'
                        disabled={blockInPreview}
                        onClick={() => {
                          phonePreview === true ? setPhonePreview(false) : setPhonePreview(true);
                        }}>
                        <span>Preview</span>
                        <Phone />
                      </Button>
                      <Button
                        type='primary'
                        className='edit-block-save-cnc__save'
                        disabled={
                          qooInPreviewHistory.length < 1 ||
                          _.isEqual(qooInPreview, qooInPreviewHistory[0]) ||
                          blockInPreview
                        }
                        onClick={() => onSubmit({ ...qooInPreview }, false, true)}>
                        <span>
                          {qooTypes.find(
                            (item) =>
                              item?._id === (qooInPreview?.qooType?._id || qooInPreview?.qooType)
                          )?.name === 'ePro' && currentuser.data.role.includes('superadmin')
                            ? 'Save as EPro'
                            : 'Save'}
                        </span>
                        <Save />
                      </Button>
                      {qooTypes.find(
                        (item) =>
                          item?._id === (qooInPreview?.qooType?._id || qooInPreview?.qooType)
                      )?.name !== 'ePro'
                        ? currentuser.data.role.includes('superadmin') && (
                            <Button
                              type='primary'
                              className='edit-block-save-cnc__save-template'
                              onClick={() => {
                                if (qooInPreview?.status === 'template') {
                                  convertToTemplate({
                                    status: 'draft',
                                    isQooloTemplate: false
                                  });
                                } else {
                                  convertToTemplate({
                                    status: 'template',
                                    isQooloTemplate: true
                                  });
                                }
                              }}
                              disabled={sendingTemplateData}>
                              {qooInPreview?.status === 'template'
                                ? 'Remove from templates'
                                : 'Save as template'}
                            </Button>
                          )
                        : null}
                      <Popconfirm
                        title='Are you sure you want to leave without saving? All chages will be lost!'
                        onConfirm={cancelFunction}>
                        <div className='edit-block-save-cnc__cnc-btn'>
                          <Close className='edit-block-save-cnc__cancel' />
                        </div>
                      </Popconfirm>
                    </div>
                  </div>
                )}
              </div>
            </HeaderInner>
            <Content>
              <div
                ref={qooBuilderRef}
                className='qoo-builder'
                // onContextMenu={(e) => handleRightClick(e)}
              >
                <div
                  style={{
                    position: 'relative',
                    height: '723px',
                    marginTop: '25px',
                    display: 'flex',
                    alignItems: gridAlign ? gridAlign : 'center',
                    backgroundColor: qooInPreview.qooBackgroundImage
                      ? ''
                      : qooInPreview.qooBackgroundColor
                      ? qooInPreview.qooBackgroundColor.hex
                      : qooInPreview.theme.colorAssignment.qooBackground
                      ? qooInPreview.theme.colorAssignment.qooBackground.hex
                      : '#fff',
                    backgroundImage: qooInPreview.qooBackgroundImage
                      ? `url(${qooInPreview.qooBackgroundImage})`
                      : '',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    border:
                      qooInPreview.qooBorderColor &&
                      qooInPreview.qooBorderThickness &&
                      qooInPreview.qooBorderType
                        ? `${qooInPreview.qooBorderThickness}em ${qooInPreview.qooBorderType} ${qooInPreview.qooBorderColor.hex}`
                        : 'none'
                  }}>
                  <div
                    ref={gridRef}
                    className='qoo-preview'
                    style={{
                      fontSize: `${(384 / 570) * 16}px`,
                      gridTemplateColumns: `repeat(12, 32px)`,
                      gridTemplateRows: `repeat(${gridSize[1]}, 32px)`
                    }}>
                    {emptyArray.map((item, index) => {
                      return (
                        <BlockPreviewBox
                          key={index}
                          index={index}
                          arrLength={emptyArray.length}
                          gridOptions={gridOptions}
                          width='32px'
                          height='32px'
                        />
                      );
                    })}
                    <QooPreviewGrid
                      draggingItem={draggingItem}
                      qooInPreview={qooInPreview}
                      dispatch={dispatch}
                      selectedBlock={selectedBlock}
                      setSelectedBlock={setSelectedBlock}
                      setSelectedBlockType={setSelectedBlockType}
                      rows={gridSize[1]}
                      blockInPreview={blockInPreview}
                      blockInPreviewDispatch={blockInPreviewDispatch}
                      setEditBlockModalVisible={setEditBlockModalVisible}
                      handleRightClick={handleRightClick}
                      contextMenuCoords={contextMenuCoords}
                      highlightedBlock={highlightedBlock}
                      hoveredEndnote={hoveredEndnote}
                      showButtonAsActive={showButtonAsActive}
                    />
                  </div>
                  {qooInPreview.showHeader &&
                    qooInPreview.theme.headerFooter &&
                    !qooInPreview.theme.headerFooter.header.noHeader && (
                      <QooHeader
                        headerHeight={headerHeight}
                        header={qooInPreview.theme.headerFooter.header}
                        gridRowHeight={32}
                      />
                    )}
                  {(qooInPreview.showISI || qooInPreview.showFooter) &&
                    qooInPreview.theme?.headerFooter &&
                    (!qooInPreview.theme?.headerFooter?.footer?.noISI ||
                      !qooInPreview.theme?.headerFooter?.footer?.noFooter) && (
                      <QooFooter
                        footerHeight={footerHeight}
                        footer={qooInPreview.theme.headerFooter.footer}
                        gridRowHeight={32}
                        dontShowISI={!qooInPreview.showISI}
                        dontShowFooter={!qooInPreview.showFooter}
                        ISISizeInQoo={qooInPreview.ISISize}
                      />
                    )}
                  {blockInPreview && (
                    <div
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '0',
                        height: '100%',
                        width: '100%',
                        backgroundColor: '#FFF',
                        opacity: '0.75',
                        zIndex: '1'
                      }}></div>
                  )}
                  {blockInPreview && (
                    <div
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '0',
                        height: '100%',
                        width: '100%',
                        zIndex: '2'
                      }}>
                      <BlockInPreviewGrid
                        blockInPreview={blockInPreview}
                        dispatch={blockInPreviewDispatch}
                        selectedBlock={selectedBlock}
                        setSelectedBlock={setSelectedBlock}
                        setSelectedBlockType={setSelectedBlockType}
                        handleRightClick={handleRightClick}
                        hoveredEndnote={hoveredEndnote}
                        rowsNumber={22}
                        rowHeight={32}
                      />
                    </div>
                  )}
                  {qooInPreview.headerFooter?.header?.menuOverlayLayout &&
                    qooInPreview.previewMenu && (
                      <MenuPreview
                        headerFooter={qooInPreview.headerFooter}
                        menuLinks={
                          qooInPreview && qooInPreview.menuLinks ? qooInPreview.menuLinks : []
                        }
                      />
                    )}
                </div>

                {phonePreview && (
                  <QooPhonePreviewModal
                    visible={phonePreview}
                    closeModal={() => setPhonePreview(false)}
                    qoo={qooInPreview}
                  />
                )}

                {blockInPreview && (
                  <div className='qoo-builder__block-btns'>
                    <Popconfirm
                      placement='bottom'
                      title='Are you sure you want to leave without saving? All chages will be lost!'
                      onConfirm={() => {
                        blockInPreviewDispatch({
                          type: SELECT_BLOCK,
                          payload: null
                        });
                      }}>
                      <Button
                      // className="edit-block-save-cnc__preview"
                      >
                        <span>Cancel</span>
                      </Button>
                    </Popconfirm>

                    <Button
                      type='primary'
                      // className="edit-block-save-cnc__save"
                      disabled={blockInPreviewHistory.length < 1}
                      onClick={() => setSaveBlockModalVisible(true)}>
                      <span>Save block</span>
                      <Save />
                    </Button>
                  </div>
                )}
                {editBlockModalVisible && (
                  <EditBlockModal
                    editBlockModalVisible={editBlockModalVisible}
                    setEditBlockModalVisible={setEditBlockModalVisible}
                    blockInPreview={blockInPreview}
                    blockInPreviewDispatch={blockInPreviewDispatch}
                    qoos={qoos}
                    qoolections={qoolections}
                  />
                )}
                {saveBlockModalVisible && (
                  <SaveBlockModal
                    saveBlockModalVisible={saveBlockModalVisible}
                    setSaveBlockModalVisible={setSaveBlockModalVisible}
                    audiences={audiences}
                    categories={categories}
                    blockInPreview={blockInPreview}
                    SERVER_URL={SERVER_URL}
                    token={token}
                    dispatch={dispatch}
                    blockInPreviewDispatch={blockInPreviewDispatch}
                  />
                )}

                {rightPanelsView === 'single' && (
                  <>
                    <div className='right-panels-btn-container'>
                      <div
                        className={
                          rightPanel === 'endnotes'
                            ? 'right-panels-btn right-panels-btn--selected'
                            : 'right-panels-btn'
                        }
                        onClick={() => {
                          if (rightPanel === 'endnotes') {
                            setRightPanel('single');
                          } else {
                            setRightPanel('endnotes');
                          }
                        }}>
                        <EndnotesIcon />
                      </div>
                      {/* <div
                        className={
                          rightPanel === 'panels'
                            ? 'right-panels-btn right-panels-btn--selected'
                            : 'right-panels-btn'
                        }
                        onClick={() => {
                          if (rightPanel === 'panels') {
                            setRightPanel('single');
                          } else {
                            setRightPanel('panels');
                          }
                        }}>
                        <PanelsIcon />
                      </div> */}
                      <div
                        className={
                          rightPanel === 'layers'
                            ? 'right-panels-btn right-panels-btn--selected'
                            : 'right-panels-btn'
                        }
                        onClick={() => {
                          if (rightPanel === 'layers') {
                            setRightPanel('single');
                          } else {
                            setRightPanel('layers');
                          }
                        }}>
                        <LayersIcon />
                      </div>
                    </div>
                    {rightPanel === 'endnotes' && (
                      <EndnotesPanel
                        endnotes={
                          blockInPreview
                            ? [
                                ...blockInPreview.endnotes?.references.map(
                                  (reference) => reference.item
                                ),
                                ...blockInPreview.endnotes?.footnotes.map(
                                  (footnote) => footnote.item
                                ),
                                ...[
                                  ...blockInPreview.endnotes.abbreviations.map(
                                    (abbreviation) => abbreviation.item
                                  )
                                ].sort((a, b) => {
                                  if (a.abbreviation < b.abbreviation) return -1;
                                  if (a.abbreviation > b.abbreviation) return 1;
                                  return 0;
                                })
                              ]
                            : [
                                ...allEndnotes.references,
                                ...allEndnotes.footnotes,
                                ...[...allEndnotes.abbreviations].sort((a, b) => {
                                  if (a.abbreviation < b.abbreviation) return -1;
                                  if (a.abbreviation > b.abbreviation) return 1;
                                  return 0;
                                })
                              ]
                        }
                        dockToRightSidebar={() => setRightPanelsView('sidebar')}
                        rightPanel={rightPanel}
                        setHoveredEndnote={setHoveredEndnote}
                        removeCallback={(endnote) => setEndnoteToDelete(endnote)}
                        editCallback={(endnote) => setEndnoteForEdit(endnote)}
                      />
                    )}
                    {rightPanel === 'panels' && (
                      <PanelsPanel
                        dockToRightSidebar={() => setRightPanelsView('sidebar')}
                        rightPanel={rightPanel}
                      />
                    )}
                    {rightPanel === 'layers' && blockInPreview && blockInPreview.blocksLayout && (
                      <LayersPanel
                        layers={blockInPreview.blocksLayout}
                        blockInPreview={blockInPreview}
                        selectedBlock={selectedBlock}
                        setSelectedBlock={setSelectedBlock}
                        setSelectedBlockType={setSelectedBlockType}
                        dispatch={blockInPreviewDispatch}
                        setDeleteModalVisible={setDeleteModalVisible}
                        dockToRightSidebar={() => setRightPanelsView('sidebar')}
                        rightPanel={rightPanel}
                      />
                    )}
                    {rightPanel === 'layers' && !blockInPreview && qooInPreview.blocksLayout && (
                      <LayersPanel
                        layers={qooInPreview.blocksLayout}
                        blockInPreview={blockInPreview}
                        selectedBlock={selectedBlock}
                        setSelectedBlock={setSelectedBlock}
                        setSelectedBlockType={setSelectedBlockType}
                        dispatch={dispatch}
                        setDeleteModalVisible={setDeleteModalVisible}
                        dockToRightSidebar={() => setRightPanelsView('sidebar')}
                        rightPanel={rightPanel}
                      />
                    )}
                  </>
                )}
                {rightPanelsView === 'sidebar' && (
                  <div className='right-sidebar'>
                    <LayersPanel
                      layers={
                        blockInPreview ? blockInPreview.blocksLayout : qooInPreview.blocksLayout
                      }
                      blockInPreview={blockInPreview}
                      selectedBlock={selectedBlock}
                      setSelectedBlock={setSelectedBlock}
                      setSelectedBlockType={setSelectedBlockType}
                      dispatch={blockInPreview ? blockInPreviewDispatch : dispatch}
                      setDeleteModalVisible={setDeleteModalVisible}
                      inSidebar={true}
                      collapseSidebar={(panel) => {
                        setRightPanelsView('single');
                        setRightPanel(panel);
                      }}
                      rightPanel={rightPanel}
                    />
                    <EndnotesPanel
                      endnotes={
                        blockInPreview
                          ? [
                              ...blockInPreview.endnotes?.references.map(
                                (reference) => reference.item
                              ),
                              ...blockInPreview.endnotes?.footnotes.map(
                                (footnote) => footnote.item
                              ),
                              ...[
                                ...blockInPreview.endnotes.abbreviations.map(
                                  (abbreviation) => abbreviation.item
                                )
                              ].sort((a, b) => {
                                if (a.abbreviation < b.abbreviation) return -1;
                                if (a.abbreviation > b.abbreviation) return 1;
                                return 0;
                              })
                            ]
                          : [
                              ...allEndnotes.references,
                              ...allEndnotes.footnotes,
                              ...[...allEndnotes.abbreviations].sort((a, b) => {
                                if (a.abbreviation < b.abbreviation) return -1;
                                if (a.abbreviation > b.abbreviation) return 1;
                                return 0;
                              })
                            ]
                      }
                      inSidebar={true}
                      collapseSidebar={(panel) => {
                        setRightPanelsView('single');
                        setRightPanel(panel);
                      }}
                      rightPanel={rightPanel}
                      setHoveredEndnote={setHoveredEndnote}
                      removeCallback={(endnote) => setEndnoteToDelete(endnote)}
                      editCallback={(endnote) => setEndnoteForEdit(endnote)}
                    />
                    <PanelsPanel
                      inSidebar={true}
                      collapseSidebar={(panel) => {
                        setRightPanelsView('single');
                        setRightPanel(panel);
                      }}
                      rightPanel={rightPanel}
                    />
                  </div>
                )}

                {contextMenuCoords && (
                  <ContextMenu
                    contextMenuCoords={contextMenuCoords}
                    highlightedBlock={highlightedBlock}
                    ref={contextMenuRef}
                    setDeleteModalVisible={setDeleteModalVisible}
                    setContextMenuCoords={setContextMenuCoords}
                    blockInPreviewDispatch={blockInPreviewDispatch}
                    moveLayerUp={moveLayerUp}
                    moveLayerToFront={moveLayerToFront}
                    moveLayerDown={moveLayerDown}
                    moveLayerToBottom={moveLayerToBottom}
                  />
                )}
              </div>
            </Content>
          </>
        )}
        {deleteModalVisible && (
          <DeleteModal
            visible={deleteModalVisible}
            closeModal={() => setDeleteModalVisible(false)}
            title={`${highlightedBlock?.block.name}`}
            deleteCallback={() => {
              setDeleteModalVisible(false);

              if (blockInPreview) {
                blockInPreviewDispatch({
                  type: REMOVE_BLOCK_FROM_LAYOUT,
                  payload: { _id: selectedBlock }
                });
              } else {
                dispatch({
                  type: REMOVE_BLOCK,
                  payload: { _id: selectedBlock }
                });
              }
            }}
          />
        )}
        {(addingEndnote || endnoteForEdit) && (
          <EndnoteModal
            addingEndnote={addingEndnote}
            closeModal={() => {
              if (addingEndnote) {
                setAddingEndnote(null);
              }
              if (endnoteForEdit) {
                setEndnoteForEdit(null);
              }
            }}
            highlightedBlock={highlightedBlock}
            addEndnotes={(endnotes, endnoteType) => {
              if (blockInPreview) {
                blockInPreviewDispatch({
                  type: ADD_ENDNOTES,
                  payload: {
                    endnotes,
                    endnoteType,
                    blockId: highlightedBlock?.block._id
                  }
                });
              } else {
                dispatch({
                  type: ADD_ENDNOTES_Q,
                  payload: {
                    endnotes,
                    endnoteType,
                    blockId: highlightedBlock?.block._id
                  }
                });
              }

              setAddingEndnote(null);
            }}
            endnoteForEdit={endnoteForEdit}
            editCallback={(endnote, endnoteType) => {
              if (blockInPreview) {
                dispatch({
                  type: EDIT_ENDNOTE,
                  payload: {
                    endnote,
                    endnoteType
                  }
                });
              } else {
                dispatch({
                  type: EDIT_ENDNOTE_Q,
                  payload: {
                    endnote,
                    endnoteType
                  }
                });
              }
              setEndnoteForEdit(null);
            }}
          />
        )}
        {endnoteToDelete && (
          <DeleteEndnoteModal
            endnote={endnoteToDelete}
            closeModal={() => setEndnoteToDelete(null)}
            blockInPreview={blockInPreview}
            qooInPreview={blockInPreview ? null : qooInPreview}
            removeCallback={(endnoteId, elements, endnoteType) => {
              if (blockInPreview) {
                blockInPreviewDispatch({
                  type: REMOVE_ENDNOTE,
                  payload: {
                    endnoteId,
                    elements,
                    endnoteType
                  }
                });
              } else {
                dispatch({
                  type: REMOVE_ENDNOTE_Q,
                  payload: {
                    endnoteId,
                    elements,
                    endnoteType
                  }
                });
              }
              setEndnoteToDelete(null);
            }}
          />
        )}
      </Layout>
    </Layout>
  );
};

export default QooBuilder;
