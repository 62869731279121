import React from 'react';
import { ReactComponent as ListViewIcon } from '../../icons/list-view.svg';
import { ReactComponent as GridViewIcon } from '../../icons/grid-view.svg';

const CustomSwitch = ({ listView, setListView }) => {


  return (
    <div className='switch-container'>
      <button className={listView ? 'switch__btn switch__btn--active' : 'switch__btn'} onClick={() => setListView(true)}>
        <ListViewIcon />
      </button>
      <button className={listView ? 'switch__btn switch__btn' : 'switch__btn switch__btn--active'} onClick={() => setListView(false)}>
        <GridViewIcon />
      </button>
      {/* <span className='switch__label' onClick={() => setListView(false)}>Grid View</span>
      <label className="switch">
        <input
          type="checkbox"
          className='switch__checkbox'
          checked={listView}
          onChange={() => setListView(listView => !listView)}
        />
        <span className="switch__slider"></span>
      </label>
      <span className="switch__label" onClick={() => setListView(true)}>List View</span> */}
    </div>
  );
};

export default CustomSwitch;