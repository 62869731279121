import React from 'react';
import { Layout } from 'antd';

const { Header } = Layout;

const HeaderInner = ({ children }) => {
  return (
    <Header
      className='header-inner'
    >
      {children}
    </Header>
  );
};

export default HeaderInner;