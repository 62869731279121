import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../../App';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification, Layout, Input, Select, Modal } from 'antd';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import useAxios from '../../hooks/useAxios';
import MyAudioTable from '../../components/tables/MyAudioTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = ['_id', '__v'];

const MyAudio = ({ files, setStateForRefetchingAudioFiles }) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [searchFilter, setSearchFilter] = useState('');

  const tableData = files.data.filter((file) => {
    const textMatch = searchFilter ? file?.originalname?.toLowerCase().includes(searchFilter.toLowerCase()) : true;
    return textMatch;
  })

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/files/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration,
      });
      setStateForRefetchingAudioFiles(prev => prev + 1);
      // history.push('/admin');
      // history.push('/admin/library');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration,
      });
    }
  };

  const editDataHandler = async (id, updates) => {
    try {
      await Axios.put(`${SERVER_URL}/files/${id}`, updates, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item updated.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration,
      });
      history.push('/admin');
      history.push('/admin/library');
    } catch (err) {
      notification.error({
        message: 'Problem with update. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration,
      });
    }
  };

  let columnKeys;
  if (files.data && files.data.length > 0) {
    const keys = Object.keys(files.data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  return (
    <>
      <div className='table-header'>
        <Input
          className='table-header__search'
          placeholder='Search Audio'
          prefix={<SearchIcon />}
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
      </div>
      <MyAudioTable
        data={tableData}
        deleteHandler={deleteDataHandler}
        // editHandler={editDataHandler}
        // editPath='/admin/edit-block/'
        columnKeys={columnKeys}
        title='Audio'
      />
    </>
  )
}

export default MyAudio;