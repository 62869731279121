import { Form, InputNumber } from 'antd';
import FormActions from './components/FormActions';

export default function LockStudyForm({
  currentEvent,
  setCurrentEvent,
  blockEventHandler,
  eventIndex
}) {
  return (
    <Form onFinish={blockEventHandler}>
      <InputNumber
        className='block-event__element'
        min={0}
        max={3600}
        type='number'
        placeholder='Delay (in minutes)'
        value={currentEvent?.lockStudyDelay}
        onChange={(value) =>
          setCurrentEvent({
            ...currentEvent,
            lockStudyDelay: value
          })
        }
      />
      <FormActions eventIndex={eventIndex} setCurrentEvent={setCurrentEvent} />
    </Form>
  );
}
