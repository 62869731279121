import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useParams, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { Button, Form, Input, Modal, notification } from 'antd';
import { SERVER_URL } from '../../config';
import { CheckOutlined } from '@ant-design/icons';

const CookieOptInModal = () => {
  const { qoolectionToken } = useParams();
  const search = useLocation().search;
  const [cookies, setCookie] = useCookies('qoolection_cookie');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneVerificationCode, setPhoneVerificationCode] = useState('');
  const [deviceVerificationCode, setDeviceVerificationCode] = useState('');
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isDeviceVerified, setIsDeviceVerified] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [showPhoneInput, setShowPhoneInput] = useState(true);
  const [verificationSMSIsSent, setVerificationSMSIsSent] = useState(false);
  const [modalVisible, setSetModalVisible] = useState(false);
  const patientToken = new URLSearchParams(search).get('token') || null;

  useEffect(() => {
    if (qoolectionToken) checkForPatieentCookie();
    checkIfSubmitButtonIsDisabled();
  }, [qoolectionToken, submitDisabled, isPhoneVerified, verificationSMSIsSent]);

  const checkForPatieentCookie = async () => {
    if (!cookies?.qoolection_cookie) setSetModalVisible(true);
    else
      try {
        const res = await Axios.get(
          `${SERVER_URL}/published-qoolection-check-cookie/${qoolectionToken}/${cookies?.qoolection_cookie}`,
          { withCredentials: false }
        );
        const publishedUrl = await res?.data?.publishedUrl;
        if (!publishedUrl) setSetModalVisible(true);
      } catch (error) {
        console.log(error);
      }
  };

  const onFinish = async (values) => {
    try {
      const res = await Axios.post(
        `${SERVER_URL}/set-published-qoolection-cookie`,
        { ...values, qoolectionToken, patientToken },
        { withCredentials: false }
      );
      const cookie = (await res?.data?.cookie) || null;
      if (cookie) {
        setCookie('qoolection_cookie', cookie, {
          path: '/published-qoolection',
          maxAge: 31536000
        });
        setSetModalVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendVerificationSMS = async () => {
    try {
      const { data } = await Axios.post(
        `${SERVER_URL}/send-verification-sms`,
        { phoneNumber },
        { withCredentials: false }
      );
      setShowPhoneInput(false);
      setVerificationSMSIsSent(true);
      setShowPhoneInput(false);
      setVerificationSMSIsSent(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerifyPhoneNumber = async () => {
    try {
      const { data } = await Axios.post(
        `${SERVER_URL}/verify-phone-number`,
        { phoneNumber, phoneVerificationCode, patientToken },
        { withCredentials: false }
      );
      if (data.isValid) {
        setIsPhoneVerified(true);
        setVerificationSMSIsSent(false);
        setVerificationSMSIsSent(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerifyDevice = async () => {
    try {
      const { data } = await Axios.post(
        `${SERVER_URL}/verify-device`,
        { deviceVerificationCode, patientToken },
        { withCredentials: false }
      );
      if (data.isValid) {
        setIsDeviceVerified(true);
      } else {
        notification.error({
          position: 'center',
          message: 'Error',
          description: 'Invalid verification code'
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkIfSubmitButtonIsDisabled = () => {
    isPhoneVerified ? setSubmitDisabled(false) : setSubmitDisabled(true);
    isDeviceVerified ? setSubmitDisabled(false) : setSubmitDisabled(true);
  };

  return (
    <Modal
      centered
      bodyStyle={{ position: 'relative' }}
      footer={null}
      closable={false}
      open={modalVisible}
      className="opt-in"
      width={390}>
      <div className="opt-in__logo-wrapper">
        <img className="opt-in__logo" src="/LogoQC.svg" alt="QClinical Logo" />
      </div>
      <div className="opt-in__body">
        <h3 className="opt-in__title">Welcome to the StudyName Clinical Trial</h3>
        <p className="opt-in__paragraph opt-in__text">
          Using QClinical, companies can create private websites intended for clinical trial
          participants. By entering QClinical, you are confirming that you are a participant in the
          StudyName Clinical Trial and that you have read and understood this disclaimer.
        </p>
        <p className="opt-in__paragraph opt-in__text">
          As part of your participation, please enter your mobile phone number below to receive
          clinical trial notifications, including questionnaire notifications and clinical trial
          news. This is a requirement for participating in this Clinical Trial.
        </p>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          style={{ marginTop: '40px' }}>
          {/* PHONE RELATED FIELDS */}
          {showPhoneInput && (
            <div className="opt-in__form-group">
              <Form.Item
                name="phone"
                rules={[{ required: true, message: 'Please enter a phone number' }]}
                className="opt-in__text opt-in__form-item">
                <Input placeholder="+1" onChange={(e) => setPhoneNumber(e.target.value)} />
              </Form.Item>
              <Button
                className="opt-in__button"
                onClick={handleSendVerificationSMS}
                disabled={phoneNumber && phoneNumber.length > 8 ? false : true}>
                Send code
              </Button>
            </div>
          )}
          {verificationSMSIsSent && (
            <div className="opt-in__form-group">
              <Input
                type="text"
                placeholder="Enter verification code"
                value={phoneVerificationCode}
                onChange={(e) => setPhoneVerificationCode(e.target.value)}
                className="opt-in__text opt-in__form-item"
              />
              <Button
                className="opt-in__button"
                onClick={handleVerifyPhoneNumber}
                disabled={phoneVerificationCode.length !== 6}>
                Verify
              </Button>
            </div>
          )}
          {isPhoneVerified && (
            <div className="opt-in__text opt-in__text--verification opt-in__text opt-in__form-item">
              Phone is successfully verified! <CheckOutlined style={{ color: '#4962E2' }} />
            </div>
          )}
          <div className="opt-in__text opt-in__text--small">
            By entering your phone number, you agree to receive text messages from QClinical.
            Message frequency varies. Message and data rates may apply.
          </div>

          {/* DEVICE RELATED FIELDS */}
          {!isDeviceVerified && (
            <div className="opt-in__form-group">
              <Input
                type="text"
                placeholder="Enter kit number code"
                value={deviceVerificationCode}
                onChange={(e) => setDeviceVerificationCode(e.target.value)}
              />
              <Button
                className="opt-in__button"
                onClick={handleVerifyDevice}
                disabled={deviceVerificationCode < 5}>
                Verify
              </Button>
            </div>
          )}
          {isDeviceVerified && (
            <div className="opt-in__text opt-in__text--verification">
              Device is successfully verified! <CheckOutlined style={{ color: '#4962E2' }} />
            </div>
          )}

          {/* SUBMIT */}
          <Button
            block
            disabled={isPhoneVerified && isDeviceVerified ? false : true}
            type="primary"
            htmlType="submit"
            style={{ marginTop: '20px' }}>
            Enter QClinical
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

export default CookieOptInModal;
