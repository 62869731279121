import React from 'react';
import { Checkbox } from 'antd';

const FormTemplate = ({ template }) => {
  const formFieldStyles = {
    width: '100%',
    fontSize: `${146 / 570 * 16}px`,
    height: '2em',
    border:
      template.formFieldBorderThickness &&
        template.formFieldBorderType &&
        template.formFieldBorderColor
        ? `${template.formFieldBorderThickness}em ${template.formFieldBorderType} ${template.formFieldBorderColor.hex}`
        : '1px solid #D5DFE8',
    backgroundColor: template.formFieldBackgroundColor?.hex,
    pointerEvents: 'none'
  };

  return (
    <div
      style={{
        fontSize: `${146 / 570 * 16}px`,
        width: '100%',
        paddingTop: template.formPaddingTop * (146 / 570),
        paddingBottom: template.formPaddingBottom * (146 / 570),
        paddingLeft: template.formPaddingLeft * (146 / 570),
        paddingRight: template.formPaddingRight * (146 / 570),
        backgroundColor: template.formBackgroundColor?.hex,
        overflow: 'hidden',
        border:
          template.formBorderThickness &&
            template.formBorderType &&
            template.formBorderColor
            ? `${template.formBorderThickness}em ${template.formBorderType} ${template.formBorderColor.hex}`
            : 'none',
      }}
    >
      {template.formFields && template.formFields.length > 0 && template.formFields.map((formField, index) => {
        switch (formField.fieldElement) {
          case 'text':
            return (
              <div
                key={index}
                style={{
                  marginBottom: `${146 / 570 * 16}px`
                }}
              >
                <p>{formField.fieldName}</p>
                <input
                  type='text'
                  className='preview-input'
                  style={formFieldStyles}
                />
              </div>
            );
          case 'email':
            return (
              <div
                key={index}
                style={{
                  marginBottom: `${146 / 570 * 16}px`
                }}
              >
                <p>{formField.fieldName}</p>
                <input
                  type='email'
                  className='preview-input'
                  style={formFieldStyles}
                />
              </div>
            );
          case 'textarea':
            return (
              <div
                key={index}
                style={{
                  marginBottom: `${146 / 570 * 16}px`
                }}
              >
                <p>{formField.fieldName}</p>
                <textarea
                  className='preview-input'
                  style={{
                    ...formFieldStyles,
                    height: '5em',
                    resize: 'none'
                  }}
                />
              </div>
            );

          case 'checkbox':
            return (
              <div
                key={index}
                style={{
                  marginBottom: `${146 / 570 * 16}px`
                }}
              >
                <Checkbox
                  style={{
                    transform: `scale(${146 / 570})`,
                    transformOrigin: 'left'
                  }}
                >
                  {formField.fieldName}
                </Checkbox>
              </div>
            );
        }
      })}

      <div
        style={{
          display: 'flex',
          justifyContent: template.formButtonPosition ? template.formButtonPosition : ''
        }}
      >
        <button
          type='submit'
          style={{
            flexGrow: template.formButtonPosition ? '0' : '1',
            backgroundColor: template.formButtonBackgroundColor?.hex,
            color: 'white',
            height: `${146 / 570 * 32}px`,
            padding: `0 ${146 / 570 * 30}px`,
            border: template.formButtonBorderThickness &&
              template.formButtonBorderType &&
              template.formButtonBorderColor
              ? `${template.formButtonBorderThickness}em ${template.formButtonBorderType} ${template.formButtonBorderColor.hex}`
              : 'none',
            borderRadius: template.formButtonBorderRadius,
            boxShadow: template.formButtonHasShadow
              ? `${template.formButtonInnerShadow ? 'inset' : ''} 0em 0.3em 0.5em rgba(0,0,0,0.3)`
              : ''
          }}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default FormTemplate;