import { Modal } from 'antd';
import React, { useState } from 'react';
import { ReactComponent as LeftIcon } from '../../icons/ph_arrow-left-light.svg';
import { ReactComponent as RightIcon } from '../../icons/ph_arrow-right-light.svg';
import QooDisplay from '../qoos/QooDisplay';

const QoolectionPhonePreviewModal = ({ visible, closeModal, qoolection }) => {
  const [qooIndex, setQooIndex] = useState(0);

  return (
    <Modal
      visible={visible}
      centered
      closable
      onCancel={closeModal}
      footer={null}
      bodyStyle={{ padding: '0' }}
      destroyOnClose={true}
      width={612}>
      <div className="qoolection-preview-modal">
        <h3 className="qoolection-preview-modal__title">{qoolection.title}</h3>
        <div className="theme-preview">
          <div className="theme-preview__phone-container">
            <div className="theme-preview__phone">
              <div className="theme-preview__top-bar"></div>
              <div className="theme-preview__black-bg"></div>
              <div className="theme-preview__qoo-content">
                {qoolection?.qoos?.length > 0 ? (
                  <QooDisplay qooInPreview={qoolection?.qoos[qooIndex]} width={308} height={585} />
                ) : (
                  <div className="qoolection-preview-modal__blank-bg"></div>
                )}
              </div>
            </div>
          </div>
        </div>

        <LeftIcon
          className={
            qooIndex > 0
              ? 'qoolection-preview-modal__left-icon'
              : 'qoolection-preview-modal__left-icon qoolection-preview-modal__left-icon--disabled'
          }
          onClick={() => {
            if (qooIndex > 0) {
              setQooIndex((previous) => previous - 1);
            }
          }}
        />
        <RightIcon
          className={
            qooIndex < qoolection?.qoos?.length - 1
              ? 'qoolection-preview-modal__right-icon'
              : 'qoolection-preview-modal__right-icon qoolection-preview-modal__right-icon--disabled'
          }
          onClick={() => {
            if (qooIndex < qoolection?.qoos?.length - 1) {
              setQooIndex((previous) => previous + 1);
            }
          }}
        />
      </div>
    </Modal>
  );
};

export default QoolectionPhonePreviewModal;
