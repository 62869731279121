import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Input, Button, Card, notification } from 'antd';
import { SERVER_URL } from '../config';
import Axios from 'axios';
import { validatePasswordStrength } from '../utils/common';
import { ReactComponent as Check } from '../icons/ph_check-circle-light.svg';
import AuthMessage from '../components/elements/AuthMessage';
import Footer from '../components/base/Footer';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};
const tailLayout = {
  wrapperCol: { span: 24 }
};

const ResetPassword = (props) => {
  // const [verifyMessage, setVerifyMessage] = useState(false);
  const [passwords, setPasswords] = useState({
    old: '',
    new: '',
    confirmNew: ''
  });
  const [successed, setSuccessed] = useState(false);
  const [resetPasswordMessage, setResetPasswordMessage] = useState('');

  const history = useHistory();

  useEffect(() => {
    const checkForVerification = window.location.href.split('/');
    if (
      checkForVerification &&
      checkForVerification[checkForVerification.length - 1].includes('email-verified')
    ) {
      // setVerifyMessage('Email verified, please change your password.');
      setResetPasswordMessage({
        message: 'Email verified, please change your password.',
        type: 'success'
      });
    }
  }, []);

  //const [token] = window.location.pathname.split('/').splice(-1);
  //console.log(token);
  const { token } = useParams();

  const onFinish = async (values) => {
    try {
      await Axios.post(`${SERVER_URL}/password-reset/${token}`, values);
      /*
      notification.success({ message: 'Password is reseted succesfully', position: 'topRight' });
      setTimeout(() => {
        window.location.href = '/login';
      }, 2000);
      */
      setSuccessed(true);
    } catch (error) {
      console.log(error);
      setResetPasswordMessage({
        message: error.message,
        type: 'error'
      });
      // notification.error({ message: error.message, position: 'topRight' });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const checkPasswords = () => {
    // Check passwords length
    if (validatePasswordStrength(passwords.new) && validatePasswordStrength(passwords.confirmNew)) {
      // Compare new and confirmed
      if (passwords.new === passwords.confirmNew) return true;
    }
    return false;
  };

  return (
    <div className="log">
      <div className="card-wrapper">
        <img className="login-logo" src="/LogoQC.svg" alt="QClinical Logo" />
        <Card title="Reset password" className="login-header" bordered={false}>
          {resetPasswordMessage && <AuthMessage content={resetPasswordMessage} />}
          <p>Enter a new password below to change your password.</p>
          {!successed && (
            <Form {...layout} name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Form.Item
                name="newPassword"
                rules={[
                  { required: true, message: 'Please enter your old password!' },
                  ({ getFieldValue }) => ({
                    validator: (_, value) => {
                      // Test if password is at least 12 characters long, contains one Upper case letter, one lower case letter, one number, and one special character
                      if (getFieldValue('oldPassword') !== value) {
                        if (validatePasswordStrength(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            'The password must contain at least 12 characters, at least one uppercase letter, and one number.'
                          );
                        }
                      } else {
                        return Promise.reject('A new password needs to be different from old');
                      }
                    }
                  })
                ]}>
                <Input.Password
                  placeholder="New Password"
                  iconRender={(visible) => (
                    <img src="/Eyelashes.svg" alt="eye" style={{ cursor: 'pointer' }} />
                  )}
                  onChange={(e) => {
                    let p = passwords;
                    setPasswords({
                      ...p,
                      new: e.target.value
                    });
                  }}
                />
              </Form.Item>

              <Form.Item
                name="confirmNewPassword"
                dependencies={['newPassword']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('The two passwords that you entered do not match!')
                      );
                    }
                  })
                ]}>
                <Input.Password
                  placeholder="Confirm New Password"
                  iconRender={(visible) => (
                    <img src="/Eyelashes.svg" alt="eye" style={{ cursor: 'pointer' }} />
                  )}
                  onChange={(e) => {
                    let p = passwords;
                    setPasswords({
                      ...p,
                      confirmNew: e.target.value
                    });
                  }}
                />
              </Form.Item>

              <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
                <Button
                  className="login-button"
                  type="primary"
                  htmlType="submit"
                  disabled={!checkPasswords()}>
                  RESET PASSWORD
                </Button>
              </Form.Item>
            </Form>
          )}

          {successed && (
            <>
              <div className="login-notification pull-start extended-margin-bottom extended-margin-top">
                {/* <Check /> <span>Your Password has been reset.</span> */}
                <AuthMessage
                  content={{ message: 'Your Password has been reset.', type: 'success' }}
                />
              </div>
              <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
                <Button
                  className="login-button"
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    window.location.href = '/login';
                  }}>
                  Go to Login
                </Button>
              </Form.Item>
            </>
          )}
        </Card>
        {/* {verifyMessage && <p style={{ marginTop: '20px' }}>{verifyMessage}</p>} */}
      </div>

      <Footer />
    </div>
  );
};
export default ResetPassword;
