import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Button, Layout, notification, Popconfirm, Modal, Input, Form } from 'antd';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import themeInPreviewReducer from '../../reducers/theme-in-preview/themeInPreviewReducer';
import { SET_THEME } from '../../reducers/theme-in-preview/actionTypes';
import HeaderComponent from '../../components/base/HeaderComponent';
import HeaderInner from '../../components/elements/HeaderInner';
import { ReactComponent as Save } from '../../icons/save.svg';
import { ReactComponent as Close } from '../../icons/close.svg';
import { ReactComponent as BucketIcon } from '../../icons/ph_paint-bucket-light.svg';
import { ReactComponent as MenuIcon } from '../../icons/ph_list-light.svg';
import { ReactComponent as FooterIcon } from '../../icons/ph_device-mobile-footer.svg';
import { ReactComponent as ISIIcon } from '../../icons/ph_info-light.svg';
import { ReactComponent as HeaderIcon } from '../../icons/ph_device-mobile-header.svg';
import headerFooterReducer from '../../reducers/header-footer/headerFooterReducer';
import { initHeaderFooter } from './header-footerPresets';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import NewThemeBuilder from './NewThemeBuilder';
import ThemeEditor from './ThemeEditor';
import ThemePreview from './ThemePreview';

const { Content } = Layout;

const EditTheme = () => {
  const history = useHistory();
  const { themeId } = useParams();
  const currentuser = useContext(UserContext);
  const isNew = themeId ? false : true;
  const [nameDescriptionForm] = Form.useForm();

  const [selectedCategory, setSelectedCategory] = useState('brand-kit');
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [theme, fetchTheme] = useAxios('', {}, currentuser.data.token, 'get');
  const [step, setStep] = useState(1);

  const initState = {
    themeInPreview: {
      name: '',
      description: '',
      colorPalette: {
        colors: [],
        colorsBlackWhiteGrey: [
          {
            hex: '#ffffff',
            rgb: { r: 255, g: 255, b: 255, a: 1 }
          },
          {
            hex: '#F4F6F7',
            rgb: { r: 244, g: 246, b: 247, a: 1 }
          },
          {
            hex: '#BDC7CF',
            rgb: { r: 189, g: 199, b: 207, a: 1 }
          },
          {
            hex: '#90989E',
            rgb: { r: 144, g: 152, b: 158, a: 1 }
          },
          {
            hex: '#2A3A49',
            rgb: { r: 42, g: 58, b: 73, a: 1 }
          },
          {
            hex: '#000000',
            rgb: { r: 0, g: 0, b: 0, a: 1 }
          }
        ]
      },
      colorAssignment: {
        qooBackground: null,
        blockBackground: null,
        buttonColor: null,
        buttonBorderColor: null,
        buttonTextColor: null,
        fontColor: null
      },
      navigation: {
        type: null,
        style: null,
        position: null,
        color: null
      },
      selectedFonts: [],
      headerFooter: initHeaderFooter
    },
    themeInPreviewHistory: [],
    themeInPreviewFuture: []
  };

  const [state, dispatch] = useReducer(themeInPreviewReducer, initState);
  const { themeInPreview, themeInPreviewHistory, themeInPreviewFuture } = state;

  const [headerFooter, headerFooterDispatch] = useReducer(headerFooterReducer, initHeaderFooter);

  // useEffect(() => {
  //   currentuser.setIsNavMenuCollapsed(true);
  // }, []);

  useEffect(() => {
    if (themeId) {
      fetchTheme(`${SERVER_URL}/themes/${themeId}`, {});
    }
  }, [themeId, fetchTheme]);

  useEffect(() => {
    if (theme && theme.data && theme.data._id) {
      nameDescriptionForm.setFieldsValue({
        name: theme.data.name,
        description: theme.data.description
      });

      dispatch({
        type: SET_THEME,
        payload: theme.data
      });
    }
  }, [theme]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/themes` : `${SERVER_URL}/themes/${themeId}`;
    try {
      await Axios[method](
        route,
        { ...formData, team: currentuser.selectedTeam, study: currentuser.study },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      notification.success({
        message: `Theme ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });

      history.push('/admin/themes');
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  return (
    <Layout className="edit-theme-page">
      <HeaderComponent
        text={
          isNew ? 'Welcome to your QClinical Theme Builder' : <Link to="/admin/themes">Themes</Link>
        }
        additionalText={
          isNew
            ? null
            : selectedCategory !== 'ISI'
            ? selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)
            : 'Info Spotlight'
        }
      />
      <Content>
        <Layout className="inner-layout">
          <Layout>
            {!isNew && (
              <HeaderInner>
                <div className="edit-theme-header-container">
                  <div className="block-types-list">
                    <div
                      className={
                        selectedCategory === 'brand-kit'
                          ? 'block-types-list__btn block-types-list__btn--active'
                          : 'block-types-list__btn'
                      }
                      onClick={() => setSelectedCategory('brand-kit')}>
                      <div className="block-types-list__icon">
                        <BucketIcon />
                      </div>
                      <span className="block-types-list__name">Brand kit</span>
                    </div>
                    <div
                      className={
                        selectedCategory === 'header'
                          ? 'block-types-list__btn block-types-list__btn--active'
                          : 'block-types-list__btn'
                      }
                      onClick={() => setSelectedCategory('header')}>
                      <div className="block-types-list__icon">
                        <HeaderIcon />
                      </div>
                      <span className="block-types-list__name">Header</span>
                    </div>
                    <div
                      className={
                        selectedCategory === 'menu'
                          ? 'block-types-list__btn block-types-list__btn--active'
                          : 'block-types-list__btn'
                      }
                      onClick={() => setSelectedCategory('menu')}>
                      <div className="block-types-list__icon">
                        <MenuIcon />
                      </div>
                      <span className="block-types-list__name">Menu</span>
                    </div>
                    <div
                      className={
                        selectedCategory === 'footer'
                          ? 'block-types-list__btn block-types-list__btn--active'
                          : 'block-types-list__btn'
                      }
                      onClick={() => setSelectedCategory('footer')}>
                      <div className="block-types-list__icon">
                        <FooterIcon />
                      </div>
                      <span className="block-types-list__name">Footer</span>
                    </div>
                    <div
                      className={
                        selectedCategory === 'ISI'
                          ? 'block-types-list__btn block-types-list__btn--active'
                          : 'block-types-list__btn'
                      }
                      onClick={() => setSelectedCategory('ISI')}>
                      <div className="block-types-list__icon">
                        <ISIIcon />
                      </div>
                      <span className="block-types-list__name">Info spotlight</span>
                    </div>
                  </div>
                  <div className="edit-theme-header">
                    <Button
                      type="primary"
                      className="edit-theme-header__save-btn"
                      disabled={
                        themeInPreviewHistory.length < 1 ||
                        !themeInPreview.colorPalette.colors.length > 0 ||
                        !themeInPreview.selectedFonts.length > 0 ||
                        headerFooter.isEditing ||
                        !themeInPreview.headingFont ||
                        !themeInPreview.subheadingFont ||
                        !themeInPreview.bodyTextFont
                      }
                      onClick={() => setSaveModalVisible(true)}>
                      <span>Save Theme</span>
                      <Save />
                    </Button>
                    <Popconfirm
                      title="Are you sure you want to leave without saving? All chages will be lost!"
                      onConfirm={() => history.push('/admin/themes')}>
                      <div className="edit-theme-header__close-btn">
                        <Close />
                      </div>
                    </Popconfirm>
                  </div>
                </div>
              </HeaderInner>
            )}

            <Content className="edit-theme-content">
              {isNew && (
                <NewThemeBuilder
                  themeInPreview={themeInPreview}
                  dispatch={dispatch}
                  step={step}
                  setStep={setStep}
                  setSaveModalVisible={setSaveModalVisible}
                />
              )}
              {!isNew && (
                <ThemeEditor
                  themeInPreview={themeInPreview}
                  dispatch={dispatch}
                  selectedCategory={selectedCategory}
                />
              )}
              <ThemePreview
                themeInPreview={themeInPreview}
                showMenu={step === 6 || selectedCategory === 'menu'}
                showInfo={isNew}
              />

              <Modal
                style={{ margin: '0', right: 'calc(-100vw + 520px)', top: '80px' }}
                visible={saveModalVisible}
                mask={false}
                closable={false}
                onCancel={() => setSaveModalVisible(false)}
                onOk={() => {
                  nameDescriptionForm
                    .validateFields()
                    .then(() => {
                      const nameDesc = nameDescriptionForm.getFieldsValue(true);
                      onSubmit({ ...themeInPreview, ...nameDesc }, isNew);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}>
                <Form form={nameDescriptionForm} className="edit-theme-save-modal">
                  <Form.Item
                    label="Theme name"
                    rules={[{ required: true, message: 'Please enter name!' }]}
                    name="name"
                    labelAlign="left">
                    <Input className="edit-theme-save-modal__input" placeholder="Type here" />
                  </Form.Item>
                  <Form.Item label="Description" name="description" labelAlign="left">
                    <Input.TextArea
                      rows={5}
                      placeholder="Type here"
                      className="edit-theme-save-modal__input"
                    />
                  </Form.Item>
                </Form>
              </Modal>
            </Content>
          </Layout>
        </Layout>
      </Content>
    </Layout>
  );
};

export default EditTheme;
