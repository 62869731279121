import { Card, message, notification, Upload } from 'antd';
import { SERVER_URL } from '../../config';
import { notificationError } from '../../config/notificationOptions';
import { ReactComponent as UploadIcon } from '../../icons/upload-icon.svg';

const { Dragger } = Upload;

export default function MediaDragger({ setStateForRefetchingMedia, config }) {
  return (
    <div className="dashboard">
      <div className="card-wrapper">
        <Card title={`Add ${config.cardTitle}`} bordered={false}>
          <Dragger
            multiple
            action={`${SERVER_URL}/${config.uploadRoute}`}
            accept={config.accept}
            name={config.name}
            showUploadList={config.showUploadList}
            headers={config.headers}
            beforeUpload={async (file) => {
              const isAllowedFormat = config.allowedFormats.includes(file.type);
              if (!isAllowedFormat) {
                notification.error({
                  message: config.formatErrorMessage,
                  placement: 'bottomRight',
                  icon: notificationError.icon,
                  className: notificationError.className,
                  duration: notificationError.duration
                });
              }
              const isAllowedSize = file.size / 1024 / 1024 < config.maxAllowedSize;
              if (!isAllowedSize) {
                notification.error({
                  message: config.sizeErrorMessage,
                  placement: 'bottomRight',
                  icon: notificationError.icon,
                  className: notificationError.className,
                  duration: notificationError.duration
                });
              }
              return isAllowedFormat && isAllowedSize;
            }}
            onChange={({ file }) => {
              try {
                console.log('file: ', file);
                if (file.status === 'done') {
                  if (file.response && file.response.file) {
                    message.success(
                      `${file.response.file.originalname} file uploaded successfully.`
                    );
                    let uploaded = sessionStorage.getItem('uploaded');
                    if (uploaded) {
                      sessionStorage.setItem('uploaded', `${uploaded},${file.response.file._id}`);
                    } else {
                      sessionStorage.setItem('uploaded', file.response.file._id);
                    }
                  }
                  setStateForRefetchingMedia((previous) => previous + 1);
                } else if (file.status === 'error') {
                  message.error(`${file} file upload failed.`);
                }
              } catch (error) {
                message.error(`${file} file upload failed.`);
              }
            }}>
            <p className="ant-upload-drag-icon">
              <UploadIcon />
            </p>
            <p className="ant-upload-text">
              Drag and drop files here <br /> or
            </p>
            <div className="ant-upload-browse-btn__wrapper">
              <div className="ant-upload-browse-btn">Browse {config.browseLabel}</div>
            </div>
          </Dragger>
        </Card>
      </div>
    </div>
  );
}
