import React, { useEffect, useContext } from 'react';
import { Switch, Button, Select, Checkbox } from 'antd';
import { UPDATE_HEADERFOOTER_PROPERTY } from '../../reducers/theme-in-preview/actionTypes';
import { headerPresetsList } from './header-footerPresets';
import { icons } from '../../icons/menu-icons/menuIcons';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import ColorPickerDropdown from '../../components/elements/ColorPickerDropdown';
import Axios from 'axios';

const HeaderTab = ({ themeInPreview, dispatch }) => {
  const currentuser = useContext(UserContext);
  const token = currentuser.data.token;

  const [logos, fetchLogos] = useAxios('', [], token, 'get');
  const isSuperAdmin = currentuser.data.role.some((item) => item === 'superadmin');
  useEffect(() => {
    const filter = currentuser.data.role.includes('superadmin')
      ? { uploadedBy: currentuser.data.id }
      : { team: currentuser.selectedTeam, study: currentuser.study };
    if (isSuperAdmin || (!isSuperAdmin && currentuser.study)) {
      fetchLogos(`${SERVER_URL}/logos?filter=${JSON.stringify(filter)}`, []);
    }
  }, [fetchLogos, currentuser]);

  let headerPresets = [];
  if (
    themeInPreview.headerFooter.header.components.length > 0 &&
    !themeInPreview.headerFooter.header.noHeader
  ) {
    headerPresets = headerPresetsList.filter(
      (preset) =>
        themeInPreview.headerFooter.header.components.sort().join('') ===
        preset.keywords.split(' ').sort().join('')
    );
  }

  return (
    <>
      <div className="new-theme-builder__hf-section">
        <div className="new-theme-builder__hf-section-row">
          <p className="new-theme-builder__intro-text">
            Would you like to have a logo in your Header?
          </p>
          <Switch
            disabled={themeInPreview.headerFooter.header.noHeader}
            checked={themeInPreview.headerFooter.header.components.includes('logo')}
            onChange={(checked) => {
              const components = checked
                ? [...themeInPreview.headerFooter.header.components, 'logo']
                : themeInPreview.headerFooter.header.components.filter(
                    (component) => component !== 'logo'
                  );

              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'header',
                  updates: {
                    components
                  }
                }
              });
            }}
          />
        </div>
      </div>
      <div className="new-theme-builder__hf-section">
        <div className="new-theme-builder__hf-section-row">
          <p className="new-theme-builder__intro-text">
            Would you like to have a menu in your Header?
          </p>
          <Switch
            disabled={themeInPreview.headerFooter.header.noHeader}
            checked={themeInPreview.headerFooter.header.components.includes('menu')}
            onChange={(checked) => {
              const components = checked
                ? [...themeInPreview.headerFooter.header.components, 'menu']
                : themeInPreview.headerFooter.header.components.filter(
                    (component) => component !== 'menu'
                  );

              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'header',
                  updates: {
                    components
                  }
                }
              });
            }}
          />
        </div>
      </div>
      {!themeInPreview.headerFooter.header.noHeader && (
        <div className="new-theme-builder__hf-section">
          <p className="new-theme-builder__intro-text">Please select your Header layout:</p>
          <div className="new-theme-builder__hf-presets-list">
            {headerPresets &&
              headerPresets.length > 0 &&
              headerPresets.map((preset) => {
                return (
                  <div
                    key={preset.id}
                    className={
                      preset.id === themeInPreview.headerFooter.header.layout.id
                        ? 'new-theme-builder__hf-preset-container new-theme-builder__hf-preset-container--selected'
                        : 'new-theme-builder__hf-preset-container'
                    }
                    onClick={() => {
                      dispatch({
                        type: UPDATE_HEADERFOOTER_PROPERTY,
                        payload: {
                          section: 'header',
                          updates: {
                            layout: preset
                          }
                        }
                      });
                    }}>
                    <div>
                      {preset.topHeader && (
                        <div className="h-f-create-new__preset-top-header">Lorem Ipsum</div>
                      )}
                      <div
                        className="h-f-create-new__preset"
                        style={{
                          justifyContent: preset.justifyContent,
                          boxShadow: themeInPreview.headerFooter.header.dropshadow
                            ? '0px 4px 4px rgba(0, 0, 0, 0.1)'
                            : ''
                        }}>
                        {preset.slot1 && (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              gap: '12px',
                              flex: '1'
                            }}>
                            {preset.slot1.map((item) => {
                              if (item.type === 'logo') {
                                return (
                                  <h2 className="h-f-create-new__qoo-header-dummy-logo">LOGO</h2>
                                );
                              } else if (item.type === 'text') {
                                return <span>Text</span>;
                              } else if (item.type === 'menu') {
                                return icons[8];
                              }
                            })}
                          </div>
                        )}
                        {preset.slot2 && (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: '12px',
                              flex: '1'
                            }}>
                            {preset.slot2.map((item) => {
                              if (item.type === 'logo') {
                                return (
                                  <h2 className="h-f-create-new__qoo-header-dummy-logo">LOGO</h2>
                                );
                              } else if (item.type === 'text') {
                                return <span>Text</span>;
                              } else if (item.type === 'menu') {
                                return icons[8];
                              }
                            })}
                          </div>
                        )}
                        {preset.slot3 && (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                              gap: '12px',
                              flex: '1'
                            }}>
                            {preset.slot3.map((item) => {
                              if (item.type === 'logo') {
                                return (
                                  <h2 className="h-f-create-new__qoo-header-dummy-logo">LOGO</h2>
                                );
                              } else if (item.type === 'text') {
                                return <span>Text</span>;
                              } else if (item.type === 'menu') {
                                return icons[8];
                              }
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <p className="new-theme-builder__intro-text">Please style your Header:</p>
      <div className="new-theme-builder__hf-style-row">
        <div className="new-theme-builder__hf-style-label">
          <span className="new-theme-builder__small-text">Select Logo</span>
        </div>
        <div className="new-theme-builder__hf-toolbar">
          <Select
            className="dropdown-small new-theme-builder__hf-logo-select"
            disabled={
              !themeInPreview.headerFooter.header.components.includes('logo') ||
              themeInPreview.headerFooter.header.noHeader
            }
            placeholder="Choose logo"
            value={themeInPreview.headerFooter.header.logo}
            onChange={(value) => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'header',
                  updates: {
                    logo: value
                  }
                }
              });
            }}>
            {logos &&
              logos.data &&
              logos.data.length > 0 &&
              logos.data.map((logo) => {
                return (
                  <Select.Option value={logo.logoUrl}>
                    <div
                      style={{
                        height: '32px',
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                      <img src={logo.logoUrl} alt="log" style={{ height: '70%' }} />
                    </div>
                  </Select.Option>
                );
              })}
          </Select>
        </div>
      </div>
      <div className="new-theme-builder__hf-style-row">
        <div className="new-theme-builder__hf-style-label">
          <span className="new-theme-builder__small-text">Menu Icon</span>
        </div>
        <div className="new-theme-builder__hf-toolbar">
          <div className="new-theme-builder__hf-toolbar-group">
            <Select
              className="dropdown-small new-theme-builder__hf-menuicon-select"
              placeholder="Choose icon"
              disabled={
                !themeInPreview.headerFooter.header.components.includes('menu') ||
                themeInPreview.headerFooter.header.noHeader
              }
              value={themeInPreview.headerFooter.header.menuIcon}
              onChange={(value) => {
                dispatch({
                  type: UPDATE_HEADERFOOTER_PROPERTY,
                  payload: {
                    section: 'header',
                    updates: {
                      menuIcon: value
                    }
                  }
                });
              }}>
              {icons.map((icon, index) => {
                return (
                  <Select.Option value={index}>
                    <div
                      style={{
                        height: '32px',
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                      {icon}
                    </div>
                  </Select.Option>
                );
              })}
            </Select>
            <ColorPickerDropdown
              colors={themeInPreview.colorPalette.colors}
              colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
              selectedColor={themeInPreview.headerFooter.header.menuIconColor}
              disabled={
                !themeInPreview.headerFooter.header.components.includes('menu') ||
                themeInPreview.headerFooter.header.noHeader
              }
              rightRadius
              callback={(color) => {
                dispatch({
                  type: UPDATE_HEADERFOOTER_PROPERTY,
                  payload: {
                    section: 'header',
                    updates: {
                      menuIconColor: color
                    }
                  }
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="new-theme-builder__hf-style-row">
        <div className="new-theme-builder__hf-style-label">
          <span className="new-theme-builder__small-text">Header Background</span>
        </div>
        <div className="new-theme-builder__hf-toolbar">
          <ColorPickerDropdown
            colors={themeInPreview.colorPalette.colors}
            colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
            selectedColor={themeInPreview.headerFooter.header.backgroundColor}
            disabled={themeInPreview.headerFooter.header.noHeader}
            rightRadius
            callback={(color) => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'header',
                  updates: {
                    backgroundColor: color
                  }
                }
              });
            }}
          />
          <Checkbox
            disabled={themeInPreview.headerFooter.header.noHeader}
            checked={themeInPreview.headerFooter.header.dropshadow}
            onChange={() => {
              if (!themeInPreview.headerFooter.header.noHeader) {
                dispatch({
                  type: UPDATE_HEADERFOOTER_PROPERTY,
                  payload: {
                    section: 'header',
                    updates: {
                      dropshadow: !themeInPreview.headerFooter.header.dropshadow
                    }
                  }
                });
              }
            }}>
            Drop Shadow
          </Checkbox>
          {/* <div
            className={themeInPreview.headerFooter.header.noHeader ? "choice-btn choice-btn--sm  choice-btn--disabled" : themeInPreview.headerFooter.header.dropshadow ? "choice-btn choice-btn--sm  choice-btn--selected" : "choice-btn choice-btn--sm"}
            onClick={() => {
              if (!themeInPreview.headerFooter.header.noHeader) {
                dispatch({
                  type: UPDATE_HEADERFOOTER_PROPERTY,
                  payload: {
                    section: 'header',
                    updates: {
                      dropshadow: !themeInPreview.headerFooter.header.dropshadow
                    }
                  }
                });
              }
            }}
          >
            Drop Shadow
          </div> */}
        </div>
      </div>
    </>
  );
};

export default HeaderTab;
