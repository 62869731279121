import Images from '../pages/library/ImagesOld';
import ImagesNewContent from '../pages/library/ImagesNewContent';
import IconsNewContent from '../pages/library/IconsNewContent';
import Library from '../pages/library/Library';
import Blocks from '../pages/blocks/Blocks';
import EditBlock from '../pages/blocks/EditBlock';
import Home from '../pages/Home';
import Qoos from '../pages/qoos/Qoos';
import EditQoo from '../pages/qoos/EditQoo';
import Qoolections from '../pages/qoolections/Qoolections';
import EditQoolection from '../pages/qoolections/EditQoolection';
import Analyze from '../pages/analyze/Analyze';
import Themes from '../pages/themes/Themes';
import EditTheme from '../pages/themes/EditTheme';
import Groups from '../pages/groups/Groups';
import { PieChartOutlined } from '@ant-design/icons';
import { ReactComponent as Block } from '../icons/Block-icon.svg';
import { ReactComponent as Qoo } from '../icons/Qoo-icon.svg';
import { ReactComponent as Qoolection } from '../icons/Qoolection-icon.svg';
import { ReactComponent as HomeIcon } from '../icons/Home.svg';
import { ReactComponent as CreateIcon } from '../icons/Create.svg';
import { ReactComponent as ManageIcon } from '../icons/Manage.svg';
import { ReactComponent as ExploreIcon } from '../icons/Explore.svg';
import { ReactComponent as AnalyzeIcon } from '../icons/Analyze.svg';
import { ReactComponent as LibraryIcon } from '../icons/Library.svg';
import { ReactComponent as SetupIcon } from '../icons/Setup.svg';
import { ReactComponent as ThemesIcon } from '../icons/Themes.svg';
import { ReactComponent as GroupsIcon } from '../icons/Groups-icon.svg';

const routes = [
  {
    label: ' Home',
    icon: HomeIcon,
    path: '/admin',
    component: Home,
    showInMenu: ['superadmin', 'admin', 'creator', 'agency']
  },
  {
    label: ' Create',
    icon: CreateIcon,
    showInMenu: ['superadmin', 'creator', 'monitor', 'agency'],
    children: [
      {
        label: ' Block',
        icon: <Block />,
        path: '/admin/new-block',
        component: EditBlock,
        allowed: ['superadmin', 'creator', 'agency'],
        showInMenu: true
      },
      {
        label: ' Qoo',
        icon: <Qoo />,
        path: '/admin/new-qoo',
        component: EditQoo,
        allowed: ['superadmin', 'creator', 'monitor', 'agency'],
        showInMenu: true
      },
      {
        label: ' Qoolection',
        icon: <Qoolection />,
        path: '/admin/new-qoolection',
        component: EditQoolection,
        allowed: ['superadmin', 'creator', 'monitor', 'agency'],
        showInMenu: true
      }
    ]
  },
  {
    label: ' Manage',
    icon: ManageIcon,
    showInMenu: ['superadmin', 'creator', 'monitor', 'agency'],
    children: [
      {
        label: ' Blocks',
        icon: <Block />,
        path: '/admin/blocks',
        component: Blocks,
        allowed: ['superadmin', 'creator', 'agency'],
        showInMenu: true
      },
      {
        label: ' Qoos',
        icon: <Qoo />,
        path: '/admin/qoos',
        component: Qoos,
        allowed: ['superadmin', 'creator', 'monitor', 'agency'],
        showInMenu: true
      },
      {
        label: ' Qoolections',
        icon: <Qoolection />,
        path: '/admin/qoolections',
        component: Qoolections,
        allowed: ['superadmin', 'creator', 'monitor', 'agency'],
        showInMenu: true
      },
      {
        label: 'Edit Block',
        path: '/admin/edit-block/:blockId',
        component: EditBlock,
        allowed: ['superadmin', 'creator', 'agency'],
        showInMenu: false
      },
      {
        label: 'Edit Qoo',
        path: '/admin/edit-qoo/:qooId',
        component: EditQoo,
        allowed: ['superadmin', 'creator', 'agency'],
        showInMenu: false
      },
      {
        label: 'Edit Qoolection',
        icon: <Qoolection />,
        path: '/admin/edit-qoolection/:qoolectionId',
        component: EditQoolection,
        allowed: ['superadmin', 'creator', 'agency'],
        showInMenu: false
      }
    ]
  },
  // {
  //   label: " Analyze",
  //   icon: AnalyzeIcon,
  //   path: "/admin/analyze",
  //   component: Analyze,
  //   showInMenu: ["superadmin", "creator", "monitor", "agency"],
  // },
  {
    label: ' Library',
    icon: LibraryIcon,
    path: '/admin/library',
    component: Library,
    showInMenu: ['superadmin', 'creator', 'agency']
    // children: [
    //   {
    //     label: 'Images',
    //     path: '/admin/images',
    //     component: Images,
    //     allowed: ['superadmin', 'admin', 'creator', 'publisher', 'agency'],
    //     showInMenu: true,
    //   },
    //   {
    //     label: 'Add Images',
    //     path: '/admin/images/new-content',
    //     component: ImagesNewContent,
    //     allowed: ['superadmin', 'admin', 'creator', 'publisher', 'agency'],
    //     showInMenu: true,
    //   },
    //   {
    //     label: 'Add Icons',
    //     path: '/admin/icons/new-content',
    //     component: IconsNewContent,
    //     allowed: ['superadmin', 'admin', 'creator', 'publisher', 'agency'],
    //     showInMenu: true,
    //   },
    // ],
  },
  {
    label: ' Themes',
    icon: ThemesIcon,
    path: '/admin/themes',
    showInMenu: ['superadmin', 'creator', 'agency'],
    children: [
      {
        label: ' Themes',
        icon: <ThemesIcon />,
        path: '/admin/themes',
        component: Themes,
        allowed: ['superadmin', 'creator', 'agency'],
        showInMenu: false
      },
      {
        label: 'New Theme',
        path: '/admin/new-theme',
        component: EditTheme,
        allowed: ['superadmin', 'creator', 'agency'],
        showInMenu: false
      },
      {
        label: 'Edit Theme',
        path: '/admin/edit-theme/:themeId',
        component: EditTheme,
        allowed: ['superadmin', 'creator', 'agency'],
        showInMenu: false
      }
    ]
  }
];

export default routes;
