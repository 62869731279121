import React, { useEffect, useContext, useState } from 'react';
import { Layout, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import HeaderComponent from '../../components/base/HeaderComponent';
import ThemeCard from './ThemeCard';
import useAxios from '../../hooks/useAxios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { ReactComponent as Plus } from '../../icons/plus.svg';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';

const { Content } = Layout;

const Themes = () => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [themes, fetchThemes] = useAxios('', [], currentuser.data.token, 'get');
  let [activeThemes, setActiveThemes] = useState([]);
  const isSuperAdmin = currentuser.data.role.some(item => item === 'superadmin');

  useEffect(() => {
    const query = !isSuperAdmin ? `?team=${currentuser.selectedTeam}&study=${currentuser.study}` : '';
    if((!isSuperAdmin && currentuser.study) || isSuperAdmin){
      fetchThemes(`${SERVER_URL}/get-user-themes/${currentuser.data.id}${query}`, []);
    }
  }, [fetchThemes, currentuser]);

  useEffect(() => {
    Axios.get(`${SERVER_URL}/users/${currentuser.data.id}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }).then((resp) => {
      setActiveThemes(resp.data.activeThemes?.filter(item => item.study === currentuser.study && item.team === currentuser.selectedTeam) || []);
    })
  }, [currentuser]);
  
  const deleteTheme = async (id) => {
    setIsLoading(true);
    try {
      await Axios.delete(`${SERVER_URL}/themes/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setIsLoading(false);
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration,
      });
      history.push('/admin');
      history.push('/admin/themes');
    } catch (err) {
      setIsLoading(false);
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration,
      });
    }
  };

  const activateTheme = async (id) => {
    setIsLoading(true);
    try {
      const res = await Axios.put(`${SERVER_URL}/activate-theme/${currentuser.data.id}/${id}?team=${currentuser.selectedTeam}&study=${currentuser.study}`, {}, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setIsLoading(false);
      // notification.success({
      //   message: 'Theme activated.',
      //   placement: 'bottomRight',
      //   icon: notificationSuccess.icon,
      //   className: notificationSuccess.className,
      //   duration: notificationSuccess.duration,
      // });
      history.push('/admin');
      history.push('/admin/themes');

    } catch (err) {
      setIsLoading(false);
      notification.error({
        message: 'Problem with theme activation. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration,
      });
    }
  };
  // activeThemes = activeThemes.filter(item => item.study === currentuser.study && item.team === currentuser.selectedTeam);
  return (
    <Layout className='themes-page'>
      <HeaderComponent text='Themes' />
      <Content className='themes-page__content'>
        {isLoading &&
          <div className='loader-container'>
            <LoadingOutlined spin style={{ fontSize: '60px' }} />
          </div>
        }
        {!isLoading &&
          <div className='themes-list'>
            {themes &&
              themes.data &&
              themes.data.items &&
              themes.data.items.length > 0 &&
              themes.data.items.map((theme) => {
                return (
                  <ThemeCard
                    key={theme._id}
                    theme={theme}
                    activeThemes={activeThemes}
                    deleteTheme={deleteTheme}
                    activateTheme={activateTheme}
                    SERVER_URL={SERVER_URL}
                    token={currentuser.data.token}
                  />
                );
              })}
            <Link to='/admin/new-theme'>
              <div className='add-theme-btn'>
                <Plus />
                <span>Build a new theme</span>
              </div>
            </Link>
          </div>
        }

      </Content>
    </Layout>
  );
};

export default Themes;
