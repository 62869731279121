import React, { useState } from 'react';
import GridLayout from 'react-grid-layout';
import {
  EDIT_INNER_BLOCK_IN_LAYOUT,
  INNER_BLOCK_LAYOUT_CHANGE_WITH_RESIZE,
  INNER_BLOCK_LAYOUT_CHANGE_WITH_RESIZE_WITHOUT_HISTORY
} from '../../reducers/block-in-preview/actionTypes';
import { Editor } from '@tinymce/tinymce-react';
import FormElement from './FormElement';
import SurveyElement from './SurveyElement';
import MediaElement from './MediaElement';
import ShapeElement from './ShapeElement';
import TextElement from './TextElement';

const BlockInPreviewGrid = ({
  blockInPreview,
  blockType,
  dispatch,
  selectedBlock,
  setSelectedBlock,
  setSelectedBlockType,
  handleRightClick,
  hoveredEndnote,
  rowsNumber,
  rowHeight
}) => {
  const [selectedTextEditor, setSelectedTextEditor] = useState(null);
  const onLayoutChange = (layout, oldItem, newItem) => {
    const filteredLayout = layout.filter((item) => item.i !== blockInPreview._id);

    const minX = Math.min(...filteredLayout.map((item) => item.x));
    const maxX = Math.max(...filteredLayout.map((item) => item.x + item.w));
    const minY = Math.min(...filteredLayout.map((item) => item.y));
    const maxY = Math.max(...filteredLayout.map((item) => item.y + item.h));

    const element = {
      ...newItem,
      x: newItem.x - minX,
      y: newItem.y - minY
    };

    dispatch({
      type: INNER_BLOCK_LAYOUT_CHANGE_WITH_RESIZE,
      payload: {
        blockSize: [maxY - minY, maxX - minX],
        column: minX,
        row: minY,
        element,
        correctionX: blockInPreview.column - minX,
        correctionY: blockInPreview.row - minY
      }
    });
  };

  let innerLayout = [];
  if (blockInPreview.blocksLayout && blockInPreview.blocksLayout.length > 0) {
    innerLayout = blockInPreview.blocksLayout.map((item) => ({
      i: item.block._id,
      x: item.x + blockInPreview.column,
      y: item.y + blockInPreview.row,
      w: item.w,
      h: item.h
    }));

    innerLayout.push({
      i: blockInPreview._id,
      x: blockInPreview.column,
      y: blockInPreview.row,
      w: blockInPreview.size[1],
      h: blockInPreview.size[0],
      static: true
    });
  }

  let tinyMCEContentStyleString = '';

  if (blockInPreview.theme) {
    blockInPreview.theme.selectedFonts.forEach(
      (font) =>
        (tinyMCEContentStyleString += `
      @font-face {
        font-family: ${font.name};
        src: url(${font.importUrl});
        font-weight: ${font.weight};
        font-style: ${font.style};
      } 
    `)
    );
  }

  tinyMCEContentStyleString += 'body { color:#2A3A49; font-size: 1em;} p {margin-bottom: 0;}';

  let tinyMCEFontFormatsString = '';
  if (blockInPreview.theme) {
    blockInPreview.theme.selectedFonts.forEach(
      (font) =>
        (tinyMCEFontFormatsString += `${font.name}=${font.name.toLowerCase().replace(' -', '')}; `)
    );
  }

  const defaultFont = blockInPreview?.theme.selectedFonts.find(
    (font) => font._id === blockInPreview.theme.bodyTextFont
  );

  return (
    <GridLayout
      className="layout"
      layout={innerLayout}
      cols={12}
      rowHeight={rowHeight}
      width={12 * rowHeight}
      height={rowHeight * rowsNumber}
      margin={[0, 0]}
      style={{ position: 'absolute', top: '0', left: '0', height: `${rowHeight * rowsNumber}px` }}
      compactType={null}
      resizeHandles={['s', 'e']}
      onResizeStop={(layout, oldItem, newItem) => onLayoutChange(layout, oldItem, newItem)}
      onDragStop={(layout, oldItem, newItem) => onLayoutChange(layout, oldItem, newItem)}
      maxRows={rowsNumber}
      allowOverlap={true}
      preventCollision={true}
      isBounded={true}
      draggableHandle={selectedTextEditor ? '.inner-drag-handle' : null}>
      {blockInPreview?.blocksLayout?.length > 0 && (
        <div
          className={selectedBlock && selectedBlock === blockInPreview._id ? 'selected-canvas' : ''}
          key={blockInPreview._id}
          style={{
            fontSize: `${((rowHeight * 12) / 576) * 16}px`,
            paddingTop: blockInPreview.blockProperties.blockPaddingTop,
            paddingRight: blockInPreview.blockProperties.blockPaddingRight,
            paddingBottom: blockInPreview.blockProperties.blockPaddingBottom,
            paddingLeft: blockInPreview.blockProperties.blockPaddingLeft,
            position: 'relative',
            backgroundColor: blockInPreview.blockProperties.blockBackgroundImage
              ? ''
              : blockInPreview.blockProperties.blockBackgroundColor
              ? blockInPreview.blockProperties.blockBackgroundColor.hex
              : blockInPreview.theme.colorAssignment.blockBackground
              ? blockInPreview.theme.colorAssignment.blockBackground.hex
              : null,
            // backgroundImage: blockInPreview.blockProperties.blockBackgroundImage
            //   ? `url(${blockInPreview.blockProperties.blockBackgroundImage})`
            //   : blockInPreview.blockProperties.blockBackgroundColor && !blockInPreview.hidden ? '' :
            //     'linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%)',
            backgroundRepeat:
              (blockInPreview.blockProperties.blockBackgroundColor ||
                blockInPreview.blockProperties.blockBackgroundImage) &&
              !blockInPreview.hidden
                ? 'no-repeat'
                : 'unset',
            backgroundPosition:
              (blockInPreview.blockProperties.blockBackgroundColor ||
                blockInPreview.blockProperties.blockBackgroundImage) &&
              !blockInPreview.hidden
                ? 'center'
                : '0px 0px, 10px 10px',
            backgroundSize:
              (blockInPreview.blockProperties.blockBackgroundColor ||
                blockInPreview.blockProperties.blockBackgroundImage) &&
              !blockInPreview.hidden
                ? 'cover'
                : '20px 20px',
            border:
              selectedBlock && selectedBlock === blockInPreview._id
                ? '1px solid #4962E2'
                : blockInPreview.blockProperties.blockBorderType &&
                  blockInPreview.blockProperties.blockBorderColor &&
                  blockInPreview.blockProperties.blockBorderThickness
                ? `${blockInPreview.blockProperties.blockBorderThickness}em ${blockInPreview.blockProperties.blockBorderType} ${blockInPreview.blockProperties.blockBorderColor.hex}`
                : 'none',
            boxShadow:
              blockInPreview.blockProperties.blockBackgroundColor ||
              blockInPreview.blockProperties.blockBorderColor
                ? 'none'
                : '0px 0px 12px 0px rgba(0,0,0,0.2)'
          }}
          onClick={() => {
            setSelectedBlock(blockInPreview._id);
            setSelectedBlockType({ name: 'general' });
          }}></div>
      )}
      {blockInPreview.blocksLayout &&
        blockInPreview.blocksLayout.length > 0 &&
        blockInPreview.blocksLayout.map((item) => {
          return (
            <div
              key={item.block._id}
              className={
                selectedBlock && selectedBlock === item.block._id
                  ? 'qoo-preview__draggable-block selected-element'
                  : 'qoo-preview__draggable-block'
              }
              style={{
                display: item.block.hidden ? 'none' : 'block',
                paddingTop: item.block.blockProperties.textPaddingTop,
                paddingRight: item.block.blockProperties.textPaddingRight,
                paddingBottom: item.block.blockProperties.textPaddingBottom,
                paddingLeft: item.block.blockProperties.textPaddingLeft,
                position: 'relative',
                cursor: item.block.blockType.name === 'text' ? 'auto' : 'move',
                border:
                  selectedBlock && selectedBlock === item.block._id
                    ? '1px solid #4962E2'
                    : item.block.blockProperties.iconBorderType &&
                      item.block.blockProperties.iconBorderThickness &&
                      item.block.blockProperties.iconBorderColor
                    ? `${item.block.blockProperties.iconBorderThickness}em ${item.block.blockProperties.iconBorderType} ${item.block.blockProperties.iconBorderColor.hex}`
                    : 'none',

                backgroundColor: item.block.blockProperties.textBackgroundImage
                  ? ''
                  : item.block.blockProperties.iconBackgroundColor?.hex,
                // backgroundImage: item.block.blockProperties.textBackgroundImage ? `url(${item.block.blockProperties.textBackgroundImage})` : '',
                // backgroundRepeat: 'no-repeat',
                // backgroundPosition: 'center',
                // backgroundSize: 'cover',
                boxSizing: 'content-box',
                outline:
                  blockInPreview.endnotes?.references
                    ?.find((reference) => reference.item?._id === hoveredEndnote)
                    ?.elements?.includes(item.block._id) ||
                  blockInPreview.endnotes?.footnotes
                    ?.find((footnote) => footnote.item?._id === hoveredEndnote)
                    ?.elements.includes(item.block._id) ||
                  blockInPreview.endnotes?.abbreviations
                    ?.find((abbreviation) => abbreviation.item?._id === hoveredEndnote)
                    ?.elements.includes(item.block._id)
                    ? '2px solid #4CAF50'
                    : 'none'
              }}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedBlockType(item.block.blockType);
                setSelectedBlock(item.block._id);
              }}
              onContextMenu={(e) => {
                if (selectedTextEditor) return;

                setSelectedBlockType(item.block.blockType);
                setSelectedBlock(item.block._id);
                handleRightClick(e);
              }}
              // onDoubleClick={() => setSelectedBlock(item.block._id)}
            >
              {item.block.blockType.name === 'text' && (
                <TextElement
                  element={item.block}
                  selectedTextEditor={selectedTextEditor}
                  setSelectedTextEditor={setSelectedTextEditor}
                  theme={blockInPreview.theme}
                  defaultFont={defaultFont}
                  handleChange={(value) => {
                    dispatch({
                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                      payload: {
                        blockId: item.block._id,
                        updates: {
                          textContent: value
                        }
                      }
                    });
                  }}
                  tinyMCEContentStyleString={tinyMCEContentStyleString}
                  tinyMCEFontFormatsString={tinyMCEFontFormatsString}
                  handleOverflow={(increment) => {
                    const maxY = Math.max(
                      ...blockInPreview.blocksLayout.map((item) => item.y + item.h)
                    );
                    const bottomElements = blockInPreview.blocksLayout.filter(
                      (item) => item.y + item.h === maxY
                    );
                    let newBlockHeight;
                    if (
                      increment < 0 &&
                      bottomElements.length === 1 &&
                      bottomElements[0].block._id === item.block._id
                    ) {
                      newBlockHeight = blockInPreview.size[0] + increment;
                    } else if (item.y + item.h + increment <= blockInPreview.size[0]) {
                      newBlockHeight = blockInPreview.size[0];
                    } else {
                      newBlockHeight = blockInPreview.size[0] + increment;
                    }

                    // const newBlockHeight = item.y + item.h + increment <= blockInPreview.size[0] ? blockInPreview.size[0] : maxY
                    // const newBlockHeight = item.y + item.h + increment === blockInPreview.size[0] ? blockInPreview.size[0] : blockInPreview.size[0] + increment;
                    dispatch({
                      type: INNER_BLOCK_LAYOUT_CHANGE_WITH_RESIZE_WITHOUT_HISTORY,
                      payload: {
                        blockSize: [newBlockHeight, blockInPreview.size[1]],
                        column: blockInPreview.column,
                        row: blockInPreview.row,
                        element: {
                          i: item.block._id,
                          x: item.x,
                          y: item.y,
                          w: item.w,
                          h: item.h + increment
                        },
                        correctionX: 0,
                        correctionY: 0
                      }
                    });
                  }}
                  rowsNumber={item.h}
                  rowHeight={rowHeight}
                  qed={true}
                />
              )}
              {item.block.blockType.name === 'divider' && (
                <div className="divider-container">
                  <div
                    className="divider"
                    style={{
                      width: `${item.block.blockProperties.dividerLength}%`,
                      borderBottom:
                        item.block.blockProperties.dividerThickness &&
                        item.block.blockProperties.dividerType &&
                        item.block.blockProperties.dividerColor
                          ? `${item.block.blockProperties.dividerThickness}em ${item.block.blockProperties.dividerType} ${item.block.blockProperties.dividerColor.hex}`
                          : 'none'
                      // alignSelf: item.block.blockProperties.dividerAlignPosition === 'center' ? 'center' : (item.block.blockProperties.dividerAlignPosition === 'top' ? 'flex-start' : 'flex-end')
                    }}></div>
                </div>
              )}
              {item.block.blockType.name === 'button' && (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <div
                    style={{
                      fontSize: `${((rowHeight * 12) / 576) * 16}px`,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: `${item.block.blockProperties.buttonWidth || 75}%`,
                      height: `${item.block.blockProperties.buttonHeight || 75}%`,
                      backgroundColor: item.block.blockProperties.buttonBackgroundColor?.hex,
                      color: item.block.blockProperties.buttonTextColor,
                      border:
                        item.block.blockProperties.buttonBorderThickness &&
                        item.block.blockProperties.buttonBorderType &&
                        item.block.blockProperties.buttonBorderColor
                          ? `${item.block.blockProperties.buttonBorderThickness} ${item.block.blockProperties.buttonBorderType} ${item.block.blockProperties.buttonBorderColor.hex}`
                          : 'none',
                      borderRadius: item.block.blockProperties.buttonBorderRadius,
                      boxShadow: item.block.blockProperties.buttonHasShadow
                        ? `
                            ${item.block.blockProperties.buttonInnerShadow ? 'inset' : ''}
                            ${item.block.blockProperties.buttonShadowX} 
                            ${item.block.blockProperties.buttonShadowY}
                            ${item.block.blockProperties.buttonShadowBlur}
                            rgba(${item.block.blockProperties.buttonShadowColor.rgb.r}, ${
                            item.block.blockProperties.buttonShadowColor.rgb.g
                          }, ${item.block.blockProperties.buttonShadowColor.rgb.b}, ${
                            item.block.blockProperties.buttonShadowOpacity
                          })
                          `
                        : '',
                      cursor:
                        selectedTextEditor && selectedTextEditor === item.block._id
                          ? 'auto'
                          : 'move'
                    }}>
                    {selectedTextEditor && selectedTextEditor === item.block._id ? (
                      <Editor
                        init={{
                          inline: true,
                          // fixed_toolbar_container: '#testtt',
                          // height: `${item.block.size[0] * 95}`,
                          menubar: false,
                          plugins: [
                            'importcss code link charmap lists wordcount charmap emoticons paste lists advlist autoresize'
                          ],
                          toolbar1: 'fontselect fontsizeselect forecolor',
                          toolbar2:
                            'bold italic underline alignleft aligncenter alignright alignjustify numlist bullist',
                          // toolbar2: 'forecolor | bold italic underline | alignleft aligncenter alignright alignjustify | numlist bullist ',
                          // toolbar1: 'fontselect',
                          // toolbar2: 'fontsizeselect',
                          // toolbar3: 'undo redo',
                          // toolbar4: 'forecolor',
                          // toolbar5: 'bold italic underline',
                          // toolbar6: 'alignleft aligncenter alignright alignjustify',
                          // toolbar7: 'numlist bullist',
                          // plugins: 'autoresize',
                          min_height: 48,
                          autoresize_bottom_margin: 0,
                          // toolbar: false,
                          statusbar: false,
                          contextmenu: '',
                          content_style: tinyMCEContentStyleString,
                          font_formats: tinyMCEFontFormatsString
                            ? tinyMCEFontFormatsString
                            : 'Arial Black=arial black,avant garde; Courier New=courier new,courier; Lato Black=lato; Roboto=roboto;',
                          placeholder: 'Add text here',
                          quickbars_insert_toolbar: false,
                          // toolbar_mode: 'wrap',
                          fontsize_formats: 'small=0.75em normal=1em large=1.5em extra-large=2em',
                          color_map: [
                            ...blockInPreview.theme.colorPalette.colors.flatMap((color, index) => [
                              color.hex,
                              color.hex
                            ]),
                            ...blockInPreview.theme.colorPalette.colorsBlackWhiteGrey.flatMap(
                              (color, index) => [color.hex, color.hex]
                            )
                          ],
                          browser_spellcheck: true
                        }}
                        onInit={(event, editor) => {
                          editor.editorCommands.execCommand(
                            'FontName',
                            false,
                            defaultFont.name.toLowerCase().replace(' -', '')
                          );
                          editor.contentAreaContainer.style.fontSize = '1em';
                          editor.setContent(item.block.blockProperties.buttonText);
                          editor.focus();
                          editor.selection.select(editor.getBody(), true);
                          editor.selection.collapse(false);
                          editor.on('blur', (e) => {
                            e.stopImmediatePropagation();
                            setSelectedTextEditor(null);
                          });
                          editor.on('ExecCommand', (e) => {
                            if (e.command === 'mceApplyTextcolor') {
                              document.querySelector(
                                'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                              ).style.background = e.value;
                            }
                            if (e.command === 'mceRemoveTextcolor') {
                              document.querySelector(
                                'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                              ).style.background = '#2A3A49';
                            }
                          });
                          editor.formatter.formatChanged(
                            'forecolor',
                            function (isNew, args) {
                              if (args.node.style.color) {
                                document.querySelector(
                                  'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                                ).style.background = args.node.style.color;
                              } else {
                                document.querySelector(
                                  'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                                ).style.background = '#2A3A49';
                              }
                            },
                            true
                          );
                          editor.on('keydown', (e) => {
                            e.stopPropagation();
                          });
                        }}
                        apiKey="j1zjwgyada24ar7u2uwpe3dxyhx6pduzftls0ex2z129yg13"
                        value={item.block.blockProperties.buttonText}
                        onEditorChange={(value, editor) => {
                          dispatch({
                            type: EDIT_INNER_BLOCK_IN_LAYOUT,
                            payload: {
                              blockId: item.block._id,
                              updates: {
                                buttonText: value
                              }
                            }
                          });
                        }}
                        // onFocus={() => setSelectedBlock(item.block._id)}
                        // onBlur={() => setSelectedBlock(null)}
                        // value={blockInPreview ? blockInPreview.blockProperties.textContent : ''}
                        // onEditorChange={(value, editor) => handleEditorChange(value, editor)}
                      />
                    ) : (
                      <div
                        className="text-content-container"
                        dangerouslySetInnerHTML={{ __html: item.block.blockProperties.buttonText }}
                        onDoubleClick={() => setSelectedTextEditor(item.block._id)}></div>
                    )}
                  </div>
                </div>
              )}
              {item.block.blockType.name === 'icon' && (
                <div
                  style={{
                    height: '100%',
                    backgroundColor: item.block.blockProperties.iconColor
                      ? item.block.blockProperties.iconColor.hex
                      : '#2A3A49',
                    mask: `url(${item.block.blockProperties.iconSrc})`,
                    maskRepeat: 'no-repeat',
                    maskPosition: 'center center',
                    maskSize: `${item.block.blockProperties.iconSize}%`,
                    WebkitMask: `url(${item.block.blockProperties.iconSrc})`,
                    WebkitMaskRepeat: 'no-repeat',
                    WebkitMaskPosition: 'center center',
                    WebkitMaskSize: `${item.block.blockProperties.iconSize}%`,
                    position: 'relative',
                    zIndex: '-1'
                  }}></div>
              )}
              {item.block.blockType.name === 'form' && (
                <FormElement block={item.block} width={570} />
              )}
              {item.block.blockType.name === 'survey' && (
                <SurveyElement
                  block={item.block}
                  width={570}
                  selectedTextEditor={selectedTextEditor}
                  setSelectedTextEditor={setSelectedTextEditor}
                  tinyMCEContentStyleString={tinyMCEContentStyleString}
                  tinyMCEFontFormatsString={tinyMCEFontFormatsString}
                  theme={blockInPreview.theme}
                  defaultFont={defaultFont}
                  editCallback={(value) => {
                    dispatch({
                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                      payload: {
                        blockId: item.block._id,
                        updates: {
                          surveyQuestion: value
                        }
                      }
                    });
                  }}
                />
              )}
              {item.block.blockType.name === 'media' && (
                <MediaElement
                  block={item.block}
                  // width={570}
                  width={rowHeight * 12}
                />
              )}
              {item.block.blockType.name === 'shape' && (
                <ShapeElement
                  dispatch={dispatch}
                  block={item.block}
                  // width={570}
                  width={rowHeight * 12}
                />
              )}
            </div>
          );
        })}
    </GridLayout>
  );
};

export default BlockInPreviewGrid;
