import React, { useState, useContext } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Input, Select } from 'antd';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
// import StockImagesTable from '../../components/tables/StockImagesTable';
import StockImagesGrid from '../../components/grids/StockImagesGrid';
import CustomSwitch from '../../components/elements/CustomSwitch';
import { ReactComponent as FavoriteIcon } from '../../icons/favorite.svg';
import { ReactComponent as FavoriteFullIcon } from '../../icons/favorite-full.svg';
import { SERVER_URL } from '../../config';

const StockImages = () => {
  const [listView, setListView] = useState(true);
  const [pexelDataInfo, setPexelDataInfo] = useState({ isLoading: false });
  const currentuser = useContext(UserContext);
  const token = currentuser.data.token;
  const history = useHistory();
  const [mediaOrientation, setMediaOrientation] = useState();
  const [mediaApiSource, setMediaApiSource] = useState();
  // const [mediaTags, setMediaTags] = useState('allTags');
  const [mediaColors, setMediaColors] = useState([]);
  const [selectedMediaColor, setSelectedMediaColor] = useState();
  const [allImages, setAllImages] = useState([]);
  const initSearchTerm = 'medical doctor pharma medicine patient medical';
  const [searchTerm, setSearchTerm] = useState(initSearchTerm);
  // const mediaSourceLabel = 'Source';

  const getAllImages = async () => {
    const pexelRes = await Axios.get(
      `${SERVER_URL}/pexels_images_searched?searchString=${searchTerm}&orientation=${mediaOrientation}&color=${selectedMediaColor}`,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    // console.log('pexelRes: ', pexelRes);

    let mappedPexel = [];

    if (pexelRes && pexelRes.data && pexelRes.data.photos.length > 0) {
      mappedPexel = pexelRes.data.photos.map((image) => ({
        id: image.id,
        url: image.src.original,
        smallUrl: image.src.small,
        mediumUrl: image.src.medium,
        imageTitle: image.alt ? image.alt : '(no title)',
        format: image.src.original.includes('jpeg' || 'jpg') ? 'JPG' : 'PNG',
        orientation:
          image.width / image.height > 1
            ? 'Landscape'
            : image.width / image.height < 1
            ? 'Portrait'
            : image.width / image.height === 1
            ? 'Square'
            : '',
        width: image.width,
        height: image.height,
        color: image.avg_color
      }));
    }

    let colors = [];
    if (mappedPexel && mappedPexel.length > 0) {
      colors = mappedPexel.map((image) => image.color);
    }
    // console.log('colors: ', colors);
    setMediaColors(colors);
    setAllImages(mappedPexel);
    setPexelDataInfo({
      isLoading: false,
      next_page: pexelRes.data.next_page,
      total_results: pexelRes.data.total_results
    });
  };

  const fetchMorePexel = async () => {
    if (pexelDataInfo.isLoading) return;

    setPexelDataInfo((previous) => ({ ...previous, isLoading: true }));

    const queryString =
      pexelDataInfo && pexelDataInfo.next_page
        ? `?${pexelDataInfo.next_page.split('?')[1]}&searchString=${searchTerm}`
        : '?searchString=${searchTerm}';
    const pexelRes = await Axios.get(`${SERVER_URL}/pexels_images_searched${queryString}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` }
    });

    let mappedPexel = [];
    if (pexelRes && pexelRes.data && pexelRes.data.photos.length > 0) {
      mappedPexel = pexelRes.data.photos.map((image) => ({
        id: String(image.id),
        url: image.src.original,
        smallUrl: image.src.small,
        mediumUrl: image.src.medium,
        imageTitle: image.alt,
        format: image.src.original.includes('jpeg' || 'jpg') ? 'JPG' : 'PNG',
        orientation:
          image.width / image.height >= 1
            ? 'Landscape'
            : image.width / image.height <= 1
            ? 'Portrait'
            : image.width / image.height === 1
            ? 'Square'
            : '',
        width: image.width + 'px',
        height: image.height + 'px',
        color: image.avg_color
      }));
    }

    setAllImages((previous) => [...previous, ...mappedPexel]);
    setPexelDataInfo({
      isLoading: false,
      next_page: pexelRes.data.next_page,
      total_results: pexelRes.data.total_results
    });
  };

  const columns = [
    {
      title: 'Image Title',
      dataIndex: 'imageTitle'
    },
    {
      title: 'Format',
      dataIndex: 'format'
    },
    {
      title: 'Orientation',
      dataIndex: 'orientation'
    }
    // {
    //   title: "Tags",
    //   dataIndex: "tags"
    // },
  ];

  columns.unshift({
    title: '',
    dataIndex: 'favorite',
    width: '40px',
    render: (text, record) => {
      return record.favorite === true ? <FavoriteFullIcon /> : <FavoriteIcon />;
    }
  });

  if (!searchTerm) {
    setSearchTerm(initSearchTerm);
  }

  return (
    <>
      <div className="table-header">
        {/* <CustomSwitch listView={listView} setListView={setListView} /> */}
        <Input
          className="table-header__search"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          placeholder="Search Images"
          prefix={<SearchIcon />}
        />
        {/* <Select
          placeholder='Quick Search'
          className='dropdown-small table-header__select'
          value={searchTerm}
          onChange={(value) => setSearchTerm(value)}
        >
          <Select.Option value='medical doctor pharma medicine patient medical' className='fieldType'>
            Default
          </Select.Option>
          <Select.Option value='health' className='fieldType'>
            Health
          </Select.Option>
          <Select.Option value='people' className='fieldType'>
            People
          </Select.Option>
          <Select.Option value='products' className='fieldType'>
            Products
          </Select.Option>
          <Select.Option value='wellness' className='fieldType'>
            Wellness
          </Select.Option>
          <Select.Option value='foodanddrink' className='fieldType'>
            Food &amp; drink
          </Select.Option>
          <Select.Option value='industry' className='fieldType'>
            Industry
          </Select.Option>
          <Select.Option value='pharmacy' className='fieldType'>
            Pharmacy
          </Select.Option>
        </Select> */}
        <Select
          placeholder="Source"
          className="dropdown-small table-header__select"
          value={mediaApiSource}
          onChange={(value) => setMediaApiSource(value)}>
          <Select.Option value="qclinical" className="fieldType">
            QClinical
          </Select.Option>
          <Select.Option value="pexels" className="fieldType">
            Pexels
          </Select.Option>
          <Select.Option value="shutterstock" className="fieldType">
            Shutterstock
          </Select.Option>
          <Select.Option value="istock" className="fieldType">
            iStock
          </Select.Option>
          <Select.Option value="pixabay" className="fieldType">
            Pixabay
          </Select.Option>
        </Select>
        <Select
          placeholder="Orientation"
          className="dropdown-small table-header__select"
          value={mediaOrientation}
          onChange={(value) => setMediaOrientation(value)}
          allowClear>
          <Select.Option value="portrait" className="fieldType">
            Portrait
          </Select.Option>
          <Select.Option value="landscape" className="fieldType">
            Landscape
          </Select.Option>
          <Select.Option value="square" className="fieldType">
            Square
          </Select.Option>
        </Select>
        <Select
          placeholder="Colors"
          className="dropdown-small table-header__select"
          value={selectedMediaColor}
          onChange={(value) => setSelectedMediaColor(value)}
          allowClear>
          <Select.Option value="" className="fieldType">
            All Colors
          </Select.Option>
          <Select.Option value="blue" className="fieldType">
            Blue
          </Select.Option>
          <Select.Option value="green" className="fieldType">
            Green
          </Select.Option>
          <Select.Option value="yellow" className="fieldType">
            Yellow
          </Select.Option>
          <Select.Option value="red" className="fieldType">
            Red
          </Select.Option>
          <Select.Option value="orange" className="fieldType">
            Orange
          </Select.Option>
          <Select.Option value="gray" className="fieldType">
            Gray
          </Select.Option>
          <Select.Option value="white" className="fieldType">
            White
          </Select.Option>
          <Select.Option value="black" className="fieldType">
            Black
          </Select.Option>
          {/* {mediaColors && mediaColors.length > 0 && mediaColors?.map(color => {
            return (
              <Select.Option value={color} className='fieldType'>
                <div style={{ backgroundColor: color, width: '100%', height: '100%' }}></div>
              </Select.Option>
            )
          })

          } */}
        </Select>
      </div>
      {/* {listView === true &&
        <StockImagesTable
          allImages={allImages}
          getAllImages={getAllImages}
          columns={columns}
          title='Images'
          searchKeyword={searchTerm}
          initSearchTerm={initSearchTerm}
        />
      } */}
      {/* {listView === false && */}
      <StockImagesGrid
        allImages={allImages}
        getAllImages={getAllImages}
        searchKeyword={searchTerm}
        initSearchTerm={initSearchTerm}
        mediaOrientation={mediaOrientation}
        selectedMediaColor={selectedMediaColor}
        fetchMorePexel={fetchMorePexel}
        pexelDataInfo={pexelDataInfo}
      />
      {/* } */}
    </>
  );
};

export default StockImages;
