/* eslint-disable no-throw-literal */
import moment from 'moment';
import React from 'react';

const PreviewReference = ({
  formValues,
  source,
  setReference,
  isGroupAuthor,
  retractionText,
  articleIdType,
  docType,
}) => {
  let reference = '';
  const values = formValues;
  const authors = values.authors;
  const groupAuthors = values.groupAuthors;

  let authorString = '';

  const year = values.year ? String(values.year) : '';
  let title = values.title ? values.title : '';
  const sectionTitle = values.sectionTitle ? values.sectionTitle + '. ' : '';
  const publisher = values.publisher ? values.publisher + '.' : '';
  const pageNumbers = values.pageNumbers ? ':' + values.pageNumbers + '.' : '';
  const pmid = values.pmid
    ? `PubMed PMID: ${values.pmid}. `
    : values.pmid
      ? `PubMed PMID: ${values.pmid}. `
      : '';
  const doi = values.doi?.value
    ? 'doi:' + values.doi.value + '.'
    : values.doi
      ? 'doi:' + values.doi
      : '';
  const pii = values.pii ? 'pii: ' + values.pii + '.' : '';
  // const piiArticleId = values.pii ? 'pii: ' + values.pii + '.' : '';
  // const doi = 'doi';
  // const pmcid = 'pmcid';
  // const medium = values.medium ? '[' + values.medium + ']' : '';
  const language = values.language ? values.language + '.' : '';
  // const city = values.city ? values.city + '. ' : '';
  const company = values.company ? values.company + '. ' : '';
  // const publishDate = values.publishDate ? values.publishDate + ';' : '';
  const publishYear = values.publishDate
    ? values.publishDate.split(' ')[0] + ';'
    : values.publicationYear
      ? values.publicationYear
      : '';
  const publisherLocation = values.publisherLocation || '';
  const publisherName = values.publisherName ? values.publisherName + ';' : '';
  // const ePubDate = values.ePubDate ? 'Epub ' + values.ePubDate + '.' : '';

  // WEBSITE DATA
  const webpage = values.webpage ? values.webpage + '. ' : '';
  const url = values.url ? 'Available from ' + values.url : '';
  const datePublishedParts = values.datePublished
    ? moment(values.datePublished).format('ll').split(',')
    : '';
  const dateAccessedParts = values.dateAccessed
    ? moment(values.dateAccessed).format('ll').split(',')
    : '';
  const datePublished = datePublishedParts
    ? `${datePublishedParts[1] ? datePublishedParts[1] + ' ' : ''}${datePublishedParts[0] ? datePublishedParts[0] : ''
    }`
    : '';
  const dateAccessed = dateAccessedParts
    ? `(Acessed ${dateAccessedParts[1] ? dateAccessedParts[1] + ' ' : ''}${dateAccessedParts[0] ? dateAccessedParts[0] : ''
    }).`
    : '';
  // END OF WEBSITE DATA

  // PI & SMPC DATA
  let brandName = values.brandName ? values.brandName : '';
  if (values.registeredTrademark) {
    brandName += '® ';
  } else {
    brandName += ' ';
  }
  const country = values.country ? values.country + '. ' : '';
  const monthYear = values.monthYear
    ? moment(values.monthYear).format('MMMM YYYY') + '.'
    : '';
  // END OF PI & SMPC DATA

  // CONFERENCE PROCEEDING DATA
  const conferenceName = values.conferenceName
    ? values.conferenceName + '; '
    : '';
  const conferenceYear = values.conferenceYear
    ? values.conferenceYear + ';'
    : '';
  const conferenceLocation = values.conferenceLocation
    ? values.conferenceLocation + ','
    : '';
  // END OF CONFERENCE PROCEEDING DATA

  // JOURNAL ARTICLE DATA
  const articleSource = values.articleSource ? values.articleSource + '.' : '';
  const journalName = values.journalName ? values.journalName + '.' : '';
  const volume = values.volume ? values.volume : '';
  const issue = values.issue ? `(${values.issue})` : '';
  // const pmcid = values.pmcid?.value ? 'PubMed Central PMCID: ' + values.pmcid.value.split(' ')[1].slice(0, -1) + '.' : '';
  // END OF JOURNAL ARTICLE DATA

  // BOOK DATA
  const chapterTitle = values.chapterTitle
    ? 'Chapter: ' + values.chapterTitle + '.'
    : '';
  const bookEdition = values.bookEdition ? values.bookEdition + '.' : '';
  const bookshelfArticleId = values.bookshelfArticleId?.value
    ? 'Bookshelf ID: ' + values.bookshelfArticleId.value + '.'
    : values.bookshelfArticleId
      ? 'Bookshelf ID: ' + values.bookshelfArticleId
      : '';
  const isbn = values.isbn ? 'ISBN: ' + values.isbn : '';
  // END OF BOOK DATA

  switch (source) {
    case 'book':
    case 'journal article':
    case 'conference proceeding':
    case 'website':
    case 'other':
      try {
        authors?.forEach((author, index) => {
          const lastName = author?.lastName ? author?.lastName + ' ' : '';
          const initials = author?.initials ? author?.initials : '';
          const isEditor = author?.editor;

          if (authors.length <= 6) {
            if (index < 6 && index !== authors.length - 1) {
              authorString +=
                lastName + initials + (isEditor ? ' editor' : '') + ', ';
            } else if (index === authors.length - 1) {
              authorString +=
                lastName + initials + (isEditor ? ' editor' : '') + '. ';
              throw 'Break';
            }
          }
          if (authors.length > 6) {
            if (index < 3 && index !== authors.length - 1) {
              authorString +=
                lastName + initials + (isEditor ? ' editor' : '') + ', ';
            } else {
              //in case more than 6 authors
              authorString += 'et al. ';
              throw 'Break';
            }
          }
        });
      } catch (error) {
        if (error !== 'Break') throw error;
      }
      try {
        groupAuthors?.forEach((groupAuthor, index) => {
          const name = groupAuthor?.name ? groupAuthor?.name : '';
          if (groupAuthors.length > 0) {
            if (index < 6 && index !== groupAuthors.length - 1) {
              authorString += name + ', ';
            } else if (index === groupAuthors.length - 1) {
              name.endsWith('.')
                ? (authorString += name + ' ')
                : (authorString += name + '. ');
              throw 'Break';
            }
          }
        });
      } catch (error) {
        if (error !== 'Break') throw error;
      }
      break;
    default:
      break;
  }

  if (docType !== undefined) {
    if (docType === 'book' || source === 'book') {
      reference = `${authorString} ${chapterTitle} ${title} ${bookEdition} ${publisherLocation} ${publisherName} ${year}${volume}${issue}${pageNumbers} ${doi} ${pmid} ${bookshelfArticleId} ${isbn} ${sectionTitle} ${publisher}`;
    }
    if (docType !== 'book') {
      if (source === 'journal article') {
        if (articleIdType === 'pmid' || articleIdType === 'doi') {
          reference = `${authorString} ${title} ${journalName} ${year}${(volume || issue) && year ? ';' : ''
            }${volume}${issue}${pageNumbers} ${doi} ${pmid} ${language} ${retractionText ? retractionText + '.' : ''
            }`;
        }
        if (articleIdType === 'pii') {
          reference = `${authorString} ${title} ${articleSource} ${year}${(volume || issue) && year ? ';' : ''
            }${volume}${issue}${pageNumbers} ${pii} ${pmid} ${language} ${retractionText ? retractionText + '.' : ''
            }`;
        }
      }
      if (source === 'conference proceeding') {
        reference = `${authorString} ${title} ${conferenceName} ${conferenceYear} ${conferenceLocation} ${publishYear} ${publisherLocation}`;
      }
      if (source === 'pi' || source === 'smpc') {
        reference = `${brandName} ${title} ${company} ${country} ${monthYear}`;
      }
      if (source === 'data on file') {
        reference = `${title} ${company} ${year}`;
      }
      if (source === 'website') {
        reference = `${authorString} ${title} ${webpage} ${datePublished} ${dateAccessed} ${url}`;
      }
      if (source === 'other') {
        reference = values.customReference || '';
      }
    }
  }

  // console.log('authors: ', authors);
  // console.log('groupAuthors: ', groupAuthors);
  // console.log('authorString: ', authorString);

  // switch (source) {
  //   case 'book':
  //     reference = `${authorString} ${title} ${publisherLocation} ${publisherName} ${articleSource} ${publishYear}${volume}${issue}${pageNumbers} ${doi} ${ePubDate} ${pmid} ${bookshelfArticleId} ${sectionTitle} ${publisher}`;
  //     break;
  //   case 'journal article':
  //     if(articleIdType === 'pmid' || articleIdType === 'doi') {
  //       reference = `${authorString} ${title} ${articleSource} ${year}${(volume || issue) && year ? ';' : ''}${volume}${issue}${pageNumbers} ${doi} ${pmid} ${language} ${retractionText ? retractionText + '.' : ''}`;
  //     }
  //     if(articleIdType === 'pii') {
  //       reference = `${authorString} ${title} ${articleSource} ${year}${(volume || issue) && year ? ';' : ''}${volume}${issue}${pageNumbers} ${pii} ${pmid} ${language} ${retractionText ? retractionText + '.' : ''}`;
  //     }
  //     break;
  //   case 'conference proceeding':
  //     reference = `${authorString} ${title} ${conferenceName} ${year} ${city} ${country}`;
  //     break;
  //   case 'pi':
  //   case 'smpc':
  //     reference = `${brandName} ${title} ${company} ${country} ${monthYear}`;
  //     break;
  //   case 'data on file':
  //     reference = `${title} ${company} ${year}`;
  //     break;
  //   case 'website':
  //     reference = `${authorString} ${title} ${webpage} ${datePublished} ${dateAccessed} ${url}`;
  //     break;
  //   case 'other':
  //     reference = values.customReference || '';
  //     break;
  //   default:
  //     break;
  // }

  setReference(reference);

  // console.log('values: ', values);

  return <div dangerouslySetInnerHTML={{ __html: reference }} />;
};

export default PreviewReference;
