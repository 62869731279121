import { Button, Form, InputNumber, Select } from 'antd';
import FormActions from './components/FormActions';

export default function ChangeInitialQoolectionForm({
  currentEvent,
  setCurrentEvent,
  blockEventHandler,
  eventIndex,
  publishedQoolections
}) {
  const initialValues = {
    setInitialQooInQoolection:
      typeof currentEvent?.setInitialQoolection === 'object'
        ? currentEvent?.setInitialQoolection.title
        : currentEvent?.setInitialQoolection
  };

  return (
    <Form onFinish={blockEventHandler} initialValues={initialValues}>
      <Form.Item
        className='block-event__form-item'
        name='setInitialQooInQoolection'
        rules={[
          {
            required: true,
            message: 'Please select a Qoo!'
          }
        ]}>
        <Select
          className='block-event__element'
          placeholder='Select Qoolection...'
          value={
            currentEvent?.setInitialQoolection?._id
              ? currentEvent?.setInitialQoolection?._id
              : currentEvent?.setInitialQoolection
          }
          onChange={(value) => {
            setCurrentEvent({
              ...currentEvent,
              setInitialQoolection: value
            });
          }}>
          {publishedQoolections?.data?.items?.map((publishedQoolection) => (
            <Select.Option key={publishedQoolection?._id} value={publishedQoolection?._id}>
              {publishedQoolection?.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <InputNumber
        className='block-event__element'
        min={0}
        max={3600}
        type='number'
        placeholder='Initial Qoolection Change Delay (in minutes)'
        value={currentEvent?.setInitialQoolectionDelay}
        onChange={(value) =>
          setCurrentEvent({
            ...currentEvent,
            setInitialQoolectionDelay: value
          })
        }
      />
      <FormActions eventIndex={eventIndex} setCurrentEvent={setCurrentEvent} />
    </Form>
  );
}
