import React, { useContext, useRef, useState, useEffect } from 'react';
import Axios from 'axios';
import { Dropdown, Select, Avatar, Button } from 'antd';
// import { ReactComponent as Language } from '../../icons/language.svg';
// import { ReactComponent as Search } from '../../icons/search.svg';
// import { ReactComponent as Bell } from '../../icons/bell.svg';
// import { ReactComponent as Help } from '../../icons/question-circled.svg';
// import { ReactComponent as Settings } from '../../icons/settings.svg';
import { ReactComponent as LogoutIcon } from '../../icons/logout.svg';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';

const UserActions = () => {
  const user = useContext(UserContext);

  const initials = user.data.firstName.slice(0, 1) + user.data.lastName.slice(0, 1);

  const dropdownRef = useRef();
  const triggerRef = useRef();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    await Axios.get(`${SERVER_URL}/logout`, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${user.data.token}` }
    });
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  };

  const setUserSettings = async (study) => {
    try {
      await Axios.put(
        `${SERVER_URL}/user-settings/${user.data.id}`,
        {
          user: user.data.id,
          team: user.selectedTeam,
          study: study
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.data.token}` }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOutside = (e) => {
    if (triggerRef.current.contains(e.target)) return;

    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const menu = (
    <div className="user-actions__dropdown" ref={dropdownRef}>
      <div className="user-actions__dropdown-user">
        <Avatar
          size={60}
          className="user-actions__avatar"
          onClick={() => setDropdownVisible((previous) => !previous)}
          ref={triggerRef}>
          {initials}
        </Avatar>
        <div className="user-actions__dropdown-user-info">
          <span className="user-actions__dropdown-user-name">{`${user?.data?.firstName} ${user?.data?.lastName}`}</span>
          <span className="user-actions__dropdown-user-email">{user?.data?.email}</span>
          <span className="user-actions__dropdown-user-role">{user?.data?.role[0]}</span>
        </div>
      </div>

      {/* <Link
        to={`/admin/edit-user/${user.data ? user.data.id : ''}`}
        className="user-actions__account-link"
        onClick={() => setDropdownVisible(false)}>
        Account settings
      </Link> */}
      <div className="user-actions__account-bottom">
        {/* <Select
          className="user-actions__language-select"
          value={user.language && user.language.selected.name}
          onChange={(lang) =>
            user.setLanguage({
              ...user.language,
              selected: user.language.list.find((l) => l.name === lang)
            })
          }
          bordered={false}
          showArrow={false}
          optionLabelProp="label"
          placeholder={
            <React.Fragment>
              <Language />
              &nbsp; Language
            </React.Fragment>
          }>
          {user.language &&
            user.language.list.map((lang) => (
              <Select.Option
                value={lang.name}
                key={lang.name}
                label={
                  <React.Fragment>
                    <Language />
                    &nbsp;
                    {lang.name}
                  </React.Fragment>
                }>
                {lang.name}
              </Select.Option>
            ))}
        </Select> */}
        <Button
          onClick={handleSignOutSubmit}
          className="no-border-btn user-actions__btn"
          type="secondary">
          <LogoutIcon />
          <span
            style={{ color: '#000000' }}
            className="user-actions__btn-label no-border-btn__label">
            Logout
          </span>
        </Button>
      </div>
    </div>
  );

  return (
    <div className="user-actions">
      <Select
        className="user-actions__team-select"
        bordered={false}
        placeholder="Team name"
        value={user.selectedTeam}
        onChange={(value) => {
          const newTeam = user.data.team.find((t) => t._id === value);
          if (newTeam) {
            const oldStoredUser = JSON.parse(sessionStorage.getItem('user'));
            oldStoredUser.selectedTeam = newTeam;
            delete oldStoredUser.selectedStudy;
            sessionStorage.setItem('user', JSON.stringify(oldStoredUser));
            user.setSelectedTeam(value);
            user.setStudy(null);
          }
        }}>
        {user.data &&
          user.data.team &&
          user.data.team.map((t) => (
            <Select.Option value={t._id} key={t.name} label={t.name}>
              {t.name}
            </Select.Option>
          ))}
      </Select>

      {(user.selectedTeam || user?.data?.team[0]?.studies[0]) && (
        <Select
          className="user-actions__team-select"
          bordered={false}
          placeholder="Study name"
          value={user.study}
          onSelect={async (value) => {
            const selectedTeam = user.data.team.find((t) => t._id === user.selectedTeam);
            const newStudy = selectedTeam.studies.find((b) => b._id === value);
            if (newStudy) {
              const oldStoredUser = JSON.parse(sessionStorage.getItem('user'));
              oldStoredUser.selectedStudy = newStudy._id;
              sessionStorage.setItem('user', JSON.stringify(oldStoredUser));
              user.setStudy(value);
            }
            await setUserSettings(value);
          }}>
          {user.data &&
            user.data.team &&
            user.data.team.map(
              (t) =>
                t._id === user.selectedTeam &&
                t.studies &&
                t.studies.map((s) => (
                  <Select.Option value={s._id} key={s.name} label={s.name}>
                    {s.name}
                  </Select.Option>
                ))
            )}
        </Select>
      )}

      {/* <ReloadOutlined
        style={{ fontSize: '20px', color: '#08c', marginRight: 20 }}
        className='user-actions__icon'
        onClick={handleSyncWithVeeva}
      />

      <Search className='user-actions__icon' />
      <Bell className='user-actions__icon' />
      <Help className='user-actions__icon' /> */}
      {/* <Settings className='user-actions__icon' /> */}
      <Dropdown overlay={menu} visible={dropdownVisible}>
        <Avatar
          size={48}
          className="user-actions__avatar"
          onClick={() => setDropdownVisible((previous) => !previous)}
          ref={triggerRef}>
          {initials}
        </Avatar>
      </Dropdown>
    </div>
  );
};

export default UserActions;
