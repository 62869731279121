import { Modal } from 'antd';
import React from 'react';
import Endnotes from './Endnotes';

const EndnoteModal = ({ addingEndnote, closeModal, highlightedBlock, addEndnotes, endnoteForEdit, editCallback }) => {

  return (
    <Modal
      visible={!!addingEndnote || !!endnoteForEdit}
      onCancel={closeModal}
      footer={null}
      width={390}
    >
      {addingEndnote &&
        <h1 className='delete-modal__title'>Add {addingEndnote}</h1>
      }
      {endnoteForEdit &&
        <h1 className='delete-modal__title'>Edit {endnoteForEdit.abbreviation ? 'abbreviation' : endnoteForEdit.reference ? 'reference' : 'footnote'}</h1>
      }
      <Endnotes
        highlightedBlock={highlightedBlock}
        addingEndnote={addingEndnote}
        addEndnotes={addEndnotes}
        endnoteForEdit={endnoteForEdit}
        editCallback={editCallback}
      />
    </Modal>
  );
};

export default EndnoteModal;