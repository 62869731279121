import React from 'react';
import { Layout } from 'antd';
import UserActions from './UserActions';

const { Header } = Layout;

const HeaderComponent = ({ text, additionalText }) => {
  return (
    <Header className="header-component">
      <h2 className="header-component__page-heading">
        {text}
        {additionalText && additionalText !== 'Brand-kit' ? (
          <span className="header-component__page-heading-additional">{` / ${additionalText}`}</span>
        ) : additionalText && additionalText === 'Brand-kit' ? (
          <span className="header-component__page-heading-additional">{` / Brand Kit`}</span>
        ) : (
          ''
        )}
      </h2>
      <UserActions />
    </Header>
  );
};

export default HeaderComponent;
