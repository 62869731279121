import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { ROUTES } from '../../config';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
// import { ReactComponent as LogoBig } from '../../icons/logo-big.svg';
import { ReactComponent as LogoSmall } from '../../icons/logo-small.svg';
import { ReactComponent as LogoBig } from '../../icons/LogoQC.svg';
import useConfirm from '../elements/ConfirmationBox';
import { LayoutContext } from './Layout';
import { UserContext } from '../../App';
const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  const { isCollapsed, setCollapsed, user } = props;
  const { editInProgress, setEditInProgress } = useContext(LayoutContext);
  const [getConfirmation, Confirmation] = useConfirm();
  const currentuser = useContext(UserContext);
  let history = useHistory();

  const processLink = async (path) => {
    if (editInProgress) {
      if (await getConfirmation('Discard changes?')) {
        setEditInProgress(false);
        await currentuser.handleRefreshToken();
        history.push(path);
      }
    } else {
      await currentuser.handleRefreshToken();
      history.push(path);
    }
  };

  return (
    <Sider
      breakpoint="lg"
      className="main-sidebar"
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      width={260}
      collapsedWidth={56}
      onBreakpoint={() => setCollapsed(false)}
      onClick={() => isCollapsed && setCollapsed(false)}>
      <div className="main-sidebar__logo">
        {isCollapsed ? (
          <>
            <LogoSmall
              className="main-sidebar__logo-icon-small"
              onClick={() => setCollapsed(false)}
            />
          </>
        ) : (
          <>
            <LogoBig />
            <LeftOutlined
              className="main-sidebar__collapse-icon"
              onClick={() => setCollapsed(true)}
            />
          </>
        )}
      </div>
      <Menu
        mode="inline"
        defaultSelectedKeys={[window.location.pathname]}
        style={{ backgroundColor: 'transparent', borderRight: 'none' }}
        expandIcon={<RightOutlined />}>
        {user &&
          user.data &&
          ROUTES.filter((route) =>
            route.showInMenu.some((item) => user.data.role.includes(item))
          ).map((route) => {
            if (
              (!route.children && route.path) ||
              route.children.every((item) => !item.showInMenu)
            ) {
              return (
                <Menu.Item
                  key={route.label.toLowerCase()}
                  icon={<route.icon />}
                  id={route.label.replace(/\s/g, '_').trim().toLowerCase()}>
                  <Link
                    to="#"
                    onClick={() => {
                      processLink(route.path);
                    }}>
                    {route.label}
                  </Link>
                </Menu.Item>
              );
            } else {
              return (
                <SubMenu
                  key={route.label.toLowerCase()}
                  title={route.label}
                  icon={<route.icon />}
                  id={route.label.replace(/\s/g, '_').trim().toLowerCase()}>
                  {route.children
                    .filter(
                      (item) =>
                        item.showInMenu &&
                        item.allowed.some((allowed) => user.data.role.includes(allowed))
                    )
                    .map((item) => {
                      return (
                        <Menu.Item
                          key={item.label}
                          icon={item.icon}
                          // eslint-disable-next-line eqeqeq
                          className={item.label == ' Themes' && 'themes-menu-item'}
                          id={`${route.label.replace(/\s/g, '_').trim().toLowerCase()}-${item.label
                            .trim()
                            .toLowerCase()}`}>
                          <Link
                            to="#"
                            onClick={() => {
                              processLink(item.path);
                            }}>
                            {item.label}
                          </Link>
                        </Menu.Item>
                      );
                    })}
                </SubMenu>
              );
            }
          })}
        <Confirmation />
      </Menu>
    </Sider>
  );
};
export default NavMenu;
