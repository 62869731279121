import { Button, Modal } from 'antd';
import React from 'react';
import BlockDisplay from './BlockDisplay';

const TemplateModal = ({ visible, closeModal, block, useTemplate }) => {
  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      footer={null}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px'
      }}
    >
      <h2>{block.name}</h2>
      <BlockDisplay
        block={block}
        width={384}
        height={512}
      />
      <div
        style={{
          alignSelf: 'stretch',
          display: 'flex',
          alignItems: 'center',
          gap: '10px'
        }}
      >
        <Button
          onClick={closeModal}
          style={{ flex: '1' }}
        >
          Cancel
        </Button>
        <Button
          type='primary'
          style={{ flex: '1' }}
          onClick={useTemplate}
        >
          Use as template
        </Button>
      </div>
    </Modal>
  );
};

export default TemplateModal;