import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Select
} from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useContext } from 'react';
import { SERVER_URL } from '../../config';
import Axios from 'axios';
import PreviewReference from './PreviewReference';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
// import { ReactComponent as MinusIcon } from '../../icons/ph_minus.svg';
import { ReactComponent as Required } from '../../icons/exclamation-circled.svg';
import useAxios from '../../hooks/useAxios';
import { useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { UserContext } from '../../App';

let initialValues = {
  title: '',
  status: '',
  text: '',
  source: '',
  authors: undefined,
  groupAuthors: undefined,
  chapterTitle: '',
  monthYear: undefined,
  city: '',
  publisher: '',
  pmid: '',
  pmcid: '',
  doi: '',
  pii: '',
  articleSource: '',
  volume: '',
  issue: '',
  pageNumbers: '',
  language: '',
  retraction: '',
  conferenceName: '',
  brandName: '',
  company: '',
  country: '',
  webpage: '',
  website: '',
  datePublished: undefined,
  dateAccessed: undefined,
  url: '',
  otherInformation: '',
  reference: '',
  customReference: ''
};

const Reference = ({
  token,
  source,
  setSource,
  endnoteForEdit,
  editCallback,
  setStateForRefetchingReferences,
  setShowForm,
  library,
  newEndnote,
  setReferenceToEdit
}) => {
  const [form] = Form.useForm();
  const [reference, setReference] = useState('');
  const [isGroupAuthor, setIsGroupAuthor] = useState(false);
  const [numberOfAuthors, setNumberOfAuthors] = useState(0);
  const [numberOfOrganisations, setNumberOfOrganisations] = useState(0);
  const [updatePreview, setUpdatePreview] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [languages, fetchLanguages] = useAxios('', {}, token, 'get');
  const [notEnglish, setNotEnglish] = useState(false);
  const [foundReferences, setFoundReferences] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [disabledOnSearch, setDisabledOnSearch] = useState(false);
  const [articleIdType, setArticleIdType] = useState('pmid');
  const [isFoundReference, setIsFoundReference] = useState(false);
  const [isManualInput, setIsManualInput] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [visibleErrorMessage, setVisibleErrorMessage] = useState(false);
  const [containsRetraction, setContainsRetraction] = useState(false);
  const [retractionText, setRetractionText] = useState('');
  const [docType, setDocType] = useState('');
  const [titleSearchData, setTitleSearchData] = useState();
  const [titleSearchReferences, setTitleSearchReferences] = useState();
  const inputRef = useRef(null);
  const currentuser = useContext(UserContext);

  useEffect(() => {
    if (!form.getFieldValue('authors')) {
      form.setFieldsValue('authors', [undefined]);
    }
  }, []);

  useEffect(() => {
    fetchLanguages(`${SERVER_URL}/languages`, {});
  }, [fetchLanguages]);

  const onSubmit = async (values) => {
    const method = endnoteForEdit ? 'put' : 'post';
    const route = endnoteForEdit
      ? `${SERVER_URL}/references/${endnoteForEdit._id}`
      : `${SERVER_URL}/references`;

    try {
      values.source = source;
      values.reference = reference;
      values.team = currentuser.selectedTeam;
      values.study = currentuser.study;

      if (values.doi && typeof values.doi === 'object') {
        values.doi = values.doi.value;
      }
      if (values.pmcid && typeof values.pmcid === 'object') {
        values.pmcid = values.pmcid.value;
      }
      if (values.bookshelfArticleId && typeof values.bookshelfArticleId === 'object') {
        values.bookshelfArticleId = values.bookshelfArticleId.value;
      }
      // const authorsKeys = Object.keys(values).filter((key) => key.includes('initials'));
      // const authors = [];
      // authorsKeys.forEach((key, index) => {
      //   authors.push({
      //     initials: values['initials' + index],
      //     lastName: values['lastName' + index],

      //   });
      // });
      // values.authors = authors;
      // console.log('authors: ', authors);
      const res = await Axios[method](route, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` }
      });
      notification.success({
        message: `Reference ${endnoteForEdit ? 'updated' : 'created'}`,
        placement: 'bottomRight'
      });
      form.resetFields();
      if (endnoteForEdit && !library) {
        editCallback(res.data.item, 'references');
      } else {
        setNotEnglish(false);
        setNumberOfAuthors(1);
        setReference('');
        setRetractionText('');
        if (numberOfAuthors === 1) {
          setUpdatePreview((prev) => prev + 1);
        }
        if (setStateForRefetchingReferences) {
          setStateForRefetchingReferences((prev) => prev + 1);
        }
      }
      if (library && !setReferenceToEdit) {
        setShowForm('');
      }
      // for library
      if (setReferenceToEdit) {
        setReferenceToEdit(null);
      }
    } catch (error) {
      notification.error({
        message: 'Problem with creating reference',
        placement: 'bottomRight'
      });
    }
  };

  useEffect(() => {
    setFormValues(form.getFieldsValue());
  }, [updatePreview, form, numberOfAuthors, source, isGroupAuthor, numberOfOrganisations]);

  useEffect(() => {
    if (endnoteForEdit) {
      setIsManualInput(true);
    }
  }, [endnoteForEdit, setIsManualInput]);

  // searching by ids for found reference by searh term
  const searchByIDs = (ids, field) => {
    let requestOptions = {
      method: 'GET'
    };
    try {
      fetch(
        `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&id=${ids}&retmode=json`,
        requestOptions
      )
        .then((response) => response.text())
        .then(async (result) => {
          const resultObject = await JSON.parse(result);
          const data = resultObject.result;
          // console.log('data: ', data);
          const ids = data.uids;
          let items = [];
          let titles = [];
          const promises = ids.map(async (id) => {
            await items.push(data[id]);
            await titles.push({ value: data[id].title.toString() });
          });
          await Promise.all(promises);
          setFoundReferences(items);
          if (field === 'title' || (items.length > 1 && field === 'pii')) {
            setTitleOptions(titles);
            if (field === 'pii') {
              setTimeout(() => {
                inputRef?.current?.focus({
                  cursor: 'start'
                });
              }, 0);
            }
          } else {
            // in case if you search by PMCID, DOI or PII which returns 1 article
            searchByTitle(titles[0].value, items);
          }
          setDisabledOnSearch(false);
        })
        .catch((error) => console.log('error', error));
    } catch (error) {
      setDisabledOnSearch(false);
      notification.error({
        message: 'Problem with getting data',
        placement: 'bottomRight'
      });
      console.log('error in searching', error);
    }
  };

  // searching by title and by any ID type except PMID
  const articleSearchHandler = async (searchTerm, field) => {
    try {
      if (searchTerm?.length >= 5 || field !== 'title') {
        // let myHeaders = new Headers();
        // myHeaders.append("Cookie", "ncbi_sid=165AD439EA2598E7_38ACSID");
        let requestOptions = {
          method: 'GET'
        };
        const url =
          field === 'title'
            ? `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=20&term=${searchTerm}[ti]+AND+Journal article[pt]`
            : `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=20&term=${searchTerm}[${field}]`;
        // console.log(url);

        fetch(url, requestOptions)
          .then((response) => response.text())
          .then(async (result) => {
            const data = await JSON.parse(result);
            const idList =
              data.esearchresult.idlist.length > 0 ? data.esearchresult.idlist.join(',') : [];
            if (
              (idList.length > 0 && field === 'title') ||
              (idList.length >= 1 && field !== 'title')
            ) {
              setTimeout(() => {
                searchByIDs(idList, field);
              }, 500);
            } else {
              setVisibleErrorMessage(true);
              setDisabledOnSearch(false);
            }
          })
          .catch((error) => console.log('error', error));
      }
    } catch (error) {
      notification.error({
        message: 'Problem with getting data',
        placement: 'bottomRight'
      });
      console.log('error in searching', error);
    }
  };

  // search by PMID
  const searchByPMID = async () => {
    try {
      const id = form.getFieldValue('pmid').trim();
      const res = await Axios.get(
        `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&id=${id}&retmode=json`
      );
      const data = res.data.result[id];
      // console.log('data: ', data);
      const authorsArray = [];
      const groupAuthorsArray = [];
      data.authors.forEach((author) => {
        if (author.authtype === 'Author') {
          const authorParts = author.name.split(' ');
          authorsArray.push({
            initials: authorParts[1],
            lastName: authorParts[0]
          });
        } else if (author.authtype === 'Editor') {
          const authorParts = author.name.split(' ');
          authorsArray.push({
            initials: authorParts[1] + ' editor',
            lastName: authorParts[0]
          });
        } else if (author.authtype === 'CollectiveName') {
          groupAuthorsArray.push({
            name: author.name
          });
        }
      });
      form.setFieldsValue({ ['authors']: authorsArray });
      form.setFieldsValue({ ['groupAuthors']: groupAuthorsArray });
      // If retraction exists
      data.references.forEach((item) => {
        setRetractionText(item.reftype + ': ' + item.refsource);
        switch (item.reftype) {
          case 'Retraction of':
            form.setFieldsValue({ retraction: 'retraction' });
            break;
          case 'Partial retraction of':
            form.setFieldsValue({ retraction: 'partial retraction' });
            break;
          case 'Retraction in':
            form.setFieldsValue({ retraction: 'retracted' });
            break;
          case 'Partial retraction in':
            form.setFieldsValue({ retraction: 'partially retracted' });
            break;
          case 'Corrected and republished from':
            form.setFieldsValue({ retraction: 'republished with corrections' });
            break;
          default:
            break;
        }
      });

      form.setFieldsValue({
        title: data.title || data.booktitle,
        journalName: data.fulljournalname,
        articleSource: data.source,
        chapterTitle: data.chapter,
        pageNumbers: data.pages,
        volume: data.volume,
        issue: data.issue,
        doi: data?.articleids?.find((articleid) => {
          if (articleid.idtype === 'doi') {
            return articleid.value;
          } else {
            return null;
          }
        }),
        pmcid: data?.articleids?.find((articleid) => articleid.idtype === 'pmcid'),
        bookshelfArticleId: data?.articleids?.find(
          (articleid) => articleid.idtype === 'bookaccession'
        ),
        ePubDate: data.epubdate,
        // medium: data.medium,
        publisherLocation: data.publisherlocation,
        publisherName: data.publishername,
        // year: moment(data.sortpubdate),
        year: new Date(data.sortpubdate).getFullYear(),
        publishDate: data.pubdate
      });
      if (data.lang[0] !== 'eng') {
        const foundLanguage = languages?.data?.items?.filter(
          (lang) => lang.abbreviation === data.lang[0]
        );
        form.setFieldsValue({ language: foundLanguage[0]?.name });
        setNotEnglish(true);
      }
      setDocType(data.doctype);
      setUpdatePreview((prev) => prev + 1);
      setDisabledOnSearch(false);
      setIsFoundReference(true);
      setVisibleErrorMessage(false);
    } catch (error) {
      setIsFoundReference(false);
      setVisibleErrorMessage(true);
      setDisabledOnSearch(false);
      setUpdatePreview((prev) => prev + 1);
    }
  };

  // console.log('titleSearchData: ', titleSearchData);
  // console.log('titleSearchReferences: ', titleSearchReferences);

  // get data for chosen title
  const searchByTitle = (data, references) => {
    try {
      const selectedReference =
        foundReferences.length !== 0
          ? foundReferences.filter((ref) => ref.title.toLowerCase() === data.toLowerCase())
          : references.filter((ref) => ref.title.toLowerCase() === data.toLowerCase());
      selectedReference[0].references.forEach((item) => {
        setRetractionText(item.reftype + ': ' + item.refsource);
        switch (item.reftype) {
          case 'Retraction of':
            form.setFieldsValue({ retraction: 'retraction' });
            break;
          case 'Partial retraction of':
            form.setFieldsValue({ retraction: 'partial retraction' });
            break;
          case 'Retraction in':
            form.setFieldsValue({ retraction: 'retracted' });
            break;
          case 'Partial retraction in':
            form.setFieldsValue({ retraction: 'partially retracted' });
            break;
          case 'Corrected and republished from':
            form.setFieldsValue({ retraction: 'republished with corrections' });
            break;
          default:
            break;
        }
      });
      // setPmid(selectedReference[0].uid);
      form.setFieldsValue({
        title: selectedReference[0].title,
        journalName: selectedReference[0].fulljournalname,
        articleSource: selectedReference[0].source,
        chapterTitle: selectedReference[0].chapter,
        volume: selectedReference[0].volume,
        pageNumbers: selectedReference[0].pages,
        issue: selectedReference[0].issue,
        pmid: selectedReference[0].uid,
        doi: selectedReference[0]?.articleids?.find((articleid) => articleid.idtype === 'doi'),
        pmcid: selectedReference[0]?.articleids?.find((articleid) => articleid.idtype === 'pmcid'),
        bookshelfArticleId: selectedReference[0]?.articleids?.find(
          (articleid) => articleid.idtype === 'bookaccession'
        ),
        // piiArticleId: selectedReference[0]?.articleids?.find(articleid => articleid.idtype === 'pii'),
        // medium: data.medium,
        publisherLocation: data.publisherlocation,
        publisherName: data.publishername,
        ePubDate: data.epubdate,
        // year: new Date(selectedReference[0].sortpubdate).getFullYear(),
        year: new Date(selectedReference[0].sortpubdate).getFullYear(),
        publishDate: selectedReference[0].pubdate
      });
      const authorsArray = [];
      const groupAuthorsArray = [];
      selectedReference[0].authors.forEach((author, index) => {
        if (author.authtype === 'Author') {
          const authorParts = author.name.split(' ');
          authorsArray.push({
            initials: authorParts[1],
            lastName: authorParts[0]
          });
        } else if (author.authtype === 'Editor') {
          const authorParts = author.name.split(' ');
          authorsArray.push({
            initials: authorParts[1] + ' ed.',
            lastName: authorParts[0]
          });
        } else if (author.authtype === 'CollectiveName' || author.authtype === 'GroupAuthor') {
          groupAuthorsArray.push({
            name: author.name
          });
        }
      });
      form.setFieldsValue({ ['authors']: authorsArray });
      form.setFieldsValue({ ['groupAuthors']: groupAuthorsArray });
      setUpdatePreview((prev) => prev + 1);
      setIsFoundReference(true);
    } catch (error) {
      setIsFoundReference(false);
    }
  };

  // search books by title
  const bookSearchHandler = (searchTerm, field) => {
    try {
      if (searchTerm?.length >= 5 || field !== 'title') {
        // let myHeaders = new Headers();
        // myHeaders.append("Cookie", "ncbi_sid=165AD439EA2598E7_38ACSID");
        let requestOptions = {
          method: 'GET'
        };
        const url =
          field === 'title'
            ? `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=20&term=${searchTerm}[bti]`
            : `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=20&term=${searchTerm}[${field}]`;
        // console.log(url);
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then(async (result) => {
            const data = await JSON.parse(result);
            console.log(data);
            const idList =
              data.esearchresult.idlist.length > 0 ? data.esearchresult.idlist.join(',') : [];
            if (
              (idList.length > 0 && field === 'title') ||
              (idList.length >= 1 && field !== 'title')
            ) {
              setTimeout(() => {
                searchByIDs(idList, field);
              }, 500);
            } else {
              // ovo se moze desiti ako ne pronadje naslov
              console.log('no ids');
              setVisibleErrorMessage(true);
              setDisabledOnSearch(false);
            }
          })
          .catch((error) => console.log('error', error));
      }
    } catch (error) {
      notification.error({
        message: 'Problem with getting data',
        placement: 'bottomRight'
      });
      console.log('error in searching', error);
    }
  };

  const handleChangeDebounced = _.debounce(async (value) => {
    if (!value) {
      form.resetFields();
      setRetractionText('');
      setNotEnglish(false);
      setIsFoundReference(false);
    }
    setTitleOptions([]);
    setFoundReferences([]);
    setUpdatePreview((prev) => prev + 1);
    setVisibleErrorMessage(false);
    if (source === 'journal article') {
      articleSearchHandler(value, 'title');
    } else if (source === 'book') {
      bookSearchHandler(value, 'title');
    }
  }, 1000);

  const handleChange = (value) => {
    handleChangeDebounced(value);
  };

  // funkcija se okida prilikom promene vrste id-ja (dropdown)
  const changeIdTypeHandler = (value) => {
    setArticleIdType(value);
    setUpdatePreview((prev) => prev + 1);
    form.resetFields();
    setRetractionText('');
    setIsFoundReference(false);
    setNotEnglish(false);
  };

  // triggers on click search button
  const searchByIdHandler = () => {
    setDisabledOnSearch(true);
    setVisibleErrorMessage(false);
    if (articleIdType === 'pmid') {
      searchByPMID();
    } else {
      if (source === 'journal article') {
        articleSearchHandler(form.getFieldValue(articleIdType), articleIdType);
      } else if (source === 'book') {
        bookSearchHandler(form.getFieldValue(articleIdType), articleIdType);
      }
    }
  };

  // if input form link is clicked
  const manualInputHandler = () => {
    setIsManualInput(true);
    setIsFoundReference(false);
    setVisibleErrorMessage(false);
    form.resetFields();
    setRetractionText('');
    setNumberOfAuthors(1);
    setNumberOfOrganisations(1);
    setNotEnglish(false);
    setUpdatePreview((prev) => prev + 1);
  };

  // console.log('docType: ', docType);
  // console.log('endnoteForEdit: ', endnoteForEdit);

  if (endnoteForEdit) {
    endnoteForEdit.monthYear = moment(endnoteForEdit.monthYear);
    initialValues = {
      ...initialValues,
      ...endnoteForEdit,
      authors: endnoteForEdit.authors?.map((author) => ({ ...author }))
    };
  }

  if (newEndnote) {
    initialValues = {
      title: '',
      status: '',
      text: '',
      source: '',
      authors: undefined,
      groupAuthors: undefined,
      chapterTitle: '',
      monthYear: undefined,
      city: '',
      publisher: '',
      pmid: '',
      pmcid: '',
      doi: '',
      pii: '',
      articleSource: '',
      volume: '',
      issue: '',
      pageNumbers: '',
      language: '',
      retraction: '',
      conferenceName: '',
      brandName: '',
      company: '',
      country: '',
      webpage: '',
      website: '',
      datePublished: undefined,
      dateAccessed: undefined,
      url: '',
      otherInformation: '',
      reference: '',
      customReference: ''
    };
    // form.resetFields();
  }
  // console.log('endnoteForEdit: ', endnoteForEdit);
  // console.log('newEndnote: ', newEndnote);

  // console.log('isFoundReference: ', isFoundReference);
  // console.log('isManualInput: ', isManualInput);
  // console.log('form.getFieldsValue() :', form.getFieldsValue());

  return (
    <div>
      <Select
        className="dropdown-medium"
        style={{ width: '100%', marginBottom: '10px' }}
        placeholder="Reference Type"
        defaultValue={source}
        onChange={(value) => {
          setSource(value);
          form.resetFields();
          setRetractionText('');
          setNotEnglish(false);
          setNumberOfAuthors(1);
          setNumberOfOrganisations(1);
        }}>
        <Select.Option value="journal article" className="fieldType">
          Journal Article
        </Select.Option>
        <Select.Option value="book" className="fieldType">
          Book
        </Select.Option>
        <Select.Option value="conference proceeding" className="fieldType">
          Conference Proceeding
        </Select.Option>
        <Select.Option value="website" className="fieldType">
          Website
        </Select.Option>
        <Select.Option value="pi" className="fieldType">
          PI
        </Select.Option>
        <Select.Option value="smpc" className="fieldType">
          SmPC
        </Select.Option>
        <Select.Option value="data on file" className="fieldType">
          Data on File
        </Select.Option>
        <Select.Option value="other" className="fieldType">
          Other
        </Select.Option>
      </Select>

      <Form
        form={form}
        initialValues={initialValues}
        onFinish={(values) => onSubmit(values)}
        // onValuesChange={(values) => setFormValues(values)}
        layout="vertical"
        preserve={false}>
        {source && (
          <>
            {source !== 'smpc' &&
              source !== 'data on file' &&
              source !== 'pi' &&
              source !== 'other' && (
                <>
                  {source === 'book' && (
                    <>
                      <Row style={{ width: '100%' }}>
                        <Col md={6} xs={24}>
                          <div>
                            <Select
                              defaultValue={articleIdType}
                              onChange={(value) => changeIdTypeHandler(value)}
                              style={{ width: '80px' }}>
                              <Select.Option value="pmid">PMID</Select.Option>
                              {/* <Select.Option value='isbn'>ISBN</Select.Option> */}
                              <Select.Option value="doi">DOI</Select.Option>
                            </Select>
                          </div>
                        </Col>
                        <Col md={12} xs={24} style={{ marginRight: '12px', marginLeft: '-2px' }}>
                          <Form.Item name={articleIdType}>
                            <Input
                              disabled={disabledOnSearch}
                              // onChange={() => { setUpdatePreview(prev => prev + 1); }}
                              placeholder="Search by ID"
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Button
                            disabled={disabledOnSearch}
                            size="small"
                            type="primary"
                            onClick={searchByIdHandler}>
                            Search
                          </Button>
                        </Col>
                      </Row>
                      <Row style={{ width: '100%' }} gutter={8}>
                        <Col md={24} xs={24} style={{ display: 'flex' }}>
                          <AutoComplete
                            open={showDropdown}
                            style={{ width: '100%', marginBottom: '15px' }}
                            disabled={disabledOnSearch}
                            ref={inputRef}
                            options={titleOptions}
                            placeholder="Search by Title"
                            onChange={handleChange}
                            onSelect={searchByTitle}
                            filterOption={(inputValue, option) =>
                              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onBlur={() => setShowDropdown(false)}
                            onFocus={() => setShowDropdown(true)}
                          />
                        </Col>
                        {/* <Col md={5} xs={24}>
                        <Button style={{ height: '32px', padding: '0px 8px' }} type='primary' onClick={searchByTitle}>
                          Search
                        </Button>
                      </Col> */}
                      </Row>
                    </>
                  )}
                  {source === 'journal article' && (
                    <>
                      <Row style={{ width: '100%' }}>
                        <Col md={6} xs={24}>
                          <div>
                            <Select
                              defaultValue={articleIdType}
                              onChange={(value) => changeIdTypeHandler(value)}
                              style={{ width: '80px' }}>
                              <Select.Option value="pmid">PMID</Select.Option>
                              {/* <Select.Option value='isbn'>ISBN</Select.Option> */}
                              <Select.Option value="doi">DOI</Select.Option>
                              <Select.Option value="pii">PII</Select.Option>
                            </Select>
                          </div>
                        </Col>
                        <Col md={12} xs={24} style={{ marginRight: '12px', marginLeft: '-2px' }}>
                          <Form.Item name={articleIdType}>
                            <Input
                              disabled={disabledOnSearch}
                              // onChange={() => { setUpdatePreview(prev => prev + 1); }}
                              placeholder="Search by ID"
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Button
                            disabled={disabledOnSearch}
                            size="small"
                            type="primary"
                            onClick={searchByIdHandler}>
                            Search
                          </Button>
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        <Col md={18} xs={24}>
                          <AutoComplete
                            open={showDropdown}
                            style={{ width: '100%', marginBottom: '15px' }}
                            disabled={disabledOnSearch}
                            ref={inputRef}
                            options={titleOptions}
                            placeholder="Search by Title"
                            onChange={handleChange}
                            onSelect={(value, option) => {
                              setTitleSearchData(value);
                              setTitleSearchReferences(option);
                            }}
                            filterOption={(inputValue, option) =>
                              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onBlur={() => setShowDropdown(false)}
                            onFocus={() => setShowDropdown(true)}
                          />
                        </Col>
                        <Col>
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => searchByTitle(titleSearchData, titleSearchReferences)}>
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
            {visibleErrorMessage && !isManualInput ? (
              <div className="referenceError">
                <Required
                  style={{
                    color: '#FB5757',
                    width: '35px',
                    height: '35px',
                    marginRight: '5px'
                  }}
                />
                <div>
                  We couldn’t find the {source === 'journal article' ? 'Journal Article' : 'Book'}{' '}
                  your are looking for. Use our{' '}
                  <a
                    href="#"
                    style={{ textDecoration: 'underline', color: '#4962E2' }}
                    onClick={manualInputHandler}>
                    Input Form
                  </a>{' '}
                  instead.
                </div>
              </div>
            ) : (
              visibleErrorMessage && (
                <div style={{ marginBottom: '15px' }}>
                  We couldn’t find the {source === 'journal article' ? 'Journal Article' : 'Book'},
                  use the form below to add a Reference manually.
                </div>
              )
            )}
            {(isFoundReference ||
              isManualInput ||
              (source !== 'journal article' && source !== 'book')) && (
              <div className="previewBox">
                <div className="previewTitle">PREVIEW</div>
                <PreviewReference
                  source={source}
                  setReference={setReference}
                  formValues={formValues}
                  // formValues={form.getFieldsValue()}
                  isGroupAuthor={isGroupAuthor}
                  retractionText={retractionText}
                  articleIdType={articleIdType}
                  docType={docType}
                />
              </div>
            )}
            {!isManualInput &&
              !visibleErrorMessage &&
              source !== 'conference proceeding' &&
              source !== 'website' &&
              source !== 'pi' &&
              source !== 'smpc' &&
              source !== 'data on file' &&
              source !== 'other' && (
                <div style={{ marginBottom: '15px' }}>
                  To add a Reference manually, use our{' '}
                  <a
                    href="#"
                    style={{ textDecoration: 'underline', color: '#4962E2' }}
                    onClick={manualInputHandler}>
                    Input Form
                  </a>
                  .
                </div>
              )}
            <div
              style={{
                display:
                  (isManualInput && !isFoundReference) ||
                  (source !== 'journal article' && source !== 'book')
                    ? 'block'
                    : 'none'
              }}>
              {source === 'journal article' && (
                <Form.Item name="title">
                  <Input
                    placeholder="Article Title"
                    onChange={() => setUpdatePreview((prev) => prev + 1)}
                  />
                </Form.Item>
              )}
              {source === 'book' && (
                <Form.Item name="title">
                  <Input
                    placeholder="Book Title"
                    onChange={() => setUpdatePreview((prev) => prev + 1)}
                  />
                </Form.Item>
              )}
              {source !== 'smpc' &&
                source !== 'data on file' &&
                source !== 'pi' &&
                source !== 'other' && (
                  <div style={{ margin: '24px 0' }}>
                    {/* <div style={{ marginBottom: '8px' }}>
                    <Checkbox onChange={(event) => setIsGroupAuthor(event.target.checked)}>Group Author</Checkbox>
                  </div> */}
                    <Form.List name="authors">
                      {(fields, { add, remove }) => {
                        return (
                          <>
                            {fields.map((field) => (
                              <React.Fragment key={field.key}>
                                <Row gutter={8}>
                                  <Col xs={24} md={4}>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, 'initials']}
                                      style={{ marginBottom: '8px' }}>
                                      <Input
                                        placeholder="Initials"
                                        onChange={() => setUpdatePreview((prev) => prev + 1)}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} md={12}>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, 'lastName']}
                                      style={{ marginBottom: '8px' }}>
                                      <Input
                                        placeholder="Last Name"
                                        onChange={() => setUpdatePreview((prev) => prev + 1)}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, 'editor']}
                                      style={{ marginBottom: '8px' }}
                                      className="editor-checkbox"
                                      valuePropName="checked">
                                      <Checkbox
                                        onChange={() => setUpdatePreview((prev) => prev + 1)}>
                                        Editor
                                      </Checkbox>
                                    </Form.Item>
                                  </Col>
                                  {fields.length >= 1 && (
                                    <Col xs={24} md={2}>
                                      <MinusCircleOutlined
                                        onClick={() => {
                                          setUpdatePreview((prev) => prev + 1);
                                          remove(field.name);
                                        }}
                                        style={{ marginTop: '8px' }}
                                      />
                                    </Col>
                                  )}
                                </Row>
                              </React.Fragment>
                            ))}
                            <Row gutter={8}>
                              <Col xs={24} md={24}>
                                <Form.Item
                                  style={{
                                    margin: 0,
                                    marginBottom: '16px',
                                    paddingBottom: '8px',
                                    borderBottom: '1px solid #D5DFE8'
                                  }}>
                                  <div
                                    onClick={() => add()}
                                    className="add-author-btn"
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: '#4962E2',
                                      cursor: 'pointer',
                                      width: 'fit-content'
                                    }}>
                                    <PlusIcon width="10px" height="10px" />
                                    <span style={{ marginLeft: '10px' }}>
                                      Add an Individual Author
                                    </span>
                                  </div>
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        );
                      }}
                    </Form.List>
                    <Form.List name="groupAuthors">
                      {(fields, { add, remove }) => {
                        return (
                          <>
                            {fields.map((field) => {
                              return (
                                <React.Fragment key={field.key}>
                                  <Row gutter={8}>
                                    <Col xs={24} md={22}>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, 'name']}
                                        style={{ marginBottom: '8px' }}>
                                        <Input
                                          placeholder="Organisation name"
                                          onChange={() => setUpdatePreview((prev) => prev + 1)}
                                        />
                                      </Form.Item>
                                    </Col>
                                    {fields.length >= 1 && (
                                      <Col xs={24} md={2}>
                                        <MinusCircleOutlined
                                          onClick={() => {
                                            setUpdatePreview((prev) => prev + 1);
                                            remove(field.name);
                                          }}
                                          style={{ marginTop: '8px' }}
                                        />
                                      </Col>
                                    )}
                                  </Row>
                                </React.Fragment>
                              );
                            })}
                            <Row gutter={8}>
                              <Col xs={24} md={24} className="add-author-btn">
                                <Form.Item
                                  style={{
                                    margin: 0,
                                    marginBottom: '16px',
                                    paddingBottom: '8px',
                                    borderBottom: '1px solid #D5DFE8'
                                  }}>
                                  <div
                                    onClick={() => add()}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: '#4962E2',
                                      cursor: 'pointer',
                                      width: 'fit-content'
                                    }}>
                                    <PlusIcon width="10px" height="10px" />
                                    <span style={{ marginLeft: '10px' }}>Add a Group Author</span>
                                  </div>
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        );
                      }}
                    </Form.List>
                  </div>
                )}
              {(source === 'book' || docType === 'book') && (
                <>
                  <Form.Item name="bookEdition">
                    <Input
                      placeholder="Edition"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="publisherLocation">
                    <Input
                      placeholder="Location(Publisher)"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="publisherName">
                    <Input
                      placeholder="Publisher Name"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="chapterTitle">
                    <Input
                      placeholder="Chapter Title"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="pageNumbers">
                    <Input
                      placeholder="Page Numbers"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="year">
                    {/* <DatePicker
                      style={{ width: '100%' }}
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                      picker='year'
                      placeholder='Year'
                    /> */}
                    <Input
                      type="number"
                      placeholder="Date (YYYY-MM)"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="doi">
                    <Input
                      placeholder="DOI"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="bookshelfArticleId">
                    <Input
                      placeholder="Bookshelf ID"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="isbn">
                    <Input
                      placeholder="ISBN"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <div style={{ marginBottom: '15px' }}>
                    <Checkbox onChange={(event) => setNotEnglish(event.target.checked)}>
                      Book is not in English
                    </Checkbox>
                  </div>
                  {notEnglish && (
                    <Form.Item name="language">
                      <Select
                        placeholder="Choose Language"
                        onChange={() => setUpdatePreview((prev) => prev + 1)}
                        showSearch>
                        {languages?.data?.items?.map((item) => (
                          <Select.Option value={item.name}>{item.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </>
              )}
              {source === 'journal article' && (
                <>
                  <Form.Item name="journalName">
                    <Input
                      placeholder="Journal Name"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="year">
                    {/* <DatePicker
                      style={{ width: '100%' }}
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                      picker='year'
                      placeholder='Year'
                    /> */}
                    <Input
                      type="number"
                      placeholder="Year"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="volume">
                    <Input
                      placeholder="Volume"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="issue">
                    <Input
                      placeholder="Issue"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="pageNumbers">
                    <Input
                      placeholder="Page Numbers"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="pmid">
                    <Input
                      placeholder="PMID"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  {/* <Form.Item
                    name='pmcid'
                  >
                    <Input
                      placeholder='PMCID'
                      onChange={() => setUpdatePreview(prev => prev + 1)}
                    />
                  </Form.Item> */}
                  <Form.Item name="doi">
                    <Input
                      placeholder="DOI"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>

                  <div style={{ marginBottom: '15px' }}>
                    <Checkbox onChange={(event) => setNotEnglish(event.target.checked)}>
                      Article is not in English
                    </Checkbox>
                  </div>
                  {notEnglish && (
                    <Form.Item name="language">
                      <Select
                        placeholder="Choose Language"
                        onChange={() => setUpdatePreview((prev) => prev + 1)}
                        showSearch>
                        {languages?.data?.items?.map((item) => (
                          <Select.Option value={item.name}>{item.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  <div style={{ marginBottom: '15px' }}>
                    <Checkbox onChange={(event) => setContainsRetraction(event.target.checked)}>
                      Article contains a Retraction
                    </Checkbox>
                  </div>
                  {containsRetraction && (
                    <Form.Item name="retraction">
                      <Select
                        placeholder="Choose Retraction Type"
                        onChange={() => setUpdatePreview((prev) => prev + 1)}
                        showSearch>
                        <Select.Option value="retraction">
                          Article Containing Retraction
                        </Select.Option>
                        <Select.Option value="partial retraction">
                          Article Containing a Partial Retraction
                        </Select.Option>
                        <Select.Option value="retracted">Article Retracted</Select.Option>
                        <Select.Option value="partially retracted">
                          Article Partially Retracted
                        </Select.Option>
                        <Select.Option value="republished with corrections">
                          Article Republished with Corrections
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  )}
                </>
              )}
              {source === 'conference proceeding' && (
                <>
                  <Form.Item name="title">
                    <Input
                      placeholder="Title"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="conferenceName">
                    <Input
                      placeholder="Conference Name"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="conferenceYear">
                    {/* <DatePicker
                      style={{ width: "100%" }}
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                      picker="year"
                      placeholder="Year(Conference)"
                    /> */}
                    <Input
                      type="number"
                      placeholder="Year(Conference)"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="conferenceLocation">
                    <Input
                      placeholder="Location(Conference)"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="publisherName">
                    <Input
                      placeholder="Publisher Name"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="publicationYear">
                    {/* <DatePicker
                      style={{ width: "100%" }}
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                      picker="year"
                      placeholder="Year(Publication)"
                    /> */}
                    <Input
                      type="number"
                      placeholder="Year(Publication)"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="publisherLocation">
                    <Input
                      placeholder="Location(Publisher)"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                </>
              )}
              {(source === 'pi' || source === 'smpc') && (
                <>
                  <Form.Item
                    name="brandName"
                    rules={[{ required: true, message: 'Please enter Brand Name!' }]}>
                    <Input
                      placeholder="Brand Name"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="registeredTrademark" valuePropName="checked">
                    <Checkbox onChange={() => setUpdatePreview((prev) => prev + 1)}>Add ®</Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="title"
                    rules={[{ required: true, message: 'Please enter Title!' }]}>
                    <Input
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                      placeholder={
                        source === 'pi'
                          ? 'Prescribing Information'
                          : 'Summary of Product Characteristics'
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="company"
                    rules={[{ required: true, message: 'Please enter Company!' }]}>
                    <Input
                      placeholder="Company"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="country">
                    <Input
                      placeholder="Country"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="monthYear" valuePropName="date">
                    <DatePicker style={{ width: '100%' }} picker="month" placeholder="Date" />
                    {/* <Input
                      type="number"
                      placeholder="Date (YYYY-MM)"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    /> */}
                  </Form.Item>
                </>
              )}
              {source === 'data on file' && (
                <>
                  <Form.Item
                    name="title"
                    rules={[{ required: true, message: 'Please enter Title!' }]}>
                    <Input
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                      placeholder="Data on File"
                    />
                  </Form.Item>
                  <Form.Item
                    name="company"
                    rules={[{ required: true, message: 'Please enter Company!' }]}>
                    <Input
                      placeholder="Company"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="year" valuePropName="date">
                    {/* <DatePicker
                      style={{ width: "100%" }}
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                      picker="year"
                      placeholder="Year"
                    /> */}
                    <Input
                      type="number"
                      placeholder="Year"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                </>
              )}
              {source === 'website' && (
                <>
                  <Form.Item
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Name of Website!'
                      }
                    ]}>
                    <Input
                      placeholder="Website"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="webpage">
                    <Input
                      placeholder="Name of Webpage"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                  <Form.Item name="datePublished" valuePropName="date">
                    <DatePicker
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                      placeholder="Date Published"
                      style={{ width: '100%' }}
                      format={'MM/DD/YYYY'}
                    />
                  </Form.Item>
                  <Form.Item name="dateAccessed" valuePropName="date">
                    <DatePicker
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                      placeholder="Date Accessed"
                      style={{ width: '100%' }}
                      format={'MM/DD/YYYY'}
                    />
                  </Form.Item>
                  <Form.Item name="url" rules={[{ required: true, message: 'Please enter URL!' }]}>
                    <Input
                      placeholder="URL"
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                    />
                  </Form.Item>
                </>
              )}
              {source === 'other' && (
                <>
                  <Form.Item
                    name="customReference"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Custom Reference!'
                      }
                    ]}>
                    <Input
                      onChange={() => setUpdatePreview((prev) => prev + 1)}
                      placeholder="Custom reference"
                    />
                  </Form.Item>
                </>
              )}
            </div>
            {(isFoundReference ||
              isManualInput ||
              (source !== 'journal article' && source !== 'book')) && (
              <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                Save Reference
              </Button>
            )}
          </>
        )}
      </Form>
    </div>
  );
};

export default Reference;
