import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as FavoriteIcon } from '../../icons/favorite.svg';
import { ReactComponent as FavoriteFullIcon } from '../../icons/favorite-full.svg';
import { ReactComponent as ExpandIcon } from '../../icons/expand-media.svg';
import Masonry from 'react-masonry-css';
import NoData from '../elements/NoData';

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#000' }} spin />;

const StockImagesGrid = ({
  allImages,
  getAllImages,
  searchKeyword,
  initSearchTerm,
  mediaOrientation,
  selectedMediaColor,
  fetchMorePexel,
  pexelDataInfo
}) => {
  const [rerenderState, setRerenderState] = useState(0);
  const [searchTerm, setSearchTerm] = useState(initSearchTerm);
  const [imageHovered, setImageHovered] = useState();
  const [imageForPreview, setImageForPreview] = useState();
  const [previewModalVisible, setPreviewModalVisible] = useState(false);

  if (searchKeyword && searchKeyword !== searchTerm) {
    setSearchTerm(searchKeyword);
    setRerenderState((previous) => previous + 1);
  } else if (!searchKeyword && searchTerm !== initSearchTerm) {
    setSearchTerm(initSearchTerm);
    setRerenderState((previous) => previous + 1);
  }

  useEffect(() => {
    getAllImages();
  }, [rerenderState, mediaOrientation, selectedMediaColor]);

  const showPreviewModal = (imageId) => {
    setPreviewModalVisible(true);
    setImageForPreview(imageId);
  };

  // console.log('imageForPreview: ', imageForPreview);
  // console.log('previewModalVisible: ', previewModalVisible);

  return (
    <>
      {allImages?.length > 0 ? (
        <InfiniteScroll
          dataLength={allImages.length}
          next={fetchMorePexel}
          hasMore={allImages.length < pexelDataInfo.total_results}
          // loader={<Spin indicator={antIcon} />}
          scrollableTarget="scrollable-div">
          <Masonry
            breakpointCols={5}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
            id="scrollable-div"
            style={{ height: '80vh', overflowY: 'auto' }}>
            {allImages.map((image, index) => {
              return (
                <div style={{ position: 'relative' }} key={index}>
                  <img
                    onMouseEnter={() => setImageHovered(index)}
                    key={index}
                    className="library-asset"
                    src={image.mediumUrl}
                    style={{ display: 'block', width: '100%' }}
                    alt={image.title}
                  />
                  {imageHovered === index && (
                    <div
                      className="image-overlay image-overlay__img"
                      style={{ position: 'absolute', left: 0, right: 0, top: 0 }}>
                      <div className="image-overlay__top-row">
                        <span className="image-overlay__icon">
                          {image.favorite ? <FavoriteFullIcon /> : <FavoriteIcon />}
                        </span>
                        <span
                          onClick={() => showPreviewModal(image.id)}
                          className="image-overlay__icon">
                          <ExpandIcon />
                        </span>
                      </div>
                      <div className="image-overlay__bottom-row">
                        <span>
                          {image.imageTitle.length > 50
                            ? image.imageTitle.substring(0, 50) + '...'
                            : image.imageTitle}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </Masonry>
        </InfiniteScroll>
      ) : (
        <NoData />
      )}
      <Modal
        destroyOnClose={true}
        className="media-preview-modal video-modal-library"
        visible={previewModalVisible}
        onCancel={() => setPreviewModalVisible(false)}
        maskClosable>
        {allImages.map((image) => {
          if (image.id === imageForPreview) {
            return (
              <img className="media-preview-modal__asset" src={image.url} alt={image.imageTitle} />
            );
          }
        })}
      </Modal>
    </>
  );
};

export default StockImagesGrid;
