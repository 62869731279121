import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as FavoriteIcon } from '../../icons/favorite.svg';
import { ReactComponent as FavoriteFullIcon } from '../../icons/favorite-full.svg';
import { ReactComponent as ExpandIcon } from '../../icons/expand-media.svg';
import { ReactComponent as PlayIcon } from '../../icons/video-play-library.svg';
import { Player, BigPlayButton, ControlBar } from 'video-react';
import StockVideoForGrid from './StockVideoForGrid';
import Masonry from 'react-masonry-css';
import NoData from '../elements/NoData';

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#000' }} spin />;

const StockVideosGrid = ({
  allVideos,
  getAllVideos,
  searchKeyword,
  initSearchTerm,
  mediaOrientation,
  fetchMorePexel,
  pexelDataInfo
}) => {
  const [rerenderState, setRerenderState] = useState(0);
  const [searchTerm, setSearchTerm] = useState(initSearchTerm);
  const [videoHovered, setVideoHovered] = useState();
  const [videoForPreview, setVideoForPreview] = useState();
  const [previewModalVisible, setPreviewModalVisible] = useState(false);

  if (searchKeyword && searchKeyword !== searchTerm) {
    setSearchTerm(searchKeyword);
    setRerenderState((previous) => previous + 1);
  } else if (!searchKeyword && searchTerm !== initSearchTerm) {
    setSearchTerm(initSearchTerm);
    setRerenderState((previous) => previous + 1);
  }

  useEffect(() => {
    getAllVideos();
  }, [rerenderState, mediaOrientation]);

  const showPreviewModal = (videoId) => {
    setPreviewModalVisible(true);
    setVideoForPreview(videoId);
  };

  return (
    <>
      {allVideos?.length > 0 ? (
        <InfiniteScroll
          dataLength={allVideos.length}
          next={fetchMorePexel}
          hasMore={allVideos.length < pexelDataInfo.total_results}
          // loader={<Spin indicator={antIcon} />}
          scrollableTarget="scrollable-div">
          <Masonry
            breakpointCols={5}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
            id="scrollable-div"
            style={{ height: '80vh', overflowY: 'auto' }}>
            {allVideos.map((video, index) => {
              return (
                <StockVideoForGrid
                  videoHovered={videoHovered}
                  setVideoHovered={setVideoHovered}
                  video={video}
                  index={index}
                  showPreviewModal={showPreviewModal}
                  key={index}
                />
              );
            })}
          </Masonry>
        </InfiniteScroll>
      ) : (
        <NoData />
      )}
      <Modal
        destroyOnClose={true}
        className="media-preview-modal video-modal-library"
        visible={previewModalVisible}
        onCancel={() => setPreviewModalVisible(false)}
        maskClosable>
        {allVideos.map((video) => {
          if (video.id === videoForPreview) {
            return (
              <Player autoPlay className="media-preview-modal__asset" fluid={false} height={640}>
                <source src={video.link} />
                <BigPlayButton position="center" />
                <ControlBar disableCompletely={true} />
              </Player>
            );
          }
        })}
      </Modal>
    </>
  );
};

export default StockVideosGrid;
