import { Button, Form, InputNumber, Select } from 'antd';
import FormActions from './components/FormActions';

export default function ChangeInitialQooForm({
  currentEvent,
  setCurrentEvent,
  blockEventHandler,
  eventIndex,
  qoos
}) {
  const initialValues = {
    setInitialQooInQoolection:
      typeof currentEvent?.setInitialQooInQoolection === 'object'
        ? currentEvent?.setInitialQooInQoolection.title
        : currentEvent?.setInitialQooInQoolection
  };

  return (
    <Form onFinish={blockEventHandler} initialValues={initialValues}>
      <Form.Item
        className='block-event__form-item'
        name='setInitialQooInQoolection'
        rules={[
          {
            required: true,
            message: 'Please select a Qoo!'
          }
        ]}>
        <Select
          className='block-event__element'
          placeholder='Select Qoo...'
          value={
            currentEvent?.setInitialQooInQoolection?._id
              ? currentEvent?.setInitialQooInQoolection?._id
              : currentEvent?.setInitialQooInQoolection
          }
          onChange={(value) => {
            setCurrentEvent({
              ...currentEvent,
              setInitialQooInQoolection: value
            });
          }}>
          {qoos?.map((qoo) => (
            <Select.Option key={qoo?._id} value={qoo?._id}>
              {qoo?.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <InputNumber
        className='block-event__element'
        min={0}
        max={3600}
        type='number'
        placeholder='Initial Qoo Change Delay (in minutes)'
        value={currentEvent?.setInitialQooInQoolectionDelay}
        onChange={(value) =>
          setCurrentEvent({
            ...currentEvent,
            setInitialQooInQoolectionDelay: value
          })
        }
      />
      STOP CHANGE INITIAL QOO CONTROL
      <Select
        className='block-event__element'
        placeholder='Dont change if initial Qoo is...'
        value={currentEvent?.stopChangeInitalQooIfInitialQoo}
        onChange={(value) =>
          setCurrentEvent({
            ...currentEvent,
            stopChangeInitalQooIfInitialQoo: value
          })
        }>
        <Select.Option value={undefined}>No Stop Control</Select.Option>
        {qoos?.map((qoo) => (
          <Select.Option key={qoo?._id} value={qoo?._id}>
            {qoo.title}
          </Select.Option>
        ))}
      </Select>
      <FormActions eventIndex={eventIndex} setCurrentEvent={setCurrentEvent} />
    </Form>
  );
}
