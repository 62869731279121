import { Form, Switch } from 'antd';
import FormActions from './components/FormActions';

export default function StartStudyForm({
  currentEvent,
  setCurrentEvent,
  blockEventHandler,
  eventIndex
}) {
  return (
    <Form onFinish={blockEventHandler}>
      <div style={{ display: 'flex', gap: '0.625rem', marginBottom: '0.625rem' }}>
        <Switch
          checked={currentEvent?.notifySite}
          onChange={(checked) =>
            setCurrentEvent({
              ...currentEvent,
              notifySite: checked
            })
          }
        />
        <span>Notify Site</span>
      </div>
      <FormActions eventIndex={eventIndex} setCurrentEvent={setCurrentEvent} />
    </Form>
  );
}
