import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { Input, Select } from 'antd';
import { ReactComponent as Search } from '../../icons/search.svg';
import { UserContext } from '../../App';

const IconsLibrary = ({ selectIcon, iconFetchUrl }) => {
  const currentuser = useContext(UserContext);
  const [allIcons, setAllIcons] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [iconType, setIconType] = useState('filled');

  const getIcons = async () => {
    const route = `${iconFetchUrl}/icons`;

    const res = await Axios.get(route, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` }
    });

    setAllIcons(res.data);
  };

  useEffect(() => {
    getIcons();
  }, []);

  let filteredIcons = [];
  if (!selectedCategory) {
    filteredIcons = allIcons
      ?.flatMap((item) => item.icons)
      ?.filter((icon) =>
        icon.tags.some((tag) => tag.toLowerCase().includes(searchFilter.toLowerCase()))
      );
  } else {
    filteredIcons = allIcons
      ?.filter((item) => item.title.toLowerCase().includes(selectedCategory))
      ?.flatMap((item) => item.icons)
      ?.filter((icon) =>
        icon.tags.some((tag) => tag.toLowerCase().includes(searchFilter.toLowerCase()))
      );
  }

  return (
    <div className="icons-library">
      <Input
        prefix={<Search style={{ width: '13px', height: '13px' }} />}
        placeholder="Search Icons"
        value={searchFilter}
        onChange={(e) => setSearchFilter(e.target.value)}
      />
      <Select
        className="dropdown-small icons-library__categories"
        placeholder="Categories"
        value={selectedCategory}
        onChange={(value) => setSelectedCategory(value)}>
        <Select.Option value="" label="All Categories">
          All Categories
        </Select.Option>
        <Select.Option value="blood" label="Blood">
          Blood
        </Select.Option>
        <Select.Option value="body" label="Body">
          Body
        </Select.Option>
        <Select.Option value="conditions" label="Conditions">
          Conditions
        </Select.Option>
        <Select.Option value="devices" label="Devices">
          Devices
        </Select.Option>
        <Select.Option value="diagnostics" label="Diagnostics">
          Diagnostics
        </Select.Option>
        <Select.Option value="emotions" label="Emotions">
          Emotions
        </Select.Option>
        <Select.Option value="family" label="Family">
          Family
        </Select.Option>
        <Select.Option value="medications" label="Medications">
          Medications
        </Select.Option>
        <Select.Option value="objects" label="Objects">
          Objects
        </Select.Option>
        <Select.Option value="people" label="People">
          People
        </Select.Option>
        <Select.Option value="places" label="Places">
          Places
        </Select.Option>
        <Select.Option value="shapes" label="Shapes">
          Shapes
        </Select.Option>
        <Select.Option value="specialties" label="Specialties">
          Specialties
        </Select.Option>
        <Select.Option value="symbols" label="Symbols">
          Symbols
        </Select.Option>
        <Select.Option value="typography" label="Typography">
          Typography
        </Select.Option>
        <Select.Option value="vehicles" label="Vehicles">
          Vehicles
        </Select.Option>
      </Select>
      <div className="icons-library__icon-types">
        <div
          className={
            iconType === 'filled'
              ? 'choice-btn choice-btn--sm choice-btn--selected'
              : 'choice-btn choice-btn--sm'
          }
          onClick={() => setIconType('filled')}>
          Filled
        </div>
        <div
          className={
            iconType === 'outline'
              ? 'choice-btn choice-btn--sm choice-btn--selected'
              : 'choice-btn choice-btn--sm'
          }
          onClick={() => setIconType('outline')}>
          Outlined
        </div>
        <div
          className={
            iconType === 'negative'
              ? 'choice-btn choice-btn--sm choice-btn--selected'
              : 'choice-btn choice-btn--sm'
          }
          onClick={() => setIconType('negative')}>
          Inverse
        </div>
      </div>
      <div className="icons-library__icons-list">
        {filteredIcons.length > 0 &&
          filteredIcons.map((icon, index) => {
            const serverUrl = `${process.env.REACT_APP_PROD_URL}/icons/svg/`;
            return (
              <img
                key={`${iconType}/${icon.category}/${icon.id}`}
                src={`${serverUrl}${iconType}/${icon.category}/${icon.id}.svg`}
                width="40"
                height="40"
                loading="lazy"
                onClick={() =>
                  selectIcon('icon', {
                    iconSrc: `${serverUrl}${iconType}/${icon.category}/${icon.id}.svg`,
                    iconColor: {
                      hex: '#2A3A49',
                      rgb: { r: 42, g: 58, b: 73, a: 1 }
                    },
                    iconSize: 75
                  })
                }
              />
            );
          })}
      </div>
    </div>
  );
};

export default IconsLibrary;
