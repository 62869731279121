import { Select } from 'antd';
import { useState } from 'react';

const CollaboratorListEntry = ({
  user,
  collaborationData,
  qoolection,
  updateUserCollaboratorAccess
}) => {
  const [userData, setUserData] = useState({
    ...user,
    accessRight: collaborationData?.accessRight
  });

  const initials =
    userData?.firstName?.charAt(0)?.toUpperCase() + userData?.lastName?.charAt(0)?.toUpperCase();
  const name = `${userData?.firstName} ${userData?.lastName}`;

  // console.log('qoolection: ', qoolection);
  // console.log('user: ', user);

  return (
    <li className="collaborator">
      <div className="collaborator__details">
        <div className="collaborator__avatar">
          <span>{initials || '?'}</span>
        </div>
        <span className="collaborator__name">{name || 'User'}</span>
      </div>
      <Select
        className="collaborator__access dropdown-small"
        defaultValue={'none'}
        value={userData?.accessRight}
        onChange={(value) => {
          setUserData({ ...userData, accessRight: value });
          updateUserCollaboratorAccess(qoolection?._id, user?._id, value);
        }}>
        <Select.Option value={'none'}>None</Select.Option>
        <Select.Option value={'edit'}>Edit</Select.Option>
        <Select.Option value={'view'}>View</Select.Option>
      </Select>
    </li>
  );
};

export default CollaboratorListEntry;
