const buttonActionTypes = {
  externalLink: {
    key: 'external-link',
    title: 'External link',
  },
  qoo: {
    key: 'qoo',
    title: 'Qoo',
  },
  file: {
    key: 'file',
    title: 'File',
  },
  email: {
    key: 'email',
    title: 'Email',
  },
  phone: {
    key: 'phone',
    title: 'Phone',
  },
  location: {
    key: 'location',
    title: 'Location',
  },
  buttonValue: {
    key: 'buttonValue',
    title: 'Button Value (stored on click event)',
    placeholder: 'Examples: label-value, action_condition'
  },
  sendTextMessage: {
    title: 'Qoolection to send via SMS',
  },
  buttonSendPublishedQoolectionId: {
    key: 'buttonSendPublishedQoolectionId',
    title: 'Qoolection to send',
    placeholder: 'Select Qoolection to send'
  },
  buttonSendDelay: {
    key: 'buttonSendDelay',
    title: 'Send Delay (in minutes)'
  },
  buttonSendResendPeriod: {
    key: 'buttonSendResendPeriod',
    title: 'Resend Period (in minutes)'
  },
  buttonSendResendMaximumNumber: {
    key: 'buttonSendResendMaximumNumber',
    title: 'The maximum number of resends'
  }
};
export default Object.freeze(buttonActionTypes);