import { Modal, Input, Spin, Button } from 'antd';
import CollaboratorListEntry from './CollaboratorListEntry';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as SearchIcon } from '../../../icons/search.svg';
import { useEffect, useState } from 'react';
import NoData from '../../../components/elements/NoData';

const CollaboratorsModal = ({
  visible,
  users,
  collaborators,
  closeModal,
  qoolection,
  updateUserCollaboratorAccess
}) => {
  const [searchFilter, setSearchFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const filteredUsers = users?.data?.filter((user) => {
    const textMatch = searchFilter
      ? user?.firstName?.toLowerCase().includes(searchFilter.toLowerCase()) ||
        user?.lastName?.toLowerCase().includes(searchFilter.toLowerCase())
      : true;
    return textMatch;
  });

  useEffect(() => {
    filteredUsers?.length > 0 && setLoading(false);
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 5000);
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, [filteredUsers]);

  return (
    <Modal
      visible={visible}
      centered
      closable
      onCancel={closeModal}
      footer={null}
      bodyStyle={{ padding: '0', position: 'relative' }}
      destroyOnClose={true}
      width={560}
      height={625}>
      <div className='collaborators-modal'>
        <h2 className='collaborators-modal__title'>Invite Collaborators</h2>
        <p className='collaborators-modal__description'>
          In the list bellow, select and change the roles of the team members you want to invite to
          work with you on this Qoolection. We will notify them of their role change.
        </p>
        <div className='collaborators-modal__users'>
          <Input
            className='collaborators-modal__search table-header__search'
            style={{ width: '100%' }}
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            placeholder='Search Team Members...'
            prefix={<SearchIcon />}
          />
          <ul className='collaborators-modal__user-list'>
            {filteredUsers?.length > 0 && !loading ? (
              filteredUsers?.map((user) => {
                const collaborationData = collaborators?.find(
                  (collaborator) => collaborator?.user?._id === user?._id
                );

                return (
                  <CollaboratorListEntry
                    key={user._id}
                    user={user}
                    collaborationData={collaborationData}
                    qoolection={qoolection}
                    updateUserCollaboratorAccess={updateUserCollaboratorAccess}
                  />
                );
              })
            ) : loading ? (
              <LoadingOutlined spin style={{ color: '#4962E2' }} />
            ) : (
              <span>
                No team members available.
                <br />
                Contact us or try again later.
              </span>
            )}
          </ul>
        </div>
        <Button type='primary' style={{ marginTop: '1rem' }} onClick={closeModal}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default CollaboratorsModal;
