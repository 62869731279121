import React, { useContext, useState, useEffect } from 'react';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';
import { Table, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../icons/table-edit.svg';
import { ReactComponent as DeleteIcon } from '../../icons/table-delete.svg';
import NoData from '../elements/NoData';

const ReferencesTable = ({
  data,
  editPath,
  editHandler,
  deleteHandler,
  title,
  setReferenceToEdit
}) => {
  const user = useContext(UserContext);

  let searchInput;

  const columns = [
    {
      title: 'Abbreviated',
      dataIndex: 'title'
    },
    {
      title: 'Full Reference',
      dataIndex: 'reference'
    }
  ];

  columns.push({
    title: 'Actions',
    render: (text, record) => (
      <div
        style={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px'
        }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <EditIcon
            title={`Edit ${title.toLowerCase()}`}
            style={{ textDecoration: 'none', color: '#90989E' }}
            onClick={() => setReferenceToEdit(record)}
          />
        </div>

        <div style={{ margin: '2px', padding: '4px' }}>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement="left"
            title="Delete Reference?"
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText="Ok"
            cancelText="Cancel">
            <DeleteIcon
              title={`Delete ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: '#90989E', cursor: 'pointer' }}
            />
          </Popconfirm>
        </div>
      </div>
    )
  });

  const locale = {
    emptyText: <NoData />
  };

  return (
    <div className="table-manage">
      <Table
        size="middle"
        bordered
        dataSource={data}
        columns={columns}
        rowKey="_id"
        pagination={{
          defaultPageSize: 10,
          position: ['bottomCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true
        }}
        locale={locale}
      />
    </div>
  );
};

export default ReferencesTable;
