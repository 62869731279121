import React from 'react';
import { Button } from 'antd';
import StepNavigation from './StepNavigation';
import HeaderTab from './HeaderTab';


const NewThemeStep5 = ({ step, setStep, themeInPreview, dispatch, completedStep, setCompletedStep }) => {

  return (
    <div className="new-theme-builder__step-5">
      <h2 className="new-theme-builder__step-subtitle">
        <span>Step 5: </span>
        <span>Let's customize your Header. Please use the toggles below
          to customize the appearance of your Header.</span>
      </h2>
      <StepNavigation
        step={step}
        setStep={setStep}
        completedStep={completedStep}
      />
      <HeaderTab
        themeInPreview={themeInPreview}
        dispatch={dispatch}
      />
      <div className="new-theme-builder__bottom-row">
        <Button
          className='new-theme-builder__step-btn'
          onClick={() => setStep(4)}
        >
          Previous step
        </Button>
        <Button
          className='new-theme-builder__step-btn'
          type='primary'
          onClick={() => {
            if (completedStep === 4) {
              setCompletedStep(5);
            }
            setStep(6);
          }}
        >
          Next step
        </Button>
      </div>
    </div>
  );
};

export default NewThemeStep5;
