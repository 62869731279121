import React, { useState } from 'react';
import { Button, Modal, Tag } from 'antd';
import BlockDisplay from './BlockDisplay';

const BlockHistoryModal = ({ blockHistoryModalVisible, setBlockHistoryModalVisible, blockHistory, onHistoryRestore }) => {
  const [selectedVersionIndex, setSelectedVersionIndex] = useState(0);

  const selectedVersion = blockHistory[selectedVersionIndex]?.oldObject;
  const selectedVersionValid = blockHistory[selectedVersionIndex]?.valid;

  return (
    <Modal
      centered
      visible={blockHistoryModalVisible}
      onCancel={() => setBlockHistoryModalVisible(false)}
      footer={null}
      width={1100}
      bodyStyle={{
        padding: '0'
      }}
    >
      <div className="history-modal">
        <div className="history-modal__versions">
          <h2 className="history-modal__title">Version history</h2>
          <h4 className="history-modal__name">
            <span>File name:</span>
            <span> {selectedVersion?.name}</span>
          </h4>
          <div className="history-modal__versions-list">
            {blockHistory?.length > 0 && blockHistory.map((item, index) => {
              return (
                <div
                  key={index}
                  className={selectedVersionIndex === index ? "history-modal__version history-modal__version--active" : "history-modal__version"}
                  onClick={() => setSelectedVersionIndex(index)}
                >
                  <span>Version {item.oldObject.version.major}.{item.oldObject.version.minor}.{item.oldObject.version.patch}</span>
                  <span>{item.oldObject.updatedAt.split('T')[0].split('-')[1] + '/' + item.oldObject.updatedAt.split('T')[0].split('-')[2] + '/' + item.oldObject.updatedAt.split('T')[0].split('-')[0]}</span>
                  <span>
                    <Tag
                      className={
                        item.oldObject.status === 'draft' ? 'table-tag--status-draft'
                          : item.oldObject.status === 'waiting-approval' ? 'table-tag--status-waiting-approval'
                            : item.oldObject.status === 'in-approval' ? 'table-tag--status-in-approval'
                              : item.oldObject.status === 'approved' ? 'table-tag--status-approved'
                                : item.oldObject.status === 'change_required' ? 'table-tag--status-change_required'
                                  : item.oldObject.status === 'shared' ? 'table-tag--status-shared'
                                    : item.oldObject.status === 'published' ? 'table-tag--status-published'
                                      : item.oldObject.status === 'retired' ? 'table-tag--status-retired'
                                        : 'table-tag'
                      }
                    >{item.oldObject.status.charAt(0).toUpperCase() + item.oldObject.status.substring(1)}
                    </Tag>
                  </span>
                </div>
              );
            })}
          </div>
          <Button
            type='primary'
            block={true}
            disabled={!selectedVersionValid}
            onClick={() => onHistoryRestore(blockHistory[selectedVersionIndex]._id)}
            style={{ marginTop: 'auto' }}
          >
            Restore
          </Button>
        </div>
        <div className="history-modal__preview">
          <h3 className="qoolection-preview-modal__title">{selectedVersion?.version.major}.{selectedVersion?.version.minor}.{selectedVersion?.version.patch} Version</h3>
          <div className="theme-preview">
            {selectedVersion &&
              <BlockDisplay
                block={selectedVersion}
                width={308}
                height={411}
              />
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BlockHistoryModal;