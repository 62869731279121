import { Button, Modal, Form, Input, notification, Table } from 'antd';
import React, { useState } from 'react';
import { ReactComponent as EditIcon } from '../../icons/ph_note-pencil-light.svg';
import { useHistory } from 'react-router-dom';

const EventModal = ({ qoolection, visible, closeModal, eventErrors, setEventErrors, eventErrorList, setEventErrorList }) => {
  const [processStatus, setProcessStatus] = useState(null);
  const history = useHistory();

  const columns = [
    {
      title: 'Qoo',
      dataIndex: 'qoo',
      key: 'qoo'
    },
    {
      title: 'Button',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Edit',
      render: item => {
        return <EditIcon style={{cursor: 'pointer'}} onClick={() => {
          history.push(`/admin/edit-qoo/${item.qooId}`, {
            fromQoolection: {
              qoolection
            }
          })
        }}/>
      }
    }
  ]

  return (
    <>
      <Modal
        open={visible}
        centered
        closable
        onCancel={closeModal}
        footer={null}
        bodyStyle={{ padding: '0' }}
        destroyOnClose={true}
        width={900}
      >
        <div className="approval-modal">
          {!processStatus && // KAD BUDE FUNKCIONALNO 'STATUS' PROPERTY U QOOLECTION, DODATI ' qoolection.status === "draft" &&'
            <>
              {eventErrorList.length > 0 ?
                <>
                  <h1 className="approval-modal__title">Event errors</h1>
                  <p style={{marginBottom: '30px'}}>
                    We’ve detected some missing links within your button’s Events. Bellow is a list of errors to resolve with the descriptions of the erros. 
                  </p>
                  <Table
                    dataSource={eventErrorList}
                    columns={columns}
                    className="event-error-table"
                    pagination={false}
                    style={{marginBottom: "30px", width: "100%"}}
                    rowKey={item => `${item.qooId}_${item.qoo}_${item.name}_${item.event}`}
                  />
                </>
                : (
                  <p>Checking for errors...</p>
                )
              }
            </>
          }
        </div>
      </Modal>
    </>
  );
};

export default EventModal;