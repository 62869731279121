import React, { useState, useEffect } from "react";
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as FavoriteIcon } from '../../icons/favorite.svg';
import { ReactComponent as FavoriteFullIcon } from '../../icons/favorite-full.svg';
import { ReactComponent as ExpandIcon } from '../../icons/expand-media.svg';
import { ReactComponent as PlayIcon } from '../../icons/video-play-library.svg';
import { Player, BigPlayButton, ControlBar } from 'video-react';

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#000' }} spin />;

const StockVideoForGrid = ({videoHovered, setVideoHovered, video, index, showPreviewModal }) => {

  return (
    <>
    {videoHovered === true ?
      <Player
      autoPlay
      className="library-asset"
      onMouseEnter={() => setVideoHovered(index)}
      onMouseLeave={() => setVideoHovered(null)}
      key={index}
      >
        <source src={video.link} />
        {/* <BigPlayButton position="center" style={{display: 'none'}} /> */}
        <ControlBar disableCompletely={true} />
        {videoHovered === index &&
          <div className="image-overlay" style={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: '997'}}>
            <div className="image-overlay__top-row video-overlay__top-row">
              <span className="video-overlay__duration">{video.duration}</span>
              <div className="video-overlay__top-row-icons">
                <span className="image-overlay__icon">
                  {video.favorite ? <FavoriteFullIcon /> : <FavoriteIcon />}
                </span>
                <span
                onClick={() => showPreviewModal(video.id)} className="image-overlay__icon" style={{zIndex: '999'}}>
                  <ExpandIcon />
                </span>
              </div>
            </div>
            <div className="image-overlay__bottom-row">
              <span>{video.title.length > 50 ? video.title.substring(0, 50) + '...' : video.title}</span>
            </div>
          </div>
        }
        <div className="video-play-btn" onClick={() => showPreviewModal(video.id)}><PlayIcon /></div>
      </Player>
      :
      <Player
      className="library-asset"
      onMouseEnter={() => setVideoHovered(index)}
      onMouseLeave={() => setVideoHovered(null)}
      key={index}
      >
        <source src={video.link} />
        {/* <BigPlayButton position="center" style={{display: 'none'}} /> */}
        <ControlBar disableCompletely={true} />
        {videoHovered === index &&
          <div className="image-overlay" style={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: '997'}}>
            <div className="image-overlay__top-row video-overlay__top-row">
              <span className="video-overlay__duration">{video.duration}</span>
              <div className="video-overlay__top-row-icons">
                <span className="image-overlay__icon">
                  {video.favorite ? <FavoriteFullIcon /> : <FavoriteIcon />}
                </span>
                <span
                onClick={() => showPreviewModal(video.id)} className="image-overlay__icon" style={{zIndex: '999'}}>
                  <ExpandIcon />
                </span>
              </div>
            </div>
            <div className="image-overlay__bottom-row">
              <span>{video.title.length > 50 ? video.title.substring(0, 50) + '...' : video.title}</span>
            </div>
          </div>
        }
        <div className="video-play-btn" onClick={() => showPreviewModal(video.id)}><PlayIcon /></div>
      </Player>
      }
    </>
  )
}

export default StockVideoForGrid;