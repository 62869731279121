import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';
import { ReactComponent as RemoveIcon } from '../../icons/ph_trash-light.svg';
import DeleteModal from '../../components/elements/DeleteModal';
import ThemeDisplay from './ThemeDisplay';
import { Switch, notification } from 'antd';
import Axios from 'axios';
import { notificationError } from '../../config/notificationOptions';
import WarningModal from './WarningModal';
import {calculateHF} from "../../utils/calculateHF";

const ThemeCard = ({ theme, deleteTheme, activateTheme, SERVER_URL, token, activeThemes }) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  const handleDelete = async (themeId) => {
    try {
      const res = await Axios.get(`${SERVER_URL}/check-theme-content/${themeId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.data.okToDelete) {
        setModalVisible(true);
      } else {
        setWarningModalVisible(true);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration,
      });
    }
  };


  const noHeaderFooter = theme.headerFooter.header.noHeader && theme.headerFooter.footer.noFooter && theme.headerFooter.footer.noISI;

  const { headerHeight } = calculateHF(noHeaderFooter, theme.headerFooter)

  return (
    <>
      <div
        className="theme-card"
      >
        <div className="theme-card__activate">
          <span className="theme-card__activate-label">Activate Theme</span>
          <Switch
              checked={activeThemes.length > 0 && activeThemes[0].theme === theme._id}
              onChange={() => {
                if (activeThemes.length > 0 && activeThemes[0].theme !== theme._id || activeThemes.length === 0) {
                  activateTheme(theme._id);
                }
              }}
          />
        </div>
        <div
          className="theme-card__content"
          onMouseEnter={() => setOptionsVisible(true)}
          onMouseLeave={() => setOptionsVisible(false)}
        >
          <ThemeDisplay
            themeInPreview={theme}
            width={192}
          />

          <div style={{marginTop: `${headerHeight * 16}px`}} className={optionsVisible ? 'theme-card__options-wrapper theme-card__options-wrapper--visible' : 'theme-card__options-wrapper'}>
            {pathname === '/admin/themes' && <div
              className="blocks-grid__options-item"
              onClick={() => history.push(`/admin/edit-theme/${theme._id}`)}
            >
              <Edit />
              <span>Edit</span>
            </div> }
            {pathname === '/admin/themes' &&  <div
              className="blocks-grid__options-item"
              onClick={() => handleDelete(theme._id)}
            >
              <RemoveIcon />
              <span>Delete</span>
            </div>}
            {/* <div className="theme-card__options">
              <Link to={`/admin/edit-theme/${theme._id}`}>
                <Edit
                  className='theme-card__option-btn'
                />
              </Link>

              <Close
                className='theme-card__option-btn'
                onClick={() => handleDelete(theme._id)}
              />
            </div> */}
          </div>
        </div>
        <span className="theme-card__name">{theme.name}</span>

      </div>
      {modalVisible &&
        <DeleteModal
          visible={modalVisible}
          closeModal={() => setModalVisible(false)}
          title={theme.name}
          deleteCallback={() => deleteTheme(theme._id)}
        />
      }
      {warningModalVisible &&
        <WarningModal
          visible={warningModalVisible}
          closeModal={() => setWarningModalVisible(false)}
        />
      }
    </>
  );
};

export default ThemeCard;