export const SELECT_BLOCK = 'SELECT_BLOCK';
export const EDIT_BLOCK_FIELD = 'EDIT_BLOCK_FIELD';
export const EDIT_BLOCK_LAYOUT = 'EDIT_BLOCK_LAYOUT';
export const EDIT_BLOCK_PROPERTY = 'EDIT_BLOCK_PROPERTY';
export const EDIT_BLOCK_PROPERTY_WITHOUT_HISTORY = 'EDIT_BLOCK_PROPERTY_WITHOUT_HISTORY';
export const TEMPLATE_BLOCK_SELECTED = 'TEMPLATE_BLOCK_SELECTED';
export const UNDO = 'UNDO';
export const REDO = 'REDO';
export const ADD_BLOCK_TO_LAYOUT = 'ADD_BLOCK_TO_LAYOUT';
export const ADD_BLOCK_TO_LAYOUT_WITH_RESIZE = 'ADD_BLOCK_TO_LAYOUT_WITH_RESIZE';
export const EDIT_INNER_BLOCK_IN_LAYOUT = 'EDIT_INNER_BLOCK_IN_LAYOUT';
export const INNER_BLOCK_LAYOUT_CHANGE = 'INNER_BLOCK_LAYOUT_CHANGE';
export const INNER_BLOCK_LAYOUT_CHANGE_WITH_RESIZE = 'INNER_BLOCK_LAYOUT_CHANGE_WITH_RESIZE';
export const INNER_BLOCK_LAYOUT_CHANGE_WITH_RESIZE_WITHOUT_HISTORY = 'INNER_BLOCK_LAYOUT_CHANGE_WITH_RESIZE_WITHOUT_HISTORY';
export const REMOVE_BLOCK_FROM_LAYOUT = 'REMOVE_BLOCK_FROM_LAYOUT';
export const REMOVE_BLOCK_FROM_LAYOUT_WITH_RESIZE = 'REMOVE_BLOCK_FROM_LAYOUT_WITH_RESIZE';
export const TOGGLE_HIDE_SHOW_LAYER = 'TOGGLE_HIDE_SHOW_LAYER';
export const TOGGLE_HIDE_SHOW_BACKGROUND = 'TOGGLE_HIDE_SHOW_BACKGROUND';
export const MOVE_LAYER_UP = 'MOVE_LAYER_UP';
export const MOVE_LAYER_UP_WITH_ID = 'MOVE_LAYER_UP_WITH_ID';
export const MOVE_LAYER_DOWN = 'MOVE_LAYER_DOWN';
export const MOVE_LAYER_DOWN_WITH_ID = 'MOVE_LAYER_DOWN_WITH_ID';
export const SET_BLOCKS_LAYOUT = 'SET_BLOCKS_LAYOUT';
export const MOVE_LAYER_TO_FRONT = 'MOVE_LAYER_TO_FRONT';
export const MOVE_LAYER_TO_BOTTOM = 'MOVE_LAYER_TO_BOTTOM';
export const ADD_FORM_FIELD = 'ADD_FORM_FIELD';
export const REMOVE_FORM_FIELD = 'REMOVE_FORM_FIELD';
export const EDIT_FORM_FIELD = 'EDIT_FORM_FIELD';
export const ADD_SURVEY_OPTION = 'ADD_SURVEY_OPTION';
export const REMOVE_SURVEY_OPTION = 'REMOVE_SURVEY_OPTION';
export const EDIT_SURVEY_OPTION = 'EDIT_SURVEY_OPTION';
export const ADD_ENDNOTES = 'ADD_ENDNOTES';
export const REMOVE_ENDNOTE = 'REMOVE_ENDNOTE';
export const EDIT_ENDNOTE = 'EDIT_ENDNOTE';
