import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Tag, Tooltip } from 'antd';
import { EyeOutlined, CloudServerOutlined, QrcodeOutlined, LinkOutlined } from '@ant-design/icons';
import { ReactComponent as EyeIcon } from '../../icons/ph_eye-light.svg';
import { ReactComponent as UserIcon } from '../../icons/user.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as ClockIcon } from '../../icons/ph_clock-light.svg';
import { ReactComponent as EditIcon } from '../../icons/ph_note-pencil-light.svg';
import { ReactComponent as InfoIcon } from '../../icons/info-icon.svg';
import QoolectionCardDropdown from '../../pages/qoolections/QoolectionCardDropdown';
import CollaboratorsAction from '../../pages/qoolections/collaborators/CollaboratorsAction';
import NoData from '../elements/NoData';

const QoolectionsTable = ({
  user,
  data,
  deleteDataHandler,
  historyHandler,
  title,
  editPath,
  viewPath,
  filters,
  setFilters,
  setOffset,
  total,
  current,
  limit,
  qoolectionTypes,
  themes,
  audiences,
  categories,
  statuses,
  setQoolectionInModalPreview,
  setQoolectionForApproval,
  duplicateQoolection,
  changeStatusForLocalQoolection,
  sendQoolectionHandler,
  setQoolectionForSending,
  setQoolectionForExport,
  setCollaboratorsQoolection
}) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'title'
    },
    {
      title: 'Theme',
      dataIndex: 'theme',
      // filters: themes.data?.items && themes.data.items.length > 0 && themes.data.items.map(theme => {
      //   return {
      //     text: theme.name,
      //     value: theme._id
      //   };
      // }),
      // filteredValue: filters?.theme,
      render: (theme) => <span>{theme?.name && theme.name}</span>
    },
    {
      title: 'Type',
      dataIndex: 'type',
      // filters: qoolectionTypes.data?.items && qoolectionTypes.data.items.length > 0 && qoolectionTypes.data.items.map(qoolectionType => {
      //   return {
      //     text: qoolectionType.name,
      //     value: qoolectionType._id
      //   };
      // }),
      // filteredValue: filters?.type,
      // onFilter: (value, record) => record.qooType && record.qooType._id === value,
      render: (qoolectionType) => <span>{qoolectionType?.name && qoolectionType.name}</span>
      // ...getColumnSearchProps("title"),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      // filters: categories.data?.items && categories.data.items.length > 0 && categories.data.items.map(category => {
      //   return {
      //     text: category.name,
      //     value: category._id
      //   };
      // }),
      // // onFilter: (value, record) => record.category && record.category.some(c => c._id === value),
      // filteredValue: filters?.category,
      render: (category) => (
        <span>
          {category &&
            category.map((c) => (
              <Tag key={c._id} className="table-tag--category">
                {c.name}
              </Tag>
            ))}
        </span>
      )
    },
    {
      title: 'Audience',
      dataIndex: 'audience',
      // filters: audiences.data?.items && audiences.data.items.length > 0 && audiences.data.items.map(audience => {
      //   return {
      //     text: audience.name,
      //     value: audience._id
      //   };
      // }),
      // // onFilter: (value, record) => record.audience && record.audience.some(a => a._id === value),
      // filteredValue: filters?.audience,
      render: (audience) => (
        <span>
          {audience &&
            audience.map((a) => (
              <Tag key={a._id} className="table-tag--audience">
                {a.name}
              </Tag>
            ))}
        </span>
      )
    },
    {
      title: 'Status',
      // (
      //   <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      //     <span>Status</span>
      //     <Tooltip
      //       placement="bottom"
      //       color="#4962E2"
      //       title={
      //         <div>
      //           <p>Draft status...</p>
      //           <p>Waiting approval status...</p>
      //           <p>In-Approval status...</p>
      //           <p>Approved status...</p>
      //           <p>Published status...</p>
      //           <p>Retired status...</p>
      //           <p>Shared status...</p>
      //         </div>
      //       }>
      //       <InfoIcon />
      //     </Tooltip>
      //   </div>
      // ),
      dataIndex: 'status',
      // filters: statuses && statuses.map((status) => {
      //   return {
      //     text: status.charAt(0).toUpperCase() + status.substring(1),
      //     value: status,
      //   };
      // }),
      // filteredValue: filters?.status,
      render: (status) => (
        <span>
          {status && (
            <Tag
              className={
                status === 'draft'
                  ? 'table-tag--status-draft'
                  : status === 'waiting-approval'
                  ? 'table-tag--status-waiting-approval'
                  : status === 'in-approval'
                  ? 'table-tag--status-in-approval'
                  : status === 'approved'
                  ? 'table-tag--status-approved'
                  : status === 'change_required'
                  ? 'table-tag--status-change_required'
                  : status === 'shared'
                  ? 'table-tag--status-shared'
                  : status === 'published'
                  ? 'table-tag--status-published'
                  : status === 'retired'
                  ? 'table-tag--status-retired'
                  : 'table-tag'
              }>
              {status.charAt(0).toUpperCase() + status.substring(1)}
            </Tag>
          )}
        </span>
      )
    },
    {
      title: 'Version',
      dataIndex: 'version',
      render: (version) => (
        <span>
          <div>
            {version?.major || 0}.{version?.minor || 0}.{version?.patch || 0}
          </div>
        </span>
      )
    }
  ];

  columns.push({
    title: 'Action',
    render: (text, record) => {
      return (
        <div className="actions">
          <div className="actions__item" onClick={() => setQoolectionInModalPreview(record)}>
            <EyeIcon title="Preview Qoolection" />
          </div>

          <div className="actions__item">
            <CollaboratorsAction
              qoolection={record}
              setCollaboratorsQoolection={setCollaboratorsQoolection}
            />
          </div>

          {/* {record.status === 'published' && record.publishedToken && record.publishedToken !== null &&
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CloudServerOutlined
                title='Send qoolection'
                style={{ textDecoration: 'none', color: '#90989E' }}
                onClick={() => setQoolectionForSending(record)}
              />
            </div>
          </div>
        }

        {record.status === 'published' && record.publishedQRCode && record.publishedQRCode !== null &&
          record.publishedUrl && record.publishedUrl !== null &&
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Popover
                content={(<img alt='QR code' src={record.publishedQRCode} />)}
                title='QR code'
              >
                <QrcodeOutlined
                  title='QR code'
                  style={{ textDecoration: 'none', color: '#90989E' }}
                />
              </Popover>
            </div>
          </div>
        } */}

          {/* {record.status === 'published' && record.publishedQRCode && record.publishedQRCode !== null &&
          record.publishedUrl && record.publishedUrl !== null &&
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Popover
                content={(<p>{record.publishedUrl}</p>)}
                title='Link'
              >
                <LinkOutlined
                  title='Link'
                  style={{ textDecoration: 'none', color: '#90989E' }}
                />
              </Popover>
            </div>
          </div>
        } */}

          <div className="actions__item">
            <ClockIcon
              title="View Qoolection History"
              style={{ textDecoration: 'none' }}
              onClick={() => historyHandler(record._id)}
            />
          </div>

          {/* {record.status === 'draft' && */}
          <div onClick={() => user.handleRefreshToken()} className="actions__item">
            <Link
              to={editPath + record._id}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <EditIcon
                title="Edit Qoolection"
                style={{ textDecoration: 'none', color: '#90989E' }}
              />
            </Link>
          </div>
          {/* } */}

          {viewPath && (
            <div className="actions__item">
              <Link to={viewPath + record._id}>
                <EyeOutlined title="Preview Qoolection" style={{ textDecoration: 'none' }} />
              </Link>
            </div>
          )}

          {/* <div style={{ margin: '2px', padding: '4px' }}>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={`This will delete ${title.toLowerCase()}`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText='Ok'
            cancelText='Cancel'
          >
            <DeleteOutlined title={`Delete ${title.toLowerCase()}`} />
          </Popconfirm>
        </div> */}
          <div style={{ margin: '2px', padding: '4px', position: 'relative' }}>
            <QoolectionCardDropdown
              qoolection={record}
              inTable={true}
              setQoolectionForApproval={setQoolectionForApproval}
              setQoolectionForExport={setQoolectionForExport}
              changeStatusForLocalQoolection={changeStatusForLocalQoolection}
              duplicateQoolection={duplicateQoolection}
              deleteDataHandler={() => deleteDataHandler(record._id)}
            />
          </div>
        </div>
      );
    }
  });

  const locale = {
    emptyText: <NoData />
  };

  return (
    <div className="table-manage">
      <Table
        size="middle"
        bordered
        dataSource={data}
        columns={columns}
        rowKey="_id"
        pagination={{
          defaultPageSize: limit,
          position: ['bottomCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          // hideOnSinglePage: true,
          total,
          current
        }}
        onChange={(pagination, filters, sorter) => {
          setOffset(pagination.current - 1);
          setFilters((previous) => ({ ...previous, ...filters }));
        }}
        locale={locale}
      />
    </div>
  );
};

export default QoolectionsTable;
