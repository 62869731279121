import { Button, Form, Input, notification } from 'antd';
import React, { useState, useContext } from 'react';
import { SERVER_URL } from '../../config';
import Axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { UserContext } from '../../App';

let initialValues = {
  title: '',
  text: ''
};

const Footnote = ({
  token,
  library,
  setStateForRefetchingFootnotes,
  endnoteForEdit,
  setFootnoteToEdit,
  editCallback
}) => {
  const [form] = Form.useForm();
  const [footnoteText, setFootnoteText] = useState(endnoteForEdit?.text);
  const currentuser = useContext(UserContext);

  const onSubmit = async (values) => {
    const method = endnoteForEdit ? 'put' : 'post';
    const route = endnoteForEdit
      ? `${SERVER_URL}/footnotes/${endnoteForEdit._id}`
      : `${SERVER_URL}/footnotes`;

    values.team = currentuser.selectedTeam;
    values.study = currentuser.study;
    try {
      values.text = footnoteText;
      const res = await Axios[method](route, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` }
      });
      notification.success({
        message: `Footnote ${endnoteForEdit ? 'updated' : 'created'}`,
        placement: 'bottomRight'
      });
      form.resetFields();
      if (endnoteForEdit && !library) {
        editCallback(res.data.item, 'footnotes');
      } else {
        setFootnoteText();
        if (setStateForRefetchingFootnotes) {
          setStateForRefetchingFootnotes((prev) => prev + 1);
        }
      }
      // for library
      if (setFootnoteToEdit) {
        setFootnoteToEdit(null);
      }
    } catch (error) {
      notification.error({
        message: 'Problem with creating footnote',
        placement: 'bottomRight'
      });
    }
  };

  let tinyMCEFontFormatsString = '';

  const styles = {
    formPadding: {
      padding: library === true ? '10px 15px' : '0'
    }
  };

  if (endnoteForEdit) {
    initialValues = {
      ...initialValues,
      ...endnoteForEdit
    };
  }

  return (
    <div style={styles.formPadding}>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={(values) => onSubmit(values)}
        layout="vertical"
        preserve={false}>
        <Form.Item
          name="title"
          rules={[{ required: true, message: 'Please enter Footnote Title!' }]}>
          <Input placeholder="Footnote Title" />
        </Form.Item>
        <div>
          <Editor
            init={{
              inline: true,
              toolbar_persist: true,
              fixed_toolbar_container: '#icon-bar',
              selector: 'textarea',
              menubar: false,
              plugins: [
                'importcss code link charmap lists wordcount charmap emoticons paste lists advlist autoresize help charmap directionality'
              ],
              toolbar: 'bold italic underline superscript subscript',
              toolbar_mode: 'floating',
              min_height: 100,
              autoresize_bottom_margin: 0,
              statusbar: false,
              contextmenu: '',
              content_style: `
                .mce-content-body {
                  min-height: 100px;
                  border: 1px solid #d5dfe8;
                  border-radius: 3px;
                  font-family: 'Noto Sans', sans-serif;
                  font-style: normal;
                  font-weight: normal;
                  padding: 4px 11px;
                  background-color: #fff;
                }
                .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
                  font-family: "Noto Sans", sans-serif;
                  font-style: normal;
                  font-weight: normal;
                  color: #AEB8C0;
                  left: 11px !important;
                }
                `,
              font_formats: tinyMCEFontFormatsString
                ? tinyMCEFontFormatsString
                : 'Arial Black=arial black,avant garde; Courier New=courier new,courier; Lato Black=lato; Roboto=roboto;',
              placeholder: 'Footnote Text',
              quickbars_insert_toolbar: false,
              fontsize_formats: 'small=0.75em normal=1em large=1.5em extra-large=2em',
              browser_spellcheck: true
            }}
            onInit={(event, editor) => {
              editor.on('submit', (e) => {
                editor.setContent('');
              });
              editor.on('ExecCommand', (e) => {
                if (e.command === 'mceApplyTextcolor') {
                  document.querySelector(
                    'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                  ).style.background = e.value;
                }
                if (e.command === 'mceRemoveTextcolor') {
                  document.querySelector(
                    'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                  ).style.background = '#2A3A49';
                }
              });
              editor.formatter.formatChanged(
                'forecolor',
                function (isNew, args) {
                  if (args.node.style.color) {
                    document.querySelector(
                      'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                    ).style.background = args.node.style.color;
                  } else {
                    document.querySelector(
                      'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                    ).style.background = '#2A3A49';
                  }
                },
                true
              );
            }}
            apiKey="j1zjwgyada24ar7u2uwpe3dxyhx6pduzftls0ex2z129yg13"
            value={footnoteText}
            onEditorChange={(value, editor) => {
              setFootnoteText(value);
            }}
          />
          <div id="icon-bar"></div>
        </div>
        <Button htmlType="submit" type="primary" style={{ width: '100%' }}>
          Save Footnote
        </Button>
      </Form>
    </div>
  );
};

export default Footnote;
