import React, { useContext } from 'react';
import { UserContext } from '../../App';
import { Table, Popconfirm } from 'antd';
import { ReactComponent as EditIcon } from '../../icons/table-edit.svg';
import { ReactComponent as DeleteIcon } from '../../icons/table-delete.svg';
import { ReactComponent as CreateEPROQoolectionIcon } from '../../icons/epro-qoolection.svg';
import { ReactComponent as MoreInfoIcon } from '../../icons/more-info.svg';
import { ReactComponent as LockIcon } from '../../icons/lock-icon.svg';
import NoData from '../elements/NoData';

const QuestioneersTable = ({
  data,
  deleteHandler,
  title,
  setQuestioneerForEdit,
  setQuestioneerForLock,
  getQuestioneerDetailsHandler,
  createEproQoolectionHandler
}) => {
  const user = useContext(UserContext);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Domain',
      dataIndex: 'domain'
    },
    {
      title: 'Source',
      dataIndex: 'questoneerSource',
      render: (text, record) => (
        <div>{record.questoneerSource ? record.questoneerSource : 'Internal'}</div>
      )
    },
    {
      title: 'Measurement System',
      dataIndex: 'measurement_system'
    },
    {
      title: 'Measure Type',
      dataIndex: 'assesment_type'
    },
    {
      title: 'Category',
      dataIndex: 'category'
    }
  ];

  columns.push({
    title: 'Actions',
    render: (text, record) => (
      <div
        style={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px'
        }}>
        {!record.locked && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <EditIcon
              title={`Edit ePRO`}
              style={{ textDecoration: 'none', color: '#90989E' }}
              onClick={() => setQuestioneerForEdit(record)}
            />
          </div>
        )}
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement="left"
            title={`${record.locked ? 'Unlock' : 'Lock'} ePRO ?`}
            onConfirm={() => setQuestioneerForLock(record._id)}
            okText="Ok"
            cancelText="Cancel">
            {record.locked ? (
              <LockIcon
                title={`Unlock ePRO`}
                style={{
                  fontSize: '19px',
                  textDecoration: 'none',
                  color: '#FB5757',
                  cursor: 'pointer'
                }}
              />
            ) : (
              <LockIcon
                title={`Lock ePRO`}
                style={{
                  fontSize: '19px',
                  textDecoration: 'none',
                  color: '#90989E',
                  cursor: 'pointer'
                }}
              />
            )}
          </Popconfirm>
        </div>
        {!record.locked && (
          <div style={{ margin: '2px', padding: '4px' }}>
            <Popconfirm
              disabled={record._id === user.data.id}
              placement="left"
              title="Delete ePRO?"
              onConfirm={() => deleteHandler(record._id)}
              okText="Ok"
              cancelText="Cancel">
              <DeleteIcon
                title={`Delete ePRO`}
                style={{
                  textDecoration: 'none',
                  color: '#90989E',
                  cursor: 'pointer'
                }}
              />
            </Popconfirm>
          </div>
        )}
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <MoreInfoIcon
            title="Show details"
            style={{ textDecoration: 'none', color: '#90989E', fontSize: '20px' }}
            onClick={() => getQuestioneerDetailsHandler(record._id)}
          />
        </div>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <CreateEPROQoolectionIcon
            title="Create ePRO Qoolection"
            style={{ textDecoration: 'none', color: '#90989E', fontSize: '20px' }}
            onClick={() => createEproQoolectionHandler(record._id)}
          />
        </div>
      </div>
    )
  });

  const locale = {
    emptyText: <NoData />
  };

  return (
    <div className="table-manage">
      <Table
        size="middle"
        bordered
        dataSource={data}
        columns={columns}
        rowKey="_id"
        pagination={{
          defaultPageSize: 10,
          position: ['bottomCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true
        }}
        locale={locale}
      />
    </div>
  );
};

export default QuestioneersTable;
