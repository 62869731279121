import React, { useState, useContext } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Input, Select } from 'antd';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
// import StockVideosTable from '../../components/tables/StockVideosTable';
import StockVideosGrid from '../../components/grids/StockVideosGrid';
import CustomSwitch from '../../components/elements/CustomSwitch';
import { SERVER_URL } from '../../config';

const StockVideos = () => {
  // const [listView, setListView] = useState(true);
  const [pexelDataInfo, setPexelDataInfo] = useState({ isLoading: false });
  const currentuser = useContext(UserContext);
  const token = currentuser.data.token;
  const history = useHistory();
  const [mediaOrientation, setMediaOrientation] = useState();
  const [mediaApiSource, setMediaApiSource] = useState();
  // const [mediaTags, setMediaTags] = useState('allTags');
  // const [mediaColors, setMediaColors] = useState('allColors');
  const [allVideos, setAllVideos] = useState([]);
  const initSearchTerm = 'medical doctor pharma medicine patient medical';
  const [searchTerm, setSearchTerm] = useState(initSearchTerm);

  const getAllVideos = async () => {
    const pexelRes = await Axios.get(
      `${SERVER_URL}/pexels_videos_searched?searchString=${searchTerm}&orientation=${mediaOrientation}&library=true`,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` }
      }
    );

    let mappedPexel = [];

    if (pexelRes && pexelRes.data && pexelRes.data.videos.length > 0) {
      mappedPexel = pexelRes.data.videos.map((video) => {
        const videoTitle = video.url.split('/')[4].split('-').slice(0, -1).join(' ');
        const sdVideoFile = video.video_files.filter((videoFile) => videoFile.quality === 'sd');
        let videoDuration = video.duration;
        if (videoDuration > 60) {
          const durationAsArray = String(videoDuration / 60).split('.');
          // FORMAT HOURS
          if (durationAsArray[0] < 10) {
            durationAsArray[0] = '0' + durationAsArray[0];
          }

          // FORMAT MINUTES
          if (videoDuration % 60 === 0) {
            durationAsArray.push('0');
          }
          durationAsArray[1] = Number('0.' + durationAsArray[1]);
          durationAsArray[1] = Math.floor(durationAsArray[1] * 60);
          if (durationAsArray[1] < 10) {
            durationAsArray[1] = '0' + durationAsArray[1];
          }
          videoDuration = durationAsArray.join(':');
        } else {
          if (videoDuration < 10) {
            videoDuration = '00:0' + videoDuration;
          } else {
            videoDuration = '00:' + videoDuration;
          }
        }
        return {
          id: video.id,
          url: video.url,
          link: video.video_files.filter((video) => video.quality === 'sd')[0].link,
          thumbnail: video.image,
          duration: videoDuration,
          title: videoTitle.charAt(0).toUpperCase() + videoTitle.substring(1),
          format: video.video_files[0].file_type.split('/')[1].toUpperCase(),
          orientation:
            video.width / video.height > 1
              ? 'Landscape'
              : video.width / video.height < 1
              ? 'Portrait'
              : video.width / video.height === 1
              ? 'Square'
              : '',
          width: sdVideoFile[0].width,
          height: sdVideoFile[0].height,
          tags: video.tags.join(', ')
        };
      });
    }
    setAllVideos(mappedPexel);
    setPexelDataInfo({
      isLoading: false,
      next_page: pexelRes.data.next_page,
      total_results: pexelRes.data.total_results
    });
  };

  const fetchMorePexel = async () => {
    if (pexelDataInfo.isLoading) return;

    setPexelDataInfo((previous) => ({ ...previous, isLoading: true }));

    const queryString =
      pexelDataInfo && pexelDataInfo.next_page
        ? `?${pexelDataInfo.next_page.split('?')[1]}&searchString=${searchTerm}`
        : '?searchString=${searchTerm}';
    const pexelRes = await Axios.get(`${SERVER_URL}/pexels_videos_searched${queryString}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` }
    });

    // console.log('pexelRes: ', pexelRes);

    let mappedPexel = [];
    if (pexelRes && pexelRes.data && pexelRes.data.videos.length > 0) {
      mappedPexel = pexelRes.data.videos.map((video) => {
        const videoTitle = video.url.split('/')[4].split('-').slice(0, -1).join(' ');
        const sdVideoFile = video.video_files.filter((videoFile) => videoFile.quality === 'sd');
        let videoDuration = video.duration;
        if (videoDuration > 60) {
          const durationAsArray = String(videoDuration / 60).split('.');
          // FORMAT HOURS
          if (durationAsArray[0] < 10) {
            durationAsArray[0] = '0' + durationAsArray[0];
          }

          // FORMAT MINUTES
          if (videoDuration % 60 === 0) {
            durationAsArray.push('0');
          }
          durationAsArray[1] = Number('0.' + durationAsArray[1]);
          durationAsArray[1] = Math.floor(durationAsArray[1] * 60);
          if (durationAsArray[1] < 10) {
            durationAsArray[1] = '0' + durationAsArray[1];
          }
          videoDuration = durationAsArray.join(':');
        } else {
          if (videoDuration < 10) {
            videoDuration = '00:0' + videoDuration;
          } else {
            videoDuration = '00:' + videoDuration;
          }
        }
        return {
          id: video.id,
          url: video.url,
          link: video.video_files.filter((video) => video.quality === 'sd')[0].link,
          thumbnail: video.image,
          duration: videoDuration,
          title: videoTitle.charAt(0).toUpperCase() + videoTitle.substring(1),
          format: video.video_files[0].file_type.split('/')[1].toUpperCase(),
          orientation:
            video.width / video.height > 1
              ? 'Landscape'
              : video.width / video.height < 1
              ? 'Portrait'
              : video.width / video.height === 1
              ? 'Square'
              : '',
          width: sdVideoFile[0].width,
          height: sdVideoFile[0].height,
          tags: video.tags.join(', ')
        };
      });
    }

    setAllVideos((previous) => [...previous, ...mappedPexel]);
    setPexelDataInfo({
      isLoading: false,
      next_page: pexelRes.data.next_page,
      total_results: pexelRes.data.total_results
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title'
    },
    {
      title: 'Format',
      dataIndex: 'format'
    },
    {
      title: 'Duration',
      dataIndex: 'duration'
    },
    {
      title: 'Orientation',
      dataIndex: 'orientation'
    }
    // {
    //   title: "Tags",
    //   dataIndex: "tags"
    // }
  ];

  if (!searchTerm) {
    setSearchTerm(initSearchTerm);
  }

  return (
    <>
      <div className="table-header">
        {/* <CustomSwitch listView={listView} setListView={setListView} /> */}
        <Input
          className="table-header__search"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          placeholder="Search Videos"
          prefix={<SearchIcon />}
        />
        <Select
          placeholder="Source"
          className="dropdown-small table-header__select"
          value={mediaApiSource}
          onChange={(value) => setMediaApiSource(value)}>
          <Select.Option value="qclinical" className="fieldType">
            QClinical
          </Select.Option>
          <Select.Option value="pexels" className="fieldType">
            Pexels
          </Select.Option>
          <Select.Option value="shutterstock" className="fieldType">
            Shutterstock
          </Select.Option>
          <Select.Option value="istock" className="fieldType">
            iStock
          </Select.Option>
          <Select.Option value="pixabay" className="fieldType">
            Pixabay
          </Select.Option>
        </Select>
        {/* <Select
          placeholder='All Tags'
          className='dropdown-small table-header__select'
          value={mediaTags}
          onChange={(value) => setMediaTags(value)}
        >
          <Select.Option value='allTags' className='fieldType'>
            All Tags
          </Select.Option>
          <Select.Option value='health' className='fieldType'>
            Health
          </Select.Option>
          <Select.Option value='people' className='fieldType'>
            People
          </Select.Option>
          <Select.Option value='products' className='fieldType'>
            Products
          </Select.Option>
          <Select.Option value='wellness' className='fieldType'>
            Wellness
          </Select.Option>
          <Select.Option value='foodanddrink' className='fieldType'>
            Food &amp; drink
          </Select.Option>
          <Select.Option value='industry' className='fieldType'>
            Industry
          </Select.Option>
          <Select.Option value='pharmacy' className='fieldType'>
            Pharmacy
          </Select.Option>
        </Select> */}
        <Select
          placeholder="Orientation"
          className="dropdown-small table-header__select"
          value={mediaOrientation}
          onChange={(value) => setMediaOrientation(value)}
          allowClear>
          <Select.Option value="portrait" className="fieldType">
            Portrait
          </Select.Option>
          <Select.Option value="landscape" className="fieldType">
            Landscape
          </Select.Option>
          <Select.Option value="square" className="fieldType">
            Square
          </Select.Option>
        </Select>
        {/* <Select
          placeholder='All Colors'
          className='dropdown-small table-header__select'
          value={mediaColors}
          onChange={(value) => setMediaColors(value)}
        >
          <Select.Option value='allColors' className='fieldType'>
            All Colors
          </Select.Option>
        </Select> */}
      </div>
      {/* {listView === true &&
        <StockVideosTable
          allVideos={allVideos}
          getAllVideos={getAllVideos}
          columns={columns}
          title='Videos'
          searchKeyword={searchTerm}
          initSearchTerm={initSearchTerm}
        />
      } */}
      {/* {listView === false && */}
      <StockVideosGrid
        allVideos={allVideos}
        getAllVideos={getAllVideos}
        searchKeyword={searchTerm}
        initSearchTerm={initSearchTerm}
        mediaOrientation={mediaOrientation}
        fetchMorePexel={fetchMorePexel}
        pexelDataInfo={pexelDataInfo}
      />
      {/* } */}
    </>
  );
};

export default StockVideos;
