import React from 'react';
import { Card, message, notification, Upload } from 'antd';
import { SERVER_URL } from '../../config';
import { notificationError } from '../../config/notificationOptions';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const IconsForm = ({ token, selectHandler, imageSavePath, imageType }) => {
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card title='Add icons' bordered={false}>
          <Dragger
            multiple
            action={`${SERVER_URL}/upload-file`}
            accept='.svg'
            name='file'
            getValueFromEvent={normFile}
            headers={{
              type: imageType,
              //resize: true,
              // resizemob: false,
              //height: imageHeight,
              Authorization: `Bearer ${token}`,
              uri: imageSavePath,
              // urimob: imageSavePath + 'mob/',
            }}
            beforeUpload={async (file) => {
              const isAllowedFormat = ['image/svg+xml'].includes(file.type);
              if (!isAllowedFormat) {
                notification.error({
                  message: 'You can only upload SVG file!',
                  placement: 'bottomRight',
                  icon: notificationError.icon,
                  className: notificationError.className,
                  duration: notificationError.duration,
                });
              }
              const isAllowedSize = file.size / 1024 / 1024 < 5;
              if (!isAllowedSize) {
                notification.error({
                  message: 'Image must smaller than 5MB!',
                  placement: 'bottomRight',
                  icon: notificationError.icon,
                  className: notificationError.className,
                  duration: notificationError.duration,
                });
              }
              return isAllowedFormat && isAllowedSize;
            }}
            onChange={({ file }) => {
              if (file.status === 'done') {
                if (file.response && file.response.file) {
                  message.success(`${file.response.file.name} file uploaded successfully.`);
                  let uploaded = sessionStorage.getItem('uploaded');
                  if (uploaded) {
                    sessionStorage.setItem('uploaded', `${uploaded},${file.response.image._id}`);
                  } else {
                    sessionStorage.setItem('uploaded', file.response.image._id);
                  }
                  // if (selectHandler) {
                  //   selectHandler(file.response.image._id);
                  // }
                }
              } else if (file.status === 'error') {
                //console.log(file.response.message);
                message.error(`${file.name} file upload failed.`);
                message.error(`${file.response.message} file upload failed.`);
              }
            }}
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>Click or drag file to this area to upload</p>
            <p className='ant-upload-hint'>
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
            </p>
          </Dragger>
        </Card>
      </div>
    </div>
  );
};

export default IconsForm;
