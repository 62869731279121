import { useContext } from 'react';
import { Tooltip } from 'antd';
import { ReactComponent as EditIcon } from '../../../icons/ph_note-pencil-light.svg';
import { ReactComponent as EyeIcon } from '../../../icons/ph_eye-light.svg';
import { ReactComponent as UserIcon } from '../../../icons/user.svg';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { UserContext } from '../../../App';

const CollaboratorsAction = ({ qoolection, setCollaboratorsQoolection }) => {
  const currentuser = useContext(UserContext);
  // console.log('currentuser: ', currentuser);
  // console.log('qoolection: ', qoolection);
  const isOwner = qoolection?.owner === currentuser?.data?.id;

  return (
    <>
      {qoolection?.collaborators?.length > 0 ? (
        <Tooltip
          placement="bottom"
          overlayClassName={'actions__tooltip'}
          color="#4962E2"
          title={
            <>
              {isOwner ? (
                qoolection.collaborators.map((collaborator) => {
                  return (
                    <div key={collaborator?.user?._id} className="actions__tooltip-line">
                      <span>{`${collaborator?.user?.firstName} ${collaborator?.user?.lastName}`}</span>
                      {collaborator?.accessRight === 'view' ? (
                        <EyeIcon />
                      ) : collaborator?.accessRight === 'edit' ? (
                        <EditIcon />
                      ) : null}
                    </div>
                  );
                })
              ) : (
                <div>You're added as a Collaborator</div>
              )}
            </>
          }>
          <div
            className={isOwner ? 'collaborators-action' : 'flex-modifier'}
            onClick={() => (isOwner ? setCollaboratorsQoolection(qoolection) : null)}>
            <UserIcon className={!isOwner && 'icon-modifier'} />
            {isOwner && (
              <span className="collaborators-action__exponent">
                {qoolection?.collaborators?.length}
              </span>
            )}
          </div>
        </Tooltip>
      ) : (
        <Tooltip
          placement="bottom"
          color="#4962E2"
          title={<div>{isOwner ? 'Add Collaborators' : "You're added as a Collaborator"}</div>}>
          <div
            className={isOwner ? 'collaborators-action' : 'flex-modifier'}
            onClick={() => (isOwner ? setCollaboratorsQoolection(qoolection) : null)}>
            <UserIcon className={!isOwner && 'icon-modifier'} />
            {isOwner && (
              <span className="collaborators-action__exponent">
                <PlusIcon />
              </span>
            )}
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default CollaboratorsAction;
