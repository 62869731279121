import React, { useState } from 'react';
import QooDisplay from '../qoos/QooDisplay';
import { ReactComponent as EditIcon } from '../../icons/ph_note-pencil-light.svg';
import { ReactComponent as RemoveIcon } from '../../icons/ph_trash-light.svg';
import { ReactComponent as LaunchIcon } from '../../icons/ph_rocket-launch-light.svg';
import { ReactComponent as CopyIcon } from '../../icons/ph_copy-light.svg';
import { ReactComponent as CheckIcon } from '../../icons/ph_check-circle-light.svg';
import { ReactComponent as ReturnIcon } from '../../icons/ph_arrow-bend-up-left-light.svg';
import { ReactComponent as PublishIcon } from '../../icons/ph_paper-plane-tilt-light.svg';
import { ReactComponent as RetireIcon } from '../../icons/ph_books-light.svg';
import { ReactComponent as UnpublishIcon } from '../../icons/ph_x-square-light.svg';
import { ReactComponent as ReviveIcon } from '../../icons/ph_arrow-counter-clockwise-light.svg';
import { ReactComponent as EyeIcon } from '../../icons/ph_eye-light.svg';
import { ReactComponent as ClockIcon } from '../../icons/ph_clock-light.svg';
import DeleteModal from '../../components/elements/DeleteModal';

const QoolectionCard = ({
  qoolection,
  editDataHandler,
  deleteDataHandler,
  historyHandler,
  previewHandler,
  setQoolectionForApproval,
  duplicateQoolection,
  changeStatusForLocalQoolection,
  width,
  height,
  homebase,
}) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  return (
    <div className='qoolections-grid__card-container'>
      <div
        className='qoolections-grid__card'
        onMouseEnter={() => setOptionsVisible(true)}
        onMouseLeave={() => setOptionsVisible(false)}
      >
        <div
          className={
            optionsVisible
              ? 'qoolections-grid__card-content qoolections-grid__card-content--border'
              : 'qoolections-grid__card-content'
          }
        >
          <QooDisplay
            qooInPreview={
              qoolection.qoos.length > 0
                ? qoolection.qoos[0]
                : {
                    theme: qoolection.theme,
                    showHeader: true,
                    showISI: true,
                    showFooter: true,
                  }
            }
            width={width || 180}
            height={height || 342}
          />
          {!homebase ? (
            <div
              className={
                optionsVisible
                  ? 'qoolections-grid__options-wrapper qoolections-grid__options-wrapper--visible'
                  : 'qoolections-grid__options-wrapper'
              }
            >
              <div
                className='blocks-grid__options-item'
                onClick={previewHandler}
              >
                <EyeIcon />
                <span>Preview</span>
              </div>
              <div
                className='blocks-grid__options-item'
                onClick={historyHandler}
              >
                <ClockIcon />
                <span>Version History</span>
              </div>
              {qoolection.status === 'draft' && (
                <div
                  className='blocks-grid__options-item'
                  onClick={editDataHandler}
                >
                  <EditIcon />
                  <span>Edit Qoolection</span>
                </div>
              )}
              {qoolection.status === 'draft' && (
                <div
                  className='blocks-grid__options-item'
                  onClick={() => setQoolectionForApproval(qoolection)}
                >
                  <LaunchIcon />
                  <span>Approval</span>
                </div>
              )}

              {qoolection.status === 'in-approval' &&
                qoolection.approvalProcess &&
                qoolection.approvalProcess === 'local' && (
                  <div
                    className='blocks-grid__options-item'
                    onClick={() =>
                      changeStatusForLocalQoolection(qoolection._id, 'approved')
                    }
                  >
                    <CheckIcon />
                    <span>Approve</span>
                  </div>
                )}

              {qoolection.status === 'approved' && (
                <div
                  className='blocks-grid__options-item'
                  onClick={() =>
                    changeStatusForLocalQoolection(qoolection._id, 'published')
                  }
                >
                  <PublishIcon />
                  <span>Publish</span>
                </div>
              )}

              {qoolection.status === 'published' && (
                <div
                  className='blocks-grid__options-item'
                  onClick={() =>
                    changeStatusForLocalQoolection(qoolection._id, 'unpublish')
                  }
                >
                  <UnpublishIcon />
                  <span>Unpublish</span>
                </div>
              )}

              {(qoolection.status === 'approved' ||
                qoolection.status === 'published') && (
                <div className='blocks-grid__options-item'>
                  <RetireIcon />
                  <span>Retire</span>
                </div>
              )}

              {qoolection.status === 'in-approval' &&
                qoolection.approvalProcess &&
                qoolection.approvalProcess === 'local' && (
                  <div
                    className='blocks-grid__options-item'
                    onClick={() =>
                      changeStatusForLocalQoolection(qoolection._id, 'draft')
                    }
                  >
                    <ReturnIcon />
                    <span>Return to draft</span>
                  </div>
                )}

              {qoolection.status === 'retired' && (
                <div className='blocks-grid__options-item'>
                  <ReviveIcon />
                  <span>Revive</span>
                </div>
              )}

              <div
                className='blocks-grid__options-item'
                onClick={() => duplicateQoolection(qoolection)}
              >
                <CopyIcon />
                <span>Duplicate</span>
              </div>

              {qoolection.status === 'draft' && (
                <div
                  className='blocks-grid__options-item'
                  onClick={() => setDeleteModalVisible(true)}
                >
                  <RemoveIcon />
                  <span>Delete</span>
                </div>
              )}
            </div>
          ) : null}
        </div>
        {!homebase && (
          <span className='qoolections-grid__title'>{qoolection.title}</span>
        )}
      </div>

      <div
        style={{
          width: width || '180px',
          height: height || '342px',
          position: 'absolute',
          top: '3px',
          left: '3px',
          border: '1px solid #D5DFE8',
          backgroundColor: '#F4F6F7',
          zIndex: '1',
        }}
      ></div>
      <div
        style={{
          width: width || '180px',
          height: height || '342px',
          position: 'absolute',
          top: '6px',
          left: '6px',
          border: '1px solid #D5DFE8',
          backgroundColor: '#F4F6F7',
          zIndex: '0',
        }}
      ></div>
      {deleteModalVisible && (
        <DeleteModal
          visible={deleteModalVisible}
          closeModal={() => setDeleteModalVisible(false)}
          title={'Qoolection'}
          deleteCallback={() => {
            deleteDataHandler();
            setDeleteModalVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default QoolectionCard;
