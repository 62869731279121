import React from 'react';
import { Dropdown } from 'antd';
import { ReactComponent as InfoIcon } from '../../icons/fluent_clipboard-task-list-ltr-20-regular.svg';
import { ReactComponent as BrowseIcon } from '../../icons/block.svg';
import { BLOCK_TYPES_ICONS } from '../../config';
import { ReactComponent as ReferenceIcon } from '../../icons/references.svg';
import { ReactComponent as FootnoteIcon } from '../../icons/Footnotes.svg';
import { ReactComponent as AbbreviationIcon } from '../../icons/Abbreviations.svg';
import { ReactComponent as DownArrow } from '../../icons/ph_caret-down-light.svg';
import { ReactComponent as BackgroundIcon } from '../../icons/background.svg';

const BlockTypesList = ({
  blockTypes,
  isNew,
  selectedBlockType,
  setSelectedBlockType,
  qooBuilder,
  setSelectedBlock,
  blockOrQooInPreview,
  endnoteCallback,
  highlightedBlock,
  currentuser
}) => {
  // u WF nema block type 'table' pa ga ovde izbacujem jer nema dovoljno prostora za dodatnu ikonicu
  const eProBlockType = blockTypes.filter((blockType) => blockType.name === 'ePro');
  if (blockTypes)
    blockTypes = blockTypes.filter(
      (blockType) =>
        blockType.name !== 'table' &&
        blockType.name !== 'form' &&
        blockType.name !== 'survey' &&
        blockType.name !== 'ePro'
    );

  return (
    <div className="block-types-list">
      <div
        className={
          selectedBlockType && selectedBlockType.name === 'browse'
            ? 'block-types-list__btn block-types-list__btn--active'
            : 'block-types-list__btn'
        }
        onClick={() => {
          setSelectedBlock(null);
          setSelectedBlockType({ name: 'browse' });
        }}>
        <div className="block-types-list__icon">
          <BrowseIcon />
        </div>
        <span className="block-types-list__name">Browse</span>
      </div>
      {/*qooBuilder &&
        <div
          className={selectedBlockType && selectedBlockType.name === 'my-blocks' ? 'block-types-list__btn block-types-list__btn--active' : 'block-types-list__btn'}
          onClick={() => setSelectedBlockType({ name: 'my-blocks' })}
        >
          <div className="block-types-list__icon">
            <Blocks />
          </div>
          <span className='block-types-list__name'>Blocks</span>
        </div>
      */}

      {/* {qooBuilder &&
        <>
          <div
            className={isNew || !selectedBlockType ? 'block-types-list__btn block-types-list__btn--active' : 'block-types-list__btn'}
            onClick={() => setSelectedBlockType(null)}
          >
            <div className="block-types-list__icon">
              <InfoOutlined />
            </div>
            <span className='block-types-list__name'>Info</span>
          </div>
          <div
            className={selectedBlockType && selectedBlockType.name === 'general' ? 'block-types-list__btn block-types-list__btn--active' : 'block-types-list__btn'}
            onClick={() => setSelectedBlockType({ name: 'general' })}
          >
            <div className="block-types-list__icon">
              <Qoo />
            </div>
            <span className='block-types-list__name'>General</span>
          </div>
        </>
      } */}
      {blockTypes &&
        blockTypes.length > 0 &&
        blockTypes.map((blockType) => {
          const isActive = selectedBlockType && blockType._id === selectedBlockType._id;
          return (
            <div
              key={blockType._id}
              className={
                isNew
                  ? 'block-types-list__btn block-types-list__btn--disabled'
                  : isActive
                  ? 'block-types-list__btn block-types-list__btn--active'
                  : 'block-types-list__btn'
              }
              onClick={
                isNew
                  ? null
                  : () => {
                      setSelectedBlock(null);
                      setSelectedBlockType({
                        name: blockType.name,
                        _id: blockType._id
                      });
                    }
              }>
              <div className="block-types-list__icon">
                {blockType.name in BLOCK_TYPES_ICONS
                  ? BLOCK_TYPES_ICONS[`${blockType.name}`].icon
                  : BLOCK_TYPES_ICONS['noicon'].icon}
              </div>
              <span className="block-types-list__name">{blockType.name}</span>
            </div>
          );
        })}
      {/* OVDE JE BILO SAMO ZA BLOK  GENERAL BTN. PROVERITI DA LI ZA QOO IDE ISTO ILI TREBA 'MORE' SA '...' */}
      {/*<div
        className={selectedBlockType && selectedBlockType.name === 'general' ? 'block-types-list__btn block-types-list__btn--active' : 'block-types-list__btn'}
        onClick={() => {
          setSelectedBlock(blockOrQooInPreview._id);
          setSelectedBlockType({ name: 'general' });
        }}
      >
        <div className="block-types-list__icon">
          <GeneralIcon />
        </div>
        <span className='block-types-list__name'>General</span>
      </div>*/}
      <div
        className={
          selectedBlockType && selectedBlockType.name === 'general'
            ? 'block-types-list__btn block-types-list__btn--active'
            : 'block-types-list__btn'
        }
        onClick={() => {
          setSelectedBlock(null);
          setSelectedBlockType({ name: 'general' });
        }}>
        <div className="block-types-list__icon">
          <BackgroundIcon />
        </div>
        <span className="block-types-list__name">Background</span>
      </div>
      <div
        className={
          selectedBlockType && selectedBlockType.name === 'info'
            ? 'block-types-list__btn block-types-list__btn--active'
            : 'block-types-list__btn'
        }
        onClick={() => {
          setSelectedBlock(null);
          setSelectedBlockType({ name: 'info' });
        }}>
        <div className="block-types-list__icon">
          <InfoIcon />
        </div>
        <span className="block-types-list__name">Info</span>
      </div>
      {currentuser?.data.role.includes('superadmin') && (
        <div
          className={
            selectedBlockType && selectedBlockType.name === 'ePro'
              ? 'block-types-list__btn block-types-list__btn--active'
              : 'block-types-list__btn'
          }
          onClick={
            isNew
              ? null
              : () => {
                  setSelectedBlock(null);
                  setSelectedBlockType({ name: 'ePro', _id: eProBlockType[0]?._id });
                }
          }>
          <div className="block-types-list__icon">{BLOCK_TYPES_ICONS['ePro'].icon}</div>
          <span className="block-types-list__name">ePro</span>
        </div>
      )}

      <div className="block-types-list__divider"></div>
      <Dropdown
        trigger={['click']}
        disabled={
          !highlightedBlock ||
          highlightedBlock.block.isMulti ||
          highlightedBlock.block.blockType.name !== 'text'
        }
        overlay={
          <div className="block-types-list__endnotes-overlay">
            <div
              className="block-types-list__endnotes-overlay-item"
              onClick={() => endnoteCallback('reference')}>
              <ReferenceIcon />
              <span>Add reference</span>
            </div>
            <div
              className="block-types-list__endnotes-overlay-item"
              onClick={() => endnoteCallback('footnote')}>
              <FootnoteIcon />
              <span>Add footnote</span>
            </div>
            <div
              className="block-types-list__endnotes-overlay-item"
              onClick={() => endnoteCallback('abbreviation')}>
              <AbbreviationIcon />
              <span>Add abbreviation</span>
            </div>
          </div>
        }>
        <div
          className={
            !highlightedBlock ||
            highlightedBlock.block.isMulti ||
            highlightedBlock.block.blockType.name !== 'text'
              ? 'block-types-list__btn block-types-list__btn--disabled'
              : 'block-types-list__btn'
          }>
          <div className="block-types-list__icon">
            <ReferenceIcon />
            <DownArrow className="block-types-list__icon-additional" />
          </div>
          <span className="block-types-list__name">Endnotes</span>
        </div>
      </Dropdown>

      {/* {qooBuilder &&
        <div
          className={selectedBlockType && selectedBlockType.name === 'my-blocks' ? 'block-types-list__btn block-types-list__btn--active' : 'block-types-list__btn'}
          onClick={() => setSelectedBlockType({ name: 'my-blocks' })}
        >
          <div className="block-types-list__icon">
            <Block />
          </div>
          <span className='block-types-list__name'>My Blocks</span>
        </div>
      } */}
    </div>
  );
};

export default BlockTypesList;
