import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../App';
import { Table, Popconfirm } from 'antd';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../icons/table-edit.svg';
import { ReactComponent as DeleteIcon } from '../../icons/table-delete.svg';
import { ReactComponent as DuplicateIcon } from '../../icons/ph_copy-light.svg';
import NoData from '../elements/NoData';

const RemindersTable = ({
  data,
  deleteHandler,
  title,
  setEditReminderModal,
  setReminderForEdit
  // lockQuestionHandler,
  // setQuestionForLock,
  // setQuestionForDuplicate,
}) => {
  const user = useContext(UserContext);

  const columns = [
    {
      title: 'Reminder',
      dataIndex: 'reminder'
    },
    {
      title: 'Description',
      dataIndex: 'description'
    }
  ];

  columns.push({
    title: 'Actions',
    render: (text, record) => (
      <div
        style={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px'
        }}>
        {!record.locked && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <EditIcon
              title={`Edit ${title}`}
              style={{ textDecoration: 'none', color: '#90989E' }}
              onClick={() => {
                setEditReminderModal(true);
                setReminderForEdit(record);
              }}
            />
          </div>
        )}
        {/* <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={`${record.locked ? 'Unlock' : 'Lock'} Question?`}
            onConfirm={() => {
              setQuestionForLock(record._id);
            }}
            okText='Ok'
            cancelText='Cancel'
          >
            {record.locked ? (
              <LockOutlined
                title={`Unlock ${title}`}
                style={{
                  fontSize: '19px',
                  textDecoration: 'none',
                  color: '#FB5757',
                  cursor: 'pointer',
                }}
              />
            ) : (
              <UnlockOutlined
                title={`Lock ${title}`}
                style={{
                  fontSize: '19px',
                  textDecoration: 'none',
                  color: '#90989E',
                  cursor: 'pointer',
                }}
              />
            )}
          </Popconfirm>
        </div> */}
        {!record.locked && (
          <div style={{ margin: '2px', padding: '4px' }}>
            <Popconfirm
              disabled={record._id === user.data.id}
              placement="left"
              title="Delete Reminders?"
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText="Ok"
              cancelText="Cancel">
              <DeleteIcon
                title={`Delete ${title}`}
                style={{
                  textDecoration: 'none',
                  color: '#90989E',
                  cursor: 'pointer'
                }}
              />
            </Popconfirm>
          </div>
        )}
        {/* <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <DuplicateIcon
            title={`Duplicate ${title}`}
            style={{ textDecoration: 'none', color: '#90989E' }}
            onClick={() => {
              setQuestionForDuplicate(record._id);
            }}
          />
        </div> */}
      </div>
    )
  });

  const locale = {
    emptyText: <NoData />
  };

  return (
    <div className="table-manage">
      <Table
        size="middle"
        bordered
        dataSource={data}
        columns={columns}
        rowKey="_id"
        pagination={{
          defaultPageSize: 10,
          position: ['bottomCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true
        }}
        locale={locale}
      />
    </div>
  );
};

export default RemindersTable;
