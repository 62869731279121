import { ReactComponent as PlusIcon } from './ph_plus.svg';
import { ReactComponent as MinusIcon } from './ph_minus.svg';
import { ReactComponent as UpCaret } from './ph_caret-up-light.svg';
import { ReactComponent as DownCaret } from './ph_caret-down-light.svg';

export const ISIIcons = [
  {
    open: <PlusIcon />,
    close: <MinusIcon />
  },
  {
    open: <UpCaret />,
    close: <DownCaret />
  }
];