import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { UserContext } from '../../App';
import Axios from 'axios';
import { notification, Input, Modal } from 'antd';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { SERVER_URL } from '../../config';
import QuestionsTable from '../../components/tables/QuestionsTable';
import QuestionsForm from '../../components/forms/QuestionsForm';

const TABLE_COLUMN_KEYS = ['_id', '__v'];

const Questions = ({ questions, setStateForRefetchingQuestions, questioneers = [] }) => {
  const currentuser = useContext(UserContext);
  const [searchFilter, setSearchFilter] = useState('');
  const [questionForEdit, setQuestionForEdit] = useState();
  const [questionForLock, setQuestionForLock] = useState();
  const [questionForDuplicate, setQuestionForDuplicate] = useState();
  const [refetch, setRefetch] = useState(false);
  // const [data, setData] = useState();
  const questionInitialState = {
    client: currentuser.data.client?._id,
    study: currentuser.study,
    team: currentuser.selectedTeam,
    locked: false
  };
  const [data, setData] = useState(questionInitialState);

  useEffect(() => {
    setData(questions?.data?.items.filter((question) => question._id === questionForEdit)[0]);
  }, [questions, questionForEdit]);

  useEffect(() => {
    setData(questions?.data?.items.filter((question) => question._id === questionForLock)[0]);
    setRefetch((prev) => !prev);
  }, [questionForLock]);

  useEffect(() => {
    setData(questions?.data?.items.filter((question) => question._id === questionForDuplicate)[0]);
    setRefetch((prev) => !prev);
  }, [questionForDuplicate]);

  useEffect(() => {
    if (questionForLock) {
      lockQuestionHandler();
    }
    if (questionForDuplicate) {
      duplicateQuestionHandler();
    }
  }, [refetch]);

  let filteredQuestions;

  if (questions?.data?.items?.length > 0) {
    filteredQuestions = questions.data.items.filter((question) => {
      const textMatch = searchFilter
        ? question.question.toLowerCase().includes(searchFilter.toLowerCase())
        : true;
      return textMatch;
    });
  }

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/questions/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      setStateForRefetchingQuestions((prev) => prev + 1);
      // history.push('/admin');
      // history.push('/admin/library');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const lockQuestionHandler = async () => {
    try {
      const questionCopy = await _.cloneDeep(data);
      questionCopy.locked = !questionCopy.locked;
      await Axios.put(`${SERVER_URL}/questions/${questionForLock}`, questionCopy, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item updated.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      setStateForRefetchingQuestions((prev) => prev + 1);
      setQuestionForLock(null);
    } catch (err) {
      notification.error({
        message: 'Problem with update. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  let columnKeys;
  if (questions.data && questions.data.items && questions.data.items.length > 0) {
    const keys = Object.keys(questions.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (questions.data && questions.data.items && questions.data.items.length > 0) {
    tableData = questions.data.items;
  }
  if (filteredQuestions && filteredQuestions.length > 0) {
    tableData = filteredQuestions;
  }

  const duplicateQuestionHandler = async () => {
    try {
      await Axios.post(`${SERVER_URL}/questions-duplicate`, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item duplicated.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      setStateForRefetchingQuestions((prev) => prev + 1);
      setQuestionForDuplicate(null);
    } catch (err) {
      console.log('error: ', err);
      notification.error({
        message: 'Problem with duplicate. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  return (
    <>
      <div className="table-header">
        <Input
          className="table-header__search"
          placeholder="Search Questions"
          prefix={<SearchIcon />}
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
      </div>
      <QuestionsTable
        data={tableData}
        questioneers={questioneers}
        deleteHandler={deleteDataHandler}
        lockQuestionHandler={lockQuestionHandler}
        setQuestionForEdit={setQuestionForEdit}
        setQuestionForLock={setQuestionForLock}
        setQuestionForDuplicate={setQuestionForDuplicate}
        columnKeys={columnKeys}
        title="Question"
      />
      {questionForEdit && (
        <Modal
          title="Edit Question"
          open={questionForEdit}
          footer={null}
          onCancel={() => setQuestionForEdit(null)}>
          <QuestionsForm
            isNew={false}
            questioneers={questioneers}
            questionForEdit={questionForEdit}
            setQuestionForEdit={setQuestionForEdit}
            setStateForRefetchingQuestions={setStateForRefetchingQuestions}
          />
        </Modal>
      )}
    </>
  );
};

export default Questions;
