import React from 'react';
import { ISIIcons } from '../../icons/ISIIcons';

const QooFooter = ({
  footerHeight,
  footer,
  gridWidth,
  gridRowHeight,
  dontShowISI,
  dontShowFooter,
  ISISizeInQoo,
  fadeOutHF,
  inQooBuilder
}) => {
  if (!footerHeight) return null;

  return (
    <div
      style={{
        width: '100%',
        height: footer.previewISIFooter ? '100%' : `${footerHeight * gridRowHeight}px`,
        position: 'absolute',
        left: '0',
        bottom: '0',
        fontSize: gridWidth ? `${(gridWidth / 384) * 16}px` : '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        boxShadow: footer.bottomFooterDropshadow ? '0px -4px 4px rgba(0, 0, 0, 0.1)' : '',
        opacity: fadeOutHF ? '0.3' : '1',
        zIndex: 1001
      }}>
      {!footer.noISI && !dontShowISI && (
        <div
          style={{
            flexGrow: '1',
            display: 'flex',
            flexDirection: 'column',
            zIndex: '1',
            overflow: footer.previewISIFooter ? 'auto' : 'hidden'
          }}>
          <div
            style={{
              flexGrow: footer.previewISIFooter ? '0' : '1',
              flexShrink: '0',
              flexBasis: `${1.3 * gridRowHeight}px`,
              backgroundColor: footer.ISITitleBackgroundColor
                ? footer.ISITitleBackgroundColor.hex
                : '#BDC7CF',
              border: footer.ISITitleBorderColor
                ? `1px solid ${footer.ISITitleBorderColor.hex}`
                : '',
              fontSize: '1em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '.625em'
            }}>
            <span
              style={{
                flex: '1',
                textAlign: footer.ISITitleTextAlign,
                color: footer.ISITitleTextColor ? footer.ISITitleTextColor.hex : '',
                fontSize:
                  footer.ISITitleFontSize === 'S'
                    ? '0.75em'
                    : footer.ISITitleFontSize === 'L'
                    ? '1.25em'
                    : '1em',
                fontFamily: footer.ISITitleFont ? footer.ISITitleFont.name : '',
                fontStyle: footer.ISITitleFont ? footer.ISITitleFont.style : '',
                fontWeight: footer.ISITitleFont ? footer.ISITitleFont.weight : ''
              }}>
              {footer.ISITitleTextContent}
            </span>
            <div
              style={{
                color: footer.ISITitleTextColor ? footer.ISITitleTextColor.hex : '',
                marginLeft: '1em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <div className="ISI-title-icon">
                {footer.previewISIFooter
                  ? ISIIcons[`${footer.ISIIcon}`].close
                  : ISIIcons[`${footer.ISIIcon}`].open}
              </div>
            </div>
          </div>
          {((footer.shownISI !== 'title-only' && ISISizeInQoo !== 'S') ||
            ((footer.shownISI === 'title-only' || ISISizeInQoo === 'S') &&
              footer.previewISIFooter)) && (
            <div
              className="theme-preview-footer"
              style={{
                flexGrow: '1',
                flexBasis: footer.previewISIFooter
                  ? '100%'
                  : footer.shownISI === 'more-text' || ISISizeInQoo === 'L'
                  ? `${4 * gridRowHeight}px`
                  : `${2 * gridRowHeight}px`,
                background: footer.ISIFooterBackgroundColor
                  ? `rgba(${footer.ISIFooterBackgroundColor.rgb.r}, ${footer.ISIFooterBackgroundColor.rgb.g}, ${footer.ISIFooterBackgroundColor.rgb.b}, ${footer.ISIFooterBackgroundColor.rgb.a})`
                  : '#F4F6F7',
                border: footer.ISIFooterBorderColor
                  ? `1px solid ${footer.ISIFooterBorderColor.hex}`
                  : '',
                padding: '0.625em 0.625em 0 0.625em',
                overflow: footer.previewISIFooter ? 'auto' : 'hidden',
                textAlign: footer.ISIFooterTextAlign,
                color: footer.ISIFooterTextColor ? footer.ISIFooterTextColor.hex : '',
                fontSize: '1em',
                fontFamily: footer.ISIFooterFont ? footer.ISIFooterFont.name : '',
                fontStyle: footer.ISIFooterFont ? footer.ISIFooterFont.style : '',
                fontWeight: footer.ISIFooterFont ? footer.ISIFooterFont.weight : ''
              }}
              dangerouslySetInnerHTML={{ __html: footer.ISIFooterTextContent }}></div>
          )}
        </div>
      )}

      {!footer.noFooter && !footer.previewISIFooter && !dontShowFooter && (
        <div
          className="h-f-create-new__qoo-bottom-footer"
          style={{
            maxHeight:
              footer.noISI || dontShowISI ? `${2.6 * gridRowHeight}px` : `${1.3 * gridRowHeight}px`,
            flex: `1 0 ${1.3 * gridRowHeight}px`,
            backgroundColor: footer.bottomFooterBackgroundColor
              ? `rgba(${footer.bottomFooterBackgroundColor.rgb.r}, ${footer.bottomFooterBackgroundColor.rgb.g}, ${footer.bottomFooterBackgroundColor.rgb.b}, ${footer.bottomFooterBackgroundColor.rgb.a})`
              : '#BDC7CF',
            border: footer.bottomFooterBorderColor
              ? `1px solid ${footer.bottomFooterBorderColor.hex}`
              : '',
            display: 'flex',
            gap: '1px',
            color: footer.bottomFooterTextColor ? footer.bottomFooterTextColor.hex : '',
            fontSize:
              footer.bottomFooterFontSize === 'S'
                ? '0.75em'
                : footer.bottomFooterFontSize === 'L'
                ? '1.25em'
                : '1em',
            fontFamily: footer.bottomFooterFont ? footer.bottomFooterFont.name : '',
            fontStyle: footer.bottomFooterFont ? footer.bottomFooterFont.style : '',
            fontWeight: footer.bottomFooterFont ? footer.bottomFooterFont.weight : '',
            textAlign: 'center'
          }}>
          {footer.bottomFooterColumnsNum && footer.bottomFooterColumnsNum > 0 && (
            <div
              style={{
                flex: '1',
                padding: '0 0.625em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: footer.bottomFooterTextAlign,
                borderRight: footer.bottomFooterColumnsNum > 1 ? '0.0625em solid' : ''
              }}>
              {(footer.bottomFooterColumn1LinkTo === 'web link' ||
                footer.bottomFooterColumn1LinkTo === 'pdf') &&
              footer.bottomFooterColumn1Link ? (
                <a
                  style={{
                    color: footer.bottomFooterTextColor?.hex
                      ? footer.bottomFooterTextColor?.hex
                      : '#333333',
                    cursor: 'pointer'
                  }}
                  href={footer.bottomFooterColumn1Link}
                  target="_blank"
                  rel="noreferrer">
                  {footer.bottomFooterColumn1TextContent}
                </a>
              ) : (
                footer.bottomFooterColumn1TextContent
              )}
            </div>
          )}
          {footer.bottomFooterColumnsNum && footer.bottomFooterColumnsNum > 1 && (
            <div
              style={{
                flex: '1',
                padding: '0 0.625em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: footer.bottomFooterTextAlign,
                borderRight: footer.bottomFooterColumnsNum > 2 ? '0.0625em solid' : ''
              }}>
              {(footer.bottomFooterColumn2LinkTo === 'web link' ||
                footer.bottomFooterColumn2LinkTo === 'pdf') &&
              footer.bottomFooterColumn2Link ? (
                <a
                  style={{
                    color: footer.bottomFooterTextColor?.hex
                      ? footer.bottomFooterTextColor?.hex
                      : '#333333',
                    cursor: 'pointer'
                  }}
                  href={footer.bottomFooterColumn2Link}
                  target="_blank"
                  rel="noreferrer">
                  {footer.bottomFooterColumn2TextContent}
                </a>
              ) : (
                footer.bottomFooterColumn2TextContent
              )}
            </div>
          )}
          {footer.bottomFooterColumnsNum && footer.bottomFooterColumnsNum > 2 && (
            <div
              style={{
                flex: '1',
                padding: '0 0.625em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: footer.bottomFooterTextAlign
              }}>
              {(footer.bottomFooterColumn3LinkTo === 'web link' ||
                footer.bottomFooterColumn3LinkTo === 'pdf') &&
              footer.bottomFooterColumn3Link ? (
                <a
                  style={{
                    color: footer.bottomFooterTextColor?.hex
                      ? footer.bottomFooterTextColor?.hex
                      : '#333333',
                    cursor: 'pointer'
                  }}
                  href={footer.bottomFooterColumn3Link}
                  target="_blank"
                  rel="noreferrer">
                  {footer.bottomFooterColumn3TextContent}
                </a>
              ) : (
                footer.bottomFooterColumn3TextContent
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QooFooter;
