import React, { useEffect, useState } from 'react';
import { Upload, notification, message, Input, Button, Spin } from 'antd';
import Axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as UploadIcon } from '../../icons/ph_upload-simple-light.svg';
import { notificationError } from '../../config/notificationOptions';
import { LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#000' }} spin />;

const BgImagesList = ({ token, SERVER_URL, callback }) => {
  const initSearchTerm = 'doctor pharma medicine patient medical';

  const [rerenderState, setRerenderState] = useState(0);
  const [pexelDataInfo, setPexelDataInfo] = useState({ isLoading: false });
  const [allImages, setAllImages] = useState([]);
  const [searchTerm, setSearchTerm] = useState(initSearchTerm);

  const getAllImages = async () => {
    const imagesRes = await Axios.get(`${SERVER_URL}/images?search=${searchTerm}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });

    const pexelRes = await Axios.get(`${SERVER_URL}/pexels_images_searched?searchString=${searchTerm}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });

    let mappedImages = [];
    let mappedPexel = [];

    if (imagesRes && imagesRes.data && imagesRes.data.length > 0) {
      mappedImages = imagesRes.data.map((image) => ({
        id: image._id,
        url: image.mdUrl,
        smallUrl: image.smUrl,
      }));
    }
    if (pexelRes && pexelRes.data && pexelRes.data.photos.length > 0) {
      mappedPexel = pexelRes.data.photos.map((image) => ({
        id: image.id,
        url: image.src.medium,
        smallUrl: image.src.small,
      }));
    }
    setAllImages([...mappedImages, ...mappedPexel]);
    setPexelDataInfo({
      isLoading: false,
      next_page: pexelRes.data.next_page,
      total_results: pexelRes.data.total_results,
    });
  };

  useEffect(() => {
    getAllImages();
  }, [rerenderState]);

  const fetchMorePexel = async () => {
    if (pexelDataInfo.isLoading) return;

    setPexelDataInfo((previous) => ({ ...previous, isLoading: true }));

    const queryString =
      pexelDataInfo && pexelDataInfo.next_page
        ? `?${pexelDataInfo.next_page.split('?')[1]}&searchString=${searchTerm}`
        : '?searchString=${searchTerm}';
    const pexelRes = await Axios.get(`${SERVER_URL}/pexels_images_searched${queryString}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });

    let mappedPexel = [];
    if (pexelRes && pexelRes.data && pexelRes.data.photos.length > 0) {
      mappedPexel = pexelRes.data.photos.map((image) => ({
        id: String(image.id),
        url: image.src.medium,
        smallUrl: image.src.small,
      }));
    }

    setAllImages((previous) => [...previous, ...mappedPexel]);
    setPexelDataInfo({
      isLoading: false,
      next_page: pexelRes.data.next_page,
      total_results: pexelRes.data.total_results,
    });
  };

  const handleChangeDebounced = _.debounce(async (e) => {
    if (!e.target.value) {
      setSearchTerm(initSearchTerm);
    } else {
      setSearchTerm(e.target.value);
    }
    setRerenderState((previous) => previous + 1);
  }, 500);

  const handleChange = (e) => {
    e.persist();
    handleChangeDebounced(e);
  };

  return (
    <div className='bg-images'>
      <div className='bg-images__search'>
        <Input
          placeholder='Search Images'
          onChange={(e) => handleChange(e)}
          allowClear={true}
        />
      </div>
      <InfiniteScroll
        dataLength={allImages.length}
        next={fetchMorePexel}
        hasMore={allImages.length < pexelDataInfo.total_results}
        loader={<Spin indicator={antIcon} />}
        height={300}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '7px',
        }}
      >
        <Upload
          action={`${SERVER_URL}/upload-image`}
          accept='.jpg,.jpeg,.png,.bmp,.svg'
          name='image'
          // getValueFromEvent={normFile}
          showUploadList={false}
          headers={{
            type: 'common',
            resize: true,
            // resizemob: false,
            height: 500,
            Authorization: `Bearer ${token}`,
            uri: 'public/images/',
            // urimob: imageSavePath + 'mob/',
          }}
          beforeUpload={async (file) => {
            const isAllowedFormat = ['image/jpeg', 'image/png'].includes(file.type);
            if (!isAllowedFormat) {
              notification.error({
                message: 'You can only upload JPG or PNG file!',
                placement: 'bottomRight',
                icon: notificationError.icon,
                className: notificationError.className,
                duration: notificationError.duration,
              });
            }
            const isAllowedSize = file.size / 1024 / 1024 < 5;
            if (!isAllowedSize) {
              notification.error({
                message: 'Image must smaller than 5MB!',
                placement: 'bottomRight',
                icon: notificationError.icon,
                className: notificationError.className,
                duration: notificationError.duration,
              });
            }
            return isAllowedFormat && isAllowedSize;
          }}
          onChange={({ file }) => {
            if (file.status === 'done') {
              if (file.response && file.response.file) {
                message.success(`${file.response.file.name} file uploaded successfully.`);
                let uploaded = sessionStorage.getItem('uploaded');
                if (uploaded) {
                  sessionStorage.setItem('uploaded', `${uploaded},${file.response.image._id}`);
                } else {
                  sessionStorage.setItem('uploaded', file.response.image._id);
                }

                setRerenderState((previous) => previous + 1);
              }
            } else if (file.status === 'error') {
              message.error(`${file.response.file.name} file upload failed.`);
            }
          }}
        >
          <div className='bg-images__upload'>
            <UploadIcon />
            <span>Upload</span>
            <span>image</span>
          </div>
        </Upload>
        {allImages.map((image, index) => {
          return (
            <div
              key={index}
              className='bg-images__image'
              style={{
                backgroundImage: `url(${image.smallUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
              onClick={() => callback(image.url)}
            ></div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default BgImagesList;
