import React, { useEffect, useState } from "react";
import Axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { message, notification, Spin, Upload } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { notificationError } from "../../config/notificationOptions";
import { ReactComponent as UploadIcon } from '../../icons/ph_upload-simple-light.svg';
import _ from 'lodash';

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#000' }} spin />;


const VideoList = ({ token, SERVER_URL, callback, searchKeyword, orientation }) => {
  const initSearchTerm = 'doctor pharma medicine';

  const [rerenderStateVideo, setRerenderStateVideo] = useState(0);
  const [pexelDataInfo, setPexelDataInfo] = useState({ isLoading: false });
  const [allVideos, setAllVideos] = useState([]);
  const [searchTerm, setSearchTerm] = useState(initSearchTerm);

  const getAllVideos = async () => {
    const videosRes = await Axios.get(`${SERVER_URL}/files?filter=${JSON.stringify({ orientation })}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });
    const pexelRes = await Axios.get(`${SERVER_URL}/pexels_videos_searched?searchString=${searchTerm}&orientation=${orientation}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });

    let mappedVideos = [];
    let mappedPexel = [];

    if (videosRes && videosRes.data && videosRes.data.length > 0) {
      mappedVideos = videosRes.data.map((video) => ({
        id: video._id,
        // url: image.mdUrl,
        link: video.location,
      }));
    }
    if (pexelRes && pexelRes.data && pexelRes.data.videos.length > 0) {
      mappedPexel = pexelRes.data.videos.map((video) => ({
        id: video.id,
        url: video.url,
        thumbnail: video.image,
        link: video.video_files.filter((video) => video.quality === 'sd')[0].link,
      }));
    }
    setAllVideos([...mappedVideos, ...mappedPexel]);
    setPexelDataInfo({
      isLoading: false,
      next_page: pexelRes.data.next_page,
      total_results: pexelRes.data.total_results,
    });
  };

  useEffect(() => {
    getAllVideos();
  }, [rerenderStateVideo, orientation]);

  const fetchMorePexel = async () => {
    if (pexelDataInfo.isLoading) return;

    setPexelDataInfo((previous) => ({ ...previous, isLoading: true }));

    const queryString =
      pexelDataInfo && pexelDataInfo.next_page
        ? `?${pexelDataInfo.next_page.split('?')[1]}&searchString=${searchTerm}`
        : '?searchString=${searchTerm}';
    const pexelRes = await Axios.get(`${SERVER_URL}/pexels_videos_searched${queryString}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });

    let mappedPexel = [];
    if (pexelRes && pexelRes.data && pexelRes.data.videos.length > 0) {
      mappedPexel = pexelRes.data.videos.map((video) => ({
        id: String(video.id),
        url: video.url,
        thumbnail: video.image,
        link: video.video_files.filter((video) => video.quality === 'sd')[0].link,
      }));
    }

    setAllVideos((previous) => [...previous, ...mappedPexel]);
    setPexelDataInfo({
      isLoading: false,
      next_page: pexelRes.data.next_page,
      total_results: pexelRes.data.total_results,
    });
  };

  if (searchKeyword && searchKeyword !== searchTerm) {
    setSearchTerm(searchKeyword);
    setRerenderStateVideo((previous) => previous + 1);
  } else if (!searchKeyword && searchTerm !== initSearchTerm) {
    setSearchTerm(initSearchTerm);
    setRerenderStateVideo((previous) => previous + 1);
  }

  return (
    <div className='bg-images'>
      <InfiniteScroll
        dataLength={allVideos.length}
        next={fetchMorePexel}
        hasMore={allVideos.length < pexelDataInfo.total_results}
        loader={<Spin indicator={antIcon} />}
        height={300}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '7px',
        }}
      >
        <Upload
          action={`${SERVER_URL}/upload-video`}
          accept='.mp4,.avi,.mpeg,.mkv,.gif'
          name='video'
          // getValueFromEvent={normFile}
          showUploadList={false}
          headers={{
            type: 'common',
            resize: true,
            // resizemob: false,
            // height: 500,
            Authorization: `Bearer ${token}`,
            uri: 'public/videos/',
            // urimob: imageSavePath + 'mob/',
          }}
          beforeUpload={async (file) => {
            const isAllowedFormat = ['video/mp4', 'video/x-matroska'].includes(file.type);
            if (!isAllowedFormat) {
              notification.error({
                message: 'You can only upload MP4 or MKV file!',
                placement: 'bottomRight',
                icon: notificationError.icon,
                className: notificationError.className,
                duration: notificationError.duration,
              });
            }
            const isAllowedSize = file.size / 1024 / 1024 < 50;
            if (!isAllowedSize) {
              notification.error({
                message: 'Video must be smaller than 50MB!',
                placement: 'bottomRight',
                icon: notificationError.icon,
                className: notificationError.className,
                duration: notificationError.duration,
              });
            }
            if (isAllowedFormat && isAllowedSize) {
              message.info('Uploading video in progress.');
            }
            return isAllowedFormat && isAllowedSize;
          }}
          onChange={({ file }) => {
            if (file.status === 'done') {
              if (file.response && file.response.file) {
                message.success(`${file.response.file.originalname
                  } file uploaded successfully.`);
                let uploaded = sessionStorage.getItem('uploaded');
                if (uploaded) {
                  sessionStorage.setItem('uploaded', `${uploaded},${file.response.newFile._id}`);
                } else {
                  sessionStorage.setItem('uploaded', file.response.newFile._id);
                }
                setTimeout(() => {
                  setRerenderStateVideo((previous) => previous + 1);

                }, 10000);
              }
            } else if (file.status === 'error') {
              message.error(`${file.response.file.name} file upload failed.`);
            }
          }}
        >
          <div className='bg-images__upload'>
            <UploadIcon />
            <span>Upload</span>
            <span>Video</span>
          </div>
        </Upload>
        {allVideos.map((video, index) =>
          <div>
            <video
              autoPlay
              loop
              muted
              key={video.id}
              className='bg-images__image'
              onClick={() => callback(video.link, video.thumbnail)}
            >
              <source src={video.link} />
            </video>
          </div>
        )}
      </InfiniteScroll>
    </div >
  );
};

export default VideoList;