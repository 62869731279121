import { Form } from 'antd';
import FormActions from './components/FormActions';

export default function SubmitDataForm({ setCurrentEvent, blockEventHandler, eventIndex }) {
  return (
    <Form onFinish={blockEventHandler}>
      <p>Make this button Submit ePro data</p>
      <FormActions eventIndex={eventIndex} setCurrentEvent={setCurrentEvent} />
    </Form>
  );
}
