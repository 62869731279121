import React, { useReducer, useState, useEffect, useContext } from 'react';
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';
import Axios from 'axios';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import {
  Button,
  notification,
  Layout,
  Popconfirm,
  Input,
  Select,
  Collapse,
  Checkbox,
  Modal,
  Form
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import HeaderComponent from '../../components/base/HeaderComponent';
import HeaderInner from '../../components/elements/HeaderInner';
import { ReactComponent as Close } from '../../icons/close.svg';
import { ReactComponent as Phone } from '../../icons/ph_device-mobile-speaker.svg';
import { ReactComponent as UndoIcon } from '../../icons/ph_arrow-u-up-left-light.svg';
import { ReactComponent as RedoIcon } from '../../icons/ph_arrow-u-up-right-light.svg';
import { ReactComponent as BrowseIcon } from '../../icons/Qoolection-icon.svg';
import { ReactComponent as MenuIcon } from '../../icons/ph_list-light.svg';
import { ReactComponent as ArrangeIcon } from '../../icons/ph_arrows-out-cardinal.svg';
import { ReactComponent as AddInfoIcon } from '../../icons/fluent_clipboard-task-list-ltr-20-regular.svg';
import { ReactComponent as SearchIcon } from '../../icons/ph_magnifying-glass.svg';
import { ReactComponent as Plus } from '../../icons/plus.svg';
import { ReactComponent as CheckIcon } from '../../icons/ph_check-bold.svg';
import { ReactComponent as PDFIcon } from '../../icons/pdf.svg';
import qoolectionReducer from '../../reducers/qoolection/qoolectionReducer';
import {
  SELECT_QOOLECTION,
  SELECT_QOOLECTION_WITH_HISTORY,
  UNDO,
  REDO,
  EDIT_QOOLECTION_PROPERTY_WITHOUT_HISTORY
} from '../../reducers/qoolection/actionTypes';
import SideBarInner from '../../components/elements/SideBarInner';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../icons/ph_minus.svg';
import { ReactComponent as Save } from '../../icons/save.svg';
import { ReactComponent as CloseIcon } from '../../icons/ph_x-light.svg';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import QooDisplay from '../qoos/QooDisplay';
import MenuLinksPanel from './MenuLinksPanel';
import _ from 'lodash';
import ArrangePanel from './ArrangePanel';
import ArrangeGrid from './ArrangeGrid';
import QooCardDropdownMenu from './QooCardDropdownMenu';
import DuplicateModal from './DuplicateModal';
import MenuTextPanel from './MenuTextPanel';
import QoolectionPreviewModal from './QoolectionPhonePreviewModal';
import PreviewDrawer from './PreviewDrawer';
import AddInfoPanel from './AddInfoPanel';
import QooPreviewDrawerModal from './QooPreviewDrawerModal';
import ApprovalModal from './ApprovalModal';
import QooDuplicatesFromQoolectionModal from './QooDuplicatsFromQoolectionModal';
import ExportQoolectionPdfModal from './ExportQoolectionPdfModal';
import ThemeModal from '../../components/elements/ThemeModal';

const { Panel } = Collapse;

const EditQoolection = (props) => {
  const initState = {
    qoolectionHistory: [],
    qoolectionFuture: [],
    qoolection: {
      title: '',
      qoos: [],
      menuLinks: []
    }
  };

  const [state, dispatch] = useReducer(qoolectionReducer, initState);
  const { qoolection, qoolectionHistory, qoolectionFuture, temporaryQoolection } = state;

  const currentuser = useContext(UserContext);
  const { qoolectionId } = useParams();
  const [qoolectionTitleForm] = Form.useForm();
  const isNew = qoolectionId ? false : true;
  const history = useHistory();
  const location = useLocation();
  const isCreateNew = location.state?.isCreateNew;

  const [previewType, setPreviewType] = useState('block');
  const [searchFilter, setSearchFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState(undefined);
  const [audienceFilter, setAudienceFilter] = useState(undefined);
  const [qoos, fetchQoos] = useAxios('', [], currentuser.data.token, 'get');
  const [eproQoos, fetchEproQoos] = useAxios('', {}, currentuser.data.token, 'get');
  const [eproQoolections, fetchEprQoollections] = useAxios('', {}, currentuser.data.token, 'get');
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [sendingTemplateData, setSendingTemplateData] = useState(false);
  // console.log('qoos: ', qoos);
  const [editingQoolection, fetchEditingQoolection] = useAxios(
    '',
    [],
    currentuser.data.token,
    'get'
  );
  const [qoosTemplates, fetchQoosTemplates] = useAxios('', [], currentuser.data.token, 'get');
  const [qoolections, fetchQoolections] = useAxios('', [], currentuser.data.token, 'get');
  // console.log('qoolections: ', qoolections);
  const [qoolectionsTemplates, fetchQoolectionsTemplates] = useAxios(
    '',
    [],
    currentuser.data.token,
    'get'
  );
  const [activeTheme, fetchActiveTheme] = useAxios('', {}, currentuser.data.token, 'get');
  const [userThemes, fetchUserThemes] = useAxios('', {}, currentuser.data.token, 'get');
  const [categories, fetchCategories] = useAxios('', {}, currentuser.data.token, 'get');
  const [audiences, fetchAudiences] = useAxios('', {}, currentuser.data.token, 'get');
  const [qoolectionTypes, fetchQoolectionTypes] = useAxios('', {}, currentuser.data.token, 'get');
  const [section, setSection] = useState('select');
  const [myWorkOrTemplates, setMyWorkOrTemplates] = useState('my-work');
  const [qoosOrQoolections, setQoosOrQoolections] = useState('qoos');
  const [draggingItem, setDraggingItem] = useState(null);
  const [itemOverDropZone, setItemOverDropZone] = useState(false);
  const [qooDuplicate, setQooDuplicate] = useState(null);
  const [qooDuplicatesFromQoolection, setQooDuplicatesFromQoolection] = useState(null);
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [qoolectionInDrawerPreview, setQoolectionInDrawerPreview] = useState(null);
  const [qooInDrawerPreview, setQooInDrawerPreview] = useState(null);
  const [approvalModalVisible, setApprovalModalVisible] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [publishedSwipeDisabled, setPublishedSwipeDisabled] = useState(false);
  const [qoolectionForExport, setQoolectionForExport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [activeThemes, setActiveThemes] = useState([]);
  const { selectedTeam, study } = currentuser;

  // console.log('qoolectionId: ', qoolectionId);

  const createDraft = async () => {
    const submitData = {
      title: `Draft ${new Date().toLocaleDateString(undefined, {
        month: '2-digit',
        day: 'numeric',
        year: '2-digit'
      })}`,
      theme: activeTheme.data,
      team: selectedTeam,
      study
    };

    try {
      const response = await Axios.post(`${SERVER_URL}/qoolections`, submitData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      // notification.success({
      //   message: 'Qoolection created.',
      //   placement: 'bottomRight',
      //   icon: notificationSuccess.icon,
      //   className: notificationSuccess.className,
      //   duration: notificationSuccess.duration
      // });
      history.push(`/admin/edit-qoolection/${response.data.item._id}`, {
        isCreateNew: true
      });
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const duplicateQoolection = async (submitData) => {
    try {
      const response = await Axios.post(
        `${SERVER_URL}/qoolection-duplicate-qoos`,
        { ...submitData },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      notification.success({
        message: 'Qoolection updated.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });

      // Redirects on duplicate action
      // const qoolectionId = response.data.item;
      // history.push('/admin/edit-qoolection/' + qoolectionId);

      //setStateForRefetchingData(previous => previous + 1);
      //setOffset(0);
      // response.data.items.map(async qoo => {
      //   await addQoo(qoo);
      // })

      const newQoolection = await updateQoolection({
        qoos: [...qoolection.qoos, ...response.data.items]
      });

      dispatch({
        type: SELECT_QOOLECTION_WITH_HISTORY,
        payload: newQoolection
      });
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const updateQoolection = async (submitData) => {
    try {
      const response = await Axios.put(`${SERVER_URL}/qoolections/${qoolectionId}`, submitData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      // notification.success({
      //   message: 'Qoolection updated.',
      //   placement: 'bottomRight',
      //   icon: notificationSuccess.icon,
      //   className: notificationSuccess.className,
      //   duration: notificationSuccess.duration,
      // });
      return response.data.item;
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };
  const isSuperAdmin = currentuser.data.role.some((item) => item === 'superadmin');

  useEffect(() => {
    if ((!isSuperAdmin && currentuser.study) || isSuperAdmin) {
      fetchUserThemes(
        `${SERVER_URL}/get-user-themes/${currentuser.data.id}?team=${currentuser.selectedTeam}&study=${currentuser.study}`,
        {}
      );
      fetchActiveTheme(
        `${SERVER_URL}/get-active-theme/${currentuser.data.id}?team=${currentuser.selectedTeam}&study=${currentuser.study}`,
        {}
      );
    }
  }, [fetchActiveTheme, fetchUserThemes, currentuser]);

  // useEffect(() => {
  //   fetchActiveTheme(`${SERVER_URL}/get-active-theme/${currentuser.data.id}?team=${currentuser.selectedTeam}&study=${currentuser.study}`, {});
  // }, []);

  useEffect(() => {
    setPublishedSwipeDisabled(_.get(qoolection, 'publishedSwipeDisabled', false));
  }, [qoolection]);

  useEffect(() => {
    if (qoolectionId) fetchEditingQoolection(`${SERVER_URL}/qoolections/${qoolectionId}`, {});
  }, [qoolectionId, fetchEditingQoolection]);

  useEffect(() => {
    if (!editingQoolection.isLoading && !editingQoolection.isError && editingQoolection.data._id) {
      dispatch({
        type: SELECT_QOOLECTION,
        payload: editingQoolection.data
      });
    }
  }, [editingQoolection]);

  useEffect(() => {
    if (isNew && !activeTheme.isLoading && !activeTheme.isError && activeTheme.data._id) {
      createDraft();
    }
  }, [isNew, activeTheme]);

  useEffect(() => {
    fetchCategories(`${SERVER_URL}/categories`, {});
    fetchAudiences(`${SERVER_URL}/audiences`, {});
    fetchQoolectionTypes(`${SERVER_URL}/qoolection-types`, {});
  }, [fetchCategories, fetchAudiences, fetchQoolectionTypes]);

  useEffect(() => {
    if (
      (isNew && !activeTheme.isLoading && !activeTheme.isError && activeTheme.data._id) ||
      (!isNew &&
        !editingQoolection.isLoading &&
        !editingQoolection.isError &&
        editingQoolection.data._id)
    ) {
      const filters = {
        theme: isNew ? activeTheme.data._id : editingQoolection.data.theme._id,
        title: searchFilter,
        category: categoryFilter,
        audience: audienceFilter,
        ownId: qoosOrQoolections === 'qoos' ? null : qoolection._id
      };
      const removedNullValues = Object.fromEntries(
        Object.entries(filters).filter(
          ([key, value]) => value != null && (!Array.isArray(value) || value.length !== 0)
        )
      );
      // if (qoosOrQoolections === 'qoos') {
      //   delete filters.ownId;
      // }

      const filter = JSON.stringify(removedNullValues);
      switch (myWorkOrTemplates) {
        case 'my-work':
          if (qoosOrQoolections === 'qoos') {
            if (!isSuperAdmin && currentuser.study) {
              fetchQoos(
                `${SERVER_URL}/qoos?filter=${filter}&team=${currentuser.selectedTeam}&study=${currentuser.study}`,
                []
              );
            } else if (isSuperAdmin) {
              fetchQoos(`${SERVER_URL}/qoos?filter=${filter}`, []);
            }
          } else {
            if (!isSuperAdmin && currentuser.study) {
              fetchQoolections(
                `${SERVER_URL}/qoolections?filter=${filter}&team=${currentuser.selectedTeam}&study=${currentuser.study}`,
                []
              );
            } else if (isSuperAdmin) {
              fetchQoolections(`${SERVER_URL}/qoolections?filter=${filter}`, []);
            }
          }
          break;
        case 'templates':
          if (qoosOrQoolections === 'qoos') {
            fetchQoosTemplates(`${SERVER_URL}/qoos-templates`, []);
          } else {
            fetchQoolectionsTemplates(`${SERVER_URL}/qoolections-templates`, []);
          }
          break;
        case 'epro':
          if (qoosOrQoolections === 'qoos') {
            fetchEproQoos(`${SERVER_URL}/qoos-epro`, []);
          } else {
            fetchEprQoollections(`${SERVER_URL}/qoolections-epro`, []);
          }
          break;
      }
      // if (
      //   qoosOrQoolections === 'qoos' &&
      //   myWorkOrTemplates === 'my-work' &&
      //   currentuser.study
      // ) {
      //   fetchQoos(
      //     `${SERVER_URL}/qoos?filter=${filter}&team=${currentuser.selectedTeam}&study=${currentuser.study}`,
      //     []
      //   );
      // } else if (
      //   qoosOrQoolections === 'qoos' &&
      //   myWorkOrTemplates === 'templates'
      // ) {
      //   fetchQoosTemplates(`${SERVER_URL}/qoos-templates`, []);
      // } else if (
      //   qoosOrQoolections === 'qoolections' &&
      //   myWorkOrTemplates === 'my-work' &&
      //   currentuser.study
      // ) {
      //   fetchQoolections(
      //     `${SERVER_URL}/qoolections?filter=${filter}&team=${currentuser.selectedTeam}&study=${currentuser.study}`,
      //     []
      //   );
      // } else if (
      //   qoosOrQoolections === 'qoolections' &&
      //   myWorkOrTemplates === 'templates'
      // ) {
      //   fetchQoolectionsTemplates(`${SERVER_URL}/qoolections-templates`, []);
      // } else if(qoosOrQoolections === 'qoos' && myWorkOrTemplates === 'epro'){
      //   fetchEproQoos(`${SERVER_URL}/qoos-epro`, []);
      // }
    }
  }, [
    qoosOrQoolections,
    myWorkOrTemplates,
    categoryFilter,
    audienceFilter,
    searchFilter,
    activeTheme,
    fetchQoos,
    fetchQoosTemplates,
    fetchEproQoos,
    fetchEprQoollections,
    fetchQoolections,
    fetchQoolectionsTemplates,
    editingQoolection,
    currentuser
  ]);

  useEffect(() => {
    currentuser.setIsNavMenuCollapsed(true);
  }, []);

  const handleUndo = async () => {
    if (qoolectionHistory.length > 0) {
      const previousState = qoolectionHistory[qoolectionHistory.length - 1];

      const newQoolection = await updateQoolection(previousState);
      dispatch({
        type: UNDO,
        payload: newQoolection
      });
    }
  };

  useEffect(() => {
    const noActiveOrCreatedTheme =
      (!activeTheme.isLoading && !activeTheme.isError && !activeTheme.data._id) ||
      (!userThemes.isLoading && !userThemes.isError && userThemes.data.items?.length === 0);

    if (noActiveOrCreatedTheme) {
      if (userThemes.data.items && userThemes.data.items.length === 0) {
        setModalMessage('Create a theme');
        setVisibleModal(true);
      } else if (!activeTheme.data._id && userThemes.data.items?.length > 0) {
        setModalMessage('Activate a theme');
        setVisibleModal(true);
      }
    }
  }, [activeTheme, userThemes]);

  const handleRedo = async () => {
    if (qoolectionFuture.length > 0) {
      const previousState = qoolectionFuture[0];

      const newQoolection = await updateQoolection(previousState);
      dispatch({
        type: REDO,
        payload: newQoolection
      });
    }
  };

  const cancelFunction = () => {
    history.push('/admin/qoolections');
  };

  const addQoo = async (qoo) => {
    if (qoolection && qoolection.qoos && qoolection.qoos.some((item) => item._id === qoo._id)) {
      return setQooDuplicate(qoo);
    } else {
      const newQoolection = await updateQoolection({
        qoos: [...qoolection.qoos, qoo]
      });
      dispatch({
        type: SELECT_QOOLECTION_WITH_HISTORY,
        payload: newQoolection
      });
    }
  };

  const convertToTemplate = async (updates) => {
    setSendingTemplateData(true);
    const route = `${SERVER_URL}/qoolections-templates/${qoolectionId}`;
    try {
      const response = await Axios.put(route, updates, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      dispatch({
        type: SELECT_QOOLECTION,
        payload: response.data.item
      });
      setSendingTemplateData(false);
    } catch (error) {
      setSendingTemplateData(false);
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const addQoosFromAnotherQoolection = async (qoosToBeAdded) => {
    const filteredQoos = [];
    const duplicateQoos = [];
    qoosToBeAdded.forEach((addingQoo) => {
      if (!qoolection.qoos.some((qoo) => qoo._id === addingQoo._id)) {
        filteredQoos.push(addingQoo);
      } else {
        duplicateQoos.push(addingQoo);
      }
    });

    if (filteredQoos.length > 0) {
      const newQoolection = await updateQoolection({
        qoos: [...qoolection.qoos, ...filteredQoos]
      });
      dispatch({
        type: SELECT_QOOLECTION_WITH_HISTORY,
        payload: newQoolection
      });
    }
    if (duplicateQoos.length > 0) {
      setQooDuplicatesFromQoolection(duplicateQoos);
    }
  };

  const duplicateQoo = async (qoo, addingBlankQoo) => {
    const submitData = {
      ...qoo
    };
    if (!addingBlankQoo) {
      delete submitData._id;
      submitData.title = `${qoo.title}(2)`;
    }
    const route = addingBlankQoo
      ? `${SERVER_URL}/qoos`
      : `${SERVER_URL}/qoos-create-new-from-existing`;
    try {
      const response = await Axios.post(route, submitData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Qoo created',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        // duration: notificationSuccess.duration,
        duration: 600
      });

      addQoo(response.data.item);
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const removeQoo = async (id) => {
    const newQoos = qoolection.qoos.filter((qoo) => qoo._id !== id);
    const newQoolection = await updateQoolection({ qoos: newQoos });
    dispatch({
      type: SELECT_QOOLECTION_WITH_HISTORY,
      payload: newQoolection
    });
  };

  const onSubmit = async (formData) => {
    formData.saveClicked = true;
    formData.publishedSwipeDisabled = publishedSwipeDisabled;

    const submitData = isNew
      ? { ...formData, theme: activeTheme.data, team: selectedTeam, study }
      : formData;

    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/qoolections` : `${SERVER_URL}/qoolections/${qoolectionId}`;

    // ***** TODO: This is a workaround for useReducer delay. Deeper analysis needed. >>
    setTimeout(async () => {
      try {
        await Axios[method](route, submitData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        });
        if (!isNew) {
          notification.success({
            message: `Qoolection updated.`,
            placement: 'bottomRight',
            icon: notificationSuccess.icon,
            className: notificationSuccess.className,
            duration: notificationSuccess.duration
          });
        }
      } catch (error) {
        const msg = error.response
          ? error.response.data.message
          : error.message
          ? error.message
          : error;
        console.log(msg);
        notification.error({
          message: msg,
          placement: 'bottomRight',
          icon: notificationError.icon,
          className: notificationError.className,
          duration: notificationError.duration
        });
      }
    }, 2000);
    // ***** TODO: This is a workaround for useReducer delay. Deeper analysis needed. ||
  };

  const handleChangeDebounced = _.debounce(async (e) => {
    setSearchFilter(e.target.value);
  }, 500);

  const handleChange = (e) => {
    e.persist();
    handleChangeDebounced(e);
  };

  const dragHandler = (e, item) => {
    e.dataTransfer.setData('text/plain', '');
    setDraggingItem(item);
  };

  // const addQoolectionOverExisting = async () => {
  //   const newQoolection = await updateQoolection({ qoos: addingQoolection.qoos });
  //   dispatch({
  //     type: SELECT_QOOLECTION_WITH_HISTORY,
  //     payload: newQoolection
  //   });
  // };

  const activateTheme = async (id) => {
    try {
      const res = await Axios.put(
        `${SERVER_URL}/activate-theme/${currentuser.data.id}/${id}?team=${currentuser.selectedTeam}&study=${currentuser.study}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      history.push('/admin');
      history.push('/admin/new-qoolection');
    } catch (err) {
      notification.error({
        message: 'Problem with theme activation. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const duplicateMultipleQoos = async (qoos) => {
    const route = `${SERVER_URL}/qoos-create-new-from-existing`;
    try {
      const newQoos = [];
      const promises = qoos.map(async (qoo) => {
        const submitData = { ...qoo };
        delete submitData._id;
        submitData.title = `${qoo.title}(2)`;
        const response = await Axios.post(route, submitData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        });
        newQoos.push(response.data.item);
      });
      await Promise.all(promises);
      const newQoolection = await updateQoolection({
        qoos: [...qoolection.qoos, ...newQoos]
      });
      dispatch({
        type: SELECT_QOOLECTION_WITH_HISTORY,
        payload: newQoolection
      });
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const menuLinks = _.get(temporaryQoolection, 'menuLinks', []);
  const qoolectionQoos = _.get(qoolection, 'qoos', []);
  const qoolectionMenuText = _.get(qoolection, 'menuText', '');
  const temporaryQoolectionMenuText = _.get(temporaryQoolection, 'menuText', '');

  // const eProQoos = qoos?.data?.items?.filter(
  //   (qoo) => qoo.qooType.name === 'ePro'
  // );

  const eProQoolections = qoolections?.data?.items?.filter(
    (qoolection) => qoolection.type?.name === 'ePro'
  );
  // console.log('epro', eproQoos);
  // console.log('templ', qoosTemplates);

  return (
    /// TODO: ubaciti pre svog contenta proveru da li su fetchovani svi resursi i staviti loader dok fetchuje
    <Layout className="edit-block edit-qoolection">
      <HeaderComponent
        text={
          isNew || isCreateNew ? (
            'Create a qoolection'
          ) : (
            <>
              <Link to="/admin/qoolections">Manage Qoolections</Link>
              <span> / Edit</span>
            </>
          )
        }
        additionalText={isNew || isCreateNew ? null : qoolection?.title}
      />
      <Content>
        <ThemeModal
          themes={userThemes}
          visibleModal={visibleModal}
          message={modalMessage}
          activeThemes={activeThemes}
          currentuser={currentuser}
          activateTheme={activateTheme}
        />
        <Layout className="inner-layout">
          <SideBarInner>
            <Collapse
              ghost
              expandIcon={({ isActive }) => (isActive ? <MinusIcon /> : <PlusIcon />)}
              expandIconPosition="right"
              className="block-properties"
              defaultActiveKey="1">
              {section === 'select' && (
                <>
                  <Panel header={<h3 className="pre-title">Browse</h3>} key="1">
                    <div className="choice-btn-group">
                      <div
                        className={
                          myWorkOrTemplates === 'my-work'
                            ? 'choice-btn choice-btn--selected choice-btn--sm choice-btn--left'
                            : 'choice-btn choice-btn--sm choice-btn--left'
                        }
                        onClick={() => setMyWorkOrTemplates('my-work')}>
                        My Work
                      </div>
                      <div
                        className={
                          myWorkOrTemplates === 'templates'
                            ? 'choice-btn choice-btn--selected choice-btn--sm choice-btn--right'
                            : 'choice-btn choice-btn--sm choice-btn--right'
                        }
                        onClick={() => setMyWorkOrTemplates('templates')}>
                        Templates
                      </div>
                      <div
                        className={
                          myWorkOrTemplates === 'epro'
                            ? 'choice-btn choice-btn--selected choice-btn--sm choice-btn--right'
                            : 'choice-btn choice-btn--sm choice-btn--right'
                        }
                        onClick={() => setMyWorkOrTemplates('epro')}>
                        ePro
                      </div>
                    </div>
                    <Select
                      className="dropdown-small qoolection-category-select qoolection-category-select--first"
                      placeholder="Qoos or Qoolections"
                      value={qoosOrQoolections}
                      onChange={(value) => setQoosOrQoolections(value)}>
                      <Select.Option value="qoos">Qoos</Select.Option>
                      <Select.Option value="qoolections">Qoolections</Select.Option>
                    </Select>
                    <Select
                      className="dropdown-small qoolection-category-select"
                      placeholder="Categories"
                      mode="multiple"
                      value={categoryFilter}
                      onChange={(value) => setCategoryFilter(value)}
                      allowClear={true}
                      onClear={() => setCategoryFilter(undefined)}>
                      {categories?.data?.items &&
                        categories?.data?.items.length > 0 &&
                        categories?.data?.items.map((category) => {
                          return (
                            <Select.Option value={category._id} key={category._id}>
                              {category.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                    <Select
                      className="dropdown-small qoolection-category-select"
                      placeholder="Audiences"
                      mode="multiple"
                      value={audienceFilter}
                      onChange={(value) => setAudienceFilter(value)}
                      allowClear={true}
                      onClear={() => setAudienceFilter(undefined)}>
                      {audiences?.data?.items &&
                        audiences?.data?.items.length > 0 &&
                        audiences?.data?.items.map((audience) => {
                          return (
                            <Select.Option value={audience._id} key={audience._id}>
                              {audience.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                    <Input
                      className="qoolection-category-select"
                      placeholder={
                        qoosOrQoolections === 'qoos' && myWorkOrTemplates === 'my-work'
                          ? 'Search Qoos'
                          : qoosOrQoolections === 'qoos' && myWorkOrTemplates === 'templates'
                          ? 'Search Qoo Templates'
                          : qoosOrQoolections === 'qoolections' && myWorkOrTemplates === 'my-work'
                          ? 'Search Qoolections'
                          : qoosOrQoolections === 'qoolections' && myWorkOrTemplates === 'templates'
                          ? 'Search Qoolection Templates'
                          : qoosOrQoolections === 'qoos' && myWorkOrTemplates === 'epro'
                          ? 'Search ePro Qoos'
                          : qoosOrQoolections === 'qoolections' && myWorkOrTemplates === 'epro'
                          ? 'Search ePro Qoolections'
                          : 'Search Templates'
                      }
                      prefix={<SearchIcon />}
                      onChange={(e) => handleChange(e)}
                    />
                    <div className="qoolection-qoos-list">
                      {qoosOrQoolections === 'qoos' &&
                        myWorkOrTemplates === 'my-work' &&
                        qoos.data &&
                        qoos.data.items &&
                        qoos.data.items.length > 0 &&
                        qoos.data.items.map((qoo) => {
                          return (
                            <div
                              key={qoo._id}
                              draggable={true}
                              unselectable="on"
                              onDragStart={(e) => dragHandler(e, qoo)}
                              onDragEnd={(e) => setDraggingItem(null)}
                              style={{ cursor: 'grab' }}
                              onClick={() => setQooInDrawerPreview(qoo)}>
                              <QooDisplay qooInPreview={qoo} width={96} height={182} />
                            </div>
                          );
                        })}
                      {qoosOrQoolections === 'qoos' &&
                        myWorkOrTemplates === 'epro' &&
                        eproQoos?.data.items?.map((qoo) => {
                          return (
                            <div
                              key={qoo._id}
                              draggable={true}
                              unselectable="on"
                              onDragStart={(e) => dragHandler(e, qoo)}
                              onDragEnd={(e) => setDraggingItem(null)}
                              style={{ cursor: 'grab' }}
                              onClick={() => setQooInDrawerPreview(qoo)}>
                              <QooDisplay qooInPreview={qoo} width={96} height={182} />
                            </div>
                          );
                        })}

                      {qoosOrQoolections === 'qoos' &&
                        myWorkOrTemplates === 'templates' &&
                        qoosTemplates.data.items &&
                        qoosTemplates.data.items.map((qoo) => {
                          return (
                            <div
                              key={qoo._id}
                              draggable={true}
                              unselectable="on"
                              onDragStart={(e) => dragHandler(e, qoo)}
                              onDragEnd={(e) => setDraggingItem(null)}
                              style={{ cursor: 'grab' }}
                              onClick={() => setQooInDrawerPreview(qoo)}>
                              <QooDisplay qooInPreview={qoo} width={96} height={182} />
                            </div>
                          );
                        })}

                      {qoosOrQoolections === 'qoolections' &&
                        myWorkOrTemplates === 'my-work' &&
                        qoolections.data &&
                        qoolections.data.items &&
                        qoolections.data.items.length > 0 &&
                        qoolections.data.items.map((qoolection) => {
                          return (
                            <div
                              key={qoolection._id}
                              draggable={true}
                              unselectable="on"
                              onDragStart={(e) =>
                                dragHandler(e, {
                                  ...qoolection,
                                  isQoolection: true
                                })
                              }
                              onDragEnd={(e) => setDraggingItem(null)}
                              style={{ cursor: 'grab', position: 'relative' }}
                              onClick={() => {
                                setQoolectionInDrawerPreview(qoolection);
                                setShowPreviewModal(true);
                              }}>
                              <div
                                style={{
                                  position: 'relative',
                                  zIndex: '2',
                                  border:
                                    qoolectionInDrawerPreview &&
                                    qoolectionInDrawerPreview._id === qoolection._id
                                      ? '1px solid #4962E2'
                                      : null
                                }}>
                                <QooDisplay
                                  qooInPreview={
                                    qoolection.qoos.length > 0
                                      ? qoolection.qoos[0]
                                      : {
                                          theme: qoolection.theme,
                                          showHeader: true,
                                          showISI: true,
                                          showFooter: true
                                        }
                                  }
                                  width={96}
                                  height={182}
                                />
                              </div>

                              <div
                                style={{
                                  width: '96px',
                                  height: '182px',
                                  position: 'absolute',
                                  top: '3px',
                                  left: '3px',
                                  border: '1px solid #D5DFE8',
                                  backgroundColor: '#F4F6F7',
                                  zIndex: '1'
                                }}></div>
                              <div
                                style={{
                                  width: '96px',
                                  height: '182px',
                                  position: 'absolute',
                                  top: '6px',
                                  left: '6px',
                                  border: '1px solid #D5DFE8',
                                  backgroundColor: '#F4F6F7',
                                  zIndex: '0'
                                }}></div>
                            </div>
                          );
                        })}
                      {qoosOrQoolections === 'qoolections' &&
                        myWorkOrTemplates === 'epro' &&
                        eproQoolections?.data.items?.map((qoolection) => {
                          return (
                            <div
                              key={qoolection._id}
                              draggable={true}
                              unselectable="on"
                              onDragStart={(e) =>
                                dragHandler(e, {
                                  ...qoolection,
                                  isQoolection: true
                                })
                              }
                              onDragEnd={(e) => setDraggingItem(null)}
                              style={{ cursor: 'grab', position: 'relative' }}
                              onClick={() => {
                                setQoolectionInDrawerPreview(qoolection);
                                setShowPreviewModal(true);
                              }}>
                              <div
                                style={{
                                  position: 'relative',
                                  zIndex: '2',
                                  border:
                                    qoolectionInDrawerPreview &&
                                    qoolectionInDrawerPreview._id === qoolection._id
                                      ? '1px solid #4962E2'
                                      : null
                                }}>
                                <QooDisplay
                                  qooInPreview={
                                    qoolection.qoos.length > 0
                                      ? qoolection.qoos[0]
                                      : {
                                          theme: qoolection.theme,
                                          showHeader: true,
                                          showISI: true,
                                          showFooter: true
                                        }
                                  }
                                  width={96}
                                  height={182}
                                />
                              </div>

                              <div
                                style={{
                                  width: '96px',
                                  height: '182px',
                                  position: 'absolute',
                                  top: '3px',
                                  left: '3px',
                                  border: '1px solid #D5DFE8',
                                  backgroundColor: '#F4F6F7',
                                  zIndex: '1'
                                }}></div>
                              <div
                                style={{
                                  width: '96px',
                                  height: '182px',
                                  position: 'absolute',
                                  top: '6px',
                                  left: '6px',
                                  border: '1px solid #D5DFE8',
                                  backgroundColor: '#F4F6F7',
                                  zIndex: '0'
                                }}></div>
                            </div>
                          );
                        })}

                      {qoosOrQoolections === 'qoolections' &&
                        myWorkOrTemplates === 'templates' &&
                        qoolectionsTemplates?.data.items?.map((qoolection) => {
                          return (
                            <div
                              key={qoolection._id}
                              draggable={true}
                              unselectable="on"
                              onDragStart={(e) =>
                                dragHandler(e, {
                                  ...qoolection,
                                  isQoolection: true
                                })
                              }
                              onDragEnd={(e) => setDraggingItem(null)}
                              style={{ cursor: 'grab', position: 'relative' }}
                              onClick={() => {
                                setQoolectionInDrawerPreview(qoolection);
                                setShowPreviewModal(true);
                              }}>
                              <div
                                style={{
                                  position: 'relative',
                                  zIndex: '2',
                                  border:
                                    qoolectionInDrawerPreview &&
                                    qoolectionInDrawerPreview._id === qoolection._id
                                      ? '1px solid #4962E2'
                                      : null
                                }}>
                                <QooDisplay
                                  qooInPreview={
                                    qoolection.qoos.length > 0
                                      ? qoolection.qoos[0]
                                      : {
                                          theme: qoolection.theme,
                                          showHeader: true,
                                          showISI: true,
                                          showFooter: true
                                        }
                                  }
                                  width={96}
                                  height={182}
                                />
                              </div>

                              <div
                                style={{
                                  width: '96px',
                                  height: '182px',
                                  position: 'absolute',
                                  top: '3px',
                                  left: '3px',
                                  border: '1px solid #D5DFE8',
                                  backgroundColor: '#F4F6F7',
                                  zIndex: '1'
                                }}></div>
                              <div
                                style={{
                                  width: '96px',
                                  height: '182px',
                                  position: 'absolute',
                                  top: '6px',
                                  left: '6px',
                                  border: '1px solid #D5DFE8',
                                  backgroundColor: '#F4F6F7',
                                  zIndex: '0'
                                }}></div>
                            </div>
                          );
                        })}
                    </div>
                  </Panel>
                </>
              )}
              {section === 'sequence' && (
                <>
                  <Panel header={<h3 className="pre-title">Arrange your qoolection</h3>} key="1">
                    <ArrangePanel
                      qoolection={qoolection}
                      dispatch={dispatch}
                      updateQoolection={updateQoolection}
                    />
                  </Panel>
                  <Panel header={<h3 className="pre-title">Additional</h3>} key="1.1">
                    <Checkbox
                      checked={publishedSwipeDisabled}
                      onChange={(e) => {
                        setPublishedSwipeDisabled(_.get(e.target, 'checked', false));
                      }}>
                      Disable swipe on published version
                    </Checkbox>
                  </Panel>
                </>
              )}
              {section === 'menu' && (
                <>
                  <Panel header={<h3 className="pre-title">menu items</h3>} key="1">
                    <MenuLinksPanel
                      qoolection={qoolection}
                      dispatch={dispatch}
                      updateQoolection={updateQoolection}
                      temporaryQoolection={temporaryQoolection}
                    />
                  </Panel>
                  <Panel header={<h3 className="pre-title">supplementary text</h3>} key="2">
                    <MenuTextPanel
                      qoolection={qoolection}
                      dispatch={dispatch}
                      theme={activeTheme.data}
                      updateQoolection={updateQoolection}
                      temporaryQoolection={temporaryQoolection}
                    />
                  </Panel>
                </>
              )}
              {section === 'add-info' && (
                <Panel header={<h3 className="pre-title">Qoolection Info</h3>} key="1">
                  <AddInfoPanel
                    qoolectionTitleForm={qoolectionTitleForm}
                    qoolection={qoolection}
                    dispatch={dispatch}
                    qoolectionTypes={qoolectionTypes}
                    categories={categories}
                    audiences={audiences}
                    updateQoolection={updateQoolection}
                    temporaryQoolection={temporaryQoolection}
                  />
                </Panel>
              )}
            </Collapse>
          </SideBarInner>
          <Layout>
            <HeaderInner>
              <div className="edit-block-inner-header edit-block-inner-header--qoolections">
                <div className="block-types-list">
                  <div
                    className={
                      section === 'select'
                        ? 'block-types-list__btn block-types-list__btn--active'
                        : 'block-types-list__btn'
                    }
                    onClick={() => setSection('select')}>
                    <div className="block-types-list__icon">
                      <BrowseIcon />
                    </div>
                    <span className="block-types-list__name">Browse</span>
                  </div>
                  <div
                    className={
                      section === 'sequence'
                        ? 'block-types-list__btn block-types-list__btn--active'
                        : 'block-types-list__btn'
                    }
                    onClick={() => setSection('sequence')}>
                    <div className="block-types-list__icon">
                      <ArrangeIcon />
                    </div>
                    <span className="block-types-list__name">Sequence</span>
                  </div>
                  <div
                    className={
                      section === 'menu'
                        ? 'block-types-list__btn block-types-list__btn--active'
                        : 'block-types-list__btn'
                    }
                    onClick={() => setSection('menu')}>
                    <div className="block-types-list__icon">
                      <MenuIcon />
                    </div>
                    <span className="block-types-list__name">Menu</span>
                  </div>
                  <div
                    className={
                      section === 'add-info'
                        ? 'block-types-list__btn block-types-list__btn--active'
                        : 'block-types-list__btn'
                    }
                    onClick={() => setSection('add-info')}>
                    <div className="block-types-list__icon">
                      <AddInfoIcon />
                    </div>
                    <span className="block-types-list__name">Info</span>
                  </div>
                </div>
                <div className="edit-block-header-buttons">
                  <div className="edit-block-undo-redo">
                    <UndoIcon
                      className={
                        qoolectionHistory.length > 0
                          ? 'edit-block-undo-redo__btn'
                          : 'edit-block-undo-redo__btn edit-block-undo-redo__btn--disabled'
                      }
                      onClick={handleUndo}
                    />
                    <RedoIcon
                      className={
                        qoolectionFuture.length > 0
                          ? 'edit-block-undo-redo__btn'
                          : 'edit-block-undo-redo__btn edit-block-undo-redo__btn--disabled'
                      }
                      onClick={handleRedo}
                    />
                  </div>
                  <div className="edit-block-save-cnc">
                    <Button
                      className={
                        previewType === 'phone'
                          ? 'edit-block-save-cnc__preview edit-block-save-cnc__preview--active'
                          : 'edit-block-save-cnc__preview'
                      }
                      onClick={() => setPreviewModalVisible(true)}>
                      <span>Preview</span>
                      <Phone />
                    </Button>
                    <Button
                      // className="edit-block-save-cnc__preview"
                      onClick={() => setQoolectionForExport(qoolection)}>
                      <span>Export</span>
                      <PDFIcon />
                    </Button>
                    <Button
                      type="primary"
                      className="edit-block-save-cnc__save"
                      // disabled={qoolectionHistory.length < 1}
                      // onClick={() => onSubmit({ ...qoolection, ...temporaryQoolection })}
                      onClick={() => setSaveModalVisible(true)}>
                      <span>
                        {qoolectionTypes?.data.items?.find(
                          (item) =>
                            item._id ===
                            (temporaryQoolection?.type?._id || temporaryQoolection?.type)
                        )?.name !== 'ePro'
                          ? 'Save'
                          : 'Save as EPro'}
                      </span>
                      <Save />
                    </Button>
                    {qoolectionTypes?.data.items?.find(
                      (item) =>
                        item._id === (temporaryQoolection?.type?._id || temporaryQoolection?.type)
                    )?.name !== 'ePro'
                      ? currentuser.data.role.includes('superadmin') && (
                          <Button
                            type="primary"
                            className="edit-block-save-cnc__save-template"
                            onClick={() => {
                              if (temporaryQoolection?.status === 'template') {
                                convertToTemplate({
                                  status: 'draft'
                                });
                              } else {
                                convertToTemplate({
                                  status: 'template'
                                });
                              }
                            }}
                            disabled={sendingTemplateData}>
                            {temporaryQoolection?.status === 'template'
                              ? 'Remove from templates'
                              : 'Save as template'}
                          </Button>
                        )
                      : null}

                    {/*TODO: check if approval is needed. Commented for now/*/}
                    {/*<Button*/}
                    {/*  type='primary'*/}
                    {/*  // className="edit-block-save-cnc__save"*/}
                    {/*  // disabled={qoolectionHistory.length < 1}*/}
                    {/*  // onClick={() => testUploadQoolectionToVeeva()}*/}
                    {/*  onClick={() => setApprovalModalVisible(true)}*/}
                    {/*// onClick={() => setSaveModalVisible(true)}*/}
                    {/*>*/}
                    {/*  <span>Start Approval</span>*/}
                    {/*  <CheckIcon />*/}
                    {/*</Button>*/}
                    <Popconfirm
                      title="Are you sure you want to leave without saving? All chages will be lost!"
                      onConfirm={cancelFunction}>
                      <div className="edit-block-save-cnc__cnc-btn">
                        <Close className="edit-block-save-cnc__cancel" />
                      </div>
                    </Popconfirm>
                  </div>
                </div>
              </div>
            </HeaderInner>

            <Content className="qoolection__main-content">
              {section === 'select' && (
                <div
                  className={
                    itemOverDropZone
                      ? 'qoolection__droppable-qoos-list qoolection__droppable-qoos-list--item-over'
                      : 'qoolection__droppable-qoos-list'
                  }
                  onDrop={(e) => {
                    e.preventDefault();
                    if (draggingItem?.isQoolection) {
                      duplicateQoolection(draggingItem);
                      // addQoosFromAnotherQoolection(draggingItem.qoos);
                      setItemOverDropZone(false);
                      return;
                    }
                    // addQoo(draggingItem);
                    duplicateQoo(draggingItem);
                    setItemOverDropZone(false);
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    setItemOverDropZone(true);
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault();
                    setItemOverDropZone(false);
                  }}>
                  {qoolectionQoos.length < 1 && (
                    <div className="qoolection__drag-info">
                      <ArrangeIcon />
                      <span>Drag a Qoo</span>
                    </div>
                  )}

                  {qoolectionQoos.length > 0 &&
                    qoolectionQoos.map((qoo, index) => {
                      return (
                        <div key={`${index}-${qoo._id}`} className="qoolection__qoo-card">
                          <QooDisplay qooInPreview={qoo} width={140} height={265} />
                          <span className="qoolection__qoo-card-title">{qoo.title}</span>
                          <QooCardDropdownMenu
                            user={currentuser}
                            dispatch={dispatch}
                            qoo={qoo}
                            index={index}
                            duplicateQoo={duplicateQoo}
                            removeQoo={removeQoo}
                            qoolection={qoolection}
                            isCreateNew={isCreateNew}
                          />
                        </div>
                      );
                    })}
                  <div
                    className="qoolection__add-blank-qoo"
                    onClick={() => {
                      duplicateQoo(
                        {
                          title: 'Blank Qoo',
                          isBlankQoo: true,
                          theme: activeTheme.data,
                          showHeader: true,
                          showISI: true,
                          showFooter: true
                        },
                        true
                      );

                      // addQoo({
                      //   _id: mongoObjectId(),
                      //   title: 'Blank Qoo',
                      //   type: 'blank',
                      //   theme: activeTheme.data,
                      //   showHeader: true,
                      //   showISI: true,
                      //   showFooter: true
                      // });
                    }}>
                    <Plus />
                    <span>Blank Qoo</span>
                  </div>
                </div>
              )}
              {section === 'sequence' && (
                <ArrangeGrid
                  qoolection={qoolection}
                  dispatch={dispatch}
                  updateQoolection={updateQoolection}
                />
              )}
              {section === 'menu' && (
                <div className="qoolection__menu-section">
                  <div
                    style={{
                      position: 'relative',
                      width: '341px',
                      height: '626px',
                      overflowY: 'auto',
                      padding: '60px 24px',
                      backgroundColor: activeTheme.data.headerFooter.header.menuOverlayColor
                        ? `rgba(${activeTheme.data.headerFooter.header.menuOverlayColor.rgb.r}, ${activeTheme.data.headerFooter.header.menuOverlayColor.rgb.g}, ${activeTheme.data.headerFooter.header.menuOverlayColor.rgb.b}, ${activeTheme.data.headerFooter.header.menuOverlayColor.rgb.a})`
                        : '#F4F6F7',
                      display: 'flex',
                      flexDirection: 'column',
                      // gap: '36px',
                      alignItems: activeTheme.data.headerFooter.header.menuOverlayTextAlign,
                      color: activeTheme.data.headerFooter.header.menuOverlayTextColor?.hex,
                      fontFamily: activeTheme.data.headerFooter.header.menuOverlayFont?.name,
                      fontWeight: activeTheme.data.headerFooter.header.menuOverlayFont?.weight,
                      fontStyle: activeTheme.data.headerFooter.header.menuOverlayFont?.style,
                      fontSize:
                        activeTheme.data.headerFooter.header.menuOverlayFontSize === 'S'
                          ? '0.75em'
                          : activeTheme.data.headerFooter.header.menuOverlayFontSize === 'L'
                          ? '1.5em'
                          : '1em'
                    }}>
                    {menuLinks.length > 0 &&
                      menuLinks.map((item, index) => {
                        return (
                          <span
                            style={{ marginBottom: '36px' }}
                            key={`temporaryQoolectionMenuLink__${index}`}>
                            {item.text}
                          </span>
                        );
                      })}
                    {qoolectionMenuText && (
                      <div
                        style={{
                          fontSize: '14px',
                          width: '100%',
                          paddingTop: '30px',
                          borderTop: `1px solid ${activeTheme.data.headerFooter.header.menuOverlayTextColor?.hex}`
                        }}
                        dangerouslySetInnerHTML={{
                          __html: temporaryQoolectionMenuText
                        }}></div>
                    )}
                    <div
                      style={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px'
                      }}>
                      <CloseIcon style={{ width: '24px', height: '24px' }} />
                    </div>
                  </div>
                </div>
              )}
              {section === 'add-info' && (
                <div className="qoolection__droppable-qoos-list qoolection__droppable-qoos-list--add-info">
                  {qoolectionQoos.length > 0 &&
                    qoolectionQoos.map((qoo, index) => {
                      return (
                        <div
                          key={`${index}-${qoo._id}`}
                          className={
                            qoolectionQoos.length - 1 === index
                              ? 'qoolection__qoo-card qoolection__qoo-card--in-info qoolection__qoo-card--in-info--last'
                              : 'qoolection__qoo-card qoolection__qoo-card--in-info'
                          }>
                          <QooDisplay qooInPreview={qoo} width={140} height={265} />
                          <span className="qoolection__qoo-card-title">{qoo.title}</span>
                          <QooCardDropdownMenu
                            user={currentuser}
                            dispatch={dispatch}
                            qoo={qoo}
                            index={index}
                            duplicateQoo={duplicateQoo}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </Content>
          </Layout>
          {qooDuplicate && (
            <DuplicateModal
              visible={qooDuplicate}
              closeModal={() => setQooDuplicate(null)}
              handleDuplicate={() => {
                duplicateQoo(qooDuplicate);
                setQooDuplicate(null);
              }}
            />
          )}
          {qooDuplicatesFromQoolection && (
            <QooDuplicatesFromQoolectionModal
              qoos={qooDuplicatesFromQoolection}
              closeModal={() => setQooDuplicatesFromQoolection(null)}
              duplicateMultipleQoos={duplicateMultipleQoos}
            />
          )}
          {previewModalVisible && (
            <QoolectionPreviewModal
              visible={previewModalVisible}
              closeModal={() => setPreviewModalVisible(false)}
              qoolection={qoolection}
            />
          )}
          {qoolectionInDrawerPreview && (
            <PreviewDrawer
              qoolection={qoolectionInDrawerPreview}
              setQoolectionInDrawerPreview={setQoolectionInDrawerPreview}
              // addQoosFromAnotherQoolection={addQoosFromAnotherQoolection}
              duplicateQoolection={duplicateQoolection}
              showPreviewModal={showPreviewModal}
              setShowPreviewModal={setShowPreviewModal}
            />
          )}
          {qooInDrawerPreview && (
            <QooPreviewDrawerModal
              qoo={qooInDrawerPreview}
              setQooInDrawerPreview={setQooInDrawerPreview}
              // addQoo={addQoo}
              addQoo={duplicateQoo}
            />
          )}
          {approvalModalVisible && (
            // TODO: add all missing props. Just like in Qoolections.js (search for ApprovalModal component).
            <ApprovalModal
              qoolection={qoolection}
              visible={approvalModalVisible}
              closeModal={() => setApprovalModalVisible(false)}
              // exportToPdf={exportToPdfHandler}
              SERVER_URL={SERVER_URL}
              currentuser={currentuser}
              triggeredBy="EditQoolection"
            />
          )}
          {qoolectionForExport && (
            <ExportQoolectionPdfModal
              visible={qoolectionForExport}
              closeModal={() => setQoolectionForExport(null)}
              qoolection={qoolectionForExport}
              SERVER_URL={SERVER_URL}
              currentuser={currentuser}
              setLoading={setLoading}
              loading={loading}
              qoolections={qoolections}
            />
          )}
          <Modal
            style={{ margin: '0', right: 'calc(-100vw + 520px)', top: '80px' }}
            open={saveModalVisible}
            mask={false}
            closable={false}
            onCancel={() => {
              dispatch({
                type: EDIT_QOOLECTION_PROPERTY_WITHOUT_HISTORY,
                payload: {
                  title: qoolection?.title
                }
              });
              qoolectionTitleForm.setFieldValue('title', qoolection?.title);
              setSaveModalVisible(false);
            }}
            onOk={() => {
              qoolectionTitleForm
                .validateFields()
                .then(async () => {
                  const formValues = qoolectionTitleForm.getFieldsValue(true);
                  await onSubmit({ ...qoolection, ...temporaryQoolection, ...formValues });
                  setSaveModalVisible(false);
                })
                .catch((err) => {
                  console.log(err);
                });
            }}>
            <Form
              form={qoolectionTitleForm}
              initialValues={{ title: temporaryQoolection?.title }}
              className="edit-theme-save-modal">
              <Form.Item
                label="Title"
                rules={[{ required: true, message: 'Please enter title!' }]}
                name="title"
                labelAlign="left">
                <Input
                  onChange={(e) =>
                    dispatch({
                      type: EDIT_QOOLECTION_PROPERTY_WITHOUT_HISTORY,
                      payload: {
                        title: e.target.value
                      }
                    })
                  }
                  className="edit-theme-save-modal__input"
                  placeholder="Type here"
                />
              </Form.Item>
            </Form>
          </Modal>
        </Layout>
      </Content>
    </Layout>
  );
};

export default EditQoolection;
