import { Button, Modal } from 'antd';
import React from 'react';
import QooDisplay from "./QooDisplay";
const TemplateModal = ({ visible, closeModal, qoo, useTemplate }) => {
    return (
        <Modal
            visible={visible}
            onCancel={closeModal}
            footer={null}
            bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '16px'
            }}
        >
            <h2>{qoo.name}</h2>
            <QooDisplay
                qooInPreview={qoo}
                width={384}
                height={716}
            />
            <div
                style={{
                    alignSelf: 'stretch',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px'
                }}
            >
                <Button
                    onClick={closeModal}
                    style={{ flex: '1' }}
                >
                    Cancel
                </Button>
                <Button
                    type='primary'
                    style={{ flex: '1' }}
                    onClick={useTemplate}
                >
                    Use as template
                </Button>
            </div>
        </Modal>
    );
};

export default TemplateModal;