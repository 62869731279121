import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../App';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from '../../config';
import ProtectedRoute from './ProtectedRoute';
import Login from '../../pages/Login';
import Home from '../../pages/Home';
import ForgotPassword from '../../pages/ForgotPassword';
import ResetPassword from '../../pages/ResetPassword';
import Library from '../../pages/library/Library';
import Analyze from "../../pages/analyze/Analyze";
import PublicRoute from './PublicRoutes';
import PublishedQoolection from '../../pages/published/PublishedViewer';

const Routes = () => {
  const user = useContext(UserContext);

  return (
    <Switch>
      <PublicRoute path="/login" isAuthenticated={user.isLoggedIn}>
        <Login />
      </PublicRoute>

      <PublicRoute path="/forgot-password" isAuthenticated={user.isLoggedIn}>
        <ForgotPassword />
      </PublicRoute>

      <PublicRoute path="/reset-password/:token" isAuthenticated={user.isLoggedIn}>
        <ResetPassword />
      </PublicRoute>

      <PublicRoute path="/email-verification/:token" isAuthenticated={user.isLoggedIn}>
        EMAIL VERIFICATION PAGE: TODO
      </PublicRoute>

      <PublicRoute path="/email-verified/:token" isAuthenticated={user.isLoggedIn}>
        <ResetPassword />
      </PublicRoute>

      <PublicRoute path="/published-qoolection/:qoolectionToken" isAuthenticated={user.isLoggedIn}>
        <PublishedQoolection />
      </PublicRoute>

      <ProtectedRoute
        user={user}
        exact
        path='/admin'
        component={Home}
        allowed={['superadmin', 'admin', 'creator', 'monitor', 'agency']}
      />

      {/* routes from routeConfig file */}
      {ROUTES.map((route) => {
        if (route.children) {
          return route.children.map((item, i) => (
            <ProtectedRoute
              exact
              user={user}
              key={`R_${i}`}
              path={item.path}
              component={item.component}
              allowed={item.allowed}
            />
          ));
        }
      })}

      <ProtectedRoute
        user={user}
        exact
        path='/admin/library'
        component={Library}
        allowed={['superadmin', 'admin', 'creator', 'monitor', 'agency']}
      />

      <ProtectedRoute
        user={user}
        exact
        path='/admin/analyze'
        component={Analyze}
        allowed={['superadmin', 'admin', 'creator', 'monitor', 'agency']}
      />

      <Route render={() => <Redirect to='/admin' />} />
    </Switch >
  );
};

export default Routes;
