import { ReactComponent as NoDataIcon } from '../../icons/no-data.svg';

export default function NoData({ noMargin = false }) {
  return (
    <div
      style={{
        width: '100%',
        margin: noMargin ? '0' : '3rem 0',
        display: 'flex',
        maxHeight: '160px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <NoDataIcon />
      <p
        style={{
          color: '#333333',
          fontSize: '1.5rem',
          fontWeight: '600',
          fontFamily: 'Titillium Web, sans-serif',
          marginBottom: '0',
          marginTop: '1rem'
        }}>
        Sorry, there’s nothing here.. Yet!
      </p>
    </div>
  );
}
