import React from 'react';
import { Collapse } from 'antd';
import { ReactComponent as DownCaret } from '../../icons/down-caret.svg';
import { ReactComponent as UpCaret } from '../../icons/up-caret.svg';
import { ReactComponent as EyeIcon } from '../../icons/ph_eye-light.svg';
import { ReactComponent as CanvasIcon } from '../../icons/ph_selection-all-light.svg';
import { ReactComponent as BlockIcon } from '../../icons/Block-icon.svg';
import { ReactComponent as TrashIcon } from '../../icons/ph_trash-light.svg';
import { ReactComponent as ExpandIcon } from '../../icons/ph_arrows-out-simple-light.svg';
import { ReactComponent as CollapseIcon } from '../../icons/ph_arrows-in-simple-light.svg';
import { TOGGLE_HIDE_SHOW_LAYER, MOVE_LAYER_DOWN, MOVE_LAYER_UP, SET_BLOCKS_LAYOUT, TOGGLE_HIDE_SHOW_BACKGROUND } from '../../reducers/block-in-preview/actionTypes';
import { BLOCK_TYPES_ICONS } from '../../config';
import GridLayout from 'react-grid-layout';
import { TOGGLE_HIDE_SHOW_LAYER_Q, MOVE_LAYER_UP_Q, MOVE_LAYER_DOWN_Q, SET_BLOCKS_LAYOUT_Q } from '../../reducers/qoo-in-preview/actionTypes';


const { Panel } = Collapse;

const LayersPanel = ({ layers, blockInPreview, selectedBlock, setSelectedBlock, setSelectedBlockType, dispatch, setDeleteModalVisible, dockToRightSidebar, inSidebar, collapseSidebar, rightPanel }) => {

  const toggleHideShow = (e, blockId) => {
    e.stopPropagation();

    if (blockInPreview) {
      dispatch({
        type: TOGGLE_HIDE_SHOW_LAYER,
        payload: {
          blockId,
        }
      });
    } else {
      dispatch({
        type: TOGGLE_HIDE_SHOW_LAYER_Q,
        payload: {
          blockId,
        }
      });
    }
  };

  const toggleHideShowBackground = (e, blockId) => {
    e.stopPropagation();

    if (blockInPreview) {
      dispatch({
        type: TOGGLE_HIDE_SHOW_BACKGROUND,
        payload: {
          blockId,
        }
      });
    } else {
      dispatch({
        type: TOGGLE_HIDE_SHOW_LAYER_Q,
        payload: {
          blockId,
        }
      });
    }
  };

  const handleMoveLayerUp = (index) => {
    if (blockInPreview) {
      dispatch({
        type: MOVE_LAYER_UP,
        payload: {
          index
        }
      });
    } else {
      dispatch({
        type: MOVE_LAYER_UP_Q,
        payload: {
          index
        }
      });
    }
  };

  const handleMoveLayerDown = (index) => {
    if (blockInPreview) {
      dispatch({
        type: MOVE_LAYER_DOWN,
        payload: {
          index
        }
      });
    } else {
      dispatch({
        type: MOVE_LAYER_DOWN_Q,
        payload: {
          index
        }
      });
    }
  };

  const getTextFromHTMLString = (str) => {
    const span = document.createElement('span');
    span.innerHTML = str;

    return span.textContent || span.innerText;
  };

  let reversedLayout = [...layers].reverse();
  let layout = [];
  if (reversedLayout && reversedLayout.length > 0) {
    layout = reversedLayout.map((item, index) => ({
      i: item.block._id,
      x: 0,
      y: index,
      w: 1,
      h: 1
    }));
  }

  const handleLayoutChange = (layout) => {
    const sortedLayout = [...layout].sort((a, b) => a.y - b.y).reverse();
    let newBlocksLayout = [];
    sortedLayout.forEach(gridItem => {
      layers.forEach(item => {
        if (item.block._id === gridItem.i) {
          newBlocksLayout.push(item);
        }
      });
    });

    if (blockInPreview) {
      dispatch({
        type: SET_BLOCKS_LAYOUT,
        payload: {
          newBlocksLayout
        }
      });
    } else {
      dispatch({
        type: SET_BLOCKS_LAYOUT_Q,
        payload: {
          newBlocksLayout
        }
      });
    }

  };

  return (
    <div className={inSidebar ? "layers-panel layers-panel--in-sidebar" : "layers-panel"} >
      <Collapse
        ghost
        defaultActiveKey={rightPanel === 'layers' ? '1' : null}
      >
        <Panel
          header={(
            <h3 className='pre-title'>
              Layers
            </h3>
          )}
          key='1'
          collapsible='header'
          extra={inSidebar ? (
            <CollapseIcon onClick={() => collapseSidebar('layers')} />
          ) : (
            <ExpandIcon onClick={dockToRightSidebar} />
          )}
          showArrow={false}
        >
          <div className="layers-panel__list" >
            <GridLayout
              className='layout'
              layout={layout}
              cols={1}
              rowHeight={52}
              width={inSidebar ? 355 : 344}
              height={reversedLayout.length * 52}
              margin={[0, 0]}
              isResizable={false}
              maxRows={reversedLayout.length}
              onDragStop={(layout) => handleLayoutChange(layout)}
              isBounded={true}
            >
              {reversedLayout && reversedLayout.length > 0 && reversedLayout.map((item, index, arr) => {
                return (
                  <div
                    key={item.block._id}
                    className={selectedBlock === item.block._id ? "layers-panel__item layers-panel__item--selected" : "layers-panel__item"}
                    onClick={() => {
                      if (item.block.isMulti) {
                        setSelectedBlockType({ name: 'my-blocks' });
                      } else {
                        setSelectedBlockType(item.block.blockType);
                      }
                      setSelectedBlock(item.block._id);
                    }}
                  >
                    <div className="layers-panel__arrows">
                      <UpCaret
                        className={index === 0 ? 'layers-panel__arrow layers-panel__arrow--disabled' : 'layers-panel__arrow'}
                        onClick={index === 0 ? null : () => handleMoveLayerUp(reversedLayout.length - index - 1)}
                      />
                      <DownCaret
                        className={index === arr.length - 1 ? 'layers-panel__arrow layers-panel__arrow--disabled' : 'layers-panel__arrow'}

                        onClick={index === arr.length - 1 ? null : () => handleMoveLayerDown(reversedLayout.length - index - 1)}
                      />
                    </div>
                    <div className="layers-panel__block-type-icon">
                      {item.block.isMulti ? (
                        <BlockIcon />
                      ) : (
                        <>
                          {/* OVDE STAVITI PROVERU ZA BLOKTYPE 'MEDIA' DA SE PRIKAZE RAZLICITA IKONICA ZA VIDEO, AUDIO I IMAGE */}
                          {BLOCK_TYPES_ICONS[`${item.block.blockType.name}`].icon}
                        </>
                      )}

                    </div>
                    <span className="layers-panel__block-type-name">
                      {item.block.isMulti ? (
                        item.block.name
                      ) : (
                        item.block.blockType.name === 'text' ? (
                          getTextFromHTMLString(item.block.blockProperties.textContent)
                        ) : item.block.blockType.name === 'button' ? (
                          getTextFromHTMLString(item.block.blockProperties.buttonText)
                        ) : (item.block.blockType.name)
                      )

                      }
                    </span>
                    <TrashIcon
                      className='layers-panel__delete-icon'
                      onClick={() => setDeleteModalVisible(true)}
                    />
                    <EyeIcon
                      className={item.block.hidden ? 'layers-panel__show-hide-icon layers-panel__show-hide-icon--hidden' : 'layers-panel__show-hide-icon'}
                      onClick={(e) => toggleHideShow(e, item.block._id)}
                    />
                  </div>
                );
              })

              }
            </GridLayout>
          </div>
          {blockInPreview &&
            <div
              className={selectedBlock === blockInPreview?._id ? 'layers-panel__canvas layers-panel__canvas--selected' : 'layers-panel__canvas'}
              onClick={() => {
                setSelectedBlockType({ name: 'general' });
                setSelectedBlock(blockInPreview._id);
              }}
            >
              <CanvasIcon className='layers-panel__canvas-icon' />
              <span className="layers-panel__block-type-name">Background</span>
              <EyeIcon
                className={blockInPreview.hidden ? 'layers-panel__show-hide-icon layers-panel__show-hide-icon--hidden' : 'layers-panel__show-hide-icon'}
                onClick={(e) => toggleHideShowBackground(e, blockInPreview._id)}
              />
            </div>
          }
        </Panel>
      </Collapse>
    </div>

  );
};

export default LayersPanel;