export default function Footer() {
  return (
    <footer className="footer-component">
      <ul className="footer-links">
        <li className="">&copy; 2023 Qoolo Inc</li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://www.qclinical.app/terms-of-service">
            Terms of Service
          </a>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://www.qoolo.com/privacy-policy">
            Privacy Policy
          </a>
        </li>
      </ul>
    </footer>
  );
}
