import React from 'react';
import { Dropdown } from 'antd';
import { ReactComponent as DownCaret } from '../../icons/down-caret.svg';

const ColorPickerDropdown = ({ colors, colorsBlackWhiteGrey, callback, selectedColor, line, leftRadius, rightRadius, disabled, themeInPreviewNavStyle, notNullable }) => {
  const menu = (
    <div
      className='color-picker-dropdown__overlay'
    >
      <div className="color-picker-dropdown__overlay-row">
        {!notNullable &&
          <div className="color-picker-dropdown__overlay-color-field"
            onClick={() => callback(null)}
          >
            <span>&nbsp;</span>
          </div>
        }

        {colors && colors.length > 0 && colors.map((color, index) => {
          return (
            <div
              key={index}
              className="color-picker-dropdown__overlay-color-field"
              style={{ backgroundColor: color.hex }}
              onClick={() => callback(color)}
            >

            </div>
          );
        })}

      </div>
      <div className="color-picker-dropdown__overlay-row">
        {/* <div className="color-picker-dropdown__overlay-color-field"
          onClick={() => callback(null)}
        >
          <span>&nbsp;</span>
        </div> */}
        {colorsBlackWhiteGrey && colorsBlackWhiteGrey.length > 0 && colorsBlackWhiteGrey.map((color, index) => {
          return (
            <div
              key={index}
              className="color-picker-dropdown__overlay-color-field"
              style={{ backgroundColor: color.hex }}
              onClick={() => callback(color)}
            >

            </div>
          );
        })}

      </div>
    </div>
  );

  const borderRadius = `${leftRadius ? '3px' : '0'} ${rightRadius ? '3px' : '0'} ${rightRadius ? '3px' : '0'} ${leftRadius ? '3px' : '0'}`;

  return (
    <Dropdown
      trigger={['click']}
      overlay={menu}
      disabled={disabled}
    >
      <div
        className={disabled ? "color-picker-dropdown color-picker-dropdown--disabled" : "color-picker-dropdown"}
        style={{ borderRadius }}
      >
        <div
          className="color-picker-dropdown__color-field"
          style={{ backgroundColor: line ? '#fff' : (selectedColor ? selectedColor.hex : '#fff') }}
        >
          {line &&
            <div
              className="color-picker-dropdown__color-field-line"
              style={{ backgroundColor: selectedColor ? selectedColor.hex : 'red' }}
            >
            </div>
          }
          {!selectedColor && !line && <span>&nbsp;</span>}
        </div>
        <DownCaret className="color-picker-dropdown__arrow" />
      </div>
    </Dropdown>
  );
};

export default ColorPickerDropdown;