import React, { useContext, useEffect } from 'react';
import { Input, Select, Radio, Space, Checkbox } from 'antd';
import ColorPickerDropdown from '../../components/elements/ColorPickerDropdown';
import { ReactComponent as AlignLeft } from '../../icons/align-text-left.svg';
import { ReactComponent as AlignCenter } from '../../icons/align-text-center.svg';
import { ReactComponent as AlignRight } from '../../icons/align-text-right.svg';
import { ReactComponent as OpenLink } from '../../icons/open-link.svg';
import { UPDATE_HEADERFOOTER_PROPERTY } from '../../reducers/theme-in-preview/actionTypes';
import useAxios from '../../hooks/useAxios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';

const FooterTab = ({ themeInPreview, dispatch }) => {
  const currentuser = useContext(UserContext);
  const [files, fetchFiles] = useAxios('', null, currentuser.data.token, 'get');

  useEffect(() => {
    let filter = { type: 'common' };
    fetchFiles(
      `${SERVER_URL}/files?filter=${JSON.stringify(filter)}&user=${currentuser.data.id}`,
      null,
      currentuser.data.token,
      'get'
    );
  }, [fetchFiles, currentuser.data.token]);

  // console.log('files: ', files);

  return (
    <>
      <p className="new-theme-builder__intro-text">
        How many sections would you like in your Footer?
      </p>
      <div className="new-theme-builder__hf-section">
        <div className="new-theme-builder__hf-footer-size">
          <Radio.Group
            // disabled={themeInPreview.headerFooter.footer.noFooter}
            value={
              themeInPreview.headerFooter.footer.noFooter
                ? null
                : themeInPreview.headerFooter.footer.bottomFooterColumnsNum
            }
            onChange={(e) => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'footer',
                  updates: {
                    bottomFooterColumnsNum: e.target.value,
                    noFooter: false
                  }
                }
              });
            }}>
            <Space direction="vertical">
              <Radio value={1}>One section</Radio>
              <Radio value={2}>Two sections</Radio>
              <Radio value={3}>Three sections</Radio>
            </Space>
          </Radio.Group>
        </div>
        <div className="new-theme-builder__hf-footer-size">
          <Radio
            checked={themeInPreview.headerFooter.footer.noFooter}
            onClick={() => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'footer',
                  updates: {
                    bottomFooterColumnsNum: null,
                    noFooter: true
                  }
                }
              });
            }}>
            I changed my mind. I don't need a footer.
          </Radio>
        </div>
      </div>
      <p className="new-theme-builder__intro-text">Please style your Footer:</p>
      <div className="new-theme-builder__hf-style-row">
        <div className="new-theme-builder__hf-style-label new-theme-builder__hf-style-label--fl-start">
          <span className="new-theme-builder__small-text">Footer Background</span>
        </div>
        <div className="new-theme-builder__hf-toolbar">
          <ColorPickerDropdown
            colors={themeInPreview.colorPalette.colors}
            colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
            selectedColor={themeInPreview.headerFooter.footer.bottomFooterBackgroundColor}
            disabled={themeInPreview.headerFooter.footer.noFooter}
            leftRadius
            rightRadius
            callback={(color) => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'footer',
                  updates: {
                    bottomFooterBackgroundColor: color
                  }
                }
              });
            }}
          />
          <Checkbox
            disabled={themeInPreview.headerFooter.footer.noFooter}
            checked={themeInPreview.headerFooter.footer.bottomFooterDropshadow}
            onChange={() => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'footer',
                  updates: {
                    bottomFooterDropshadow:
                      !themeInPreview.headerFooter.footer.bottomFooterDropshadow
                  }
                }
              });
            }}>
            Drop Shadow
          </Checkbox>
          {/* <div
            className={
              themeInPreview.headerFooter.footer.noFooter
                ? 'choice-btn choice-btn--sm  choice-btn--disabled'
                : themeInPreview.headerFooter.footer.bottomFooterDropshadow
                ? 'choice-btn choice-btn--sm  choice-btn--selected'
                : 'choice-btn choice-btn--sm'
            }
            onClick={() => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'footer',
                  updates: {
                    bottomFooterDropshadow:
                      !themeInPreview.headerFooter.footer.bottomFooterDropshadow
                  }
                }
              });
            }}>
            Drop Shadow
          </div> */}
        </div>
      </div>
      <div className="new-theme-builder__hf-style-row">
        <div className="new-theme-builder__hf-style-label new-theme-builder__hf-style-label--fl-start">
          <span className="new-theme-builder__small-text">Footer Text</span>
        </div>
        <div className="new-theme-builder__hf-toolbar">
          <Select
            className="dropdown-small new-theme-builder__hf-footer-font-select"
            placeholder="Choose font"
            disabled={themeInPreview.headerFooter.footer.noFooter}
            value={themeInPreview.headerFooter.footer.bottomFooterFont?._id}
            onChange={(value) => {
              const chosenFont = themeInPreview.selectedFonts.find((font) => font._id === value);
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'footer',
                  updates: {
                    bottomFooterFont: chosenFont
                  }
                }
              });
            }}>
            {themeInPreview.selectedFonts.length > 0 &&
              themeInPreview.selectedFonts.map((font) => {
                return (
                  <Select.Option key={font._id} value={font._id}>
                    <div
                      style={{
                        fontFamily: font.name,
                        fontWeight: font.weight,
                        fontStyle: font.style
                      }}>
                      {font.name}
                    </div>
                  </Select.Option>
                );
              })}
          </Select>
          <Select
            className="dropdown-small new-theme-builder__hf-menu-font-size-select"
            placeholder="Size"
            disabled={themeInPreview.headerFooter.footer.noFooter}
            value={themeInPreview.headerFooter.footer.bottomFooterFontSize}
            onChange={(value) => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'footer',
                  updates: {
                    bottomFooterFontSize: value
                  }
                }
              });
            }}>
            <Select.Option value="S">Small</Select.Option>
            <Select.Option value="M">Medium</Select.Option>
            <Select.Option value="L">Large</Select.Option>
          </Select>
          <ColorPickerDropdown
            colors={themeInPreview.colorPalette.colors}
            colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
            selectedColor={themeInPreview.headerFooter.footer.bottomFooterTextColor}
            disabled={themeInPreview.headerFooter.footer.noFooter}
            leftRadius
            rightRadius
            callback={(color) => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'footer',
                  updates: {
                    bottomFooterTextColor: color
                  }
                }
              });
            }}
          />
          {!themeInPreview.headerFooter.footer.noFooter && (
            <div className="h-f-create-new__toolbar-group">
              <div
                className={
                  themeInPreview.headerFooter.footer.bottomFooterTextAlign === 'flex-start'
                    ? 'h-f-create-new__text-align-btn h-f-create-new__text-align-btn--selected'
                    : 'h-f-create-new__text-align-btn'
                }
                onClick={() => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'footer',
                      updates: {
                        bottomFooterTextAlign: 'flex-start'
                      }
                    }
                  });
                }}>
                <AlignLeft />
              </div>
              <div
                className={
                  themeInPreview.headerFooter.footer.bottomFooterTextAlign === 'center'
                    ? 'h-f-create-new__text-align-btn h-f-create-new__text-align-btn--selected'
                    : 'h-f-create-new__text-align-btn'
                }
                onClick={() => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'footer',
                      updates: {
                        bottomFooterTextAlign: 'center'
                      }
                    }
                  });
                }}>
                <AlignCenter />
              </div>
              <div
                className={
                  themeInPreview.headerFooter.footer.bottomFooterTextAlign === 'flex-end'
                    ? 'h-f-create-new__text-align-btn h-f-create-new__text-align-btn--selected'
                    : 'h-f-create-new__text-align-btn'
                }
                onClick={() => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'footer',
                      updates: {
                        bottomFooterTextAlign: 'flex-end'
                      }
                    }
                  });
                }}>
                <AlignRight />
              </div>
            </div>
          )}
        </div>
      </div>

      {themeInPreview.headerFooter.footer.bottomFooterColumnsNum &&
        themeInPreview.headerFooter.footer.bottomFooterColumnsNum > 0 &&
        !themeInPreview.headerFooter.footer.noFooter && (
          <>
            <div className="flex-basis-correction-footer">
              <div className="new-theme-builder__hf-style-row">
                <div className="new-theme-builder__hf-style-label new-theme-builder__hf-style-label--fl-start">
                  <span className="new-theme-builder__small-text">Footer Section 1</span>
                </div>
                <div className="new-theme-builder__hf-footer-section">
                  <Input
                    className="new-theme-builder__hf-text-content-input"
                    placeholder="Section text"
                    value={themeInPreview.headerFooter.footer.bottomFooterColumn1TextContent}
                    onChange={(e) => {
                      dispatch({
                        type: UPDATE_HEADERFOOTER_PROPERTY,
                        payload: {
                          section: 'footer',
                          updates: {
                            bottomFooterColumn1TextContent: e.target.value
                          }
                        }
                      });
                    }}
                  />
                  <div className="new-theme-builder__hf-footer-bottom-inputs">
                    <Select
                      className="dropdown-small new-theme-builder__hf-footer-linkto-select"
                      placeholder="Link to"
                      value={themeInPreview.headerFooter.footer.bottomFooterColumn1LinkTo}
                      onChange={(value) => {
                        dispatch({
                          type: UPDATE_HEADERFOOTER_PROPERTY,
                          payload: {
                            section: 'footer',
                            updates: {
                              bottomFooterColumn1LinkTo: value
                            }
                          }
                        });
                      }}>
                      <Select.Option value="web link" label="Web Link">
                        Web Link
                      </Select.Option>
                      <Select.Option value="qoo link" label="Link Qoo">
                        Qoo Link
                      </Select.Option>
                      <Select.Option value="footnotes" label="Footnotes">
                        Footnotes
                      </Select.Option>
                      <Select.Option value="plain text" label="Plain text">
                        Plain Text
                      </Select.Option>
                      <Select.Option value="pdf" label="Open PDF">
                        Open PDF
                      </Select.Option>
                    </Select>
                    {themeInPreview?.headerFooter?.footer?.bottomFooterColumn1LinkTo === 'pdf' ? (
                      <Select
                        placeholder="Select a PDF file"
                        value={themeInPreview.headerFooter.footer.bottomFooterColumn1Link}
                        style={{ flex: 'auto' }}
                        onChange={(value) => {
                          dispatch({
                            type: UPDATE_HEADERFOOTER_PROPERTY,
                            payload: {
                              section: 'footer',
                              updates: {
                                bottomFooterColumn1Link: value
                              }
                            }
                          });
                        }}>
                        {files?.data?.map((file) => (
                          <Select.Option value={file?.location}>{file?.originalname}</Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        className="new-theme-builder__hf-footer-link-input"
                        disabled={!themeInPreview.headerFooter.footer.bottomFooterColumn1LinkTo}
                        placeholder={
                          themeInPreview.headerFooter.footer.bottomFooterColumn1LinkTo
                            ? themeInPreview.headerFooter.footer.bottomFooterColumn1LinkTo ===
                              'web link'
                              ? 'https://www.website.com'
                              : themeInPreview.headerFooter.footer.bottomFooterColumn1LinkTo ===
                                'plain text'
                              ? 'Type here'
                              : themeInPreview.headerFooter.footer.bottomFooterColumn1LinkTo ===
                                'qoo link'
                              ? 'Search Qoos'
                              : themeInPreview.headerFooter.footer.bottomFooterColumn1LinkTo ===
                                'footnotes'
                              ? 'Search Footnotes'
                              : ''
                            : ''
                        }
                        suffix={
                          themeInPreview.headerFooter.footer.bottomFooterColumn1LinkTo ===
                          'web link' ? (
                            <a
                              className="new-theme-builder__hf-footer-external-link"
                              href={themeInPreview.headerFooter.footer.bottomFooterColumn1Link}
                              target="_blank">
                              <OpenLink />
                            </a>
                          ) : null
                        }
                        value={themeInPreview.headerFooter.footer.bottomFooterColumn1Link}
                        onChange={(e) => {
                          dispatch({
                            type: UPDATE_HEADERFOOTER_PROPERTY,
                            payload: {
                              section: 'footer',
                              updates: {
                                bottomFooterColumn1Link: e.target.value
                              }
                            }
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      {themeInPreview.headerFooter.footer.bottomFooterColumnsNum &&
        themeInPreview.headerFooter.footer.bottomFooterColumnsNum > 1 &&
        !themeInPreview.headerFooter.footer.noFooter && (
          <>
            <div className="new-theme-builder__hf-style-row">
              <div className="new-theme-builder__hf-style-label new-theme-builder__hf-style-label--fl-start">
                <span className="new-theme-builder__small-text">Footer Section 2</span>
              </div>
              <div className="new-theme-builder__hf-footer-section">
                <Input
                  className="new-theme-builder__hf-text-content-input"
                  placeholder="Section text"
                  value={themeInPreview.headerFooter.footer.bottomFooterColumn2TextContent}
                  onChange={(e) => {
                    dispatch({
                      type: UPDATE_HEADERFOOTER_PROPERTY,
                      payload: {
                        section: 'footer',
                        updates: {
                          bottomFooterColumn2TextContent: e.target.value
                        }
                      }
                    });
                  }}
                />
                <div className="new-theme-builder__hf-footer-bottom-inputs">
                  <Select
                    className="dropdown-small new-theme-builder__hf-footer-linkto-select"
                    placeholder="Link to"
                    value={themeInPreview.headerFooter.footer.bottomFooterColumn2LinkTo}
                    onChange={(value) => {
                      dispatch({
                        type: UPDATE_HEADERFOOTER_PROPERTY,
                        payload: {
                          section: 'footer',
                          updates: {
                            bottomFooterColumn2LinkTo: value
                          }
                        }
                      });
                    }}>
                    <Select.Option value="web link" label="Web Link">
                      Web Link
                    </Select.Option>
                    <Select.Option value="qoo link" label="Link Qoo">
                      Qoo Link
                    </Select.Option>
                    <Select.Option value="footnotes" label="Footnotes">
                      Footnotes
                    </Select.Option>
                    <Select.Option value="plain text" label="Plain text">
                      Plain Text
                    </Select.Option>
                    <Select.Option value="" label="None">
                      None
                    </Select.Option>
                  </Select>
                  <Input
                    className="new-theme-builder__hf-footer-link-input"
                    disabled={!themeInPreview.headerFooter.footer.bottomFooterColumn2LinkTo}
                    placeholder={
                      themeInPreview.headerFooter.footer.bottomFooterColumn2LinkTo
                        ? themeInPreview.headerFooter.footer.bottomFooterColumn2LinkTo ===
                          'web link'
                          ? 'https://www.website.com'
                          : themeInPreview.headerFooter.footer.bottomFooterColumn2LinkTo ===
                            'plain text'
                          ? 'Type here'
                          : themeInPreview.headerFooter.footer.bottomFooterColumn2LinkTo ===
                            'qoo link'
                          ? 'Search Qoos'
                          : themeInPreview.headerFooter.footer.bottomFooterColumn2LinkTo ===
                            'footnotes'
                          ? 'Search Footnotes'
                          : ''
                        : ''
                    }
                    suffix={
                      themeInPreview.headerFooter.footer.bottomFooterColumn2LinkTo ===
                      'web link' ? (
                        <a
                          className="new-theme-builder__hf-footer-external-link"
                          href={themeInPreview.headerFooter.footer.bottomFooterColumn2Link}
                          target="_blank">
                          <OpenLink />
                        </a>
                      ) : null
                    }
                    value={themeInPreview.headerFooter.footer.bottomFooterColumn2Link}
                    onChange={(e) => {
                      dispatch({
                        type: UPDATE_HEADERFOOTER_PROPERTY,
                        payload: {
                          section: 'footer',
                          updates: {
                            bottomFooterColumn2Link: e.target.value
                          }
                        }
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      {themeInPreview.headerFooter.footer.bottomFooterColumnsNum &&
        themeInPreview.headerFooter.footer.bottomFooterColumnsNum > 2 &&
        !themeInPreview.headerFooter.footer.noFooter && (
          <>
            <div className="new-theme-builder__hf-style-row">
              <div className="new-theme-builder__hf-style-label new-theme-builder__hf-style-label--fl-start">
                <span className="new-theme-builder__small-text">Footer Section 3</span>
              </div>
              <div className="new-theme-builder__hf-footer-section">
                <Input
                  className="new-theme-builder__hf-text-content-input"
                  placeholder="Section text"
                  value={themeInPreview.headerFooter.footer.bottomFooterColumn3TextContent}
                  onChange={(e) => {
                    dispatch({
                      type: UPDATE_HEADERFOOTER_PROPERTY,
                      payload: {
                        section: 'footer',
                        updates: {
                          bottomFooterColumn3TextContent: e.target.value
                        }
                      }
                    });
                  }}
                />
                <div className="new-theme-builder__hf-footer-bottom-inputs">
                  <Select
                    className="dropdown-small new-theme-builder__hf-footer-linkto-select"
                    placeholder="Link to"
                    value={themeInPreview.headerFooter.footer.bottomFooterColumn3LinkTo}
                    onChange={(value) => {
                      dispatch({
                        type: UPDATE_HEADERFOOTER_PROPERTY,
                        payload: {
                          section: 'footer',
                          updates: {
                            bottomFooterColumn3LinkTo: value
                          }
                        }
                      });
                    }}>
                    <Select.Option value="web link" label="Web Link">
                      Web Link
                    </Select.Option>
                    <Select.Option value="qoo link" label="Link Qoo">
                      Qoo Link
                    </Select.Option>
                    <Select.Option value="footnotes" label="Footnotes">
                      Footnotes
                    </Select.Option>
                    <Select.Option value="plain text" label="Plain text">
                      Plain Text
                    </Select.Option>
                    <Select.Option value="" label="None">
                      None
                    </Select.Option>
                  </Select>
                  <Input
                    className="new-theme-builder__hf-footer-link-input"
                    disabled={!themeInPreview.headerFooter.footer.bottomFooterColumn3LinkTo}
                    placeholder={
                      themeInPreview.headerFooter.footer.bottomFooterColumn3LinkTo
                        ? themeInPreview.headerFooter.footer.bottomFooterColumn3LinkTo ===
                          'web link'
                          ? 'https://www.website.com'
                          : themeInPreview.headerFooter.footer.bottomFooterColumn3LinkTo ===
                            'plain text'
                          ? 'Type here'
                          : themeInPreview.headerFooter.footer.bottomFooterColumn3LinkTo ===
                            'qoo link'
                          ? 'Search Qoos'
                          : themeInPreview.headerFooter.footer.bottomFooterColumn3LinkTo ===
                            'footnotes'
                          ? 'Search Footnotes'
                          : ''
                        : ''
                    }
                    suffix={
                      themeInPreview.headerFooter.footer.bottomFooterColumn3LinkTo ===
                      'web link' ? (
                        <a
                          className="new-theme-builder__hf-footer-external-link"
                          href={themeInPreview.headerFooter.footer.bottomFooterColumn3Link}
                          target="_blank">
                          <OpenLink />
                        </a>
                      ) : null
                    }
                    value={themeInPreview.headerFooter.footer.bottomFooterColumn3Link}
                    onChange={(e) => {
                      dispatch({
                        type: UPDATE_HEADERFOOTER_PROPERTY,
                        payload: {
                          section: 'footer',
                          updates: {
                            bottomFooterColumn3Link: e.target.value
                          }
                        }
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default FooterTab;
