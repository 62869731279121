import ReminderForm from './EventForms/ReminderForm';
import ExternalLinkForm from './EventForms/ExternalLinkForm';
import InternalLinkForm from './EventForms/InternalLinkForm';
import QAForm from './EventForms/QAForm';
import SelectOptionForm from './EventForms/SelectOptionForm';
import ChangeInitialQooForm from './EventForms/ChangeInitialQooForm';
import ChangeInitialQoolectionForm from './EventForms/ChangeInitialQoolectionForm';
import VerifyQuestionForm from './EventForms/VerifyQuestionForm';
import EndStudyForm from './EventForms/EndStudyForm';
import StartStudyForm from './EventForms/StartStudyForm';
import LockStudyForm from './EventForms/LockStudyForm';
import RefreshAppForm from './EventForms/RefreshAppForm';
import SubmitDataForm from './EventForms/SubmitDataForm';

function BlockEventsForm(props) {
  const {
    currentEvent,
    setCurrentEvent,
    blockEventHandler,
    eventIndex,
    publishedQoolections,
    qoos,
    questionsForBlockEvent,
    remindersForBlockEvent
  } = props;

  // console.log('questionsForBlockEvent: ', questionsForBlockEvent);
  switch (currentEvent?.eventType) {
    case 'ExternalLink': {
      return (
        <ExternalLinkForm
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          blockEventHandler={blockEventHandler}
          eventIndex={eventIndex}
        />
      );
    }
    case 'InternalLink': {
      return (
        <InternalLinkForm
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          blockEventHandler={blockEventHandler}
          eventIndex={eventIndex}
          qoos={qoos}
        />
      );
    }
    case 'QA': {
      return (
        <QAForm
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          blockEventHandler={blockEventHandler}
          eventIndex={eventIndex}
          questionsForBlockEvent={questionsForBlockEvent}
        />
      );
    }
    case 'SelectOption': {
      return (
        <SelectOptionForm
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          blockEventHandler={blockEventHandler}
          eventIndex={eventIndex}
          questionsForBlockEvent={questionsForBlockEvent}
        />
      );
    }
    case 'ChangeInitialQoo': {
      return (
        <ChangeInitialQooForm
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          blockEventHandler={blockEventHandler}
          eventIndex={eventIndex}
          qoos={qoos}
        />
      );
    }
    case 'ChangeInitialQoolection': {
      return (
        <ChangeInitialQoolectionForm
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          blockEventHandler={blockEventHandler}
          eventIndex={eventIndex}
          publishedQoolections={publishedQoolections}
        />
      );
    }
    case 'Reminder': {
      return (
        <ReminderForm
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          qoos={qoos}
          remindersForBlockEvent={remindersForBlockEvent}
          blockEventHandler={blockEventHandler}
          eventIndex={eventIndex}
        />
      );
    }
    case 'EndStudy': {
      return (
        <EndStudyForm
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          blockEventHandler={blockEventHandler}
          eventIndex={eventIndex}
        />
      );
    }
    case 'StartStudy': {
      return (
        <StartStudyForm
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          blockEventHandler={blockEventHandler}
          eventIndex={eventIndex}
        />
      );
    }
    case 'LockStudy': {
      return (
        <LockStudyForm
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          blockEventHandler={blockEventHandler}
          eventIndex={eventIndex}
        />
      );
    }
    case 'RefreshApp': {
      return (
        <RefreshAppForm
          setCurrentEvent={setCurrentEvent}
          blockEventHandler={blockEventHandler}
          eventIndex={eventIndex}
        />
      );
    }
    case 'VerifyQuestion': {
      return (
        <VerifyQuestionForm
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          blockEventHandler={blockEventHandler}
          eventIndex={eventIndex}
          questionsForBlockEvent={questionsForBlockEvent}
        />
      );
    }
    case 'SubmitData': {
      return (
        <SubmitDataForm
          setCurrentEvent={setCurrentEvent}
          blockEventHandler={blockEventHandler}
          eventIndex={eventIndex}
        />
      );
    }
    default:
      return <div>Something went wrong! Please contact us for help or try again later.</div>;
  }
}

export default BlockEventsForm;
