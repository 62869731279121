import React, { useState, useContext } from 'react';
import { Upload, Select, notification, message } from 'antd';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as SearchIcon } from '../../icons/ph_magnifying-glass.svg';
import { ReactComponent as DeleteIcon } from '../../icons/ph_trash-light.svg';
import _ from 'lodash';
import {
  ADD_FONT,
  REMOVE_FONT,
  EDIT_THEME_PROPERTY,
} from '../../reducers/theme-in-preview/actionTypes';
import { notificationError } from '../../config/notificationOptions';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';

const TypographyTab = ({
  themeInPreview,
  dispatch,
  isNew

}) => {

  const currentuser = useContext(UserContext);
  const token = currentuser.data.token;

  const allFonts = currentuser.fonts;
  const [addFontVisible, setAddFontVisible] = useState(false);

  const fontUpload = (
    <div className="typography-tab-new__font-upload">
      <Upload
        action={`${SERVER_URL}/upload-font`}
        accept='.ttf,.woff,.woff2'
        name='font'
        headers={{
          Authorization: `Bearer ${token}`,
        }}
        beforeUpload={async (file) => {
          const isAllowedFormat = file.name.includes('ttf' || 'woff' || 'woff2');
          if (!isAllowedFormat) {
            notification.error({
              message: 'You can only upload TTF, WOFF or WOFF2 fonts!',
              placement: 'bottomRight',
              icon: notificationError.icon,
              className: notificationError.className,
              duration: notificationError.duration,
            });
          }
          const isAllowedSize = file.size / 1024 / 1024 < 0.2;
          if (!isAllowedSize) {
            notification.error({
              message: 'Font must smaller than 200KB!',
              placement: 'bottomRight',
              icon: notificationError.icon,
              className: notificationError.className,
              duration: notificationError.duration,
            });
          }
          return isAllowedFormat && isAllowedSize;
        }}
        onChange={({ file }) => {
          if (file.status === 'done') {
            if (file.response && file.response.file) {
              message.success(`${file.response.file.name} font uploaded successfully.`);

              const font = file.response.font;
              const newFont = {
                _id: font._id,
                family: font.fontFamily,
                name: font.name,
                style: font.fontType.toLowerCase().includes('italic') ? 'italic' : 'normal',
                weight: font.fontType.toLowerCase().includes('light')
                  ? 300
                  : font.fontType.toLowerCase().includes('semibold')
                    ? 600
                    : font.fontType.toLowerCase().includes('bold')
                      ? 700
                      : font.fontType.toLowerCase().includes('black')
                        ? 900
                        : 400,
                importUrl: font.location,
              };

              window.document.styleSheets[0].insertRule(`
                @font-face {
                  font-family: ${newFont.name};
                  src: url(${file.response.font.location});
                  font-weight: ${newFont.weight};
                  font-style: ${newFont.style};
                }
              `);
              currentuser.setInjectedFontFaceRulesIds((prev) => [...prev, font._id]);
              currentuser.setFonts((prev) => [...prev, newFont]);
            }
          } else if (file.status === 'error') {
            console.log('error', file.response.file);
          }
        }}
        showUploadList={false}
      >
        <span className="typography-tab-new__font-upload-text">+ Upload a font</span>
      </Upload>
    </div>
  );

  const handleAssignmentChange = (fontId, assignedProperty) => {
    const chosenFont = allFonts.find(font => font._id === fontId);

    if (themeInPreview.selectedFonts.findIndex(font => font._id === fontId) < 0) {
      dispatch({
        type: ADD_FONT,
        payload: {
          font: chosenFont,
        }
      });
    }
    dispatch({
      type: EDIT_THEME_PROPERTY,
      payload: {
        updates: {
          [`${assignedProperty}`]: chosenFont,
        },
      },
    });
  };

  return (
    <div className="typography-tab-new">
      {!isNew &&
        <h3 className='pre-title pre-title--dark'>Typography</h3>
      }
      <div className="typography-tab-new__row">
        <div className="typography-tab-new__text-label">
          <span>Heading</span>
        </div>
        <Select
          className='dropdown-small typography-tab-new__font-select'
          placeholder={(
            <div className='typography-tab-new__font-select-placeholder'>
              <SearchIcon />
              <span>Search font</span>
            </div>
          )}
          showSearch={true}
          value={themeInPreview.headingFont?._id}
          onChange={(value) => handleAssignmentChange(value, 'headingFont')}
          dropdownRender={menu => (
            <>
              {fontUpload}
              {menu}
            </>
          )}
        >
          {allFonts && allFonts.length > 0 && allFonts.map((font) => {
            return (
              <Select.Option
                key={font._id}
                value={font._id}
              >
                <div
                  style={{
                    fontFamily: font.name,
                    fontWeight: font.weight,
                    fontStyle: font.style,
                  }}
                >
                  {font.name}
                </div>
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div className="typography-tab-new__row">
        <div className="typography-tab-new__text-label">
          <span>Subheading</span>
        </div>
        <Select
          className='dropdown-small typography-tab-new__font-select'
          placeholder={(
            <div className='typography-tab-new__font-select-placeholder'>
              <SearchIcon />
              <span>Search font</span>
            </div>
          )}
          showSearch={true}
          value={themeInPreview.subheadingFont?._id}
          onChange={(value) => handleAssignmentChange(value, 'subheadingFont')}
          dropdownRender={menu => (
            <>
              {fontUpload}
              {menu}
            </>
          )}
        >
          {allFonts && allFonts.length > 0 && allFonts.map((font) => {
            return (
              <Select.Option
                key={font._id}
                value={font._id}
              >
                <div
                  style={{
                    fontFamily: font.name,
                    fontWeight: font.weight,
                    fontStyle: font.style,
                  }}
                >
                  {font.name}
                </div>
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div className="typography-tab-new__row">
        <div className="typography-tab-new__text-label">
          <span>Body Text</span>
        </div>
        <Select
          className='dropdown-small typography-tab-new__font-select'
          placeholder={(
            <div className='typography-tab-new__font-select-placeholder'>
              <SearchIcon />
              <span>Search font</span>
            </div>
          )}
          showSearch={true}
          value={themeInPreview.bodyTextFont?._id}
          onChange={(value) => handleAssignmentChange(value, 'bodyTextFont')}
          dropdownRender={menu => (
            <>
              {fontUpload}
              {menu}
            </>
          )}
        >
          {allFonts && allFonts.length > 0 && allFonts.map((font) => {
            return (
              <Select.Option
                key={font._id}
                value={font._id}
              >
                <div
                  style={{
                    fontFamily: font.name,
                    fontWeight: font.weight,
                    fontStyle: font.style,
                  }}
                >
                  {font.name}
                </div>
              </Select.Option>
            );
          })}
        </Select>
      </div>

      {themeInPreview.selectedFonts && themeInPreview.selectedFonts.length > 0 && themeInPreview.selectedFonts
        .filter(font => font._id !== themeInPreview.headingFont?._id && font._id !== themeInPreview.subheadingFont?._id && font._id !== themeInPreview.bodyTextFont?._id)
        .map(font => (
          <div className="typography-tab-new__row">
            <div className="typography-tab-new__text-label typography-tab-new__text-label--empty">
            </div>
            <div
              className="typography-tab-new__selected-font"
              style={{
                fontFamily: font.name,
                fontWeight: font.weight,
                fontStyle: font.style,
              }}
            >
              {font.name}
            </div>
            <div
              className="typography-tab-new__delete-icon"
              onClick={() => {
                dispatch({
                  type: REMOVE_FONT,
                  payload: {
                    id: font._id,
                  },
                });
              }}
            >
              <DeleteIcon />
            </div>
          </div>
        ))
      }
      {addFontVisible &&
        <div className="typography-tab-new__row">
          <div className="typography-tab-new__text-label typography-tab-new__text-label--empty">
          </div>
          <Select
            className='dropdown-small typography-tab-new__font-select'
            placeholder={(
              <div className='typography-tab-new__font-select-placeholder'>
                <SearchIcon />
                <span>Search font</span>
              </div>
            )}
            showSearch={true}
            onChange={(value) => {
              const chosenFont = allFonts.find(font => font._id === value);
              dispatch({
                type: ADD_FONT,
                payload: {
                  font: chosenFont,
                }
              });
              setAddFontVisible(false);
            }}
            dropdownRender={menu => (
              <>
                {fontUpload}
                {menu}
              </>
            )}
          >
            {allFonts && allFonts.length > 0 && allFonts
              .filter(font => !themeInPreview.selectedFonts.some(selectedFont => selectedFont._id === font._id))
              .map((font) => {
                return (
                  <Select.Option
                    key={font._id}
                    value={font._id}
                  >
                    <div
                      style={{
                        fontFamily: font.name,
                        fontWeight: font.weight,
                        fontStyle: font.style,
                      }}
                    >
                      {font.name}
                    </div>
                  </Select.Option>
                );
              })}
          </Select>
          <div
            className="typography-tab-new__delete-icon"
            onClick={() => setAddFontVisible(false)}
          >
            <DeleteIcon />
          </div>
        </div>
      }

      <div className="typography-tab-new__row">
        <div className="typography-tab-new__text-label typography-tab-new__text-label--empty">
        </div>
        <div
          className="typography-tab-new__add-font"
          onClick={() => setAddFontVisible(true)}
        >
          <PlusIcon />
          <span>Add another font</span>
        </div>
      </div>
    </div>
  );
};

export default TypographyTab;
