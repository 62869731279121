import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import BlockListGrid from './BlockListGrid';

const BlockTemplatesList = ({ token, blockType, addBlockToLayout, addEmptyBlock }) => {
  const [templates, fetchTemplates] = useAxios('', {}, token, 'get');
  const [searchFilter, setSearchFilter] = useState('');

  useEffect(() => {
    fetchTemplates(`${SERVER_URL}/blocks-templates`, []);
  }, [fetchTemplates]);

  let filteredTemplates;

  if (templates.data.items) filteredTemplates = templates.data.items
    .filter((template) => template.blockType && template.blockType._id === blockType._id)
    .filter((template) => {
      const searchMatch = searchFilter && template.name ? template.name.toLowerCase().includes(searchFilter.toLocaleLowerCase()) : true;

      return searchMatch;
    });

  return (
    <>
      {templates.isLoading &&
        <div style={{ textAlign: 'center' }}>
          <LoadingOutlined spin />
        </div>
      }
      {!templates.isLoading && filteredTemplates &&
        <div
          className='block-templates'
        >
          <Input
            placeholder='Search Templates'
            prefix={<SearchOutlined />}
            allowClear
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
          />
          <BlockListGrid
            blocks={filteredTemplates}
            blockType={blockType}
            handleClick={addBlockToLayout}
            handleNew={addEmptyBlock}
          />
        </div>
      }
    </>
  );
};

export default BlockTemplatesList;
