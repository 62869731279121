import {Button, Modal} from "antd";
import {Link} from "react-router-dom";
import React from "react";
import {useHistory} from "react-router-dom";
import ThemeCard from "../../pages/themes/ThemeCard";
import {SERVER_URL} from "../../config";

const ThemeModal = ({ visibleModal, message, themes, activeThemes, currentuser, activateTheme }) => {
    const history = useHistory();

    return(
        <Modal
            title={<h2 style={{textAlign: 'center', fontSize: '24px', fontWeight: '600', fontFamily: 'Titillium Web'}}> {message}</h2>}
            className='delete-modal'
            visible={visibleModal}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { justifySelf: 'center' } }}
            onOk={() => history.push('/admin/themes')}
            bodyStyle={{overflowY: 'scroll', maxHeight: 'calc(100vh - 350px)'}}
            closable={false}
            footer={message === 'Create a theme' ? [
                <Button onClick={() => history.push('/admin/themes')} type='primary'>
                    Go To Themes
                </Button>
            ] : null}
        >
            {message === 'Activate a theme' ?
                <div className='themes-list'>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                        In order to access the Create section, you must first activate a theme for your Blocks, Qoos and Qoolections.
                    </p>
                    {
                    themes &&
                    themes.data &&
                    themes.data.items &&
                    themes.data.items.length > 0 &&
                    themes.data.items.map((theme) =>
                        (
                            <ThemeCard
                                key={theme._id}
                                theme={theme}
                                activeThemes={activeThemes}
                                activateTheme={activateTheme}
                                SERVER_URL={SERVER_URL}
                                token={currentuser.data.token}
                            />
                        )
                    )}
                </div> :
                <p style={{ fontSize: '14px', fontWeight: '400' }}>
                    In order to access the Create section, you must first <Link to={{ pathname: '/admin/themes' }}>create a theme</Link> for your Blocks, Qoos and Qoolections.
                </p>
            }
        </Modal>
    )
}

export default ThemeModal;