/* eslint-disable no-useless-escape */
import {
  SET_QOO,
  EDIT_QOO_PROPERTY,
  ADD_BLOCK,
  REMOVE_BLOCK,
  BLOCKS_LAYOUT_CHANGE,
  REPLACE_BLOCK_WITH_CLONE,
  EDIT_CLONED_BLOCK,
  EDIT_SINGLE_ELEMENT,
  EDIT_SINGLE_ELEMENT_EVENTS,
  REMOVE_SINGLE_ELEMENT_EVENT,
  REPLACE_EDITED_BLOCK,
  TOGGLE_HIDE_SHOW_LAYER_Q,
  MOVE_LAYER_UP_Q,
  MOVE_LAYER_DOWN_Q,
  SET_BLOCKS_LAYOUT_Q,
  UNDO,
  REDO,
  MOVE_LAYER_UP_WITH_ID_Q,
  MOVE_LAYER_TO_FRONT_Q,
  MOVE_LAYER_DOWN_WITH_ID_Q,
  MOVE_LAYER_TO_BOTTOM_Q,
  ADD_FORM_FIELD_Q,
  EDIT_FORM_FIELD_Q,
  REMOVE_FORM_FIELD_Q,
  ADD_SURVEY_OPTION_Q,
  REMOVE_SURVEY_OPTION_Q,
  EDIT_SURVEY_OPTION_Q,
  ADD_ENDNOTES_Q,
  REMOVE_ENDNOTE_Q,
  EDIT_ENDNOTE_Q,
  SELECT_QOO,
  ADD_BLOCKS,
  EDIT_BLOCK_WIDTH,
  REMOVE_QUESTION_FROM_QOO,
  DELETE_NAVIGATION_BUTTON, ADD_NAVIGATION_BUTTON, EDIT_NAVIGATION_BUTTON, EDIT_ANSWER_TEXT,
} from './actionTypes';
import _ from 'lodash';
import { SELECT_BLOCK } from '../block-in-preview/actionTypes';
import block from "react-color/lib/components/block/Block";

const qooInPreviewReducer = (state, action) => {
  switch (action.type) {
    case SET_QOO:
      return {
        qooInPreview: action.payload,
        qooInPreviewHistory: [],
        qooInPreviewFuture: [],
      };

    case EDIT_QOO_PROPERTY:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          ...action.payload,
        },
      };

    case SELECT_QOO:
      return {
        qooInPreview: action.payload,
        qooInPreviewHistory: [],
        qooInPreviewFuture: [],
      };

    case ADD_BLOCK:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: [...state.qooInPreview.blocksLayout, action.payload],
        },
      };

    case ADD_BLOCKS:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: [...state.qooInPreview.blocksLayout, ...action.payload],
        },
      };

    case ADD_NAVIGATION_BUTTON:
      const nextNavigationButton = state.qooInPreview.blocksLayout.find(item => item.block.name === 'nextButton' || item.block.name === 'submitButton');
      const previousNavigationButton = state.qooInPreview.blocksLayout.find(item => item.block.name === 'previousButton');
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: [...state.qooInPreview.blocksLayout, ...action.payload].map(item => {
            if (nextNavigationButton && nextNavigationButton.block._id === item.block._id) {
              return {
                ...item,
                x: 6,
                w: 6,
              }
            }
            if (previousNavigationButton && previousNavigationButton.block._id === item.block._id) {
              return {
                ...item,
                x: 0,
                w: 6,
              }
            }
            return item;
          })
        }
      }

    case BLOCKS_LAYOUT_CHANGE:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.map((block) => {
            if (block.block._id === action.payload._id) {
              return {
                block: block.block,
                endnotes: block.endnotes,
                x: action.payload.x,
                y: action.payload.y,
                w: action.payload.w,
                h: action.payload.h,
              };
            } else {
              return block;
            }
          }),
        },
      };

    case EDIT_BLOCK_WIDTH:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.map((block) => {
            for (let i = 0; i < action.payload.length; i++) {
              if (action.payload[i]._id === block.block._id) {
                return {
                  ...block,
                  w: action.payload[i].w,
                };
              }
            }
            return block;
          }),
        },
      }

    case DELETE_NAVIGATION_BUTTON:

      let blocksLayout = [...state.qooInPreview.blocksLayout];

      for (let i = 0; i < blocksLayout.length; i++) {
        if (blocksLayout[i].block._id === action.payload._id) {
          blocksLayout.splice(i, 1);
          i--;
        }
      }
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: blocksLayout.map(item => {
            if (item.block.name === action.payload.buttonToEdit) {
              return {
                ...item,
                w: 12,
                x: 0,
              }
            }
            return item;
          })
        }
      }

    case EDIT_NAVIGATION_BUTTON:
      const isLastQoo = action.payload.isLastQoo;

      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.map(item => {
            if (item.block._id === action.payload._id) {
              return {
                ...item,
                block: {
                  ...item.block,
                  name: isLastQoo ? 'submitButton' : 'nextButton',
                  blockProperties: {
                    ...item.block.blockProperties,
                    buttonText: `<p><strong><span style=\"color: #4962E2; font-family: 'Noto Sans'; font-size: 1.5em;\"> ${isLastQoo ? 'Submit' : 'Next'}</span></strong></p>`
                  }
                }
              }
            }
            return item;
          })
        }
      }
    case REMOVE_QUESTION_FROM_QOO:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.filter(block => block.block.question !== action.payload.questionId),
        }
      }

    case EDIT_ANSWER_TEXT:
      // console.log(action.payload.text);
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload._id) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockProperties: {
                    ...item.block.blockProperties,
                    buttonText: `<p><span style=\"color: #000000; font-family: 'Noto Sans'; font-size: 1.5em;\">${action.payload.text}</span></p>`
                  }
                }
              }
            }
            return item;
          })
        }
      }

    case REMOVE_BLOCK:
      const newRefs = state.qooInPreview.endnotes.references
        .filter(
          (reference) =>
            !(
              reference.elements.includes(action.payload._id) &&
              reference.elements.length === 1
            )
        )
        .map((reference) => ({
          ...reference,
          elements: reference.elements.filter(
            (element) => element !== action.payload._id
          ),
        }));
      const newFoots = state.qooInPreview.endnotes.footnotes
        .filter(
          (footnote) =>
            !(
              footnote.elements.includes(action.payload._id) &&
              footnote.elements.length === 1
            )
        )
        .map((footnote) => ({
          ...footnote,
          elements: footnote.elements.filter(
            (element) => element !== action.payload._id
          ),
        }));
      const newAbbrs = state.qooInPreview.endnotes.abbreviations
        .filter(
          (abbreviation) =>
            !(
              abbreviation.elements.includes(action.payload._id) &&
              abbreviation.elements.length === 1
            )
        )
        .map((abbreviation) => ({
          ...abbreviation,
          elements: abbreviation.elements.filter(
            (element) => element !== action.payload._id
          ),
        }));

      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.filter(
            (block) => block.block._id !== action.payload._id
          ),
          endnotes: {
            references: newRefs,
            footnotes: newFoots,
            abbreviations: newAbbrs,
          },
        },
      };
    case REPLACE_BLOCK_WITH_CLONE:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: [
            ...state.qooInPreview.blocksLayout,
            action.payload.clonedBlock,
          ].filter(
            (block) => block.block._id !== action.payload.originalBlockId
          ),
        },
      };
    case EDIT_CLONED_BLOCK:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.map((block) => {
            if (block.block._id === action.payload.block._id) {
              return action.payload;
            } else {
              return block;
            }
          }),
        },
      };
    case EDIT_SINGLE_ELEMENT:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockProperties: {
                    ...item.block.blockProperties,
                    ...action.payload.updates,
                  },
                },
              };
            } else {
              return item;
            }
          }),
        },
      };
    case EDIT_SINGLE_ELEMENT_EVENTS:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              const blockEvents = item.block.blockEvents;
              const eventIndex = action.payload.eventIndex;
              let newBlockEvents = [...blockEvents];
              if (eventIndex || eventIndex === 0) {
                newBlockEvents[eventIndex] = action.payload.updates;
              } else {
                newBlockEvents.push(action.payload.updates)
              }
              return {
                ...item,
                block: {
                  ...item.block,
                  blockEvents: [
                    ...newBlockEvents
                  ],
                },
              };
            } else {
              return item;
            }
          }),
        },
      };
    case REMOVE_SINGLE_ELEMENT_EVENT:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockEvents: [...item.block.blockEvents].filter(
                    (blockEvent, index) => {
                      // console.log('index in reducer: ', index);
                      // console.log('payload in reducer: ', action.payload);
                      return index !== action.payload.updates;
                    }
                  ),
                },
              };
            } else {
              return item;
            }
          }),
        },
      };
    case REPLACE_EDITED_BLOCK:
      const newLayout = [...state.qooInPreview.blocksLayout];
      const oldBlockIndex = newLayout.findIndex(
        (item) => item.block._id === action.payload.originalBlockId
      );

      const newBlock = {
        x: action.payload.x,
        y: action.payload.y,
        w: action.payload.block.size[1],
        h: action.payload.block.size[0],
        block: action.payload.block,
      };

      newLayout.splice(oldBlockIndex, 1, newBlock);

      return {
        qooInPreviewHistory: [],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: newLayout,
        },
      };
    case TOGGLE_HIDE_SHOW_LAYER_Q:
      return {
        qooInPreviewHistory: state.qooInPreviewHistory,
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  hidden: !item.block.hidden,
                },
              };
            } else {
              return item;
            }
          }),
        },
      };

    case MOVE_LAYER_UP_Q:
      const tempArrForMovingUp = [...state.qooInPreview.blocksLayout];
      const layerToMoveUp = tempArrForMovingUp.splice(action.payload.index, 1);
      tempArrForMovingUp.splice(action.payload.index + 1, 0, ...layerToMoveUp);

      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: tempArrForMovingUp,
        },
      };

    case MOVE_LAYER_DOWN_Q:
      const tempArrForMovingDown = [...state.qooInPreview.blocksLayout];
      const layerToMoveDown = tempArrForMovingDown.splice(
        action.payload.index,
        1
      );
      tempArrForMovingDown.splice(
        action.payload.index - 1,
        0,
        ...layerToMoveDown
      );

      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: tempArrForMovingDown,
        },
      };

    case SET_BLOCKS_LAYOUT_Q:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: action.payload.newBlocksLayout,
        },
      };

    case MOVE_LAYER_UP_WITH_ID_Q:
      const tempArrForMovingUpWithId = [...state.qooInPreview.blocksLayout];
      const indexOfLayerToMoveUpWithId = tempArrForMovingUpWithId.findIndex(
        (item) => item.block._id === action.payload.blockId
      );
      const layerToMoveUpWithId = tempArrForMovingUpWithId.splice(
        indexOfLayerToMoveUpWithId,
        1
      );
      tempArrForMovingUpWithId.splice(
        indexOfLayerToMoveUpWithId + 1,
        0,
        ...layerToMoveUpWithId
      );

      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: tempArrForMovingUpWithId,
        },
      };

    case MOVE_LAYER_DOWN_WITH_ID_Q:
      const tempArrForMovingDownWithId = [...state.qooInPreview.blocksLayout];
      const indexOfLayerToMoveDownWithId = tempArrForMovingDownWithId.findIndex(
        (item) => item.block._id === action.payload.blockId
      );
      const layerToMoveDownWithId = tempArrForMovingDownWithId.splice(
        indexOfLayerToMoveDownWithId,
        1
      );
      tempArrForMovingDownWithId.splice(
        indexOfLayerToMoveDownWithId - 1,
        0,
        ...layerToMoveDownWithId
      );

      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: tempArrForMovingDownWithId,
        },
      };

    case MOVE_LAYER_TO_FRONT_Q:
      const tempArrForMovingToFront = [...state.qooInPreview.blocksLayout];
      const indexOfLayerToMoveToFront = tempArrForMovingToFront.findIndex(
        (item) => item.block._id === action.payload.blockId
      );
      const layerToMoveToFront = tempArrForMovingToFront.splice(
        indexOfLayerToMoveToFront,
        1
      );
      const newArrFormovingToFront = [
        ...tempArrForMovingToFront,
        ...layerToMoveToFront,
      ];

      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: newArrFormovingToFront,
        },
      };

    case MOVE_LAYER_TO_BOTTOM_Q:
      const tempArrForMovingToBottom = [...state.qooInPreview.blocksLayout];
      const indexOfLayerToMoveToBottom = tempArrForMovingToBottom.findIndex(
        (item) => item.block._id === action.payload.blockId
      );
      const layerToMoveToBottom = tempArrForMovingToBottom.splice(
        indexOfLayerToMoveToBottom,
        1
      );
      const newArrFormovingToBottom = [
        ...layerToMoveToBottom,
        ...tempArrForMovingToBottom,
      ];

      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: newArrFormovingToBottom,
        },
      };

    case ADD_FORM_FIELD_Q:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockProperties: {
                    ...item.block.blockProperties,
                    formFields: [
                      ...item.block.blockProperties.formFields,
                      action.payload.formField,
                    ],
                  },
                },
              };
            } else {
              return item;
            }
          }),
        },
      };

    case EDIT_FORM_FIELD_Q:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockProperties: {
                    ...item.block.blockProperties,
                    formFields: item.block.blockProperties.formFields.map(
                      (formField, index) => {
                        if (index === action.payload.index) {
                          return {
                            ...formField,
                            required: action.payload.required,
                          };
                        } else {
                          return formField;
                        }
                      }
                    ),
                  },
                },
              };
            } else {
              return item;
            }
          }),
        },
      };

    case REMOVE_FORM_FIELD_Q:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockProperties: {
                    ...item.block.blockProperties,
                    formFields: item.block.blockProperties.formFields.filter(
                      (formField, index) => index !== action.payload.index
                    ),
                  },
                },
              };
            } else {
              return item;
            }
          }),
        },
      };

    case ADD_SURVEY_OPTION_Q:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockProperties: {
                    ...item.block.blockProperties,
                    surveyOptions: [
                      ...item.block.blockProperties.surveyOptions,
                      action.payload.surveyOption,
                    ],
                  },
                },
              };
            } else {
              return item;
            }
          }),
        },
      };

    case REMOVE_SURVEY_OPTION_Q:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockProperties: {
                    ...item.block.blockProperties,
                    surveyOptions:
                      item.block.blockProperties.surveyOptions.filter(
                        (surveyOption, index) => index !== action.payload.index
                      ),
                  },
                },
              };
            } else {
              return item;
            }
          }),
        },
      };

    case EDIT_SURVEY_OPTION_Q:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          blocksLayout: state.qooInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockProperties: {
                    ...item.block.blockProperties,
                    surveyOptions: item.block.blockProperties.surveyOptions.map(
                      (surveyOption, index) => {
                        if (index === action.payload.index) {
                          return action.payload.value;
                        } else {
                          return surveyOption;
                        }
                      }
                    ),
                  },
                },
              };
            } else {
              return item;
            }
          }),
        },
      };

    case UNDO:
      const oldHistory = [...state.qooInPreviewHistory];
      const newPresentForUndo = oldHistory.pop();

      return {
        qooInPreviewHistory: oldHistory,
        qooInPreview: newPresentForUndo,
        qooInPreviewFuture: [state.qooInPreview, ...state.qooInPreviewFuture],
      };
    case REDO:
      const oldFuture = [...state.qooInPreviewFuture];
      const newPresentForRedo = oldFuture.shift();

      return {
        qooInPreview: newPresentForRedo,
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: oldFuture,
      };

    case ADD_ENDNOTES_Q:
      // console.log(state.qooInPreview, action.payload)
      let newEndnotesArray = state.qooInPreview.endnotes[
        action.payload.endnoteType
      ].map((endnote) => {
        if (
          action.payload.endnotes.some(
            (addingEndnote) => addingEndnote._id === endnote.item._id
          )
        ) {
          return {
            ...endnote,
            elements: _.uniq([...endnote.elements, action.payload.blockId]),
          };
        } else {
          return endnote;
        }
      });

      action.payload.endnotes.forEach((endnote) => {
        if (
          !newEndnotesArray.some(
            (existingEndnote) => existingEndnote.item._id === endnote._id
          )
        ) {
          newEndnotesArray.push({
            item: endnote,
            elements: [action.payload.blockId],
          });
        }
      });

      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          endnotes: {
            ...state.qooInPreview.endnotes,
            [`${action.payload.endnoteType}`]: newEndnotesArray,
          },
        },
      };

    case REMOVE_ENDNOTE_Q:
      const existingElements = state.qooInPreview.endnotes[
        action.payload.endnoteType
      ].find((item) => item.item._id === action.payload.endnoteId).elements;
      const removeAll = _.isEqual(existingElements, action.payload.elements);

      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          endnotes: {
            ...state.qooInPreview.endnotes,
            [`${action.payload.endnoteType}`]: removeAll
              ? state.qooInPreview.endnotes[action.payload.endnoteType].filter(
                (endnote) => endnote.item._id !== action.payload.endnoteId
              )
              : state.qooInPreview.endnotes[action.payload.endnoteType].map(
                (endnote) => {
                  if (endnote.item._id === action.payload._id) {
                    return {
                      ...endnote,
                      elements: endnote.elements.filter(
                        (element) =>
                          !action.payload.elements.includes(element)
                      ),
                    };
                  } else {
                    return endnote;
                  }
                }
              ),
          },
        },
      };

    case EDIT_ENDNOTE_Q:
      return {
        qooInPreviewHistory: [...state.qooInPreviewHistory, state.qooInPreview],
        qooInPreviewFuture: [],
        qooInPreview: {
          ...state.qooInPreview,
          endnotes: {
            ...state.qooInPreview.endnotes,
            [`${action.payload.endnoteType}`]: state.qooInPreview.endnotes[
              action.payload.endnoteType
            ].map((endnote) => {
              if (endnote.item._id === action.payload.endnote._id) {
                return {
                  ...endnote,
                  item: action.payload.endnote,
                };
              } else {
                return endnote;
              }
            }),
          },
        },
      };

    default:
      return state;
  }
};

export default qooInPreviewReducer;
