import { ReactComponent as NoIcon } from '../icons/block-types-icons/no_icon.svg';
import { ReactComponent as TargetParticipantsIcon } from '../icons/homebase-icons/target-participants.svg';
import { ReactComponent as InDemoParticipantsIcon } from '../icons/homebase-icons/in-demo-participants.svg';
import { ReactComponent as InStudyParticipantsIcon } from '../icons/homebase-icons/in-study-participants.svg';
import { ReactComponent as CompletedParticipantsIcon } from '../icons/homebase-icons/completed.svg';
// import { ReactComponent as ActiveParticipantsIcon } from '../icons/homebase-icons/active-participants.svg';
// import { ReactComponent as ActiveSitesIcon } from '../icons/homebase-icons/active-sites.svg';
// import { ReactComponent as ActivePersonnelIcon } from '../icons/homebase-icons/active-personnel.svg';
// import { ReactComponent as ParticipantViewsIcon } from '../icons/homebase-icons/participant-views.svg';
import { ReactComponent as MedicatedParticipantsIcon } from '../icons/homebase-icons/treated-participants.svg';
// import { ReactComponent as MedicatedIcon } from '../icons/homebase-icons/treated.svg';
import { ReactComponent as VomitedParticipantsIcon } from '../icons/homebase-icons/vomited-participants.svg';
// import { ReactComponent as VomitedIcon } from '../icons/homebase-icons/vomited.svg';
// import { ReactComponent as AverageIntensityIcon } from '../icons/homebase-icons/average-severity.svg';
import { ReactComponent as Percentage } from '../icons/homebase-icons/percentage.svg';

const homebaseStats = {
  noicon: {
    icon: <NoIcon />
  },
  targetParticipants: {
    icon: <TargetParticipantsIcon />,
    label: 'Target',
    type: 'number',
    group: 'basic',
    tooltip: 'The # of participants intended for this clinical trial.'
  },
  inDemoParticipants: {
    icon: <InDemoParticipantsIcon />,
    label: 'In-Demo',
    type: 'number',
    group: 'basic',
    tooltip:
      'The # of clinical trial participants that have enrolled and are in Demo Mode for QClinical.'
  },
  inStudyParticipants: {
    icon: <InStudyParticipantsIcon />,
    label: 'In-Study',
    type: 'number',
    group: 'basic',
    tooltip:
      'The # of clinical trial participants that have enrolled and passed Demo Mode for QClinical.'
  },
  completedParticipants: {
    icon: <CompletedParticipantsIcon />,
    label: 'Completed',
    type: 'number',
    group: 'basic',
    tooltip:
      'The # of clinical trial participants that have enrolled and completed this clinical trial.'
  },
  // activeSites: {
  //   icon: <ActiveSitesIcon />,
  //   label: 'Active Sites',
  //   type: 'number',
  //   group: 'basic',
  //   tooltip: 'The # of sites with at least 1 Active Participant.'
  // },
  // activePersonnel: {
  //   icon: <ActivePersonnelIcon />,
  //   label: 'Active Personnel',
  //   type: 'number',
  //   group: 'basic',
  //   tooltip: 'Tooltip placeholder'
  // },
  // participantViews: {
  //   icon: <ParticipantViewsIcon />,
  //   label: 'Participant Views',
  //   type: 'number',
  //   group: 'basic',
  //   tooltip: 'Tooltip placeholder'
  // },
  // averageIntensity: {
  //   icon: <AverageIntensityIcon />,
  //   label: 'Average Intensity',
  //   type: 'number',
  //   group: 'basic',
  //   tooltip: `<div>
  //     <p>
  //       The average intensity of the participants' last vomiting episode, as reported on the Intensity of Attack ePRO question. Here is how the numerical scale is defined:
  //     </p>
  //     <p style='margin-top: 1rem;'>
  //       <span>1 = Mild</span><br />
  //       <span>2 = Moderate</span><br />
  //       <span>3 = Severe</span><br />
  //       <span>4 = Excruciating</span><br />
  //     </p>
  //   </div>`
  // },
  vomitedParticipants: {
    icon: <VomitedParticipantsIcon />,
    label: 'Vomited Participants',
    type: 'number',
    group: 'outcomes',
    tooltip:
      'The # of clinical trial participants that have reported at least 1 vomiting episode in QClinical.'
  },
  vomited: {
    icon: <Percentage />,
    label: 'Vomited',
    type: 'percentage',
    group: 'outcomes',
    tooltip: 'Vomited Participants / Target Participants'
  },
  medicatedParticipants: {
    icon: <MedicatedParticipantsIcon />,
    label: 'Medicated Participants',
    type: 'number',
    group: 'outcomes',
    tooltip:
      'The # of clinical trial participants that have reported taking their study medication.'
  },
  medicated: {
    icon: <Percentage />,
    label: 'Medicated',
    type: 'percentage',
    group: 'outcomes',
    tooltip: 'Medicated Participants / Target Participants'
  }
};

export default homebaseStats;
