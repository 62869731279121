import React from 'react';
import { Modal } from 'antd';
import QooDisplay from '../qoos/QooDisplay';

const QooPhonePreviewModal = ({ visible, closeModal, qoo }) => {

  return (
    <Modal
      visible={visible}
      centered
      closable
      onCancel={closeModal}
      footer={null}
      bodyStyle={{ padding: '0' }}
      destroyOnClose={true}
      width={612}
    >
      <div
        className="qoolection-preview-modal"
      >
        <h3 className="qoolection-preview-modal__title">{qoo.title}</h3>
        <div className="theme-preview">
          <div className="theme-preview__phone-container">
            <div className="theme-preview__phone">
              <div className="theme-preview__top-bar"></div>
              <div className="theme-preview__black-bg"></div>
              <div
                className="theme-preview__qoo-content"
              >
                <QooDisplay
                  qooInPreview={qoo}
                  width={308}
                  height={585}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </Modal>
  );
};

export default QooPhonePreviewModal;