import React from 'react';
import GridLayout from 'react-grid-layout';
import { SELECT_QOOLECTION_WITH_HISTORY } from '../../reducers/qoolection/actionTypes';
import { ReactComponent as ArrangeIcon } from '../../icons/ph_arrows-out-cardinal.svg';

const ArrangePanel = ({ qoolection, dispatch, updateQoolection }) => {

  const layout = qoolection.qoos.map((qoo, index) => {
    return {
      i: `${qoo._id}-${index}`,
      x: 0,
      y: index,
      w: 1,
      h: 1
    };
  });

  const handleLayoutChange = async (layout) => {
    const sortedLayout = [...layout].sort((a, b) => a.y - b.y);
    let newQoosLayout = [];
    sortedLayout.forEach(gridItem => {
      qoolection.qoos.forEach((item, index) => {
        if (`${item._id}-${index}` === gridItem.i) {
          newQoosLayout.push(item);
        }
      });
    });

    const newQoolection = await updateQoolection({ qoos: newQoosLayout });
    dispatch({
      type: SELECT_QOOLECTION_WITH_HISTORY,
      payload: newQoolection
    });
  };

  return (
    <div>
      <GridLayout
        className='layout'
        layout={layout}
        cols={1}
        rowHeight={32}
        width={305}
        height={layout.length * 32}
        margin={[0, 0]}
        isResizable={false}
        maxRows={layout.length}
        onDragStop={(layout) => handleLayoutChange(layout)}
        isBounded={true}
      >
        {qoolection.qoos?.length > 0 && qoolection.qoos.map((qoo, index) => {
          return (
            <div
              key={`${qoo._id}-${index}`}
              className='arrange-panel__item'
            >
              <span className='arrange-panel__item-nr'>{index + 1} </span>
              <span className='arrange-panel__item-title'>{qoo.title}</span>
              <ArrangeIcon />
            </div>
          );
        })}
      </GridLayout>
    </div>
  );
};

export default ArrangePanel;