import React from 'react';
import { Layout } from 'antd';

const { Sider } = Layout;

const SideBarInner = ({ children, width }) => {
  return (
    <Sider
      width={width ? width : 360}
      className='sidebar-inner'
    >
      {children}
    </Sider>
  );
};

export default SideBarInner;