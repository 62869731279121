import React from 'react';

const MenuPreview = ({ headerFooter, menuLinks }) => {

  return (
    <div
      className='menu-preview'
      style={{
        position: 'absolute',
        top: headerFooter.header.menuOverlayLayout.y === 1 ? '0' : `${(headerFooter.header.menuOverlayLayout.y - 1) / 11 * 100}%`,
        left: `${(headerFooter.header.menuOverlayLayout.x - 1) / 6 * 100}%`,
        width: `${headerFooter.header.menuOverlayLayout.w / 6 * 100}%`,
        height: headerFooter.header.menuOverlayLayout.h === -1 ? '100%' : `${headerFooter.header.menuOverlayLayout.h / 11 * 100}%`,
        background: headerFooter.header.menuOverlayColor ? `rgba(${headerFooter.header.menuOverlayColor.rgb.r}, ${headerFooter.header.menuOverlayColor.rgb.g}, ${headerFooter.header.menuOverlayColor.rgb.b}, ${headerFooter.header.menuOverlayColor.rgb.a})` : '',
        color: headerFooter.header.menuOverlayTextColor ? headerFooter.header.menuOverlayTextColor.hex : '',
        border: headerFooter.header.menuOverlayBorderColor ? `1px solid ${headerFooter.header.menuOverlayBorderColor.hex}` : '',
        fontFamily: headerFooter.header.menuOverlayFont ? headerFooter.header.menuOverlayFont.name : '',
        fontStyle: headerFooter.header.menuOverlayFont ? headerFooter.header.menuOverlayFont.style : '',
        fontWeight: headerFooter.header.menuOverlayFont ? headerFooter.header.menuOverlayFont.weight : '',
        fontSize: headerFooter.header.menuOverlayFontSize ? (headerFooter.header.menuOverlayFontSize === 'L' ? '3em' : (headerFooter.header.menuOverlayFontSize === 'S' ? '1.5em' : '2em')) : '2em',
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: headerFooter.header.menuOverlayLayout.align,
        gap: '1.5em',
        padding: '2em',
        zIndex: '10',
        overflow: 'auto'
      }}
    >
      {menuLinks && menuLinks.length > 0 && menuLinks.map((item, index) => {
        return (
          <span>{item.text ? item.text : `Link ${index + 1}`}</span>
        );
      })}
    </div>
  );
};

export default MenuPreview;