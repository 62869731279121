const publishedMenuItemsTypes = {
  qoo: {
    key: 'qoo link',
    title: 'Qoo',
  },
  webLink: {
    key: 'web link',
    title: 'Web Link',
  },
  footnotes: {
    key: 'footnotes',
    title: 'Footnotes',
  },
  plainText: {
    key: 'plain text',
    title: 'Plain Text',
  },
};

export default Object.freeze(publishedMenuItemsTypes);