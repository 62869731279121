import React from 'react';
import { Button } from 'antd';
import StepNavigation from './StepNavigation';
import TypographyTab from './TypographyTab';

const NewThemeStep3 = ({ step, setStep, themeInPreview, dispatch, completedStep, setCompletedStep }) => {
  return (
    <div className="new-theme-builder__step-3">
      <h2 className="new-theme-builder__step-subtitle">
        <span>Step 3: </span>
        <span>Now, let's select one or more fonts that will be used in your Qoolections.</span>
      </h2>
      <StepNavigation
        step={step}
        setStep={setStep}
        completedStep={completedStep}
      />
      <p className="new-theme-builder__intro-text">
        Add individual fonts by using our library of free fonts below, or click the button to upload your custom fonts.
      </p>

      <TypographyTab
        themeInPreview={themeInPreview}
        dispatch={dispatch}
        isNew={true}
      />

      <div className="new-theme-builder__bottom-row">
        <Button
          className='new-theme-builder__step-btn'
          onClick={() => setStep(2)}
        >
          Previous step
        </Button>
        <Button
          className='new-theme-builder__step-btn'
          type='primary'
          onClick={() => {
            if (completedStep === 2) {
              setCompletedStep(3);
            }
            setStep(4);
          }}
          disabled={!themeInPreview.headingFont || !themeInPreview.subheadingFont || !themeInPreview.bodyTextFont}
        >
          Next step
        </Button>
      </div>
    </div>
  );
};

export default NewThemeStep3;