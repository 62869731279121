export const addBlockData = {
  empty: {
    name: 'untitled',
    size: [2, 4],
    isClone: true
  },
  heading: {
    name: 'Heading',
    size: [1, 6],
    isClone: true,
    blockProperties: {
      blockPaddingBottom: 8,
      blockPaddingLeft: 16,
      blockPaddingRight: 16,
      blockPaddingTop: 8,
      textContent:
        '<p><strong><span style="font-size: 2em; color: #000000; font-family: \'**default**\'; ">Add a heading</span></strong></p>'
    }
  },
  subheading: {
    name: 'Subheading',
    size: [1, 6],
    isClone: true,
    blockProperties: {
      blockPaddingBottom: 8,
      blockPaddingLeft: 16,
      blockPaddingRight: 16,
      blockPaddingTop: 8,
      textContent:
        '<p><span style="color: #000000; font-size: 1.5em; font-family: \'**default**\'; ">Add a subheading</span></p>'
    }
  },
  surveyQuestion: {
    name: 'question',
    size: [1, 12],
    isClone: true,
    blockProperties: {
      blockPaddingBottom: 8,
      blockPaddingLeft: 16,
      blockPaddingRight: 16,
      blockPaddingTop: 8,
      textContent:
        '<p><strong><span style="font-size: 2em; color: #000000; font-family: \'**default**\'; ">Question</span></strong></p>'
    }
  },
  surveyDescription: {
    name: 'description',
    size: [3, 12],
    isClone: true,
    blockProperties: {
      blockPaddingBottom: 8,
      blockPaddingLeft: 16,
      blockPaddingRight: 16,
      blockPaddingTop: 8,
      textContent:
        '<p><span style="font-size: 1.5em; color: #000000; font-family: \'**default**\'; ">Answer</span></p>'
    }
  },
  body: {
    name: 'Body',
    size: [1, 6],
    isClone: true,
    blockProperties: {
      blockPaddingBottom: 8,
      blockPaddingLeft: 16,
      blockPaddingRight: 16,
      blockPaddingTop: 8,
      textContent:
        '<p><span style="color: #000000; font-family: \'**default**\'; ">Add some body text</span></p>'
    }
  },
  icon: {
    name: 'icon',
    size: [2, 2],
    isClone: true
  },
  button: {
    name: 'button',
    size: [2, 8],
    isClone: true
  },
  navigationButton: {
    name: 'navigationButton',
    size: [2, 6],
    isClone: true
  },
  divider: {
    name: 'divider',
    size: [2, 8],
    isClone: true
  },
  form: {
    name: 'form',
    size: [8, 8],
    isClone: true
  },
  survey: {
    name: 'survey',
    size: [8, 8],
    isClone: true
  },
  media: {
    name: 'media',
    size: [4, 4],
    isClone: true
  },
  shape: {
    name: 'shape',
    size: [3, 3],
    isClone: true
  }
};

export const answerButtonTemplates = [
  {
    id: '1',
    buttonBackgroundColor: {
      hex: '#FFFFFF',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    buttonBorderColor: {
      hex: '#D5DFE8',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    buttonBorderThickness: '0.0625em',
    buttonBorderType: 'solid',
    buttonBorderRadius: '0.375em',
    border: '2px solid #4962E2',
    buttonWidth: 75,
    buttonHeight: 75,
    buttonText:
      '<p><span style="color: #2A3A49; font-family: \'Noto Sans\'; font-size: 1.5em;">Choice</span></p>',
    buttonHasShadow: false,
    buttonShadowX: '0em',
    buttonShadowY: '0.3em',
    buttonShadowBlur: '0.5em',
    buttonShadowOpacity: 0.3,
    buttonShadowColor: {
      hex: '#FFFFFF',
      rgb: { r: 0, g: 0, b: 0, a: 1 }
    },
    buttonInnerShadow: false
  }
];

export const navigationButtonTemplates = [
  {
    id: '1',
    buttonBackgroundColor: {
      hex: '#FFFFFF',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    buttonBorderColor: {
      hex: '#4962E2',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    buttonBorderThickness: '0.0625em',
    buttonBorderType: 'solid',
    buttonBorderRadius: '0.375em',
    border: '2px solid #4962E2',
    buttonWidth: 75,
    buttonHeight: 75,
    buttonText:
      '<p><strong><span style="color: #4962E2; font-family: \'Noto Sans\'; font-size: 1.5em;">Previous</span></strong></p>',
    buttonHasShadow: false,
    buttonShadowX: '0em',
    buttonShadowY: '0.3em',
    buttonShadowBlur: '0.5em',
    buttonShadowOpacity: 0.3,
    buttonShadowColor: {
      hex: '#FFFFFF',
      rgb: { r: 0, g: 0, b: 0, a: 1 }
    },
    buttonInnerShadow: false
  },
  {
    id: '2',
    buttonBackgroundColor: {
      hex: '#FFFFFF',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    buttonBorderColor: {
      hex: '#4962E2',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    buttonBorderThickness: '0.0625em',
    buttonBorderType: 'solid',
    buttonBorderRadius: '0.375em',
    buttonWidth: 75,
    buttonHeight: 75,
    buttonText:
      '<p><strong><span style="color: #4962E2; font-family: \'Noto Sans\'; font-size: 1.5em;">Next</span></strong></p>',
    buttonHasShadow: false,
    buttonShadowX: '0em',
    buttonShadowY: '0.3em',
    buttonShadowBlur: '0.5em',
    buttonShadowOpacity: 0.3,
    buttonShadowColor: {
      hex: '#000000',
      rgb: { r: 0, g: 0, b: 0, a: 1 }
    },
    buttonInnerShadow: false
  }
];

export const buttonTemplates = [
  {
    id: '1',
    buttonBackgroundColor: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    buttonBackgroundColorActive: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    buttonBorderThickness: '0.0625em',
    buttonBorderThicknessActive: '0.0625em',
    buttonBorderType: 'solid',
    buttonBorderTypeActive: 'solid',
    buttonBorderRadius: '0.375em',
    buttonWidth: 75,
    buttonHeight: 75,
    buttonText:
      '<p><span style="color: #FFFFFF; font-family: \'**default**\'; font-size: 1.5em;">Label</span></p>',
    buttonHasShadow: false,
    buttonShadowX: '0em',
    buttonShadowY: '0.3em',
    buttonShadowBlur: '0.5em',
    buttonShadowOpacity: 0.3,
    buttonShadowColor: {
      hex: '#000000',
      rgb: { r: 0, g: 0, b: 0, a: 1 }
    },
    buttonInnerShadow: false
  },
  {
    id: '2',
    buttonBorderColor: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    buttonBorderColorActive: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    buttonBorderThickness: '0.0625em',
    buttonBorderThicknessActive: '0.0625em',
    buttonBorderType: 'solid',
    buttonBorderTypeActive: 'solid',
    buttonBorderRadius: '0em',
    buttonWidth: 75,
    buttonHeight: 75,
    buttonText:
      '<p><span style="color: #333; font-family: \'**default**\'; font-size: 1.5em;">Label</span></p>',
    buttonHasShadow: false,
    buttonShadowX: '0em',
    buttonShadowY: '0.3em',
    buttonShadowBlur: '0.5em',
    buttonShadowOpacity: 0.3,
    buttonShadowColor: {
      hex: '#000000',
      rgb: { r: 0, g: 0, b: 0, a: 1 }
    },
    buttonInnerShadow: false
  },
  {
    id: '3',
    buttonBackgroundColor: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    buttonBackgroundColorActive: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    buttonBorderThickness: '0.0625em',
    buttonBorderThicknessActive: '0.0625em',
    buttonBorderType: 'solid',
    buttonBorderTypeActive: 'solid',
    buttonBorderRadius: '30em',
    buttonWidth: 75,
    buttonHeight: 75,
    buttonText:
      '<p><span style="color: #FFFFFF; font-family: \'**default**\'; font-size: 1.5em;">Label</span></p>',
    buttonHasShadow: false,
    buttonShadowX: '0em',
    buttonShadowY: '0.3em',
    buttonShadowBlur: '0.5em',
    buttonShadowOpacity: 0.3,
    buttonShadowColor: {
      hex: '#000000',
      rgb: { r: 0, g: 0, b: 0, a: 1 }
    },
    buttonInnerShadow: false
  },
  {
    id: '4',
    buttonBorderThickness: '0.0625em',
    buttonBorderThicknessActive: '0.0625em',
    buttonBorderType: 'solid',
    buttonBorderTypeActive: 'solid',
    buttonBorderRadius: '0em',
    buttonWidth: 75,
    buttonHeight: 75,
    buttonText:
      '<p><span style="color: #333; font-family: \'**default**\'; font-size: 1.5em; text-decoration: underline;">Label</span></p>',
    buttonHasShadow: false,
    buttonShadowX: '0em',
    buttonShadowY: '0.3em',
    buttonShadowBlur: '0.5em',
    buttonShadowOpacity: 0.3,
    buttonShadowColor: {
      hex: '#000000',
      rgb: { r: 0, g: 0, b: 0, a: 1 }
    },
    buttonInnerShadow: false
  }
];

export const dividerTemplates = [
  {
    id: '1',
    dividerLength: 75,
    dividerColor: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    dividerType: 'solid',
    dividerThickness: 0.0625
  },
  {
    id: '2',
    dividerLength: 75,
    dividerColor: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    dividerType: 'dotted',
    dividerThickness: 0.0625
  },
  {
    id: '3',
    dividerLength: 75,
    dividerColor: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    dividerType: 'dashed',
    dividerThickness: 0.0625
  },
  {
    id: '4',
    dividerLength: 75,
    dividerColor: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    dividerType: 'double',
    dividerThickness: 0.5
  }
];

export const formTemplates = [
  {
    id: '1',
    formFields: [
      {
        fieldName: 'Name',
        fieldElement: 'text'
      },
      {
        fieldName: 'Email',
        fieldElement: 'email'
      },
      {
        fieldName: 'Message',
        fieldElement: 'textarea'
      }
    ],
    formPaddingTop: 18,
    formPaddingBottom: 18,
    formPaddingLeft: 18,
    formPaddingRight: 18,
    formFieldBorderColor: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    formFieldBorderThickness: '0.0625',
    formFieldBorderType: 'solid',
    formFieldBorderRadius: '0.25',
    formButtonBackgroundColor: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    formButtonBorderRadius: '0.25em'
    // formBorderColor: {
    //   hex: '#333',
    //   rgb: { r: 73, g: 98, b: 226, a: 1 }
    // },
    // formBorderThickness: '0.0625',
    // formBorderType: 'solid',
  },
  {
    id: '2',
    formFields: [
      {
        fieldName: 'Name',
        fieldElement: 'text'
      },
      {
        fieldName: 'Email',
        fieldElement: 'email'
      },
      {
        fieldName: 'Message',
        fieldElement: 'textarea'
      },
      {
        fieldName: 'Confirm',
        fieldElement: 'checkbox'
      }
    ],
    formPaddingTop: 18,
    formPaddingBottom: 18,
    formPaddingLeft: 18,
    formPaddingRight: 18,
    formFieldBorderColor: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    formFieldBorderThickness: '0.0625',
    formFieldBorderType: 'solid',
    formFieldBorderRadius: '0.25',
    formButtonBackgroundColor: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    formButtonBorderRadius: '4em',
    // formBorderColor: {
    //   hex: '#333',
    //   rgb: { r: 73, g: 98, b: 226, a: 1 }
    // },
    // formBorderThickness: '0.0625',
    // formBorderType: 'solid',
    formButtonPosition: 'flex-end'
  },
  {
    id: '3',
    formFields: [
      {
        fieldName: 'Name',
        fieldElement: 'text'
      },
      {
        fieldName: 'Email',
        fieldElement: 'email'
      },
      {
        fieldName: 'Message',
        fieldElement: 'textarea'
      }
    ],
    formPaddingTop: 18,
    formPaddingBottom: 18,
    formPaddingLeft: 18,
    formPaddingRight: 18,
    formFieldBorderColor: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    formFieldBorderThickness: '0.0625',
    formFieldBorderType: 'solid',
    formFieldBorderRadius: '0.25',
    formButtonBackgroundColor: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    formButtonBorderRadius: '4em',
    // formBorderColor: {
    //   hex: '#333',
    //   rgb: { r: 73, g: 98, b: 226, a: 1 }
    // },
    // formBorderThickness: '0.0625',
    // formBorderType: 'solid',
    formButtonPosition: 'flex-end'
  }
];

export const surveyTemplates = [
  {
    id: '1',
    surveyType: 'checkbox',
    surveyOptions: ['Choice 1', 'Choice 2', 'Choice 3'],
    surveyAnswerRequired: true,
    surveyOptionBackgroundColor: {
      hex: '#FFFFFF',
      rgb: { r: 255, g: 255, b: 255, a: 1 }
    },
    surveyOptionBorderColor: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    surveyOptionBorderThickness: '0.0625',
    surveyOptionBorderType: 'solid',
    surveyOptionActiveBackgroundColor: {
      hex: '#31DDD4',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    // surveyOptionActiveBorderColor: { type: colorSchema },
    // surveyOptionActiveBorderThickness: { type: Number },
    // surveyOptionActiveBorderType: { type: String },
    surveyOptionBorderRadius: '0.375em',
    // surveyOptionFont: {
    //   family: { type: String },
    //   name: { type: String },
    //   style: { type: String },
    //   weight: { type: Number },
    // },
    surveyOptionFontColor: {
      hex: '#333',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    // surveyBackgroundColor: { type: colorSchema },
    // surveyBorderColor: { type: colorSchema },
    // surveyBorderThickness: { type: Number },
    // surveyBorderType: { type: String },
    surveyQuestion:
      '<p><span style="font- family: \'**default**\'; color: #333;">Type survey question here...|</span></p>'
  }
];
