import React, { useState } from 'react';
import GridLayout from 'react-grid-layout';
import { Editor } from '@tinymce/tinymce-react';
import {
  ADD_BLOCK,
  BLOCKS_LAYOUT_CHANGE,
  EDIT_SINGLE_ELEMENT
} from '../../reducers/qoo-in-preview/actionTypes';
import { SELECT_BLOCK, EDIT_BLOCK_FIELD } from '../../reducers/block-in-preview/actionTypes';
import FormElement from '../blocks/FormElement';
import SurveyElement from '../blocks/SurveyElement';
import MediaElement from '../blocks/MediaElement';
import TextElement from '../blocks/TextElement';
import ShapeElement from '../blocks/ShapeElement';

const QooPreviewGrid = ({
  draggingItem,
  qooInPreview,
  dispatch,
  selectedBlock,
  setSelectedBlock,
  setSelectedBlockType,
  rows,
  blockInPreview,
  blockInPreviewDispatch,
  setEditBlockModalVisible,
  handleRightClick,
  hoveredEndnote,
  highlightedBlock,
  showButtonAsActive
}) => {
  const [selectedTextEditor, setSelectedTextEditor] = useState(null);

  let layout = [];
  if (qooInPreview && qooInPreview.blocksLayout && qooInPreview.blocksLayout.length > 0) {
    qooInPreview.blocksLayout.forEach((block) => {
      if (block.block) {
        layout.push({
          i: block.block._id,
          x: block.x,
          y: block.y,
          w:
            blockInPreview && blockInPreview._id === block.block._id
              ? blockInPreview.size[1]
              : block.block.isMulti
              ? block.block.size[1]
              : block.w,
          h:
            blockInPreview && blockInPreview._id === block.block._id
              ? blockInPreview.size[0]
              : block.block.isMulti
              ? block.block.size[0]
              : block.h,
          isResizable: block.block.isMulti
            ? blockInPreview && blockInPreview._id === block.block._id
              ? true
              : false
            : true
        });
      }
    });
  }

  const handleBlocksLayoutChange = (item) => {
    // if (item.y > 10) return;

    if (blockInPreview) {
      blockInPreviewDispatch({
        type: EDIT_BLOCK_FIELD,
        payload: {
          size: [item.h, item.w]
        }
      });
    } else {
      dispatch({
        type: BLOCKS_LAYOUT_CHANGE,
        payload: { _id: item.i, x: item.x, y: item.y, w: item.w, h: item.h }
      });
    }
  };

  const handleDropFromOutside = (item) => {
    // if (item.y > 10) return;

    dispatch({
      type: ADD_BLOCK,
      payload: {
        block: draggingItem,
        x: item.x,
        y: item.y,
        w: item.w,
        h: item.h
      }
    });
  };

  let tinyMCEContentStyleString = '';

  if (qooInPreview.theme) {
    qooInPreview.theme.selectedFonts.forEach(
      (font) =>
        (tinyMCEContentStyleString += `
      @font-face {
        font-family: ${font.name};
        src: url(${font.importUrl});
        font-weight: ${font.weight};
        font-style: ${font.style};
      } 
    `)
    );
  }

  tinyMCEContentStyleString += 'body { color:#2A3A49; font-size: 1em;} p {margin-bottom: 0;}';

  let tinyMCEFontFormatsString = '';
  if (qooInPreview.theme) {
    qooInPreview.theme.selectedFonts.forEach(
      (font) =>
        (tinyMCEFontFormatsString += `${font.name}=${font.name.toLowerCase().replace(' -', '')}; `)
    );
  }

  const defaultFont = qooInPreview?.theme.selectedFonts.find(
    (font) => font._id === qooInPreview.theme.bodyTextFont
  );

  return (
    <GridLayout
      className="layout"
      layout={layout}
      cols={12}
      rowHeight={32}
      width={384}
      height={rows * 32}
      margin={[0, 0]}
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '384px',
        height: `${rows * 32}px`
      }}
      compactType={null}
      // isResizable={false}
      isDroppable={true}
      preventCollision={true}
      onDragStop={(layout, oldItem, newItem) => handleBlocksLayoutChange(newItem)}
      maxRows={rows}
      onDrop={(layout, item) => handleDropFromOutside(item)}
      droppingItem={
        draggingItem
          ? { i: draggingItem._id, w: draggingItem.size[1], h: draggingItem.size[0] }
          : null
      }
      onResizeStop={(layout, oldItem, newItem) => handleBlocksLayoutChange(newItem)}
      allowOverlap={true}
      isResizable={true}
      resizeHandles={['e', 's']}
      isDraggable={selectedTextEditor || blockInPreview ? false : true}
      isBounded={true}>
      {qooInPreview &&
        qooInPreview.blocksLayout &&
        qooInPreview.blocksLayout.length > 0 &&
        qooInPreview.blocksLayout.map((item) => {
          const block = item.block;
          if (block) {
            if (blockInPreview && blockInPreview._id === block._id) {
              return null;
            } else {
              return (
                <div
                  key={block._id}
                  className={
                    selectedBlock && selectedBlock === item.block._id
                      ? 'qoo-preview__draggable-block selected-element'
                      : 'qoo-preview__draggable-block'
                  }
                  style={{
                    fontSize: `${(384 / 570) * 16}px`,
                    paddingTop: (384 / 570) * block.blockProperties.blockPaddingTop,
                    paddingRight: (384 / 570) * block.blockProperties.blockPaddingRight,
                    paddingBottom: (384 / 570) * block.blockProperties.blockPaddingBottom,
                    paddingLeft: (384 / 570) * block.blockProperties.blockPaddingLeft,
                    position: 'relative',
                    // cursor: 'move',
                    cursor: block.blockType?.name === 'text' ? 'auto' : 'move',
                    display: block.hidden
                      ? 'none'
                      : block.blocksLayout && block.blocksLayout.length > 0
                      ? 'grid'
                      : 'block',
                    gridTemplateColumns:
                      block.blocksLayout && block.blocksLayout.length > 0
                        ? `repeat(${block.size[1]}, 32px)`
                        : '',
                    gridTemplateRows:
                      block.blocksLayout && block.blocksLayout.length > 0
                        ? `repeat(${block.size[0]}, 32px)`
                        : '',

                    backgroundColor: block.blockProperties.blockBackgroundImage
                      ? ''
                      : block.blockProperties.blockBackgroundColor
                      ? block.blockProperties.blockBackgroundColor.hex
                      : block.theme?.colorAssignment?.blockBackground
                      ? block.theme.colorAssignment.blockBackground.hex
                      : 'transparent',
                    backgroundImage: block.blockProperties.blockBackgroundImage
                      ? `url(${block.blockProperties.blockBackgroundImage})`
                      : '',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    border:
                      selectedBlock && selectedBlock === block._id
                        ? '1px solid #3C9AEF'
                        : block.blockProperties.blockBorderType &&
                          block.blockProperties.blockBorderColor &&
                          block.blockProperties.blockBorderThickness
                        ? `${block.blockProperties.blockBorderThickness}em ${block.blockProperties.blockBorderType} ${block.blockProperties.blockBorderColor.hex}`
                        : 'none',
                    outline:
                      !blockInPreview &&
                      !block.isMulti &&
                      (qooInPreview.endnotes?.references
                        ?.find((reference) => reference.item._id === hoveredEndnote)
                        ?.elements?.includes(block._id) ||
                        qooInPreview.endnotes?.footnotes
                          ?.find((footnote) => footnote.item._id === hoveredEndnote)
                          ?.elements.includes(block._id) ||
                        qooInPreview.endnotes?.abbreviations
                          ?.find((abbreviation) => abbreviation.item._id === hoveredEndnote)
                          ?.elements.includes(block._id))
                        ? '2px solid #4CAF50'
                        : 'none'
                  }}
                  onClick={() => {
                    setSelectedBlock(block._id);
                    setSelectedBlockType(block.isMulti ? { name: 'my-blocks' } : block.blockType);
                  }}
                  onDoubleClick={() => {
                    if (!block.isMulti) return;
                    blockInPreviewDispatch({
                      type: SELECT_BLOCK,
                      payload: {
                        ...item.block,
                        column: item.x,
                        row: item.y
                      }
                    });
                    setEditBlockModalVisible(true);
                  }}
                  onContextMenu={(e) => {
                    if (selectedTextEditor) return;

                    setSelectedBlock(block._id);
                    setSelectedBlockType(block.isMulti ? { name: 'my-blocks' } : block.blockType);
                    handleRightClick(e);
                  }}>
                  {block.isMulti && block.blocksLayout ? (
                    <>
                      {block.blocksLayout.map((item) => {
                        return (
                          <div
                            key={item.block._id}
                            style={{
                              gridColumn: `${item.x + 1} / span ${item.w}`,
                              gridRow: `${item.y + 1} / span ${item.h}`,
                              paddingTop: (384 / 570) * item.block.blockProperties.textPaddingTop,
                              paddingRight:
                                (384 / 570) * item.block.blockProperties.textPaddingRight,
                              paddingBottom:
                                (384 / 570) * item.block.blockProperties.textPaddingBottom,
                              paddingLeft: (384 / 570) * item.block.blockProperties.textPaddingLeft,

                              fontSize: `${(384 / 570) * 16}px`,
                              border:
                                selectedBlock && selectedBlock === item.block._id
                                  ? '1px solid #3C9AEF'
                                  : item.block.blockProperties.iconBorderType &&
                                    item.block.blockProperties.iconBorderThickness &&
                                    item.block.blockProperties.iconBorderColor
                                  ? `${item.block.blockProperties.iconBorderThickness}em ${item.block.blockProperties.iconBorderType} ${item.block.blockProperties.iconBorderColor.hex}`
                                  : 'none',

                              backgroundColor: item.block.blockProperties.textBackgroundImage
                                ? ''
                                : item.block.blockProperties.iconBackgroundColor?.hex,
                              outline:
                                !blockInPreview &&
                                (block.endnotes?.references
                                  ?.find((reference) => reference.item._id === hoveredEndnote)
                                  ?.elements?.includes(item.block._id) ||
                                  block.endnotes?.footnotes
                                    ?.find((footnote) => footnote.item._id === hoveredEndnote)
                                    ?.elements.includes(item.block._id) ||
                                  block.endnotes?.abbreviations
                                    ?.find(
                                      (abbreviation) => abbreviation.item._id === hoveredEndnote
                                    )
                                    ?.elements.includes(item.block._id))
                                  ? '2px solid #4CAF50'
                                  : 'none'
                            }}>
                            {item.block.blockType.name === 'text' && (
                              <div
                                className="text-content-container"
                                // ref={textDivRef}
                                dangerouslySetInnerHTML={{
                                  __html: item.block.blockProperties.textContent
                                }}
                                style={{
                                  height: '100%',
                                  fontSize: `${(384 / 570) * 16}px`,
                                  // overflow: 'hidden',
                                  // border: textDivOverflow ? '1px solid red' : 'none', cursor: 'move',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center'
                                }}
                                // onDoubleClick={() => setSelectedTextEditor(item.block._id)}
                              ></div>
                            )}
                            {item.block.blockType.name === 'button' && (
                              <div
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: `${item.block.blockProperties.buttonWidth || 75}%`,
                                    height: `${item.block.blockProperties.buttonHeight || 75}%`,
                                    backgroundColor:
                                      item.block.blockProperties.buttonBackgroundColor?.hex,
                                    color: item.block.blockProperties.buttonTextColor,
                                    border:
                                      item.block.blockProperties.buttonBorderThickness &&
                                      item.block.blockProperties.buttonBorderType &&
                                      item.block.blockProperties.buttonBorderColor
                                        ? `${item.block.blockProperties.buttonBorderThickness} ${item.block.blockProperties.buttonBorderType} ${item.block.blockProperties.buttonBorderColor.hex}`
                                        : 'none',
                                    borderRadius: item.block.blockProperties.buttonBorderRadius,
                                    boxShadow: item.block.blockProperties.buttonHasShadow
                                      ? `
                              ${item.block.blockProperties.buttonInnerShadow ? 'inset' : ''}
                              ${item.block.blockProperties.buttonShadowX} 
                              ${item.block.blockProperties.buttonShadowY}
                              ${item.block.blockProperties.buttonShadowBlur}
                              rgba(${item.block.blockProperties.buttonShadowColor.rgb.r}, ${
                                          item.block.blockProperties.buttonShadowColor.rgb.g
                                        }, ${item.block.blockProperties.buttonShadowColor.rgb.b}, ${
                                          item.block.blockProperties.buttonShadowOpacity
                                        })
                            `
                                      : ''
                                    // cursor: selectedTextEditor && selectedTextEditor === item.block._id ? 'auto' : 'move'
                                  }}>
                                  <div
                                    className="text-content-container"
                                    dangerouslySetInnerHTML={{
                                      __html: item.block.blockProperties.buttonText
                                    }}
                                    // onDoubleClick={() => setSelectedTextEditor(item.block._id)}
                                  ></div>
                                </div>
                              </div>
                            )}
                            {item.block.blockType.name === 'divider' && (
                              <div className="divider-container">
                                <div
                                  className="divider"
                                  style={{
                                    width: `${item.block.blockProperties.dividerLength}%`,
                                    borderBottom:
                                      item.block.blockProperties.dividerThickness &&
                                      item.block.blockProperties.dividerType &&
                                      item.block.blockProperties.dividerColor
                                        ? `${item.block.blockProperties.dividerThickness}em ${item.block.blockProperties.dividerType} ${item.block.blockProperties.dividerColor.hex}`
                                        : 'none'
                                    // alignSelf: item.block.blockProperties.dividerAlignPosition === 'center' ? 'center' : (item.block.blockProperties.dividerAlignPosition === 'top' ? 'flex-start' : 'flex-end')
                                  }}></div>
                              </div>
                            )}
                            {item.block.blockType.name === 'icon' && (
                              <div
                                style={{
                                  height: '100%',
                                  backgroundColor: item.block.blockProperties.iconColor
                                    ? item.block.blockProperties.iconColor.hex
                                    : '#2A3A49',
                                  mask: `url(${item.block.blockProperties.iconSrc})`,
                                  maskRepeat: 'no-repeat',
                                  maskPosition: 'center center',
                                  maskSize: `${item.block.blockProperties.iconSize}%`,
                                  WebkitMask: `url(${item.block.blockProperties.iconSrc})`,
                                  WebkitMaskRepeat: 'no-repeat',
                                  WebkitMaskPosition: 'center center',
                                  WebkitMaskSize: `${item.block.blockProperties.iconSize}%`,
                                  position: 'relative'
                                  // zIndex: '-1'
                                }}></div>
                            )}
                            {item.block.blockType.name === 'form' && (
                              <FormElement block={item.block} font={defaultFont} width={384} />
                            )}
                            {item.block.blockType.name === 'survey' && (
                              <SurveyElement block={item.block} width={384} />
                            )}
                            {item.block.blockType.name === 'media' && (
                              <MediaElement block={item.block} width={384} />
                            )}
                            {item?.block?.blockType?.name === 'shape' && (
                              <ShapeElement block={item.block} width={384} />
                            )}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      className={block.blockType.name === 'text' ? 'text-element-container' : ''}
                      style={{
                        height: '100%',
                        backgroundColor:
                          item.block.blockType.name === 'icon'
                            ? item.block.blockProperties.iconBackgroundColor?.hex
                            : '',
                        border:
                          item.block.blockType.name === 'icon' &&
                          item.block.blockProperties.iconBorderThickness &&
                          item.block.blockProperties.iconBorderType &&
                          item.block.blockProperties.iconBorderColor
                            ? `${item.block.blockProperties.iconBorderThickness}em ${item.block.blockProperties.iconBorderType} ${item.block.blockProperties.iconBorderColor.hex}`
                            : '',
                        paddingTop: (384 / 570) * item.block.blockProperties.textPaddingTop,
                        paddingRight: (384 / 570) * item.block.blockProperties.textPaddingRight,
                        paddingBottom: (384 / 570) * item.block.blockProperties.textPaddingBottom,
                        paddingLeft: (384 / 570) * item.block.blockProperties.textPaddingLeft
                      }}>
                      {block.blockType.name === 'text' && (
                        <TextElement
                          element={item.block}
                          selectedTextEditor={selectedTextEditor}
                          setSelectedTextEditor={setSelectedTextEditor}
                          theme={qooInPreview.theme}
                          defaultFont={defaultFont}
                          handleChange={(value) => {
                            dispatch({
                              type: EDIT_SINGLE_ELEMENT,
                              payload: {
                                blockId: item.block._id,
                                updates: {
                                  textContent: value
                                }
                              }
                            });
                          }}
                          tinyMCEContentStyleString={tinyMCEContentStyleString}
                          tinyMCEFontFormatsString={tinyMCEFontFormatsString}
                          handleOverflow={(increment) => {
                            handleBlocksLayoutChange({
                              i: item.block._id,
                              x: item.x,
                              y: item.y,
                              w: item.w,
                              h: item.h + increment
                            });
                          }}
                          rowsNumber={item.h}
                          rowHeight={32}
                        />
                      )}
                      {block.blockType.name === 'button' && (
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: `${item.block.blockProperties.buttonWidth || 75}%`,
                              height: `${item.block.blockProperties.buttonHeight || 75}%`,
                              backgroundColor:
                                showButtonAsActive && block?._id === highlightedBlock?.block?._id
                                  ? item.block.blockProperties.buttonBackgroundColorActive?.hex
                                  : item.block.blockProperties.buttonBackgroundColor?.hex,
                              color: item.block.blockProperties.buttonTextColor,
                              border:
                                item.block.blockProperties.buttonBorderThickness &&
                                item.block.blockProperties.buttonBorderType &&
                                item.block.blockProperties.buttonBorderColor
                                  ? `${
                                      showButtonAsActive &&
                                      block?._id === highlightedBlock?.block?._id
                                        ? item.block.blockProperties.buttonBorderThicknessActive
                                        : item.block.blockProperties.buttonBorderThickness
                                    } ${
                                      showButtonAsActive &&
                                      block?._id === highlightedBlock?.block?._id
                                        ? item.block.blockProperties.buttonBorderTypeActive
                                        : item.block.blockProperties.buttonBorderType
                                    } ${
                                      showButtonAsActive &&
                                      block?._id === highlightedBlock?.block?._id
                                        ? item.block.blockProperties.buttonBorderColorActive?.hex
                                        : item.block.blockProperties.buttonBorderColor?.hex
                                    }`
                                  : 'none',
                              borderRadius: item.block.blockProperties.buttonBorderRadius,
                              boxShadow: item.block.blockProperties.buttonHasShadow
                                ? `
                            ${item.block.blockProperties.buttonInnerShadow ? 'inset' : ''}
                            ${item.block.blockProperties.buttonShadowX} 
                            ${item.block.blockProperties.buttonShadowY}
                            ${item.block.blockProperties.buttonShadowBlur}
                            rgba(${item.block.blockProperties.buttonShadowColor.rgb.r}, ${
                                    item.block.blockProperties.buttonShadowColor.rgb.g
                                  }, ${item.block.blockProperties.buttonShadowColor.rgb.b}, ${
                                    item.block.blockProperties.buttonShadowOpacity
                                  })
                          `
                                : '',
                              cursor:
                                selectedTextEditor && selectedTextEditor === item.block._id
                                  ? 'auto'
                                  : 'move'
                            }}>
                            {selectedTextEditor && selectedTextEditor === item.block._id ? (
                              <Editor
                                init={{
                                  inline: true,
                                  // fixed_toolbar_container: '#testtt',
                                  // height: `${item.block.size[0] * 95}`,
                                  menubar: false,
                                  plugins: [
                                    'importcss code link charmap lists wordcount charmap emoticons paste lists advlist autoresize'
                                  ],
                                  toolbar1: 'fontselect fontsizeselect forecolor',
                                  toolbar2:
                                    'bold italic underline alignleft aligncenter alignright alignjustify numlist bullist',
                                  // toolbar2: 'forecolor | bold italic underline | alignleft aligncenter alignright alignjustify | numlist bullist ',
                                  // toolbar1: 'fontselect',
                                  // toolbar2: 'fontsizeselect',
                                  // toolbar3: 'undo redo',
                                  // toolbar4: 'forecolor',
                                  // toolbar5: 'bold italic underline',
                                  // toolbar6: 'alignleft aligncenter alignright alignjustify',
                                  // toolbar7: 'numlist bullist',
                                  // plugins: 'autoresize',
                                  min_height: 48,
                                  autoresize_bottom_margin: 0,
                                  // toolbar: false,
                                  statusbar: false,
                                  contextmenu: '',
                                  content_style: tinyMCEContentStyleString,
                                  font_formats: tinyMCEFontFormatsString
                                    ? tinyMCEFontFormatsString
                                    : 'Arial Black=arial black,avant garde; Courier New=courier new,courier; Lato Black=lato; Roboto=roboto;',
                                  placeholder: 'Add text here',
                                  quickbars_insert_toolbar: false,
                                  // toolbar_mode: 'wrap',
                                  fontsize_formats:
                                    'small=0.75em normal=1em large=1.5em extra-large=2em',
                                  color_map: [
                                    ...qooInPreview.theme.colorPalette.colors.flatMap(
                                      (color, index) => [color.hex, color.hex]
                                    ),
                                    ...qooInPreview.theme.colorPalette.colorsBlackWhiteGrey.flatMap(
                                      (color, index) => [color.hex, color.hex]
                                    )
                                  ],
                                  browser_spellcheck: true
                                }}
                                onInit={(event, editor) => {
                                  editor.editorCommands.execCommand(
                                    'FontName',
                                    false,
                                    defaultFont.name.toLowerCase().replace(' -', '')
                                  );
                                  editor.contentAreaContainer.style.fontSize = '1em';
                                  editor.setContent(item.block.blockProperties.buttonText);
                                  editor.focus();
                                  editor.selection.select(editor.getBody(), true);
                                  editor.selection.collapse(false);
                                  editor.on('blur', (e) => {
                                    e.stopImmediatePropagation();
                                    setSelectedTextEditor(null);
                                  });
                                  editor.on('ExecCommand', (e) => {
                                    if (e.command === 'mceApplyTextcolor') {
                                      document.querySelector(
                                        'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                                      ).style.background = e.value;
                                    }
                                    if (e.command === 'mceRemoveTextcolor') {
                                      document.querySelector(
                                        'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                                      ).style.background = '#2A3A49';
                                    }
                                  });
                                  editor.formatter.formatChanged(
                                    'forecolor',
                                    function (isNew, args) {
                                      if (args.node.style.color) {
                                        document.querySelector(
                                          'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                                        ).style.background = args.node.style.color;
                                      } else {
                                        document.querySelector(
                                          'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                                        ).style.background = '#2A3A49';
                                      }
                                    },
                                    true
                                  );
                                  editor.on('keydown', (e) => {
                                    e.stopPropagation();
                                  });
                                }}
                                apiKey="j1zjwgyada24ar7u2uwpe3dxyhx6pduzftls0ex2z129yg13"
                                value={item.block.blockProperties.buttonText}
                                onEditorChange={(value, editor) => {
                                  dispatch({
                                    type: EDIT_SINGLE_ELEMENT,
                                    payload: {
                                      blockId: item.block._id,
                                      updates: {
                                        buttonText: value
                                      }
                                    }
                                  });
                                }}
                                // onFocus={() => setSelectedBlock(item.block._id)}
                                // onBlur={() => setSelectedBlock(null)}
                                // value={blockInPreview ? blockInPreview.blockProperties.textContent : ''}
                                // onEditorChange={(value, editor) => handleEditorChange(value, editor)}
                              />
                            ) : (
                              <div
                                className="text-content-container"
                                dangerouslySetInnerHTML={{
                                  __html: item.block.blockProperties.buttonText
                                }}
                                onDoubleClick={() => setSelectedTextEditor(item.block._id)}></div>
                            )}
                          </div>
                        </div>
                      )}
                      {block.blockType.name === 'divider' && (
                        <div className="divider-container">
                          <div
                            className="divider"
                            style={{
                              width: `${item.block.blockProperties.dividerLength}%`,
                              borderBottom:
                                item.block.blockProperties.dividerThickness &&
                                item.block.blockProperties.dividerType &&
                                item.block.blockProperties.dividerColor
                                  ? `${item.block.blockProperties.dividerThickness}em ${item.block.blockProperties.dividerType} ${item.block.blockProperties.dividerColor.hex}`
                                  : 'none'
                              // alignSelf: item.block.blockProperties.dividerAlignPosition === 'center' ? 'center' : (item.block.blockProperties.dividerAlignPosition === 'top' ? 'flex-start' : 'flex-end')
                            }}></div>
                        </div>
                      )}
                      {item.block.blockType.name === 'icon' && (
                        <div
                          style={{
                            height: '100%',
                            backgroundColor: item.block.blockProperties.iconColor
                              ? item.block.blockProperties.iconColor.hex
                              : '#2A3A49',
                            mask: `url(${item.block.blockProperties.iconSrc})`,
                            maskRepeat: 'no-repeat',
                            maskPosition: 'center center',
                            maskSize: `${item.block.blockProperties.iconSize}%`,
                            WebkitMask: `url(${item.block.blockProperties.iconSrc})`,
                            WebkitMaskRepeat: 'no-repeat',
                            WebkitMaskPosition: 'center center',
                            WebkitMaskSize: `${item.block.blockProperties.iconSize}%`,
                            position: 'relative'
                            // zIndex: '-1'
                          }}></div>
                      )}
                      {item.block.blockType.name === 'form' && (
                        <FormElement block={item.block} font={defaultFont} width={384} />
                      )}
                      {item.block.blockType.name === 'survey' && (
                        <SurveyElement
                          block={item.block}
                          width={384}
                          selectedTextEditor={selectedTextEditor}
                          setSelectedTextEditor={setSelectedTextEditor}
                          tinyMCEContentStyleString={tinyMCEContentStyleString}
                          tinyMCEFontFormatsString={tinyMCEFontFormatsString}
                          theme={qooInPreview.theme}
                          defaultFont={defaultFont}
                          editCallback={(value) => {
                            dispatch({
                              type: EDIT_SINGLE_ELEMENT,
                              payload: {
                                blockId: item.block._id,
                                updates: {
                                  surveyQuestion: value
                                }
                              }
                            });
                          }}
                        />
                      )}
                      {item.block.blockType.name === 'media' && (
                        <MediaElement block={item.block} width={384} />
                      )}
                      {item.block.blockType.name === 'shape' && (
                        <ShapeElement
                          // dispatch={dispatch}
                          block={item.block}
                          width={384}
                          // width={rowHeight * 12}
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            }
          }
        })}
    </GridLayout>
  );
};

export default QooPreviewGrid;
