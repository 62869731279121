import React, { useState } from 'react';
import NewThemeStep1 from './NewThemeStep1';
import NewThemeStep2 from './NewThemeStep2';
import NewThemeStep3 from './NewThemeStep3';
import NewThemeStep4 from './NewThemeStep4';
import NewThemeStep5 from './NewThemeStep5';
import NewThemeStep6 from './NewThemeStep6';
import NewThemeStep7 from './NewThemeStep7';
import NewThemeStep8 from './NewThemeStep8';

const NewThemeBuilder = ({ themeInPreview, dispatch, step, setStep, setSaveModalVisible }) => {
  const [completedStep, setCompletedStep] = useState(null);

  return (
    <div
      className='new-theme-builder'
    >
      {step === 1 &&
        <NewThemeStep1
          step={step}
          setStep={setStep}
          themeInPreview={themeInPreview}
          dispatch={dispatch}
          completedStep={completedStep}
          setCompletedStep={setCompletedStep}
        />
      }
      {step === 2 &&
        <NewThemeStep2
          step={step}
          setStep={setStep}
          themeInPreview={themeInPreview}
          dispatch={dispatch}
          completedStep={completedStep}
          setCompletedStep={setCompletedStep}
        />
      }
      {step === 3 &&
        <NewThemeStep3
          step={step}
          setStep={setStep}
          themeInPreview={themeInPreview}
          dispatch={dispatch}
          completedStep={completedStep}
          setCompletedStep={setCompletedStep}
        />
      }
      {step === 4 &&
        <NewThemeStep4
          step={step}
          setStep={setStep}
          themeInPreview={themeInPreview}
          dispatch={dispatch}
          completedStep={completedStep}
          setCompletedStep={setCompletedStep}
        />
      }
      {step === 5 &&
        <NewThemeStep5
          step={step}
          setStep={setStep}
          themeInPreview={themeInPreview}
          dispatch={dispatch}
          completedStep={completedStep}
          setCompletedStep={setCompletedStep}
        />
      }
      {step === 6 &&
        <NewThemeStep6
          step={step}
          setStep={setStep}
          themeInPreview={themeInPreview}
          dispatch={dispatch}
          completedStep={completedStep}
          setCompletedStep={setCompletedStep}
        />
      }
      {step === 7 &&
        <NewThemeStep7
          step={step}
          setStep={setStep}
          themeInPreview={themeInPreview}
          dispatch={dispatch}
          completedStep={completedStep}
          setCompletedStep={setCompletedStep}
        />
      }
      {step === 8 &&
        <NewThemeStep8
          step={step}
          setStep={setStep}
          themeInPreview={themeInPreview}
          dispatch={dispatch}
          completedStep={completedStep}
          setCompletedStep={setCompletedStep}
          setSaveModalVisible={setSaveModalVisible}
        />
      }
    </div>
  );
};

export default NewThemeBuilder;