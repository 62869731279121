import React, { useState } from 'react';
import { Modal, Button, Tabs, Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const DeleteModal = ({ visible, closeModal, title, deleteCallback, isBlock, qoos, qoolections, blockId }) => {
  const [impactedListVisible, setImpactedListVisible] = useState(false);
  const filteredQoolectionItems = qoolections?.data?.items?.filter((item) => {
    return item.qoos.length > 0;
  })
  const filteredQooItems = qoos?.data?.items?.filter((item) => {
    return item.blocksLayout.length > 0;
  })
  let finalQoolectionItems = [];
  let finalQooItems = [];
  if (isBlock && isBlock === true && blockId) {
    // get Qoolections that use this block
    if (filteredQoolectionItems && filteredQoolectionItems.length > 0) {
      for(let j = 0; j < filteredQoolectionItems.length; j++) {
        if(filteredQoolectionItems[j].qoos && filteredQoolectionItems[j].qoos.length > 0) {
          for(let i = 0; i < filteredQoolectionItems[j].qoos.length; i++) {
            filteredQoolectionItems[j].qoos[i].blocksLayout.map((block) => {
              if (block?.block?._id === blockId) {
                // add qoolections that match the filter
                finalQoolectionItems.push(filteredQoolectionItems[j]);
              }
              return block;
            })
          }
        }
      }
    }
    // get Qoos that use this block
    if(filteredQooItems && filteredQooItems.length > 0) {
      for(let i = 0; i < filteredQooItems.length; i++) {
        filteredQooItems[i].blocksLayout.map((block) => {
          if (block?.block?._id === blockId) {
            // add qoos that match the filter
            finalQooItems.push(filteredQooItems[i]);
          }
          return block;
        })
      }
    }
  }

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      footer={null}
      className='delete-modal'
      width={494}
      closable={false}
    >
      <h2 className="delete-modal__title">Delete <span className='delete-modal__title-element'>{title}</span>?</h2>
      <p className="delete-modal__subtitle">Note that you will not be able to recover it.</p>
      <div className="edit-block-modal">
        <h2 className="edit-block-modal__title">You are about to delete an existing Block</h2>
        <p className="edit-block-modal__text">Please be aware that selecting <strong>Delete</strong> will affect all Qoos/Qoolections that are currently using this Block. If any of those impacted Qoos/Qoolections are <em>In Approval</em> or <em>Approved</em>, they will be moved back to Draft and will require you to initiate Approval again.</p>

        <p className="edit-block-modal__text"> What would you like to do?​</p>

        <Checkbox
          className="edit-block-modal__checkbox edit-block-modal__show-impacted"
          checked={impactedListVisible}
          onChange={() => setImpactedListVisible(previous => !previous)}
        >
          Show me all impacted Qoos/Qoolections
        </Checkbox>
        {impactedListVisible &&
          <Tabs>
            <TabPane
              tab='Qoos'
              key='1'
            >
              <div className='edit-block-modal__list'>
                {qoos?.isLoading === false ?
                  (qoos?.data?.items?.length > 0 && finalQooItems && finalQooItems.length > 0 ?
                    finalQooItems.map((qoo) => {
                      return (
                        <div className='edit-block-modal__list-entry'>
                          <span className='edit-block-modal__list-entry-title'>
                            {`${qoo.title} (${qoo._id})`}
                          </span>
                          <span className='edit-block-modal__list-entry-status'>
                            {qoo.status}
                          </span>
                        </div>
                      )
                    }) :
                    'No Data'
                  ) :
                  (
                    <LoadingOutlined spin style={{ fontSize: '3rem' }} />
                  )
                }
              </div>
            </TabPane>
            <TabPane
              tab='Qoolections'
              key='2'
            >
              <div className='edit-block-modal__list'>
                {qoolections?.isLoading === false ?
                  (qoolections?.data?.items?.length > 0 && finalQoolectionItems && finalQoolectionItems.length > 0 ?
                    finalQoolectionItems.map((qoolection) => {
                      return (
                        <div className='edit-block-modal__list-entry'>
                          <span className='edit-block-modal__list-entry-title'>
                            {`${qoolection.title} (${qoolection._id})`}
                          </span>
                          <span className='edit-block-modal__list-entry-status'>
                            {qoolection.status}
                          </span>
                        </div>
                      )
                    }) :
                    'No Data'
                  ) :
                  (
                    <LoadingOutlined spin style={{ fontSize: '3rem' }} />
                  )
                }
              </div>
            </TabPane>
          </Tabs>
        }

        <div className="edit-block-modal__bottom-actions">
        <div className="delete-modal__buttons">
          <Button
            className='delete-modal__btn'
            onClick={closeModal}
            type='primary'
          >
            Cancel
          </Button>
          <Button
            className='delete-modal__btn'
            onClick={deleteCallback}
          >
            Delete
          </Button>
        </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;