export const SELECT_QOOLECTION = 'SELECT_QOOLECTION';
export const SELECT_QOOLECTION_WITH_HISTORY = 'SELECT_QOOLECTION_WITH_HISTORY';
export const ADD_QOO = 'ADD_QOO';
export const REMOVE_QOO = 'REMOVE_QOO';
export const CLEAR_ALL_QOOS = 'CLEAR_ALL_QOOS';
export const EDIT_QOOLECTION_PROPERTY = 'EDIT_QOOLECTION_PROPERTY';
export const EDIT_QOOLECTION_PROPERTY_WITHOUT_HISTORY = 'EDIT_QOOLECTION_PROPERTY_WITHOUT_HISTORY';
export const ADD_MENU_LINK = 'ADD_MENU_LINK';
export const REMOVE_MENU_LINK = 'REMOVE_MENU_LINK';
export const EDIT_MENU_LINK = 'EDIT_MENU_LINK';
export const UNDO = 'UNDO';
export const REDO = 'REDO';
export const EDIT_QOOLECTION_COLLABORATOR_ACCESS = 'EDIT_QOOLECTION_COLLABORATOR_ACCESS';
