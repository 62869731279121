import React, { useState } from 'react';
import { Button, Input, Select } from 'antd';
import {
  ADD_MENU_LINK,
  REMOVE_MENU_LINK,
  EDIT_MENU_LINK,
  SELECT_QOOLECTION_WITH_HISTORY
} from '../../reducers/qoolection/actionTypes';
import { ReactComponent as Close } from '../../icons/close.svg';
import _ from 'lodash';
import publishedMenuItemsTypes from '../../params/publishedMenuItemsTypes';
import { removeUrlProtocolFromUrl } from '../../utils/common';

const MenuLinksPanel = ({ qoolection, dispatch, updateQoolection, temporaryQoolection }) => {
  const [menuItemReference, setMenuItemReference] = useState('');

  const nth = (num) => {
    if (num > 3 && num < 21) return 'th';
    switch (num % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const handleBlur = async () => {
    const newQoolection = await updateQoolection(temporaryQoolection);
    dispatch({
      type: SELECT_QOOLECTION_WITH_HISTORY,
      payload: newQoolection
    });
  };

  const handleRemoveItem = async (index) => {
    const temporaryQoolectionMenuLinks = _.get(temporaryQoolection, 'menuLinks', []);

    const tempMenuLinksForRemoving = [...temporaryQoolectionMenuLinks];
    tempMenuLinksForRemoving.splice(index, 1);

    const newQoolection = await updateQoolection({ menuLinks: tempMenuLinksForRemoving });
    dispatch({
      type: SELECT_QOOLECTION_WITH_HISTORY,
      payload: newQoolection
    });
  };

  const renderMenuItemReference = (menuItem, index, temporaryQoolection) => {
    const menuItemType = _.get(menuItem, 'linkTo', '');
    const qoos = _.get(temporaryQoolection, 'qoos', []);

    if (menuItemType === publishedMenuItemsTypes.qoo.key) {
      return (
        <Select
          className="dropdown-small qoo-menu-panel__link-dropdown flex-fill-by-width"
          placeholder="Select Qoo"
          value={menuItem.link}
          onChange={(qooId) => {
            dispatch({
              type: EDIT_MENU_LINK,
              payload: {
                linkIndex: index,
                updates: {
                  link: qooId
                }
              }
            });
          }}>
          {qoos.map((qoo) => {
            const qooId = _.get(qoo, '_id', '');
            const title = _.get(qoo, 'title', '');

            return (
              <Select.Option key={qooId} value={qooId} label={title}>
                {title}
              </Select.Option>
            );
          })}
        </Select>
      );
    } else if (menuItemType === publishedMenuItemsTypes.webLink.key) {
      return (
        <Input
          // addonBefore={selectAddonUrlProtocol} // TODO: remove this later if not used.
          disabled={!menuItem.linkTo}
          placeholder="www.website.com"
          value={menuItem.link}
          onChange={(e) => {
            const url = _.get(e, 'target.value', '');

            dispatch({
              type: EDIT_MENU_LINK,
              payload: {
                linkIndex: index,
                updates: {
                  link: removeUrlProtocolFromUrl(url)
                }
              }
            });
          }}
        />
      );
    } else {
      return (
        <Input
          // placeholder='https://www.website.com'
          disabled={!menuItem.linkTo}
          placeholder={
            menuItem.linkTo
              ? menuItem.linkTo === 'web link'
                ? 'https://www.website.com'
                : menuItem.linkTo === 'plain text'
                ? 'Type here'
                : menuItem.linkTo === 'qoo link'
                ? 'Search Qoos'
                : menuItem.linkTo === 'footnotes'
                ? 'Search Footnotes'
                : ''
              : ''
          }
          value={menuItem.link}
          onChange={(e) => {
            dispatch({
              type: EDIT_MENU_LINK,
              payload: {
                linkIndex: index,
                updates: {
                  link: e.target.value
                }
              }
            });
          }}
        />
      );
    }
  };

  const temporaryQoolectionMenuLinks = _.get(temporaryQoolection, 'menuLinks', []);

  console.log('temporaryQoolectionMenuLinks: ', temporaryQoolectionMenuLinks);

  return (
    <>
      <div className="qoo-menu-panel__row">
        <div className="qoo-menu-panel__inputs" onBlur={handleBlur}>
          {temporaryQoolectionMenuLinks.map((item, index) => {
            return (
              <div className="qoo-menu-panel__link-item" key={`menuLinksPanelLinkItem__${index}`}>
                <div className="qoo-menu-panel__top-inputs">
                  <Input
                    placeholder={`Enter your ${index + 1}${nth(index + 1)} menu item`}
                    value={item.text}
                    onChange={(e) => {
                      dispatch({
                        type: EDIT_MENU_LINK,
                        payload: {
                          linkIndex: index,
                          updates: {
                            text: e.target.value
                          }
                        }
                      });
                    }}
                  />
                  <Close
                    className="qoo-menu-panel__remove-icon"
                    onClick={() => handleRemoveItem(index)}
                  />
                </div>

                <div className="qoo-menu-panel__bottom-inputs">
                  <Select
                    className="dropdown-small qoo-menu-panel__link-dropdown"
                    placeholder="Link to"
                    value={item.linkTo}
                    onChange={(value) => {
                      dispatch({
                        type: EDIT_MENU_LINK,
                        payload: {
                          linkIndex: index,
                          updates: {
                            linkTo: value,
                            link: '' // Reset menu item reference value.
                          }
                        }
                      });
                    }}>
                    <Select.Option value="web link" label="Website">
                      Website
                    </Select.Option>
                    <Select.Option value="qoo link" label="Qoo">
                      Qoo
                    </Select.Option>
                    <Select.Option value="footnotes" label="Footnotes">
                      Footnotes
                    </Select.Option>
                    {/* <Select.Option value='plain text' label='Plain text'>Plain text</Select.Option>
                    <Select.Option value='' label='None'>None</Select.Option> */}
                  </Select>

                  {renderMenuItemReference(item, index, temporaryQoolection)}
                </div>
              </div>
            );
          })}
          <Button
            block={true}
            onClick={() => {
              dispatch({
                type: ADD_MENU_LINK
              });
            }}>
            Add a Menu Item
          </Button>
        </div>
      </div>
    </>
  );
};

export default MenuLinksPanel;
