import { Form, Select } from 'antd';
import FormActions from './components/FormActions';

export default function VerifyQuestionForm({
  currentEvent,
  setCurrentEvent,
  blockEventHandler,
  eventIndex,
  questionsForBlockEvent
}) {
  const initialValues = {
    question: currentEvent?.question
  };

  return (
    <Form onFinish={blockEventHandler} initialValues={initialValues}>
      <Form.Item
        className='block-event__form-item'
        name='question'
        rules={[
          {
            required: true,
            message: 'Please select a Question!'
          }
        ]}>
        <Select
          className='block-event__element'
          placeholder='Select Question...'
          value={currentEvent?.question}
          onChange={(value) =>
            setCurrentEvent({
              ...currentEvent,
              question: value
            })
          }>
          {questionsForBlockEvent?.data?.items?.map((question) => (
            <Select.Option key={question?._id} value={question?._id}>
              {question?.question}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <FormActions eventIndex={eventIndex} setCurrentEvent={setCurrentEvent} />
    </Form>
  );
}
