import React, { useState, useRef, useEffect } from 'react';
import { Popconfirm } from 'antd';
import { ReactComponent as DotsMenuIcon } from '../../icons/ph_dots-three.svg';
import { ReactComponent as EditIcon } from '../../icons/ph_note-pencil-light.svg';
import { ReactComponent as RemoveIcon } from '../../icons/ph_trash-light.svg';
import { ReactComponent as LaunchIcon } from '../../icons/ph_rocket-launch-light.svg';
import { ReactComponent as CopyIcon } from '../../icons/ph_copy-light.svg';
import { ReactComponent as CheckIcon } from '../../icons/ph_check-circle-light.svg';
import { ReactComponent as ReturnIcon } from '../../icons/ph_arrow-bend-up-left-light.svg';
import { ReactComponent as PublishIcon } from '../../icons/ph_paper-plane-tilt-light.svg';
import { ReactComponent as RetireIcon } from '../../icons/ph_books-light.svg';
import { ReactComponent as UnpublishIcon } from '../../icons/ph_x-square-light.svg';
import { ReactComponent as ReviveIcon } from '../../icons/ph_arrow-counter-clockwise-light.svg';
import { ReactComponent as EyeIcon } from '../../icons/ph_eye-light.svg';
import { ReactComponent as ClockIcon } from '../../icons/ph_clock-light.svg';
import { ReactComponent as PDFIcon } from '../../icons/pdf.svg';

const QoolectionCardDropdown = ({
  qoolection,
  editDataHandler,
  deleteDataHandler,
  historyHandler,
  inTable,
  previewHandler,
  setQoolectionForApproval,
  setQoolectionForExport,
  duplicateQoolection,
  changeStatusForLocalQoolection
}) => {
  const dropdownRef = useRef();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleClickOutside = (e) => {
    if (
      dropdownVisible === true &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [dropdownVisible]);

  return (
    <div
      className={
        inTable
          ? 'qoolections-grid__qoolection-card-menu-icon qoolections-grid__qoolection-card-menu-icon--in-table'
          : !inTable && dropdownVisible
          ? 'qoolections-grid__qoolection-card-menu-icon qoolections-grid__qoolection-card-menu-icon--visible'
          : 'qoolections-grid__qoolection-card-menu-icon'
      }
      onClick={() => setDropdownVisible(true)}>
      <DotsMenuIcon />
      {dropdownVisible && (
        <div
          ref={dropdownRef}
          className={
            inTable
              ? 'qoolections-grid__qoolection-card-dropdown qoolections-grid__qoolection-card-dropdown--in-table'
              : 'qoolections-grid__qoolection-card-dropdown'
          }>
          {!inTable && (
            <div
              className="qoolections-grid__qoolection-card-dropdown-item"
              onClick={previewHandler}>
              <EyeIcon />
              <span>Preview</span>
            </div>
          )}
          {!inTable && (
            <div
              className="qoolections-grid__qoolection-card-dropdown-item"
              onClick={historyHandler}>
              <ClockIcon />
              <span>Version history</span>
            </div>
          )}
          {/* {!inTable && qoolection.status === 'draft' && */}
          {!inTable && (
            <div
              className="qoolections-grid__qoolection-card-dropdown-item"
              onClick={editDataHandler}>
              <EditIcon />
              <span>Edit</span>
            </div>
          )}
          {qoolection.status === 'draft' && (
            <div
              className="qoolections-grid__qoolection-card-dropdown-item"
              onClick={() => setQoolectionForApproval(qoolection)}>
              <LaunchIcon />
              <span>Approval</span>
            </div>
          )}

          {qoolection.status === 'in-approval' &&
            qoolection.approvalProcess &&
            qoolection.approvalProcess === 'local' && (
              <div
                className="qoolections-grid__qoolection-card-dropdown-item"
                onClick={() => changeStatusForLocalQoolection(qoolection._id, 'approved')}>
                <CheckIcon />
                <span>Approve</span>
              </div>
            )}

          {qoolection.status === 'approved' && (
            <div
              className="qoolections-grid__qoolection-card-dropdown-item"
              onClick={() => changeStatusForLocalQoolection(qoolection._id, 'published')}>
              <PublishIcon />
              <span>Publish</span>
            </div>
          )}

          {qoolection.status === 'published' && (
            <div
              className="qoolections-grid__qoolection-card-dropdown-item"
              onClick={() => changeStatusForLocalQoolection(qoolection._id, 'unpublish')}>
              <UnpublishIcon />
              <span>Unpublish</span>
            </div>
          )}

          {(qoolection.status === 'approved' || qoolection.status === 'published') && (
            <div className="qoolections-grid__qoolection-card-dropdown-item">
              <RetireIcon />
              <span>Retire</span>
            </div>
          )}

          {(qoolection.status === 'approved' ||
            (qoolection.status === 'in-approval' &&
              qoolection.approvalProcess &&
              qoolection.approvalProcess === 'local')) && (
            <div
              className="qoolections-grid__qoolection-card-dropdown-item"
              onClick={() => changeStatusForLocalQoolection(qoolection._id, 'draft')}>
              <ReturnIcon />
              <span>Return to draft</span>
            </div>
          )}

          {qoolection.status === 'retired' && (
            <div className="qoolections-grid__qoolection-card-dropdown-item">
              <ReviveIcon />
              <span>Revive</span>
            </div>
          )}

          <div
            className="qoolections-grid__qoolection-card-dropdown-item"
            onClick={() => setQoolectionForExport(qoolection)}>
            <PDFIcon />
            <span>Export PDF</span>
          </div>

          <div
            className="qoolections-grid__qoolection-card-dropdown-item"
            onClick={() => duplicateQoolection(qoolection)}>
            <CopyIcon />
            <span>Duplicate</span>
          </div>

          {/* {qoolection.status === 'draft' && */}
          <Popconfirm
            placement="bottom"
            title="Delete Qoolection?"
            onConfirm={deleteDataHandler}
            okText="Ok"
            cancelText="Cancel">
            <div
              className="qoolections-grid__qoolection-card-dropdown-item"
              // onClick={(e) => {
              //   e.stopPropagation();
              //   handleRemove();
              // }}
            >
              <RemoveIcon />
              <span>Delete</span>
            </div>
          </Popconfirm>
          {/* } */}
        </div>
      )}
    </div>
  );
};

export default QoolectionCardDropdown;
