import React, { useState } from 'react';
import { Button, Modal, Tag } from 'antd';
import QooDisplay from '../qoos/QooDisplay';
import { ReactComponent as LeftIcon } from '../../icons/ph_arrow-left-light.svg';
import { ReactComponent as RightIcon } from '../../icons/ph_arrow-right-light.svg';

const QoolectionHistoryModal = ({ qoolectionHistoryModalVisible, setQoolectionHistoryModalVisible, qoolectionHistory, onHistoryRestore }) => {
  const [selectedVersionIndex, setSelectedVersionIndex] = useState(0);
  const [qooIndex, setQooIndex] = useState(0);

  const selectedVersion = qoolectionHistory[selectedVersionIndex]?.oldObject;
  const selectedVersionValid = qoolectionHistory[selectedVersionIndex]?.valid;

  return (
    <Modal
      centered
      visible={qoolectionHistoryModalVisible}
      onCancel={() => setQoolectionHistoryModalVisible(false)}
      footer={null}
      width={1100}
      bodyStyle={{
        padding: '0'
      }}
    >
      <div className="history-modal">
        <div className="history-modal__versions">
          <h2 className="history-modal__title">Version history</h2>
          <h4 className="history-modal__name">
            <span>File name:</span>
            <span> {selectedVersion?.title}</span>
          </h4>
          <div className="history-modal__versions-list">
            {qoolectionHistory?.length > 0 && qoolectionHistory.map((item, index) => {
              return (
                <div
                  key={index}
                  className={selectedVersionIndex === index ? "history-modal__version history-modal__version--active" : "history-modal__version"}
                  onClick={() => setSelectedVersionIndex(index)}
                >
                  <span>Version {item.oldObject.version.major}.{item.oldObject.version.minor}.{item.oldObject.version.patch}</span>
                  <span>{item.oldObject.updatedAt.split('T')[0].split('-')[1] + '/' + item.oldObject.updatedAt.split('T')[0].split('-')[2] + '/' + item.oldObject.updatedAt.split('T')[0].split('-')[0]}</span>
                  <span>
                    <Tag
                      className={
                        item.oldObject.status === 'draft' ? 'table-tag--status-draft'
                          : item.oldObject.status === 'waiting-approval' ? 'table-tag--status-waiting-approval'
                            : item.oldObject.status === 'in-approval' ? 'table-tag--status-in-approval'
                              : item.oldObject.status === 'approved' ? 'table-tag--status-approved'
                                : item.oldObject.status === 'change_required' ? 'table-tag--status-change_required'
                                  : item.oldObject.status === 'shared' ? 'table-tag--status-shared'
                                    : item.oldObject.status === 'published' ? 'table-tag--status-published'
                                      : item.oldObject.status === 'retired' ? 'table-tag--status-retired'
                                        : 'table-tag'
                      }
                    >{item.oldObject.status.charAt(0).toUpperCase() + item.oldObject.status.substring(1)}
                    </Tag>
                  </span>
                </div>
              );
            })}
          </div>
          <Button
            type='primary'
            block={true}
            disabled={!selectedVersionValid}
            onClick={() => onHistoryRestore(qoolectionHistory[selectedVersionIndex]._id)}
            style={{ marginTop: 'auto' }}
          >
            Restore
          </Button>
        </div>
        <div className="history-modal__preview">
          <h3 className="qoolection-preview-modal__title">{selectedVersion?.version.major}.{selectedVersion?.version.minor}.{selectedVersion?.version.patch} Version</h3>
          <div className="theme-preview">
            <div className="theme-preview__phone-container">
              <div className="theme-preview__phone">
                <div className="theme-preview__top-bar"></div>
                <div className="theme-preview__black-bg"></div>
                <div
                  className="theme-preview__qoo-content"
                >
                  {selectedVersion && selectedVersion.qoos.length > 0 ? (
                    <QooDisplay
                      qooInPreview={selectedVersion.qoos[qooIndex]}
                      width={308}
                      height={585}
                    />
                  ) : (
                    <div className="qoolection-preview-modal__blank-bg"></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <LeftIcon
            className={qooIndex > 0 ? "qoolection-preview-modal__left-icon" : "qoolection-preview-modal__left-icon qoolection-preview-modal__left-icon--disabled"}
            onClick={() => {
              if (qooIndex > 0) {
                setQooIndex(previous => previous - 1);
              }
            }}
          />
          <RightIcon
            className={qooIndex < selectedVersion?.qoos.length - 1 ? "qoolection-preview-modal__right-icon" : "qoolection-preview-modal__right-icon qoolection-preview-modal__right-icon--disabled"}
            onClick={() => {
              if (qooIndex < selectedVersion?.qoos.length - 1) {
                setQooIndex(previous => previous + 1);
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default QoolectionHistoryModal;