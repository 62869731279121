import React from 'react';
import { Pagination } from 'antd';
import BlockCardDropdown from '../../pages/blocks/BlockCardDropdown';
import BlockCard from '../../pages/blocks/BlockCard';
import NoData from '../elements/NoData';

const BlocksGrid = ({
  data,
  deleteHandler,
  editHandler,
  historyHandler,
  columnKeys,
  title,
  editPath,
  viewPath,
  selectCallback,
  setOffset,
  total,
  current,
  qoos,
  qoolections
}) => {
  return (
    <div className="qoolections-grid-container">
      <div className="qoolections-grid">
        {data?.length > 0 ? (
          data.map((block) => {
            return (
              <BlockCard
                key={block._id}
                block={block}
                editDataHandler={() => editHandler(block._id)}
                deleteDataHandler={() => deleteHandler(block._id)}
                historyHandler={() => historyHandler(block._id)}
                qoos={qoos}
                qoolections={qoolections}
              />
            );
          })
        ) : (
          <NoData />
        )}
      </div>
      <div className="qoolections-grid__pagination">
        <Pagination
          current={current}
          total={total}
          onChange={(page) => setOffset(page - 1)}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default BlocksGrid;
