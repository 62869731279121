import { UPDATE_HEADERFOOTER_PROPERTY } from '../header-footer/actionTypes';
import {
  SET_THEME,
  EDIT_THEME_PROPERTY,
  ADD_COLOR_TO_PALETTE,
  SET_COLOR__PALETTE,
  SET_SELECTED_FONTS,
  ADD_FONT,
  REMOVE_FONT,
  ADD_FONT_GROUP,
  REMOVE_FONT_GROUP,
  SET_LOGO,
  SET_THEMEHEADERFOOTER,
  UPDATE_NAVIGATION_PROPERTY,
  ASSIGN_COLOR,


  EDIT_COLOR_PALETTE_ARRAY,
  REMOVE_COLOR_FROM_PALETTE,
  TOGGLE_NO_HEADER,
  SET_HEADER_LAYOUT,
  EDIT_MENU_PROPERTY,
  EDIT_FOOTER_PROPERTY,
  SET_BUTTON,
  EDIT_NAVIGATION_PROPERTY,
  EDIT_LOGO_PROPERTY
} from './actionTypes';

const themeInPreviewReducer = (state, action) => {
  switch (action.type) {
    case SET_THEME:
      return {
        themeInPreview: action.payload,
        themeInPreviewHistory: [],
        themeInPreviewFuture: []
      };
    case EDIT_THEME_PROPERTY:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          ...action.payload.updates
        }
      };

    case EDIT_COLOR_PALETTE_ARRAY:
      const tempColorsArray = [...state.themeInPreview.colorPalette.colors];
      tempColorsArray[action.payload.index] = action.payload.color;

      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          colorPalette: {
            ...state.themeInPreview.colorPalette,
            colors: tempColorsArray
          }
        }
      };
    case REMOVE_COLOR_FROM_PALETTE:
      const newColorsArray = [...state.themeInPreview.colorPalette.colors];
      newColorsArray.splice(action.payload, 1);

      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          colorPalette: {
            ...state.themeInPreview.colorPalette,
            colors: newColorsArray
          }
        }
      };

    case SET_COLOR__PALETTE:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          colorPalette: {
            ...state.themeInPreview.colorPalette,
            colors: action.payload
          }
        }
      };
    case ASSIGN_COLOR:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          colorAssignment: {
            ...state.themeInPreview.colorAssignment,
            ...action.payload.updates
          }
        }
      };
    case SET_SELECTED_FONTS:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          selectedFonts: action.payload.fonts
        }
      };
    case ADD_FONT:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          selectedFonts: [
            ...state.themeInPreview.selectedFonts,
            action.payload.font
          ]
        }
      };
    case REMOVE_FONT:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          selectedFonts: state.themeInPreview.selectedFonts.filter((font) => font._id !== action.payload.id)
        }
      };
    case ADD_FONT_GROUP:
      const notAlreadySelected = action.payload.fontGroup.filter((font) => !state.themeInPreview.selectedFonts.some((selectedFont) => selectedFont._id === font._id));

      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          selectedFonts: [...state.themeInPreview.selectedFonts, ...notAlreadySelected]
        }
      };
    case REMOVE_FONT_GROUP:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          selectedFonts: state.themeInPreview.selectedFonts.filter((selectedFont) => !action.payload.fontGroup.some((font) => font._id === selectedFont._id))
        }
      };
    case SET_LOGO:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          logo: action.payload.logo
        }
      };
    case SET_THEMEHEADERFOOTER:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          headerFooter: action.payload.headerFooter
        }
      };

    case UPDATE_HEADERFOOTER_PROPERTY:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          headerFooter: {
            ...state.themeInPreview.headerFooter,
            [`${action.payload.section}`]: {
              ...state.themeInPreview.headerFooter[`${action.payload.section}`],
              ...action.payload.updates
            }
          }
        }
      };

    case UPDATE_NAVIGATION_PROPERTY:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          navigation: {
            ...state.themeInPreview.navigation,
            ...action.payload.updates
          }
        }
      };


    //stari case-ovi po prvobitnom dizajnu

    case ADD_COLOR_TO_PALETTE:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          colorPalette: {
            ...state.themeInPreview.colorPalette,
            colors: [
              ...state.themeInPreview.colorPalette.colors,
              action.payload
            ]
          }
        }
      };
    case TOGGLE_NO_HEADER:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          header: {
            ...state.themeInPreview.header,
            noHeader: !state.themeInPreview.header.noHeader
          }
        }
      };
    case SET_HEADER_LAYOUT:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          header: {
            ...state.themeInPreview.header,
            layout: action.payload
          }
        }
      };
    case EDIT_MENU_PROPERTY:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          menu: {
            ...state.themeInPreview.menu,
            ...action.payload.updates
          }
        }
      };
    case EDIT_FOOTER_PROPERTY:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          footer: {
            ...state.themeInPreview.footer,
            ...action.payload.updates
          }
        }
      };
    case SET_BUTTON:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          button: {
            ...action.payload.updates
          }
        }
      };
    case EDIT_NAVIGATION_PROPERTY:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          navigation: {
            ...state.themeInPreview.navigation,
            ...action.payload.updates
          }
        }
      };
    case EDIT_LOGO_PROPERTY:
      return {
        themeInPreviewHistory: [...state.themeInPreviewHistory, state.themeInPreview],
        themeInPreviewFuture: [],
        themeInPreview: {
          ...state.themeInPreview,
          logo: {
            ...state.themeInPreview.logo,
            ...action.payload.updates
          }
        }
      };
    default:
      return state;
  }
};

export default themeInPreviewReducer;
