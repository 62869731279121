import { Modal, Button, Checkbox } from 'antd';
import React, { useState } from 'react';

const DeleteEndnoteModal = ({ endnote, closeModal, blockInPreview, qooInPreview, removeCallback }) => {
  const [elementsToRemoveFrom, setElementsToRemoveFrom] = useState([]);

  const endnoteType = endnote.abbreviation ? 'abbreviation' : endnote.reference ? 'reference' : 'footnote';
  const endnotesField = endnote.abbreviation ? 'abbreviations' : endnote.reference ? 'references' : 'footnotes';

  const getTextFromHTMLString = (str) => {
    const span = document.createElement('span');
    span.innerHTML = str;

    return span.textContent || span.innerText;
  };

  let elements;

  if (qooInPreview) {
    elements = qooInPreview.blocksLayout.filter(item => !item.block.isMulti && qooInPreview.endnotes[endnotesField]?.find(item => item.item._id === endnote._id)?.elements?.includes(item.block._id));
  } else {
    elements = blockInPreview.blocksLayout.filter(item => blockInPreview.endnotes[endnotesField]?.find(item => item.item._id === endnote._id)?.elements?.includes(item.block._id));
  }

  return (
    <Modal
      visible={!!endnote}
      onCancel={closeModal}
      footer={null}
      width={494}
      closable={false}
    >
      <h1 className='delete-modal__title'>Removing {endnote.abbreviation ? endnote.abbreviation : endnote.title} {endnoteType}</h1>
      <p className="delete-modal__subtitle">Please select elements you want to remove it from:</p>
      <div className="delete-modal__elements-list">
        {elements.map(item => (
          <div
            key={item.block._id}
            className="delete-modal__element"
          >
            <Checkbox
              checked={elementsToRemoveFrom.includes(item.block._id)}
              onChange={() => {
                if (elementsToRemoveFrom.includes(item.block._id)) {
                  setElementsToRemoveFrom(previous => previous.filter(element => element !== item.block._id));
                } else {
                  setElementsToRemoveFrom(previous => [...previous, item.block._id]);
                }
              }}
            />
            <span>{getTextFromHTMLString(item.block.blockProperties.textContent)}</span>
          </div>
        ))}
      </div>

      <div className="delete-modal__buttons">
        <Button
          className='delete-modal__btn'
          onClick={closeModal}
          type='primary'
        >
          Cancel
        </Button>
        <Button
          className='delete-modal__btn'
          onClick={() => removeCallback(endnote._id, elementsToRemoveFrom, endnotesField)}
          disabled={elementsToRemoveFrom.length === 0}
        >
          Remove
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteEndnoteModal;