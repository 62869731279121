import React from "react";
import { Select, Input } from "antd";
import {
  EDIT_QOOLECTION_PROPERTY_WITHOUT_HISTORY,
  SELECT_QOOLECTION_WITH_HISTORY,
} from "../../reducers/qoolection/actionTypes";

const AddInfoPanel = ({
  qoolection,
  dispatch,
  qoolectionTypes,
  categories,
  audiences,
  updateQoolection,
  temporaryQoolection,
  qoolectionTitleForm
}) => {

  const handleBlur = async () => {
    const newQoolection = await updateQoolection(temporaryQoolection);
    dispatch({
      type: SELECT_QOOLECTION_WITH_HISTORY,
      payload: newQoolection,
    });
  };

  return (
    <div className="add-info-form" onBlur={handleBlur}>
      <div className="add-info-form__item">
        <h4 className="add-info-form__label">Title *</h4>
        <Input
          placeholder="Title"
          value={temporaryQoolection?.title}
          onChange={(e) => {
            qoolectionTitleForm.setFieldValue('title', e.target.value);
            dispatch({
              type: EDIT_QOOLECTION_PROPERTY_WITHOUT_HISTORY,
              payload: {
                title: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className="add-info-form__item">
        <h4 className="add-info-form__label">Qoolection Type</h4>
        <Select
          placeholder="Qoolection Type"
          className="dropdown-small"
          value={temporaryQoolection?.type?._id || temporaryQoolection?.type}
          onChange={(value) => {
            dispatch({
              type: EDIT_QOOLECTION_PROPERTY_WITHOUT_HISTORY,
              payload: {
                type: value,
              },
            });
          }}
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          {qoolectionTypes?.data?.items &&
            qoolectionTypes?.data?.items.length > 0 &&
            qoolectionTypes?.data?.items.map((qoolectionType) => {
              return (
                <Select.Option
                  value={qoolectionType._id}
                  key={qoolectionType._id}
                >
                  {qoolectionType.name}
                </Select.Option>
              );
            })}
        </Select>
      </div>
      <div className="add-info-form__item">
        <h4 className="add-info-form__label">Categories</h4>
        <Select
          mode="multiple"
          placeholder="Select Categories"
          className="dropdown-small"
          value={temporaryQoolection?.category}
          onChange={(value) => {
            dispatch({
              type: EDIT_QOOLECTION_PROPERTY_WITHOUT_HISTORY,
              payload: {
                category: value,
              },
            });
          }}
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          {categories?.data?.items &&
            categories?.data?.items.length > 0 &&
            categories?.data?.items.map((category) => {
              return (
                <Select.Option value={category._id} key={category._id}>
                  {category.name}
                </Select.Option>
              );
            })}
        </Select>
      </div>
      <div className="add-info-form__item">
        <h4 className="add-info-form__label">Audiences</h4>
        <Select
          mode="multiple"
          placeholder="Select Audiences"
          className="dropdown-small"
          value={temporaryQoolection?.audience}
          onChange={(value) => {
            dispatch({
              type: EDIT_QOOLECTION_PROPERTY_WITHOUT_HISTORY,
              payload: {
                audience: value,
              },
            });
          }}
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          {audiences?.data?.items &&
            audiences?.data?.items.length > 0 &&
            audiences?.data?.items.map((audience) => {
              return (
                <Select.Option
                  value={audience._id}
                  key={audience._id}
                  label={audience.name}
                >
                  {audience.name}
                </Select.Option>
              );
            })}
        </Select>
      </div>
      <div className="add-info-form__item">
        <h4 className="add-info-form__label">Notes</h4>
        <Input.TextArea
          rows={4}
          placeholder="Notes"
          value={temporaryQoolection?.description}
          onChange={(e) => {
            dispatch({
              type: EDIT_QOOLECTION_PROPERTY_WITHOUT_HISTORY,
              payload: {
                description: e.target.value,
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export default AddInfoPanel;
