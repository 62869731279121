export const calculateHF = (noHeaderFooter, headerFooter) => {
  const header = headerFooter?.header;
  const footer = headerFooter?.footer;

  // 1.
  if (noHeaderFooter) {
    return {
      gridAlign: 'center',
    };
  }
  // 2.
  else if (header.noHeader && footer.noFooter && !footer.noISI && footer.shownISI === 'title-only') {
    return {
      gridAlign: 'flex-start',
      footerHeight: 2.6
    };
  }
  // 3.
  else if (header.noHeader && footer.noFooter && !footer.noISI && footer.shownISI === 'less-text') {
    return {
      gridAlign: 'flex-start',
      footerHeight: 2.6
    };
  }
  // 4.
  else if (header.noHeader && footer.noFooter && !footer.noISI && footer.shownISI === 'more-text') {
    return {
      gridAlign: 'flex-start',
      footerHeight: 4.6
    };
  }
  // 5.
  else if (header.noHeader && !footer.noFooter && footer.noISI) {
    return {
      gridAlign: 'flex-start',
      footerHeight: 2.6
    };
  }
  // 6.
  else if (header.noHeader && !footer.noFooter && !footer.noISI && footer.shownISI === 'title-only') {
    return {
      gridAlign: 'flex-start',
      footerHeight: 2.6
    };
  }
  // 7.
  else if (header.noHeader && !footer.noFooter && !footer.noISI && footer.shownISI === 'less-text') {
    return {
      gridAlign: 'flex-start',
      footerHeight: 4.6
    };
  }
  // 8.
  else if (header.noHeader && !footer.noFooter && !footer.noISI && footer.shownISI === 'more-text') {
    return {
      gridAlign: 'flex-start',
      footerHeight: 6.6
    };
  }
  // 9.
  else if (!header.noHeader && footer.noFooter && footer.noISI) {
    return {
      gridAlign: 'flex-end',
      headerHeight: 2.6
    };
  }
  // 10.
  else if (!header.noHeader && !footer.noFooter && footer.noISI) {
    return {
      gridAlign: 'flex-start',
      headerHeight: 2,
      footerHeight: 2.6
    };
  }
  // 11.
  else if (!header.noHeader && footer.noFooter && !footer.noISI && footer.shownISI === 'title-only') {
    return {
      gridAlign: 'flex-start',
      headerHeight: 2,
      footerHeight: 2.6
    };
  }
  // 12.
  else if (!header.noHeader && !footer.noFooter && !footer.noISI && footer.shownISI === 'title-only') {
    return {
      gridAlign: 'flex-start',
      headerHeight: 2,
      footerHeight: 2.6
    };
  }
  // 13.
  else if (!header.noHeader && footer.noFooter && !footer.noISI && footer.shownISI === 'less-text') {
    return {
      gridAlign: 'flex-start',
      headerHeight: 2,
      footerHeight: 2.6
    };
  }
  // 14.
  else if (!header.noHeader && !footer.noFooter && !footer.noISI && footer.shownISI === 'less-text') {
    return {
      gridAlign: 'flex-start',
      headerHeight: 2,
      footerHeight: 4.6
    };
  }
  // 15.
  else if (!header.noHeader && footer.noFooter && !footer.noISI && footer.shownISI === 'more-text') {
    return {
      gridAlign: 'flex-start',
      headerHeight: 2,
      footerHeight: 4.6
    };
  }
  // 16.
  else if (!header.noHeader && !footer.noFooter && !footer.noISI && footer.shownISI === 'more-text') {
    return {
      gridAlign: 'flex-start',
      headerHeight: 2,
      footerHeight: 6.6
    };
  }
  // 17.
  else {
    return {
      gridAlign: 'center',
    };
  }
};