import React, { useState } from 'react';
import { Modal, Button, Tabs, Checkbox } from 'antd';

const createPromise = () => {
  let resolver;
  return [new Promise((resolve, reject) => {

    resolver = resolve
  }), resolver]
}

const useConfirm = () => {
  const [open, setOpen] = useState(false);
  const [resolver, setResolver] = useState({ resolver: null })
  const [label, setLabel] = useState('')

  const getConfirmation = async (text) => {
    setLabel(text);
    setOpen(true);
    const [promise, resolve] = await createPromise()
    setResolver({ resolve })
    return promise;
  }

  const onClick = async (status) => {
    setOpen(false);
    resolver.resolve(status)
  }

  const Confirmation = () => (
    <Modal
      open={open}
      className='delete-modal'
      width={494}
      footer={null}
    >
      <h2 className="delete-modal__title">{label}</h2>
      <div className="edit-block-modal">
        <div className="edit-block-modal__bottom-actions">
          <div className="delete-modal__buttons">
            <Button
              className='delete-modal__btn'
              onClick={() => onClick(false)}
              type='primary'
            >
              Cancel
            </Button>
            <Button
              className='delete-modal__btn'
              onClick={() => onClick(true)}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )

  return [getConfirmation, Confirmation]

}

export default useConfirm;