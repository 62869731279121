import React from 'react';
import { Pagination } from 'antd';

import QooCardDropdown from '../../pages/qoos/QooCardDropdown';
import QooCard from '../../pages/qoos/QooCard';
import NoData from '../elements/NoData';

const QoosGrid = ({
  qoos,
  current,
  total,
  setOffset,
  deleteDataHandler,
  editDataHandler,
  historyHandler,
  columnKeys,
  title,
  editPath,
  viewPath,
  selectCallback,
  setQooInModalPreview,
  duplicateQoo
}) => {
  return (
    <div className="qoolections-grid-container">
      <div className="qoolections-grid">
        {qoos.length > 0 ? (
          qoos.map((qoo) => {
            return (
              <QooCard
                key={qoo._id}
                qoo={qoo}
                previewHandler={() => setQooInModalPreview(qoo)}
                deleteDataHandler={() => deleteDataHandler(qoo._id)}
                historyHandler={historyHandler}
                editDataHandler={() => editDataHandler(qoo._id)}
                duplicateQoo={duplicateQoo}
              />
            );
          })
        ) : (
          <NoData />
        )}
      </div>
      <div className="qoolections-grid__pagination">
        <Pagination
          current={current}
          total={total}
          onChange={(page) => setOffset(page - 1)}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default QoosGrid;
