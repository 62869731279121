import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AdminLayout from '../base/Layout';
// import { useSelector, shallowEqual } from 'react-redux';

const ProtectedRoute = ({ children, component: Component, allowed, user, ...rest }) => {
  // const userRoles = user && user.role.sort((a, b) => (a.trim().toLowerCase() > b.trim().toLowerCase() ? 1 : -1)).join('-');
  // const allowedRoles = allowed.sort((a, b) => (a.trim().toLowerCase() > b.trim().toLowerCase() ? 1 : -1)).join('-');
  
  return (
    <Route
      {...rest}
      render={(props) =>
        user.data && allowed.some((role) => user.data.role.includes(role) 
        // && user.checkIsLoggedIn()
        ) ? ( // if signed in and user's role is allowed
          <AdminLayout>
            <Component {...props} />
          </AdminLayout>
        ) : (
          <Redirect to='/login' />
        )
      }
    />
  );
};

export default ProtectedRoute;
