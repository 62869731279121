import { ReactComponent as Icon1 } from '../../icons/menu-icons/icon1.svg';
import { ReactComponent as Icon2 } from '../../icons/menu-icons/icon2.svg';
import { ReactComponent as Icon3 } from '../../icons/menu-icons/icon3.svg';
import { ReactComponent as Icon4 } from '../../icons/menu-icons/icon4.svg';
import { ReactComponent as Icon5 } from '../../icons/menu-icons/icon5.svg';
import { ReactComponent as Icon6 } from '../../icons/menu-icons/icon6.svg';
import { ReactComponent as Icon7 } from '../../icons/menu-icons/icon7.svg';
import { ReactComponent as Icon8 } from '../../icons/menu-icons/icon8.svg';
import { ReactComponent as Icon9 } from '../../icons/menu-icons/icon9.svg';
import { ReactComponent as Icon10 } from '../../icons/menu-icons/icon10.svg';

export const icons = [
  <Icon1 />, <Icon2 />, <Icon3 />, <Icon4 />, <Icon5 />, <Icon6 />, <Icon7 />, <Icon8 />, <Icon9 />, <Icon10 />
];
