import { Button, Form, Input, notification } from 'antd';
import React, { useContext } from 'react';
import { SERVER_URL } from '../../config';
import Axios from 'axios';
import { UserContext } from '../../App';

let initialValues = {
  abbreviation: '',
  definition: ''
};

const Abbreviation = ({
  token,
  library,
  setStateForRefetchingAbbreviations,
  endnoteForEdit,
  setAbbreviationToEdit,
  editCallback
}) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);

  const onSubmit = async (values) => {
    const method = endnoteForEdit ? 'put' : 'post';
    const route = endnoteForEdit
      ? `${SERVER_URL}/abbreviations/${endnoteForEdit._id}`
      : `${SERVER_URL}/abbreviations`;

    values.team = currentuser.selectedTeam;
    values.study = currentuser.study;
    try {
      const res = await Axios[method](route, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` }
      });

      notification.success({
        message: `Abbreviation ${endnoteForEdit ? 'updated' : 'created'}`,
        placement: 'bottomRight'
      });
      form.resetFields();

      if (endnoteForEdit && !library) {
        editCallback(res.data.item, 'abbreviations');
      } else {
        if (setStateForRefetchingAbbreviations) {
          setStateForRefetchingAbbreviations((prev) => prev + 1);
        }
      }

      // for library
      if (setAbbreviationToEdit) {
        setAbbreviationToEdit(null);
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Problem with creating abbreviation',
        placement: 'bottomRight'
      });
    }
  };

  const styles = {
    formPadding: {
      padding: library ? '10px 15px' : '0'
    }
  };

  if (endnoteForEdit) {
    initialValues = {
      ...initialValues,
      ...endnoteForEdit
    };
  }

  return (
    <div style={styles.formPadding}>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={(values) => onSubmit(values)}
        layout="vertical"
        preserve={false}>
        <Form.Item
          name="abbreviation"
          rules={[{ required: true, message: 'Please enter Abbreviation!' }]}>
          <Input placeholder="Abbreviation (highlighted text)" />
        </Form.Item>
        <Form.Item name="definition">
          <Input placeholder="Definition" />
        </Form.Item>
        <Button htmlType="submit" type="primary" style={{ width: '100%' }}>
          Save Abbreviation
        </Button>
      </Form>
    </div>
  );
};

export default Abbreviation;
