import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { notification, Input, Modal, Divider, Spin, Alert } from 'antd';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { SERVER_URL } from '../../config';
import QuestioneersTable from '../../components/tables/QuestioneersTable';
import QuestioneersForm from '../../components/forms/QuestioneersForm';

const TABLE_COLUMN_KEYS = ['_id', '__v'];

const Questioneers = ({ questioneers, setStateForRefetchingQuestioneers }) => {
  const currentuser = useContext(UserContext);
  const [searchFilter, setSearchFilter] = useState('');
  const [questioneerForEdit, setQuestioneerForEdit] = useState(null);
  const [questioneerForLock, setQuestioneerForLock] = useState(null);
  const [showQuestioneerDetails, setShowQuestioneerDetails] = useState(null);
  const [working, setWorking] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const questioneerInitialState = {
    client: currentuser.data.client?._id,
    study: currentuser.study,
    team: currentuser.selectedTeam,
    locked: false
  };
  const [data, setData] = useState(questioneerInitialState);

  useEffect(() => {
    setData(
      questioneers?.data?.items.filter((questioneer) => questioneer._id === questioneerForLock)[0]
    );
    setRefetch((prev) => !prev);
  }, [questioneerForLock]);

  useEffect(() => {
    if (questioneerForLock) {
      lockQuestioneerHandler();
    }
  }, [refetch]);

  let filteredQuestioneers;

  if (questioneers?.data?.items?.length > 0) {
    filteredQuestioneers = questioneers.data.items.filter((questioneer) => {
      const textMatch = searchFilter
        ? questioneer.name.toLowerCase().includes(searchFilter.toLowerCase())
        : true;
      return textMatch;
    });
  }

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/questioneers/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      setStateForRefetchingQuestioneers((prev) => prev + 1);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const lockQuestioneerHandler = async () => {
    try {
      await Axios.put(
        `${SERVER_URL}/questioneers/${questioneerForLock}`,
        [{ $set: { locked: { $not: '$locked' } } }],
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      notification.success({
        message: 'Item updated.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      setStateForRefetchingQuestioneers((prev) => prev + 1);
      setQuestioneerForLock(null);
    } catch (err) {
      notification.error({
        message: 'Problem with update. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const getQuestioneerDetailsHandler = async (questioneerId) => {
    try {
      const questioner = await Axios.get(
        `${SERVER_URL}/questioneers-get-populated-questioneer/${questioneerId}`,
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      setShowQuestioneerDetails(questioner.data);
      setStateForRefetchingQuestioneers((prev) => prev + 1);
      setQuestioneerForLock(null);
    } catch (err) {
      notification.error({
        message: 'Problem with showing ePRO info. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const createEproQoolectionHandler = async (questioneerId) => {
    setWorking(true);
    try {
      await Axios.post(
        `${SERVER_URL}/questioneers-create-epro-qoolection/${questioneerId}`,
        { team: currentuser?.selectedTeam, study: currentuser?.study },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      notification.success({
        message: 'ePRO Qoolection created.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      setStateForRefetchingQuestioneers((prev) => prev + 1);
      setQuestioneerForLock(null);
      setWorking(false);
    } catch (err) {
      notification.error({
        message: 'Problem with creating ePRO Qoolection. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  let columnKeys;
  if (questioneers.data && questioneers.data.items && questioneers.data.items.length > 0) {
    const keys = Object.keys(questioneers.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (questioneers.data && questioneers.data.items && questioneers.data.items.length > 0) {
    tableData = questioneers.data.items;
  }
  if (filteredQuestioneers && filteredQuestioneers.length > 0) {
    tableData = filteredQuestioneers;
  }

  return (
    <>
      <div className="table-header">
        <Input
          className="table-header__search"
          placeholder="Search ePRO"
          prefix={<SearchIcon />}
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
      </div>
      {working && (
        <Spin size="large" tip="Working...">
          <Alert
            message="Creating ePRO"
            description="Please wait until ePRO is created."
            type="info"
          />
        </Spin>
      )}
      {!working && tableData && tableData.length > 0 && (
        <QuestioneersTable
          data={tableData}
          deleteHandler={deleteDataHandler}
          lockQuestionHandler={lockQuestioneerHandler}
          setQuestioneerForEdit={setQuestioneerForEdit}
          setQuestioneerForLock={setQuestioneerForLock}
          getQuestioneerDetailsHandler={getQuestioneerDetailsHandler}
          createEproQoolectionHandler={createEproQoolectionHandler}
          columnKeys={columnKeys}
          title="Questioneer"
        />
      )}
      {questioneerForEdit && (
        <Modal
          title="Edit ePRO"
          open={questioneerForEdit}
          footer={null}
          onCancel={() => setQuestioneerForEdit(null)}>
          <QuestioneersForm
            isNew={false}
            questioneerForEdit={questioneerForEdit}
            setQuestioneerForEdit={setQuestioneerForEdit}
            setStateForRefetchingQuestioneers={setStateForRefetchingQuestioneers}
          />
        </Modal>
      )}
      {showQuestioneerDetails && (
        <Modal
          title="Details"
          open={showQuestioneerDetails}
          footer={null}
          onCancel={() => setShowQuestioneerDetails(null)}>
          <div style={{ maxHeight: '600px', overflow: 'auto', paddingRight: '0.625rem' }}>
            <h2 style={{ fontSize: '1.25rem' }}>{showQuestioneerDetails.name}</h2>
            <Divider />
            {showQuestioneerDetails.questions.map((question, index) => (
              <div key={index}>
                <h3>{question.question}</h3>
                {question.answers.map((answer, index) => (
                  <p key={index}>• {answer.description}</p>
                ))}
                <Divider />
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default Questioneers;
