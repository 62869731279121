import React from 'react';
import { ReactComponent as CloseIcon } from '../../icons/ph_x-light.svg';
import { useSpring, animated, easings } from 'react-spring';
import _ from 'lodash';
import publishedMenuItemsTypes from '../../params/publishedMenuItemsTypes';

const PublishedSideBarMenu = ({ qoolectionInPreview, qooInPreview, onMenuItemClick, onMenuCloseClick, isMenuOpened }) => {
  const menuTransition = useSpring({
    transform: isMenuOpened ? `translateX(0%)` : `translateX(100%)`,
    width: '100%',
    height: '100vh',
    position: 'absolute',
    zIndex: '99999',
    config: {
      duration: isMenuOpened ? 360 : 360,
      easing: isMenuOpened ? easings.easeOutSine : easings.easeOutSine,
    },
  });

  const isThisMenuItemSelected = (qooIndex, menuItem) => {
    const menuItemType = _.get(menuItem, 'linkTo', '');

    if (
      !menuItemType ||
      menuItemType === publishedMenuItemsTypes.webLink.key ||
      menuItemType === publishedMenuItemsTypes.footnotes.key
    ) {
      // If menu item type is invalid, or it is of type 'web link' or 'footnotes'
      // return false since those menu items can not be highlighted.
      // Only menu items of type 'qoo link' can be highlighted.
      return false;
    }

    // Menu item type: qoo link
    const qooIdFromQoolection = _.get(qoolectionInPreview, `qoos.[${qooIndex}]._id`, null);
    const qooIdFromQooInPreview = _.get(qooInPreview, `_id`, null);

    return qooIdFromQoolection && qooIdFromQooInPreview && qooIdFromQoolection === qooIdFromQooInPreview;
  };

  const qoolectionInPreviewMenuLinks = _.get(qoolectionInPreview, 'menuLinks', []);

  return (
    <animated.div style={menuTransition}>
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          padding: '80px 40px',
          zIndex: '1',
          backgroundColor: qoolectionInPreview.theme.headerFooter.header.menuOverlayColor ? `rgba(${qoolectionInPreview.theme.headerFooter.header.menuOverlayColor.rgb.r}, ${qoolectionInPreview.theme.headerFooter.header.menuOverlayColor.rgb.g}, ${qoolectionInPreview.theme.headerFooter.header.menuOverlayColor.rgb.b}, ${qoolectionInPreview.theme.headerFooter.header.menuOverlayColor.rgb.a})` : '#F4F6F7',
          display: 'flex',
          flexDirection: 'column',
          // gap: '36px',
          alignItems: qoolectionInPreview.theme.headerFooter.header.menuOverlayTextAlign,
          color: qoolectionInPreview.theme.headerFooter.header.menuOverlayTextColor?.hex,
          fontFamily: qoolectionInPreview.theme.headerFooter.header.menuOverlayFont?.name,
          fontWeight: qoolectionInPreview.theme.headerFooter.header.menuOverlayFont?.weight,
          fontStyle: qoolectionInPreview.theme.headerFooter.header.menuOverlayFont?.style,
          fontSize: qoolectionInPreview.theme.headerFooter.header.menuOverlayFontSize === 'S' ? '0.75em' : qoolectionInPreview.theme.headerFooter.header.menuOverlayFontSize === 'L' ? '1.5em' : '1em',
        }}
      >
        {qoolectionInPreviewMenuLinks.map((item, index) => {
          const menuItemStyle = {
            marginBottom: '36px',
            color: isThisMenuItemSelected(index, item) ? qoolectionInPreview.theme.headerFooter.header.menuOverlayHighlightedTextColor?.hex : qoolectionInPreview.theme.headerFooter.header.menuOverlayTextColor?.hex,
          };

          return (
            <span
              key={`menu_item_${index}`}
              onClick={onMenuItemClick.bind(this, item)}
              style={menuItemStyle}
            >{item.text}</span>
          );
        })}
        <div
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
        >
          <CloseIcon style={{ position: 'absolute', width: '24px', height: '24px', top: '12px', right: '12px' }} onClick={onMenuCloseClick} />
        </div>
      </div>
    </animated.div>
  );
};

export default PublishedSideBarMenu;