import React, { useState } from 'react';
import ColorsTab from './ColorsTab';
import StepNavigation from './StepNavigation';
import { SET_COLOR__PALETTE, ASSIGN_COLOR } from '../../reducers/theme-in-preview/actionTypes';
import ColorPickerDropdown from '../../components/elements/ColorPickerDropdown';
import { Button } from 'antd';

const presets = [
  [
    {
      hex: '#4962E2',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    {
      hex: '#FFC197',
      rgb: { r: 255, g: 193, b: 151, a: 1 }
    },
    {
      hex: '#FF7BAC',
      rgb: { r: 255, g: 123, b: 172, a: 1 }
    },
    {
      hex: '#31DDD4',
      rgb: { r: 49, g: 221, b: 212, a: 1 }
    }
  ],
  [
    {
      hex: '#ff0000',
      rgb: { r: 255, g: 0, b: 0, a: 1 }
    },
    {
      hex: '#008000',
      rgb: { r: 0, g: 128, b: 0, a: 1 }
    },
    {
      hex: '#0000ff',
      rgb: { r: 0, g: 0, b: 255, a: 1 }
    },
    {
      hex: '#ffff00',
      rgb: { r: 255, g: 255, b: 0, a: 1 }
    }
  ],
  [
    {
      hex: '#4962E2',
      rgb: { r: 73, g: 98, b: 226, a: 1 }
    },
    {
      hex: '#FFC197',
      rgb: { r: 255, g: 193, b: 151, a: 1 }
    },
    {
      hex: '#FF7BAC',
      rgb: { r: 255, g: 123, b: 172, a: 1 }
    },
    {
      hex: '#31DDD4',
      rgb: { r: 49, g: 221, b: 212, a: 1 }
    }
  ]
];

const NewThemeStep2 = ({
  step,
  setStep,
  themeInPreview,
  dispatch,
  completedStep,
  setCompletedStep
}) => {
  const [presetsVisible, setPresetsVisible] = useState(false);
  const [presetSelected, setPresetSelected] = useState(false);

  return (
    <div className="new-theme-builder__step-2">
      <h2 className="new-theme-builder__step-subtitle">
        <span>Step 2: </span>
        <span>Next, let's add your color palette.</span>
      </h2>
      <StepNavigation step={step} setStep={setStep} completedStep={completedStep} />
      <p className="new-theme-builder__intro-text">Add individual colors to your custom palette.</p>
      <ColorsTab
        isNew={true}
        themeInPreview={themeInPreview}
        dispatch={dispatch}
        presetSelected={presetSelected}
        setPresetSelected={setPresetSelected}
      />
      {!presetsVisible && (
        <p className="new-theme-builder__small-text">
          <span>Don't have colors already? Check out our </span>
          <span
            className="new-theme-builder__color-presets-btn"
            onClick={() => setPresetsVisible(true)}>
            preset color palettes.
          </span>
        </p>
      )}
      {presetsVisible && (
        <p className="new-theme-builder__intro-text">Select one of our preset color palettes.</p>
      )}

      {presetsVisible && (
        <div className="colors-tab__presets">
          <div className="colors-tab__presets-list">
            {presets &&
              presets.length > 0 &&
              presets.map((preset, index) => {
                return (
                  <div
                    key={index}
                    className="colors-tab__preset-container"
                    onClick={() => {
                      dispatch({
                        type: SET_COLOR__PALETTE,
                        payload: preset
                      });
                      setPresetSelected(true);
                      setPresetsVisible(false);
                    }}>
                    <div className="colors-tab__preset">
                      {preset.map((color, index) => (
                        <div
                          key={index}
                          className="colors-tab__preset-color-field"
                          style={{ backgroundColor: color.hex }}></div>
                      ))}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <p className="new-theme-builder__intro-text">
        Would you like to assign individual colors as defaults to various elements, e.g., text
        color?
      </p>
      <p className="new-theme-builder__small-text">
        Note: This is optional. You will be able set and adjust all colors throughout QClinical. Use
        this only if you want to set defaults to help speed things up later.
      </p>
      <div className="colors-tab__assignment">
        <div className="colors-tab__assignment-row">
          <span className="colors-tab__row-title">Qoo Background</span>
          <ColorPickerDropdown
            colors={themeInPreview.colorPalette.colors}
            colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
            selectedColor={themeInPreview.colorAssignment.qooBackground}
            leftRadius
            rightRadius
            callback={(color) => {
              dispatch({
                type: ASSIGN_COLOR,
                payload: {
                  updates: {
                    qooBackground: color
                  }
                }
              });
            }}
          />
        </div>
        <div className="colors-tab__assignment-row">
          <span className="colors-tab__row-title">Button Color</span>
          <ColorPickerDropdown
            colors={themeInPreview.colorPalette.colors}
            colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
            selectedColor={themeInPreview.colorAssignment.buttonColor}
            leftRadius
            callback={(color) => {
              dispatch({
                type: ASSIGN_COLOR,
                payload: {
                  updates: {
                    buttonColor: color
                  }
                }
              });
            }}
          />
        </div>
        <div className="colors-tab__assignment-row">
          <span className="colors-tab__row-title">Button Text Color</span>
          <ColorPickerDropdown
            colors={themeInPreview.colorPalette.colors}
            colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
            selectedColor={themeInPreview.colorAssignment.buttonTextColor}
            leftRadius
            rightRadius
            callback={(color) => {
              dispatch({
                type: ASSIGN_COLOR,
                payload: {
                  updates: {
                    buttonTextColor: color
                  }
                }
              });
            }}
          />
        </div>
        <div className="colors-tab__assignment-row">
          <span className="colors-tab__row-title">Font Color</span>
          <ColorPickerDropdown
            colors={themeInPreview.colorPalette.colors}
            colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
            selectedColor={themeInPreview.colorAssignment.fontColor}
            leftRadius
            rightRadius
            callback={(color) => {
              dispatch({
                type: ASSIGN_COLOR,
                payload: {
                  updates: {
                    fontColor: color
                  }
                }
              });
            }}
          />
        </div>
      </div>
      <div className="new-theme-builder__bottom-row">
        <Button className="new-theme-builder__step-btn" onClick={() => setStep(1)}>
          Previous step
        </Button>
        <Button
          className="new-theme-builder__step-btn"
          type="primary"
          onClick={() => {
            if (completedStep === 1) {
              setCompletedStep(2);
            }
            setStep(3);
          }}
          disabled={!themeInPreview.colorPalette.colors.length > 0}>
          Next step
        </Button>
      </div>
    </div>
  );
};

export default NewThemeStep2;
