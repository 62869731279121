import React, { useEffect, useContext, useState, useReducer } from 'react';
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';
import { Form, Input, Select, notification, Layout, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { LayoutContext } from '../../components/base/Layout';
import HeaderComponent from '../../components/base/HeaderComponent';
import SideBarInner from '../../components/elements/SideBarInner';
import QoosList from './QoosList';
import QooBuilder from './QooBuilder';
import blockInPreviewReducer from '../../reducers/block-in-preview/blockInPreviewReducer';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import _ from 'lodash';
import ThemeModal from '../../components/elements/ThemeModal';

const { Content } = Layout;

const EditQoo = () => {
  const currentuser = useContext(UserContext);
  const layoutContext = useContext(LayoutContext);
  const { qooId } = useParams();
  const isNew = qooId ? false : true;
  const history = useHistory();
  const [form] = Form.useForm();
  const [createFrom, setCreateFrom] = useState(null);
  const [qoo, fetchQoo] = useAxios('', {}, currentuser.data.token, 'get');
  const [selectedQoo, setSelectedQoo] = useState(null);
  const location = useLocation();
  const isCreateNew = location.state?.isCreateNew;
  const fromQoolection = location.state?.fromQoolection;

  const [audiences, fetchAudiences] = useAxios('', {}, currentuser.data.token, 'get');
  const [categories, fetchCategories] = useAxios('', {}, currentuser.data.token, 'get');
  const [activeTheme, fetchActiveTheme] = useAxios('', {}, currentuser.data.token, 'get');
  const [userThemes, fetchUserThemes] = useAxios('', {}, currentuser.data.token, 'get');
  const [qooTypes, fetchQooTypes] = useAxios('', {}, currentuser.data.token, 'get');
  const [visibleModal, setVisibleModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [activeThemes, setActiveThemes] = useState([]);
  const initEditingBlockState = {
    blockInPreview: null,
    blockInPreviewHistory: [],
    blockInPreviewFuture: []
  };
  const [editingBlockState, blockInPreviewDispatch] = useReducer(
    blockInPreviewReducer,
    initEditingBlockState
  );
  const { blockInPreview, blockInPreviewHistory, blockInPreviewFuture } = editingBlockState;
  const isSuperAdmin = currentuser.data.role.some((item) => item === 'superadmin');
  useEffect(() => {
    if (!isNew) {
      currentuser.setIsNavMenuCollapsed(true);
    }
  }, []);

  useEffect(() => {
    if (qooId) fetchQoo(`${SERVER_URL}/qoos/${qooId}`, {});
  }, [qooId, fetchQoo]);

  useEffect(() => {
    fetchCategories(`${SERVER_URL}/categories`, {});
    fetchAudiences(`${SERVER_URL}/audiences`, {});
    fetchQooTypes(`${SERVER_URL}/qoo-types`, {});
    if ((!isSuperAdmin && currentuser.study) || isSuperAdmin) {
      fetchUserThemes(
        `${SERVER_URL}/get-user-themes/${currentuser.data.id}?team=${currentuser.selectedTeam}&study=${currentuser.study}`,
        {}
      );
      fetchActiveTheme(
        `${SERVER_URL}/get-active-theme/${currentuser.data.id}?team=${currentuser.selectedTeam}&study=${currentuser.study}`,
        {}
      );
    }
  }, [
    fetchCategories,
    fetchAudiences,
    fetchQooTypes,
    fetchActiveTheme,
    fetchUserThemes,
    currentuser
  ]);

  useEffect(() => {
    Axios.get(`${SERVER_URL}/users/${currentuser.data.id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` }
    }).then((resp) => {
      setActiveThemes(resp.data.activeThemes || []);
    });
  }, [currentuser]);

  const isDataFetched = !qoo.isLoading;

  const isDataFetchedWithBlockTypeAndAudiencesAndCategoriesAndThemes =
    !qoo.isLoading &&
    qoo.data &&
    currentuser.language &&
    !audiences.isLoading &&
    !categories.isLoading &&
    !qooTypes.isLoading &&
    qooTypes.data.items &&
    qooTypes.data.items.length > 0;
  //   &&
  // !activeTheme.isLoading &&
  // !activeTheme.isError &&
  // activeTheme.data._id;

  useEffect(() => {
    const noActiveOrCreatedTheme =
      (!activeTheme.isLoading && !activeTheme.isError && !activeTheme.data._id) ||
      (!userThemes.isLoading && !userThemes.isError && userThemes.data.items?.length === 0);

    if (isDataFetchedWithBlockTypeAndAudiencesAndCategoriesAndThemes && noActiveOrCreatedTheme) {
      if (userThemes.data.items && userThemes.data.items.length === 0) {
        setModalMessage('Create a theme');
        setVisibleModal(true);
      } else if (!activeTheme.data._id && userThemes.data.items?.length > 0) {
        setModalMessage('Activate a theme');
        setVisibleModal(true);
      }
    }
  }, [activeTheme, isDataFetchedWithBlockTypeAndAudiencesAndCategoriesAndThemes, userThemes]);

  const onSubmit = async (formData, isNew) => {
    // const isEpro = formData.blocksLayout?.filter(item => item.block.name === 'question' || item.block.name === 'description').length > 0;
    // if(isEpro){
    //   const eproType = qooTypes.data.items.filter( item => item.name === 'ePro' );
    //   formData = {...formData, qooType: eproType[0]?._id }
    // }
    const { selectedTeam, study } = currentuser;
    const submitData = isNew
      ? { ...formData, theme: activeTheme.data, team: selectedTeam, study }
      : { ...formData, team: selectedTeam, study };

    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/qoos` : `${SERVER_URL}/qoos/${qooId}`;

    const qooTitle = _.get(formData, 'title', '');
    const qooType = _.get(formData, 'qooType', null);

    if (!qooTitle) {
      const element = document.getElementById('qoo_builder_info_name');
      if (element) {
        element.focus();
      }

      notification.error({
        message: 'Name is required',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });

      return false;
    }

    if (!qooType) {
      const element = document.getElementById('qoo_builder_info_qoo_type');

      if (element) {
        element.focus();
      }

      notification.error({
        message: 'Qoo Type is required. Select it in section "Info".',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });

      return false;
    }

    if (submitData.isBlankQoo && fromQoolection) {
      submitData.isBlankQoo = null;
    }

    try {
      const response = await Axios[method](route, submitData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      if (!isNew) {
        notification.success({
          message: `Qoo updated.`,
          placement: 'bottomRight',
          icon: notificationSuccess.icon,
          className: notificationSuccess.className,
          duration: notificationSuccess.duration
        });
      }

      layoutContext.setEditInProgress(false);

      if (isNew) {
        history.push(`/admin/edit-qoo/${response.data.item._id}`, {
          isCreateNew: true
        });
      } else if (fromQoolection) {
        history.push(`/admin/edit-qoolection/${fromQoolection.qoolection._id}`, {
          isCreateNew: fromQoolection.isCreateNew
        });
      } else {
        history.push('/admin/qoos');
      }
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const cancelFunction = () => {
    layoutContext.setEditInProgress(false);
    if (fromQoolection) {
      history.push(`/admin/edit-qoolection/${fromQoolection.qoolection._id}`, {
        isCreateNew: fromQoolection.isCreateNew
      });
    } else {
      history.push('/admin/qoos');
    }
  };

  // TREBALO PO STAROM WIREFRAMU. OSTAVI ZA SADA PA POSLE AKO NE TREBA OBRISATI
  //  VVVVVVVVVVVVVVVVVV

  // const createNew = (data) => {
  //   form.validateFields()
  //     .then(() => {
  //       onSubmit(data, true);
  //     }).catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const handleSelectCreateFrom = (value) => {
  //   setSelectedQoo(null);
  //   form.validateFields().then(() => {
  //     setCreateFrom(value);
  //   }).catch((err) => {
  //     console.log(err);
  //   });
  // };

  const createNewFromTemplateOrExisting = () => {
    if (!selectedQoo) {
      return notification.error({
        message: `Please select ${createFrom} Qoo`,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }

    const metadata = form.getFieldsValue(true);
    const newQoo = {
      ...selectedQoo,
      ...metadata
    };

    delete newQoo._id;
    delete newQoo.createdAt;
    delete newQoo.updatedAt;
    delete newQoo.__v;

    onSubmit(newQoo, true);
  };

  const handleNewQooSubmit = (values) => {
    if (!createFrom) {
      onSubmit(values, isNew);
    } else {
      createNewFromTemplateOrExisting();
    }
  };

  useEffect(() => {
    if (
      isNew &&
      activeTheme.data &&
      Object.keys(activeTheme.data).length !== 0 &&
      qooTypes?.data?.items
    ) {
      const data = {
        title: 'Qoo1',
        qooType: qooTypes.data.items.filter((item) => item.name === 'General')[0]._id
      };
      onSubmit(data, isNew);
    }
  }, [isNew, activeTheme, qooTypes]);

  const activateTheme = async (id) => {
    try {
      const res = await Axios.put(
        `${SERVER_URL}/activate-theme/${currentuser.data.id}/${id}?team=${currentuser.selectedTeam}&study=${currentuser.study}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      history.push('/admin');
      history.push('/admin/new-qoo');
    } catch (err) {
      notification.error({
        message: 'Problem with theme activation. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  return (
    <Layout className="edit-block">
      <HeaderComponent
        text={
          fromQoolection ? (
            `${fromQoolection.isCreateNew ? 'Create ' : 'Edit '}Qoolection / Edit Qoo`
          ) : isNew || isCreateNew ? (
            'Create a Qoo'
          ) : (
            <>
              <Link to="/admin/qoos">Manage Qoos</Link>
              <span>
                <span> / Edit</span>
                {blockInPreview ? <span> / {qoo?.data?.title}</span> : null}
              </span>
            </>
          )

          // `Manage Qoos / Edit${blockInPreview ? ` / ${qoo?.data?.title}` : ''}`
        }
        // text={
        //   fromQoolection ? `${fromQoolection.isCreateNew ? 'Create ' : 'Edit '}Qoolection / Edit Qoo` : isNew || isCreateNew ? 'Create a Qoo' : `Manage Qoos / Edit${blockInPreview ? ` / ${qoo?.data?.title}` : ''}`
        // }
        additionalText={
          blockInPreview ? 'Edit block' : isNew || isCreateNew ? null : qoo?.data?.title
        }
      />
      <Content>
        <ThemeModal
          themes={userThemes}
          visibleModal={visibleModal}
          message={modalMessage}
          activeThemes={activeThemes}
          currentuser={currentuser}
          activateTheme={activateTheme}
        />
        {!isDataFetchedWithBlockTypeAndAudiencesAndCategoriesAndThemes && (
          <div className="spinner">
            <LoadingOutlined />
          </div>
        )}
        {/*isDataFetchedWithBlockTypeAndAudiencesAndCategoriesAndThemes && isNew && (
          <Layout className='inner-layout'>
            <SideBarInner>
              <div className='pre-create-block'>
                <h3 className='pre-title pre-title--dark'>Add info</h3>
                <div className='choice-btn-group edit-qoo__create-from'>
                  <div
                    className={
                      !createFrom
                        ? 'choice-btn choice-btn--selected choice-btn--sm choice-btn--left'
                        : 'choice-btn choice-btn--sm choice-btn--left'
                    }
                    onClick={() => setCreateFrom(null)}
                  >
                    New Qoo
                  </div>
                  <div
                    className={
                      createFrom === 'existing'
                        ? 'choice-btn choice-btn--selected choice-btn--sm choice-btn--middle'
                        : 'choice-btn choice-btn--sm choice-btn--middle'
                    }
                    onClick={() => setCreateFrom('existing')}
                  >
                    My Qoos
                  </div>
                  <div
                    className={
                      createFrom === 'templates'
                        ? 'choice-btn choice-btn--selected choice-btn--sm choice-btn--right'
                        : 'choice-btn choice-btn--sm choice-btn--right'
                    }
                    onClick={() => setCreateFrom('templates')}
                  >
                    Templates
                  </div>
                </div>
                <Form
                  layout='vertical'
                  className='pre-create-block__form'
                  form={form}
                  onFinish={(values) => handleNewQooSubmit(values)}
                >
                  <Form.Item
                    label='Title'
                    rules={[{ required: true, message: 'Please enter title!' }]}
                    name='title'
                    labelAlign='left'
                  >
                    <Input placeholder='Title' className='input-medium' />
                  </Form.Item>
                  <Form.Item
                    label='Qoo type'
                    rules={[{ required: true, message: 'Please select Qoo type!' }]}
                    name='qooType'
                    labelAlign='left'
                  >
                    <Select placeholder='Qoo type' className='dropdown-medium' size='large'>
                      {qooTypes.data.items &&
                        qooTypes.data.items.length > 0 &&
                        qooTypes.data.items.map((qooType) => {
                          return (
                            <Select.Option value={qooType._id} key={qooType._id}>
                              {qooType.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label='Category'
                    //rules={[{ required: true, message: 'Please enter category!' }]}
                    name='category'
                    labelAlign='left'
                  >
                    <Select mode='multiple' placeholder='Category' className='dropdown-medium' size='large'>
                      {categories.data.items &&
                        categories.data.items.length > 0 &&
                        categories.data.items.map((category) => {
                          return (
                            <Select.Option value={category._id} key={category._id}>
                              {category.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label='Audience'
                    //rules={[{ required: true, message: 'Please enter audience!' }]}
                    name='audience'
                    labelAlign='left'
                  >
                    <Select mode='multiple' placeholder='Audience' className='dropdown-medium' size='large'>
                      {audiences.data.items &&
                        audiences.data.items.length > 0 &&
                        audiences.data.items.map((audience) => {
                          return (
                            <Select.Option value={audience._id} key={audience._id}>
                              {audience.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item label='Notes' name='notes' labelAlign='left'>
                    <Input.TextArea rows={4} placeholder='Notes' />
                  </Form.Item>
                  <Button type='primary' htmlType='submit' block>
                    Continue
                  </Button>
                </Form>
              </div>
            </SideBarInner>
            {createFrom && (
              <QoosList
                createFrom={createFrom}
                selectedQoo={selectedQoo}
                setSelectedQoo={setSelectedQoo}
                qooTypes={qooTypes.data.items}
                categories={categories.data.items}
              />
            )}
          </Layout>
            )*/}
        {isDataFetchedWithBlockTypeAndAudiencesAndCategoriesAndThemes &&
          !isNew &&
          isDataFetched && (
            <QooBuilder
              token={currentuser?.data?.token}
              SERVER_URL={SERVER_URL}
              qoo={qoo?.data}
              onSubmit={onSubmit}
              cancelFunction={cancelFunction}
              audiences={audiences?.data?.items}
              categories={categories?.data?.items}
              blockInPreview={blockInPreview}
              blockInPreviewHistory={blockInPreviewHistory}
              blockInPreviewFuture={blockInPreviewFuture}
              blockInPreviewDispatch={blockInPreviewDispatch}
              qooTypes={qooTypes?.data?.items}
              qoolection={fromQoolection?.qoolection?._id}
            />
          )}
      </Content>
    </Layout>
  );
};

export default EditQoo;
