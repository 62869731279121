import React, { useState } from 'react';
import { SERVER_URL } from '../../config';
import { notification, Input } from 'antd';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import Axios from 'axios';
import MyVideosGrid from '../../components/grids/MyVideosGrid';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';

const MyVideos = ({ videos, user, setStateForRefetchingVideoFiles }) => {
  const [searchFilter, setSearchFilter] = useState('');

  const tableData = videos.data.filter((video) => {
    const textMatch = searchFilter
      ? video?.originalname?.toLowerCase().includes(searchFilter.toLowerCase())
      : true;
    return textMatch;
  });

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/files/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.data.token}` }
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      setStateForRefetchingVideoFiles((prev) => prev + 1);
    } catch (err) {
      console.log('err: ', err);
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  return (
    <>
      <div className="table-header">
        <Input
          className="table-header__search"
          onChange={(event) => {
            setSearchFilter(event.target.value);
          }}
          placeholder="Search Videos"
          prefix={<SearchIcon />}
        />
      </div>
      <MyVideosGrid data={tableData} deleteHandler={deleteDataHandler} user={user} />
    </>
  );
};

export default MyVideos;
