import { Button, Modal, Checkbox } from 'antd';
import React, { useState } from 'react';
import QooDisplay from '../qoos/QooDisplay';

const QooDuplicatesFromQoolectionModal = ({ qoos, closeModal, duplicateMultipleQoos }) => {
  const [duplicates, setDuplicates] = useState(qoos);

  return (
    <Modal
      visible={qoos?.length > 0}
      onCancel={closeModal}
      footer={null}
      width={1240}
      centered={true}
      maskStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
      }}
      bodyStyle={{
        padding: '0'
      }}
    >
      <div className="preview-drawer">
        <div className="preview-drawer__content-container">
          <div className="preview-drawer__content">
            <h2 className="preview-drawer__title">These Qoos are already in your Qoolection. Do you want to duplicate them?</h2>
            <div className="preview-drawer__qoos-list">
              {qoos?.length > 0 && qoos.map((qoo, index) => {
                return (
                  <div
                    key={qoo._id}
                    className='qoolection__qoo-card'
                  >
                    <QooDisplay
                      qooInPreview={qoo}
                      width={96}
                      height={182}
                    />
                    <span className='preview-drawer__qoo-title'>{qoo.title}</span>
                    <Checkbox
                      checked={duplicates.some(duplicate => duplicate._id === qoo._id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setDuplicates(previous => [...previous, qoo]);
                        } else {
                          setDuplicates(previous => previous.filter(item => item._id !== qoo._id));
                        }
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <div className="preview-drawer__buttons-container">
              <Button
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                // className="preview-drawer__add-btn"
                type='primary'
                onClick={() => {
                  duplicateMultipleQoos(duplicates);
                  closeModal();
                }}
              >
                Duplicate
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QooDuplicatesFromQoolectionModal;