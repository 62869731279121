/**
 * Opens url in a new tab with optional timeout.
 * @param url (required)
 * @param timeout (optional, in milliseconds)
 * @returns {boolean}
 */
export const openUrlInNewTab = (url, timeout = 0) => {
  if (!url) {
    return false;
  }

  setTimeout(() => {
    const win = window.open(`https://${url}`, '_blank');
    win.focus();
    return true;
  }, timeout);
};

/**
 *
 * @param url {string}
 * @returns {string}
 */
export const removeUrlProtocolFromUrl = (url) => {
  return url.replace('https://', '').replace('http://', '');
};

// Validate if email is in correct format
export const validateEmail = (email) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  return (false)
}

// Validate id password is strength enough
export const validatePasswordStrength = (str) => {
    var re = /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9!@#$%^&*()~¥=_+}{":;'?/>.<,`\-\|\[\]]{12,50}$/;
    return re.test(str);
}