import { Button, Form, Input } from 'antd';
import FormActions from './components/FormActions';

export default function ExternalLinkForm({
  currentEvent,
  setCurrentEvent,
  blockEventHandler,
  eventIndex
}) {
  const initialValues = {
    externalLinkUrl: currentEvent?.externalLinkUrl
  };

  return (
    <Form onFinish={blockEventHandler} initialValues={initialValues}>
      <Form.Item
        className='block-event__form-item'
        name='externalLinkUrl'
        rules={[
          {
            required: true,
            message: 'Please enter a link!'
          }
        ]}>
        <Input
          className='block-event__element'
          placeholder='https://www.website.com'
          value={currentEvent?.externalLinkUrl}
          onChange={(e) =>
            setCurrentEvent({
              ...currentEvent,
              externalLinkUrl: e.target.value
            })
          }
        />
      </Form.Item>
      <FormActions eventIndex={eventIndex} setCurrentEvent={setCurrentEvent} />
    </Form>
  );
}
