import React from 'react';
import { ReactComponent as EditIcon } from '../../icons/ph_note-pencil-light.svg';
import { ReactComponent as CopyIcon } from '../../icons/ph_copy-light.svg';
import { ReactComponent as PasteIcon } from '../../icons/ph_clipboard-text-light.svg';
import { ReactComponent as DeleteIcon } from '../../icons/ph_trash-light.svg';
import { ReactComponent as ArrowLineUpIcon } from '../../icons/ph_arrow-fat-line-up-light.svg';
import { ReactComponent as ArrowLinesIcon } from '../../icons/ph_arrow-fat-lines-up-light.svg';
import { ReactComponent as BlockIcon } from '../../icons/Block-icon.svg';
import { ReactComponent as SelectAllIcon } from '../../icons/ph_selection-all-light.svg';
import { SELECT_BLOCK } from '../../reducers/block-in-preview/actionTypes';

const ContextMenu = React.forwardRef(({ contextMenuCoords, highlightedBlock, setDeleteModalVisible, setContextMenuCoords, blockInPreviewDispatch, moveLayerUp, moveLayerToFront, moveLayerDown, moveLayerToBottom }, ref) => {

  return (
    <div
      ref={ref}
      className="context-menu"
      style={{
        top: `${contextMenuCoords.y}px`,
        left: `${contextMenuCoords.x}px`,
      }}
    >
      {highlightedBlock?.block.isMulti &&
        <div className="context-menu__group">
          <div
            className="context-menu__item"
            onClick={() => {
              blockInPreviewDispatch({
                type: SELECT_BLOCK,
                payload: {
                  ...highlightedBlock.block,
                  column: highlightedBlock.x,
                  row: highlightedBlock.y
                }
              });
              setContextMenuCoords(null);
            }}
          >
            <div className="context-menu__action">
              <EditIcon />
              <span>Edit</span>
            </div>
            <div className="context-menu__keyboard">
              <span>Double click</span>
            </div>
          </div>
        </div>
      }
      <div className="context-menu__group">
        <div className="context-menu__item">
          <div className="context-menu__action">
            <CopyIcon />
            <span>Copy</span>
          </div>
          <div className="context-menu__keyboard">
            <span>Ctrl+C</span>
          </div>
        </div>
        <div className="context-menu__item">
          <div className="context-menu__action">
            <PasteIcon />
            <span>Paste</span>
          </div>
          <div className="context-menu__keyboard">
            <span>Ctrl+V</span>
          </div>
        </div>
        <div
          className="context-menu__item"
          onClick={(e) => {
            // e.stopPropagation();
            setContextMenuCoords(null);
            setDeleteModalVisible(true);
          }}
        >
          <div className="context-menu__action">
            <DeleteIcon />
            <span>Delete</span>
          </div>
          <div className="context-menu__keyboard">
            <span>DELETE</span>
          </div>
        </div>
      </div>
      <div className="context-menu__group">
        <div
          className="context-menu__item"
          onClick={() => {
            setContextMenuCoords(null);
            moveLayerUp(highlightedBlock.block._id);
          }}
        >
          <div className="context-menu__action">
            <ArrowLineUpIcon />
            <span>Bring forward</span>
          </div>
          <div className="context-menu__keyboard">
            <span>Ctrl+]</span>
          </div>
        </div>
        <div
          className="context-menu__item"
          onClick={() => {
            setContextMenuCoords(null);
            moveLayerToFront(highlightedBlock.block._id);
          }}
        >
          <div className="context-menu__action">
            <ArrowLinesIcon />
            <span>Bring to front</span>
          </div>
          <div className="context-menu__keyboard">
            <span>Ctrl+Alt+]</span>
          </div>
        </div>
        <div
          className="context-menu__item"
          onClick={() => {
            setContextMenuCoords(null);
            moveLayerDown(highlightedBlock.block._id);
          }}
        >
          <div className="context-menu__action context-menu__action--rotate">
            <ArrowLineUpIcon />
            <span>Bring backward</span>
          </div>
          <div className="context-menu__keyboard">
            <span>Ctrl+[</span>
          </div>
        </div>
        <div
          className="context-menu__item"
          onClick={() => {
            setContextMenuCoords(null);
            moveLayerToBottom(highlightedBlock.block._id);
          }}
        >
          <div className="context-menu__action context-menu__action--rotate">
            <ArrowLinesIcon />
            <span>Bring to bottom</span>
          </div>
          <div className="context-menu__keyboard">
            <span>Ctrl+Alt+[</span>
          </div>
        </div>
      </div>
      <div className="context-menu__group">
        <div className="context-menu__item">
          <div className="context-menu__action">
            <BlockIcon />
            <span>Save as block</span>
          </div>
        </div>
        <div className="context-menu__item">
          <div className="context-menu__action">
            <SelectAllIcon />
            <span>Select all</span>
          </div>
          <div className="context-menu__keyboard">
            <span>Ctrl+A</span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ContextMenu;