import { Col, Row, Switch } from 'antd';
import React from 'react';
import { ReactComponent as DotsIcon } from '../../icons/ph_dots-six-vertical.svg';
import { ReactComponent as CloseIcon } from '../../icons/close-small.svg';
import { ReactComponent as TextIcon } from '../../icons/ph_text-t.svg';
import { ReactComponent as EmailIcon } from '../../icons/ph_envelope-simple.svg';
import { ReactComponent as TextAreaIcon } from '../../icons/ph_selection-all.svg';
import { ReactComponent as CheckboxIcon } from '../../icons/fluent_checkbox-checked-24-regular.svg';


const FormField = ({ formField, editCallback, removeCallback }) => {
  return (
    <Row gutter={16} style={{ marginBottom: '15px' }}>
      <Col className='fieldCol' md={18}>
        <DotsIcon />
        {formField.fieldElement === 'text' && <TextIcon />}
        {formField.fieldElement === 'textarea' && <TextAreaIcon />}
        {formField.fieldElement === 'email' && <EmailIcon />}
        {formField.fieldElement === 'checkbox' && <CheckboxIcon />}

        <span style={{ marginLeft: '5px' }}>{formField.fieldName}</span>
      </Col>
      <Col className='fieldCol' md={6}>
        <Switch
          checked={formField.required}
          onChange={(value) => editCallback(value)}
        />
        <CloseIcon
          style={{ marginLeft: '10px', cursor: 'pointer' }}
          onClick={removeCallback}
        />
      </Col>
    </Row>
  );
};

export default FormField;