import { TableOutlined } from '@ant-design/icons';
import { ReactComponent as LetterCase } from '../icons/block-types-icons/letter-case.svg';
//import { ReactComponent as Media } from '../icons/media_black.svg';
import { ReactComponent as BtnIcon } from '../icons/block-types-icons/button.svg';
import { ReactComponent as InputIcon } from '../icons/input_black.svg';
import { ReactComponent as MediaIcon } from '../icons/block-types-icons/image-square-light.svg';
import { ReactComponent as Star } from '../icons/block-types-icons/ph_star-light.svg';
import { ReactComponent as DividerIcon } from '../icons/block-types-icons/ph_line-segment-light.svg';
import { ReactComponent as FormIcon } from '../icons/block-types-icons/ph_table-light.svg';
import { ReactComponent as VideoIcon } from '../icons/block-types-icons/ph_play-light.svg';
import { ReactComponent as AudioIcon } from '../icons/block-types-icons/ph_headphones-light.svg';
import { ReactComponent as SurveyIcon } from '../icons/block-types-icons/fluent_clipboard-task-20-regular.svg';
import { ReactComponent as ShapeIcon } from '../icons/block-types-icons/ph_x-square-light.svg';
import { ReactComponent as NoIcon } from '../icons/block-types-icons/no_icon.svg';
import { ReactComponent as EproIcon} from '../icons/block-types-icons/fluent_clipboard-task-20-regular.svg'
const blockTypesIcons = {
  noicon: {
    icon: <NoIcon />
  },
  text: {
    icon: <LetterCase />
  },
  button: {
    icon: <BtnIcon />
  },
  input: {
    icon: <InputIcon />
  },
  icon: {
    icon: <Star />
  },
  divider: {
    icon: <DividerIcon />
  },
  table: {
    icon: <TableOutlined />
  },
  form: {
    icon: <FormIcon />
  },
  shape: {
    icon: <ShapeIcon />
  },
  shapes: {
    icon: <ShapeIcon />
  },
  media: {
    icon: <MediaIcon />
  },
  video: {
    icon: <VideoIcon />
  },
  audio: {
    icon: <AudioIcon />
  },
  survey: {
    icon: <SurveyIcon />
  },
  ePro: {
    icon: <EproIcon/>
  }
};

export default blockTypesIcons;