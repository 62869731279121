import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Modal, Popconfirm } from 'antd';
import { ReactComponent as ExpandIcon } from '../../icons/expand-media.svg';
import { ReactComponent as DeleteIcon } from '../../icons/table-delete.svg';
import Masonry from 'react-masonry-css';
import NoData from '../elements/NoData';

const MyImagesGrid = ({ data, deleteHandler, user }) => {
  const [imageHovered, setImageHovered] = useState();
  const [imageForPreview, setImageForPreview] = useState();
  const [previewModalVisible, setPreviewModalVisible] = useState(false);

  const showPreviewModal = (imageId) => {
    setPreviewModalVisible(true);
    setImageForPreview(imageId);
  };

  if (data && data.length > 0) {
    data.map((image) => {
      const img = new Image();
      img.onload = function () {
        image.width = this.width;
        image.height = this.height;
      };
      img.src = image.originalUrl;
    });
  }

  return (
    <>
      {data?.length > 0 ? (
        <InfiniteScroll dataLength={data.length} scrollableTarget="scrollable-div">
          <Masonry
            breakpointCols={5}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
            id="scrollable-div"
            style={{ height: '80vh', overflowY: 'auto' }}>
            {data.map((image, index) => {
              return (
                <div style={{ position: 'relative' }} key={index}>
                  <img
                    onMouseOver={() => setImageHovered(image._id)}
                    key={image._id}
                    className="library-asset"
                    src={image.smUrl}
                    alt={image.originalname}
                  />
                  {imageHovered === image._id && (
                    <div className="image-overlay image-overlay__img">
                      <div className="image-overlay__top-row">
                        <span
                          onClick={() => showPreviewModal(image._id)}
                          className="image-overlay__icon">
                          <ExpandIcon />
                        </span>
                        <Popconfirm
                          disabled={image._id === user.data.id}
                          placement="left"
                          title="Delete Image?"
                          onConfirm={() => {
                            deleteHandler(image._id);
                          }}
                          okText="Ok"
                          cancelText="Cancel">
                          <span className="image-overlay__icon">
                            <DeleteIcon
                              title={`Delete ${image.originalname}`}
                              style={{
                                textDecoration: 'none',
                                color: '#90989E',
                                cursor: 'pointer'
                              }}
                            />
                          </span>
                        </Popconfirm>
                      </div>
                      <div className="image-overlay__bottom-row">
                        <span>
                          {image.originalname.length > 50
                            ? image.originalname.substring(0, 50) + '...'
                            : image.originalname}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </Masonry>
        </InfiniteScroll>
      ) : (
        <NoData />
      )}
      <Modal
        destroyOnClose={true}
        className="media-preview-modal video-modal-library"
        visible={previewModalVisible}
        onCancel={() => setPreviewModalVisible(false)}
        maskClosable>
        {data.map((image) => {
          if (image._id === imageForPreview) {
            return (
              <img
                className="media-preview-modal__asset"
                src={image.originalUrl}
                alt={image.imageTitle}
              />
            );
          }
        })}
      </Modal>
    </>
  );
};

export default MyImagesGrid;
