/* eslint-disable array-callback-return */
import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from 'antd';
import { ReactComponent as UploadIcon } from '../../icons/ph_upload-simple-light.svg';

const ExportToExcel = ({ csvData, fileName, propsToRemove, header, format }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const removeProperties = (object, ...keys) => {
    Object.keys(object).map((item) => {
      for (let i = 0; i < propsToRemove.length; i++) {
        if (item === propsToRemove[i]) {
          delete object[item];
        }
      }
    });
  };

  const dataForExcel = async (data) => {
    let returnData = [];

    data?.map((item) => {
      returnData.push(JSON.parse(JSON.stringify(item)));
    });

    if (propsToRemove && Array.isArray(propsToRemove) && propsToRemove.length > 0) {
      returnData.map((item) => removeProperties(item, ...propsToRemove));
    }

    if (format === 'custom_name') {
      returnData.map(async (item) => {
        Object.keys(item).map((prop) => {
          let newProp = prop.charAt(0).toUpperCase() + prop.slice(1);

          item[newProp] = item[prop];
          delete item[prop];
        });
      });
    }

    return returnData;
  };

  const exportToCSV = async (csvData, fileName) => {
    const filtered = await dataForExcel(csvData);
    const ws = XLSX.utils.json_to_sheet(filtered, { header: header });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      size="small"
      className="no-border-btn no-border-btn--homebase-export"
      title="Export data"
      onClick={(e) => exportToCSV(csvData, fileName)}>
      <UploadIcon /> <span className="no-border-btn__label">Export data</span>
    </Button>
  );
};

export default ExportToExcel;
