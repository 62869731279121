import React from 'react';
import FormElement from '../blocks/FormElement';
import MediaElement from '../blocks/MediaElement';
import ShapeElement from '../blocks/ShapeElement';
import SurveyElement from '../blocks/SurveyElement';
import _ from 'lodash';
import buttonActionTypes from '../../params/blocks/buttonActionTypes';
import { openUrlInNewTab, removeUrlProtocolFromUrl } from '../../utils/common';
import viewTypes from '../../params/viewTypes';

const QooBlocksLayout = ({ layout, gridWidth, gridRowHeight, viewType, onButtonClickCallback }) => {
  // Button click is enabled only if viewType is of type 'published'.
  const isButtonClickEnabled = viewType === viewTypes.published.key;

  const onButtonClick = (layoutItem, e) => {
    e.preventDefault();
    e.stopPropagation();

    const buttonLinkTo = _.get(layoutItem, 'block.blockProperties.buttonAction', null);
    const buttonAction = _.get(layoutItem, 'block.blockProperties.buttonLinkTo', null);

    if (!(buttonAction && buttonLinkTo)) {
      // No action and / or reference. Escape.
      return false;
    }

    if (buttonAction === buttonActionTypes.externalLink.key) {
      openUrlInNewTab(removeUrlProtocolFromUrl(buttonLinkTo), 10);
    }

    if (buttonAction === buttonActionTypes.qoo.key) {
      if (onButtonClickCallback) {
        onButtonClickCallback(buttonLinkTo);
      }
    }

    if (buttonAction === buttonActionTypes.email.key) {
      window.open(`mailto:${buttonLinkTo}`);
    }

    if (buttonAction === buttonActionTypes.phone.key) {
      window.open(`tel:${buttonLinkTo}`);
    }

    if (buttonAction === buttonActionTypes.location.key) {
      window.open(`${buttonLinkTo}`);
    }
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(12, ${gridRowHeight}px)`,
        gridTemplateRows: `repeat(22, ${gridRowHeight}px)`
      }}>
      {layout.map((item) => {
        const block = item.block;
        return (
          <div
            key={block?._id}
            style={{
              gridColumn: `${item.x + 1} / span ${item.w}`,
              gridRow: `${item.y + 1} / span ${item.h}`,
              fontSize: `${(gridWidth / 576) * 16}px`,
              paddingTop: (gridWidth / 576) * block?.blockProperties?.blockPaddingTop,
              paddingRight: (gridWidth / 576) * block?.blockProperties?.blockPaddingRight,
              paddingBottom: (gridWidth / 576) * block?.blockProperties?.blockPaddingBottom,
              paddingLeft: (gridWidth / 576) * block?.blockProperties?.blockPaddingLeft,
              display: block?.isMulti ? 'grid' : 'block',
              gridTemplateColumns: block?.isMulti
                ? `repeat(${block.size[1]}, ${gridRowHeight}px)`
                : '',
              gridTemplateRows: block?.isMulti
                ? `repeat(${block.size[0]}, ${gridRowHeight}px)`
                : '',
              backgroundColor: block?.blockProperties?.blockBackgroundImage
                ? ''
                : block?.blockProperties?.blockBackgroundColor
                ? block?.blockProperties?.blockBackgroundColor?.hex
                : block?.theme?.colorAssignment?.blockBackground
                ? block?.theme.colorAssignment?.blockBackground?.hex
                : 'transparent',
              backgroundImage: block?.blockProperties?.blockBackgroundImage
                ? `url(${block?.blockProperties?.blockBackgroundImage})`
                : '',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              border:
                block?.blockProperties?.blockBorderType &&
                block?.blockProperties?.blockBorderColor &&
                block?.blockProperties?.blockBorderThickness
                  ? `${block.blockProperties?.blockBorderThickness}em ${block.blockProperties?.blockBorderType} ${block.blockProperties?.blockBorderColor?.hex}`
                  : 'none',
              position: 'relative',
              zIndex: 1
            }}>
            {block?.isMulti && block?.blocksLayout ? (
              <>
                {block?.blocksLayout?.map((item) => {
                  return (
                    <div
                      key={item?.block?._id}
                      style={{
                        gridColumn: `${item.x + 1} / span ${item.w}`,
                        gridRow: `${item.y + 1} / span ${item.h}`,
                        paddingTop: (gridWidth / 576) * item.block?.blockProperties?.textPaddingTop,
                        paddingRight:
                          (gridWidth / 576) * item.block?.blockProperties?.textPaddingRight,
                        paddingBottom:
                          (gridWidth / 576) * item.block?.blockProperties?.textPaddingBottom,
                        paddingLeft:
                          (gridWidth / 576) * item.block?.blockProperties?.textPaddingLeft,

                        fontSize: `${(gridWidth / 576) * 16}px`,
                        border:
                          item.block?.blockProperties?.iconBorderType &&
                          item.block?.blockProperties?.iconBorderThickness &&
                          item.block?.blockProperties?.iconBorderColor
                            ? `${item.block?.blockProperties?.iconBorderThickness}em ${item.block?.blockProperties?.iconBorderType} ${item.block?.blockProperties?.iconBorderColor?.hex}`
                            : 'none',
                        backgroundColor: item.block?.blockProperties?.textBackgroundImage
                          ? ''
                          : item.block?.blockProperties?.iconBackgroundColor?.hex
                      }}>
                      {item?.block?.blockType?.name === 'text' && (
                        <div
                          className="text-content-container"
                          dangerouslySetInnerHTML={{
                            __html: item.block?.blockProperties?.textContent
                          }}
                          style={{
                            height: '100%',
                            fontSize: `${(gridWidth / 576) * 16}px`,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            lineHeight: '1.2'
                          }}></div>
                      )}
                      {item?.block?.blockType?.name === 'button' && (
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            lineHeight: '1.2'
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: `${item.block?.blockProperties?.buttonWidth || 75}%`,
                              height: `${item.block?.blockProperties?.buttonHeight || 75}%`,
                              backgroundColor:
                                item.block?.blockProperties?.buttonBackgroundColor?.hex,
                              color: item.block?.blockProperties?.buttonTextColor,
                              border:
                                item.block?.blockProperties?.buttonBorderThickness &&
                                item.block?.blockProperties?.buttonBorderType &&
                                item.block?.blockProperties?.buttonBorderColor
                                  ? `${item.block?.blockProperties?.buttonBorderThickness} ${item.block?.blockProperties?.buttonBorderType} ${item.block?.blockProperties?.buttonBorderColor?.hex}`
                                  : 'none',
                              borderRadius: item.block?.blockProperties?.buttonBorderRadius,
                              boxShadow: item.block?.blockProperties?.buttonHasShadow
                                ? `
                            ${item.block?.blockProperties?.buttonInnerShadow ? 'inset' : ''}
                            ${item.block?.blockProperties?.buttonShadowX} 
                            ${item.block?.blockProperties?.buttonShadowY}
                            ${item.block?.blockProperties?.buttonShadowBlur}
                            rgba(${item.block?.blockProperties?.buttonShadowColor?.rgb.r}, ${
                                    item.block?.blockProperties?.buttonShadowColor?.rgb.g
                                  }, ${item.block?.blockProperties?.buttonShadowColor?.rgb.b}, ${
                                    item.block?.blockProperties?.buttonShadowOpacity
                                  })
                          `
                                : ''
                            }}
                            onClick={isButtonClickEnabled ? onButtonClick.bind(this, item) : null}>
                            <div
                              className="text-content-container"
                              dangerouslySetInnerHTML={{
                                __html: item.block?.blockProperties?.buttonText
                              }}></div>
                          </div>
                        </div>
                      )}
                      {item?.block?.blockType?.name === 'divider' && (
                        <div className="divider-container">
                          <div
                            className="divider"
                            style={{
                              width: `${item.block?.blockProperties?.dividerLength}%`,
                              borderBottom:
                                item.block?.blockProperties?.dividerThickness &&
                                item.block?.blockProperties?.dividerType &&
                                item.block?.blockProperties?.dividerColor
                                  ? `${item.block?.blockProperties?.dividerThickness}em ${item.block?.blockProperties?.dividerType} ${item.block?.blockProperties?.dividerColor?.hex}`
                                  : 'none'
                              // alignSelf: item.block.blockProperties.dividerAlignPosition === 'center' ? 'center' : (item.block.blockProperties.dividerAlignPosition === 'top' ? 'flex-start' : 'flex-end')
                            }}></div>
                        </div>
                      )}
                      {item?.block?.blockType?.name === 'icon' && (
                        <div
                          style={{
                            height: '100%',
                            backgroundColor: item.block?.blockProperties?.iconColor
                              ? item.block?.blockProperties?.iconColor?.hex
                              : '#2A3A49',
                            mask: `url(${item.block?.blockProperties?.iconSrc})`,
                            maskRepeat: 'no-repeat',
                            maskPosition: 'center center',
                            maskSize: `${item.block?.blockProperties?.iconSize}%`,
                            WebkitMask: `url(${item.block?.blockProperties?.iconSrc})`,
                            WebkitMaskRepeat: 'no-repeat',
                            WebkitMaskPosition: 'center center',
                            WebkitMaskSize: `${item.block?.blockProperties?.iconSize}%`,
                            position: 'relative'
                            // zIndex: '-1'
                          }}></div>
                      )}
                      {item?.block?.blockType?.name === 'form' && (
                        <FormElement block={item.block} width={gridWidth} />
                      )}
                      {item?.block?.blockType?.name === 'survey' && (
                        <SurveyElement block={item.block} width={gridWidth} />
                      )}
                      {item?.block?.blockType?.name === 'media' && (
                        <MediaElement block={item.block} width={gridWidth} />
                      )}
                      {item?.block?.blockType?.name === 'shape' && (
                        <ShapeElement block={item.block} width={gridWidth} />
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <div
                style={{
                  height: '100%',
                  backgroundColor:
                    item?.block?.blockType?.name === 'icon'
                      ? item.block?.blockProperties?.iconBackgroundColor?.hex
                      : '',
                  border:
                    item?.block?.blockType?.name === 'icon' &&
                    item?.block?.blockProperties?.iconBorderThickness &&
                    item?.block?.blockProperties?.iconBorderType &&
                    item?.block?.blockProperties?.iconBorderColor
                      ? `${item.block?.blockProperties?.iconBorderThickness}em ${item.block?.blockProperties?.iconBorderType} ${item.block?.blockProperties?.iconBorderColor?.hex}`
                      : '',
                  paddingTop: (gridWidth / 576) * item?.block?.blockProperties?.textPaddingTop,
                  paddingRight: (gridWidth / 576) * item?.block?.blockProperties?.textPaddingRight,
                  paddingBottom:
                    (gridWidth / 576) * item?.block?.blockProperties?.textPaddingBottom,
                  paddingLeft: (gridWidth / 576) * item?.block?.blockProperties?.textPaddingLeft
                }}>
                {item?.block?.blockType?.name === 'text' && (
                  <div
                    className="text-content-container"
                    dangerouslySetInnerHTML={{
                      __html: item?.block?.blockProperties?.textContent
                    }}
                    style={{
                      height: '100%',
                      fontSize: `${(gridWidth / 576) * 16}px`,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      lineHeight: '1.2'
                    }}></div>
                )}
                {item?.block?.blockType?.name === 'button' && (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      lineHeight: '1.2'
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: `${item.block?.blockProperties?.buttonWidth || 75}%`,
                        height: `${item.block?.blockProperties?.buttonHeight || 75}%`,
                        backgroundColor: item.block?.blockProperties?.buttonBackgroundColor?.hex,
                        color: item.block?.blockProperties?.buttonTextColor,
                        border:
                          item.block?.blockProperties?.buttonBorderThickness &&
                          item.block?.blockProperties?.buttonBorderType &&
                          item.block?.blockProperties?.buttonBorderColor
                            ? `${item.block?.blockProperties?.buttonBorderThickness} ${item.block?.blockProperties?.buttonBorderType} ${item.block?.blockProperties?.buttonBorderColor?.hex}`
                            : 'none',
                        borderRadius: item.block?.blockProperties?.buttonBorderRadius,
                        boxShadow: item.block?.blockProperties?.buttonHasShadow
                          ? `
                            ${item.block.blockProperties.buttonInnerShadow ? 'inset' : ''}
                            ${item.block?.blockProperties?.buttonShadowX} 
                            ${item.block?.blockProperties?.buttonShadowY}
                            ${item.block?.blockProperties?.buttonShadowBlur}
                            rgba(${item.block?.blockProperties?.buttonShadowColor?.rgb.r}, ${
                              item.block?.blockProperties?.buttonShadowColor?.rgb.g
                            }, ${item.block?.blockProperties?.buttonShadowColor?.rgb.b}, ${
                              item.block?.blockProperties?.buttonShadowOpacity
                            })
                          `
                          : ''
                      }}
                      onClick={isButtonClickEnabled ? onButtonClick.bind(this, item) : null}>
                      <div
                        className="text-content-container"
                        dangerouslySetInnerHTML={{
                          __html: item.block?.blockProperties?.buttonText
                        }}></div>
                    </div>
                  </div>
                )}
                {item?.block?.blockType?.name === 'divider' && (
                  <div className="divider-container">
                    <div
                      className="divider"
                      style={{
                        width: `${item.block?.blockProperties?.dividerLength}%`,
                        borderBottom:
                          item.block?.blockProperties?.dividerThickness &&
                          item.block?.blockProperties?.dividerType &&
                          item.block?.blockProperties?.dividerColor
                            ? `${item.block?.blockProperties?.dividerThickness}em ${item.block?.blockProperties?.dividerType} ${item.block?.blockProperties?.dividerColor?.hex}`
                            : 'none'
                        // alignSelf: item.block.blockProperties.dividerAlignPosition === 'center' ? 'center' : (item.block.blockProperties.dividerAlignPosition === 'top' ? 'flex-start' : 'flex-end')
                      }}></div>
                  </div>
                )}
                {item?.block?.blockType?.name === 'icon' && (
                  <div
                    style={{
                      height: '100%',
                      backgroundColor: item.block?.blockProperties?.iconColor
                        ? item.block?.blockProperties?.iconColor?.hex
                        : '#2A3A49',
                      mask: `url(${item.block?.blockProperties?.iconSrc})`,
                      maskRepeat: 'no-repeat',
                      maskPosition: 'center center',
                      maskSize: `${item.block?.blockProperties?.iconSize}%`,
                      WebkitMask: `url(${item.block?.blockProperties?.iconSrc})`,
                      WebkitMaskRepeat: 'no-repeat',
                      WebkitMaskPosition: 'center center',
                      WebkitMaskSize: `${item.block?.blockProperties?.iconSize}%`,
                      position: 'relative'
                      // zIndex: '-1'
                    }}></div>
                )}
                {item?.block?.blockType?.name === 'form' && (
                  <FormElement block={item.block} width={gridWidth} />
                )}
                {item?.block?.blockType?.name === 'survey' && (
                  <SurveyElement block={item.block} width={gridWidth} />
                )}
                {item?.block?.blockType?.name === 'media' && (
                  <MediaElement block={item.block} width={gridWidth} />
                )}
                {item?.block?.blockType?.name === 'shape' && (
                  <ShapeElement block={item.block} width={gridWidth} />
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default QooBlocksLayout;
