import React, { useState } from 'react';
import QooDisplay from './QooDisplay';
import { ReactComponent as EditIcon } from '../../icons/ph_note-pencil-light.svg';
import { ReactComponent as RemoveIcon } from '../../icons/ph_trash-light.svg';
import { ReactComponent as LaunchIcon } from '../../icons/ph_rocket-launch-light.svg';
import { ReactComponent as CopyIcon } from '../../icons/ph_copy-light.svg';
import { ReactComponent as CheckIcon } from '../../icons/ph_check-circle-light.svg';
import { ReactComponent as ReturnIcon } from '../../icons/ph_arrow-bend-up-left-light.svg';
import { ReactComponent as PublishIcon } from '../../icons/ph_paper-plane-tilt-light.svg';
import { ReactComponent as RetireIcon } from '../../icons/ph_books-light.svg';
import { ReactComponent as UnpublishIcon } from '../../icons/ph_x-square-light.svg';
import { ReactComponent as ReviveIcon } from '../../icons/ph_arrow-counter-clockwise-light.svg';
import { ReactComponent as EyeIcon } from "../../icons/ph_eye-light.svg";
import { ReactComponent as ClockIcon } from "../../icons/ph_clock-light.svg";
import DeleteModal from '../../components/elements/DeleteModal';

const QooCard = ({ qoo, previewHandler, editDataHandler, duplicateQoo, deleteDataHandler, historyHandler, setQooForApproval }) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  return (
    <div
      className="qoolections-grid__card-container"
    >
      <div
        className="qoolections-grid__card"
        onMouseEnter={() => setOptionsVisible(true)}
        onMouseLeave={() => setOptionsVisible(false)}
      >
        <div
          className={optionsVisible ? "qoolections-grid__card-content qoolections-grid__card-content--border" : "qoolections-grid__card-content"}
        >
          <QooDisplay
            qooInPreview={qoo}
            width={180}
            height={342}
          />
          <div
            className={optionsVisible ? 'qoolections-grid__options-wrapper qoolections-grid__options-wrapper--visible' : 'qoolections-grid__options-wrapper'}
          >
            <div
              className="blocks-grid__options-item"
              onClick={previewHandler}
            >
              <EyeIcon />
              <span>Preview Qoo</span>
            </div>
            <div
              className="blocks-grid__options-item"
              onClick={() => historyHandler(qoo._id)}
            >
              <ClockIcon />
              <span>Version history</span>
            </div>
            {qoo.status === 'draft' &&
              <div
                className="blocks-grid__options-item"
                onClick={editDataHandler}
              >
                <EditIcon />
                <span>Edit Qoo</span>
              </div>
            }
            {qoo.status === 'draft' &&
              <div
                className="blocks-grid__options-item"
                onClick={() => setQooForApproval(qoo)}
              >
                <LaunchIcon />
                <span>Approval</span>
              </div>
            }

            {qoo.status === 'in-approval' &&
              <div
                className="blocks-grid__options-item"
              >
                <CheckIcon />
                <span>Approve</span>
              </div>
            }

            {qoo.status === 'approved' &&
              <div
                className="blocks-grid__options-item"
              >
                <PublishIcon />
                <span>Publish</span>
              </div>
            }

            {qoo.status === 'published' &&
              <div
                className="blocks-grid__options-item"
              >
                <UnpublishIcon />
                <span>Unpublish</span>
              </div>
            }

            {(qoo.status === 'approved' || qoo.status === 'published') &&
              <div
                className="blocks-grid__options-item"
              >
                <RetireIcon />
                <span>Retire</span>
              </div>
            }

            {qoo.status === 'in-approval' &&
              <div
                className="blocks-grid__options-item"
              >
                <ReturnIcon />
                <span>Return to draft</span>
              </div>
            }

            {qoo.status === 'retired' &&
              <div
                className="blocks-grid__options-item"
              >
                <ReviveIcon />
                <span>Revive</span>
              </div>
            }

            <div
              className="blocks-grid__options-item"
              onClick={() => duplicateQoo(qoo)}
            >
              <CopyIcon />
              <span>Duplicate</span>
            </div>

            {qoo.status === 'draft' &&
              <div
                className="blocks-grid__options-item"
                onClick={(e) => setDeleteModalVisible(true)}
              >
                <RemoveIcon />
                <span>Delete Qoo</span>
              </div>
            }

          </div>
        </div>
        <span className="qoolections-grid__title">{qoo.title}</span>
      </div>
      {deleteModalVisible &&
        <DeleteModal
          visible={deleteModalVisible}
          closeModal={() => setDeleteModalVisible(false)}
          title={'Qoo'}
          deleteCallback={() => {
            deleteDataHandler();
            setDeleteModalVisible(false);
          }}
        />
      }
    </div>
  );
};

export default QooCard;