import React, { useContext, useState } from 'react';
import { Button, Input, InputNumber, Select, notification } from 'antd';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import axios from 'axios';

const { TextArea } = Input;

const RemindersForm = ({
  setStateForRefetchingReminders,
  reminderForEdit,
  setReminderForEdit,
  editDataHandler,
  isNew
}) => {
  const currentuser = useContext(UserContext);
  const reminderInitialState = {
    client: currentuser.data.client?._id,
    study: currentuser.study,
    team: currentuser.selectedTeam,
    locked: false,
    reminder: '',
    description: '',
    ...reminderForEdit
  };
  const [data, setData] = useState(reminderInitialState);
  // console.log('data: ', data);

  async function addReminderHandler(data) {
    try {
      axios.post(
        `${SERVER_URL}/reminders`,
        { ...data },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      notification.success({
        message: `Reminder created`,
        placement: 'bottomRight'
      });
      setStateForRefetchingReminders((prev) => prev + 1);
      setData({
        client: currentuser.data.client?._id,
        study: currentuser.study,
        team: currentuser.selectedTeam,
        locked: false
      });
    } catch (error) {
      notification.error({
        message: 'Problem with creating Reminder',
        placement: 'bottomRight'
      });
    }
  }
  // console.log('data: ', data);

  return (
    <div style={{ padding: '10px 15px' }}>
      <Input
        className="block-event__element"
        placeholder="Reminder"
        value={data.reminder}
        onChange={(e) => setData({ ...data, reminder: e.target.value })}
      />
      <TextArea
        style={{
          height: 'auto'
        }}
        className="block-event__element"
        rows={3}
        maxLength={300}
        placeholder="Description"
        value={data.description}
        onChange={(e) => setData({ ...data, description: e.target.value })}
      />
      <Button
        style={{ width: '100%' }}
        type="primary"
        onClick={() =>
          isNew ? addReminderHandler(data) : editDataHandler(reminderForEdit._id, data)
        }>
        {isNew ? 'Save Reminder' : 'Edit Reminder'}
      </Button>
    </div>
  );
};

export default RemindersForm;
