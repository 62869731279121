import React from 'react';

const BlockPreviewBox = ({ index, arrLength, gridOptions, width, height }) => {

  const getGridPositionFromIndex = (index) => {
    let column = index + 1 - (Math.floor(index / 12) * 12);
    let row = Math.ceil((index + 1) / 12);

    return { column, row };
  };

  const { column, row } = getGridPositionFromIndex(index);

  return (
    <div
      className={gridOptions.style === 'dots' ? 'grid-box' : ''}
      style={{
        width: width ? width : '48px',
        height: height ? height : '48px',
        gridColumn: `${column} / span 1`,
        gridRow: `${row} / span 1`,
        borderTop: gridOptions.style === 'lines' ? `1px solid ${gridOptions.color.hex}` : '',
        borderLeft: gridOptions.style === 'lines' ? `1px solid ${gridOptions.color.hex}` : '',
        borderRight: gridOptions.style === 'lines' && (index + 1) % 12 === 0 ? `1px solid ${gridOptions.color.hex}` : '',
        borderBottom: gridOptions.style === 'lines' && arrLength - index <= 12 ? `1px solid ${gridOptions.color.hex}` : '',
      }}
    >
      {gridOptions.style === 'dots' &&
        <>
          <span className="grid-box__top-left-dot"></span>
          <span className="grid-box__top-right-dot"></span>
          {arrLength - index <= 12 &&
            <>
              <span className="grid-box__bottom-left-dot"></span>
              <span className="grid-box__bottom-right-dot"></span>
            </>
          }
        </>
      }

    </div>
  );
};

export default BlockPreviewBox;