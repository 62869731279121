import React from 'react';
import GridLayout from 'react-grid-layout';
import { SELECT_QOOLECTION_WITH_HISTORY } from '../../reducers/qoolection/actionTypes';
import QooDisplay from '../qoos/QooDisplay';

const ArrangeGrid = ({ qoolection, dispatch, updateQoolection }) => {

  const layout = qoolection.qoos.map((qoo, index) => {
    return {
      i: `${qoo._id}-${index}`,
      x: index,
      y: 0,
      w: 1,
      h: 1
    };
  });

  const handleLayoutChange = async (layout) => {
    const sortedLayout = [...layout].sort((a, b) => a.x - b.x);
    let newQoosLayout = [];
    sortedLayout.forEach(gridItem => {
      qoolection.qoos.forEach((item, index) => {
        if (`${item._id}-${index}` === gridItem.i) {
          newQoosLayout.push(item);
        }
      });
    });

    const newQoolection = await updateQoolection({ qoos: newQoosLayout });
    dispatch({
      type: SELECT_QOOLECTION_WITH_HISTORY,
      payload: newQoolection
    });
  };

  return (
    <div className="qoolection__arrange-qoos">
      <GridLayout
        className='layout'
        layout={layout}
        cols={qoolection.qoos.length}
        rowHeight={292}
        width={qoolection.qoos.length * 160}
        height={292}
        compactType='horizontal'
        margin={[0, 0]}
        isResizable={false}
        maxRows={1}
        onDragStop={(layout) => handleLayoutChange(layout)}
        isBounded={true}
      >
        {qoolection.qoos?.length > 0 && qoolection.qoos.map((qoo, index) => {
          return (
            <div
              key={`${qoo._id}-${index}`}
              className={qoolection.qoos.length - 1 === index ? "qoolection__qoo-card qoolection__qoo-card--in-grid qoolection__qoo-card--in-grid--last" : "qoolection__qoo-card qoolection__qoo-card--in-grid"}
            >
              <QooDisplay
                qooInPreview={qoo}
                width={140}
                height={265}
              />
              <span className='qoolection__qoo-card-title'>{qoo.title}</span>
              <span className='qoolection__qoo-card-number'>{index + 1}</span>
            </div>

          );
        })}
      </GridLayout>
    </div>
  );
};

export default ArrangeGrid;