import React from 'react';
import { Select } from 'antd';
import { UPDATE_HEADERFOOTER_PROPERTY } from '../../reducers/theme-in-preview/actionTypes';
import ColorPickerDropdown from '../../components/elements/ColorPickerDropdown';
import TransparencySlider from '../../components/elements/TransparencySlider';

const MenuTab = ({ themeInPreview, dispatch }) => {

  return (
    <>
      <p className="new-theme-builder__intro-text">
        Please style your Menu:
      </p>
      {!themeInPreview.headerFooter.header.noHeader && themeInPreview.headerFooter.header.components.includes('menu') &&
        <div className="new-theme-builder__hf-style-row">
          <div className="new-theme-builder__hf-style-label new-theme-builder__hf-style-label--fl-start">
            <span className="new-theme-builder__small-text">Menu Text Position</span>
          </div>
          <div className="new-theme-builder__hf-menu-presets">
            <div className="new-theme-builder__hf-toolbar">
              <div
                className={themeInPreview.headerFooter.header.menuOverlayTextAlign === 'flex-start' ? "new-theme-builder__hf-text-align-container new-theme-builder__hf-text-align-container--selected" : "new-theme-builder__hf-text-align-container"}
                onClick={() => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'header',
                      updates: {
                        menuOverlayTextAlign: 'flex-start'
                      }
                    }
                  });
                }}
              >
                <div
                  className="new-theme-builder__hf-text-align-item"
                  style={{ alignItems: 'flex-start' }}
                >
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div
                className={themeInPreview.headerFooter.header.menuOverlayTextAlign === 'center' ? "new-theme-builder__hf-text-align-container new-theme-builder__hf-text-align-container--selected" : "new-theme-builder__hf-text-align-container"}
                onClick={() => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'header',
                      updates: {
                        menuOverlayTextAlign: 'center'
                      }
                    }
                  });
                }}
              >
                <div
                  className="new-theme-builder__hf-text-align-item"
                  style={{ alignItems: 'center' }}
                >
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div
                className={themeInPreview.headerFooter.header.menuOverlayTextAlign === 'flex-end' ? "new-theme-builder__hf-text-align-container new-theme-builder__hf-text-align-container--selected" : "new-theme-builder__hf-text-align-container"}
                onClick={() => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'header',
                      updates: {
                        menuOverlayTextAlign: 'flex-end'
                      }
                    }
                  });
                }}
              >
                <div
                  className="new-theme-builder__hf-text-align-item"
                  style={{ alignItems: 'flex-end' }}
                >
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div className="new-theme-builder__hf-style-row">
        <div className="new-theme-builder__hf-style-label new-theme-builder__hf-style-label--fl-start">
          <span className="new-theme-builder__small-text">Menu Text Style</span>
        </div>
        <div className="new-theme-builder__hf-toolbar">
          <Select
            className='dropdown-small new-theme-builder__hf-menu-font-select'
            placeholder='Select Font'
            disabled={themeInPreview.headerFooter.header.noHeader || !themeInPreview.headerFooter.header.components.includes('menu')}
            value={themeInPreview.headerFooter.header.menuOverlayFont?._id}
            onChange={(value) => {
              const chosenFont = themeInPreview.selectedFonts.find(font => font._id === value);
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'header',
                  updates: {
                    menuOverlayFont: chosenFont
                  }
                }
              });
            }}
          >
            {themeInPreview.selectedFonts.length > 0 && themeInPreview.selectedFonts.map((font) => {
              return (
                <Select.Option
                  key={font._id}
                  value={font._id}
                >
                  <div
                    style={{
                      fontFamily: font.name,
                      fontWeight: font.weight,
                      fontStyle: font.style,
                    }}
                  >
                    {font.name}
                  </div>
                </Select.Option>
              );
            })}
          </Select>
          <Select
            className='dropdown-small new-theme-builder__hf-menu-font-size-select'
            placeholder='Size'
            disabled={themeInPreview.headerFooter.header.noHeader || !themeInPreview.headerFooter.header.components.includes('menu')}
            value={themeInPreview.headerFooter.header.menuOverlayFontSize}
            onChange={(value) => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'header',
                  updates: {
                    menuOverlayFontSize: value
                  }
                }
              });
            }}
          >
            <Select.Option value='S'>Small</Select.Option>
            <Select.Option value='M'>Medium</Select.Option>
            <Select.Option value='L'>Large</Select.Option>
          </Select>
          <ColorPickerDropdown
            colors={themeInPreview.colorPalette.colors}
            colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
            selectedColor={themeInPreview.headerFooter.header.menuOverlayTextColor}
            disabled={themeInPreview.headerFooter.header.noHeader || !themeInPreview.headerFooter.header.components.includes('menu')}
            leftRadius
            rightRadius
            callback={(color) => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'header',
                  updates: {
                    menuOverlayTextColor: color
                  }
                }
              });
            }}
          />
        </div>
      </div>
      <div className="new-theme-builder__hf-style-row">
        <div className="new-theme-builder__hf-style-label new-theme-builder__hf-style-label--fl-start">
          <span className="new-theme-builder__small-text">Highlighted Text Color</span>
        </div>
        <div className="new-theme-builder__hf-toolbar">
          <ColorPickerDropdown
            colors={themeInPreview.colorPalette.colors}
            colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
            selectedColor={themeInPreview.headerFooter.header.menuOverlayHighlightedTextColor}
            disabled={themeInPreview.headerFooter.header.noHeader || !themeInPreview.headerFooter.header.components.includes('menu')}
            leftRadius
            rightRadius
            callback={(color) => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'header',
                  updates: {
                    menuOverlayHighlightedTextColor: color
                  }
                }
              });
            }}
          />
        </div>
      </div>
      <div className="new-theme-builder__hf-style-row">
        <div className="new-theme-builder__hf-style-label new-theme-builder__hf-style-label--fl-start">
          <span className="new-theme-builder__small-text">Menu Background</span>
        </div>
        <div className="new-theme-builder__hf-toolbar">
          <ColorPickerDropdown
            colors={themeInPreview.colorPalette.colors}
            colorsBlackWhiteGrey={themeInPreview.colorPalette.colorsBlackWhiteGrey}
            selectedColor={themeInPreview.headerFooter.header.menuOverlayColor}
            disabled={themeInPreview.headerFooter.header.noHeader || !themeInPreview.headerFooter.header.components.includes('menu')}
            leftRadius
            rightRadius
            callback={(color) => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'header',
                  updates: {
                    menuOverlayColor: color
                  }
                }
              });
            }}
          />
          <TransparencySlider
            disabled={themeInPreview.headerFooter.header.noHeader || !themeInPreview.headerFooter.header.components.includes('menu')}
            inputValue={themeInPreview.headerFooter.header.menuOverlayColor ? themeInPreview.headerFooter.header.menuOverlayColor.rgb.a * 100 : 100}
            callback={(value) => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'header',
                  updates: {
                    menuOverlayColor: {
                      ...themeInPreview.headerFooter.header.menuOverlayColor,
                      rgb: {
                        ...themeInPreview.headerFooter.header.menuOverlayColor.rgb,
                        a: value / 100
                      }
                    }
                  }
                }
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MenuTab;