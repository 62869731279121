import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { UserContext } from '../../App';
import Axios from 'axios';
import { notification, Input, Modal } from 'antd';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { SERVER_URL } from '../../config';
import RemindersTable from '../../components/tables/RemindersTable';
import RemindersForm from '../../components/forms/RemindersForm';

const TABLE_COLUMN_KEYS = ['_id', '__v'];

const Reminders = ({ reminders, setStateForRefetchingReminders }) => {
  const currentuser = useContext(UserContext);
  const [searchFilter, setSearchFilter] = useState('');
  const [editReminderModal, setEditReminderModal] = useState(false);
  const [reminderForEdit, setReminderForEdit] = useState();
  const reminderInitialState = {
    client: currentuser.data.client?._id,
    study: currentuser.study,
    team: currentuser.selectedTeam,
    locked: false,
    reminder: '',
    description: '',
    ...reminderForEdit
  };
  const [data, setData] = useState(reminderInitialState);

  useEffect(() => {
    setData(reminders?.data?.items.filter((reminder) => reminder._id === reminderForEdit)[0]);
  }, [reminders, reminderForEdit]);

  let filteredReminders;

  if (reminders?.data?.items?.length > 0) {
    filteredReminders = reminders.data.items.filter((reminder) => {
      const textMatch = searchFilter
        ? reminder.reminder.toLowerCase().includes(searchFilter.toLowerCase())
        : true;
      return textMatch;
    });
  }

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/reminders/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      setStateForRefetchingReminders((prev) => prev + 1);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const editDataHandler = async (id, updates) => {
    try {
      await Axios.put(`${SERVER_URL}/reminders/${id}`, updates, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item updated.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      setStateForRefetchingReminders((prev) => prev + 1);
      setEditReminderModal(false);
    } catch (err) {
      notification.error({
        message: 'Problem with update. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  let columnKeys;
  if (reminders.data && reminders.data.items && reminders.data.items.length > 0) {
    const keys = Object.keys(reminders.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (reminders.data && reminders.data.items && reminders.data.items.length > 0) {
    tableData = reminders.data.items;
  }
  if (filteredReminders && filteredReminders.length > 0) {
    tableData = filteredReminders;
  }

  return (
    <>
      <div className="table-header">
        <Input
          className="table-header__search"
          placeholder="Search Reminders"
          prefix={<SearchIcon />}
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
      </div>
      <RemindersTable
        data={tableData}
        deleteHandler={deleteDataHandler}
        setEditReminderModal={setEditReminderModal}
        setReminderForEdit={setReminderForEdit}
        columnKeys={columnKeys}
        title="Reminder"
      />
      {reminderForEdit && (
        <Modal
          title="Edit Reminder"
          okText="Edit Reminder"
          open={editReminderModal}
          onCancel={() => setEditReminderModal(false)}
          onOk={() => editDataHandler(data._id, data)}>
          <RemindersForm
            editDataHandler={editDataHandler}
            reminderForEdit={reminderForEdit}
            setReminderForEdit={setReminderForEdit}
            setStateForRefetchingReminders={setStateForRefetchingReminders}
          />
        </Modal>
      )}
    </>
  );
};

export default Reminders;
