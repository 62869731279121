import React, { useState, useEffect } from 'react';
import { Button, Input, Modal, Form, Tabs, Select } from 'antd';

const { TabPane } = Tabs;

const ComplianceModal = ({ modalVisible, setModalVisible, onSubmit, complianceCategory }) => {
  // const [modalVisible, setModalVisible] = useState(false);
  const [openTab, setOpenTab] = useState('1');

  const [refSource, setRefSource] = useState('');
  const [refAuthor, setRefAuthor] = useState('');
  const [refSourceTitle, setRefSourceTitle] = useState('');
  const [refYear, setRefYear] = useState('');
  const [refCity, setRefCity] = useState('');
  const [refPublisher, setRefPublisher] = useState('');

  const [form] = Form.useForm();
  const initialValues = {
    title: undefined,
    text: undefined,
    abbreviation: undefined,
    definition: undefined,

  };

  useEffect(() => {
    const source = refSource ? ` ${refSource}` : '';
    const author = refAuthor ? ` ${refAuthor}` : '';
    const sourceTitle = refSourceTitle ? ` ${refSourceTitle}` : '';
    const year = refYear ? ` ${refYear}` : '';
    const city = refCity ? ` ${refCity}` : '';
    const publisher = refPublisher ? ` ${refPublisher}` : '';

    form.setFieldsValue({
      text: source + author + sourceTitle + year + city + publisher
    });
  }, [refSource, refAuthor, refSourceTitle, refYear, refCity, refPublisher]);

  return (
    <Modal
      visible={modalVisible}
      closable={false}
      footer={null}
      onCancel={() => setModalVisible(false)}
      width={560}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      centered
      afterClose={() => setOpenTab('1')}
      destroyOnClose={true}
    >
      <div className="compliance__modal">
        <h2 className="compliance__modal-title">Create new {complianceCategory}</h2>
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={(values) => onSubmit(values)}
          layout='vertical'
          preserve={false}
        >
          {complianceCategory === 'reference' &&
            <Tabs
              activeKey={openTab}
              onChange={(activeKey) => setOpenTab(activeKey)}
            >
              <TabPane
                tab='Basic'
                key='1'
              >
                <Form.Item
                  label='Title'
                  rules={[{ required: true, message: 'Please enter title!' }]}
                  name='title'
                  labelAlign='left'
                >
                  <Input placeholder='Title' />
                </Form.Item>
                <Form.Item
                  label='Reference Text'
                  rules={[{ required: true, message: 'Please enter text!' }]}
                  name='text'
                  labelAlign='left'
                >
                  <Input placeholder='Text' />
                </Form.Item>
              </TabPane>
              <TabPane
                tab='Advanced'
                key='2'
              >
                <Form.Item
                  label='Type of source'
                  // name='source'
                  labelAlign='left'
                >
                  <Select
                    placeholder='Choose source'
                    className='dropdown-medium'
                    size='large'
                    value={refSource}
                    onChange={(value) => setRefSource(value)}
                  >
                    <Select.Option value='book' label='Book'>Book</Select.Option>
                    <Select.Option value='medical-journal' label='Medical Journal'>Medical Journal</Select.Option>
                    <Select.Option value='other' label='Other'>Other</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label='Author'
                  // rules={[{ required: true, message: 'Please enter text!' }]}
                  // name='author'
                  labelAlign='left'
                >
                  <Input
                    placeholder='Author'
                    value={refAuthor}
                    onChange={(e) => setRefAuthor(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label='Title'
                  // rules={[{ required: true, message: 'Please enter text!' }]}
                  // name='sourceTitle'
                  labelAlign='left'
                >
                  <Input
                    placeholder='Title'
                    value={refSourceTitle}
                    onChange={(e) => setRefSourceTitle(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label='Year'
                  // rules={[{ required: true, message: 'Please enter text!' }]}
                  // name='year'
                  labelAlign='left'
                >
                  <Input
                    placeholder='Year'
                    value={refYear}
                    onChange={(e) => setRefYear(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label='City'
                  // rules={[{ required: true, message: 'Please enter text!' }]}
                  // name='city'
                  labelAlign='left'
                >
                  <Input
                    placeholder='City'
                    value={refCity}
                    onChange={(e) => setRefCity(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label='Publisher'
                  // rules={[{ required: true, message: 'Please enter text!' }]}
                  // name='publisher'
                  labelAlign='left'
                >
                  <Input
                    placeholder='Publisher'
                    value={refPublisher}
                    onChange={(e) => setRefPublisher(e.target.value)}
                  />
                </Form.Item>
              </TabPane>
            </Tabs>
          }
          {complianceCategory === 'footnote' &&
            <>
              <Form.Item
                label='Title'
                rules={[{ required: true, message: 'Please enter title!' }]}
                name='title'
                labelAlign='left'
              >
                <Input placeholder='Title' />
              </Form.Item>
              <Form.Item
                label='Footnote Text'
                // rules={[{ required: true, message: 'Please enter text!' }]}
                name='text'
                labelAlign='left'
              >
                <Input.TextArea rows={3} placeholder='Text' />
              </Form.Item>
            </>
          }
          {complianceCategory === 'abbreviation' &&
            <>
              <Form.Item
                label='Abbreviation (highlighted text)'
                rules={[{ required: true, message: 'Please enter abbreviation!' }]}
                name='abbreviation'
                labelAlign='left'
              >
                <Input placeholder='Abbreviation' />
              </Form.Item>
              <Form.Item
                label='Definition'
                // rules={[{ required: true, message: 'Please enter definition!' }]}
                name='definition'
                labelAlign='left'
              >
                <Input placeholder='Definition' />
              </Form.Item>
            </>
          }
          {openTab === '1' &&
            <Button
              type='primary'
              block
              htmlType='submit'
            >
              Add {complianceCategory}
            </Button>
          }

        </Form>
      </div>
    </Modal>
  );
};

export default ComplianceModal;