import React from 'react';
import { Tabs, Divider, Input, Button, Form, Row, Col } from 'antd';

const { TextArea } = Input;

const formInit = {
  _id: null,
  title: '',
  description: '',
};

const RoleForm = ({ isNew, data, onSubmit, language, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };

  // useEffect(() => {
  //   if (data && data.image) setImage(data.image.url);
  // }, [data]);

  const onFinish = async (values, isNew) => {
    onSubmit(values, isNew);
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form className='form-horizontal' initialValues={initialValues} onFinish={(values) => onFinish(values, !data)} layout='vertical' form={form}>
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Tabs tabPosition='left' defaultActiveKey={language.default.code}>
                  {language.list.map((lang) => (
                    <Tabs.TabPane tab={lang.code} key={lang.code} forceRender>
                      <Form.Item
                        label='Title'
                        rules={[{ required: true, message: 'Please enter title!' }]}
                        name='title'
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item label='Description' name='description'>
                        <TextArea rows={6} />
                      </Form.Item>
                    </Tabs.TabPane>
                  ))}
                </Tabs>

                <Divider type='horizontal' />
              </div>
            </Col>

          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Add' : 'update'} Role
            </Button>
          </div>
        </Form>

      </div>
    </div>
  );
};

export default RoleForm;
