import React from 'react';
import { Button } from 'antd';
import StepNavigation from './StepNavigation';
import ISITab from './ISITab';

const NewThemeStep8 = ({ step, setStep, themeInPreview, dispatch, completedStep, setSaveModalVisible }) => {

  return (
    <div className="new-theme-builder__step-8">
      <h2 className="new-theme-builder__step-subtitle">
        <span>Step 8: </span>
        <span>Let's customize your Info Spotlight. Please use the toggles below
          to customize the appearance of your Info Spotlight.</span>
      </h2>
      <StepNavigation
        step={step}
        setStep={setStep}
        completedStep={completedStep}
      />
      <ISITab
        themeInPreview={themeInPreview}
        dispatch={dispatch}
      />

      <div className="new-theme-builder__bottom-row">
        <Button
          className='new-theme-builder__step-btn'
          onClick={() => setStep(7)}
        >
          Previous step
        </Button>
        <Button
          className='new-theme-builder__step-btn'
          type='primary'
          onClick={() => {
            setSaveModalVisible(true);
          }}
        >
          Save Theme
        </Button>
      </div>
    </div>
  );
};

export default NewThemeStep8;