import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { notification, Layout, Input, Modal, List, Select } from 'antd';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import Table from '../../components/tables/QoosTable';
import QoosGrid from '../../components/grids/QoosGrid';
import QooDisplay from '../../pages/qoos/QooDisplay';
import CustomSwitch from '../../components/elements/CustomSwitch';
import HeaderComponent from '../../components/base/HeaderComponent';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import _ from 'lodash';
import QooPhonePreviewModal from './QooPhonePreviewModal';
import QooHistoryModal from './QooHistoryModal';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'blocks',
  'message',
  'createdAt',
  'updatedAt',
];
const statuses = [
  'draft',
  'waiting-approval',
  'in-approval',
  'approved',
  'change_required',
  'shared',
  'published',
  'retired',
];

const Qoos = () => {
  const [stateForRefetchingData, setStateForRefetchingData] = useState(1);
  const currentuser = useContext(UserContext);
  const [qoos, fetchQoos] = useAxios(
    '',
    [],
    currentuser.data.token,
    'get',
    stateForRefetchingData
  );
  const [themes, fetchThemes] = useAxios('', [], currentuser.data.token, 'get');
  const [categories, fetchCategories] = useAxios(
    '',
    [],
    currentuser.data.token,
    'get'
  );
  const [audiences, fetchAudiences] = useAxios(
    '',
    [],
    currentuser.data.token,
    'get'
  );
  const [qooTypes, fetchQooTypes] = useAxios(
    '',
    {},
    currentuser.data.token,
    'get'
  );
  const history = useHistory();
  const [qooHistory, setQooHistory] = useState([]);
  const [qooHistoryModalVisible, setQooHistoryModalVisible] = useState(false);
  const [listView, setListView] = useState(true);
  const [filters, setFilters] = useState(null);
  const [offset, setOffset] = useState(0);
  const [qooInModalPreview, setQooInModalPreview] = useState(null);

  useEffect(() => {
    fetchThemes(`${SERVER_URL}/themes`, []);
    fetchCategories(`${SERVER_URL}/categories`, {});
    fetchAudiences(`${SERVER_URL}/audiences`, {});
    fetchQooTypes(`${SERVER_URL}/qoo-types`, {});
  }, [fetchThemes, fetchCategories, fetchAudiences, fetchQooTypes]);

  const limit = 10;
  const isSuperAdmin = currentuser.data.role.some(
    (item) => item === 'superadmin'
  );
  useEffect(() => {
    let filter;
    if (filters) {
      const removedNullValues = Object.fromEntries(
        Object.entries(filters).filter(
          ([key, value]) =>
            value != null && (!Array.isArray(value) || value.length !== 0)
        )
      );
      filter = JSON.stringify(removedNullValues);
    }

    let route = filters
      ? `${SERVER_URL}/qoos?filter=${filter}&offset=${offset * limit
      }&limit=${limit}`
      : `${SERVER_URL}/qoos?offset=${offset * limit}&limit=${limit}`;
    if (!isSuperAdmin && currentuser.study) {
      route += `&team=${currentuser.selectedTeam}&study=${currentuser.study}`;
      fetchQoos(
        route,
        [],
        currentuser.data.token,
        'get',
        stateForRefetchingData
      );
    } else if (isSuperAdmin) {
      fetchQoos(
        route,
        [],
        currentuser.data.token,
        'get',
        stateForRefetchingData
      );
    }
  }, [fetchQoos, filters, offset, stateForRefetchingData, currentuser]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/qoos/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      // notification.success({
      //   message: 'Item is deleted.',
      //   placement: 'bottomRight',
      //   icon: notificationSuccess.icon,
      //   className: notificationSuccess.className,
      //   duration: notificationSuccess.duration,
      // });
      history.push('/admin');
      history.push('/admin/qoos');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration,
      });
    }
  };

  const exportQooToPfdHandler = async (id) => {
    try {
      const response = await Axios.get(
        `${SERVER_URL}/export-qoo-to-pdf/${id}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        }
      );
      notification.success({
        message: 'Item exported.',
        placement: 'bottomRight',
      });
      window.open(response.data.location, '_blank');
      return response.data.location;
    } catch (err) {
      notification.error({
        message: 'Problem with exporting. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const editDataHandler = (qooId) => {
    history.push('/admin/edit-qoo/' + qooId);
  };

  const historyHandler = async (id) => {
    try {
      const qooHistory = await Axios.get(`${SERVER_URL}/history-qoo/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setQooHistory(qooHistory.data);
      setQooHistoryModalVisible(true);
    } catch (err) {
      console.log(err);
    }
  };

  const historyRestore = async (id) => {
    try {
      const qoolectionHistory = await Axios.put(
        `${SERVER_URL}/history-restore-qoo/${id}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      setQooHistoryModalVisible(false);
      setStateForRefetchingData((previous) => previous + 1);
    } catch (err) {
      console.log(err);
    }
  };

  const duplicateQoo = async (qoo) => {
    const submitData = {
      ...qoo,
      title: `${qoo.title}(2)`,
    };
    const route = `${SERVER_URL}/qoos-create-new-from-existing`;

    try {
      const response = await Axios.post(route, submitData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Qoo created',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration,
      });

      // Redirects on duplicate action
      const qooId = response.data.item._id;
      history.push('/admin/edit-qoo/' + qooId);

      //setStateForRefetchingData(previous => previous + 1);
      //setOffset(0);
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
          ? error.message
          : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration,
      });
    }
  };

  let columnKeys;
  if (qoos.data && qoos.data.items && qoos.data.items.length > 0) {
    const keys = Object.keys(qoos.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (qoos.data && qoos.data.items && qoos.data.items.length > 0) {
    tableData = qoos.data.items.map((item) => item);
  }

  const handleChangeDebounced = _.debounce(async (e) => {
    setOffset(0);
    setFilters((previous) => ({ ...previous, title: e.target.value }));
  }, 500);

  const handleChange = (e) => {
    e.persist();
    handleChangeDebounced(e);
  };

  return (
    <Layout className='manage-layout'>
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <HeaderComponent text='Manage Qoos' />
        <div className='table-header'>
          <CustomSwitch listView={listView} setListView={setListView} />
          <Input
            className='table-header__search'
            placeholder='Search'
            prefix={<SearchIcon />}
            onChange={(e) => handleChange(e)}
            allowClear={true}
          />
          <div className='table-header__grid-filters'>
            <Select
              className='dropdown-small'
              placeholder='Filter by Theme'
              mode='multiple'
              value={filters?.theme || []}
              onChange={(value) =>
                setFilters((previous) => ({ ...previous, theme: value }))
              }
              maxTagCount={1}
              showArrow
            >
              {themes?.data?.items?.length > 0 &&
                themes.data.items.map((theme) => {
                  return (
                    <Select.Option key={theme._id} value={theme._id}>
                      {theme.name}
                    </Select.Option>
                  );
                })}
            </Select>
            <Select
              className='dropdown-small'
              placeholder='Filter by Type'
              mode='multiple'
              value={filters?.type || []}
              onChange={(value) =>
                setFilters((previous) => ({ ...previous, type: value }))
              }
              maxTagCount={1}
              showArrow
            >
              {qooTypes?.data?.items?.length > 0 &&
                qooTypes.data.items.map((qooType) => {
                  return (
                    <Select.Option key={qooType._id} value={qooType._id}>
                      {qooType.name}
                    </Select.Option>
                  );
                })}
            </Select>
            <Select
              className='dropdown-small'
              placeholder='Filter by Category'
              mode='multiple'
              value={filters?.category || []}
              onChange={(value) =>
                setFilters((previous) => ({ ...previous, category: value }))
              }
              maxTagCount={1}
              showArrow
            >
              {categories?.data?.items?.length > 0 &&
                categories.data.items.map((category) => {
                  return (
                    <Select.Option key={category._id} value={category._id}>
                      {category.name}
                    </Select.Option>
                  );
                })}
            </Select>
            <Select
              className='dropdown-small'
              placeholder='Filter by Audience'
              mode='multiple'
              value={filters?.audience || []}
              onChange={(value) =>
                setFilters((previous) => ({ ...previous, audience: value }))
              }
              maxTagCount={1}
              showArrow
            >
              {audiences?.data?.items?.length > 0 &&
                audiences.data.items.map((audience) => {
                  return (
                    <Select.Option key={audience._id} value={audience._id}>
                      {audience.name}
                    </Select.Option>
                  );
                })}
            </Select>
            <Select
              className='dropdown-small'
              placeholder='Filter by Status'
              mode='multiple'
              value={filters?.status || []}
              onChange={(value) =>
                setFilters((previous) => ({ ...previous, status: value }))
              }
              maxTagCount={1}
              showArrow
            >
              {statuses.map((status) => {
                return (
                  <Select.Option key={status} value={status}>
                    {status}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        </div>
        {qoos.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: '3rem', marginTop: '5rem' }}
          />
        )}
        {!qoos.isLoading &&
          qoos.data &&
          qoos.data.items &&
          qoos.data.items.length > 0 &&
          listView && (
            <Table
              user={currentuser}
              data={tableData}
              deleteDataHandler={deleteDataHandler}
              exportToPfd={exportQooToPfdHandler}
              editHandler={editDataHandler}
              historyHandler={historyHandler}
              columnKeys={columnKeys}
              title='Qoos'
              editPath='/admin/edit-qoo/'
              filters={filters}
              setFilters={setFilters}
              setOffset={setOffset}
              total={qoos.data.count}
              current={offset + 1}
              limit={limit}
              qooTypes={qooTypes}
              themes={themes}
              audiences={audiences}
              categories={categories}
              statuses={statuses}
              setQooInModalPreview={setQooInModalPreview}
              duplicateQoo={duplicateQoo}
            />
          )}
        {!qoos.isLoading &&
          qoos.data &&
          qoos.data.items &&
          qoos.data.items.length === 0 && <h2>NO DATA</h2>}
        {!qoos.isLoading &&
          qoos.data &&
          qoos.data.items &&
          qoos.data.items.length > 0 &&
          !listView && (
            <QoosGrid
              qoos={qoos?.data?.items}
              setOffset={setOffset}
              total={qoos.data.count}
              current={offset + 1}
              editDataHandler={editDataHandler}
              historyHandler={historyHandler}
              deleteDataHandler={deleteDataHandler}
              columnKeys={columnKeys}
              title='Qoos'
              // editPath='/admin/edit-qoo/'
              selectCallback={(item) =>
                history.push(`/admin/edit-qoo/${item._id}`)
              }
              setQooInModalPreview={setQooInModalPreview}
              duplicateQoo={duplicateQoo}
            />
          )}
      </div>

      {qooHistoryModalVisible && (
        <QooHistoryModal
          qooHistoryModalVisible={qooHistoryModalVisible}
          setQooHistoryModalVisible={setQooHistoryModalVisible}
          qooHistory={qooHistory}
          onHistoryRestore={historyRestore}
        />
      )}

      {qooInModalPreview && (
        <QooPhonePreviewModal
          visible={qooInModalPreview}
          closeModal={() => setQooInModalPreview(null)}
          qoo={qooInModalPreview}
        />
      )}
    </Layout>
  );
};

export default Qoos;
