import React, { useState } from 'react';
import { Button, Checkbox, Modal, Tabs } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { SELECT_BLOCK } from '../../reducers/block-in-preview/actionTypes';

const { TabPane } = Tabs;

const EditBlockModal = ({
  editBlockModalVisible,
  setEditBlockModalVisible,
  blockInPreview,
  blockInPreviewDispatch,
  qoos,
  qoolections
}) => {
  const [impactedListVisible, setImpactedListVisible] = useState(false);

  const filteredQoolectionItems = qoolections?.data?.items?.filter((item) => {
    return item.qoos.length > 0;
  });
  const filteredQooItems = qoos?.data?.items?.filter((item) => {
    return item.blocksLayout.length > 0;
  });

  let finalQoolectionItems = [];
  let finalQooItems = [];
  // get Qoolections that use this block
  if (filteredQoolectionItems && filteredQoolectionItems.length > 0) {
    for (let j = 0; j < filteredQoolectionItems.length; j++) {
      if (filteredQoolectionItems[j].qoos && filteredQoolectionItems[j].qoos.length > 0) {
        for (let i = 0; i < filteredQoolectionItems[j].qoos.length; i++) {
          filteredQoolectionItems[j].qoos[i].blocksLayout.map((block) => {
            if (block?.block?._id === blockInPreview._id) {
              // add qoolections that match the filter
              finalQoolectionItems.push(filteredQoolectionItems[j]);
            }
            return block;
          });
        }
      }
    }
  }
  // get Qoos that use this block
  if (filteredQooItems && filteredQooItems.length > 0) {
    for (let i = 0; i < filteredQooItems.length; i++) {
      filteredQooItems[i].blocksLayout.map((block) => {
        if (block?.block?._id === blockInPreview._id) {
          // add qoos that match the filter
          finalQooItems.push(filteredQooItems[i]);
        }
        return block;
      });
    }
  }

  return (
    <Modal
      visible={editBlockModalVisible}
      onCancel={() => {
        setEditBlockModalVisible(false);
        blockInPreviewDispatch({
          type: SELECT_BLOCK,
          payload: null
        });
      }}
      footer={null}
      width={494}>
      <div className="edit-block-modal">
        <h2 className="edit-block-modal__title">You are about to edit an existing Block</h2>
        <p className="edit-block-modal__text">
          Please be aware that selecting <strong>Edit Block</strong> will affect all
          Qoos/Qoolections that are currently using this Block. If any of those impacted
          Qoos/Qoolections are <em>In Approval</em> or <em>Approved</em>, they will be moved back to
          Draft and will require you to initiate Approval again.
        </p>

        <p className="edit-block-modal__text"> What would you like to do?​</p>

        <Checkbox
          className="edit-block-modal__checkbox edit-block-modal__show-impacted"
          checked={impactedListVisible}
          onChange={() => setImpactedListVisible((previous) => !previous)}>
          Show me all impacted Qoos/Qoolections
        </Checkbox>
        {impactedListVisible && (
          <Tabs>
            <TabPane tab="Qoos" key="1">
              <div className="edit-block-modal__list">
                {qoos?.isLoading === false ? (
                  qoos?.data?.items?.length > 0 &&
                  finalQooItems?.length > 0 &&
                  finalQooItems?.map((qoo) => {
                    return (
                      <div className="edit-block-modal__list-entry">
                        <span className="edit-block-modal__list-entry-title">
                          {`${qoo.title} (${qoo._id})`}
                        </span>
                        <span className="edit-block-modal__list-entry-status">{qoo.status}</span>
                      </div>
                    );
                  })
                ) : (
                  <LoadingOutlined spin style={{ fontSize: '3rem' }} />
                )}
              </div>
            </TabPane>
            <TabPane tab="Qoolections" key="2">
              <div className="edit-block-modal__list">
                {qoolections?.isLoading === false ? (
                  qoolections?.data?.items?.length > 0 &&
                  finalQoolectionItems?.length > 0 &&
                  finalQoolectionItems?.map((qoolection) => {
                    return (
                      <div className="edit-block-modal__list-entry">
                        <span className="edit-block-modal__list-entry-title">
                          {`${qoolection.title} (${qoolection._id})`}
                        </span>
                        <span className="edit-block-modal__list-entry-status">
                          {qoolection.status}
                        </span>
                      </div>
                    );
                  })
                ) : (
                  <LoadingOutlined spin style={{ fontSize: '3rem' }} />
                )}
              </div>
            </TabPane>
          </Tabs>
        )}

        <div className="edit-block-modal__bottom-actions">
          <div className="edit-block-modal__bottom-row">
            <Button
              block={true}
              onClick={() => {
                setEditBlockModalVisible(false);
                blockInPreviewDispatch({
                  type: SELECT_BLOCK,
                  payload: {
                    ...blockInPreview
                  }
                });
              }}>
              Edit Block
            </Button>
            <Button
              block={true}
              onClick={() => {
                setEditBlockModalVisible(false);
                blockInPreviewDispatch({
                  type: SELECT_BLOCK,
                  payload: {
                    ...blockInPreview,
                    name: '',
                    isNew: true
                  }
                });
              }}>
              Create new Block
            </Button>
          </div>
          <Button
            block={true}
            type="primary"
            onClick={() => {
              setEditBlockModalVisible(false);
              blockInPreviewDispatch({
                type: SELECT_BLOCK,
                payload: null
              });
            }}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditBlockModal;
