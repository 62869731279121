import React from 'react';
import { Button } from 'antd';
import StepNavigation from './StepNavigation';
import MenuTab from './MenuTab';

const NewThemeStep6 = ({ step, setStep, themeInPreview, dispatch, completedStep, setCompletedStep }) => {

  return (
    <div className="new-theme-builder__step-6">
      <h2 className="new-theme-builder__step-subtitle">
        <span>Step 6: </span>
        <span>Let's customize your Menu. Please use the toggles below
          to customize the appearance of your Menu.</span>
      </h2>
      <StepNavigation
        step={step}
        setStep={setStep}
        completedStep={completedStep}
      />
      <MenuTab
        themeInPreview={themeInPreview}
        dispatch={dispatch}
      />

      <div className="new-theme-builder__bottom-row">
        <Button
          className='new-theme-builder__step-btn'
          onClick={() => setStep(5)}
        >
          Previous step
        </Button>
        <Button
          className='new-theme-builder__step-btn'
          type='primary'
          onClick={() => {
            if (completedStep === 5) {
              setCompletedStep(6);
            }
            setStep(7);
          }}
        >
          Next step
        </Button>
      </div>
    </div>
  );
};

export default NewThemeStep6;