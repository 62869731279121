export const SET_QOO = 'SET_QOO';
export const EDIT_QOO_PROPERTY = 'EDIT_QOO_PROPERTY';
export const ADD_BLOCK = 'ADD_BLOCK';
export const REMOVE_BLOCK = 'REMOVE_BLOCK';
export const BLOCKS_LAYOUT_CHANGE = 'BLOCKS_LAYOUT_CHANGE';
export const REPLACE_BLOCK_WITH_CLONE = 'REPLACE_BLOCK_WITH_CLONE';
export const EDIT_CLONED_BLOCK = 'EDIT_CLONED_BLOCK';
export const EDIT_SINGLE_ELEMENT = 'EDIT_SINGLE_ELEMENT';
export const EDIT_SINGLE_ELEMENT_EVENTS = 'EDIT_SINGLE_ELEMENT_EVENTS';
export const REMOVE_SINGLE_ELEMENT_EVENT = 'REMOVE_SINGLE_ELEMENT_EVENT';
export const REPLACE_EDITED_BLOCK = 'REPLACE_EDITED_BLOCK';
export const TOGGLE_HIDE_SHOW_LAYER_Q = 'TOGGLE_HIDE_SHOW_LAYER_Q';
export const MOVE_LAYER_UP_Q = 'MOVE_LAYER_UP_Q';
export const MOVE_LAYER_DOWN_Q = 'MOVE_LAYER_DOWN_Q';
export const SET_BLOCKS_LAYOUT_Q = 'SET_BLOCKS_LAYOUT_Q';
export const MOVE_LAYER_UP_WITH_ID_Q = 'MOVE_LAYER_UP_WITH_ID_Q';
export const MOVE_LAYER_TO_FRONT_Q = 'MOVE_LAYER_TO_FRONT_Q';
export const MOVE_LAYER_DOWN_WITH_ID_Q = 'MOVE_LAYER_DOWN_WITH_ID_Q';
export const MOVE_LAYER_TO_BOTTOM_Q = 'MOVE_LAYER_TO_BOTTOM_Q';
export const ADD_FORM_FIELD_Q = 'ADD_FORM_FIELD_Q';
export const REMOVE_FORM_FIELD_Q = 'REMOVE_FORM_FIELD_Q';
export const EDIT_FORM_FIELD_Q = 'EDIT_FORM_FIELD_Q';
export const ADD_SURVEY_OPTION_Q = 'ADD_SURVEY_OPTION_Q';
export const REMOVE_SURVEY_OPTION_Q = 'REMOVE_SURVEY_OPTION_Q';
export const EDIT_SURVEY_OPTION_Q = 'EDIT_SURVEY_OPTION_Q';
export const UNDO = 'UNDO';
export const REDO = 'REDO';
export const ADD_ENDNOTES_Q = 'ADD_ENDNOTES_Q';
export const REMOVE_ENDNOTE_Q = 'REMOVE_ENDNOTE_Q';
export const EDIT_ENDNOTE_Q = 'EDIT_ENDNOTE_Q';
export const SELECT_QOO = 'SELECT_QOO';
export const ADD_BLOCKS = 'ADD_BLOCKS';
export const EDIT_BLOCK_WIDTH = 'EDIT_BLOCK_WIDTH';
export const REMOVE_QUESTION_FROM_QOO = 'REMOVE_QUESTION_FROM_QOO';
export const DELETE_NAVIGATION_BUTTON = 'DELETE_NAVIGATION_BUTTON';
export const ADD_NAVIGATION_BUTTON = 'ADD_NAVIGATION_BUTTON';
export const EDIT_NAVIGATION_BUTTON = 'EDIT_NAVIGATION_BUTTON';
export const EDIT_ANSWER_TEXT = 'EDIT_ANSWER_TEXT';