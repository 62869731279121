import { ReactComponent as CheckSuccessIcon } from '../icons/check-success-notification.svg';
import { ReactComponent as AttentionIcon } from '../icons/attention-notification.svg';
import { ReactComponent as ErrorIcon } from '../icons/error-notification.svg';
import { ReactComponent as InfoIcon } from '../icons/info-notification.svg';

export const notificationSuccess = {
  icon: <CheckSuccessIcon />,
  className: 'notification-success-option',
  // duration: 0,
};

export const notificationAttention = {
  icon: <AttentionIcon />,
  className: 'notification-attention-option',
  // duration: 0,
};

export const notificationError = {
  icon: <ErrorIcon />,
  className: 'notification-error-option',
  // duration: 0,
};

export const notificationInfo = {
  icon: <InfoIcon />,
  className: 'notification-info-option',
  // duration: 0,
};
