import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Tag } from 'antd';
import { ReactComponent as SortIcon } from '../../icons/arrow-down.svg';
import { ReactComponent as EyeIcon } from '../../icons/ph_eye-light.svg';
import { ReactComponent as EditIcon } from '../../icons/ph_note-pencil-light.svg';
import { ReactComponent as ClockIcon } from '../../icons/ph_clock-light.svg';
import { ReactComponent as PDFIcon } from '../../icons/pdf.svg';
import QooCardDropdown from '../../pages/qoos/QooCardDropdown';

import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import NoData from '../elements/NoData';

const QoosTable = ({
  user,
  data,
  deleteDataHandler,
  historyHandler,
  exportToPfd,
  editHandler,
  title,
  editPath,
  viewPath,
  filters,
  setFilters,
  setOffset,
  total,
  current,
  limit,
  qooTypes,
  themes,
  audiences,
  categories,
  statuses,
  setQooInModalPreview,
  duplicateQoo
}) => {
  const [sortDirection, setSortDirection] = useState(false);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'title'
      // ...getColumnSearchProps("title"),
    },
    {
      title: 'Theme',
      dataIndex: 'theme',
      // filters: themes.data?.items && themes.data.items.length > 0 && themes.data.items.map(theme => {
      //   return {
      //     text: theme.name,
      //     value: theme._id
      //   };
      // }),
      // filteredValue: filters?.theme,
      // onFilter: (value, record) => record.theme && record.theme._id === value,
      // // ...getColumnSearchProps("title"),
      render: (theme) => <span>{theme?.name && theme.name}</span>
    },
    {
      title: 'Type',
      dataIndex: 'qooType',
      // filters: qooTypes.data?.items && qooTypes.data.items.length > 0 && qooTypes.data.items.map(qooType => {
      //   return {
      //     text: qooType.name,
      //     value: qooType._id
      //   };
      // }),
      // filteredValue: filters?.type,
      // onFilter: (value, record) => record.qooType && record.qooType._id === value,
      render: (qooType) => <span>{qooType?.name && qooType.name}</span>
      // ...getColumnSearchProps("title"),
    },
    // {
    //   title: "Grid size",
    //   dataIndex: "gridSize",
    //   // ...getColumnSearchProps("gridSize"),
    // },
    // {
    //   title: "Device ratio",
    //   dataIndex: "deviceRatio",
    //   // ...getColumnSearchProps("deviceRatio"),
    // },
    // {
    //   title: "Message",
    //   dataIndex: "message",
    //   // ...getColumnSearchProps("message"),
    // },
    {
      title: 'Category',
      dataIndex: 'category',
      // filters: categories.data?.items && categories.data.items.length > 0 && categories.data.items.map(category => {
      //   return {
      //     text: category.name,
      //     value: category._id
      //   };
      // }),
      // filteredValue: filters?.category,
      // onFilter: (value, record) => record.category && record.category.some(c => c._id === value),
      render: (category) => (
        <span>
          {category &&
            category.map((c) => (
              <Tag key={c._id} className="table-tag--category">
                {c.name}
              </Tag>
            ))}
        </span>
      )
    },
    {
      title: 'Audience',
      dataIndex: 'audience',
      // filters: audiences.data?.items && audiences.data.items.length > 0 && audiences.data.items.map(audience => {
      //   return {
      //     text: audience.name,
      //     value: audience._id
      //   };
      // }),
      // filteredValue: filters?.audience,
      // onFilter: (value, record) => record.audience && record.audience.some(a => a._id === value),
      render: (audience) => (
        <span>
          {audience &&
            audience.map((a) => (
              <Tag key={a._id} className="table-tag--audience">
                {a.name}
              </Tag>
            ))}
        </span>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // filters: statuses && statuses.map((status) => {
      //   return {
      //     text: status.charAt(0).toUpperCase() + status.substring(1),
      //     value: status,
      //   };
      // }),
      // filteredValue: filters?.status,
      // onFilter: (value, record) => record.status && record.status.indexOf(value) === 0,
      render: (status) => (
        <span>
          {
            /* {status && status.map(s => <Tag key={s.name}>{s.name}</Tag>)} */
            status && (
              <Tag
                className={
                  status === 'draft'
                    ? 'table-tag--status-draft'
                    : status === 'waiting-approval'
                    ? 'table-tag--status-waiting-approval'
                    : status === 'in-approval'
                    ? 'table-tag--status-in-approval'
                    : status === 'approved'
                    ? 'table-tag--status-approved'
                    : status === 'change_required'
                    ? 'table-tag--status-change_required'
                    : status === 'shared'
                    ? 'table-tag--status-shared'
                    : status === 'published'
                    ? 'table-tag--status-published'
                    : status === 'retired'
                    ? 'table-tag--status-retired'
                    : status === 'template'
                    ? 'table-tag--status-template'
                    : 'table-tag'
                }>
                {status.charAt(0).toUpperCase() + status.substring(1)}
              </Tag>
            )
          }
        </span>
      )
    },
    {
      title: 'Version',
      dataIndex: 'version',
      render: (version) => (
        <span>
          <div>
            {version?.major || 0}.{version?.minor || 0}.{version?.patch || 0}
          </div>
        </span>
      )
    }
    // {
    //   title: (
    //     <div onClick={() => setSortDirection(!sortDirection)}>Date Created {sortDirection ?
    //       <SortIcon style={{ marginLeft: "8px", transform: "rotate(180deg)", transition: ".2s ease-in-out transform" }} /> :
    //       <SortIcon style={{ marginLeft: "8px", transition: ".2s ease-in-out transform" }} />
    //     }</div>
    //   ),
    //   dataIndex: 'createdAt',
    //   sorter: {
    //     compare: (a, b) => String(a.createdAt).localeCompare(String(b.createdAt))
    //   },
    //   sortDirections: ['descend', 'ascend', 'descend'],
    //   defaultSortOrder: 'descend',
    //   // ...getColumnSearchProps("title"),
    //   render: createdAt => (
    //     <span>
    //       {
    //         createdAt && createdAt.split('T')[0].split('-')[1] + '/' + createdAt.split('T')[0].split('-')[2] + '/' + createdAt.split('T')[0].split('-')[0]
    //       }
    //     </span>
    //   )
    // },
    // {
    //   title: "Date Modified",
    //   dataIndex: 'updatedAt',
    //   // ...getColumnSearchProps("title"),
    //   render: updatedAt => (
    //     <span>
    //       {
    //         updatedAt && updatedAt.split('T')[0].split('-')[1] + '/' + updatedAt.split('T')[0].split('-')[2] + '/' + updatedAt.split('T')[0].split('-')[0]
    //       }
    //     </span>
    //   )
    // },
  ];

  columns.push({
    title: 'Action',
    render: (text, record) => (
      <div
        style={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px',
          color: '#90989E'
        }}>
        <div
          style={{
            margin: '2px',
            padding: '4px',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#90989E'
          }}>
          <PDFIcon placement="left" onClick={() => exportToPfd(record._id)} title="Export PDF" />
        </div>
        {(record.status === 'draft' || record.status === 'template') && (
          <div onClick={() => user.handleRefreshToken()} style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link
              to={editPath + record._id}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <EditIcon title="Edit Qoo" style={{ textDecoration: 'none', color: '#90989E' }} />
            </Link>
          </div>
        )}

        {/* <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <Link to={editPath + record._id}>
            <EditOutlined title={`Edit ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: 'black' }} />
          </Link>
         {connectedToVeeva && record.veevaDocumentId ?
            (<LockOutlined title='Uploaded to veeva - locked' style={{ textDecoration: 'none', color: 'red' }} />)
            :
            (<Link to={editPath + record._id}>
              <EditOutlined title={`Edit ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: 'black' }} />
            </Link>)
          }
        </div> */}

        {/* {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={viewPath + record._id}>
              <EyeOutlined title={`View ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          </div>
        )} */}

        <div
          style={{
            margin: '2px',
            padding: '4px',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={() => setQooInModalPreview(record)}>
          <EyeIcon title="Preview Qoo" />
        </div>

        {/* <div style={{ margin: '2px', padding: '4px' }}>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={`This will delete ${title.toLowerCase()}`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText='Ok'
            cancelText='Cancel'
          >
            <DeleteOutlined title={`Delete ${title.toLowerCase()}`} />
          </Popconfirm>
        </div> */}

        <div
          style={{
            margin: '2px',
            padding: '4px',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <ClockIcon
            title="View Qoo History"
            style={{ textDecoration: 'none' }}
            onClick={() => historyHandler(record._id)}
          />
        </div>

        <div style={{ margin: '2px', padding: '4px', position: 'relative' }}>
          <QooCardDropdown
            qoo={record}
            inTable={true}
            // setQoolectionForApproval={setQoolectionForApproval}
            duplicateQoo={duplicateQoo}
            deleteDataHandler={() => deleteDataHandler(record._id)}
          />
        </div>
      </div>
    )
  });

  const locale = {
    emptyText: <NoData />
  };

  return (
    <div className="table-manage">
      <Table
        size="middle"
        bordered
        dataSource={data}
        columns={columns}
        rowKey="_id"
        pagination={{
          defaultPageSize: limit,
          position: ['bottomCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          // hideOnSinglePage: true,
          total,
          current
        }}
        onChange={(pagination, filters, sorter) => {
          setOffset(pagination.current - 1);
          setFilters((previous) => ({ ...previous, ...filters }));
        }}
        locale={locale}
      />
    </div>
  );
};

export default QoosTable;
