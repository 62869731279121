import React from 'react';
import QooBlocksLayout from '../qoos/QooBlocksLayout';

const BlockDisplay = ({
  block,
  width,
  height,
  noBorder,
  isDraggable
}) => {

  return (
    <div
      className={noBorder ? 'block-display block-display--no-border' : 'block-display'}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <div
        id={isDraggable ? `draggable-${block._id}` : null}
        style={{
          fontSize: `${(width / 576) * 16}px`,
          gridColumn: `1 / span ${block.size[1]}`,
          gridRow: `1 / span ${block.size[0]}`,
          backgroundColor: block.blockProperties.blockBackgroundImage ? (
            ''
          ) : (
            block.blockProperties.blockBackgroundColor ? (
              block.blockProperties.blockBackgroundColor.hex
            ) : (block.theme.colorAssignment.blockBackground ? block.theme.colorAssignment.blockBackground.hex : null)
          ),
          // backgroundImage: block.blockProperties.blockBackgroundImage
          //   ? `url(${block.blockProperties.blockBackgroundImage})`
          //   : block.blockProperties.blockBackgroundColor && !block.hidden ? '' :
          //     'linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%)',
          // backgroundRepeat: (block.blockProperties.blockBackgroundColor || block.blockProperties.blockBackgroundImage) && !block.hidden ? 'no-repeat' : 'unset',
          // backgroundPosition: (block.blockProperties.blockBackgroundColor || block.blockProperties.blockBackgroundImage) && !block.hidden ? 'center' : '0px 0px, 10px 10px',
          // backgroundSize: (block.blockProperties.blockBackgroundColor || block.blockProperties.blockBackgroundImage) && !block.hidden ? 'cover' : '20px 20px',
          border: (block.blockProperties.blockBorderType && block.blockProperties.blockBorderThickness && block.blockProperties.blockBorderColor) ? `${block.blockProperties.blockBorderThickness}em ${block.blockProperties.blockBorderType} ${block.blockProperties.blockBorderColor.hex}` : 'none',
          boxShadow: block.blockProperties.blockBackgroundColor || block.blockProperties.blockBorderColor ? 'none' : '0px 0px 12px 0px rgba(0,0,0,0.2)'
        }}
      >
        {block.blocksLayout && block.blocksLayout.length > 0 &&
          <QooBlocksLayout
            layout={block.blocksLayout}
            gridWidth={width}
            gridRowHeight={width / 12}
          />
        }
      </div>
    </div>
  );
};

export default BlockDisplay;