export const initHeaderFooter = {
  isEditing: false,
  activeKey: '1',
  header: {
    components: ['logo'],
    backgroundColor: '',
    borderColor: '',
    dropshadow: false,
    logo: null,
    topHeaderColor: '',
    topHeaderBorderColor: null,
    topHeaderText: '',
    topHeaderFont: null,
    topHeaderTextColor: null,
    topHeaderBoxShadow: false,
    topHeaderColumnsNum: null,
    topHeaderTextAlign: '',
    topHeaderColumn1TextContent: '',
    topHeaderColumn2TextContent: '',
    topHeaderColumn1LinkTo: '',
    topHeaderColumn2LinkTo: '',
    topHeaderColumn1Link: '',
    topHeaderColumn2Link: '',
    textFontSize: '',
    textColor: null,
    textContent: '',
    textTextAlign: '',
    menuIcon: 8,
    menuIconColor: null,
    noHeader: false,
    layout: {
      id: '1',
      slot1: [{ type: 'logo' }],
      justifyContent: 'flex-start',
      keywords: 'logo'
    },
    menuOverlayTextAlign: 'flex-start',
    menuOverlayColor: null,
    menuOverlayBorderColor: null,
    menuOverlayFont: null,
    menuOverlayFontSize: null,
    menuOverlayTextColor: null,
    menuOverlayLayout: null,
    previewMenu: false
  },
  footer: {
    components: [],
    noFooter: false,
    noISI: false,
    shownISI: 'more-text',
    size: '',
    ISITitleBackgroundColor: null,
    ISITitleBorderColor: null,
    ISITitleFont: null,
    ISITitleFontSize: null,
    ISITitleTextColor: null,
    ISITitleTextAlign: 'left',
    ISITitleTextContent: '',
    ISIFooterBackgroundColor: null,
    ISIFooterBorderColor: null,
    ISIFooterFont: null,
    ISIFooterFontSize: '',
    ISIFooterTextColor: null,
    ISIFooterTextAlign: '',
    ISIFooterTextContent: '',
    ISIFooterDropshadow: false,
    ISIIcon: 0,
    previewISIFooter: false,
    bottomFooterBackgroundColor: null,
    bottomFooterBorderColor: null,
    bottomFooterDropshadow: false,
    bottomFooterFont: null,
    bottomFooterFontSize: null,
    bottomFooterTextColor: null,
    bottomFooterTextAlign: '',
    bottomFooterColumnsNum: 2,
    bottomFooterColumn1TextContent: '',
    bottomFooterColumn2TextContent: '',
    bottomFooterColumn3TextContent: '',
    bottomFooterColumn1LinkTo: null,
    bottomFooterColumn2LinkTo: null,
    bottomFooterColumn3LinkTo: null,
    bottomFooterColumn1Link: '',
    bottomFooterColumn2Link: '',
    bottomFooterColumn3Link: '',

  }
};

export const headerPresetsList = [
  {
    id: '1',
    slot1: [{ type: 'logo' }],
    justifyContent: 'flex-start',
    keywords: 'logo'
  },
  {
    id: '2',
    slot3: [{ type: 'logo' }],
    justifyContent: 'flex-end',
    keywords: 'logo'
  },
  {
    id: '3',
    slot2: [{ type: 'logo' }],
    justifyContent: 'center',
    keywords: 'logo'
  },
  {
    id: '4',
    slot1: [{ type: 'logo' }],
    slot3: [{ type: 'text' }],
    justifyContent: 'space-between',
    keywords: 'logo text'
  },
  {
    id: '5',
    slot1: [{ type: 'text' }],
    slot3: [{ type: 'logo' }],
    justifyContent: 'space-between',
    keywords: 'logo text'
  },
  {
    id: '6',
    slot1: [{ type: 'logo' }],
    slot3: [{ type: 'menu' }],
    justifyContent: 'space-between',
    keywords: 'logo menu'
  },
  {
    id: '7',
    slot1: [{ type: 'menu' }],
    slot3: [{ type: 'logo' }],
    justifyContent: 'space-between',
    keywords: 'logo menu'
  },
  {
    id: '8',
    slot1: [{ type: 'text' }],
    slot2: [{ type: 'logo' }],
    slot3: [{ type: 'menu' }],
    justifyContent: 'space-between',
    keywords: 'logo text menu'
  },
  {
    id: '9',
    slot1: [{ type: 'menu' }],
    slot2: [{ type: 'logo' }],
    slot3: [{ type: 'text' }],
    justifyContent: 'space-between',
    keywords: 'logo text menu'
  },
  {
    id: '10',
    slot1: [{ type: 'logo' }],
    topHeader: true,
    justifyContent: 'flex-start',
    keywords: 'logo top-header'
  },
  {
    id: '11',
    slot3: [{ type: 'logo' }],
    topHeader: true,
    justifyContent: 'flex-end',
    keywords: 'logo top-header'
  },
  {
    id: '12',
    slot2: [{ type: 'logo' }],
    topHeader: true,
    justifyContent: 'center',
    keywords: 'logo top-header'
  },
  {
    id: '13',
    slot1: [{ type: 'logo' }],
    slot3: [{ type: 'text' }],
    topHeader: true,
    justifyContent: 'space-between',
    keywords: 'logo text top-header'
  },
  {
    id: '14',
    slot1: [{ type: 'text' }],
    slot3: [{ type: 'logo' }],
    topHeader: true,
    justifyContent: 'space-between',
    keywords: 'logo text top-header'
  },
  {
    id: '15',
    slot1: [{ type: 'logo' }],
    slot3: [{ type: 'menu' }],
    topHeader: true,
    justifyContent: 'space-between',
    keywords: 'logo menu top-header'
  },
  {
    id: '16',
    slot1: [{ type: 'menu' }],
    slot3: [{ type: 'logo' }],
    topHeader: true,
    justifyContent: 'space-between',
    keywords: 'logo menu top-header'
  },
  {
    id: '17',
    slot1: [{ type: 'menu' }],
    slot2: [{ type: 'logo' }],
    slot3: [{ type: 'text' }],
    topHeader: true,
    justifyContent: 'space-between',
    keywords: 'logo text menu top-header'
  },
  {
    id: '18',
    slot1: [{ type: 'text' }],
    slot2: [{ type: 'logo' }],
    slot3: [{ type: 'menu' }],
    topHeader: true,
    justifyContent: 'space-between',
    keywords: 'logo text menu top-header'
  },
  {
    id: '19',
    slot1: [{ type: 'menu' }],
    justifyContent: 'flex-start',
    keywords: 'menu'
  },
  {
    id: '20',
    slot3: [{ type: 'menu' }],
    justifyContent: 'flex-end',
    keywords: 'menu'
  },
  {
    id: '21',
    slot2: [{ type: 'menu' }],
    justifyContent: 'center',
    keywords: 'menu'
  },
  {
    id: '22',
    slot1: [{ type: 'text' }],
    justifyContent: 'flex-start',
    keywords: 'text'
  },
  {
    id: '23',
    slot3: [{ type: 'text' }],
    justifyContent: 'flex-end',
    keywords: 'text'
  },
  {
    id: '24',
    slot2: [{ type: 'text' }],
    justifyContent: 'center',
    keywords: 'text'
  },
  {
    id: '25',
    topHeader: true,
    justifyContent: 'space-between',
    keywords: 'top-header'
  },
  {
    id: '26',
    slot1: [],
    slot2: [{ type: 'logo' }],
    slot3: [{ type: 'menu' }],
    justifyContent: 'space-between',
    keywords: 'logo menu'
  },
  {
    id: '27',
    slot1: [{ type: 'menu' }],
    slot2: [{ type: 'logo' }],
    slot3: [],
    justifyContent: 'space-between',
    keywords: 'logo menu'
  },
  {
    id: '28',
    slot1: [],
    slot2: [{ type: 'logo' }],
    slot3: [{ type: 'text' }],
    justifyContent: 'space-between',
    keywords: 'logo text'
  },
  {
    id: '29',
    slot1: [{ type: 'text' }],
    slot2: [{ type: 'logo' }],
    slot3: [],
    justifyContent: 'space-between',
    keywords: 'logo text'
  },
  {
    id: '30',
    slot1: [{ type: 'text' }],
    slot3: [{ type: 'menu' }],
    justifyContent: 'space-between',
    keywords: 'text menu'
  },
  {
    id: '31',
    slot1: [{ type: 'menu' }],
    slot3: [{ type: 'text' }],
    justifyContent: 'space-between',
    keywords: 'text menu'
  },
  {
    id: '32',
    slot1: [],
    slot2: [{ type: 'text' }],
    slot3: [{ type: 'menu' }],
    justifyContent: 'space-between',
    keywords: 'text menu'
  },
  {
    id: '33',
    slot1: [{ type: 'menu' }],
    slot2: [{ type: 'text' }],
    slot3: [],
    justifyContent: 'space-between',
    keywords: 'text menu'
  },
  {
    id: '34',
    slot1: [{ type: 'menu' }],
    topHeader: true,
    justifyContent: 'flex-start',
    keywords: 'menu top-header'
  },
  {
    id: '35',
    slot3: [{ type: 'menu' }],
    topHeader: true,
    justifyContent: 'flex-end',
    keywords: 'menu top-header'
  },
  {
    id: '36',
    slot2: [{ type: 'menu' }],
    topHeader: true,
    justifyContent: 'center',
    keywords: 'menu top-header'
  },
  {
    id: '37',
    slot1: [{ type: 'text' }],
    topHeader: true,
    justifyContent: 'flex-start',
    keywords: 'text top-header'
  },
  {
    id: '38',
    slot3: [{ type: 'text' }],
    topHeader: true,
    justifyContent: 'flex-end',
    keywords: 'text top-header'
  },
  {
    id: '39',
    slot2: [{ type: 'text' }],
    topHeader: true,
    justifyContent: 'center',
    keywords: 'text top-header'
  },
  {
    id: '40',
    slot1: [{ type: 'text' }],
    slot3: [{ type: 'menu' }],
    topHeader: true,
    justifyContent: 'space-between',
    keywords: 'text menu top-header'
  },
  {
    id: '41',
    slot1: [{ type: 'menu' }],
    slot3: [{ type: 'text' }],
    topHeader: true,
    justifyContent: 'space-between',
    keywords: 'text menu top-header'
  },
  {
    id: '42',
    slot1: [],
    slot2: [{ type: 'text' }],
    slot3: [{ type: 'menu' }],
    topHeader: true,
    justifyContent: 'space-between',
    keywords: 'text menu top-header'
  },
  {
    id: '43',
    slot1: [{ type: 'menu' }],
    slot2: [{ type: 'text' }],
    slot3: [],
    topHeader: true,
    justifyContent: 'space-between',
    keywords: 'text menu top-header'
  },
  {
    id: '44',
    slot1: [],
    slot2: [{ type: 'logo' }],
    slot3: [{ type: 'menu' }],
    topHeader: true,
    justifyContent: 'space-between',
    keywords: 'logo menu top-header'
  },
  {
    id: '45',
    slot1: [{ type: 'menu' }],
    slot2: [{ type: 'logo' }],
    slot3: [],
    topHeader: true,
    justifyContent: 'space-between',
    keywords: 'logo menu top-header'
  },
  {
    id: '46',
    slot1: [],
    slot2: [{ type: 'logo' }],
    slot3: [{ type: 'text' }],
    topHeader: true,
    justifyContent: 'space-between',
    keywords: 'logo text top-header'
  },
  {
    id: '47',
    slot1: [{ type: 'text' }],
    slot2: [{ type: 'logo' }],
    slot3: [],
    topHeader: true,
    justifyContent: 'space-between',
    keywords: 'logo text top-header'
  },
];

export const menuPresets = [
  {
    id: '1',
    x: 1,
    y: 1,
    w: 6,
    h: -1,
    align: 'center',
    linesAlign: 'center'
  },
  {
    id: '2',
    x: 1,
    y: 1,
    w: 4,
    h: -1,
    align: 'flex-start',
    linesAlign: 'flex-start',
  },
  {
    id: '3',
    x: 3,
    y: 1,
    w: 4,
    h: -1,
    align: 'flex-end',
    linesAlign: 'flex-end'
  },
  {
    id: '4',
    x: 1,
    y: 2,
    w: 6,
    h: 6,
    align: 'flex-start',
    linesAlign: 'center'
  },
  {
    id: '5',
    x: 1,
    y: 1,
    w: 6,
    h: 6,
    align: 'flex-start',
    linesAlign: 'center'
  },
];
