import React, { useContext, useState, useEffect } from 'react';
import { ReactComponent as DashIcon } from '../../icons/dash.svg';
import { Button, Input, InputNumber, Select, notification } from 'antd';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import axios from 'axios';

const { TextArea } = Input;

const QuestionsForm = ({
  setStateForRefetchingQuestions,
  questioneers,
  questionForEdit,
  setQuestionForEdit,
  isNew
}) => {
  const currentuser = useContext(UserContext);
  const questionInitialState = {
    client: currentuser.data.client?._id,
    study: currentuser.study,
    team: currentuser.selectedTeam,
    locked: false,
    answers: [],
    ...questionForEdit
  };
  const [data, setData] = useState(questionInitialState);

  useEffect(() => {
    const newAnswers = Array.from({ length: data.numberOfAnswers }, (_, i) => ({
      description: data.answers[i]?.description || '',
      value: data.answers[i]?.value || '',
      position: data.answers[i]?.position || i + 1
    }));

    setData({
      ...data,
      answers: newAnswers
    });
  }, [data.numberOfAnswers]);

  async function addQuestionHandler(data) {
    try {
      axios.post(
        `${SERVER_URL}/questions`,
        { ...data },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      notification.success({
        message: `Question created`,
        placement: 'bottomRight'
      });
      setStateForRefetchingQuestions((prev) => prev + 1);
      setData({ client: currentuser.data.client?._id });
    } catch (error) {
      notification.error({
        message: 'Problem with creating Question',
        placement: 'bottomRight'
      });
    }
  }

  async function updateQuestionHandler(data) {
    console.log('Update data', data);
    try {
      axios.put(
        `${SERVER_URL}/questions/${questionForEdit._id}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      notification.success({
        message: `Question updated`,
        placement: 'bottomRight'
      });
      setStateForRefetchingQuestions((prev) => prev + 1);
      setQuestionForEdit(null);
      setData({ client: currentuser?.data?.client?._id });
    } catch (error) {
      console.log('Error', error);
      notification.error({
        message: 'Problem with updating Question',
        placement: 'bottomRight'
      });
    }
  }

  const handleAnswerChange = (property, value, index) => {
    const newAnswers = [...data.answers];
    newAnswers[index][property] = value;
    setData({
      ...data,
      answers: newAnswers
    });
  };

  const handleRemoveAnswer = (index) => {
    const newAnswers = [...data.answers];
    newAnswers.splice(index, 1);
    setData({
      ...data,
      answers: newAnswers,
      numberOfAnswers: data.numberOfAnswers - 1
    });
  };

  // console.log('questioneers: ', questioneers);

  return (
    <div style={{ padding: '10px 15px' }}>
      <Input
        className="block-event__element"
        placeholder="Question"
        value={data.question}
        onChange={(e) => setData({ ...data, question: e.target.value })}
      />
      <TextArea
        style={{
          height: 'auto'
        }}
        className="block-event__element"
        rows={3}
        maxLength={300}
        placeholder="Description"
        value={data.description}
        onChange={(e) => setData({ ...data, description: e.target.value })}
      />
      <Input
        className="block-event__element"
        placeholder="Index"
        value={data.questionIndex}
        onChange={(e) => setData({ ...data, questionIndex: e.target.value })}
      />
      <Select
        className="block-event__element"
        placeholder="ePRO"
        value={questioneers?.find((questioneer) => questioneer._id === data?.questioneer)?.name}
        onChange={(value) => {
          setData({
            ...data,
            questioneer: value
          });
        }}>
        {questioneers?.map((questioneer) => (
          <Select.Option key={questioneer._id} value={questioneer._id}>
            {questioneer.name}
          </Select.Option>
        ))}
      </Select>
      <InputNumber
        className="block-event__element num-input"
        min={1}
        max={100}
        type="number"
        placeholder="Number of answers"
        value={data.numberOfAnswers}
        onChange={(value) =>
          setData({
            ...data,
            numberOfAnswers: value
          })
        }
      />
      {data?.answers?.map((answer, i) => (
        <div style={{ marginBottom: '1rem' }} key={i}>
          <div className="form-answer-header">
            <span>Answer {i + 1}</span>
            <span className="form-answer-header__remove" onClick={() => handleRemoveAnswer(i)}>
              <DashIcon /> Remove
            </span>
          </div>
          <Input
            className="block-event__element"
            placeholder={`Answer text`}
            value={answer.description}
            onChange={(e) => handleAnswerChange('description', e.target.value, i)}
          />
          <Input
            className="block-event__element"
            placeholder={`Answer value`}
            value={answer.value}
            onChange={(e) => handleAnswerChange('value', e.target.value, i)}
          />
          <InputNumber
            className="block-event__element num-input"
            placeholder={`Answer position...`}
            min={1}
            max={100}
            value={answer.position}
            onChange={(value) => handleAnswerChange('position', value, i)}
          />
        </div>
      ))}
      <InputNumber
        className="block-event__element num-input"
        min={1}
        max={100}
        type="number"
        placeholder="Minimum select"
        value={data.minSelect}
        onChange={(value) =>
          setData({
            ...data,
            minSelect: value
          })
        }
      />
      <InputNumber
        className="block-event__element num-input"
        min={1}
        max={100}
        type="number"
        placeholder="Maximum select"
        value={data.maxSelect}
        onChange={(value) =>
          setData({
            ...data,
            maxSelect: value
          })
        }
      />
      <Button
        style={{ width: '100%' }}
        type="primary"
        onClick={() => (isNew ? addQuestionHandler(data) : updateQuestionHandler(data))}>
        {isNew ? 'Save Question' : 'Edit Question'}
      </Button>
    </div>
  );
};

export default QuestionsForm;
