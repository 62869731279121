const viewTypes = {
  edit: {
    key: 'edit',
    title: 'Edit View',
  },
  published: {
    key: 'published',
    title: 'Published View',
  },
};

export default Object.freeze(viewTypes);