export const SET_THEME = 'SET_THEME';
export const EDIT_THEME_PROPERTY = 'EDIT_THEME_PROPERTY';
export const EDIT_COLOR_PALETTE_ARRAY = 'EDIT_COLOR_PALETTE_ARRAY';
export const REMOVE_COLOR_FROM_PALETTE = 'REMOVE_COLOR_FROM_PALETTE';
export const SET_COLOR__PALETTE = 'SET_COLOR__PALETTE';
export const ASSIGN_COLOR = 'ASSIGN_COLOR';
export const SET_SELECTED_FONTS = 'SET_SELECTED_FONTS';
export const ADD_FONT = 'ADD_FONT';
export const REMOVE_FONT = 'REMOVE_FONT';
export const ADD_FONT_GROUP = 'ADD_FONT_GROUP';
export const REMOVE_FONT_GROUP = 'REMOVE_FONT_GROUP';
export const SET_LOGO = 'SET_LOGO';
export const SET_THEMEHEADERFOOTER = 'SET_THEMEHEADERFOOTER';
export const UPDATE_HEADERFOOTER_PROPERTY = 'UPDATE_HEADERFOOTER_PROPERTY';
export const UPDATE_NAVIGATION_PROPERTY = 'UPDATE_NAVIGATION_PROPERTY';


export const ADD_COLOR_TO_PALETTE = 'ADD_COLOR_TO_PALETTE';
export const TOGGLE_NO_HEADER = 'TOGGLE_NO_HEADER';
export const SET_HEADER_LAYOUT = 'SET_HEADER_LAYOUT';
export const EDIT_MENU_PROPERTY = 'EDIT_MENU_PROPERTY';
export const EDIT_FOOTER_PROPERTY = 'EDIT_FOOTER_PROPERTY';
export const SET_BUTTON = 'SET_BUTTON';
export const EDIT_NAVIGATION_PROPERTY = 'EDIT_NAVIGATION_PROPERTY';
export const EDIT_LOGO_PROPERTY = 'EDIT_LOGO_PROPERTY';
