import React from 'react';
import { Switch, Radio, Space } from 'antd';
import ColorsTab from './ColorsTab';
import LogoTab from './LogoTab';
import TypographyTab from './TypographyTab';
import { UPDATE_HEADERFOOTER_PROPERTY } from '../../reducers/theme-in-preview/actionTypes';
import HeaderTab from './HeaderTab';
import MenuTab from './MenuTab';
import FooterTab from './FooterTab';
import ISITab from './ISITab';

const ThemeEditor = ({ themeInPreview, dispatch, selectedCategory }) => {
  return (
    <div className="theme-editor">
      {selectedCategory === 'brand-kit' &&
        <>
          <div className="theme-editor__section">
            {/* <h3 className='pre-title pre-title--dark'>Logos</h3> */}
            <LogoTab
              themeInPreview={themeInPreview}
              dispatch={dispatch}
            />
          </div>
          <div className="theme-editor__section">
            <ColorsTab
              themeInPreview={themeInPreview}
              dispatch={dispatch}
            />
          </div>
          <div className="theme-editor__section">
            <TypographyTab
              themeInPreview={themeInPreview}
              dispatch={dispatch}
            />
          </div>
        </>
      }
      {selectedCategory === 'header' &&
        <>
          <div className="new-theme-builder__hf-section">
            <div className="new-theme-builder__hf-section-row">
              <p className="new-theme-builder__intro-text">
                Would you like to have a Header?
              </p>
              <Switch
                checked={!themeInPreview.headerFooter.header.noHeader}
                onChange={() => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'header',
                      updates: {
                        noHeader: !themeInPreview.headerFooter.header.noHeader
                      }
                    }
                  });
                }}
              />
            </div>
          </div>
          <HeaderTab
            themeInPreview={themeInPreview}
            dispatch={dispatch}
          />
        </>
      }
      {selectedCategory === 'menu' &&
        <MenuTab
          themeInPreview={themeInPreview}
          dispatch={dispatch}
        />
      }
      {selectedCategory === 'footer' &&
        <>
          <div className="new-theme-builder__hf-section">
            <div className="new-theme-builder__hf-section-row">
              <p className="new-theme-builder__intro-text">
                Would you like to have a Footer on your Qoolections?
              </p>
              <Switch
                checked={!themeInPreview.headerFooter.footer.noFooter}
                disabled={themeInPreview.headerFooter.footer.shownISI === 'title-only'}
                onChange={() => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'footer',
                      updates: {
                        noFooter: !themeInPreview.headerFooter.footer.noFooter
                      }
                    }
                  });
                }}
              />
            </div>
          </div>
          <FooterTab
            themeInPreview={themeInPreview}
            dispatch={dispatch}
          />
        </>
      }
      {selectedCategory === 'ISI' &&
        <>
          <div className="new-theme-builder__hf-section">
            <div className="new-theme-builder__hf-section-row">
              <p className="new-theme-builder__intro-text">
                Would you like to have an Info Spotlight?
              </p>
              <Switch
                checked={!themeInPreview.headerFooter.footer.noISI}
                onChange={() => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'footer',
                      updates: {
                        noISI: !themeInPreview.headerFooter.footer.noISI
                      }
                    }
                  });
                }}
              />
            </div>
          </div>
          <div className="new-theme-builder__hf-section">
            <p className="new-theme-builder__intro-text">
              How much text do you need to show in the Info Spotlight preview?
            </p>
            <div className='radio-isi-correction'>
              <Radio.Group
                disabled={themeInPreview.headerFooter.footer.noISI}
                value={themeInPreview.headerFooter.footer.shownISI}
                onChange={(e) => {
                  dispatch({
                    type: UPDATE_HEADERFOOTER_PROPERTY,
                    payload: {
                      section: 'footer',
                      updates: {
                        shownISI: e.target.value
                      }
                    }
                  });
                }}
              >
                <Space direction="vertical">
                  <Radio value='title-only' disabled={themeInPreview.headerFooter.footer.noFooter}><span className='new-theme-builder__small-text'>Title only</span></Radio>
                  <Radio value='less-text'><span className='new-theme-builder__small-text'>Less Info Spotlight Text</span></Radio>
                  <Radio value='more-text'><span className='new-theme-builder__small-text'>More Info Spotlight Text</span></Radio>
                </Space>
              </Radio.Group>
            </div>
          </div>
          <ISITab
            themeInPreview={themeInPreview}
            dispatch={dispatch}
          />
        </>
      }
    </div>
  );
};

export default ThemeEditor;