import React, { useRef, useLayoutEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TextElement = ({
  element,
  selectedTextEditor,
  setSelectedTextEditor,
  theme,
  defaultFont,
  handleChange,
  tinyMCEContentStyleString,
  tinyMCEFontFormatsString,
  handleOverflow,
  rowsNumber,
  rowHeight
}) => {
  const textDivRef = useRef();

  useLayoutEffect(() => {
    document.fonts.ready.then(() => {
      if (textDivRef?.current?.clientHeight > 0) {
        const heightDifference = rowHeight * rowsNumber - textDivRef?.current?.clientHeight;
        if (textDivRef.current && heightDifference < 0) {
          const increment = Math.ceil(Math.abs(heightDifference / rowHeight));
          handleOverflow(increment);
        } else if (textDivRef.current && heightDifference > rowHeight) {
          const decrement = Math.floor(heightDifference / rowHeight) * -1;
          handleOverflow(decrement);
        }
      }
    });
  });

  return (
    <div
      className="text-element-container"
      style={{
        height: '100%',
        position: 'relative'
      }}>
      {selectedTextEditor && selectedTextEditor === element._id ? (
        <div
          ref={textDivRef}
          style={{
            position: 'absolute',
            top: '50%',
            left: '0',
            transform: 'translateY(-50%)',
            width: '100%',
            fontSize: `${((rowHeight * 12) / 576) * 16}px`
          }}>
          <Editor
            init={{
              inline: true,
              // fixed_toolbar_container: '#testtt',
              // height: `${item.block.size[0] * 95}`,
              menubar: false,
              plugins: [
                'importcss code link charmap lists wordcount charmap emoticons paste lists advlist autoresize help charmap directionality'
              ],
              toolbar1: 'fontselect fontsizeselect forecolor',
              toolbar2:
                'bold italic underline alignleft aligncenter alignright alignjustify numlist showMore',
              toolbar_groups: {
                showMore: {
                  icon: 'image-options',
                  tooltip: 'More...',
                  items: 'bullist backcolor removeformat charmap ltr rtl superscript subscript help'
                }
              },
              help_tabs: ['shortcuts'],
              toolbar_mode: 'floating',
              // toolbar: 'fontselect fontsizeselect forecolor bold italic underline alignleft aligncenter alignright alignjustify numlist | bullist emoticons',
              // toolbar2: 'forecolor | bold italic underline | alignleft aligncenter alignright alignjustify | numlist bullist ',
              // toolbar1: 'fontselect',
              // toolbar2: 'fontsizeselect',
              // toolbar3: 'undo redo',
              // toolbar4: 'forecolor',
              // toolbar5: 'bold italic underline',
              // toolbar6: 'alignleft aligncenter alignright alignjustify',
              // toolbar7: 'numlist bullist',
              // plugins: 'autoresize',
              min_height: 48,
              autoresize_bottom_margin: 0,
              // toolbar: false,
              statusbar: false,
              contextmenu: '',
              content_style: tinyMCEContentStyleString,
              font_formats: tinyMCEFontFormatsString
                ? tinyMCEFontFormatsString
                : 'Arial Black=arial black,avant garde; Courier New=courier new,courier; Lato Black=lato; Roboto=roboto;',
              placeholder: 'Add text here',
              quickbars_insert_toolbar: false,
              // toolbar_mode: 'wrap',
              fontsize_formats: 'small=0.75em normal=1em large=1.5em extra-large=2em',
              color_map: [
                ...theme.colorPalette.colors.flatMap((color, index) => [color.hex, color.hex]),
                ...theme.colorPalette.colorsBlackWhiteGrey.flatMap((color, index) => [
                  color.hex,
                  color.hex
                ])
              ],
              browser_spellcheck: true
            }}
            onInit={(event, editor) => {
              editor.editorCommands.execCommand(
                'FontName',
                false,
                defaultFont.name.toLowerCase().replace(' -', '')
              );
              editor.contentAreaContainer.style.fontSize = '1em';
              editor.setContent(element.blockProperties.textContent);
              editor.focus();
              editor.selection.select(editor.getBody(), true);
              editor.selection.collapse(false);
              editor.on('blur', (e) => {
                e.stopImmediatePropagation();
                setSelectedTextEditor(null);
              });
              editor.on('ExecCommand', (e) => {
                if (e.command === 'mceApplyTextcolor') {
                  document.querySelector(
                    'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                  ).style.background = e.value;
                }
                if (e.command === 'mceRemoveTextcolor') {
                  document.querySelector(
                    'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                  ).style.background = '#2A3A49';
                }
              });
              editor.formatter.formatChanged(
                'forecolor',
                function (isNew, args) {
                  if (args.node.style.color) {
                    document.querySelector(
                      'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                    ).style.background = args.node.style.color;
                  } else {
                    document.querySelector(
                      'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap'
                    ).style.background = '#2A3A49';
                  }
                },
                true
              );
              editor.on('keydown', (e) => {
                e.stopPropagation();
              });
            }}
            apiKey="j1zjwgyada24ar7u2uwpe3dxyhx6pduzftls0ex2z129yg13"
            value={element.blockProperties.textContent}
            onEditorChange={(value, editor) => {
              handleChange(value);
            }}
          />
        </div>
      ) : (
        <div
          className="text-content-container"
          ref={textDivRef}
          dangerouslySetInnerHTML={{
            __html: element.blockProperties.textContent || 'Add text here'
          }}
          style={{
            cursor: 'move',
            position: 'absolute',
            top: '50%',
            left: '0',
            transform: 'translateY(-50%)',
            width: '100%',
            fontSize: `${((rowHeight * 12) / 576) * 16}px`
          }}
          onDoubleClick={() => {
            if (element.name !== 'question') {
              setSelectedTextEditor(element._id);
            }
          }}></div>
      )}
    </div>
  );
};

export default TextElement;
