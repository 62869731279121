import { Button, Checkbox, Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import { ReactComponent as LinkSearch } from '../../icons/link-search.svg';
import { SERVER_URL } from '../../config';
import { useContext } from 'react';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { useEffect } from 'react';
import Reference from '../../components/endnotes/Reference';
import Footnote from '../../components/endnotes/Footnote';
import Abbreviation from '../../components/endnotes/Abbreviation';

const Endnotes = ({
  highlightedBlock,
  addingEndnote,
  addEndnotes,
  endnoteForEdit,
  editCallback,
}) => {
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const [library, setLibrary] = useState(true);
  const [newEndnote, setNewEndnote] = useState(false);
  const [endnoteType, setEndnoteType] = useState();
  const [sourceType, setSourceType] = useState();
  const [endnotes, fetchEndnotes] = useAxios(
    '',
    {},
    currentuser.data.token,
    'get'
  );
  const [selectedEndnotes, setSelectedEndnotes] = useState([]);

  useEffect(() => {
    if (library && !endnoteForEdit) {
      if (endnoteType === 'Reference' && sourceType) {
        const filter = { source: sourceType };
        fetchEndnotes(
          `${SERVER_URL}/references?user=${
            currentuser.data.id
          }&filter=${JSON.stringify(filter)}`,
          null,
          currentuser.data.token,
          'get'
        );
      } else if (endnoteType === 'Footnote') {
        fetchEndnotes(`${SERVER_URL}/footnotes`, {});
      } else if (endnoteType === 'Abbreviation') {
        fetchEndnotes(`${SERVER_URL}/abbreviations`, {});
      }
    } else {
      fetchEndnotes(`/`, {});
    }
  }, [endnoteType, fetchEndnotes, library, sourceType, endnoteForEdit]);

  useEffect(() => {
    if (!endnoteForEdit && addingEndnote) {
      const currentEndnoteType =
        addingEndnote.charAt(0).toUpperCase() + addingEndnote.substring(1);
      setEndnoteType(currentEndnoteType);
    } else if (endnoteForEdit) {
      if (endnoteForEdit.abbreviation) {
        setEndnoteType('Abbreviation');
      } else if (endnoteForEdit.reference) {
        setEndnoteType('Reference');
        setSourceType(endnoteForEdit.source);
      } else {
        setEndnoteType('Footnote');
      }
    }
  }, [endnoteForEdit, addingEndnote]);

  const endnoteTypeForReducer =
    endnoteType === 'Reference'
      ? 'references'
      : endnoteType === 'Footnote'
      ? 'footnotes'
      : 'abbreviations';
      
  return (
    <div>
      {!endnoteForEdit && (
        <div className='button-props-panel__btn-effects-choices choice-btn-group'>
          <div
            className={
              library
                ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                : 'button-props-panel__btn-effects-choice choice-btn'
            }
            onClick={() => {
              setLibrary(true);
              setNewEndnote(false);
            }}
          >
            Library
          </div>
          <div
            className={
              newEndnote
                ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                : 'button-props-panel__btn-effects-choice choice-btn'
            }
            onClick={() => {
              setNewEndnote(true);
              setLibrary(false);
            }}
          >
            New
          </div>
        </div>
      )}

      {/* <Select
        className='dropdown-medium'
        style={{ width: '100%', marginBottom: '10px' }}
        disabled={!highlightedBlock || highlightedBlock.block.blockType.name !== 'text'}
        placeholder='Endnote Type'
        onChange={(value) => {
          setEndnoteType(value);
          if (value !== 'Reference') {
            setSourceType();
          }
        }
        }
      >
        <Select.Option value='Reference' className='fieldType'>
          Reference
        </Select.Option>
        <Select.Option value='Footnote' className='fieldType'>
          Footnote
        </Select.Option>
        <Select.Option value='Abbreviation' className='fieldType'>
          Abbreviation
        </Select.Option>
      </Select> */}
      {library && endnoteType === 'Reference' && !endnoteForEdit && (
        <Select
          className='dropdown-medium'
          style={{ width: '100%', marginBottom: '10px' }}
          disabled={
            !highlightedBlock ||
            highlightedBlock.block.blockType.name !== 'text'
          }
          placeholder='Reference Type'
          defaultValue={sourceType}
          onChange={(value) => {
            setSourceType(value);
            form.resetFields();
          }}
        >
          <Select.Option value='book' className='fieldType'>
            Book
          </Select.Option>
          <Select.Option value='journal article' className='fieldType'>
            Journal Article
          </Select.Option>
          <Select.Option value='conference proceeding' className='fieldType'>
            Conference Proceeding
          </Select.Option>
          <Select.Option value='pi' className='fieldType'>
            PI
          </Select.Option>
          <Select.Option value='smpc' className='fieldType'>
            SmPC
          </Select.Option>
          <Select.Option value='data on file' className='fieldType'>
            Data on File
          </Select.Option>
          <Select.Option value='website' className='fieldType'>
            Website
          </Select.Option>
          <Select.Option value='other' className='fieldType'>
            Other
          </Select.Option>
        </Select>
      )}

      {library && endnoteType && !endnoteForEdit && (
        <>
          <Input
            placeholder={`Search ${endnoteType}s`}
            prefix={<LinkSearch style={{ marginRight: '5px' }} />}
            allowClear
            style={{ marginTop: '10px' }}
            disabled={
              !highlightedBlock ||
              highlightedBlock.block.blockType.name !== 'text'
            }
          />
          <div
            className={endnotes?.data?.items?.length > 5 ? 'endnoteList' : ''}
          >
            {endnotes?.data?.items?.map((endnote) => (
              <div className='endnote'>
                <span>
                  {endnote.title ||
                    endnote.abbreviation ||
                    endnote.customReference}
                </span>
                <Checkbox
                  checked={selectedEndnotes.some(
                    (selectedEndnote) => selectedEndnote._id === endnote._id
                  )}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedEndnotes((previous) => [...previous, endnote]);
                    } else {
                      setSelectedEndnotes((previous) =>
                        previous.filter(
                          (selectedEndnote) =>
                            selectedEndnote._id !== endnote._id
                        )
                      );
                    }
                  }}
                />
              </div>
            ))}
          </div>
          <Button
            type='primary'
            style={{ width: '100%', marginTop: '10px' }}
            disabled={
              !highlightedBlock ||
              highlightedBlock.block.blockType.name !== 'text'
            }
            onClick={() => addEndnotes(selectedEndnotes, endnoteTypeForReducer)}
          >
            Add {endnoteType}
          </Button>
        </>
      )}
      {(newEndnote || endnoteForEdit) && endnoteType === 'Reference' && (
        <Reference
          token={currentuser.data.token}
          source={sourceType}
          setSource={setSourceType}
          endnoteForEdit={endnoteForEdit}
          editCallback={editCallback}
          newEndnote={newEndnote}
        />
      )}
      {(newEndnote || endnoteForEdit) && endnoteType === 'Abbreviation' && (
        <Abbreviation
          token={currentuser.data.token}
          endnoteForEdit={endnoteForEdit}
          editCallback={editCallback}
        />
      )}
      {(newEndnote || endnoteForEdit) && endnoteType === 'Footnote' && (
        <Footnote
          token={currentuser.data.token}
          endnoteForEdit={endnoteForEdit}
          editCallback={editCallback}
        />
      )}
    </div>
  );
};

export default Endnotes;
