import { Button, Modal, Checkbox, Table } from 'antd';
import React, { useState } from 'react';

const CheckboxGroup = Checkbox.Group;
const plainOptions = ['SMS', 'Email', 'WhatsApp', 'Viber'];
const defaultCheckedList = ['Email'];

const SendQoolectionModal = ({ qoolection, visible, closeModal, sendQoolectionHandler, contacts }) => {
  const [selectedClients, setSelectedClients] = useState(false);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columns = [
    {
      title: "First Name",
      dataIndex: 'firstName',
    },
    {
      title: "Last Name",
      dataIndex: 'lastName',
    },
    {
      title: "Email",
      dataIndex: 'email',
    },
    {
      title: "Phone",
      dataIndex: 'phone',
    },
    {
      title: "Employes",
      dataIndex: 'employer',
    },
    {
      title: "SMS",
      dataIndex: ['permissions', 'sms'],
      render: (text, record) => (
        <Checkbox checked={record?.permissions?.sms} />
      )
    },
    {
      title: "EMAIL",
      dataIndex: ['permissions', 'email'],
      render: (text, record) => (
        <Checkbox checked={record?.permissions?.email} />
      )
    },
    {
      title: "Viber",
      dataIndex: ['permissions', 'viber'],
      render: (text, record) => (
        <Checkbox checked={record?.permissions?.viber} />
      )
    },
    {
      title: "WhatsApp",
      dataIndex: ['permissions', 'whatsapp'],
      render: (text, record) => (
        <Checkbox checked={record?.permissions?.whatsapp} />
      )
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedClients(selectedRows);
    },
  };

  const onCheckboxChange = (list) => {
    setCheckedList(list);
  };

  return (
    <div>
      <Modal
        visible={visible}
        centered
        closable
        onCancel={closeModal}
        footer={null}
        bodyStyle={{
          padding: '0',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
        destroyOnClose={true}
        width='auto'
      >
        <div className="approval-modal">
          <Table
            size='middle'
            bordered
            dataSource={contacts}
            columns={columns}
            rowKey='phone'
            rowSelection={{ ...rowSelection }}
            pagination={{
              defaultPageSize: 10,
              position: ['bottomCenter'],
              showSizeChanger: false,
              pageSizeOptions: ['10', '20', '50', '100'],
            }}
          />
        </div>
        <CheckboxGroup
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 20,
          }}
          options={plainOptions}
          value={checkedList}
          onChange={onCheckboxChange}
        />
        {selectedClients && (
          <Button type="primary" style={{ width: 'auto', marginTop: 30 }} onClick={() => sendQoolectionHandler(qoolection._id, selectedClients, checkedList)}>Send qoolection</Button>
        )}
      </Modal>
    </div>
  );
};

export default SendQoolectionModal;