import React, { useState } from 'react';
import BlockDisplay from '../../pages/blocks/BlockDisplay';
import { ReactComponent as EditIcon } from '../../icons/ph_note-pencil-light.svg';
import { ReactComponent as RemoveIcon } from '../../icons/ph_trash-light.svg';
import { ReactComponent as ClockIcon } from "../../icons/ph_clock-light.svg";
import DeleteModal from '../../components/elements/DeleteModal';

const BlockCard = ({ block, editDataHandler, deleteDataHandler, historyHandler, qoos, qoolections }) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  return (
    <div
      className="blocks-grid__card"
      onMouseEnter={() => setOptionsVisible(true)}
      onMouseLeave={() => setOptionsVisible(false)}
    >
      <div className="blocks-grid__card-content">
        <BlockDisplay
          block={block}
          width={180}
          height={240}
          noBorder={true}
        />
        <div className={optionsVisible ? "blocks-grid__options-wrapper blocks-grid__options-wrapper--visible" : "blocks-grid__options-wrapper"}>
          <div
            className="blocks-grid__options-item"
            onClick={editDataHandler}
          >
            <EditIcon />
            <span>Edit Block</span>
          </div>
          <div
            className="blocks-grid__options-item"
            onClick={() => setDeleteModalVisible(true)}
          >
            <RemoveIcon />
            <span>Delete Block</span>
          </div>
          <div
            className="blocks-grid__options-item"
            onClick={historyHandler}
          >
            <ClockIcon />
            <span>Version history</span>
          </div>
        </div>

      </div>
      <span className="blocks-grid__title">{block.name}</span>
      {deleteModalVisible &&
        <DeleteModal
          visible={deleteModalVisible}
          closeModal={() => setDeleteModalVisible(false)}
          title={'Block'}
          deleteCallback={() => {
            setDeleteModalVisible(false);
            deleteDataHandler();
          }}
          qoos={qoos}
          qoolections={qoolections}
          isBlock={true}
          blockId={block._id}
        />
      }
    </div>
  );
};

export default BlockCard;