import React from 'react';
import { Modal, Button } from 'antd';

const DuplicateModal = ({ visible, closeModal, handleDuplicate }) => {
  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      footer={null}
      className='delete-modal'
      width={494}
      closable={false}
    >
      <h2 className="delete-modal__title">This Qoo is already in your Qoolection.</h2>
      <p className="delete-modal__subtitle">Do you want to duplicate it as a new Qoo?</p>
      <div className="delete-modal__buttons">
        <Button
          className='delete-modal__btn'
          onClick={closeModal}
          type='primary'
        >
          Cancel
        </Button>
        <Button
          className='delete-modal__btn'
          onClick={handleDuplicate}
        >
          Duplicate
        </Button>
      </div>
    </Modal>
  );
};

export default DuplicateModal;