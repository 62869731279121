import React from 'react';
import { Tooltip } from 'antd';
import { ReactComponent as InfoIcon } from '../../icons/info-icon.svg';

function HomebaseStatsEntry({ entry, name, data }) {
  return (
    <div className="homebase__list-entry">
      <div className="homebase__list-entry-icon">{entry.icon}</div>
      {/* TO-DO Add Dynamic Value */}
      <div className="homebase__list-entry-data">
        <span className="homebase__list-entry-value">
          {data}
          {entry.type === 'percentage' && '%'}
        </span>
        {/* end TO-DO */}
        <span className="homebase__list-entry-label">{entry.label}</span>
        {entry.tooltip && (
          <Tooltip
            color={'#4962E2'}
            placement="bottom"
            title={
              <div
                className="homebase__tooltip"
                dangerouslySetInnerHTML={{ __html: entry.tooltip }}></div>
            }>
            <InfoIcon className="homebase__list-entry-info" />
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default HomebaseStatsEntry;
