import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Modal, Popconfirm } from 'antd';
import { ReactComponent as ExpandIcon } from '../../icons/expand-media.svg';
import { ReactComponent as PlayIcon } from '../../icons/video-play-library.svg';
import { ReactComponent as DeleteIcon } from '../../icons/table-delete.svg';
import { Player, BigPlayButton, ControlBar } from 'video-react';
import Masonry from 'react-masonry-css';
import NoData from '../elements/NoData';

const MyVideosGrid = ({ data, deleteHandler, user }) => {
  const [videoHovered, setVideoHovered] = useState(1);
  const [videoForPreview, setVideoForPreview] = useState();
  const [previewModalVisible, setPreviewModalVisible] = useState(false);

  const showPreviewModal = (videoId) => {
    setPreviewModalVisible(true);
    setVideoForPreview(videoId);
  };

  return (
    <>
      {data?.length > 0 ? (
        <InfiniteScroll dataLength={data.length} scrollableTarget="scrollable-div">
          <Masonry
            breakpointCols={5}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
            id="scrollable-div"
            style={{ height: '80vh', overflowY: 'auto' }}>
            {data.map((video, index) => {
              return (
                <Player
                  className="library-asset"
                  onMouseEnter={() => setVideoHovered(video._id)}
                  onMouseLeave={() => setVideoHovered(null)}
                  key={video._id}
                  style={{ position: 'relative' }}>
                  <source src={video.location} />
                  <ControlBar disableCompletely={true} />
                  {videoHovered === video._id && (
                    <div
                      className="image-overlay"
                      style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: '997' }}>
                      <div className="image-overlay__top-row video-overlay__top-row">
                        <div className="video-overlay__top-row-icons">
                          <span
                            onClick={() => showPreviewModal(video._id)}
                            className="image-overlay__icon">
                            <ExpandIcon />
                          </span>
                          <Popconfirm
                            disabled={video._id === user.data.id}
                            placement="left"
                            title="Delete Video?"
                            onConfirm={() => {
                              deleteHandler(video._id);
                            }}
                            okText="Ok"
                            cancelText="Cancel">
                            <span className="image-overlay__icon" style={{ zIndex: 999 }}>
                              <DeleteIcon
                                title={`Delete ${video.originalname}`}
                                style={{
                                  textDecoration: 'none',
                                  color: '#90989E',
                                  cursor: 'pointer'
                                }}
                              />
                            </span>
                          </Popconfirm>
                        </div>
                      </div>
                      <div className="image-overlay__bottom-row">
                        <span>
                          {video.originalname.split('.')[0].length > 50
                            ? video.originalname.substring(0, 50) + '...'
                            : video.originalname}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="video-play-btn" onClick={() => showPreviewModal(video._id)}>
                    <PlayIcon />
                  </div>
                </Player>
              );
            })}
          </Masonry>
        </InfiniteScroll>
      ) : (
        <NoData />
      )}
      <Modal
        destroyOnClose={true}
        className="media-preview-modal video-modal-library"
        visible={previewModalVisible}
        onCancel={() => setPreviewModalVisible(false)}
        maskClosable>
        {data.map((video) => {
          if (video._id === videoForPreview) {
            return (
              <Player autoPlay className="media-preview-modal__asset" fluid={false} height={640}>
                <source src={video.location} />
                <BigPlayButton position="center" />
                <ControlBar disableCompletely={true} />
              </Player>
            );
          }
          return null;
        })}
      </Modal>
    </>
  );
};

export default MyVideosGrid;
