import React, { useState } from 'react';
import { notification } from 'antd';
import Axios from 'axios';
import { UPDATE_HEADERFOOTER_PROPERTY } from '../../reducers/theme-in-preview/actionTypes';
import { ReactComponent as Close } from '../../icons/close-small.svg';
import DeleteModal from '../../components/elements/DeleteModal';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';

const LogoCard = ({ logo, selected, dispatch, setRerenderState, SERVER_URL, token }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const deleteLogo = async () => {
    try {
      await Axios.post(
        `${SERVER_URL}/remove-logo/${logo.logoKey}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      notification.success({
        message: 'Logo deleted.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration,
      });
      setRerenderState((previous) => previous + 1);
    } catch (err) {
      notification.error({
        message: 'Problem with deleting logo. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration,
      });
    }
  };

  return (
    <>
      <div
        className={selected ? 'logo-card logo-card--selected' : 'logo-card'}
        onClick={() => {
          dispatch({
            type: UPDATE_HEADERFOOTER_PROPERTY,
            payload: {
              section: 'header',
              updates: {
                logo: logo.logoUrl
              }
            }
          });
        }}
      >
        <div
          className={
            selected ? 'logo-card__logo-container logo-card__logo-container--selected' : 'logo-card__logo-container'
          }
        >
          <img src={logo.logoUrl} alt='logo' />
        </div>
        <div
          className='logo-card__delete'
          onClick={(e) => {
            e.stopPropagation();
            setModalVisible(true);
          }}
        >
          <Close />
        </div>
      </div>
      {modalVisible && (
        <DeleteModal
          visible={modalVisible}
          closeModal={() => setModalVisible(false)}
          title='this logo'
          deleteCallback={deleteLogo}
        />
      )}
    </>
  );
};

export default LogoCard;
