import React, { useContext, useState } from 'react';
import { Button, Input, InputNumber, Select, notification } from 'antd';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import axios from 'axios';

const QuestioneersForm = ({
  setStateForRefetchingQuestioneers,
  questioneerForEdit,
  setQuestioneerForEdit,
  isNew
}) => {
  const currentuser = useContext(UserContext);
  const questioneerInitialState = {
    client: currentuser?.data?.client?._id,
    study: currentuser.study,
    team: currentuser.selectedTeam,
    locked: false,
    ...questioneerForEdit
  };
  const [data, setData] = useState(questioneerInitialState);

  async function addQuestioneerHandler(data) {
    try {
      axios.post(
        `${SERVER_URL}/questioneers`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      notification.success({
        message: `ePRO created`,
        placement: 'bottomRight'
      });
      setStateForRefetchingQuestioneers((prev) => prev + 1);
      setData({ client: currentuser.data.client?._id });
    } catch (error) {
      notification.error({
        message: 'Problem with creating ePRO',
        placement: 'bottomRight'
      });
    }
  }

  async function updateQuestioneerHandler(data) {
    console.log('Update data', data);
    try {
      axios.put(
        `${SERVER_URL}/questioneers/${questioneerForEdit._id}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      notification.success({
        message: `ePRO updated`,
        placement: 'bottomRight'
      });
      setStateForRefetchingQuestioneers((prev) => prev + 1);
      setQuestioneerForEdit(null);
      setData({ client: currentuser?.data?.client?._id });
    } catch (error) {
      console.log('Error', error);
      notification.error({
        message: 'Problem with updating ePRO',
        placement: 'bottomRight'
      });
    }
  }

  return (
    <div style={{ padding: '10px 15px' }}>
      <Input
        className="block-event__element"
        placeholder="Name"
        value={data.name}
        onChange={(e) => setData({ ...data, name: e.target.value })}
      />
      <Input.TextArea
        style={{ height: 'auto' }}
        className="block-event__element"
        rows={3}
        maxLength={300}
        placeholder="Description"
        value={data.description}
        onChange={(e) => setData({ ...data, description: e.target.value })}
      />
      <Select
        className="block-event__element"
        placeholder="Category"
        value={data.category}
        onChange={(value) => setData({ ...data, category: value })}
        showSearch>
        <Select.Option value="Mental Health">Mental Health</Select.Option>
        <Select.Option value="Physical Health">Physical Health</Select.Option>
        <Select.Option value="Social Health">Social Health</Select.Option>
        <Select.Option value="Preclinical Research">Preclinical Research</Select.Option>
        <Select.Option value="Clinical Pharmacology">Clinical Pharmacology</Select.Option>
        <Select.Option value="Phase 1 Clinical Trials">Phase 1 Clinical Trials</Select.Option>
        <Select.Option value="Phase 2 Clinical Trials">Phase 2 Clinical Trials</Select.Option>
        <Select.Option value="Phase 3 Clinical Trials">Phase 3 Clinical Trials</Select.Option>
        <Select.Option value="Phase 4 Clinical Trials">Phase 4 Clinical Trials</Select.Option>
        <Select.Option value="Drug Safety and Pharmacovigilance">
          Drug Safety and Pharmacovigilance
        </Select.Option>
        <Select.Option value="Biostatistics and Data Analysis">
          Biostatistics and Data Analysis
        </Select.Option>
        <Select.Option value="Regulatory Affairs">Regulatory Affairs</Select.Option>
        <Select.Option value="Health Economics and Outcomes Research">
          Health Economics and Outcomes Research
        </Select.Option>
      </Select>
      <Select
        className="block-event__element"
        placeholder="Domain"
        value={data.domain}
        onChange={(value) => setData({ ...data, domain: value })}
        showSearch>
        <Select.Option value="Ability to Participate">Ability to Participate</Select.Option>
        <Select.Option value="Alcohol Use">Alcohol Use</Select.Option>
        <Select.Option value="Anger">Anger</Select.Option>
        <Select.Option value="Anger/Irritability">Anger/Irritability</Select.Option>
        <Select.Option value="Anxiety/Fear">Anxiety/Fear</Select.Option>
        <Select.Option value="Cognition">Cognition</Select.Option>
        <Select.Option value="Communication">Communication</Select.Option>
        <Select.Option value="Depression/Sadness">Depression/Sadness</Select.Option>
        <Select.Option value="Dyspnea">Dyspnea</Select.Option>
        <Select.Option value="End of Life Concerns">End of Life Concerns</Select.Option>
        <Select.Option value="Engagement">Engagement</Select.Option>
        <Select.Option value="Fatigue">Fatigue</Select.Option>
        <Select.Option value="Gastrointestinal">Gastrointestinal</Select.Option>
        <Select.Option value="Itch">Itch</Select.Option>
        <Select.Option value="Motor Function">Motor Function</Select.Option>
        <Select.Option value="Pain">Pain</Select.Option>
        <Select.Option value="Physical Activity">Physical Activity</Select.Option>
        <Select.Option value="Physical Function">Physical Function</Select.Option>
        <Select.Option value="Positive Affect/Well-being">Positive Affect/Well-being</Select.Option>
        <Select.Option value="Relationships/Social Support">
          Relationships/Social Support
        </Select.Option>
        <Select.Option value="Satisfaction with Participation">
          Satisfaction with Participation
        </Select.Option>
        <Select.Option value="Self-Efficacy">Self-Efficacy</Select.Option>
        <Select.Option value="Self-Efficacy for Managing Chronic Conditions">
          Self-Efficacy for Managing Chronic Conditions
        </Select.Option>
        <Select.Option value="Self-Regulation">Self-Regulation</Select.Option>
        <Select.Option value="Sexual Function and Satisfaction">
          Sexual Function and Satisfaction
        </Select.Option>
        <Select.Option value="Sleep">Sleep</Select.Option>
        <Select.Option value="Smoking">Smoking</Select.Option>
        <Select.Option value="Stiffness">Stiffness</Select.Option>
        <Select.Option value="Stigma">Stigma</Select.Option>
        <Select.Option value="Stress">Stress</Select.Option>
        <Select.Option value="Substance Use">Substance Use</Select.Option>
        <Select.Option value="Nutrition">Nutrition</Select.Option>
        <Select.Option value="Quality of Life">Quality of Life</Select.Option>
        <Select.Option value="Caregiver Burden">Caregiver Burden</Select.Option>
        <Select.Option value="Adherence">Adherence</Select.Option>
        <Select.Option value="Health Literacy">Health Literacy</Select.Option>
        <Select.Option value="Body Image">Body Image</Select.Option>
        <Select.Option value="Cultural Beliefs">Cultural Beliefs</Select.Option>
      </Select>
      <Select
        className="block-event__element"
        placeholder="Population"
        value={data.population}
        onChange={(value) => setData({ ...data, population: value })}
        showSearch>
        <Select.Option value="Adult">Adult</Select.Option>
        <Select.Option value="Pediatric">Pediatric</Select.Option>
        <Select.Option value="Proxy Report for Adult">Proxy Report for Adult</Select.Option>
        <Select.Option value="Proxy Report for Pediatric">Proxy Report for Pediatric</Select.Option>
        <Select.Option value="Geriatric">Geriatric</Select.Option>
        <Select.Option value="Pregnant Women">Pregnant Women</Select.Option>
        <Select.Option value="Postpartum">Postpartum</Select.Option>
        <Select.Option value="Adolescent">Adolescent</Select.Option>
        <Select.Option value="Caregiver">Caregiver</Select.Option>
        <Select.Option value="Individuals with Disabilities">
          Individuals with Disabilities
        </Select.Option>
        <Select.Option value="Ethnic or Cultural Groups">Ethnic or Cultural Groups</Select.Option>
      </Select>
      <Select
        className="block-event__element"
        placeholder="Measurement System"
        value={data.measurement_system}
        onChange={(value) => setData({ ...data, measurement_system: value })}
        showSearch>
        <Select.Option value="ASCQ-Me">
          Adult Sickle Cell Quality of Life Measurement Information System (ASCQ-Me)
        </Select.Option>
        <Select.Option value="NIH Toolbox">NIH Toolbox</Select.Option>
        <Select.Option value="Neuro-QoL">
          Quality of Life in Neurological Disorders (Neuro-QoL)
        </Select.Option>
        <Select.Option value="PROMIS">
          Patient-Reported Outcomes Measurement Information System (PROMIS)
        </Select.Option>
        <Select.Option value="SF-36">36-Item Short Form Health Survey (SF-36)</Select.Option>
        <Select.Option value="EQ-5D">EuroQol Five Dimensions Questionnaire (EQ-5D)</Select.Option>
        <Select.Option value="HADS">Hospital Anxiety and Depression Scale (HADS)</Select.Option>
        <Select.Option value="MMSE">Mini-Mental State Examination (MMSE)</Select.Option>
        <Select.Option value="BDI">Beck Depression Inventory (BDI)</Select.Option>
        <Select.Option value="MOS-HIV">
          Medical Outcomes Study HIV Health Survey (MOS-HIV)
        </Select.Option>
        <Select.Option value="VAS">Visual Analog Scale (VAS)</Select.Option>
        <Select.Option value="GAD-7">
          Generalized Anxiety Disorder 7-item scale (GAD-7)
        </Select.Option>
        <Select.Option value="PHQ-9">
          Patient Health Questionnaire 9-item scale (PHQ-9)
        </Select.Option>
        <Select.Option value="CES-D">
          Center for Epidemiologic Studies Depression Scale (CES-D)
        </Select.Option>
        <Select.Option value="IPAQ">
          International Physical Activity Questionnaire (IPAQ)
        </Select.Option>
        <Select.Option value="WHOQOL-BREF">
          World Health Organization Quality of Life-BREF (WHOQOL-BREF)
        </Select.Option>
      </Select>
      <Select
        className="block-event__element"
        placeholder="Assessment Type"
        value={data.assesment_type}
        onChange={(value) => setData({ ...data, assesment_type: value })}
        showSearch>
        <Select.Option value="Fixed Length Short Form">Fixed Length Short Form</Select.Option>
        <Select.Option value="Battery/Profile">Battery/Profile</Select.Option>
        <Select.Option value="Computer Adaptive Test/Item Bank">
          Computer Adaptive Test/Item Bank
        </Select.Option>
        <Select.Option value="Multiple Choice Questions">Multiple Choice Questions</Select.Option>
        <Select.Option value="Short Answer Questions">Short Answer Questions</Select.Option>
        <Select.Option value="Essay Questions">Essay Questions</Select.Option>
        <Select.Option value="Oral Examinations">Oral Examinations</Select.Option>
        <Select.Option value="Practical/Performance Assessments">
          Practical/Performance Assessments
        </Select.Option>
        <Select.Option value="Objective Structured Clinical Examination">
          Objective Structured Clinical Examination
        </Select.Option>
        <Select.Option value="Standardized Patient Encounters">
          Standardized Patient Encounters
        </Select.Option>
        <Select.Option value="Direct Observation of Procedural Skills">
          Direct Observation of Procedural Skills
        </Select.Option>
        <Select.Option value="Mini-Clinical Evaluation Exercise">
          Mini-Clinical Evaluation Exercise
        </Select.Option>
        <Select.Option value="Case-based Discussions">Case-based Discussions</Select.Option>
        <Select.Option value="Journal Club Presentations">Journal Club Presentations</Select.Option>
        <Select.Option value="Portfolio Assessments">Portfolio Assessments</Select.Option>
        <Select.Option value="Reflective Writing Assignments">
          Reflective Writing Assignments
        </Select.Option>
        <Select.Option value="Peer Assessment">Peer Assessment</Select.Option>
        <Select.Option value="Self-Assessment">Self-Assessment</Select.Option>
        <Select.Option value="360-Degree Evaluation">360-Degree Evaluation</Select.Option>
        <Select.Option value="Entrustable Professional Activities">
          Entrustable Professional Activities
        </Select.Option>
        <Select.Option value="Situational Judgment Tests">Situational Judgment Tests</Select.Option>
        <Select.Option value="In-Training Examination">In-Training Examination</Select.Option>
        <Select.Option value="Knowledge Tests">Knowledge Tests</Select.Option>
        <Select.Option value="Skills Checklists">Skills Checklists</Select.Option>
        <Select.Option value="Simulation-Based Assessments">
          Simulation-Based Assessments
        </Select.Option>
        <Select.Option value="Problem-Based Learning Assessments">
          Problem-Based Learning Assessments
        </Select.Option>
        <Select.Option value="Virtual Patient Scenarios">Virtual Patient Scenarios</Select.Option>
        <Select.Option value="Video-Based Assessments">Video-Based Assessments</Select.Option>
        <Select.Option value="Logbooks">Logbooks</Select.Option>
        <Select.Option value="Progress Testing">Progress Testing</Select.Option>
      </Select>

      {/* <InputNumber
        className='block-event__element'
        min={1}
        max={100}
        type='number'
        placeholder='Max Select'
        value={data.maxSelect}
        onChange={(value) =>
          setData({
            ...data,
            maxSelect: value,
          })
        }
      /> */}
      <Button
        style={{ width: '100%' }}
        type="primary"
        onClick={() => (isNew ? addQuestioneerHandler(data) : updateQuestioneerHandler(data))}>
        {isNew ? 'Save ePRO' : 'Edit ePRO'}
      </Button>
    </div>
  );
};

export default QuestioneersForm;
