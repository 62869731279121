import { Switch, Radio, Space, Button } from 'antd';
import React from 'react';
import { UPDATE_HEADERFOOTER_PROPERTY } from '../../reducers/theme-in-preview/actionTypes';
import StepNavigation from './StepNavigation';

const NewThemeStep4 = ({ step, setStep, themeInPreview, dispatch, completedStep, setCompletedStep }) => {

  return (
    <div className="new-theme-builder__step-4">
      <h2 className="new-theme-builder__step-subtitle">
        <span>Step 4: </span>
        <span>Now that you've added your brand elements, let's move to your
          Qoolection layout.</span>
      </h2>
      <StepNavigation
        step={step}
        setStep={setStep}
        completedStep={completedStep}
      />
      <div className="new-theme-builder__hf-section">
        <div className="new-theme-builder__hf-section-row">
          <p className="new-theme-builder__intro-text">
            Would you like to have a Header on your Qoolections?
          </p>
          <Switch
            checked={!themeInPreview.headerFooter.header.noHeader}
            onChange={() => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'header',
                  updates: {
                    noHeader: !themeInPreview.headerFooter.header.noHeader
                  }
                }
              });
            }}
          />
        </div>
        <p className="new-theme-builder__small-text">
          Note: If turned on, you will be able to toggle the Header on/off when you create individual Qoos.
        </p>
      </div>
      <div className="new-theme-builder__hf-section">
        <div className="new-theme-builder__hf-section-row">
          <p className="new-theme-builder__intro-text">
            Would you like to have a Footer on your Qoolections?
          </p>
          <Switch
            checked={!themeInPreview.headerFooter.footer.noFooter}
            disabled={themeInPreview.headerFooter.footer.shownISI === 'title-only'}
            onChange={() => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'footer',
                  updates: {
                    noFooter: !themeInPreview.headerFooter.footer.noFooter
                  }
                }
              });
            }}
          />
        </div>
      </div>
      <div className="new-theme-builder__hf-section">
        <div className="new-theme-builder__hf-section-row">
          <p className="new-theme-builder__intro-text">
            Do you need to show an Info Spotlight?
          </p>
          <Switch
            checked={!themeInPreview.headerFooter.footer.noISI}
            onChange={() => {
              dispatch({
                type: UPDATE_HEADERFOOTER_PROPERTY,
                payload: {
                  section: 'footer',
                  updates: {
                    noISI: !themeInPreview.headerFooter.footer.noISI
                  }
                }
              });
            }}
          />
        </div>
        <p className="new-theme-builder__small-text">
          Note: If tuned on, you will be able to toggle the Info Spotlight preview text on/off when you create individual Qoos.
        </p>
      </div>
      <p className="new-theme-builder__intro-text">
        How much text do you need to show in the Info Spotlight preview?
      </p>
      <Radio.Group
        disabled={themeInPreview.headerFooter.footer.noISI}
        value={themeInPreview.headerFooter.footer.shownISI}
        onChange={(e) => {
          dispatch({
            type: UPDATE_HEADERFOOTER_PROPERTY,
            payload: {
              section: 'footer',
              updates: {
                shownISI: e.target.value
              }
            }
          });
        }}
      >
        <Space direction="vertical">
          <Radio value='title-only' disabled={themeInPreview.headerFooter.footer.noFooter}><span className='new-theme-builder__small-text'>Title only</span></Radio>
          <Radio value='less-text'><span className='new-theme-builder__small-text'>Less Info Spotlight Text</span></Radio>
          <Radio value='more-text'><span className='new-theme-builder__small-text'>More Info Spotlight Text</span></Radio>
        </Space>
      </Radio.Group>
      <div className="new-theme-builder__bottom-row">
        <Button
          className='new-theme-builder__step-btn'
          onClick={() => setStep(3)}
        >
          Previous step
        </Button>
        <Button
          className='new-theme-builder__step-btn'
          type='primary'
          onClick={() => {
            if (completedStep === 3) {
              setCompletedStep(4);
            }
            setStep(5);
          }}
        >
          Next step
        </Button>
      </div>
    </div>
  );
};

export default NewThemeStep4;