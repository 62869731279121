import React, { useState, useRef, useEffect } from 'react';
import BlockPreviewBox from './BlockPreviewBox';
import {
  EDIT_BLOCK_LAYOUT,
  INNER_BLOCK_LAYOUT_CHANGE,
  MOVE_LAYER_TO_BOTTOM,
  MOVE_LAYER_TO_FRONT,
  MOVE_LAYER_UP_WITH_ID,
  MOVE_LAYER_DOWN_WITH_ID,
  REMOVE_BLOCK_FROM_LAYOUT,
  REMOVE_ENDNOTE
} from '../../reducers/block-in-preview/actionTypes';
import LayersPanel from './LayersPanel';
import ContextMenu from '../../components/elements/ContextMenu';
import BlockPhonePreviewModal from './BlockPhonePreviewModal';
import { ReactComponent as EndnotesIcon } from '../../icons/references.svg';
import { ReactComponent as PanelsIcon } from '../../icons/ph_selection-background-light.svg';
import { ReactComponent as LayersIcon } from '../../icons/ph_stack-light.svg';
import EndnotesPanel from './EndnotesPanel';
import PanelsPanel from './PanelsPanel';
import DeleteEndnoteModal from './DeleteEndnoteModal';
import BlockInPreviewGrid from './BlockInPreviewGrid';

const BlockBuilder = ({
  blockInPreview,
  dispatch,
  previewType,
  setPreviewType,
  blockType,
  selectedBlock,
  setSelectedBlock,
  setSelectedBlockType,
  gridOptions,
  setGridOptions,
  deleteModalVisible,
  setDeleteModalVisible,
  highlightedBlock,
  setEndnoteForEdit
}) => {
  const gridRef = useRef();
  const blockBuilderRef = useRef();
  const contextMenuRef = useRef();

  const [contextMenuCoords, setContextMenuCoords] = useState(null);
  const [rightPanelsView, setRightPanelsView] = useState('single');
  const [rightPanel, setRightPanel] = useState(null);
  const [hoveredEndnote, setHoveredEndnote] = useState(null);
  const [endnoteToDelete, setEndnoteToDelete] = useState(null);

  const handleClickOutside = (e) => {
    const layersPanelDiv = document.querySelector('.layers-panel');

    if (layersPanelDiv && layersPanelDiv.contains(e.target)) {
      return;
    }
    if (contextMenuRef.current && contextMenuRef.current.contains(e.target)) {
      return;
    }
    if (contextMenuRef.current && !contextMenuRef.current.contains(e.target)) {
      setContextMenuCoords(null);
    }

    if (gridRef.current && !gridRef.current.contains(e.target)) {
      setSelectedBlock(null);
      // setSelectedBlockType(null);
    }
  };

  useEffect(() => {
    const outside = document.querySelector('.edit-block-content');

    if (selectedBlock) {
      outside.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  });

  const [previewDevice, setPreviewDevice] = useState('iphonex');

  // const gridSize = gridOptions.size === '6x8' ? [6, 8] : gridOptions.size === '6x9' ? [6, 9] : [6, 10];
  const gridSize = [12, 16];
  const squaresNr = gridSize[0] * gridSize[1];
  const emptyArray = [...Array(squaresNr)];

  const handleBlockLayoutChange = (updates) => {
    dispatch({
      type: EDIT_BLOCK_LAYOUT,
      payload: updates
    });
  };

  const handleInnerLayoutChange = (item) => {
    dispatch({
      type: INNER_BLOCK_LAYOUT_CHANGE,
      payload: item
    });
  };

  const handleRightClick = (e) => {
    e.preventDefault();

    const x = e.clientX - blockBuilderRef.current.getBoundingClientRect().left;
    const y = e.clientY - blockBuilderRef.current.getBoundingClientRect().top;

    setContextMenuCoords({ x, y });
  };

  const moveLayerUp = (blockId) => {
    if (highlightedBlock) {
      dispatch({
        type: MOVE_LAYER_UP_WITH_ID,
        payload: {
          blockId
        }
      });
    }
  };

  const moveLayerToFront = (blockId) => {
    if (highlightedBlock) {
      dispatch({
        type: MOVE_LAYER_TO_FRONT,
        payload: {
          blockId
        }
      });
    }
  };

  const moveLayerDown = (blockId) => {
    if (highlightedBlock) {
      dispatch({
        type: MOVE_LAYER_DOWN_WITH_ID,
        payload: {
          blockId
        }
      });
    }
  };

  const moveLayerToBottom = (blockId) => {
    if (highlightedBlock) {
      dispatch({
        type: MOVE_LAYER_TO_BOTTOM,
        payload: {
          blockId
        }
      });
    }
  };

  return (
    <div className="block-builder" ref={blockBuilderRef}>
      {contextMenuCoords && (
        <ContextMenu
          contextMenuCoords={contextMenuCoords}
          highlightedBlock={highlightedBlock}
          ref={contextMenuRef}
          setDeleteModalVisible={setDeleteModalVisible}
          setContextMenuCoords={setContextMenuCoords}
          blockInPreviewDispatch={dispatch}
          moveLayerUp={moveLayerUp}
          moveLayerToFront={moveLayerToFront}
          moveLayerDown={moveLayerDown}
          moveLayerToBottom={moveLayerToBottom}
        />
      )}
      {rightPanelsView === 'single' && (
        <>
          <div className="right-panels-btn-container">
            <div
              className={
                rightPanel === 'endnotes'
                  ? 'right-panels-btn right-panels-btn--selected'
                  : 'right-panels-btn'
              }
              onClick={() => {
                if (rightPanel === 'endnotes') {
                  setRightPanel('single');
                } else {
                  setRightPanel('endnotes');
                }
              }}>
              <EndnotesIcon />
            </div>
            {/* <div
              className={rightPanel === 'panels' ? "right-panels-btn right-panels-btn--selected" : "right-panels-btn"}
              onClick={() => {
                if(rightPanel === 'panels') {
                  setRightPanel('single')
                } else {
                  setRightPanel('panels')
                }
              }}
            >
              <PanelsIcon />
            </div> */}
            <div
              className={
                rightPanel === 'layers'
                  ? 'right-panels-btn right-panels-btn--selected'
                  : 'right-panels-btn'
              }
              onClick={() => {
                if (rightPanel === 'layers') {
                  setRightPanel('single');
                } else {
                  setRightPanel('layers');
                }
              }}>
              <LayersIcon />
            </div>
          </div>
          {rightPanel === 'endnotes' && (
            <EndnotesPanel
              endnotes={[
                ...blockInPreview.endnotes?.references.map((reference) => reference.item),
                ...blockInPreview.endnotes?.footnotes.map((footnote) => footnote.item),
                ...[
                  ...blockInPreview.endnotes.abbreviations.map((abbreviation) => abbreviation.item)
                ].sort((a, b) => {
                  if (a.abbreviation < b.abbreviation) return -1;
                  if (a.abbreviation > b.abbreviation) return 1;
                  return 0;
                })
              ]}
              dockToRightSidebar={() => setRightPanelsView('sidebar')}
              rightPanel={rightPanel}
              setHoveredEndnote={setHoveredEndnote}
              removeCallback={(endnote) => setEndnoteToDelete(endnote)}
              editCallback={(endnote) => setEndnoteForEdit(endnote)}
            />
          )}
          {rightPanel === 'panels' && (
            <PanelsPanel
              dockToRightSidebar={() => setRightPanelsView('sidebar')}
              rightPanel={rightPanel}
            />
          )}
          {rightPanel === 'layers' && blockInPreview && blockInPreview.blocksLayout && (
            <LayersPanel
              layers={blockInPreview.blocksLayout}
              blockInPreview={blockInPreview}
              selectedBlock={selectedBlock}
              setSelectedBlock={setSelectedBlock}
              setSelectedBlockType={setSelectedBlockType}
              dispatch={dispatch}
              setDeleteModalVisible={setDeleteModalVisible}
              dockToRightSidebar={() => setRightPanelsView('sidebar')}
              rightPanel={rightPanel}
            />
          )}
        </>
      )}
      {rightPanelsView === 'sidebar' && (
        <div className="right-sidebar">
          <LayersPanel
            layers={blockInPreview.blocksLayout}
            blockInPreview={blockInPreview}
            selectedBlock={selectedBlock}
            setSelectedBlock={setSelectedBlock}
            setSelectedBlockType={setSelectedBlockType}
            dispatch={dispatch}
            setDeleteModalVisible={setDeleteModalVisible}
            inSidebar={true}
            collapseSidebar={(panel) => {
              setRightPanelsView('single');
              setRightPanel(panel);
            }}
            rightPanel={rightPanel}
          />
          <EndnotesPanel
            endnotes={[
              ...blockInPreview.endnotes?.references.map((reference) => reference.item),
              ...blockInPreview.endnotes?.footnotes.map((footnote) => footnote.item),
              ...[
                ...blockInPreview.endnotes.abbreviations.map((abbreviation) => abbreviation.item)
              ].sort((a, b) => {
                if (a.abbreviation < b.abbreviation) return -1;
                if (a.abbreviation > b.abbreviation) return 1;
                return 0;
              })
            ]}
            inSidebar={true}
            collapseSidebar={(panel) => {
              setRightPanelsView('single');
              setRightPanel(panel);
            }}
            rightPanel={rightPanel}
            setHoveredEndnote={setHoveredEndnote}
            removeCallback={(endnote) => setEndnoteToDelete(endnote)}
            editCallback={(endnote) => setEndnoteForEdit(endnote)}
          />
          <PanelsPanel
            inSidebar={true}
            collapseSidebar={(panel) => {
              setRightPanelsView('single');
              setRightPanel(panel);
            }}
            rightPanel={rightPanel}
          />
        </div>
      )}

      <div ref={gridRef} className="block-preview">
        {emptyArray.map((item, index) => {
          return (
            <BlockPreviewBox
              key={index}
              index={index}
              arrLength={emptyArray.length}
              gridOptions={gridOptions}
            />
          );
        })}
        {blockInPreview && (
          <BlockInPreviewGrid
            blockInPreview={blockInPreview}
            handleBlockLayoutChange={handleBlockLayoutChange}
            blockType={blockType}
            setSelectedBlockType={setSelectedBlockType}
            handleInnerLayoutChange={handleInnerLayoutChange}
            dispatch={dispatch}
            selectedBlock={selectedBlock}
            setSelectedBlock={setSelectedBlock}
            rows={gridSize[1]}
            deleteModalVisible={deleteModalVisible}
            setDeleteModalVisible={setDeleteModalVisible}
            handleRightClick={handleRightClick}
            hoveredEndnote={hoveredEndnote}
            rowHeight={48}
            rowsNumber={16}
          />
        )}
      </div>

      {previewType === 'phone' && (
        <BlockPhonePreviewModal
          visible={previewType === 'phone'}
          closeModal={() => setPreviewType('block')}
          block={blockInPreview}
        />
      )}
      {endnoteToDelete && (
        <DeleteEndnoteModal
          endnote={endnoteToDelete}
          closeModal={() => setEndnoteToDelete(null)}
          blockInPreview={blockInPreview}
          removeCallback={(endnoteId, elements, endnoteType) => {
            dispatch({
              type: REMOVE_ENDNOTE,
              payload: {
                endnoteId,
                elements,
                endnoteType
              }
            });
            setEndnoteToDelete(null);
          }}
        />
      )}
    </div>
  );
};

export default BlockBuilder;
