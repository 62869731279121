import React from 'react';
import { calculateHF } from '../../utils/calculateHF';
import QooHeader from '../qoos/QooHeader';
import QooFooter from '../qoos/QooFooter';
import { ReactComponent as BarsIcon } from '../../icons/mdi-light_signal.svg';
import { ReactComponent as FlaskIcon } from '../../icons/ph_flask-light.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';

const ThemeDisplay = ({ themeInPreview, showMenu, width }) => {
  const noHeaderFooter = themeInPreview.headerFooter.header.noHeader && themeInPreview.headerFooter.footer.noFooter && themeInPreview.headerFooter.footer.noISI;

  const { gridAlign, headerHeight, footerHeight } = calculateHF(noHeaderFooter, themeInPreview.headerFooter);

  return (
    <div
      style={{
        position: 'relative',
        width: `${width}px`,
        height: `${width * 1.9}px`,
        display: 'flex',
        alignItems: gridAlign ? gridAlign : 'center',
        backgroundColor: themeInPreview.colorAssignment.qooBackground ? themeInPreview.colorAssignment.qooBackground.hex : '#fff',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(6, ${width / 6}px)`,
          gridTemplateRows: `repeat(11, ${width / 6}px)`,
          fontSize: `${width / 308 * 16}px`
        }}
      >
        <div
          className="theme-preview__heading-container "
        >
          <h1
            style={{
              color: themeInPreview.colorAssignment.fontColor?.hex,
              fontFamily: themeInPreview.headingFont?.name,
              fontWeight: themeInPreview.headingFont?.weight,
              fontStyle: themeInPreview.headingFont?.style,
            }}
          >
            Heading lorem
          </h1>
        </div>
        <div className="theme-preview__subheading-container sub1">
          <BarsIcon
            style={{
              width: '3.3em',
              height: '3.3em'
            }}
          />
          <h2
            style={{
              color: themeInPreview.colorAssignment.fontColor?.hex,
              fontFamily: themeInPreview.subheadingFont?.name,
              fontWeight: themeInPreview.subheadingFont?.weight,
              fontStyle: themeInPreview.subheadingFont?.style,
            }}
          >Subheading</h2>
        </div>
        <div className="theme-preview__subheading-container sub2">
          <FlaskIcon
            style={{
              width: '3.3em',
              height: '3.3em'
            }}
          />
          <h2
            style={{
              color: themeInPreview.colorAssignment.fontColor?.hex,
              fontFamily: themeInPreview.subheadingFont?.name,
              fontWeight: themeInPreview.subheadingFont?.weight,
              fontStyle: themeInPreview.subheadingFont?.style,
            }}
          >Subheading</h2>
        </div>
        <div className="theme-preview__bodytext-container bod1">
          <p
            style={{
              color: themeInPreview.colorAssignment.fontColor?.hex,
              fontFamily: themeInPreview.bodyTextFont?.name,
              fontWeight: themeInPreview.bodyTextFont?.weight,
              fontStyle: themeInPreview.bodyTextFont?.style,
            }}
          >
            <strong>Body text </strong>
            <span>dolor sit amet, consectetur adipiscing elit, sed do </span>
          </p>
        </div>
        <div className="theme-preview__bodytext-container bod2">
          <p
            style={{
              color: themeInPreview.colorAssignment.fontColor?.hex,
              fontFamily: themeInPreview.bodyTextFont?.name,
              fontWeight: themeInPreview.bodyTextFont?.weight,
              fontStyle: themeInPreview.bodyTextFont?.style,
            }}
          >
            <strong>Body text </strong>
            <span>dolor sit amet, consectetur adipiscing elit, sed do </span>
          </p>
        </div>
        <div className="theme-preview__btn-container">
          <div className="theme-preview__btn"
            style={{
              color: themeInPreview.colorAssignment.buttonTextColor?.hex,
              backgroundColor: themeInPreview.colorAssignment.buttonColor?.hex || '#BDC7CF',
              fontFamily: themeInPreview.bodyTextFont?.name,
              fontWeight: themeInPreview.bodyTextFont?.weight,
              fontStyle: themeInPreview.bodyTextFont?.style,
            }}
          >
            Button
          </div>
        </div>
      </div>

      {!themeInPreview.headerFooter.header.noHeader && (
        <QooHeader
          headerHeight={headerHeight}
          header={themeInPreview.headerFooter.header}
          gridRowHeight={width / 12}
          gridWidth={width}
        />
      )}
      {(!themeInPreview.headerFooter.footer.noFooter || !themeInPreview.headerFooter.footer.noISI) && (
        <QooFooter
          footerHeight={footerHeight}
          footer={themeInPreview.headerFooter.footer}
          gridRowHeight={width / 12}
          gridWidth={width}
        />
      )}
      {showMenu &&
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            padding: '60px 24px',
            backgroundColor: themeInPreview.headerFooter.header.menuOverlayColor ? `rgba(${themeInPreview.headerFooter.header.menuOverlayColor.rgb.r}, ${themeInPreview.headerFooter.header.menuOverlayColor.rgb.g}, ${themeInPreview.headerFooter.header.menuOverlayColor.rgb.b}, ${themeInPreview.headerFooter.header.menuOverlayColor.rgb.a})` : '#F4F6F7',
            display: 'flex',
            flexDirection: 'column',
            gap: '36px',
            alignItems: themeInPreview.headerFooter.header.menuOverlayTextAlign,
            color: themeInPreview.headerFooter.header.menuOverlayTextColor?.hex,
            fontFamily: themeInPreview.headerFooter.header.menuOverlayFont?.name,
            fontWeight: themeInPreview.headerFooter.header.menuOverlayFont?.weight,
            fontStyle: themeInPreview.headerFooter.header.menuOverlayFont?.style,
            fontSize: themeInPreview.headerFooter.header.menuOverlayFontSize === 'S' ? '0.75em' : themeInPreview.headerFooter.header.menuOverlayFontSize === 'L' ? '1.5em' : '1em',
            zIndex: '2'
          }}
        >
          <span>Home</span>
          <span
            style={{
              color: themeInPreview.headerFooter.header.menuOverlayHighlightedTextColor?.hex ? themeInPreview.headerFooter.header.menuOverlayHighlightedTextColor?.hex : themeInPreview.headerFooter.header.menuOverlayTextColor?.hex
            }}
          >Contact</span>
          <span>References</span>
          <span>Prescription</span>
          <span>Menu item</span>
          <div
            style={{
              position: 'absolute',
              top: '8px',
              right: '8px'
            }}
          >
            <CloseIcon style={{ width: '24px', height: '24px' }} />
          </div>
        </div>
      }
    </div>
  );
};

export default ThemeDisplay;