import React from 'react';
import { Modal } from 'antd';
// import BlockDisplay from './BlockDisplay';
import QooDisplay from '../qoos/QooDisplay';

const BlockPhonePreviewModal = ({ visible, closeModal, block }) => {

  const wrapperQoo = {
    theme: block.theme,
    showHeader: true,
    showISI: true,
    showFooter: true,
    blocksLayout: [
      {
        block,
        x: block.column,
        y: block.row,
        w: block.size[1],
        h: block.size[0]
      }
    ]
  };

  return (
    <Modal
      visible={visible}
      centered
      closable
      onCancel={closeModal}
      footer={null}
      bodyStyle={{ padding: '0' }}
      destroyOnClose={true}
      width={612}
    >
      <div
        className="qoolection-preview-modal"
      >
        <h3 className="qoolection-preview-modal__title">{block.name}</h3>
        <div className="theme-preview">
          <div className="theme-preview__phone-container">
            <div className="theme-preview__phone">
              <div className="theme-preview__top-bar"></div>
              <div className="theme-preview__black-bg"></div>
              <div
                className="theme-preview__qoo-content"
              >
                <QooDisplay
                  qooInPreview={wrapperQoo}
                  width={308}
                  height={585}
                  fadeOutHF={true}
                />
                {/* <BlockDisplay
                  block={block}
                  width={308}
                  height={411}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

    </Modal>
  );
};

export default BlockPhonePreviewModal;