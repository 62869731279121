import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Layout, Input, Select, Modal } from 'antd';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/BlocksTable';
import { SERVER_URL } from '../../config';
import BlocksGrid from '../../components/grids/BlocksGrid';
import CustomSwitch from '../../components/elements/CustomSwitch';
import HeaderComponent from '../../components/base/HeaderComponent';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import _ from 'lodash';
import BlockHistoryModal from './BlockHistoryModal';
const { Option } = Select;

const TABLE_COLUMN_KEYS = ['_id', '__v'];
const statuses = [
  'draft',
  'waiting-approval',
  'in-approval',
  'approved',
  'change_required',
  'shared',
  'published',
  'retired'
];

const blockFormats = ['basic', 'dynamic', 'survey', 'form', 'graph', 'table'];

const Blocks = () => {
  const currentuser = useContext(UserContext);
  console.log('currentuser: ', currentuser);

  const [stateForRefetchingData, setStateForRefetchingData] = useState(1);
  const [blocks, fetchBlocks] = useAxios(
    '',
    [],
    currentuser.data.token,
    'get',
    stateForRefetchingData
  );
  console.log('blocks: ', blocks);
  const history = useHistory();
  const [listView, setListView] = useState(true);
  const [blockHistory, setBlockHistory] = useState([]);
  const [blockHistoryModalVisible, setBlockHistoryModalVisible] = useState(false);
  const [filters, setFilters] = useState(null);
  const [offset, setOffset] = useState(0);
  const [themes, fetchThemes] = useAxios('', [], currentuser.data.token, 'get');
  const [categories, fetchCategories] = useAxios('', [], currentuser.data.token, 'get');
  const [audiences, fetchAudiences] = useAxios('', [], currentuser.data.token, 'get');
  const [qoos, fetchQoos] = useAxios('', [], currentuser.data.token, 'get');
  const [qoolections, fetchQoolections] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    fetchThemes(`${SERVER_URL}/themes`, []);
    fetchCategories(`${SERVER_URL}/categories`, {});
    fetchAudiences(`${SERVER_URL}/audiences`, {});
    fetchQoos(`${SERVER_URL}/qoos`, {});
    fetchQoolections(`${SERVER_URL}/qoolections`, {});
  }, [fetchThemes, fetchCategories, fetchAudiences, fetchQoos, fetchQoolections]);

  const limit = 10;
  const isSuperAdmin = currentuser.data.role.some((item) => item === 'superadmin');
  useEffect(() => {
    let filter;
    if (filters) {
      const removedNullValues = Object.fromEntries(
        Object.entries(filters).filter(
          ([key, value]) => value != null && (!Array.isArray(value) || value.length !== 0)
        )
      );
      filter = JSON.stringify(removedNullValues);
    }
    const route = filters
      ? `${SERVER_URL}/blocks?filter=${filter}&offset=${offset * limit}&limit=${limit}&team=${
          currentuser.selectedTeam
        }&study=${currentuser.study}`
      : `${SERVER_URL}/blocks?offset=${offset * limit}&limit=${limit}&team=${
          currentuser.selectedTeam
        }&study=${currentuser.study}`;
    if ((!isSuperAdmin && currentuser.study) || isSuperAdmin) {
      fetchBlocks(route, [], currentuser.data.token, 'get', stateForRefetchingData);
    }
  }, [fetchBlocks, filters, offset, stateForRefetchingData, currentuser]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/blocks/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      // notification.success({
      //   message: 'Item is deleted.',
      //   placement: 'bottomRight',
      //   icon: notificationSuccess.icon,
      //   className: notificationSuccess.className,
      //   duration: notificationSuccess.duration,
      // });
      history.push('/admin');
      history.push('/admin/blocks');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const historyHandler = async (id) => {
    try {
      const blockHistory = await Axios.get(`${SERVER_URL}/history-block/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      setBlockHistory(blockHistory.data);
      setBlockHistoryModalVisible(true);
    } catch (err) {
      console.log(err);
    }
  };

  const historyRestore = async (id) => {
    try {
      const blockHistory = await Axios.put(
        `${SERVER_URL}/history-restore-block/${id}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      setBlockHistoryModalVisible(false);
      setStateForRefetchingData((previous) => previous + 1);
    } catch (err) {
      console.log(err);
    }
  };

  let columnKeys;
  if (blocks.data && blocks.data.items && blocks.data.items.length > 0) {
    const keys = Object.keys(blocks.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  const editHandler = (blockId) => {
    history.push('/admin/edit-block/' + blockId);
  };

  const handleChangeDebounced = _.debounce(async (e) => {
    setOffset(0);
    setFilters((previous) => ({ ...previous, name: e.target.value }));
  }, 500);

  const handleChange = (e) => {
    e.persist();
    handleChangeDebounced(e);
  };

  // useEffect(() => {
  //   const blockCategories = blocks?.data?.items.map(block => block.category);
  //   blockCategories?.map(blockCategory => {
  //     return blockCategory?.sort((blockCategoryA, blockCategoryB) => blockCategoryA?.name?.localeCompare(blockCategoryB?.name))
  //   })
  // }, [])

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}>
        <HeaderComponent text="Manage Blocks" />
        <div className="table-header">
          <CustomSwitch listView={listView} setListView={setListView} />
          <Input
            className="table-header__search"
            placeholder="Search"
            prefix={<SearchIcon />}
            onChange={(e) => handleChange(e)}
            allowClear={true}
          />
          <div className="table-header__grid-filters">
            <Select
              className="dropdown-small"
              placeholder="Filter by Theme"
              mode="multiple"
              value={filters?.theme || []}
              onChange={(value) => setFilters((previous) => ({ ...previous, theme: value }))}
              maxTagCount={1}
              showArrow
              // maxTagPlaceholder={ov => console.log(ov)}
            >
              {themes?.data?.items?.length > 0 &&
                themes.data.items.map((theme) => {
                  return (
                    <Select.Option key={theme._id} value={theme._id}>
                      {theme.name}
                    </Select.Option>
                  );
                })}
            </Select>
            <Select
              className="dropdown-small"
              placeholder="Filter by Type"
              mode="multiple"
              value={filters?.blockFormat || []}
              onChange={(value) => setFilters((previous) => ({ ...previous, blockFormat: value }))}
              maxTagCount={1}
              showArrow>
              {blockFormats &&
                blockFormats.map((blockFormat) => {
                  return (
                    <Select.Option key={blockFormat} value={blockFormat}>
                      {blockFormat}
                    </Select.Option>
                  );
                })}
            </Select>
            <Select
              className="dropdown-small"
              placeholder="Filter by Category"
              mode="multiple"
              value={filters?.category || []}
              onChange={(value) => setFilters((previous) => ({ ...previous, category: value }))}
              maxTagCount={1}
              showArrow>
              {categories?.data?.items?.length > 0 &&
                categories.data.items.map((category) => {
                  return (
                    <Select.Option key={category._id} value={category._id}>
                      {category.name}
                    </Select.Option>
                  );
                })}
            </Select>
            <Select
              className="dropdown-small"
              placeholder="Filter by Audience"
              mode="multiple"
              value={filters?.audience || []}
              onChange={(value) => setFilters((previous) => ({ ...previous, audience: value }))}
              maxTagCount={1}
              showArrow>
              {audiences?.data?.items?.length > 0 &&
                audiences.data.items.map((audience) => {
                  return (
                    <Select.Option key={audience._id} value={audience._id}>
                      {audience.name}
                    </Select.Option>
                  );
                })}
            </Select>
            <Select
              className="dropdown-small"
              placeholder="Filter by Status"
              mode="multiple"
              value={filters?.status || []}
              onChange={(value) => setFilters((previous) => ({ ...previous, status: value }))}
              maxTagCount={1}
              showArrow>
              {statuses.map((status) => {
                return (
                  <Select.Option key={status} value={status}>
                    {status}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        </div>

        {blocks.isLoading && (
          <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
        )}
        {!blocks.isLoading &&
          blocks.data &&
          blocks.data.items &&
          blocks.data.items.length > 0 &&
          listView && (
            <Table
              user={currentuser}
              data={blocks?.data?.items}
              deleteHandler={deleteDataHandler}
              historyHandler={historyHandler}
              editHandler={editHandler}
              columnKeys={columnKeys}
              title="Blocks"
              editPath="/admin/edit-block/"
              filters={filters}
              setFilters={setFilters}
              setOffset={setOffset}
              total={blocks?.data?.count}
              current={offset + 1}
              limit={limit}
              themes={themes}
              audiences={audiences}
              categories={categories}
              statuses={statuses}
              blockFormats={blockFormats}
            />
          )}
        {!blocks.isLoading &&
          blocks.data &&
          blocks.data.items &&
          blocks.data.items.length === 0 && <h2>NO DATA</h2>}
        {!blocks.isLoading &&
          blocks.data &&
          blocks.data.items &&
          blocks.data.items.length > 0 &&
          !listView && (
            <BlocksGrid
              data={blocks?.data?.items}
              deleteHandler={deleteDataHandler}
              editHandler={editHandler}
              historyHandler={historyHandler}
              columnKeys={columnKeys}
              title="Blocks"
              editPath="/admin/edit-block/"
              // selectCallback={(item) => history.push(`/admin/edit-block/${item._id}`)}
              setOffset={setOffset}
              total={blocks.data.count}
              current={offset + 1}
              qoos={qoos}
              qoolections={qoolections}
            />
          )}
      </div>
      {blockHistoryModalVisible && (
        <BlockHistoryModal
          blockHistory={blockHistory}
          blockHistoryModalVisible={blockHistoryModalVisible}
          setBlockHistoryModalVisible={setBlockHistoryModalVisible}
          onHistoryRestore={historyRestore}
        />
      )}
    </Layout>
  );
};

export default Blocks;
