import { Button, Modal, Form, Radio, Checkbox, notification, Spin } from 'antd';
import React, { useState } from 'react';
import Axios from 'axios';
import { notificationSuccess } from '../../config/notificationOptions';
import { ReactComponent as InfoIcon } from '../../icons/info-icon.svg';
import { ReactComponent as QooBoardImage } from '../../icons/qooboard.svg';
import { ReactComponent as QooFlowImage } from '../../icons/qooflow.svg';

const ExportQoolectionPdfModal = ({
  qoolections,
  loading,
  qoolection,
  visible,
  closeModal,
  SERVER_URL,
  currentuser,
  setLoading,
  approve,
  setStateForRefetchingData,
  veevaConnected
}) => {
  const [form] = Form.useForm();
  const [includeStoryboard, setIncludeStoryboard] = useState(true);
  const [includeSpecialQoos, setIncludeSpecialQoos] = useState(true);
  const [infoModalFor, setInfoModalFor] = useState('');
  const noISI = qoolection.theme.headerFooter.footer.noISI;
  const noMenu = qoolection.menuLinks.length === 0 ? true : false;
  const disableIncludeSpecialQoos = noISI === true && noMenu === true;

  const exportQoolectionPdf = async (pdfConfigurationData) => {
    const approveCheck = approve;
    pdfConfigurationData.qoolectionVersion = `${qoolection?.version?.major}.${qoolection?.version?.minor}.${qoolection?.version?.patch}`;

    pdfConfigurationData.qoolectionTitle = qoolection?.title;

    const todayDate = new Date();
    const dd = String(todayDate.getDate()).padStart(2, '0');
    const mm = String(todayDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = todayDate.getFullYear();
    const formattedTodayDate = mm + '/' + dd + '/' + yyyy;
    pdfConfigurationData.qoolectionLastChangeDate = formattedTodayDate;

    setLoading(true);
    if (veevaConnected === true) {
      try {
        const response = await Axios.post(
          `${SERVER_URL}/export-qoolection-to-veeva/${qoolection._id}/${currentuser?.data?.id}/${currentuser?.data?.client?._id}`,
          { pdfConfigurationData, approveCheck },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
        );
        if (response?.data?.responseStatus === 'SUCCESS') {
          setLoading(false);
          notification.success({
            message: `Sucessfuly uploaded pdf to veeva vault, document id in veeva is: ${response?.data?.id}`,
            placement: 'bottomRight',
            icon: notificationSuccess.icon,
            className: notificationSuccess.className,
            duration: 3
          });
        }
        if (response.data.responseStatus === 'FAILURE') {
          setLoading(false);
          notification.error({
            message: response?.data?.errors[0]?.message,
            placement: 'bottomRight',
            duration: 3
          });
        }
      } catch (err) {
        setLoading(false);
        notification.error({
          message: `Problem with exporting. ${err?.response?.data?.message}`,
          placement: 'bottomRight',
          duration: 3
        });
      }
    } else {
      try {
        const response = await Axios.post(
          `${SERVER_URL}/export-qoolection-to-pdf/${qoolection._id}`,
          { pdfConfigurationData, approveCheck },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
        );
        if (response.data && response.data.fileUrl) {
          setLoading(false);
          const filepath = response.data.fileUrl;
          window.open(filepath, '_blank');
        }
      } catch (error) {
        setLoading(false);
        notification.error({
          message: error?.response?.data?.message,
          placement: 'bottomRight'
        });
      }
    }
    closeModal();
    if (setStateForRefetchingData) {
      setStateForRefetchingData((previous) => previous + 1);
    }
  };

  const onSubmit = async (values) => {
    try {
      if (values.includeSpecialQoos === false) {
        values.specialQoosType = '';
      }
      if (values.includeStoryboard === false) {
        values.storyboardLayout = '';
      }
      exportQoolectionPdf(values);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleIncludeStoryboardChange = (e) => {
    setIncludeStoryboard(e.target.checked);
    if (!e.target.checked) {
      form.setFieldValue('storyboardLayout', '');
    }
  };
  const handleIncludeSpecialQoosChange = (e) => {
    setIncludeSpecialQoos(e.target.checked);
    if (!e.target.checked) {
      form.setFieldValue('specialQoosType', '');
    }
  };

  const formInitialValues = {
    includeStoryboard: true,
    storyboardLayout: 'qooboard',
    includeJourneyFlow: true,
    includeSpecialQoos: disableIncludeSpecialQoos ? false : true,
    specialQoosType: '',
    includeBrandLogo: true,
    includeVersionAndDate: true,
    includeCoverPage: true,
    includeNotes: true
  };

  return (
    <>
      <Modal
        visible={visible}
        centered
        closable
        onCancel={closeModal}
        footer={null}
        bodyStyle={{ padding: '0', position: 'relative' }}
        destroyOnClose={true}
        width={495}>
        {loading && (
          <Spin
            spinning={loading}
            size="large"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999
            }}></Spin>
        )}
        <div className="download-qoolection-modal">
          <h1 className="download-qoolection-modal__title">Export Qoolection Storyboard</h1>
          <p className="download-qoolection-modal__description">
            Customize your Qoolection Storyboard using the options below:
          </p>
          <Form
            form={form}
            onFinish={(values) => onSubmit(values)}
            layout="vertical"
            preserve={false}
            initialValues={formInitialValues}
            className="download-qoolection-modal__form">
            <Form.Item name="includeStoryboard" valuePropName="checked">
              <div className="download-qoolection-modal__row">
                <Checkbox
                  defaultChecked
                  onChange={handleIncludeStoryboardChange}
                  className="download-qoolection-modal__checkbox">
                  <span className="download-qoolection-modal__label">
                    Include Storyboard...
                    {/* <InfoIcon className='download-qoolection-modal__icon' /> */}
                  </span>
                </Checkbox>
              </div>
            </Form.Item>
            <Form.Item
              name="storyboardLayout"
              rules={[
                {
                  required: includeStoryboard ? true : false,
                  message: 'Please choose a Storyboard layout!'
                }
              ]}>
              <Radio.Group disabled={!includeStoryboard}>
                <div className="download-qoolection-modal__row">
                  <Radio
                    defaultChecked
                    value="qooboard"
                    className="download-qoolection-modal__checkbox">
                    <span className="download-qoolection-modal__label">
                      as a QooBoard
                      <InfoIcon
                        className="download-qoolection-modal__icon"
                        onClick={(event) => {
                          event.preventDefault();
                          setInfoModalFor('qooboard');
                        }}
                      />
                    </span>
                  </Radio>
                </div>
                <div className="download-qoolection-modal__row">
                  <Radio value="qooflow" className="download-qoolection-modal__checkbox">
                    <span className="download-qoolection-modal__label">
                      as a QooFlow
                      <InfoIcon
                        className="download-qoolection-modal__icon"
                        onClick={(event) => {
                          event.preventDefault();
                          setInfoModalFor('qooflow');
                        }}
                      />
                    </span>
                  </Radio>
                </div>
                {/* <div className='download-qoolection-modal__row'>
                  <Radio value='qoomap' className="download-qoolection-modal__checkbox">
                    <span className='download-qoolection-modal__label'>
                      as a QooMap
                      <InfoIcon className='download-qoolection-modal__icon' />
                    </span>
                  </Radio>
                </div> */}
              </Radio.Group>
            </Form.Item>
            <Form.Item name="includeJourneyFlow" valuePropName="checked">
              <div className="download-qoolection-modal__row">
                <Checkbox defaultChecked className="download-qoolection-modal__checkbox">
                  <span className="download-qoolection-modal__label">
                    Include Journey Flow
                    {/* <InfoIcon className='download-qoolection-modal__icon' /> */}
                  </span>
                </Checkbox>
              </div>
            </Form.Item>
            <Form.Item name="includeSpecialQoos" valuePropName="checked">
              <div className="download-qoolection-modal__row">
                <Checkbox
                  defaultChecked={!disableIncludeSpecialQoos}
                  onChange={handleIncludeSpecialQoosChange}
                  disabled={disableIncludeSpecialQoos}
                  className="download-qoolection-modal__checkbox">
                  <span className="download-qoolection-modal__label">
                    Include Special Qoos
                    <InfoIcon
                      className="download-qoolection-modal__icon"
                      onClick={(event) => {
                        event.preventDefault();
                        setInfoModalFor('includeSpecialQoos');
                      }}
                    />
                  </span>
                </Checkbox>
              </div>
            </Form.Item>
            {/* <Form.Item
              name="specialQoosType"
              rules={[
                {
                  required: includeSpecialQoos ? true : false,
                  message: 'Please choose how to include Special Qoos!',
                },
              ]}
            >
              <Radio.Group disabled={!includeSpecialQoos}>
                <div className='download-qoolection-modal__row'>
                  <Radio value='sectionOfStoryboard' className="download-qoolection-modal__checkbox">
                    <span className='download-qoolection-modal__label'>
                      as a section of the Storyboard
                      <InfoIcon className='download-qoolection-modal__icon' />
                    </span>
                  </Radio>
                </div>
                <div className='download-qoolection-modal__row'>
                  <Radio value='inTheStoryboard' className="download-qoolection-modal__checkbox">
                    <span className='download-qoolection-modal__label'>
                      in the Storyboard
                      <InfoIcon className='download-qoolection-modal__icon' />
                    </span>
                  </Radio>
                </div>
                <div className='download-qoolection-modal__row'>
                  <Radio value='standalonePage' className="download-qoolection-modal__checkbox">
                    <span className='download-qoolection-modal__label'>
                      on a stand-alone page
                      <InfoIcon className='download-qoolection-modal__icon' />
                    </span>
                  </Radio>
                </div>
              </Radio.Group>
            </Form.Item> */}
            <Form.Item name="includeBrandLogo" valuePropName="checked">
              <div className="download-qoolection-modal__row">
                <Checkbox defaultChecked className="download-qoolection-modal__checkbox">
                  <span className="download-qoolection-modal__label">
                    Include my Brand logo
                    {/* <InfoIcon className='download-qoolection-modal__icon' /> */}
                  </span>
                </Checkbox>
              </div>
            </Form.Item>
            <Form.Item name="includeVersionAndDate" valuePropName="checked">
              <div className="download-qoolection-modal__row">
                <Checkbox defaultChecked className="download-qoolection-modal__checkbox">
                  <span className="download-qoolection-modal__label">
                    Include Version and Date
                    {/* <InfoIcon className='download-qoolection-modal__icon' /> */}
                  </span>
                </Checkbox>
              </div>
            </Form.Item>
            <Form.Item name="includeCoverPage" valuePropName="checked">
              <div className="download-qoolection-modal__row">
                <Checkbox defaultChecked className="download-qoolection-modal__checkbox">
                  <span className="download-qoolection-modal__label">
                    Include a Cover page
                    {/* <InfoIcon className='download-qoolection-modal__icon' /> */}
                  </span>
                </Checkbox>
              </div>
            </Form.Item>
            <Form.Item name="includeNotes" valuePropName="checked">
              <div className="download-qoolection-modal__row">
                <Checkbox defaultChecked className="download-qoolection-modal__checkbox">
                  <span className="download-qoolection-modal__label">
                    Include Notes
                    {/* <InfoIcon className='download-qoolection-modal__icon' /> */}
                  </span>
                </Checkbox>
              </div>
            </Form.Item>

            <div className="download-qoolection-modal__buttons">
              <Button onClick={closeModal} className="download-qoolection-modal__button">
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="download-qoolection-modal__button">
                Export PDF
              </Button>
            </div>
            {/* end of  download-qoolection-modal__buttons */}
          </Form>
        </div>
        {/* end of  download-qoolection-modal */}
      </Modal>
      <Modal
        className="download-qoolection-modal__tooltip"
        visible={infoModalFor}
        confirmLoading
        centered
        maskClosable
        onCancel={() => setInfoModalFor(null)}
        footer={null}
        destroyOnClose={true}
        width={300}>
        <p className="download-qoolection-modal__tooltip-label">
          <InfoIcon />
          {infoModalFor === 'qooboard'
            ? 'QooBoard'
            : infoModalFor === 'qooflow'
            ? 'QooFlow'
            : infoModalFor === 'includeSpecialQoos'
            ? 'Include Special Qoos'
            : ''}
        </p>
        <span className="download-qoolection-modal__tooltip-image" style={{ color: '#333' }}>
          {infoModalFor === 'qooboard' ? (
            <QooBoardImage />
          ) : infoModalFor === 'qooflow' ? (
            <QooFlowImage />
          ) : infoModalFor === 'includeSpecialQoos' ? (
            'Special Qoos are Qoos that do not appear in the main sequence of the Qoolection but are accessible via other features. For example, they could be Qoos only accessible via a Footer link or a Menu item.'
          ) : (
            ''
          )}
        </span>
        {disableIncludeSpecialQoos && infoModalFor === 'includeSpecialQoos' && (
          <div style={{ color: '#FB5E5E', marginTop: '8px', fontSize: '12px' }}>
            This option is currently disabled because there are no Special Qoos present in this
            Qoolection.
          </div>
        )}
      </Modal>
    </>
  );
};

export default ExportQoolectionPdfModal;
