import {
  SELECT_BLOCK,
  EDIT_BLOCK_FIELD,
  EDIT_BLOCK_LAYOUT,
  EDIT_BLOCK_PROPERTY,
  EDIT_BLOCK_PROPERTY_WITHOUT_HISTORY,
  TEMPLATE_BLOCK_SELECTED,
  UNDO,
  REDO,
  ADD_BLOCK_TO_LAYOUT,
  ADD_BLOCK_TO_LAYOUT_WITH_RESIZE,
  EDIT_INNER_BLOCK_IN_LAYOUT,
  INNER_BLOCK_LAYOUT_CHANGE,
  INNER_BLOCK_LAYOUT_CHANGE_WITH_RESIZE,
  INNER_BLOCK_LAYOUT_CHANGE_WITH_RESIZE_WITHOUT_HISTORY,
  REMOVE_BLOCK_FROM_LAYOUT,
  REMOVE_BLOCK_FROM_LAYOUT_WITH_RESIZE,
  TOGGLE_HIDE_SHOW_LAYER,
  TOGGLE_HIDE_SHOW_BACKGROUND,
  MOVE_LAYER_UP,
  MOVE_LAYER_DOWN,
  SET_BLOCKS_LAYOUT,
  MOVE_LAYER_UP_WITH_ID,
  MOVE_LAYER_TO_FRONT,
  MOVE_LAYER_DOWN_WITH_ID,
  MOVE_LAYER_TO_BOTTOM,
  ADD_FORM_FIELD,
  REMOVE_FORM_FIELD,
  EDIT_FORM_FIELD,
  ADD_SURVEY_OPTION,
  REMOVE_SURVEY_OPTION,
  EDIT_SURVEY_OPTION,
  ADD_ENDNOTES,
  REMOVE_ENDNOTE,
  EDIT_ENDNOTE
} from './actionTypes';
import _ from 'lodash'

const blockInPreviewReducer = (state, action) => {
  switch (action.type) {
    case SELECT_BLOCK:
      return {
        blockInPreview: action.payload,
        blockInPreviewHistory: [],
        blockInPreviewFuture: []
      };
    case EDIT_BLOCK_LAYOUT:
    case EDIT_BLOCK_FIELD:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreview: { ...state.blockInPreview, ...action.payload },
        blockInPreviewFuture: []
      };
    case EDIT_BLOCK_PROPERTY:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreview: { ...state.blockInPreview, blockProperties: { ...state.blockInPreview.blockProperties, ...action.payload } },
        blockInPreviewFuture: []
      };
    case EDIT_BLOCK_PROPERTY_WITHOUT_HISTORY:
      return {
        blockInPreviewHistory: state.blockInPreviewHistory,
        blockInPreviewFuture: state.blockInPreviewFuture,
        blockInPreview: { ...state.blockInPreview, blockProperties: { ...state.blockInPreview.blockProperties, ...action.payload } }
      };
    case TEMPLATE_BLOCK_SELECTED:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: { ...state.blockInPreview, size: action.payload.size, blockProperties: { ...state.blockInPreview.blockProperties, ...action.payload.blockProperties } }
      };
    case UNDO:
      const oldHistory = [...state.blockInPreviewHistory];
      const newPresentForUndo = oldHistory.pop();

      return {
        blockInPreviewHistory: oldHistory,
        blockInPreview: newPresentForUndo,
        blockInPreviewFuture: [state.blockInPreview, ...state.blockInPreviewFuture]
      };
    case REDO:
      const oldFuture = [...state.blockInPreviewFuture];
      const newPresentForRedo = oldFuture.shift();

      return {
        blockInPreview: newPresentForRedo,
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: oldFuture
      };

    case ADD_BLOCK_TO_LAYOUT:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: [
            ...state.blockInPreview.blocksLayout,
            action.payload
          ]
        }
      };

    case ADD_BLOCK_TO_LAYOUT_WITH_RESIZE:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          size: action.payload.blockSize,
          blocksLayout: [
            ...state.blockInPreview.blocksLayout,
            action.payload.element
          ]
        }
      };

    case EDIT_INNER_BLOCK_IN_LAYOUT:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: state.blockInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockProperties: {
                    ...item.block.blockProperties,
                    ...action.payload.updates
                  }
                }
              };
            } else {
              return item;
            }
          })
        }
      };

    case INNER_BLOCK_LAYOUT_CHANGE:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: state.blockInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.i) {
              return {
                ...item,
                x: action.payload.x,
                y: action.payload.y,
                w: action.payload.w,
                h: action.payload.h
              };
            } else {
              return item;
            }
          })
        }
      };

    case INNER_BLOCK_LAYOUT_CHANGE_WITH_RESIZE:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          size: action.payload.blockSize,
          column: action.payload.column,
          row: action.payload.row,
          blocksLayout: state.blockInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.element.i) {
              return {
                ...item,
                x: action.payload.element.x,
                y: action.payload.element.y,
                w: action.payload.element.w,
                h: action.payload.element.h
              };
            } else {
              return {
                ...item,
                x: item.x + action.payload.correctionX,
                y: item.y + action.payload.correctionY,
              };
            }
          })
        }
      };

    case INNER_BLOCK_LAYOUT_CHANGE_WITH_RESIZE_WITHOUT_HISTORY:
      return {
        blockInPreviewHistory: state.blockInPreviewHistory,
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          size: action.payload.blockSize,
          column: action.payload.column,
          row: action.payload.row,
          blocksLayout: state.blockInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.element.i) {
              return {
                ...item,
                x: action.payload.element.x,
                y: action.payload.element.y,
                w: action.payload.element.w,
                h: action.payload.element.h
              };
            } else {
              return {
                ...item,
                x: item.x + action.payload.correctionX,
                y: item.y + action.payload.correctionY,
              };
            }
          })
        }
      };

    case REMOVE_BLOCK_FROM_LAYOUT:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: state.blockInPreview.blocksLayout.filter((item) => item.block._id !== action.payload._id)
        }
      };

    case REMOVE_BLOCK_FROM_LAYOUT_WITH_RESIZE:
      const newRefs = state.blockInPreview.endnotes.references
        .filter(reference => !(reference.elements.includes(action.payload._id) && reference.elements.length === 1))
        .map(reference => ({
          ...reference,
          elements: reference.elements.filter(element => element !== action.payload._id)
        }))
      const newFoots = state.blockInPreview.endnotes.footnotes
        .filter(footnote => !(footnote.elements.includes(action.payload._id) && footnote.elements.length === 1))
        .map(footnote => ({
          ...footnote,
          elements: footnote.elements.filter(element => element !== action.payload._id)
        }))
      const newAbbrs = state.blockInPreview.endnotes.abbreviations
        .filter(abbreviation => !(abbreviation.elements.includes(action.payload._id) && abbreviation.elements.length === 1))
        .map(abbreviation => ({
          ...abbreviation,
          elements: abbreviation.elements.filter(element => element !== action.payload._id)
        }))

      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          size: action.payload.blockSize,
          column: action.payload.column,
          row: action.payload.row,
          blocksLayout: state.blockInPreview.blocksLayout
            .map((item) => ({
              ...item,
              x: item.x + action.payload.correctionX,
              y: item.y + action.payload.correctionY,
            }))
            .filter((item) => item.block._id !== action.payload._id),
          endnotes: {
            references: newRefs,
            footnotes: newFoots,
            abbreviations: newAbbrs
          }
        }
      };

    case TOGGLE_HIDE_SHOW_LAYER:
      return {
        blockInPreviewHistory: state.blockInPreviewHistory,
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: state.blockInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  hidden: !item.block.hidden
                }
              };
            } else {
              return item;
            }
          })
        }
      };
    case TOGGLE_HIDE_SHOW_BACKGROUND:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          hidden: !state.blockInPreview.hidden,
        }
      };
    case MOVE_LAYER_UP:
      const tempArrForMovingUp = [...state.blockInPreview.blocksLayout];
      const layerToMoveUp = tempArrForMovingUp.splice(action.payload.index, 1);
      tempArrForMovingUp.splice(action.payload.index + 1, 0, ...layerToMoveUp);

      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: tempArrForMovingUp
        }
      };
    case MOVE_LAYER_UP_WITH_ID:
      const tempArrForMovingUpWithId = [...state.blockInPreview.blocksLayout];
      const indexOfLayerToMoveUpWithId = tempArrForMovingUpWithId.findIndex(item => item.block._id === action.payload.blockId);
      const layerToMoveUpWithId = tempArrForMovingUpWithId.splice(indexOfLayerToMoveUpWithId, 1);
      tempArrForMovingUpWithId.splice(indexOfLayerToMoveUpWithId + 1, 0, ...layerToMoveUpWithId);

      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: tempArrForMovingUpWithId
        }
      };

    case MOVE_LAYER_DOWN:
      const tempArrForMovingDown = [...state.blockInPreview.blocksLayout];
      const layerToMoveDown = tempArrForMovingDown.splice(action.payload.index, 1);
      tempArrForMovingDown.splice(action.payload.index - 1, 0, ...layerToMoveDown);

      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: tempArrForMovingDown
        }
      };

    case MOVE_LAYER_DOWN_WITH_ID:
      const tempArrForMovingDownWithId = [...state.blockInPreview.blocksLayout];
      const indexOfLayerToMoveDown = tempArrForMovingDownWithId.findIndex(item => item.block._id === action.payload.blockId);
      const layerToMoveDownWithId = tempArrForMovingDownWithId.splice(indexOfLayerToMoveDown, 1);
      tempArrForMovingDownWithId.splice(indexOfLayerToMoveDown - 1, 0, ...layerToMoveDownWithId);

      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: tempArrForMovingDownWithId
        }
      };

    case SET_BLOCKS_LAYOUT:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: action.payload.newBlocksLayout
        }
      };

    case MOVE_LAYER_TO_FRONT:
      const tempArrForMovingToFront = [...state.blockInPreview.blocksLayout];
      const indexOfLayerToMoveToFront = tempArrForMovingToFront.findIndex(item => item.block._id === action.payload.blockId);
      const layerToMoveToFront = tempArrForMovingToFront.splice(indexOfLayerToMoveToFront, 1);
      const newArrFormovingToFront = [...tempArrForMovingToFront, ...layerToMoveToFront];

      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: newArrFormovingToFront
        }
      };

    case MOVE_LAYER_TO_BOTTOM:
      const tempArrForMovingToBottom = [...state.blockInPreview.blocksLayout];
      const indexOfLayerToMoveToBottom = tempArrForMovingToBottom.findIndex(item => item.block._id === action.payload.blockId);
      const layerToMoveToBottom = tempArrForMovingToBottom.splice(indexOfLayerToMoveToBottom, 1);
      const newArrFormovingToBottom = [...layerToMoveToBottom, ...tempArrForMovingToBottom];

      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: newArrFormovingToBottom
        }
      };

    case ADD_FORM_FIELD:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: state.blockInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockProperties: {
                    ...item.block.blockProperties,
                    formFields: [
                      ...item.block.blockProperties.formFields,
                      action.payload.formField
                    ]
                  }
                }
              };
            } else {
              return item;
            }
          })

        }
      };

    case EDIT_FORM_FIELD:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: state.blockInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockProperties: {
                    ...item.block.blockProperties,
                    formFields: item.block.blockProperties.formFields.map((formField, index) => {
                      if (index === action.payload.index) {
                        return {
                          ...formField,
                          required: action.payload.required,
                        };
                      } else {
                        return formField;
                      }
                    })
                  }
                }
              };
            } else {
              return item;
            }
          })
        }
      };

    case REMOVE_FORM_FIELD:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: state.blockInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockProperties: {
                    ...item.block.blockProperties,
                    formFields: item.block.blockProperties.formFields.filter((formField, index) => index !== action.payload.index)
                  }
                }
              };
            } else {
              return item;
            }
          })
        }
      };

    case ADD_SURVEY_OPTION:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: state.blockInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockProperties: {
                    ...item.block.blockProperties,
                    surveyOptions: [
                      ...item.block.blockProperties.surveyOptions,
                      action.payload.surveyOption
                    ]
                  }
                }
              };
            } else {
              return item;
            }
          })

        }
      };

    case REMOVE_SURVEY_OPTION:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: state.blockInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockProperties: {
                    ...item.block.blockProperties,
                    surveyOptions: item.block.blockProperties.surveyOptions.filter((surveyOption, index) => index !== action.payload.index)
                  }
                }
              };
            } else {
              return item;
            }
          })
        }
      };

    case EDIT_SURVEY_OPTION:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          blocksLayout: state.blockInPreview.blocksLayout.map((item) => {
            if (item.block._id === action.payload.blockId) {
              return {
                ...item,
                block: {
                  ...item.block,
                  blockProperties: {
                    ...item.block.blockProperties,
                    surveyOptions: item.block.blockProperties.surveyOptions.map((surveyOption, index) => {
                      if (index === action.payload.index) {
                        return action.payload.value;
                      } else {
                        return surveyOption;
                      }
                    })
                  }
                }
              };
            } else {
              return item;
            }
          })
        }
      };

    case ADD_ENDNOTES:
      let newEndnotesArray = state.blockInPreview.endnotes[action.payload.endnoteType].map(endnote => {
        if (action.payload.endnotes.some(addingEndnote => addingEndnote._id === endnote.item._id)) {
          return {
            ...endnote,
            elements: _.uniq([...endnote.elements, action.payload.blockId])
          }
        } else {
          return endnote
        }
      })
      
      action.payload.endnotes.forEach(endnote => {
        if (!newEndnotesArray.some(existingEndnote => existingEndnote.item._id === endnote._id)) {
          newEndnotesArray.push({
            item: endnote,
            elements: [action.payload.blockId]
          })
        }
      })

      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          endnotes: {
            ...state.blockInPreview.endnotes,
            [`${action.payload.endnoteType}`]: newEndnotesArray
          }
        }
      }

    case REMOVE_ENDNOTE:
      const existingElements = state.blockInPreview.endnotes[action.payload.endnoteType].find(item => item.item._id === action.payload.endnoteId).elements
      const removeAll = _.isEqual(existingElements, action.payload.elements)

      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          endnotes: {
            ...state.blockInPreview.endnotes,
            [`${action.payload.endnoteType}`]: removeAll 
              ? state.blockInPreview.endnotes[action.payload.endnoteType].filter(endnote => endnote.item._id !== action.payload.endnoteId)
              : state.blockInPreview.endnotes[action.payload.endnoteType].map(endnote => {
                if (endnote.item._id === action.payload._id) {
                  return {
                    ...endnote,
                    elements: endnote.elements.filter(element => !action.payload.elements.includes(element))
                  }
                } else {
                  return endnote
                }
              })
          }
            
        }
      };

    case EDIT_ENDNOTE:
      return {
        blockInPreviewHistory: [...state.blockInPreviewHistory, state.blockInPreview],
        blockInPreviewFuture: [],
        blockInPreview: {
          ...state.blockInPreview,
          endnotes: {
            ...state.blockInPreview.endnotes,
            [`${action.payload.endnoteType}`]: state.blockInPreview.endnotes[action.payload.endnoteType].map(endnote => {
              if (endnote.item._id === action.payload.endnote._id) {
                return {
                  ...endnote,
                  item: action.payload.endnote
                };
              } else {
                return endnote;
              }
            })
          }
        }
      };

    default:
      return state;
  }
};

export default blockInPreviewReducer;
