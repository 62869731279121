import React from 'react';
import { Button } from 'antd';
import StepNavigation from './StepNavigation';
import FooterTab from './FooterTab';

const NewThemeStep7 = ({ step, setStep, themeInPreview, dispatch, completedStep, setCompletedStep }) => {

  return (
    <div className="new-theme-builder__step-7">
      <h2 className="new-theme-builder__step-subtitle">
        <span>Step 7: </span>
        <span>Let's customize your Footer. Please use the toggles below
          to customize the appearance of your Footer.</span>
      </h2>
      <StepNavigation
        step={step}
        setStep={setStep}
        completedStep={completedStep}
      />
      <FooterTab
        themeInPreview={themeInPreview}
        dispatch={dispatch}
      />

      <div className="new-theme-builder__bottom-row">
        <Button
          className='new-theme-builder__step-btn'
          onClick={() => setStep(6)}
        >
          Previous step
        </Button>
        <Button
          className='new-theme-builder__step-btn'
          type='primary'
          onClick={() => {
            if (completedStep === 6) {
              setCompletedStep(7);
            }
            setStep(8);
          }}
        >
          Next step
        </Button>
      </div>
    </div>
  );
};

export default NewThemeStep7;