import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification, Layout, Input, Select, Modal } from 'antd';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import useAxios from '../../hooks/useAxios';
import ReferencesTable from '../../components/tables/ReferencesTable';
import { SERVER_URL } from '../../config';
import Reference from '../../components/endnotes/Reference';

const TABLE_COLUMN_KEYS = ['_id', '__v'];

const References = ({ setStateForRefetchingReferences, references }) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [searchFilter, setSearchFilter] = useState('');
  const [referenceToEdit, setReferenceToEdit] = useState();
  const [source, setSource] = useState('');

  let filteredReferences;

  if (references?.data?.items && references?.data?.items?.length > 0) {
    filteredReferences = references.data.items.filter((reference) => {
      const textMatch = searchFilter
        ? reference?.reference?.toLowerCase().includes(searchFilter.toLowerCase())
        : true;
      return textMatch;
    });
  }

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/references/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      setStateForRefetchingReferences((prev) => prev + 1);
      // history.push('/admin');
      // history.push('/admin/library');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const editDataHandler = async (id, updates) => {
    try {
      await Axios.put(`${SERVER_URL}/references/${id}`, updates, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item updated.',
        placement: 'bottomRight',
        icon: notificationSuccess.icon,
        className: notificationSuccess.className,
        duration: notificationSuccess.duration
      });
      history.push('/admin');
      history.push('/admin/library');
    } catch (err) {
      notification.error({
        message: 'Problem with update. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  let columnKeys;
  if (references.data && references.data.items && references.data.items.length > 0) {
    const keys = Object.keys(references.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (references.data && references.data.items && references.data.items.length > 0) {
    tableData = references.data.items;
  }
  if (filteredReferences && filteredReferences.length > 0) {
    tableData = filteredReferences;
  }

  console.log('referenceToEdit: ', referenceToEdit);

  useEffect(() => {
    if (referenceToEdit) {
      setSource(referenceToEdit.source);
    }
  }, [referenceToEdit]);

  return (
    <>
      <div className="table-header">
        <Input
          className="table-header__search"
          placeholder="Search References"
          prefix={<SearchIcon />}
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
      </div>
      <ReferencesTable
        data={tableData}
        deleteHandler={deleteDataHandler}
        // editHandler={editDataHandler}
        // editPath='/admin/edit-block/'
        columnKeys={columnKeys}
        title="References"
        setReferenceToEdit={setReferenceToEdit}
      />
      {referenceToEdit && (
        <Modal
          title="Edit Reference"
          open={referenceToEdit}
          footer={null}
          onCancel={() => setReferenceToEdit(null)}>
          <Reference
            token={currentuser.data.token}
            setStateForRefetchingReferences={setStateForRefetchingReferences}
            endnoteForEdit={referenceToEdit}
            setReferenceToEdit={setReferenceToEdit}
            source={referenceToEdit.source}
            library
          />
        </Modal>
      )}
    </>
  );
};

export default References;
