import React from 'react';
import { Tooltip } from 'antd';
import { ReactComponent as Plus } from '../../icons/plus.svg';

const BlockListGrid = ({ blocks, blockType, handleClick, handleNew }) => {
  return (
    <div className="block-templates__grid">
      {blockType.name !== 'button' && blockType.name !== 'icon' && blockType.name !== 'divider' && (
        <div className="block-templates__add-btn" onClick={() => handleNew()}>
          <Plus />
          <span>Add empty</span>
          <span>block</span>
        </div>
      )}
      {blocks &&
        blocks.length > 0 &&
        blocks.map((block, index) => {
          return (
            <Tooltip key={index} title={block.name} color="#4962E2">
              <div
                className="block-templates__template"
                style={{
                  gridRow: `auto / span ${block.size[0]}`,
                  gridColumn: `auto / span ${block.size[1]}`
                }}
                onClick={() =>
                  handleClick(block._id, {
                    size: block.size,
                    blockProperties: block.blockProperties
                  })
                }>
                {block.blockProperties && block.blockProperties.textContent && (
                  <div
                    style={{
                      fontSize: `${(140 / 570) * 16}px`,
                      paddingTop: (140 / 570) * block.blockProperties.blockPaddingTop,
                      paddingRight: (140 / 570) * block.blockProperties.blockPaddingRight,
                      paddingBottom: (140 / 570) * block.blockProperties.blockPaddingBottom,
                      paddingLeft: (140 / 570) * block.blockProperties.blockPaddingLeft,
                      lineHeight: 'initial'
                    }}
                    dangerouslySetInnerHTML={{ __html: block.blockProperties.textContent }}></div>
                )}
                {blockType &&
                  blockType.name &&
                  blockType.name === 'divider' &&
                  block.blockProperties && (
                    <div
                      className="divider-container"
                      style={{
                        paddingTop: (140 / 570) * block.blockProperties.blockPaddingTop,
                        paddingRight: (140 / 570) * block.blockProperties.blockPaddingRight,
                        paddingBottom: (140 / 570) * block.blockProperties.blockPaddingBottom,
                        paddingLeft: (140 / 570) * block.blockProperties.blockPaddingLeft
                      }}>
                      <div
                        style={{
                          width: '100%',
                          borderBottom: `${block.blockProperties.dividerThickness} ${block.blockProperties.dividerType} ${block.blockProperties.dividerColor}`,
                          alignSelf:
                            block.blockProperties.dividerAlignPosition === 'center'
                              ? 'center'
                              : block.blockProperties.dividerAlignPosition === 'top'
                              ? 'flex-start'
                              : 'flex-end'
                        }}></div>
                    </div>
                  )}
                {blockType &&
                  blockType.name &&
                  blockType.name === 'button' &&
                  block.blockProperties && (
                    <div
                      style={{
                        fontSize: `${(140 / 570) * 16}px`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%'
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: `${block.blockProperties.buttonWidth || 75}%`,
                          height: `${block.blockProperties.buttonHeight || 75}%`,
                          backgroundColor: block.blockProperties.buttonBackgroundColor?.hex,
                          color: block.blockProperties.buttonTextColor,
                          border: `${block.blockProperties.buttonBorderThickness} ${block.blockProperties.buttonBorderType} ${block.blockProperties.buttonBorderColor.hex}`,
                          borderRadius: block.blockProperties.buttonBorderRadius,
                          boxShadow: block.blockProperties.buttonShadowColor
                            ? `
                            ${block.blockProperties.buttonInnerShadow ? 'inset' : ''}
                            ${block.blockProperties.buttonShadowX} 
                            ${block.blockProperties.buttonShadowY}
                            ${block.blockProperties.buttonShadowBlur}
                            rgba(${block.blockProperties.buttonShadowColor.rgb.r}, ${
                                block.blockProperties.buttonShadowColor.rgb.g
                              }, ${block.blockProperties.buttonShadowColor.rgb.b}, ${
                                block.blockProperties.buttonShadowOpacity
                              })
                          `
                            : ''
                        }}>
                        <div
                          className="text-content-container"
                          dangerouslySetInnerHTML={{
                            __html: block.blockProperties.buttonText
                          }}></div>
                      </div>
                    </div>
                  )}
              </div>
            </Tooltip>
          );
        })}
    </div>
  );
};

export default BlockListGrid;
