import React, { useContext, useEffect, useState, useReducer } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { LayoutContext } from '../../components/base/Layout';
import {
  Button,
  notification,
  Layout,
  Collapse,
  InputNumber,
  Popconfirm,
  Switch,
  Slider,
  Checkbox,
  Radio,
  Popover,
  Upload,
  Row,
  Col,
  Modal
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Input, Select, Dropdown } from 'antd';
import BlockBuilder from './BlockBuilder';
import BlockTypesList from '../../components/elements/BlockTypesList';
import blockInPreviewReducer from '../../reducers/block-in-preview/blockInPreviewReducer';
import {
  SELECT_BLOCK,
  EDIT_BLOCK_FIELD,
  EDIT_BLOCK_LAYOUT,
  EDIT_BLOCK_PROPERTY,
  EDIT_BLOCK_PROPERTY_WITHOUT_HISTORY,
  TEMPLATE_BLOCK_SELECTED,
  UNDO,
  REDO,
  ADD_BLOCK_TO_LAYOUT,
  EDIT_INNER_BLOCK_IN_LAYOUT,
  REMOVE_BLOCK_FROM_LAYOUT,
  ADD_FORM_FIELD,
  EDIT_FORM_FIELD,
  REMOVE_FORM_FIELD,
  ADD_SURVEY_OPTION,
  REMOVE_SURVEY_OPTION,
  EDIT_SURVEY_OPTION,
  ADD_BLOCK_TO_LAYOUT_WITH_RESIZE,
  REMOVE_BLOCK_FROM_LAYOUT_WITH_RESIZE,
  ADD_ENDNOTES,
  EDIT_ENDNOTE
} from '../../reducers/block-in-preview/actionTypes';
import BlockTemplatesList from './BlockTemplatesList';
import HeaderComponent from '../../components/base/HeaderComponent';
import HeaderInner from '../../components/elements/HeaderInner';
import SideBarInner from '../../components/elements/SideBarInner';
import DeleteModal from '../../components/elements/DeleteModal';
import { ReactComponent as Save } from '../../icons/save.svg';
import { ReactComponent as Close } from '../../icons/close.svg';
import { ReactComponent as Phone } from '../../icons/ph_device-mobile-speaker.svg';
import { ReactComponent as UndoIcon } from '../../icons/ph_arrow-u-up-left-light.svg';
import { ReactComponent as RedoIcon } from '../../icons/ph_arrow-u-up-right-light.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../icons/ph_minus.svg';
import { ReactComponent as AlignTopIcon } from '../../icons/ph_align-top-light.svg';
import { ReactComponent as AlignBottomIcon } from '../../icons/ph_align-bottom-light.svg';
import { ReactComponent as AlignLeftIcon } from '../../icons/ph_align-left-light.svg';
import { ReactComponent as AlignRightIcon } from '../../icons/ph_align-right-light.svg';
import { ReactComponent as InfoIcon } from '../../icons/info-icon.svg';
import { ReactComponent as BtnWidthIcon } from '../../icons/btn-width.svg';
import { ReactComponent as BtnHeightIcon } from '../../icons/btn-height.svg';
import { ReactComponent as LinkWebsite } from '../../icons/link-website.svg';
import { ReactComponent as LinkSearch } from '../../icons/link-search.svg';
import { ReactComponent as LinkEmail } from '../../icons/link-email.svg';
import { ReactComponent as LinkPhone } from '../../icons/link-phone.svg';
import { ReactComponent as TextIcon } from '../../icons/ph_text-t.svg';
import { ReactComponent as EmailIcon } from '../../icons/ph_envelope-simple.svg';
import { ReactComponent as TextAreaIcon } from '../../icons/ph_selection-all.svg';
import { ReactComponent as CheckboxIcon } from '../../icons/fluent_checkbox-checked-24-regular.svg';
import { ReactComponent as CheckboxGreyIcon } from '../../icons/fluent_checkbox-checked-24-regular-grey.svg';
import { ReactComponent as PlusIconButton } from '../../icons/ph_plus.svg';
import { ReactComponent as AlignCenterIcon } from '../../icons/ph_align-left-center.svg';
import { ReactComponent as DownCaret } from '../../icons/down-caret.svg';
import { ReactComponent as RadioIcon } from '../../icons/radix-icons_radiobutton.svg';
import { ReactComponent as DeleteIcon } from '../../icons/close-small.svg';
import { ReactComponent as MediaIcon } from '../../icons/block-types-icons/image-square-light.svg';
import { ReactComponent as PlayIcon } from '../../icons/ph_play-light.svg';
import { ReactComponent as LinkIcon } from '../../icons/link.svg';
import {
  addBlockData,
  buttonTemplates,
  dividerTemplates,
  formTemplates,
  surveyTemplates
} from './addBlockData';
import ColorPickerDropdown from '../../components/elements/ColorPickerDropdown';
import ImageListMedia from './ImageListMedia';
import CompliancePanel from './CompliancePanel';
import GridSizePopup from '../../components/elements/GridSizePopup';
import IconsLibrary from './IconsLibrary';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import FormField from './FormField';
import FormTemplate from '../../components/elements/FormTemplate';
import BgImagesList from './BgImagesList';
import VideoList from './VideoList';
import ImageCropModal from './ImageCropModal';
import VideoCropModal from './VideoCropModal';
import Shapes from '../../components/elements/Shapes';
import _ from 'lodash';
import Endnotes from './Endnotes';
import BlockDisplay from './BlockDisplay';
import TemplateModal from './TemplateModal';
import EndnoteModal from './EndnoteModal';
import ThemeModal from '../../components/elements/ThemeModal';

const { Panel } = Collapse;
const { Content } = Layout;

const EditBlock = (props) => {
  const initState = {
    blockInPreview: null,
    blockInPreviewHistory: [],
    blockInPreviewFuture: []
  };

  // const sliderRef = useRef();

  const [state, dispatch] = useReducer(blockInPreviewReducer, initState);
  const { blockInPreview, blockInPreviewHistory, blockInPreviewFuture } = state;

  const [selectedBlock, setSelectedBlock] = useState(null);

  const currentuser = useContext(UserContext);
  const layoutContext = useContext(LayoutContext);
  const history = useHistory();
  const [block, fetchBlock] = useAxios('', {}, currentuser.data.token, 'get');
  const [audiences, fetchAudiences] = useAxios('', {}, currentuser.data.token, 'get');
  const [categories, fetchCategories] = useAxios('', {}, currentuser.data.token, 'get');
  const [activeTheme, fetchActiveTheme] = useAxios('', {}, currentuser.data.token, 'get');
  const [userThemes, fetchUserThemes] = useAxios('', {}, currentuser.data.token, 'get');
  const [allBlockTypes, fetchAllBlockTypes] = useAxios('', {}, currentuser.data.token, 'get');
  const [templates, fetchTemplates] = useAxios('', {}, currentuser.data.token, 'get');
  const [blocks, fetchBlocks] = useAxios('', {}, currentuser.data.token, 'get');
  const [selectedBlockType, setSelectedBlockType] = useState({ name: 'browse' });
  const [previewType, setPreviewType] = useState('block');
  const [selectedPaddingSide, setSelectedPaddingSide] = useState('textPaddingTop');
  const [backgroundImageOpen, setBackgroundImageOpen] = useState(false);
  const [selectedBtnDimension, setSelectedBtnDimension] = useState('buttonWidth');
  const [advancedMode, setAdvancedMode] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedPaddingSideForm, setSelectedPaddingSideForm] = useState('formPaddingTop');
  const [selectedFormFieldElement, setSelectedFormFieldElement] = useState(null);
  const [formFieldName, setFormFieldName] = useState('');
  const [mediaType, setMediaType] = useState('image');
  const [editImageOption, setEditImageOption] = useState();
  const [imageCropModalVisible, setImageCropModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [videoCropModalVisible, setVideoCropModalVisible] = useState(false);
  const [mediaOrientation, setMediaOrientation] = useState();
  const [myWorkOrTemplates, setMyWorkOrTemplates] = useState('my-work');
  const [sendingTemplateData, setSendingTemplateData] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState(undefined);
  const [audienceFilter, setAudienceFilter] = useState(undefined);
  const [blockTypeFilter, setBlockTypeFilter] = useState(undefined);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [addingEndnote, setAddingEndnote] = useState(null);
  const [endnoteForEdit, setEndnoteForEdit] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [activeThemes, setActiveThemes] = useState([]);
  const iconFetchUrl = process.env.REACT_APP_PROD_URL;
  const { selectedTeam, study } = currentuser;

  const initGridOptions = {
    style: 'dots',
    color: {
      hex: '#BDC7CF',
      rgb: { r: 189, g: 199, b: 207, a: 1 }
    },
    size: '6x8',
    previewDevice: 'iphonex'
  };
  const [gridOptions, setGridOptions] = useState(initGridOptions);

  const [newBlockForm] = Form.useForm();

  const { blockId } = props.match.params;
  const isNew = blockId ? false : true;
  const isCreateNew = props.location.state?.isCreateNew;
  const isSuperAdmin = currentuser.data.role.some((item) => item === 'superadmin');
  const selectBlock = (block) => {
    let column = 1;
    let row = 2;

    if (column + block.size[1] > 12) column += 12 - block.size[1] - column;
    if (row + block.size[0] > 16) row += 16 - block.size[0] - row;

    dispatch({
      type: SELECT_BLOCK,
      payload: {
        ...block,
        column,
        row
      }
    });
  };

  useEffect(() => {
    if (blockId) fetchBlock(`${SERVER_URL}/blocks/${blockId}`, {});
  }, [fetchBlock, blockId]);

  useEffect(() => {
    fetchCategories(`${SERVER_URL}/categories`, {});
    fetchAudiences(`${SERVER_URL}/audiences`, {});
    fetchAllBlockTypes(`${SERVER_URL}/block-types`, {});
    if ((!isSuperAdmin && currentuser.study) || isSuperAdmin) {
      fetchUserThemes(
        `${SERVER_URL}/get-user-themes/${currentuser.data.id}?team=${currentuser.selectedTeam}&study=${currentuser.study}`,
        {}
      );
      fetchActiveTheme(
        `${SERVER_URL}/get-active-theme/${currentuser.data.id}?team=${currentuser.selectedTeam}&study=${currentuser.study}`,
        {}
      );
    }
  }, [
    fetchCategories,
    fetchAudiences,
    fetchAllBlockTypes,
    fetchActiveTheme,
    fetchUserThemes,
    currentuser
  ]);

  useEffect(() => {
    if (block && block.data && block.data.name) {
      newBlockForm.setFieldsValue({
        name: block.data.name,
        category: block.data.category,
        audience: block.data.audience,
        notes: block.data.notes
      });
    }
  }, [block, newBlockForm]);

  useEffect(() => {
    Axios.get(`${SERVER_URL}/users/${currentuser.data.id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` }
    }).then((resp) => {
      setActiveThemes(resp.data.activeThemes || []);
    });
  }, [currentuser]);

  useEffect(() => {
    if (block && block.data && block.data.blockType) {
      setSelectedBlockType(block.data.blockType);
    }
  }, [block, setSelectedBlockType]);

  useEffect(() => {
    if (block && block.data && block.data.name) {
      selectBlock(block.data);
    }
  }, [block]);

  useEffect(() => {
    if (!isNew) {
      currentuser.setIsNavMenuCollapsed(true);
    }
  }, []);

  useEffect(() => {
    const filters = {
      name: searchFilter,
      category: categoryFilter,
      audience: audienceFilter,
      blockFormat: blockTypeFilter,
      ownId: myWorkOrTemplates === 'templates' ? null : blockInPreview?._id
    };
    const removedNullValues = Object.fromEntries(
      Object.entries(filters).filter(
        ([key, value]) => value != null && (!Array.isArray(value) || value.length !== 0)
      )
    );
    const filter = JSON.stringify(removedNullValues);
    let route =
      myWorkOrTemplates === 'templates'
        ? `${SERVER_URL}/blocks-templates?filter=${filter}`
        : `${SERVER_URL}/blocks`;
    if (myWorkOrTemplates === 'templates') {
      fetchTemplates(route, []);
    } else if (myWorkOrTemplates === 'my-work') {
      if (!isSuperAdmin && currentuser.study) {
        route += `?filter=${filter}&team=${currentuser.selectedTeam}&study=${currentuser.study}`;
        fetchBlocks(route, []);
      } else if (isSuperAdmin) {
        fetchBlocks(route, []);
      }
    }
  }, [
    myWorkOrTemplates,
    searchFilter,
    categoryFilter,
    audienceFilter,
    blockTypeFilter,
    currentuser
  ]);

  const onSubmit = async (formData, isNew, isFinal) => {
    const { selectedTeam, study } = currentuser;
    const submitData = isNew
      ? {
          ...formData,
          status: 'draft',
          isMulti: true,
          theme: activeTheme.data,
          team: selectedTeam,
          study
        }
      : { ...formData, team: selectedTeam, study };
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/blocks` : `${SERVER_URL}/blocks/${blockId}`;
    try {
      const response = await Axios[method](route, submitData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      if (!isNew) {
        notification.success({
          message: 'Block updated.',
          placement: 'bottomRight',
          icon: notificationSuccess.icon,
          className: notificationSuccess.className,
          duration: notificationSuccess.duration
        });
        layoutContext.setEditInProgress(false);
      }

      if (isFinal) {
        history.push('/admin/blocks');
      } else {
        history.push('/admin/blocks');
        history.push(`/admin/edit-block/${response.data.item._id}`, { isCreateNew: true });
      }
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  useEffect(() => {
    if (isNew && activeTheme.data && Object.keys(activeTheme.data).length !== 0) {
      const data = { name: 'Block1', blockFormat: 'basic' };
      onSubmit(data, isNew);
    }
  }, [isNew, activeTheme]);

  const cancelFunction = () => {
    layoutContext.setEditInProgress(false);
    history.push('/admin/blocks');
  };

  const handleBlockLayoutChange = (updates) => {
    dispatch({
      type: EDIT_BLOCK_LAYOUT,
      payload: updates
    });
  };

  const handleBlockPropertyChange = (updates) => {
    dispatch({
      type: EDIT_BLOCK_PROPERTY,
      payload: updates
    });
  };

  const handleBlockPropertyChangeWithoutHistory = (updates) => {
    dispatch({
      type: EDIT_BLOCK_PROPERTY_WITHOUT_HISTORY,
      payload: updates
    });
  };

  const selectTemplate = (template) => {
    const blockProperties = template.blockProperties;
    delete blockProperties.block;
    delete blockProperties._id;
    delete blockProperties.__v;

    dispatch({
      type: TEMPLATE_BLOCK_SELECTED,
      payload: {
        size: template.size,
        blockProperties
      }
    });

    // dispatch({
    //   type: EDIT_BLOCK_PROPERTY,
    //   payload: blockProperties
    // });
  };

  const handleUndo = () => {
    if (blockInPreviewHistory.length > 0) {
      dispatch({
        type: UNDO
      });
    }
  };

  const handleRedo = () => {
    if (blockInPreviewFuture.length > 0) {
      dispatch({
        type: REDO
      });
    }
  };

  const handleUndoRedoShortcut = (e) => {
    if (e.key == 'z' && e.ctrlKey) {
      handleUndo();
    } else if (e.key == 'y' && e.ctrlKey) {
      handleRedo();
    }
  };

  const handleDeleteShortcut = (e) => {
    if (
      (e.key == 'Delete' || (navigator.userAgent.indexOf('Mac') != -1 && e.key == 'Backspace')) &&
      document.activeElement.tagName !== 'INPUT'
    ) {
      setDeleteModalVisible(true);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleUndoRedoShortcut);
    return () => document.removeEventListener('keydown', handleUndoRedoShortcut);
  });
  useEffect(() => {
    if (selectedBlock && selectedBlock !== blockInPreview._id) {
      document.addEventListener('keydown', handleDeleteShortcut);
      return () => document.removeEventListener('keydown', handleDeleteShortcut);
    }
  });

  const handleEditorChange = (value, editor) => {
    const length = editor.getContent({ format: 'text' }).length;

    if (length % 5 === 1) {
      handleBlockPropertyChange({ textContent: value });
    } else {
      handleBlockPropertyChangeWithoutHistory({ textContent: value });
    }
  };

  // const isDataFetched = !block.isLoading && block.data && currentuser.language;
  const isDataFetchedWithBlockTypeAndAudiencesAndCategoriesAndThemes =
    !block.isLoading &&
    block.data &&
    currentuser.language &&
    !audiences.isLoading &&
    !categories.isLoading &&
    !allBlockTypes.isLoading &&
    allBlockTypes.data.length > 0;
  //   &&
  // !activeTheme.isLoading &&
  // !activeTheme.isError &&
  // activeTheme.data._id;

  useEffect(() => {
    const noActiveOrCreatedTheme =
      (!activeTheme.isLoading && !activeTheme.isError && !activeTheme.data._id) ||
      (!userThemes.isLoading && !userThemes.isError && userThemes.data.items?.length === 0);

    if (isDataFetchedWithBlockTypeAndAudiencesAndCategoriesAndThemes && noActiveOrCreatedTheme) {
      if (userThemes.data.items && userThemes.data.items.length === 0) {
        setModalMessage('Create a theme');
        setVisibleModal(true);
      } else if (!activeTheme.data._id && userThemes.data.items?.length > 0) {
        setModalMessage('Activate a theme');
        setVisibleModal(true);
      }
    }
  }, [activeTheme, isDataFetchedWithBlockTypeAndAudiencesAndCategoriesAndThemes, userThemes]);

  const addBlockToLayout = async (id, blockData) => {
    const route = `${SERVER_URL}/blocks-clone/${id}`;

    try {
      const response = await Axios.post(route, blockData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });

      dispatch({
        type: ADD_BLOCK_TO_LAYOUT,
        payload: {
          block: response.data.item,
          x: 0,
          y: 0,
          w: response.data.item.size[1],
          h: response.data.item.size[0]
        }
      });

      setSelectedBlock(response.data.item._id);
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }

    // dispatch({
    //   type: ADD_BLOCK_TO_LAYOUT,
    //   payload: block
    // });
  };

  const activateTheme = async (id) => {
    try {
      const res = await Axios.put(
        `${SERVER_URL}/activate-theme/${currentuser.data.id}/${id}?team=${currentuser.selectedTeam}&study=${currentuser.study}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      history.push('/admin');
      history.push('/admin/new-block');
    } catch (err) {
      notification.error({
        message: 'Problem with theme activation. Please try later.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const addEmptyBlock = async (type, properties) => {
    const defaultFont =
      type === 'heading'
        ? headingFont
        : type === 'subheading'
        ? subheadingFont
        : type === 'body'
        ? bodyTextFont
        : null;

    const blockData = type
      ? {
          ...addBlockData[`${type}`],
          blockProperties: properties
            ? { ...properties }
            : {
                ...addBlockData[`${type}`].blockProperties,
                textContent: addBlockData[`${type}`].blockProperties.textContent.replace(
                  '**default**',
                  defaultFont.name.toLowerCase().replace(' -', '')
                )
              },
          blockType: selectedBlockType._id
        }
      : {
          ...addBlockData.empty,
          blockType: selectedBlockType._id
        };
    const route = `${SERVER_URL}/blocks`;

    try {
      const response = await Axios.post(route, blockData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });

      const newBlockHeight =
        blockInPreview.row + blockInPreview.size[0] + response.data.item.size[0] <= 16
          ? blockInPreview.size[0] + response.data.item.size[0]
          : blockInPreview.size[0];

      dispatch({
        type: ADD_BLOCK_TO_LAYOUT_WITH_RESIZE,
        payload: {
          blockSize:
            response.data.item.size[1] > blockInPreview.size[1]
              ? [newBlockHeight, response.data.item.size[1]]
              : [newBlockHeight, blockInPreview.size[1]],
          element: {
            block: response.data.item,
            x: 0,
            y:
              blockInPreview.row + blockInPreview.size[0] + response.data.item.size[0] <= 16
                ? blockInPreview.size[0]
                : blockInPreview.size[0] - response.data.item.size[0],
            w: response.data.item.size[1],
            h: response.data.item.size[0]
          }
        }
      });
      // dispatch({
      //   type: ADD_BLOCK_TO_LAYOUT,
      //   payload: {
      //     block: response.data.item,
      //     x: 0,
      //     y: 0,
      //     w: response.data.item.size[1],
      //     h: response.data.item.size[0],
      //   },
      // });

      setSelectedBlock(response.data.item._id);
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight'
      });
    }
  };

  let highlightedBlock;
  if (selectedBlock && blockInPreview) {
    highlightedBlock = blockInPreview.blocksLayout.find((item) => item.block._id === selectedBlock);
  }

  const headingFont = blockInPreview?.theme.selectedFonts.find(
    (font) => font._id === blockInPreview.theme.headingFont
  );
  const subheadingFont = blockInPreview?.theme.selectedFonts.find(
    (font) => font._id === blockInPreview.theme.subheadingFont
  );
  const bodyTextFont = blockInPreview?.theme.selectedFonts.find(
    (font) => font._id === blockInPreview.theme.bodyTextFont
  );

  const convertToTemplate = async (updates) => {
    setSendingTemplateData(true);
    const route = `${SERVER_URL}/blocks-templates/${blockId}`;
    try {
      const response = await Axios.put(route, updates, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      dispatch({
        type: SELECT_BLOCK,
        payload: {
          ...response.data.item,
          column: blockInPreview.column,
          row: blockInPreview.row
        }
      });
      setSendingTemplateData(false);
    } catch (error) {
      setSendingTemplateData(false);
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const getLayoutFromTemplate = async (template) => {
    const route = `${SERVER_URL}/blocks-create-layout-from-existing`;
    await currentuser.handleRefreshToken();
    try {
      const res = await Axios.post(
        route,
        { blocksLayout: template.blocksLayout },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      const newLayout = res.data.item;

      dispatch({
        type: SELECT_BLOCK,
        payload: {
          ...blockInPreview,
          blockFormat: template.blockFormat,
          blockProperties: template.blockProperties,
          isMulti: template.isMulti,
          size: template.size,
          blocksLayout: newLayout,
          column: blockInPreview.column,
          row: blockInPreview.row
        }
      });
      setSelectedTemplate(null);
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration
      });
    }
  };

  const handleChangeDebounced = _.debounce(async (e) => {
    setSearchFilter(e.target.value);
  }, 500);

  const handleSearchInputChange = (e) => {
    e.persist();
    handleChangeDebounced(e);
  };

  const removeElementWithResize = (id) => {
    const newLayout = blockInPreview.blocksLayout.filter((item) => item.block._id !== id);
    const minX = Math.min(...newLayout.map((item) => item.x));
    const maxX = Math.max(...newLayout.map((item) => item.x + item.w));
    const minY = Math.min(...newLayout.map((item) => item.y));
    const maxY = Math.max(...newLayout.map((item) => item.y + item.h));

    dispatch({
      type: REMOVE_BLOCK_FROM_LAYOUT_WITH_RESIZE,
      payload: {
        blockSize: [maxY - minY, maxX - minX],
        column: minX + blockInPreview.column,
        row: minY + blockInPreview.row,
        _id: id,
        correctionX: -1 * minX,
        correctionY: -1 * minY
      }
    });
  };

  useEffect(() => {
    layoutContext.setEditInProgress(blockInPreviewHistory.length > 0);
  }, [blockInPreviewHistory.length]);

  return (
    <Layout className="edit-block">
      <HeaderComponent
        text={
          isNew || isCreateNew ? (
            'Create a Block'
          ) : (
            <>
              <Link to="/admin/blocks">Manage Blocks</Link>
              <span> / Edit</span>
            </>
          )
        }
        additionalText={isNew || isCreateNew ? null : blockInPreview?.name}
      />
      <Content>
        <ThemeModal
          themes={userThemes}
          visibleModal={visibleModal}
          message={modalMessage}
          activeThemes={activeThemes}
          currentuser={currentuser}
          activateTheme={activateTheme}
        />
        {!isDataFetchedWithBlockTypeAndAudiencesAndCategoriesAndThemes && (
          <div className="spinner">
            <LoadingOutlined />
          </div>
        )}
        {isDataFetchedWithBlockTypeAndAudiencesAndCategoriesAndThemes && (
          <Layout className="inner-layout">
            {/*isNew && (
              <SideBarInner>
                <div className='pre-create-block'>
                  <h3 className='pre-title pre-title--dark'>Add info</h3>
                  <Form
                    layout='vertical'
                    className='pre-create-block__form'
                    form={newBlockForm}
                    onFinish={(values) => onSubmit(values, isNew)}
                  >
                    <Form.Item
                      label='Name'
                      rules={[{ required: true, message: 'Please enter name!' }]}
                      name='name'
                      labelAlign='left'
                    >
                      <Input placeholder='Name' className='input-medium' />
                    </Form.Item>
                    <Form.Item
                      label='Block Type'
                      name='blockType'
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'Please select block type!',
                      //   },
                      // ]}
                    >
                      <Select
                        placeholder='Block Type'
                        className='dropdown-medium' size='large'
                      >
                        {allBlockTypes.data &&
                          allBlockTypes.data.length > 0 &&
                          allBlockTypes.data.map((blt) => (
                            <Select.Option value={blt._id} key={blt._id}>
                              {blt.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label='Category'
                      //rules={[{ required: true, message: 'Please enter category!' }]}
                      name='category'
                      labelAlign='left'
                    >
                      <Select mode='multiple' placeholder='Category' className='dropdown-medium' size='large'>
                        {categories.data.items &&
                          categories.data.items.length > 0 &&
                          categories.data.items.map((category) => {
                            return (
                              <Select.Option value={category._id} key={category._id}>
                                {category.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label='Audience'
                      //rules={[{ required: true, message: 'Please enter audience!' }]}
                      name='audience'
                      labelAlign='left'
                    >
                      <Select mode='multiple' placeholder='Audience' className='dropdown-medium' size='large'>
                        {audiences.data.items &&
                          audiences.data.items.length > 0 &&
                          audiences.data.items.map((audience) => {
                            return (
                              <Select.Option value={audience._id} key={audience._id}>
                                {audience.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item label='Notes' name='notes' labelAlign='left'>
                      <Input.TextArea rows={4} placeholder='Notes' />
                    </Form.Item>
                    <Button type='primary' htmlType='submit' block>
                      Continue
                    </Button>
                  </Form>
                </div>
              </SideBarInner>
                        )*/}
            {!isNew && (
              <SideBarInner>
                {selectedBlockType && (
                  <Collapse
                    ghost
                    expandIcon={({ isActive }) => (isActive ? <MinusIcon /> : <PlusIcon />)}
                    expandIconPosition="right"
                    className="block-properties"
                    defaultActiveKey={['2', '6', '8', '13', '16', '23', '27', '32', '41', '42']}>
                    {selectedBlockType.name === 'text' && (
                      <>
                        <Panel header={<h3 className="pre-title">Text</h3>} key="2">
                          <div className="text-formatting">
                            <div
                              className="text-formatting__item text-formatting__item--heading"
                              style={{
                                fontFamily: headingFont.name,
                                fontStyle: headingFont.style,
                                fontWeight: headingFont.weight
                              }}
                              onClick={() => addEmptyBlock('heading')}>
                              Add a heading
                            </div>
                            <div
                              className="text-formatting__item text-formatting__item--subheading"
                              style={{
                                fontFamily: subheadingFont.name,
                                fontStyle: subheadingFont.style,
                                fontWeight: subheadingFont.weight
                              }}
                              onClick={() => addEmptyBlock('subheading')}>
                              Add a subheading
                            </div>
                            <div
                              className="text-formatting__item text-formatting__item--body"
                              style={{
                                fontFamily: bodyTextFont.name,
                                fontStyle: bodyTextFont.style,
                                fontWeight: bodyTextFont.weight
                              }}
                              onClick={() => addEmptyBlock('body')}>
                              Add some body text
                            </div>
                          </div>
                        </Panel>
                        <Panel header={<h3 className="pre-title">Text padding</h3>} key="3">
                          <div className="text-padding">
                            <div className="text-padding__icons">
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'text'
                                    ? 'text-padding__btn text-padding__btn--disabled'
                                    : selectedPaddingSide === 'textPaddingTop'
                                    ? 'text-padding__btn text-padding__btn--selected'
                                    : 'text-padding__btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'text'
                                  ) {
                                    setSelectedPaddingSide('textPaddingTop');
                                  }
                                }}>
                                <AlignTopIcon />
                                <span>Top</span>
                              </div>
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'text'
                                    ? 'text-padding__btn text-padding__btn--disabled'
                                    : selectedPaddingSide === 'textPaddingBottom'
                                    ? 'text-padding__btn text-padding__btn--selected'
                                    : 'text-padding__btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'text'
                                  ) {
                                    setSelectedPaddingSide('textPaddingBottom');
                                  }
                                }}>
                                <AlignBottomIcon />
                                <span>Bottom</span>
                              </div>
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'text'
                                    ? 'text-padding__btn text-padding__btn--disabled'
                                    : selectedPaddingSide === 'textPaddingLeft'
                                    ? 'text-padding__btn text-padding__btn--selected'
                                    : 'text-padding__btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'text'
                                  ) {
                                    setSelectedPaddingSide('textPaddingLeft');
                                  }
                                }}>
                                <AlignLeftIcon />
                                <span>Left</span>
                              </div>
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'text'
                                    ? 'text-padding__btn text-padding__btn--disabled'
                                    : selectedPaddingSide === 'textPaddingRight'
                                    ? 'text-padding__btn text-padding__btn--selected'
                                    : 'text-padding__btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'text'
                                  ) {
                                    setSelectedPaddingSide('textPaddingRight');
                                  }
                                }}>
                                <AlignRightIcon />
                                <span>Right</span>
                              </div>
                            </div>
                            <div className="text-padding__slider">
                              <Slider
                                min={0}
                                max={100}
                                disabled={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'text'
                                }
                                tooltipVisible={false}
                                value={
                                  highlightedBlock?.block.blockProperties[`${selectedPaddingSide}`]
                                }
                                onChange={(value) => {
                                  dispatch({
                                    type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                    payload: {
                                      blockId: highlightedBlock.block._id,
                                      updates: {
                                        [`${selectedPaddingSide}`]: value
                                      }
                                    }
                                  });
                                }}
                              />
                              {highlightedBlock &&
                                highlightedBlock.block.blockType.name === 'text' && (
                                  <span>
                                    {
                                      highlightedBlock.block.blockProperties[
                                        `${selectedPaddingSide}`
                                      ]
                                    }
                                  </span>
                                )}
                            </div>
                          </div>
                        </Panel>

                        {/*<Panel header={<h3 className='pre-title'>Compliance</h3>} key='4'>
                          <CompliancePanel
                            token={currentuser.data.token}
                            SERVER_URL={SERVER_URL}
                            dispatch={dispatch}
                            highlightedBlock={highlightedBlock}
                          />
                           </Panel>*/}
                        {/* <Panel header={<h3 className='pre-title'>Endnotes</h3>} key='5'>
                          <Endnotes highlightedBlock={highlightedBlock} />
                        </Panel> */}
                      </>
                    )}
                    {selectedBlockType.name === 'divider' && (
                      <>
                        <Panel header={<h3 className="pre-title">Dividers</h3>} key="6">
                          <div className="divider-templates">
                            {dividerTemplates.length > 0 &&
                              dividerTemplates.map((template) => {
                                return (
                                  <div
                                    className="divider-templates__container"
                                    key={template.id}
                                    onClick={() => addEmptyBlock('divider', { ...template })}>
                                    <div
                                      style={{
                                        width: `${template.dividerLength}%`,
                                        borderBottom: `${template.dividerThickness}em ${template.dividerType} ${template.dividerColor.hex}`
                                      }}></div>
                                  </div>
                                );
                              })}
                          </div>
                        </Panel>
                        <Panel
                          header={<h3 className="pre-title">Divider Size &amp; Color</h3>}
                          key="7">
                          <div className="divider-props-panel">
                            <h3 className="pre-title">Length</h3>
                            <div className="divider-props-panel__slider">
                              <Slider
                                min={50}
                                max={100}
                                disabled={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'divider'
                                }
                                tooltipVisible={false}
                                value={highlightedBlock?.block.blockProperties.dividerLength || 75}
                                onChange={(value) => {
                                  dispatch({
                                    type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                    payload: {
                                      blockId: highlightedBlock.block._id,
                                      updates: {
                                        dividerLength: value
                                      }
                                    }
                                  });
                                }}
                              />
                              {highlightedBlock &&
                                highlightedBlock.block.blockType.name === 'divider' && (
                                  <span>
                                    {highlightedBlock.block.blockProperties.dividerLength}%
                                  </span>
                                )}
                            </div>
                            <h3 className="pre-title">Color</h3>
                            <div className="divider-props-panel__border-inputs">
                              <ColorPickerDropdown
                                colors={blockInPreview.theme.colorPalette.colors}
                                colorsBlackWhiteGrey={
                                  blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                }
                                selectedColor={highlightedBlock?.block.blockProperties.dividerColor}
                                disabled={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'divider'
                                }
                                leftRadius
                                callback={(color) => {
                                  dispatch({
                                    type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                    payload: {
                                      blockId: highlightedBlock.block._id,
                                      updates: {
                                        dividerColor: color
                                      }
                                    }
                                  });
                                }}
                              />
                              <InputNumber
                                className="divider-props-panel__border-thickness"
                                min={1}
                                max={10}
                                disabled={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'divider'
                                }
                                value={
                                  highlightedBlock?.block.blockProperties.dividerThickness * 16
                                }
                                onChange={(value) => {
                                  dispatch({
                                    type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                    payload: {
                                      blockId: highlightedBlock.block._id,
                                      updates: {
                                        dividerThickness: value / 16
                                      }
                                    }
                                  });
                                }}
                              />
                              <Select
                                className="dropdown-small divider-props-panel__border-type"
                                value={highlightedBlock?.block.blockProperties.dividerType}
                                disabled={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'divider'
                                }
                                onChange={(value) => {
                                  dispatch({
                                    type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                    payload: {
                                      blockId: highlightedBlock.block._id,
                                      updates: {
                                        dividerType: value
                                      }
                                    }
                                  });
                                }}>
                                <Select.Option value="solid" label="Solid">
                                  Solid
                                </Select.Option>
                                <Select.Option value="dotted" label="Dotted">
                                  Dotted
                                </Select.Option>
                                <Select.Option value="dashed" label="Dashed">
                                  Dashed
                                </Select.Option>
                                <Select.Option value="double" label="Double">
                                  Double
                                </Select.Option>
                                <Select.Option value="" label="None">
                                  None
                                </Select.Option>
                              </Select>
                            </div>
                          </div>
                        </Panel>
                      </>
                    )}
                    {selectedBlockType.name === 'button' && (
                      <>
                        <Panel header={<h3 className="pre-title">Buttons</h3>} key="8">
                          <div className="buttons-templates">
                            {buttonTemplates.length > 0 &&
                              buttonTemplates.map((template) => {
                                return (
                                  <div
                                    key={template.id}
                                    className="buttons-templates__container"
                                    style={{ fontSize: `${(310 / 570) * 16}px` }}
                                    onClick={() =>
                                      addEmptyBlock('button', {
                                        ...template,
                                        buttonText: template.buttonText.replace(
                                          '**default**',
                                          bodyTextFont.name.toLowerCase().replace(' -', '')
                                        )
                                      })
                                    }>
                                    <div
                                      className="buttons-templates__button"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: `${template.buttonWidth}%`,
                                        height: `${template.buttonHeight}%`,
                                        backgroundColor: template.buttonBackgroundColor?.hex,
                                        border: template.buttonBorderType
                                          ? `${template.buttonBorderThickness} ${template.buttonBorderType} ${template.buttonBorderColor?.hex}`
                                          : '',
                                        borderRadius: template.buttonBorderRadius
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: template.buttonText.replace(
                                          '**default**',
                                          bodyTextFont.name.toLowerCase().replace(' -', '')
                                        )
                                      }}></div>
                                  </div>
                                );
                              })}
                          </div>
                        </Panel>
                        <Panel
                          header={<h3 className="pre-title">Button Size &amp; Color</h3>}
                          key="9">
                          <div className="button-props-panel__section-wrapper button-props-panel__section-wrapper">
                            <h3 className="pre-title button-props-panel__label">SIZE</h3>
                            <div className="button-props-panel__btn-sizes">
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'button'
                                    ? 'button-props-panel__btn-size button-props-panel__btn-size--disabled'
                                    : selectedBtnDimension === 'buttonWidth'
                                    ? 'button-props-panel__btn-size button-props-panel__btn-size--active'
                                    : 'button-props-panel__btn-size'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'button'
                                  ) {
                                    setSelectedBtnDimension('buttonWidth');
                                  }
                                }}>
                                <div className="button-props-panel__btn-size-icon">
                                  <BtnWidthIcon />
                                </div>
                                <span className="button-props-panel__btn-size-label">Width</span>
                              </div>
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'button'
                                    ? 'button-props-panel__btn-size button-props-panel__btn-size--disabled'
                                    : selectedBtnDimension === 'buttonHeight'
                                    ? 'button-props-panel__btn-size button-props-panel__btn-size--active'
                                    : 'button-props-panel__btn-size'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'button'
                                  ) {
                                    setSelectedBtnDimension('buttonHeight');
                                  }
                                }}>
                                <div className="button-props-panel__btn-size-icon">
                                  <BtnHeightIcon />
                                </div>
                                <span className="button-props-panel__btn-size-label">Height</span>
                              </div>
                            </div>
                            <div className="button-props-panel__slider">
                              <Slider
                                // ref={sliderRef}
                                min={50}
                                max={100}
                                disabled={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'button'
                                }
                                tooltipVisible={false}
                                value={
                                  highlightedBlock?.block.blockProperties[
                                    `${selectedBtnDimension}`
                                  ] || 75
                                }
                                onChange={(value) => {
                                  dispatch({
                                    type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                    payload: {
                                      blockId: highlightedBlock.block._id,
                                      updates: {
                                        [`${selectedBtnDimension}`]: value
                                      }
                                    }
                                  });
                                }}
                                // onAfterChange={sliderRef?.current?.blur}
                              />
                              {highlightedBlock &&
                                highlightedBlock.block.blockType.name === 'button' && (
                                  <span>
                                    {
                                      highlightedBlock?.block.blockProperties[
                                        `${selectedBtnDimension}`
                                      ]
                                    }
                                    %
                                  </span>
                                )}
                            </div>
                          </div>
                          <div className="button-props-panel__section-wrapper button-props-panel__section-wrapper">
                            <div className="button-props-panel__color">
                              <div className="button-props-panel__btn-color">
                                <h3 className="pre-title button-props-panel__label">Color</h3>
                                <ColorPickerDropdown
                                  colors={blockInPreview.theme.colorPalette.colors}
                                  colorsBlackWhiteGrey={
                                    blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                  }
                                  selectedColor={
                                    highlightedBlock?.block.blockProperties.buttonBackgroundColor
                                  }
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'button'
                                  }
                                  leftRadius
                                  rightRadius
                                  callback={(color) => {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          buttonBackgroundColor: color
                                        }
                                      }
                                    });
                                  }}
                                />
                              </div>
                              <div className="button-props-panel__border">
                                <h3 className="pre-title button-props-panel__label">Border</h3>
                                <div className="button-props-panel__border-inputs">
                                  <ColorPickerDropdown
                                    colors={blockInPreview.theme.colorPalette.colors}
                                    colorsBlackWhiteGrey={
                                      blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                    }
                                    selectedColor={
                                      highlightedBlock?.block.blockProperties.buttonBorderColor
                                    }
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'button'
                                    }
                                    leftRadius
                                    callback={(color) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            buttonBorderColor: color
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <InputNumber
                                    className="button-props-panel__border-thickness"
                                    min={1}
                                    max={10}
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'button'
                                    }
                                    value={
                                      highlightedBlock?.block.blockProperties.buttonBorderThickness.replace(
                                        'em',
                                        ''
                                      ) * 16
                                    }
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            buttonBorderThickness: `${value / 16}em`
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <Select
                                    className="dropdown-small button-props-panel__border-type"
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'button'
                                    }
                                    value={highlightedBlock?.block.blockProperties.buttonBorderType}
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            buttonBorderType: value
                                          }
                                        }
                                      });
                                    }}>
                                    <Select.Option value="solid" label="Solid">
                                      Solid
                                    </Select.Option>
                                    <Select.Option value="dotted" label="Dotted">
                                      Dotted
                                    </Select.Option>
                                    <Select.Option value="dashed" label="Dashed">
                                      Dashed
                                    </Select.Option>
                                    <Select.Option value="double" label="Double">
                                      Double
                                    </Select.Option>
                                    <Select.Option value="" label="None">
                                      None
                                    </Select.Option>
                                  </Select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="button-props-panel__section-wrapper button-props-panel__section-wrapper">
                            <div className="button-props-panel__btn-shape">
                              <h3 className="pre-title button-props-panel__label">Shape</h3>
                              <div className="button-props-panel__slider">
                                <Slider
                                  min={0}
                                  max={30000}
                                  step={625}
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'button'
                                  }
                                  tooltipVisible={false}
                                  value={
                                    highlightedBlock?.block.blockProperties.buttonBorderRadius.replace(
                                      'em',
                                      ''
                                    ) * 10000
                                  }
                                  onChange={(value) => {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          buttonBorderRadius: `${value / 10000}em`
                                        }
                                      }
                                    });
                                  }}
                                />
                                {highlightedBlock &&
                                  highlightedBlock.block.blockType.name === 'button' && (
                                    <span>
                                      {highlightedBlock?.block.blockProperties.buttonBorderRadius.replace(
                                        'em',
                                        ''
                                      ) * 16}
                                      px
                                    </span>
                                  )}
                              </div>
                            </div>
                          </div>
                        </Panel>

                        <Panel header={<h3 className="pre-title">Shadow</h3>} key="10">
                          <div className="button-props-panel__btn-effects">
                            <div className="button-props-panel__btn-effects-choices choice-btn-group">
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'button'
                                    ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--disabled'
                                    : highlightedBlock?.block.blockProperties.buttonHasShadow &&
                                      !highlightedBlock.block.blockProperties.buttonInnerShadow
                                    ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                                    : 'button-props-panel__btn-effects-choice choice-btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'button'
                                  ) {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: highlightedBlock.block.blockProperties
                                          .buttonHasShadow
                                          ? highlightedBlock.block.blockProperties.buttonInnerShadow
                                            ? {
                                                buttonInnerShadow: false,
                                                buttonHasShadow: true
                                              }
                                            : {
                                                buttonHasShadow: false
                                              }
                                          : {
                                              buttonInnerShadow: false,
                                              buttonHasShadow: true
                                            }
                                      }
                                    });
                                  }
                                }}>
                                Drop Shadow
                              </div>
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'button'
                                    ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--disabled'
                                    : highlightedBlock?.block.blockProperties.buttonHasShadow &&
                                      highlightedBlock?.block.blockProperties.buttonInnerShadow
                                    ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                                    : 'button-props-panel__btn-effects-choice choice-btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'button'
                                  ) {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: highlightedBlock.block.blockProperties
                                          .buttonHasShadow
                                          ? highlightedBlock.block.blockProperties.buttonInnerShadow
                                            ? {
                                                buttonHasShadow: false
                                              }
                                            : {
                                                buttonInnerShadow: true,
                                                buttonHasShadow: true
                                              }
                                          : {
                                              buttonInnerShadow: true,
                                              buttonHasShadow: true
                                            }
                                      }
                                    });
                                  }
                                }}>
                                Inner Shadow
                              </div>
                            </div>
                            {advancedMode && (
                              <>
                                <div className="button-props-panel__btn-effects-color">
                                  <h3 className="pre-title button-props-panel__label">Color</h3>
                                  <ColorPickerDropdown
                                    colors={blockInPreview.theme.colorPalette.colors}
                                    colorsBlackWhiteGrey={
                                      blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                    }
                                    selectedColor={
                                      highlightedBlock?.block.blockProperties.buttonShadowColor ||
                                      null
                                    }
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'button'
                                    }
                                    leftRadius
                                    rightRadius
                                    callback={(color) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            buttonShadowColor: color
                                          }
                                        }
                                      });
                                    }}
                                  />
                                </div>
                                <div className="button-props-panel__btn-effects-color">
                                  <h3 className="pre-title button-props-panel__label">
                                    X position
                                  </h3>
                                  <div className="button-props-panel__slider">
                                    <Slider
                                      min={-100}
                                      max={100}
                                      disabled={
                                        !highlightedBlock ||
                                        highlightedBlock.block.blockType.name !== 'button'
                                      }
                                      tooltipVisible={false}
                                      value={
                                        highlightedBlock?.block.blockProperties.buttonShadowX
                                          ? highlightedBlock.block.blockProperties.buttonShadowX.replace(
                                              'em',
                                              ''
                                            ) * 100
                                          : 0
                                      }
                                      onChange={(value) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              buttonShadowX: `${value / 100}em`
                                            }
                                          }
                                        });
                                      }}
                                    />
                                    {highlightedBlock &&
                                      highlightedBlock.block.blockType.name === 'button' && (
                                        <span>
                                          {highlightedBlock?.block.blockProperties.buttonShadowX
                                            ? Math.round(
                                                highlightedBlock.block.blockProperties.buttonShadowX.replace(
                                                  'em',
                                                  ''
                                                ) * 100
                                              )
                                            : 0}
                                        </span>
                                      )}
                                  </div>
                                  <h3 className="pre-title button-props-panel__label">
                                    Y position
                                  </h3>
                                  <div className="button-props-panel__slider">
                                    <Slider
                                      min={-100}
                                      max={100}
                                      disabled={
                                        !highlightedBlock ||
                                        highlightedBlock.block.blockType.name !== 'button'
                                      }
                                      tooltipVisible={false}
                                      value={
                                        highlightedBlock?.block.blockProperties.buttonShadowY
                                          ? highlightedBlock.block.blockProperties.buttonShadowY.replace(
                                              'em',
                                              ''
                                            ) * 100
                                          : 0
                                      }
                                      onChange={(value) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              buttonShadowY: `${value / 100}em`
                                            }
                                          }
                                        });
                                      }}
                                    />
                                    {highlightedBlock &&
                                      highlightedBlock.block.blockType.name === 'button' && (
                                        <span>
                                          {highlightedBlock?.block.blockProperties.buttonShadowY
                                            ? Math.round(
                                                highlightedBlock.block.blockProperties.buttonShadowY.replace(
                                                  'em',
                                                  ''
                                                ) * 100
                                              )
                                            : 0}
                                        </span>
                                      )}
                                  </div>
                                  <h3 className="pre-title button-props-panel__label">Blur</h3>
                                  <div className="button-props-panel__slider">
                                    <Slider
                                      min={0}
                                      max={100}
                                      disabled={
                                        !highlightedBlock ||
                                        highlightedBlock.block.blockType.name !== 'button'
                                      }
                                      tooltipVisible={false}
                                      value={
                                        highlightedBlock?.block.blockProperties.buttonShadowBlur
                                          ? highlightedBlock.block.blockProperties.buttonShadowBlur.replace(
                                              'em',
                                              ''
                                            ) * 100
                                          : 0
                                      }
                                      onChange={(value) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              buttonShadowBlur: `${value / 100}em`
                                            }
                                          }
                                        });
                                      }}
                                    />
                                    {highlightedBlock &&
                                      highlightedBlock.block.blockType.name === 'button' && (
                                        <span>
                                          {highlightedBlock?.block.blockProperties.buttonShadowBlur
                                            ? Math.round(
                                                highlightedBlock.block.blockProperties.buttonShadowBlur.replace(
                                                  'em',
                                                  ''
                                                ) * 100
                                              )
                                            : 0}
                                        </span>
                                      )}
                                  </div>
                                  <h3 className="pre-title button-props-panel__label">Opacity</h3>
                                  <div className="button-props-panel__slider">
                                    <Slider
                                      min={0}
                                      max={100}
                                      disabled={
                                        !highlightedBlock ||
                                        highlightedBlock.block.blockType.name !== 'button'
                                      }
                                      tooltipVisible={false}
                                      value={
                                        highlightedBlock?.block.blockProperties.buttonShadowOpacity
                                          ? highlightedBlock.block.blockProperties
                                              .buttonShadowOpacity * 100
                                          : 0
                                      }
                                      onChange={(value) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              buttonShadowOpacity: value / 100
                                            }
                                          }
                                        });
                                      }}
                                    />
                                    {highlightedBlock &&
                                      highlightedBlock.block.blockType.name === 'button' && (
                                        <span>
                                          {highlightedBlock?.block.blockProperties
                                            .buttonShadowOpacity
                                            ? Math.round(
                                                highlightedBlock.block.blockProperties
                                                  .buttonShadowOpacity * 100
                                              )
                                            : 0}
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </Panel>
                        <Panel header={<h3 className="pre-title">ACTION</h3>} key="11">
                          <div className="button-props-panel__link">
                            <Select
                              placeholder="Select Link Type..."
                              className="button-props-panel__link-selected dropdown-small"
                              disabled={
                                !highlightedBlock ||
                                highlightedBlock.block.blockType.name !== 'button'
                              }
                              value={highlightedBlock?.block.blockProperties.buttonLinkTo}
                              onChange={(value) => {
                                dispatch({
                                  type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                  payload: {
                                    blockId: highlightedBlock.block._id,
                                    updates: {
                                      buttonLinkTo: value
                                    }
                                  }
                                });
                              }}>
                              <Select.Option value="external-link" label="External link">
                                Open a Website
                              </Select.Option>
                              <Select.Option value="qoolection" label="Qoolection">
                                Open a Qoolection
                              </Select.Option>
                              <Select.Option value="file" label="File">
                                Open a PDF
                              </Select.Option>
                              <Select.Option value="email" label="Email">
                                Send an Email
                              </Select.Option>
                              <Select.Option value="phone" label="Phone">
                                Call a Phone Number
                              </Select.Option>
                              <Select.Option value="location" label="Go to a Map Location">
                                Go to a Map Location
                              </Select.Option>
                            </Select>
                            {highlightedBlock?.block.blockProperties.buttonLinkTo ===
                              'external-link' && (
                              <>
                                <span className="button-props-panel__link-type-label">Website</span>
                                <Input
                                  placeholder="https://www.website.com"
                                  className="button-props-panel__link-type-input"
                                  prefix={<LinkWebsite />}
                                  value={highlightedBlock?.block.blockProperties.buttonAction}
                                  onChange={(e) => {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          buttonAction: e.target.value
                                        }
                                      }
                                    });
                                  }}
                                />
                              </>
                            )}
                            {highlightedBlock?.block.blockProperties.buttonLinkTo ===
                              'qoolection' && (
                              <Input
                                placeholder="Search your Qoolections"
                                className="button-props-panel__link-type-input"
                                prefix={<LinkSearch />}
                                value={highlightedBlock?.block.blockProperties.buttonAction}
                                onChange={(e) => {
                                  dispatch({
                                    type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                    payload: {
                                      blockId: highlightedBlock.block._id,
                                      updates: {
                                        buttonAction: e.target.value
                                      }
                                    }
                                  });
                                }}
                              />
                            )}
                            {highlightedBlock?.block.blockProperties.buttonLinkTo === 'file' && (
                              <>
                                <Upload>
                                  <Button
                                    size="small"
                                    type="primary"
                                    block
                                    className="button-props-panel__link-type-remove">
                                    Upload a file
                                  </Button>
                                </Upload>

                                {/* <Button
                                  size='small'
                                  type='secondary'
                                  block
                                  className='button-props-panel__link-type-remove'
                                >
                                  Remove file
                                </Button> */}
                              </>
                            )}
                            {highlightedBlock?.block.blockProperties.buttonLinkTo === 'email' && (
                              <Input
                                placeholder="name@email.com"
                                className="button-props-panel__link-type-input"
                                prefix={<LinkEmail />}
                                value={highlightedBlock?.block.blockProperties.buttonAction}
                                onChange={(e) => {
                                  dispatch({
                                    type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                    payload: {
                                      blockId: highlightedBlock.block._id,
                                      updates: {
                                        buttonAction: e.target.value
                                      }
                                    }
                                  });
                                }}
                              />
                            )}
                            {highlightedBlock?.block.blockProperties.buttonLinkTo === 'phone' && (
                              <Input
                                placeholder="+1 (222) 333-1234"
                                className="button-props-panel__link-type-input"
                                prefix={<LinkPhone />}
                                value={highlightedBlock?.block.blockProperties.buttonAction}
                                onChange={(e) => {
                                  dispatch({
                                    type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                    payload: {
                                      blockId: highlightedBlock.block._id,
                                      updates: {
                                        buttonAction: e.target.value
                                      }
                                    }
                                  });
                                }}
                              />
                            )}
                            {highlightedBlock?.block.blockProperties.buttonLinkTo ===
                              'location' && (
                              <>
                                <span className="button-props-panel__link-type-label">
                                  Link to Location
                                </span>
                                <Input
                                  placeholder="https://goo.gl/maps/"
                                  className="button-props-panel__link-type-input"
                                  prefix={<LinkIcon />}
                                  value={highlightedBlock?.block.blockProperties.buttonAction}
                                  onChange={(e) => {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          buttonAction: e.target.value
                                        }
                                      }
                                    });
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </Panel>
                        {/* <Panel header={<h3 className='pre-title'>Compliance</h3>} key='12'>
                          <CompliancePanel
                            token={currentuser.data.token}
                            SERVER_URL={SERVER_URL}
                            dispatch={dispatch}
                            highlightedBlock={highlightedBlock}
                          />
                        </Panel> */}
                      </>
                    )}
                    {selectedBlockType.name === 'icon' && (
                      <>
                        <Panel header={<h3 className="pre-title">Icons Library</h3>} key="13">
                          <IconsLibrary selectIcon={addEmptyBlock} iconFetchUrl={iconFetchUrl} />
                        </Panel>
                        <Panel
                          header={<h3 className="pre-title">Icon size &amp; color</h3>}
                          key="14">
                          <div className="icon-size-color">
                            <h3 className="pre-title">Size</h3>
                            <div className="icon-size-color__slider">
                              <Slider
                                min={50}
                                max={100}
                                disabled={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'icon'
                                }
                                tooltipVisible={false}
                                value={highlightedBlock?.block.blockProperties.iconSize}
                                onChange={(value) => {
                                  dispatch({
                                    type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                    payload: {
                                      blockId: highlightedBlock.block._id,
                                      updates: {
                                        iconSize: value
                                      }
                                    }
                                  });
                                }}
                              />
                              {highlightedBlock &&
                                highlightedBlock.block.blockType.name === 'icon' && (
                                  <span>{highlightedBlock.block.blockProperties.iconSize}%</span>
                                )}
                            </div>
                            <h3 className="pre-title icon-size-color__color-title">Color</h3>
                            <ColorPickerDropdown
                              colors={blockInPreview.theme.colorPalette.colors}
                              colorsBlackWhiteGrey={
                                blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                              }
                              selectedColor={highlightedBlock?.block.blockProperties.iconColor}
                              disabled={
                                !highlightedBlock ||
                                highlightedBlock.block.blockType.name !== 'icon'
                              }
                              leftRadius
                              rightRadius
                              callback={(color) => {
                                dispatch({
                                  type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                  payload: {
                                    blockId: highlightedBlock.block._id,
                                    updates: {
                                      iconColor: color
                                    }
                                  }
                                });
                              }}
                            />
                          </div>
                        </Panel>
                        {/*<Panel header={<h3 className='pre-title'>Icon background</h3>} key='15'>
                          {highlightedBlock && highlightedBlock.block.blockType.name === 'icon' && (
                            <div className='icon-background'>
                              <div className='text-background__color'>
                                <div className='text-background__bg-color'>
                                  <h3 className='pre-title text-background__label'>Color</h3>
                                  <ColorPickerDropdown
                                    colors={blockInPreview.theme.colorPalette.colors}
                                    colorsBlackWhiteGrey={blockInPreview.theme.colorPalette.colorsBlackWhiteGrey}
                                    selectedColor={highlightedBlock.block.blockProperties.iconBackgroundColor}
                                    leftRadius
                                    rightRadius
                                    callback={(color) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            iconBackgroundColor: color,
                                          },
                                        },
                                      });
                                    }}
                                  />
                                </div>
                                <div className='text-background__border'>
                                  <h3 className='pre-title text-background__label'>Border</h3>
                                  <div className='text-background__border-inputs'>
                                    <ColorPickerDropdown
                                      colors={blockInPreview.theme.colorPalette.colors}
                                      colorsBlackWhiteGrey={blockInPreview.theme.colorPalette.colorsBlackWhiteGrey}
                                      selectedColor={highlightedBlock.block.blockProperties.iconBorderColor}
                                      leftRadius
                                      callback={(color) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              iconBorderColor: color,
                                            },
                                          },
                                        });
                                      }}
                                    />
                                    <InputNumber
                                      className='input-small text-background__border-thickness'
                                      min={0}
                                      max={10}
                                      value={highlightedBlock.block.blockProperties.iconBorderThickness * 16}
                                      onChange={(value) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              iconBorderThickness: value / 16,
                                            },
                                          },
                                        });
                                      }}
                                    />
                                    <Select
                                      className='dropdown-small text-background__border-type'
                                      value={highlightedBlock.block.blockProperties.iconBorderType}
                                      onChange={(value) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              iconBorderType: value,
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      <Select.Option value='solid' label='Solid'>
                                        Solid
                                      </Select.Option>
                                      <Select.Option value='dotted' label='Dotted'>
                                        Dotted
                                      </Select.Option>
                                      <Select.Option value='dashed' label='Dashed'>
                                        Dashed
                                      </Select.Option>
                                      <Select.Option value='double' label='Double'>
                                        Double
                                      </Select.Option>
                                      <Select.Option value='' label='None'>
                                        None
                                      </Select.Option>
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                                    </Panel>*/}
                      </>
                    )}
                    {selectedBlockType.name === 'form' && (
                      <>
                        <Panel header={<h3 className="pre-title">Forms</h3>} key="16">
                          <div className="forms-templates">
                            {formTemplates &&
                              formTemplates.length > 0 &&
                              formTemplates.map((template) => {
                                return (
                                  <div
                                    key={template.id}
                                    className="forms-templates__container"
                                    onClick={() => addEmptyBlock('form', { ...template })}>
                                    <FormTemplate template={template} />
                                  </div>
                                );
                              })}
                          </div>
                        </Panel>
                        <Panel header={<h3 className="pre-title">Form padding</h3>} key="17">
                          <div className="text-padding">
                            <div className="text-padding__icons">
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'form'
                                    ? 'text-padding__btn text-padding__btn--disabled'
                                    : selectedPaddingSideForm === 'formPaddingTop'
                                    ? 'text-padding__btn text-padding__btn--selected'
                                    : 'text-padding__btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'form'
                                  ) {
                                    setSelectedPaddingSideForm('formPaddingTop');
                                  }
                                }}>
                                <AlignTopIcon />
                                <span>Top</span>
                              </div>
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'form'
                                    ? 'text-padding__btn text-padding__btn--disabled'
                                    : selectedPaddingSideForm === 'formPaddingBottom'
                                    ? 'text-padding__btn text-padding__btn--selected'
                                    : 'text-padding__btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'form'
                                  ) {
                                    setSelectedPaddingSideForm('formPaddingBottom');
                                  }
                                }}>
                                <AlignBottomIcon />
                                <span>Bottom</span>
                              </div>
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'form'
                                    ? 'text-padding__btn text-padding__btn--disabled'
                                    : selectedPaddingSideForm === 'formPaddingLeft'
                                    ? 'text-padding__btn text-padding__btn--selected'
                                    : 'text-padding__btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'form'
                                  ) {
                                    setSelectedPaddingSideForm('formPaddingLeft');
                                  }
                                }}>
                                <AlignLeftIcon />
                                <span>Left</span>
                              </div>
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'form'
                                    ? 'text-padding__btn text-padding__btn--disabled'
                                    : selectedPaddingSideForm === 'formPaddingRight'
                                    ? 'text-padding__btn text-padding__btn--selected'
                                    : 'text-padding__btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'form'
                                  ) {
                                    setSelectedPaddingSideForm('formPaddingRight');
                                  }
                                }}>
                                <AlignRightIcon />
                                <span>Right</span>
                              </div>
                            </div>
                            <div className="text-padding__slider">
                              <Slider
                                min={0}
                                max={100}
                                disabled={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'form'
                                }
                                tooltipVisible={false}
                                value={
                                  highlightedBlock?.block?.blockProperties[
                                    `${selectedPaddingSideForm}`
                                  ]
                                }
                                onChange={(value) => {
                                  dispatch({
                                    type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                    payload: {
                                      blockId: highlightedBlock?.block?._id,
                                      updates: {
                                        [`${selectedPaddingSideForm}`]: value
                                      }
                                    }
                                  });
                                }}
                              />
                              {highlightedBlock &&
                                highlightedBlock.block.blockType.name === 'form' && (
                                  <span>
                                    {
                                      highlightedBlock?.block?.blockProperties[
                                        `${selectedPaddingSideForm}`
                                      ]
                                    }
                                  </span>
                                )}
                            </div>
                          </div>
                        </Panel>

                        <Panel header={<h3 className="pre-title">Form fields</h3>} key="18">
                          {highlightedBlock?.block.blockProperties.formFields?.length > 0 &&
                            highlightedBlock.block.blockProperties.formFields.map(
                              (formField, index) => {
                                return (
                                  <FormField
                                    key={index}
                                    formField={formField}
                                    index={index}
                                    blockId={highlightedBlock.block._id}
                                    dispatch={dispatch}
                                    editCallback={(value) => {
                                      dispatch({
                                        type: EDIT_FORM_FIELD,
                                        payload: {
                                          required: value,
                                          blockId: highlightedBlock.block._id,
                                          index
                                        }
                                      });
                                    }}
                                    removeCallback={() => {
                                      dispatch({
                                        type: REMOVE_FORM_FIELD,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          index
                                        }
                                      });
                                    }}
                                  />
                                );
                              }
                            )}

                          <div className="add-info-form">
                            <div
                              className="add-info-form__item"
                              style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'space-between'
                              }}>
                              <div
                                style={{ width: '100%', marginRight: '10px', marginTop: '10px' }}>
                                <h4 className="add-info-form__label">Add field</h4>
                                <Select
                                  placeholder="Choose type"
                                  className="dropdown-small fieldType"
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'form'
                                  }
                                  style={{ width: '100%', marginBottom: '5px' }}
                                  value={selectedFormFieldElement}
                                  onChange={(value) => setSelectedFormFieldElement(value)}>
                                  <Select.Option value="text" className="fieldType">
                                    <TextIcon />
                                    <span>Text</span>
                                  </Select.Option>
                                  <Select.Option value="email" className="fieldType">
                                    <EmailIcon />
                                    <span>Email</span>
                                  </Select.Option>
                                  <Select.Option value="textarea" className="fieldType">
                                    <TextAreaIcon />
                                    <span>Text area</span>
                                  </Select.Option>
                                  <Select.Option value="checkbox" className="fieldType">
                                    <CheckboxIcon />
                                    <span>Checkbox</span>
                                  </Select.Option>
                                </Select>
                                <Input
                                  placeholder="Field name"
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'form'
                                  }
                                  value={formFieldName}
                                  onChange={(e) => setFormFieldName(e.target.value)}
                                />
                              </div>
                              <Button
                                className="add-button"
                                disabled={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'form' ||
                                  !selectedFormFieldElement ||
                                  !formFieldName
                                }
                                onClick={() => {
                                  dispatch({
                                    type: ADD_FORM_FIELD,
                                    payload: {
                                      blockId: highlightedBlock.block._id,
                                      formField: {
                                        fieldElement: selectedFormFieldElement,
                                        fieldName: formFieldName
                                      }
                                    }
                                  });
                                  setSelectedFormFieldElement(null);
                                  setFormFieldName('');
                                }}>
                                <div className="add-button-text">
                                  <PlusIconButton />
                                  <span style={{ marginLeft: '5px' }}>Add</span>
                                </div>
                              </Button>
                            </div>
                          </div>
                        </Panel>

                        <Panel header={<h3 className="pre-title">Field style</h3>} key="19">
                          <div className="text-background button-props-panel__section-wrapper">
                            <div className="text-background__color">
                              <div className="text-background__bg-color">
                                <h3 className="pre-title text-background__label">Default</h3>
                                <h3 className="pre-title text-background__label">Color</h3>
                                <ColorPickerDropdown
                                  colors={blockInPreview.theme.colorPalette.colors}
                                  colorsBlackWhiteGrey={
                                    blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                  }
                                  selectedColor={
                                    highlightedBlock?.block.blockProperties.formFieldBackgroundColor
                                  }
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'form'
                                  }
                                  leftRadius
                                  rightRadius
                                  callback={(color) => {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock?.block?._id,
                                        updates: {
                                          formFieldBackgroundColor: color
                                        }
                                      }
                                    });
                                  }}
                                />
                              </div>
                              <div className="text-background__border">
                                <h3
                                  className="pre-title text-background__label"
                                  style={{ marginTop: '26px' }}>
                                  Border
                                </h3>
                                <div className="text-background__border-inputs">
                                  <ColorPickerDropdown
                                    colors={blockInPreview.theme.colorPalette.colors}
                                    colorsBlackWhiteGrey={
                                      blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                    }
                                    selectedColor={
                                      highlightedBlock?.block.blockProperties.formFieldBorderColor
                                    }
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'form'
                                    }
                                    leftRadius
                                    line
                                    callback={(color) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock?.block?._id,
                                          updates: {
                                            formFieldBorderColor: color
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <InputNumber
                                    className="text-background__border-thickness"
                                    controls
                                    min={1}
                                    max={10}
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'form'
                                    }
                                    defaultValue={0}
                                    value={
                                      highlightedBlock?.block.blockProperties
                                        .formFieldBorderThickness * 16
                                    }
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            formFieldBorderThickness: value / 16
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <Select
                                    className="dropdown-small text-background__border-type"
                                    placeholder="Type"
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'form'
                                    }
                                    value={
                                      highlightedBlock?.block.blockProperties.formFieldBorderType
                                    }
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            formFieldBorderType: value
                                          }
                                        }
                                      });
                                    }}>
                                    <Select.Option value="solid" label="Solid">
                                      Solid
                                    </Select.Option>
                                    <Select.Option value="dotted" label="Dotted">
                                      Dotted
                                    </Select.Option>
                                    <Select.Option value="dashed" label="Dashed">
                                      Dashed
                                    </Select.Option>
                                    <Select.Option value="double" label="Double">
                                      Double
                                    </Select.Option>
                                    <Select.Option value="" label="None">
                                      None
                                    </Select.Option>
                                  </Select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-background button-props-panel__section-wrapper">
                            <div className="text-background__color">
                              <div className="text-background__bg-color">
                                <h3 className="pre-title text-background__label">Focused</h3>
                                <h3 className="pre-title text-background__label">Color</h3>
                                <ColorPickerDropdown
                                  colors={blockInPreview.theme.colorPalette.colors}
                                  colorsBlackWhiteGrey={
                                    blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                  }
                                  selectedColor={
                                    highlightedBlock?.block.blockProperties
                                      .formFieldFocusedBackgroundColor
                                  }
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'form'
                                  }
                                  leftRadius
                                  rightRadius
                                  callback={(color) => {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock?.block?._id,
                                        updates: {
                                          formFieldFocusedBackgroundColor: color
                                        }
                                      }
                                    });
                                  }}
                                />
                              </div>
                              <div className="text-background__border">
                                <h3
                                  className="pre-title text-background__label"
                                  style={{ marginTop: '26px' }}>
                                  Border
                                </h3>
                                <div className="text-background__border-inputs">
                                  <ColorPickerDropdown
                                    colors={blockInPreview.theme.colorPalette.colors}
                                    colorsBlackWhiteGrey={
                                      blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                    }
                                    selectedColor={
                                      highlightedBlock?.block.blockProperties
                                        .formFieldFocusedBorderColor
                                    }
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'form'
                                    }
                                    leftRadius
                                    line
                                    callback={(color) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock?.block?._id,
                                          updates: {
                                            formFieldFocusedBorderColor: color
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <InputNumber
                                    className="text-background__border-thickness"
                                    controls
                                    min={1}
                                    max={10}
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'form'
                                    }
                                    defaultValue={0}
                                    value={
                                      highlightedBlock?.block.blockProperties
                                        .formFieldFocusedBorderThickness * 16
                                    }
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            formFieldFocusedBorderThickness: value / 16
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <Select
                                    className="dropdown-small text-background__border-type"
                                    placeholder="Type"
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'form'
                                    }
                                    value={
                                      highlightedBlock?.block.blockProperties
                                        .formFieldFocusedBorderType
                                    }
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            formFieldFocusedBorderType: value
                                          }
                                        }
                                      });
                                    }}>
                                    <Select.Option value="solid" label="Solid">
                                      Solid
                                    </Select.Option>
                                    <Select.Option value="dotted" label="Dotted">
                                      Dotted
                                    </Select.Option>
                                    <Select.Option value="dashed" label="Dashed">
                                      Dashed
                                    </Select.Option>
                                    <Select.Option value="double" label="Double">
                                      Double
                                    </Select.Option>
                                    <Select.Option value="" label="None">
                                      None
                                    </Select.Option>
                                  </Select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-background button-props-panel__section-wrapper">
                            <div className="text-background__border">
                              <h3 className="pre-title text-background__label">Input text</h3>
                              <div className="text-background__border-inputs">
                                <Dropdown
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'form'
                                  }
                                  trigger={['click']}
                                  overlay={
                                    <div className="h-f-create-new__fontlist-dropdown-overlay">
                                      {blockInPreview.theme.selectedFonts.length > 0 &&
                                        blockInPreview.theme.selectedFonts.map((font) => {
                                          return (
                                            <div
                                              key={font._id}
                                              className="h-f-create-new__fontlist-dropdown-font"
                                              style={{
                                                fontFamily: font.name,
                                                fontWeight: font.weight,
                                                fontStyle: font.style
                                              }}
                                              onClick={() => {
                                                dispatch({
                                                  type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                                  payload: {
                                                    blockId: highlightedBlock.block._id,
                                                    updates: {
                                                      formFont: font
                                                    }
                                                  }
                                                });
                                              }}>
                                              {font.name}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  }>
                                  <div className="h-f-create-new__fonts-dropdown">
                                    <span
                                      className="h-f-create-new__fonts-dropdown-text"
                                      style={{
                                        fontFamily:
                                          highlightedBlock?.block.blockProperties.formFont?.name,
                                        fontStyle:
                                          highlightedBlock?.block.blockProperties.formFont?.style,
                                        fontWeight:
                                          highlightedBlock?.block.blockProperties.formFont?.weight
                                      }}>
                                      {highlightedBlock?.block.blockProperties.formFont
                                        ? highlightedBlock.block.blockProperties.formFont.name
                                        : 'Choose font'}
                                    </span>
                                    <DownCaret className="h-f-create-new__fonts-dropdown__arrow" />
                                  </div>
                                </Dropdown>
                                <ColorPickerDropdown
                                  colors={blockInPreview.theme.colorPalette.colors}
                                  colorsBlackWhiteGrey={
                                    blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                  }
                                  selectedColor={
                                    highlightedBlock?.block.blockProperties.formFontColor
                                  }
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'form'
                                  }
                                  rightRadius
                                  callback={(color) => {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          formFontColor: color
                                        }
                                      }
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Panel>

                        <Panel header={<h3 className="pre-title">Button options</h3>} key="20">
                          <div className="text-padding" style={{ marginBottom: '20px' }}>
                            <h3
                              className="pre-title text-background__label"
                              style={{ marginBottom: '10px' }}>
                              Position
                            </h3>
                            <div className="text-padding__icons">
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'form'
                                    ? 'text-padding__btn text-padding__btn--disabled'
                                    : highlightedBlock.block.blockProperties.formButtonPosition ===
                                      'flex-start'
                                    ? 'text-padding__btn text-padding__btn--selected'
                                    : 'text-padding__btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'form'
                                  ) {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          formButtonPosition: 'flex-start'
                                        }
                                      }
                                    });
                                  }
                                }}>
                                <AlignLeftIcon />
                                <span>Left</span>
                              </div>
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'form'
                                    ? 'text-padding__btn text-padding__btn--disabled'
                                    : highlightedBlock.block.blockProperties.formButtonPosition ===
                                      'center'
                                    ? 'text-padding__btn text-padding__btn--selected'
                                    : 'text-padding__btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'form'
                                  ) {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          formButtonPosition: 'center'
                                        }
                                      }
                                    });
                                  }
                                }}>
                                <AlignCenterIcon />
                                <span>Center</span>
                              </div>
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'form'
                                    ? 'text-padding__btn text-padding__btn--disabled'
                                    : highlightedBlock.block.blockProperties.formButtonPosition ===
                                      'flex-end'
                                    ? 'text-padding__btn text-padding__btn--selected'
                                    : 'text-padding__btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'form'
                                  ) {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          formButtonPosition: 'flex-end'
                                        }
                                      }
                                    });
                                  }
                                }}>
                                <AlignRightIcon />
                                <span>Right</span>
                              </div>
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'form'
                                    ? 'text-padding__btn text-padding__btn--disabled'
                                    : !highlightedBlock.block.blockProperties.formButtonPosition
                                    ? 'text-padding__btn text-padding__btn--selected'
                                    : 'text-padding__btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'form'
                                  ) {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          formButtonPosition: ''
                                        }
                                      }
                                    });
                                  }
                                }}>
                                <AlignBottomIcon />
                                <span>Wide</span>
                              </div>
                            </div>
                          </div>

                          <div className="text-background button-props-panel__section-wrapper">
                            <div className="text-background__color">
                              <div className="text-background__bg-color">
                                <h3 className="pre-title text-background__label">Color</h3>
                                <ColorPickerDropdown
                                  colors={blockInPreview.theme.colorPalette.colors}
                                  colorsBlackWhiteGrey={
                                    blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                  }
                                  selectedColor={
                                    highlightedBlock?.block.blockProperties
                                      .formButtonBackgroundColor
                                  }
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'form'
                                  }
                                  leftRadius
                                  rightRadius
                                  callback={(color) => {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          formButtonBackgroundColor: color
                                        }
                                      }
                                    });
                                  }}
                                />
                              </div>
                              <div className="text-background__border">
                                <h3 className="pre-title text-background__label">Border</h3>
                                <div className="text-background__border-inputs">
                                  <ColorPickerDropdown
                                    colors={blockInPreview.theme.colorPalette.colors}
                                    colorsBlackWhiteGrey={
                                      blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                    }
                                    selectedColor={
                                      highlightedBlock?.block.blockProperties.formButtonBorderColor
                                    }
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'form'
                                    }
                                    leftRadius
                                    line
                                    callback={(color) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            formButtonBorderColor: color
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <InputNumber
                                    className="text-background__border-thickness"
                                    controls
                                    min={1}
                                    max={10}
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'form'
                                    }
                                    defaultValue={0}
                                    value={
                                      highlightedBlock?.block.blockProperties
                                        .formButtonBorderThickness * 16
                                    }
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            formButtonBorderThickness: value / 16
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <Select
                                    className="dropdown-small text-background__border-type"
                                    placeholder="Type"
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'form'
                                    }
                                    value={
                                      highlightedBlock?.block.blockProperties.formButtonBorderType
                                    }
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            formButtonBorderType: value
                                          }
                                        }
                                      });
                                    }}>
                                    <Select.Option value="solid" label="Solid">
                                      Solid
                                    </Select.Option>
                                    <Select.Option value="dotted" label="Dotted">
                                      Dotted
                                    </Select.Option>
                                    <Select.Option value="dashed" label="Dashed">
                                      Dashed
                                    </Select.Option>
                                    <Select.Option value="double" label="Double">
                                      Double
                                    </Select.Option>
                                    <Select.Option value="" label="None">
                                      None
                                    </Select.Option>
                                  </Select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="button-props-panel__slider">
                            <Slider
                              min={0}
                              max={30000}
                              step={625}
                              disabled={
                                !highlightedBlock ||
                                highlightedBlock.block.blockType.name !== 'form'
                              }
                              tooltipVisible={false}
                              value={
                                highlightedBlock?.block.blockProperties.formButtonBorderRadius?.replace(
                                  'em',
                                  ''
                                ) * 10000
                              }
                              onChange={(value) => {
                                dispatch({
                                  type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                  payload: {
                                    blockId: highlightedBlock.block._id,
                                    updates: {
                                      formButtonBorderRadius: `${value / 10000}em`
                                    }
                                  }
                                });
                              }}
                            />
                            {highlightedBlock &&
                              highlightedBlock.block.blockType.name === 'form' && (
                                <span>
                                  {highlightedBlock.block.blockProperties.formButtonBorderRadius?.replace(
                                    'em',
                                    ''
                                  ) * 16}
                                  px
                                </span>
                              )}
                          </div>

                          <div className="button-props-panel__btn-effects">
                            <h3
                              className="pre-title text-background__label"
                              style={{ marginBottom: '10px' }}>
                              Effects
                            </h3>
                            <div className="button-props-panel__btn-effects-choices choice-btn-group">
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'form'
                                    ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--disabled'
                                    : highlightedBlock?.block.blockProperties.formButtonHasShadow &&
                                      !highlightedBlock?.block.blockProperties.formButtonInnerShadow
                                    ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                                    : 'button-props-panel__btn-effects-choice choice-btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'form'
                                  ) {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: highlightedBlock.block.blockProperties
                                          .formButtonHasShadow
                                          ? highlightedBlock.block.blockProperties
                                              .formButtonInnerShadow
                                            ? {
                                                formButtonInnerShadow: false,
                                                formButtonHasShadow: true
                                              }
                                            : {
                                                formButtonHasShadow: false
                                              }
                                          : {
                                              formButtonInnerShadow: false,
                                              formButtonHasShadow: true
                                            }
                                      }
                                    });
                                  }
                                }}>
                                Shadow
                              </div>
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'form'
                                    ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--disabled'
                                    : highlightedBlock?.block.blockProperties.formButtonHasShadow &&
                                      highlightedBlock?.block.blockProperties.formButtonInnerShadow
                                    ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                                    : 'button-props-panel__btn-effects-choice choice-btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'form'
                                  ) {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: highlightedBlock.block.blockProperties
                                          .formButtonHasShadow
                                          ? highlightedBlock.block.blockProperties
                                              .formButtonInnerShadow
                                            ? {
                                                formButtonHasShadow: false
                                              }
                                            : {
                                                formButtonInnerShadow: true,
                                                formButtonHasShadow: true
                                              }
                                          : {
                                              formButtonInnerShadow: true,
                                              formButtonHasShadow: true
                                            }
                                      }
                                    });
                                  }
                                }}>
                                Inner Shadow
                              </div>
                            </div>
                          </div>
                        </Panel>

                        <Panel header={<h3 className="pre-title">Form background</h3>} key="21">
                          <div className="text-background">
                            <div className="text-background__color">
                              <div className="text-background__bg-color">
                                <h3 className="pre-title text-background__label">Color</h3>
                                <ColorPickerDropdown
                                  colors={blockInPreview.theme.colorPalette.colors}
                                  colorsBlackWhiteGrey={
                                    blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                  }
                                  selectedColor={
                                    highlightedBlock?.block.blockProperties.formBackgroundColor
                                  }
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'form'
                                  }
                                  leftRadius
                                  rightRadius
                                  callback={(color) => {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          formBackgroundColor: color
                                        }
                                      }
                                    });
                                  }}
                                />
                              </div>
                              <div className="text-background__border">
                                <h3 className="pre-title text-background__label">Border</h3>
                                <div className="text-background__border-inputs">
                                  <ColorPickerDropdown
                                    colors={blockInPreview.theme.colorPalette.colors}
                                    colorsBlackWhiteGrey={
                                      blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                    }
                                    selectedColor={
                                      highlightedBlock?.block.blockProperties.formBorderColor
                                    }
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'form'
                                    }
                                    leftRadius
                                    line
                                    callback={(color) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            formBorderColor: color
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <InputNumber
                                    className="text-background__border-thickness"
                                    controls
                                    min={1}
                                    max={10}
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'form'
                                    }
                                    defaultValue={0}
                                    value={
                                      highlightedBlock?.block.blockProperties.formBorderThickness *
                                      16
                                    }
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            formBorderThickness: value / 16
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <Select
                                    className="dropdown-small text-background__border-type"
                                    placeholder="Type"
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'form'
                                    }
                                    value={highlightedBlock?.block.blockProperties.formBorderType}
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            formBorderType: value
                                          }
                                        }
                                      });
                                    }}>
                                    <Select.Option value="solid" label="Solid">
                                      Solid
                                    </Select.Option>
                                    <Select.Option value="dotted" label="Dotted">
                                      Dotted
                                    </Select.Option>
                                    <Select.Option value="dashed" label="Dashed">
                                      Dashed
                                    </Select.Option>
                                    <Select.Option value="double" label="Double">
                                      Double
                                    </Select.Option>
                                    <Select.Option value="" label="None">
                                      None
                                    </Select.Option>
                                  </Select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Panel>

                        <Panel header={<h3 className="pre-title">Link</h3>} key="22">
                          <div className="add-info-form" style={{ marginTop: 0 }}>
                            <div className="add-info-form_item">
                              <h4 className="add-info-form_label">Email</h4>
                              <Input
                                className="emailInput"
                                placeholder="name@email.com"
                                prefix={<EmailIcon />}
                                disabled={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'form'
                                }
                              />
                            </div>
                            <Checkbox
                              style={{ marginTop: '10px' }}
                              disabled={
                                !highlightedBlock ||
                                highlightedBlock.block.blockType.name !== 'form'
                              }>
                              Use publisher email
                            </Checkbox>
                          </div>
                        </Panel>
                      </>
                    )}

                    {selectedBlockType.name === 'survey' && (
                      <>
                        <Panel header={<h3 className="pre-title">Surveys</h3>} key="23">
                          <div className="surveys-templates">
                            {surveyTemplates?.length > 0 &&
                              surveyTemplates.map((template) => {
                                return (
                                  <div
                                    className="surveys-templates__container"
                                    onClick={() =>
                                      addEmptyBlock('survey', {
                                        ...template,
                                        surveyQuestion: template.surveyQuestion.replace(
                                          '**default**',
                                          bodyTextFont.name.toLowerCase().replace(' -', '')
                                        )
                                      })
                                    }>
                                    <span>Checkbox type</span>
                                  </div>
                                );
                              })}
                          </div>
                        </Panel>

                        <Panel header={<h3 className="pre-title">Question type</h3>} key="24">
                          <Select
                            className="dropdown-small fieldType"
                            placeholder="Type"
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock.block.blockType.name !== 'survey'
                            }
                            style={{ width: '100%', marginBottom: '15px' }}
                            value={highlightedBlock?.block.blockProperties.surveyType}
                            onChange={(value) => {
                              dispatch({
                                type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                payload: {
                                  blockId: highlightedBlock.block._id,
                                  updates: {
                                    surveyType: value
                                  }
                                }
                              });
                            }}>
                            <Select.Option value="radio" className="fieldType">
                              <RadioIcon />
                              <span>Multiple choice</span>
                            </Select.Option>
                            <Select.Option value="checkbox" className="fieldType">
                              <CheckboxGreyIcon />
                              <span>Checkbox</span>
                            </Select.Option>
                          </Select>
                          {highlightedBlock?.block.blockProperties.surveyOptions?.length > 0 &&
                            highlightedBlock.block.blockProperties.surveyOptions.map(
                              (option, index) => (
                                <Input
                                  style={{ marginBottom: '10px' }}
                                  key={index}
                                  value={option}
                                  onChange={(e) => {
                                    dispatch({
                                      type: EDIT_SURVEY_OPTION,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        index,
                                        value: e.target.value
                                      }
                                    });
                                  }}
                                  suffix={
                                    <DeleteIcon
                                      className="removeOptionIcon"
                                      onClick={() => {
                                        dispatch({
                                          type: REMOVE_SURVEY_OPTION,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            index
                                          }
                                        });
                                      }}
                                    />
                                  }
                                />
                              )
                            )}
                          <Button
                            className="add-button"
                            style={{ width: '100%', marginTop: '5px' }}
                            disabled={
                              !highlightedBlock ||
                              highlightedBlock.block.blockType.name !== 'survey'
                            }
                            onClick={() => {
                              if (
                                highlightedBlock &&
                                highlightedBlock.block.blockType.name === 'survey'
                              ) {
                                dispatch({
                                  type: ADD_SURVEY_OPTION,
                                  payload: {
                                    blockId: highlightedBlock.block._id,
                                    surveyOption: `Choice ${
                                      highlightedBlock.block.blockProperties.surveyOptions?.length +
                                      1
                                    }`
                                  }
                                });
                              }
                            }}>
                            Add an option
                          </Button>
                          <div className="surveyRequired">
                            <span>Required to answer</span>
                            <Switch
                              disabled={
                                !highlightedBlock ||
                                highlightedBlock.block.blockType.name !== 'survey'
                              }
                              checked={highlightedBlock?.block.blockProperties.surveyAnswerRequired}
                              onChange={(checked) => {
                                if (
                                  highlightedBlock &&
                                  highlightedBlock.block.blockType.name === 'survey'
                                ) {
                                  dispatch({
                                    type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                    payload: {
                                      blockId: highlightedBlock.block._id,
                                      updates: {
                                        surveyAnswerRequired: checked
                                      }
                                    }
                                  });
                                }
                              }}
                            />
                          </div>
                        </Panel>

                        <Panel header={<h3 className="pre-title">Question style</h3>} key="25">
                          <div className="text-background button-props-panel__section-wrapper">
                            <div className="text-background__color">
                              <div className="text-background__bg-color">
                                <h3 className="pre-title text-background__label">Default</h3>
                                <h3 className="pre-title text-background__label">Color</h3>
                                <ColorPickerDropdown
                                  colors={blockInPreview.theme.colorPalette.colors}
                                  colorsBlackWhiteGrey={
                                    blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                  }
                                  selectedColor={
                                    highlightedBlock?.block.blockProperties
                                      .surveyOptionBackgroundColor
                                  }
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'survey'
                                  }
                                  leftRadius
                                  rightRadius
                                  callback={(color) => {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          surveyOptionBackgroundColor: color
                                        }
                                      }
                                    });
                                  }}
                                />
                              </div>
                              <div className="text-background__border">
                                <h3
                                  className="pre-title text-background__label"
                                  style={{ marginTop: '26px' }}>
                                  Border
                                </h3>
                                <div className="text-background__border-inputs">
                                  <ColorPickerDropdown
                                    colors={blockInPreview.theme.colorPalette.colors}
                                    colorsBlackWhiteGrey={
                                      blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                    }
                                    selectedColor={
                                      highlightedBlock?.block.blockProperties
                                        .surveyOptionBorderColor
                                    }
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'survey'
                                    }
                                    leftRadius
                                    line
                                    callback={(color) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            surveyOptionBorderColor: color
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <InputNumber
                                    className="text-background__border-thickness"
                                    controls
                                    min={1}
                                    max={10}
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'survey'
                                    }
                                    defaultValue={0}
                                    value={
                                      highlightedBlock?.block.blockProperties
                                        .surveyOptionBorderThickness * 16
                                    }
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            surveyOptionBorderThickness: value / 16
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <Select
                                    className="dropdown-small text-background__border-type"
                                    placeholder="Type"
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'survey'
                                    }
                                    value={
                                      highlightedBlock?.block.blockProperties.surveyOptionBorderType
                                    }
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            surveyOptionBorderType: value
                                          }
                                        }
                                      });
                                    }}>
                                    <Select.Option value="solid" label="Solid">
                                      Solid
                                    </Select.Option>
                                    <Select.Option value="dotted" label="Dotted">
                                      Dotted
                                    </Select.Option>
                                    <Select.Option value="dashed" label="Dashed">
                                      Dashed
                                    </Select.Option>
                                    <Select.Option value="double" label="Double">
                                      Double
                                    </Select.Option>
                                    <Select.Option value="" label="None">
                                      None
                                    </Select.Option>
                                  </Select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-background button-props-panel__section-wrapper">
                            <div className="text-background__color">
                              <div className="text-background__bg-color">
                                <h3 className="pre-title text-background__label">Active</h3>
                                <h3 className="pre-title text-background__label">Color</h3>
                                <ColorPickerDropdown
                                  colors={blockInPreview.theme.colorPalette.colors}
                                  colorsBlackWhiteGrey={
                                    blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                  }
                                  selectedColor={
                                    highlightedBlock?.block.blockProperties
                                      .surveyOptionActiveBackgroundColor
                                  }
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'survey'
                                  }
                                  leftRadius
                                  rightRadius
                                  callback={(color) => {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          surveyOptionActiveBackgroundColor: color
                                        }
                                      }
                                    });
                                  }}
                                />
                              </div>
                              <div className="text-background__border">
                                <h3
                                  className="pre-title text-background__label"
                                  style={{ marginTop: '26px' }}>
                                  Border
                                </h3>
                                <div className="text-background__border-inputs">
                                  <ColorPickerDropdown
                                    colors={blockInPreview.theme.colorPalette.colors}
                                    colorsBlackWhiteGrey={
                                      blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                    }
                                    selectedColor={
                                      highlightedBlock?.block.blockProperties
                                        .surveyOptionActiveBorderColor
                                    }
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'survey'
                                    }
                                    leftRadius
                                    line
                                    callback={(color) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            surveyOptionActiveBorderColor: color
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <InputNumber
                                    className="text-background__border-thickness"
                                    controls
                                    min={1}
                                    max={10}
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'survey'
                                    }
                                    defaultValue={0}
                                    value={
                                      highlightedBlock?.block.blockProperties
                                        .surveyOptionActiveBorderThickness * 16
                                    }
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            surveyOptionActiveBorderThickness: value / 16
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <Select
                                    className="dropdown-small text-background__border-type"
                                    placeholder="Type"
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'survey'
                                    }
                                    value={
                                      highlightedBlock?.block.blockProperties
                                        .surveyOptionActiveBorderType
                                    }
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            surveyOptionActiveBorderType: value
                                          }
                                        }
                                      });
                                    }}>
                                    <Select.Option value="solid" label="Solid">
                                      Solid
                                    </Select.Option>
                                    <Select.Option value="dotted" label="Dotted">
                                      Dotted
                                    </Select.Option>
                                    <Select.Option value="dashed" label="Dashed">
                                      Dashed
                                    </Select.Option>
                                    <Select.Option value="double" label="Double">
                                      Double
                                    </Select.Option>
                                    <Select.Option value="" label="None">
                                      None
                                    </Select.Option>
                                  </Select>
                                </div>
                              </div>
                            </div>
                            <Checkbox
                              disabled={
                                !highlightedBlock ||
                                highlightedBlock.block.blockType.name !== 'survey'
                              }
                              checked={highlightedBlock?.block.blockProperties.surveyPreviewActive}
                              onChange={(e) => {
                                dispatch({
                                  type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                  payload: {
                                    blockId: highlightedBlock.block._id,
                                    updates: {
                                      surveyPreviewActive: e.target.checked
                                    }
                                  }
                                });
                              }}>
                              Preview Active
                            </Checkbox>
                          </div>

                          <div className="button-props-panel__slider">
                            <Slider
                              min={0}
                              max={30000}
                              step={625}
                              disabled={
                                !highlightedBlock ||
                                highlightedBlock.block.blockType.name !== 'survey'
                              }
                              tooltipVisible={false}
                              value={
                                highlightedBlock?.block.blockProperties.surveyOptionBorderRadius?.replace(
                                  'em',
                                  ''
                                ) * 10000
                              }
                              onChange={(value) => {
                                dispatch({
                                  type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                  payload: {
                                    blockId: highlightedBlock.block._id,
                                    updates: {
                                      surveyOptionBorderRadius: `${value / 10000}em`
                                    }
                                  }
                                });
                              }}
                            />
                            {highlightedBlock &&
                              highlightedBlock.block.blockType.name === 'survey' && (
                                <span>
                                  {highlightedBlock.block.blockProperties.surveyOptionBorderRadius?.replace(
                                    'em',
                                    ''
                                  ) * 16}
                                  px
                                </span>
                              )}
                          </div>

                          <div className="text-background button-props-panel__section-wrapper">
                            <div className="text-background__border">
                              <h3 className="pre-title text-background__label">Option text</h3>
                              <div className="text-background__border-inputs">
                                <Dropdown
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'survey'
                                  }
                                  trigger={['click']}
                                  overlay={
                                    <div className="h-f-create-new__fontlist-dropdown-overlay">
                                      {blockInPreview.theme.selectedFonts.length > 0 &&
                                        blockInPreview.theme.selectedFonts.map((font) => {
                                          return (
                                            <div
                                              key={font._id}
                                              className="h-f-create-new__fontlist-dropdown-font"
                                              style={{
                                                fontFamily: font.name,
                                                fontWeight: font.weight,
                                                fontStyle: font.style
                                              }}
                                              onClick={() => {
                                                dispatch({
                                                  type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                                  payload: {
                                                    blockId: highlightedBlock.block._id,
                                                    updates: {
                                                      surveyOptionFont: font
                                                    }
                                                  }
                                                });
                                              }}>
                                              {font.name}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  }>
                                  <div className="h-f-create-new__fonts-dropdown">
                                    <span
                                      className="h-f-create-new__fonts-dropdown-text"
                                      style={{
                                        fontFamily:
                                          highlightedBlock?.block.blockProperties.surveyOptionFont
                                            ?.name,
                                        fontStyle:
                                          highlightedBlock?.block.blockProperties.surveyOptionFont
                                            ?.style,
                                        fontWeight:
                                          highlightedBlock?.block.blockProperties.surveyOptionFont
                                            ?.weight
                                      }}>
                                      {highlightedBlock?.block.blockProperties.surveyOptionFont
                                        ? highlightedBlock.block.blockProperties.surveyOptionFont
                                            .name
                                        : 'Choose font'}
                                    </span>
                                    <DownCaret className="h-f-create-new__fonts-dropdown__arrow" />
                                  </div>
                                </Dropdown>
                                <ColorPickerDropdown
                                  colors={blockInPreview.theme.colorPalette.colors}
                                  colorsBlackWhiteGrey={
                                    blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                  }
                                  selectedColor={
                                    highlightedBlock?.block.blockProperties.surveyOptionFontColor
                                  }
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'survey'
                                  }
                                  rightRadius
                                  callback={(color) => {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          surveyOptionFontColor: color
                                        }
                                      }
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Panel>
                        <Panel header={<h3 className="pre-title">Question background</h3>} key="26">
                          <div className="text-background">
                            <div className="text-background__color">
                              <div className="text-background__bg-color">
                                <h3 className="pre-title text-background__label">Color</h3>
                                <ColorPickerDropdown
                                  colors={blockInPreview.theme.colorPalette.colors}
                                  colorsBlackWhiteGrey={
                                    blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                  }
                                  selectedColor={
                                    highlightedBlock?.block.blockProperties.surveyBackgroundColor
                                  }
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'survey'
                                  }
                                  leftRadius
                                  rightRadius
                                  callback={(color) => {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          surveyBackgroundColor: color
                                        }
                                      }
                                    });
                                  }}
                                />
                              </div>
                              <div className="text-background__border">
                                <h3 className="pre-title text-background__label">Border</h3>
                                <div className="text-background__border-inputs">
                                  <ColorPickerDropdown
                                    colors={blockInPreview.theme.colorPalette.colors}
                                    colorsBlackWhiteGrey={
                                      blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                    }
                                    selectedColor={
                                      highlightedBlock?.block.blockProperties.surveyBorderColor
                                    }
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'survey'
                                    }
                                    leftRadius
                                    line
                                    callback={(color) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            surveyBorderColor: color
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <InputNumber
                                    className="text-background__border-thickness"
                                    controls
                                    min={1}
                                    max={10}
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'survey'
                                    }
                                    defaultValue={0}
                                    value={
                                      highlightedBlock?.block.blockProperties
                                        .surveyBorderThickness * 16
                                    }
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            surveyBorderThickness: value / 16
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <Select
                                    className="dropdown-small text-background__border-type"
                                    placeholder="Type"
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'survey'
                                    }
                                    value={highlightedBlock?.block.blockProperties.surveyBorderType}
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            surveyBorderType: value
                                          }
                                        }
                                      });
                                    }}>
                                    <Select.Option value="solid" label="Solid">
                                      Solid
                                    </Select.Option>
                                    <Select.Option value="dotted" label="Dotted">
                                      Dotted
                                    </Select.Option>
                                    <Select.Option value="dashed" label="Dashed">
                                      Dashed
                                    </Select.Option>
                                    <Select.Option value="double" label="Double">
                                      Double
                                    </Select.Option>
                                    <Select.Option value="" label="None">
                                      None
                                    </Select.Option>
                                  </Select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </>
                    )}
                    {selectedBlockType.name === 'media' && (
                      <>
                        <Panel header={<h3 className="pre-title">Media gallery</h3>} key="27">
                          <Input
                            onChange={(event) => {
                              setSearchTerm(event.target.value);
                            }}
                            placeholder="Search Media"
                            prefix={<LinkSearch style={{ marginRight: '5px' }} />}
                            allowClear
                          />
                          <Select
                            placeholder="Choose media type"
                            className="dropdown-small fieldType"
                            style={{ width: '100%', marginTop: '10px' }}
                            value={mediaType}
                            onChange={(value) => setMediaType(value)}>
                            <Select.Option value="image" className="fieldType">
                              <MediaIcon style={{ marginRight: '5px' }} />
                              Images
                            </Select.Option>
                            <Select.Option value="video" className="fieldType">
                              <PlayIcon style={{ marginRight: '5px' }} />
                              Videos
                            </Select.Option>
                          </Select>
                          <Select
                            placeholder="Choose Orientation"
                            className="dropdown-small"
                            style={{ width: '100%', marginTop: '10px' }}
                            value={mediaOrientation}
                            onChange={(value) => setMediaOrientation(value)}
                            allowClear>
                            <Select.Option value="portrait" className="fieldType">
                              Portrait
                            </Select.Option>
                            <Select.Option value="landscape" className="fieldType">
                              Landscape
                            </Select.Option>
                            <Select.Option value="square" className="fieldType">
                              Square
                            </Select.Option>
                          </Select>
                          {mediaType === 'image' && (
                            <ImageListMedia
                              mediaType={mediaType}
                              token={currentuser.data.token}
                              SERVER_URL={SERVER_URL}
                              callback={(imageUrl) => {
                                addEmptyBlock('media', {
                                  mediaType: 'image',
                                  mediaPadding: 0,
                                  mediaImageSrc: imageUrl,
                                  mediaImageIntensity: 1,
                                  mediaImageBrightness: 1,
                                  mediaImageContrast: 1,
                                  mediaImageSaturate: 1
                                });
                              }}
                              searchKeyword={searchTerm}
                              orientation={mediaOrientation}
                            />
                          )}
                          {mediaType === 'video' && (
                            <VideoList
                              token={currentuser.data.token}
                              SERVER_URL={SERVER_URL}
                              callback={(videoUrl, videoThumbnail) => {
                                addEmptyBlock('media', {
                                  mediaType: 'video',
                                  mediaPadding: 0,
                                  mediaVideoSrc: videoUrl,
                                  mediaVideoThumbnail: videoThumbnail,
                                  autoplay: false,
                                  loop: false,
                                  muted: false,
                                  volume: 1
                                });
                              }}
                              searchKeyword={searchTerm}
                              orientation={mediaOrientation}
                            />
                          )}
                        </Panel>

                        {highlightedBlock &&
                          highlightedBlock.block.blockType.name === 'media' &&
                          highlightedBlock.block.blockProperties.mediaType === 'image' && (
                            <>
                              <Panel header={<h3 className="pre-title">Edit image</h3>} key="28">
                                <div className="button-props-panel__btn-effects-choices choice-btn-group">
                                  <div
                                    onClick={() => setEditImageOption('filter')}
                                    className={
                                      editImageOption === 'filter'
                                        ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                                        : 'button-props-panel__btn-effects-choice choice-btn'
                                    }>
                                    Filter
                                  </div>
                                  <div
                                    onClick={() => setEditImageOption('adjust')}
                                    className={
                                      editImageOption === 'adjust'
                                        ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                                        : 'button-props-panel__btn-effects-choice choice-btn'
                                    }>
                                    Adjust
                                  </div>
                                </div>
                                {editImageOption === 'filter' && (
                                  <div>
                                    <h3 className="pre-title text-background__label">Intensity</h3>
                                    <Row
                                      gutter={16}
                                      style={{ marginBottom: '20px', marginTop: '5px' }}>
                                      <Col md={20}>
                                        <Slider
                                          style={{ width: '100%' }}
                                          min={0}
                                          max={100}
                                          tooltipVisible={false}
                                          value={
                                            highlightedBlock.block.blockProperties
                                              .mediaImageIntensity * 100
                                          }
                                          onChange={(value) => {
                                            dispatch({
                                              type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                              payload: {
                                                blockId: highlightedBlock.block._id,
                                                updates: {
                                                  mediaImageIntensity: value / 100
                                                }
                                              }
                                            });
                                          }}
                                        />
                                      </Col>
                                      <Col
                                        md={4}
                                        style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <span>
                                          {Math.round(
                                            highlightedBlock.block.blockProperties
                                              .mediaImageIntensity * 100
                                          )}
                                          %
                                        </span>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                                {editImageOption === 'adjust' && (
                                  <div style={{ marginTop: '20px' }}>
                                    <h3 className="pre-title text-background__label">Brightness</h3>
                                    <Row
                                      gutter={16}
                                      style={{ marginBottom: '20px', marginTop: '5px' }}>
                                      <Col md={20}>
                                        <Slider
                                          style={{ width: '100%' }}
                                          min={0}
                                          max={300}
                                          tooltipVisible={false}
                                          value={
                                            highlightedBlock.block.blockProperties
                                              .mediaImageBrightness * 100
                                          }
                                          onChange={(value) => {
                                            dispatch({
                                              type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                              payload: {
                                                blockId: highlightedBlock.block._id,
                                                updates: {
                                                  mediaImageBrightness: value / 100
                                                }
                                              }
                                            });
                                          }}
                                        />
                                      </Col>
                                      <Col
                                        md={4}
                                        style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <span>
                                          {Math.round(
                                            highlightedBlock.block.blockProperties
                                              .mediaImageBrightness * 100
                                          )}
                                          %
                                        </span>
                                      </Col>
                                    </Row>
                                    <h3 className="pre-title text-background__label">Contrast</h3>
                                    <Row
                                      gutter={16}
                                      style={{ marginBottom: '20px', marginTop: '5px' }}>
                                      <Col md={20}>
                                        <Slider
                                          style={{ width: '100%' }}
                                          min={0}
                                          max={300}
                                          tooltipVisible={false}
                                          value={
                                            highlightedBlock.block.blockProperties
                                              .mediaImageContrast * 100
                                          }
                                          onChange={(value) => {
                                            dispatch({
                                              type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                              payload: {
                                                blockId: highlightedBlock.block._id,
                                                updates: {
                                                  mediaImageContrast: value / 100
                                                }
                                              }
                                            });
                                          }}
                                        />
                                      </Col>
                                      <Col
                                        md={4}
                                        style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <span>
                                          {Math.round(
                                            highlightedBlock.block.blockProperties
                                              .mediaImageContrast * 100
                                          )}
                                          %
                                        </span>
                                      </Col>
                                    </Row>
                                    <h3 className="pre-title text-background__label">Saturation</h3>
                                    <Row
                                      gutter={16}
                                      style={{ marginBottom: '20px', marginTop: '5px' }}>
                                      <Col md={20}>
                                        <Slider
                                          style={{ width: '100%' }}
                                          min={0}
                                          max={300}
                                          tooltipVisible={false}
                                          value={
                                            highlightedBlock.block.blockProperties
                                              .mediaImageSaturate * 100
                                          }
                                          onChange={(value) => {
                                            dispatch({
                                              type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                              payload: {
                                                blockId: highlightedBlock.block._id,
                                                updates: {
                                                  mediaImageSaturate: value / 100
                                                }
                                              }
                                            });
                                          }}
                                        />
                                      </Col>
                                      <Col
                                        md={4}
                                        style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <span>
                                          {Math.round(
                                            highlightedBlock.block.blockProperties
                                              .mediaImageSaturate * 100
                                          )}
                                          %
                                        </span>
                                      </Col>
                                    </Row>
                                    <span
                                      className="defaultText"
                                      onClick={() => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              mediaImageIntensity: 1,
                                              mediaImageBrightness: 1,
                                              mediaImageContrast: 1,
                                              mediaImageSaturate: 1
                                            }
                                          }
                                        });
                                      }}>
                                      Reset to default
                                    </span>
                                  </div>
                                )}
                              </Panel>

                              <Panel header={<h3 className="pre-title">Crop</h3>} key="29">
                                <Row gutter={8}>
                                  <Col md={12}>
                                    <Button
                                      onClick={() => setImageCropModalVisible(true)}
                                      style={{ width: '100%' }}
                                      type="primary">
                                      Crop
                                    </Button>
                                  </Col>
                                </Row>
                                <h3
                                  style={{ marginTop: '15px', marginBottom: '10px' }}
                                  className="pre-title text-background__label">
                                  Crop to shape
                                </h3>
                                <Select
                                  style={{ width: '100%' }}
                                  className="dropdown-small"
                                  placeholder="Choose shape"
                                  allowClear={true}
                                  value={highlightedBlock.block.blockProperties.mediaImageClipPath}
                                  onChange={(value) => {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          mediaImageClipPath: value
                                        }
                                      }
                                    });
                                  }}>
                                  <Select.Option value="circle(50% at 50% 50%)">
                                    Circle
                                  </Select.Option>
                                  <Select.Option value="polygon(50% 0%, 0% 100%, 100% 100%)">
                                    Triangle
                                  </Select.Option>
                                  <Select.Option value="polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)">
                                    Octagon
                                  </Select.Option>
                                  <Select.Option value="polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%)">
                                    Left Arrow
                                  </Select.Option>
                                  <Select.Option value="polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%)">
                                    Right Arrow
                                  </Select.Option>
                                  <Select.Option value="polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)">
                                    Star
                                  </Select.Option>
                                </Select>

                                {imageCropModalVisible && (
                                  <ImageCropModal
                                    imageCropModalVisible={imageCropModalVisible}
                                    setImageCropModalVisible={setImageCropModalVisible}
                                    image={highlightedBlock.block.blockProperties.mediaImageSrc}
                                    SERVER_URL={SERVER_URL}
                                    token={currentuser.data.token}
                                    setNewImage={(imgUrl) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            mediaImageSrc: imgUrl
                                          }
                                        }
                                      });
                                    }}
                                  />
                                )}
                              </Panel>
                            </>
                          )}
                        {highlightedBlock &&
                          highlightedBlock.block.blockType.name === 'media' &&
                          highlightedBlock.block.blockProperties.mediaType === 'video' && (
                            <>
                              <Panel header={<h3 className="pre-title">Playback</h3>} key="30">
                                <Row gutter={16} style={{ marginBottom: '10px' }}>
                                  <Col md={20}>Autoplay</Col>
                                  <Col md={4}>
                                    <Switch
                                      checked={highlightedBlock.block.blockProperties.autoplay}
                                      onChange={(checked) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              autoplay: checked
                                            }
                                          }
                                        });
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col md={20}>Loop video</Col>
                                  <Col md={4}>
                                    <Switch
                                      checked={highlightedBlock.block.blockProperties.loop}
                                      onChange={(checked) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              loop: checked
                                            }
                                          }
                                        });
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Panel>

                              <Panel header={<h3 className="pre-title">Audio</h3>} key="31">
                                <h3 className="pre-title text-background__label">Volume</h3>
                                <Row gutter={16} style={{ marginBottom: '20px', marginTop: '5px' }}>
                                  <Col md={20}>
                                    <Slider
                                      style={{ width: '100%' }}
                                      min={0}
                                      max={100}
                                      value={highlightedBlock.block.blockProperties.volume * 100}
                                      onChange={(checked) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              volume: checked / 100
                                            }
                                          }
                                        });
                                      }}
                                    />
                                  </Col>
                                  <Col
                                    md={4}
                                    style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <span>
                                      {Math.round(
                                        highlightedBlock.block.blockProperties.volume * 100
                                      )}
                                    </span>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col md={20}>Mute video</Col>
                                  <Col md={4}>
                                    <Switch
                                      checked={highlightedBlock.block.blockProperties.muted}
                                      onChange={(value) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              muted: value
                                            }
                                          }
                                        });
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Panel>
                            </>
                          )}
                      </>
                    )}
                    {selectedBlockType.name === 'shape' && (
                      <>
                        <Panel header={<h3 className="pre-title">Shapes</h3>} key="32">
                          <Shapes addEmptyBlock={addEmptyBlock} />
                        </Panel>
                        <Panel
                          header={<h3 className="pre-title">Shape color & border</h3>}
                          key="33">
                          <div className="button-props-panel__section-wrapper button-props-panel__section-wrapper">
                            <div className="button-props-panel__color">
                              <div className="button-props-panel__btn-color">
                                <h3 className="pre-title button-props-panel__label">Color</h3>
                                <ColorPickerDropdown
                                  colors={blockInPreview.theme.colorPalette.colors}
                                  colorsBlackWhiteGrey={
                                    blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                  }
                                  selectedColor={highlightedBlock?.block.blockProperties.shapeColor}
                                  disabled={
                                    !highlightedBlock ||
                                    highlightedBlock.block.blockType.name !== 'shape'
                                  }
                                  leftRadius
                                  rightRadius
                                  callback={(color) => {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: {
                                          shapeColor: color
                                        }
                                      }
                                    });
                                  }}
                                />
                              </div>
                              <div className="button-props-panel__border">
                                <h3 className="pre-title button-props-panel__label">Border</h3>
                                <div className="button-props-panel__border-inputs">
                                  <ColorPickerDropdown
                                    colors={blockInPreview.theme.colorPalette.colors}
                                    colorsBlackWhiteGrey={
                                      blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                    }
                                    selectedColor={
                                      highlightedBlock?.block.blockProperties.shapeBorderColor
                                    }
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'shape'
                                    }
                                    leftRadius
                                    callback={(color) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            shapeBorderColor: color
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <InputNumber
                                    className="button-props-panel__border-thickness"
                                    min={0}
                                    max={10}
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'shape'
                                    }
                                    value={
                                      highlightedBlock?.block.blockProperties.shapeBorderThickness?.replace(
                                        'em',
                                        ''
                                      ) * 16
                                    }
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            shapeBorderThickness: `${value / 16}em`
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <Select
                                    className="dropdown-small button-props-panel__border-type"
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'shape'
                                    }
                                    value={highlightedBlock?.block.blockProperties.shapeBorderType}
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            shapeBorderType: value
                                          }
                                        }
                                      });
                                    }}>
                                    <Select.Option value="solid" label="Solid">
                                      Solid
                                    </Select.Option>
                                    <Select.Option value="dotted" label="Dotted">
                                      Dotted
                                    </Select.Option>
                                    <Select.Option value="dashed" label="Dashed">
                                      Dashed
                                    </Select.Option>
                                    <Select.Option value="" label="None">
                                      None
                                    </Select.Option>
                                  </Select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Panel>
                        <Panel header={<h3 className="pre-title">Shape Shadow</h3>} key="34">
                          <div className="button-props-panel__btn-effects">
                            <div className="button-props-panel__btn-effects-choices choice-btn-group">
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'shape'
                                    ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--disabled'
                                    : highlightedBlock?.block.blockProperties.shapeHasShadow &&
                                      !highlightedBlock.block.blockProperties.shapeInnerShadow
                                    ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                                    : 'button-props-panel__btn-effects-choice choice-btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'shape'
                                  ) {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: highlightedBlock.block.blockProperties
                                          .shapeHasShadow
                                          ? highlightedBlock.block.blockProperties.shapeInnerShadow
                                            ? {
                                                shapeInnerShadow: false,
                                                shapeHasShadow: true
                                              }
                                            : {
                                                shapeHasShadow: false
                                              }
                                          : {
                                              shapeInnerShadow: false,
                                              shapeHasShadow: true
                                            }
                                      }
                                    });
                                  }
                                }}>
                                Drop shadow
                              </div>
                              <div
                                className={
                                  !highlightedBlock ||
                                  highlightedBlock.block.blockType.name !== 'shape'
                                    ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--disabled'
                                    : highlightedBlock?.block.blockProperties.shapeHasShadow &&
                                      highlightedBlock?.block.blockProperties.shapeInnerShadow
                                    ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                                    : 'button-props-panel__btn-effects-choice choice-btn'
                                }
                                onClick={() => {
                                  if (
                                    highlightedBlock &&
                                    highlightedBlock.block.blockType.name === 'shape'
                                  ) {
                                    dispatch({
                                      type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                      payload: {
                                        blockId: highlightedBlock.block._id,
                                        updates: highlightedBlock.block.blockProperties
                                          .shapeHasShadow
                                          ? highlightedBlock.block.blockProperties.shapeInnerShadow
                                            ? {
                                                shapeHasShadow: false
                                              }
                                            : {
                                                shapeInnerShadow: true,
                                                shapeHasShadow: true
                                              }
                                          : {
                                              shapeInnerShadow: true,
                                              shapeHasShadow: true
                                            }
                                      }
                                    });
                                  }
                                }}>
                                Inner Shadow
                              </div>
                            </div>
                            {advancedMode && (
                              <>
                                <div className="button-props-panel__btn-effects-color">
                                  <h3 className="pre-title button-props-panel__label">Color</h3>
                                  <ColorPickerDropdown
                                    colors={blockInPreview.theme.colorPalette.colors}
                                    colorsBlackWhiteGrey={
                                      blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                    }
                                    selectedColor={
                                      highlightedBlock?.block.blockProperties.shapeShadowColor ||
                                      null
                                    }
                                    disabled={
                                      !highlightedBlock ||
                                      highlightedBlock.block.blockType.name !== 'shape'
                                    }
                                    leftRadius
                                    rightRadius
                                    callback={(color) => {
                                      dispatch({
                                        type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                        payload: {
                                          blockId: highlightedBlock.block._id,
                                          updates: {
                                            shapeShadowColor: color
                                          }
                                        }
                                      });
                                    }}
                                  />
                                </div>
                                <div className="button-props-panel__btn-effects-color">
                                  <h3 className="pre-title button-props-panel__label">
                                    X position
                                  </h3>
                                  <div className="button-props-panel__slider">
                                    <Slider
                                      min={-100}
                                      max={100}
                                      disabled={
                                        !highlightedBlock ||
                                        highlightedBlock.block.blockType.name !== 'shape'
                                      }
                                      tooltipVisible={false}
                                      value={
                                        highlightedBlock?.block.blockProperties.shapeShadowX
                                          ? highlightedBlock.block.blockProperties.shapeShadowX.replace(
                                              'em',
                                              ''
                                            ) * 100
                                          : 0
                                      }
                                      onChange={(value) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              shapeShadowX: `${value / 100}em`
                                            }
                                          }
                                        });
                                      }}
                                    />
                                    {highlightedBlock &&
                                      highlightedBlock.block.blockType.name === 'shape' && (
                                        <span>
                                          {highlightedBlock?.block.blockProperties.shapeShadowX
                                            ? Math.round(
                                                highlightedBlock.block.blockProperties.shapeShadowX.replace(
                                                  'em',
                                                  ''
                                                ) * 100
                                              )
                                            : 0}
                                        </span>
                                      )}
                                  </div>
                                  <h3 className="pre-title button-props-panel__label">
                                    Y position
                                  </h3>
                                  <div className="button-props-panel__slider">
                                    <Slider
                                      min={-100}
                                      max={100}
                                      disabled={
                                        !highlightedBlock ||
                                        highlightedBlock.block.blockType.name !== 'shape'
                                      }
                                      tooltipVisible={false}
                                      value={
                                        highlightedBlock?.block.blockProperties.shapeShadowY
                                          ? highlightedBlock.block.blockProperties.shapeShadowY.replace(
                                              'em',
                                              ''
                                            ) * 100
                                          : 0
                                      }
                                      onChange={(value) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              shapeShadowY: `${value / 100}em`
                                            }
                                          }
                                        });
                                      }}
                                    />
                                    {highlightedBlock &&
                                      highlightedBlock.block.blockType.name === 'shape' && (
                                        <span>
                                          {highlightedBlock?.block.blockProperties.shapeShadowY
                                            ? Math.round(
                                                highlightedBlock.block.blockProperties.shapeShadowY.replace(
                                                  'em',
                                                  ''
                                                ) * 100
                                              )
                                            : 0}
                                        </span>
                                      )}
                                  </div>
                                  <h3 className="pre-title button-props-panel__label">Blur</h3>
                                  <div className="button-props-panel__slider">
                                    <Slider
                                      min={0}
                                      max={100}
                                      disabled={
                                        !highlightedBlock ||
                                        highlightedBlock.block.blockType.name !== 'shape'
                                      }
                                      tooltipVisible={false}
                                      value={
                                        highlightedBlock?.block.blockProperties.shapeShadowBlur
                                          ? highlightedBlock.block.blockProperties.shapeShadowBlur.replace(
                                              'em',
                                              ''
                                            ) * 100
                                          : 0
                                      }
                                      onChange={(value) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              shapeShadowBlur: `${value / 100}em`
                                            }
                                          }
                                        });
                                      }}
                                    />
                                    {highlightedBlock &&
                                      highlightedBlock.block.blockType.name === 'shape' && (
                                        <span>
                                          {highlightedBlock?.block.blockProperties.shapeShadowBlur
                                            ? Math.round(
                                                highlightedBlock.block.blockProperties.shapeShadowBlur.replace(
                                                  'em',
                                                  ''
                                                ) * 100
                                              )
                                            : 0}
                                        </span>
                                      )}
                                  </div>
                                  <h3 className="pre-title button-props-panel__label">Opacity</h3>
                                  <div className="button-props-panel__slider">
                                    <Slider
                                      min={0}
                                      max={100}
                                      disabled={
                                        !highlightedBlock ||
                                        highlightedBlock.block.blockType.name !== 'shape'
                                      }
                                      tooltipVisible={false}
                                      value={
                                        highlightedBlock?.block.blockProperties.shapeShadowOpacity
                                          ? highlightedBlock.block.blockProperties
                                              .shapeShadowOpacity * 100
                                          : 0
                                      }
                                      onChange={(value) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock.block._id,
                                            updates: {
                                              shapeShadowOpacity: value / 100
                                            }
                                          }
                                        });
                                      }}
                                    />
                                    {highlightedBlock &&
                                      highlightedBlock.block.blockType.name === 'shape' && (
                                        <span>
                                          {highlightedBlock?.block.blockProperties
                                            .shapeShadowOpacity
                                            ? Math.round(
                                                highlightedBlock.block.blockProperties
                                                  .shapeShadowOpacity * 100
                                              )
                                            : 0}
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </Panel>
                        {/*<Panel header={<h3 className='pre-title'>Classic shapes</h3>} key='35'>
                        </Panel>
                        <Panel header={<h3 className='pre-title'>Fill</h3>} key='36'>
                        </Panel>
                        <Panel header={<h3 className='pre-title'>Stroke</h3>} key='37'>
                          </Panel>*/}
                      </>
                    )}

                    {selectedBlockType.name === 'general' && (
                      <>
                        <Panel header={<h3 className="pre-title">Canvas Background</h3>} key="38">
                          <div className="text-background">
                            <div className="text-background__color">
                              <div className="text-background__bg-color">
                                <h3 className="pre-title text-background__label">Color</h3>
                                <ColorPickerDropdown
                                  colors={blockInPreview.theme.colorPalette.colors}
                                  colorsBlackWhiteGrey={
                                    blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                  }
                                  selectedColor={
                                    blockInPreview.blockProperties.blockBackgroundColor
                                      ? blockInPreview.blockProperties.blockBackgroundColor
                                      : blockInPreview.theme.colorAssignment.blockBackground
                                      ? blockInPreview.theme.colorAssignment.blockBackground
                                      : null
                                  }
                                  leftRadius
                                  rightRadius
                                  callback={(color) => {
                                    dispatch({
                                      type: EDIT_BLOCK_PROPERTY,
                                      payload: {
                                        blockBackgroundColor: color
                                      }
                                    });
                                  }}
                                />
                              </div>
                              <div className="text-background__border">
                                <h3 className="pre-title text-background__label">Border</h3>
                                <div className="text-background__border-inputs">
                                  <ColorPickerDropdown
                                    colors={blockInPreview.theme.colorPalette.colors}
                                    colorsBlackWhiteGrey={
                                      blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                    }
                                    selectedColor={blockInPreview.blockProperties.blockBorderColor}
                                    leftRadius
                                    line
                                    callback={(color) => {
                                      dispatch({
                                        type: EDIT_BLOCK_PROPERTY,
                                        payload: {
                                          blockBorderColor: color
                                        }
                                      });
                                    }}
                                  />
                                  <InputNumber
                                    className="text-background__border-thickness"
                                    controls
                                    min={1}
                                    max={10}
                                    defaultValue={0}
                                    value={blockInPreview.blockProperties.blockBorderThickness * 16}
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_BLOCK_PROPERTY,
                                        payload: {
                                          blockBorderThickness: value / 16
                                        }
                                      });
                                    }}
                                  />
                                  <Select
                                    className="dropdown-small text-background__border-type"
                                    placeholder="Type"
                                    value={blockInPreview.blockProperties.blockBorderType}
                                    onChange={(value) => {
                                      dispatch({
                                        type: EDIT_BLOCK_PROPERTY,
                                        payload: {
                                          blockBorderType: value
                                        }
                                      });
                                    }}>
                                    <Select.Option value="solid" label="Solid">
                                      Solid
                                    </Select.Option>
                                    <Select.Option value="dotted" label="Dotted">
                                      Dotted
                                    </Select.Option>
                                    <Select.Option value="dashed" label="Dashed">
                                      Dashed
                                    </Select.Option>
                                    <Select.Option value="double" label="Double">
                                      Double
                                    </Select.Option>
                                    <Select.Option value="" label="None">
                                      None
                                    </Select.Option>
                                  </Select>
                                </div>
                              </div>
                            </div>
                            {/*<div className='text-background__checkbox'>
                              <Checkbox
                                checked={backgroundImageOpen}
                                onChange={() => setBackgroundImageOpen((previous) => !previous)}
                              >
                                Background image
                              </Checkbox>
                            </div>
                            {backgroundImageOpen && (
                              <div className='text-background__images'>
                                <Button
                                  block
                                  size='small'
                                  onClick={() => {
                                    dispatch({
                                      type: EDIT_BLOCK_PROPERTY,
                                      payload: {
                                        blockBackgroundImage: null,
                                      },
                                    });
                                  }}
                                >
                                  Remove background image
                                </Button>
                                <BgImagesList
                                  token={currentuser.data.token}
                                  SERVER_URL={SERVER_URL}
                                  callback={(imageUrl) => {
                                    dispatch({
                                      type: EDIT_BLOCK_PROPERTY,
                                      payload: {
                                        blockBackgroundImage: imageUrl,
                                      },
                                    });
                                  }}
                                />
                              </div>
                                )}*/}
                          </div>
                        </Panel>
                        {/* <Panel header={<h3 className='pre-title'>Grid Options</h3>} key='39'>
                          <div className='grid-options'>
                            <div className='grid-options__row'>
                              <h4 className='grid-options__label'>Grid style</h4>
                              <div className='grid-options__radio'>
                                <Radio.Group
                                  value={gridOptions.style}
                                  onChange={(e) =>
                                    setGridOptions((previous) => ({ ...previous, style: e.target.value }))
                                  }
                                >
                                  <Radio value='dots'>Dots</Radio>
                                  <Radio value='lines'>Lines</Radio>
                                </Radio.Group>
                              </div>
                            </div>
                            <div className='grid-options__row'>
                              <h4 className='grid-options__label'>Grid color</h4>
                              <ColorPickerDropdown
                                colors={blockInPreview.theme.colorPalette.colors}
                                colorsBlackWhiteGrey={blockInPreview.theme.colorPalette.colorsBlackWhiteGrey}
                                selectedColor={gridOptions.color}
                                leftRadius
                                rightRadius
                                callback={(color) => setGridOptions((previous) => ({ ...previous, color }))}
                                notNullable={true}
                              />
                            </div>
                            <div className='grid-options__row'>
                              <h4 className='grid-options__label'>
                                <span>Grid size</span>
                                <Popover content={<GridSizePopup />} placement='right'>
                                  <InfoIcon />
                                </Popover>
                              </h4>
                              <Select
                                className='dropdown-small'
                                value={gridOptions.size}
                                onChange={(value) => setGridOptions((previous) => ({ ...previous, size: value }))}
                              >
                                <Select.Option value='6x8'>6x8</Select.Option>
                                <Select.Option value='6x9'>6x9</Select.Option>
                                <Select.Option value='6x10'>6x10</Select.Option>
                              </Select>
                                </div>
                          </div>
                              </Panel>*/}
                        {/* <Panel header={<h3 className='pre-title'>Add Info</h3>} key='40'>
                          <div className='add-info-form'>
                            <div className='add-info-form__item'>
                              <h4 className='add-info-form__label'>Name *</h4>
                              <Input
                                placeholder='Name'
                                className='input-small'
                                value={blockInPreview.name}
                                onChange={(e) => {
                                  dispatch({
                                    type: EDIT_BLOCK_FIELD,
                                    payload: {
                                      name: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </div>
                            <div className='add-info-form__item'>
                              <h4 className='add-info-form__label'>Category</h4>
                              <Select
                                mode='multiple'
                                placeholder='Category'
                                className='dropdown-small'
                                value={blockInPreview.category}
                                onChange={(value) => {
                                  dispatch({
                                    type: EDIT_BLOCK_FIELD,
                                    payload: {
                                      category: value,
                                    },
                                  });
                                }}
                              >
                                {categories.data.items &&
                                  categories.data.items.length > 0 &&
                                  categories.data.items.map((category) => {
                                    return (
                                      <Select.Option value={category._id} key={category._id} label={category.name}>
                                        {category.name}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </div>
                            <div className='add-info-form__item'>
                              <h4 className='add-info-form__label'>Audience</h4>
                              <Select
                                mode='multiple'
                                placeholder='Audience'
                                className='dropdown-small'
                                value={blockInPreview.audience}
                                onChange={(value) => {
                                  dispatch({
                                    type: EDIT_BLOCK_FIELD,
                                    payload: {
                                      audience: value,
                                    },
                                  });
                                }}
                              >
                                {audiences.data.items &&
                                  audiences.data.items.length > 0 &&
                                  audiences.data.items.map((audience) => {
                                    return (
                                      <Select.Option value={audience._id} key={audience._id} label={audience.name}>
                                        {audience.name}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </div>
                            <div className='add-info-form__item'>
                              <h4 className='add-info-form__label'>Notes</h4>
                              <Input.TextArea
                                rows={4}
                                placeholder='Notes'
                                value={blockInPreview.notes}
                                onChange={(e) => {
                                  dispatch({
                                    type: EDIT_BLOCK_FIELD,
                                    payload: {
                                      notes: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </div>
                          </div>
                              </Panel>*/}
                      </>
                    )}
                    {selectedBlockType.name === 'info' && (
                      <>
                        <Panel header={<h3 className="pre-title">Info</h3>} key="41">
                          <div className="add-info-form">
                            <div className="add-info-form__item">
                              {/* <h4 className="add-info-form__label">Name *</h4> */}
                              <Input
                                placeholder="Name"
                                value={blockInPreview.name}
                                onChange={(e) => {
                                  dispatch({
                                    type: EDIT_BLOCK_FIELD,
                                    payload: {
                                      name: e.target.value
                                    }
                                  });
                                }}
                              />
                            </div>
                            <div className="add-info-form__item">
                              {/* <h4 className="add-info-form__label">Block Type</h4> */}
                              <Select
                                placeholder="Type"
                                className="dropdown-small"
                                value={blockInPreview?.blockFormat}
                                onChange={(value) => {
                                  dispatch({
                                    type: EDIT_BLOCK_FIELD,
                                    payload: {
                                      blockFormat: value
                                    }
                                  });
                                }}>
                                <Select.Option value="basic" className="fieldType">
                                  Basic
                                </Select.Option>
                                <Select.Option value="dynamic" className="fieldType">
                                  Dynamic
                                </Select.Option>
                                <Select.Option value="survey" className="fieldType">
                                  Survey
                                </Select.Option>
                                <Select.Option value="form" className="fieldType">
                                  Form
                                </Select.Option>
                                <Select.Option value="graph" className="fieldType">
                                  Graph
                                </Select.Option>
                                <Select.Option value="table" className="fieldType">
                                  Table
                                </Select.Option>
                              </Select>
                            </div>
                            <div className="add-info-form__item">
                              {/* <h4 className="add-info-form__label">Categories</h4> */}
                              <Select
                                mode="multiple"
                                placeholder="Categories"
                                className="dropdown-small"
                                value={blockInPreview.category}
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={(value) => {
                                  dispatch({
                                    type: EDIT_BLOCK_FIELD,
                                    payload: {
                                      category: value
                                    }
                                  });
                                }}>
                                {categories.data.items &&
                                  categories.data.items.length > 0 &&
                                  categories.data.items.map((category) => {
                                    return (
                                      <Select.Option
                                        value={category._id}
                                        key={category._id}
                                        label={category.name}>
                                        {category.name}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </div>
                            <div className="add-info-form__item">
                              {/* <h4 className="add-info-form__label">Audiences</h4> */}
                              <Select
                                mode="multiple"
                                placeholder="Audiences"
                                className="dropdown-small"
                                value={blockInPreview.audience}
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={(value) => {
                                  dispatch({
                                    type: EDIT_BLOCK_FIELD,
                                    payload: {
                                      audience: value
                                    }
                                  });
                                }}>
                                {audiences.data.items &&
                                  audiences.data.items.length > 0 &&
                                  audiences.data.items.map((audience, audienceCompare) => {
                                    return (
                                      <Select.Option
                                        value={audience._id}
                                        key={audience._id}
                                        label={audience.name}>
                                        {audience.name}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </div>
                            <div className="add-info-form__item">
                              {/* <h4 className="add-info-form__label">Notes</h4> */}
                              <Input.TextArea
                                rows={3}
                                placeholder="Notes"
                                value={blockInPreview.notes}
                                onChange={(e) => {
                                  dispatch({
                                    type: EDIT_BLOCK_FIELD,
                                    payload: {
                                      notes: e.target.value
                                    }
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </Panel>
                      </>
                    )}
                    {selectedBlockType.name === 'browse' && blockInPreview && (
                      <Panel header={<h3 className="pre-title">Browse</h3>} key="42">
                        <div className="button-props-panel__btn-effects-choices choice-btn-group">
                          {/* <div
                            className={
                              myWorkOrTemplates === 'templates'
                                ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                                : 'button-props-panel__btn-effects-choice choice-btn'
                            }
                            onClick={() => {
                              setMyWorkOrTemplates('templates');
                            }}
                          >
                            Templates
                          </div> */}
                          <div
                            className={
                              myWorkOrTemplates === 'my-work'
                                ? 'button-props-panel__btn-effects-choice choice-btn choice-btn--selected'
                                : 'button-props-panel__btn-effects-choice choice-btn'
                            }
                            onClick={() => {
                              setMyWorkOrTemplates('my-work');
                            }}>
                            My Work
                          </div>
                        </div>
                        <Select
                          placeholder="Select Block Type"
                          className="dropdown-small fieldType"
                          style={{ width: '100%' }}
                          mode="multiple"
                          value={blockTypeFilter}
                          onChange={(value) => setBlockTypeFilter(value)}>
                          <Select.Option value="basic" className="fieldType">
                            Basic
                          </Select.Option>
                          <Select.Option value="dynamic" className="fieldType">
                            Dynamic
                          </Select.Option>
                          <Select.Option value="survey" className="fieldType">
                            Survey
                          </Select.Option>
                          <Select.Option value="form" className="fieldType">
                            Form
                          </Select.Option>
                          <Select.Option value="graph" className="fieldType">
                            Graph
                          </Select.Option>
                          <Select.Option value="table" className="fieldType">
                            Table
                          </Select.Option>
                        </Select>
                        <Select
                          placeholder="Select Category"
                          className="dropdown-small"
                          style={{ width: '100%', marginTop: '10px' }}
                          mode="multiple"
                          value={categoryFilter}
                          onChange={(value) => setCategoryFilter(value)}>
                          {categories.data.items &&
                            categories.data.items.length > 0 &&
                            categories.data.items.map((category) => {
                              return (
                                <Select.Option
                                  value={category._id}
                                  key={category._id}
                                  label={category.name}>
                                  {category.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                        <Select
                          placeholder="Select Audience"
                          className="dropdown-small"
                          style={{ width: '100%', marginTop: '10px' }}
                          value={audienceFilter}
                          onChange={(value) => setAudienceFilter(value)}
                          mode="multiple">
                          {audiences.data.items &&
                            audiences.data.items.length > 0 &&
                            audiences.data.items.map((audience) => {
                              return (
                                <Select.Option value={audience._id} key={audience._id}>
                                  {audience.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                        <Input
                          placeholder={
                            myWorkOrTemplates === 'templates' ? 'Search Templates' : 'Search Blocks'
                          }
                          prefix={<LinkSearch style={{ marginRight: '5px' }} />}
                          allowClear
                          style={{ marginTop: '10px' }}
                          onChange={(e) => handleSearchInputChange(e)}
                        />
                        <div className="qoolection-qoos-list">
                          {myWorkOrTemplates === 'templates' &&
                            templates?.data?.items?.length > 0 &&
                            templates.data.items.map((template) => {
                              const adjustedTemplate = {
                                ...template,
                                theme: blockInPreview?.theme,
                                blocksLayout: template.blocksLayout.map((item) => {
                                  if (item.block.blockType.name === 'text') {
                                    const textType =
                                      item.block.name === 'Heading'
                                        ? 'headingFont'
                                        : item.block.name === 'Subheading'
                                        ? 'subheadingFont'
                                        : 'bodyTextFont';
                                    const newFont = blockInPreview?.theme.selectedFonts
                                      .find(
                                        (font) => font._id === blockInPreview.theme[`${textType}`]
                                      )
                                      .name.toLowerCase();

                                    return {
                                      ...item,
                                      block: {
                                        ...item.block,
                                        blockProperties: {
                                          ...item.block.blockProperties,
                                          textContent:
                                            item.block.blockProperties.textContent.replaceAll(
                                              /(font-family: .*?;)/g,
                                              `font-family: '${newFont}';`
                                            )
                                        }
                                      }
                                    };
                                  } else if (item.block.blockType.name === 'button') {
                                    const newFont = blockInPreview?.theme.selectedFonts
                                      .find(
                                        (font) => font._id === blockInPreview.theme.bodyTextFont
                                      )
                                      .name.toLowerCase();

                                    return {
                                      ...item,
                                      block: {
                                        ...item.block,
                                        blockProperties: {
                                          ...item.block.blockProperties,
                                          buttonText:
                                            item.block.blockProperties.buttonText.replaceAll(
                                              /(font-family: .*?;)/g,
                                              `font-family: '${newFont}';`
                                            )
                                        }
                                      }
                                    };
                                  } else {
                                    return item;
                                  }
                                })
                              };

                              return (
                                <div
                                  key={template._id}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setSelectedTemplate(adjustedTemplate);
                                  }}>
                                  <BlockDisplay
                                    key={template._id}
                                    block={adjustedTemplate}
                                    width={148}
                                    height={197}
                                  />
                                </div>
                              );
                            })}
                          {myWorkOrTemplates === 'my-work' &&
                            blocks?.data?.items?.length > 0 &&
                            blocks?.data?.items?.map((block) => {
                              return (
                                <div
                                  key={block._id}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    // getLayoutFromTemplate(block);
                                    setSelectedTemplate(block);
                                  }}>
                                  <BlockDisplay
                                    key={block._id}
                                    block={block}
                                    width={148}
                                    height={197}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </Panel>
                    )}
                    {selectedBlockType.name !== 'general' &&
                      selectedBlockType.name !== 'text' &&
                      selectedBlockType.name !== 'icon' &&
                      selectedBlockType.name !== 'button' &&
                      selectedBlockType.name !== 'divider' &&
                      selectedBlockType.name !== 'form' &&
                      selectedBlockType.name !== 'survey' &&
                      selectedBlockType.name !== 'media' &&
                      selectedBlockType.name !== 'info' &&
                      selectedBlockType.name !== 'shape' &&
                      selectedBlockType.name !== 'browse' && (
                        <Panel header={<h3 className="pre-title">Templates</h3>} key="43">
                          <BlockTemplatesList
                            token={currentuser.data.token}
                            blockType={selectedBlockType}
                            addBlockToLayout={addBlockToLayout}
                            addEmptyBlock={addEmptyBlock}
                          />
                        </Panel>
                      )}
                    {/*{selectedBlockType.name !== 'info' &&*/}
                    {/*  selectedBlockType.name !== 'shape' &&*/}
                    {/*  selectedBlockType.name !== 'browse' &&*/}
                    {/*  <div className='sidebar-inner__category-field'>*/}
                    {/*    <div className='advanced-mode'>*/}
                    {/*      <h3 className='pre-title pre-title--dark'>Advanced mode</h3>*/}
                    {/*      /!* <h3 className={selectedCategory === 'colors' ? 'pre-title pre-title--dark' : 'pre-title'}>Advanced mode</h3> *!/*/}
                    {/*      <Switch checked={advancedMode} onChange={(checked) => setAdvancedMode(checked)} />*/}
                    {/*    </div>*/}
                    {/*  </div>}*/}
                    {advancedMode &&
                      highlightedBlock &&
                      highlightedBlock.block.blockType.name === 'media' && (
                        <>
                          <Panel header={<h3 className="pre-title">Padding</h3>} key="44">
                            {highlightedBlock &&
                              highlightedBlock.block.blockType.name === 'media' && (
                                <Row gutter={16}>
                                  <Col md={20}>
                                    <Slider
                                      style={{ width: '100%' }}
                                      min={0}
                                      max={100}
                                      tooltipVisible={false}
                                      value={highlightedBlock?.block?.blockProperties.mediaPadding}
                                      onChange={(value) => {
                                        dispatch({
                                          type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                          payload: {
                                            blockId: highlightedBlock?.block?._id,
                                            updates: {
                                              mediaPadding: value
                                            }
                                          }
                                        });
                                      }}
                                    />
                                  </Col>
                                  <Col
                                    md={4}
                                    style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <span>
                                      {highlightedBlock.block.blockProperties.mediaPadding}
                                    </span>
                                  </Col>
                                </Row>
                              )}
                          </Panel>

                          <Panel header={<h3 className="pre-title">Background</h3>} key="45">
                            {highlightedBlock &&
                              highlightedBlock.block.blockType.name === 'media' && (
                                <div className="text-background">
                                  <div className="text-background__color">
                                    <div className="text-background__bg-color">
                                      <h3 className="pre-title text-background__label">Color</h3>
                                      <ColorPickerDropdown
                                        colors={blockInPreview.theme.colorPalette.colors}
                                        colorsBlackWhiteGrey={
                                          blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                        }
                                        leftRadius
                                        rightRadius
                                        selectedColor={
                                          highlightedBlock.block.blockProperties
                                            .mediaBackgroundColor
                                        }
                                        callback={(color) => {
                                          dispatch({
                                            type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                            payload: {
                                              blockId: highlightedBlock.block._id,
                                              updates: {
                                                mediaBackgroundColor: color
                                              }
                                            }
                                          });
                                        }}
                                      />
                                    </div>
                                    <div className="text-background__border">
                                      <h3 className="pre-title text-background__label">Border</h3>
                                      <div className="text-background__border-inputs">
                                        <ColorPickerDropdown
                                          colors={blockInPreview.theme.colorPalette.colors}
                                          colorsBlackWhiteGrey={
                                            blockInPreview.theme.colorPalette.colorsBlackWhiteGrey
                                          }
                                          leftRadius
                                          line
                                          selectedColor={
                                            highlightedBlock.block.blockProperties.mediaBorderColor
                                          }
                                          callback={(color) => {
                                            dispatch({
                                              type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                              payload: {
                                                blockId: highlightedBlock.block._id,
                                                updates: {
                                                  mediaBorderColor: color
                                                }
                                              }
                                            });
                                          }}
                                        />
                                        <InputNumber
                                          className="text-background__border-thickness"
                                          controls
                                          min={1}
                                          max={10}
                                          defaultValue={0}
                                          value={
                                            highlightedBlock.block.blockProperties
                                              .mediaBorderThickness * 16
                                          }
                                          onChange={(value) => {
                                            dispatch({
                                              type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                              payload: {
                                                blockId: highlightedBlock.block._id,
                                                updates: {
                                                  mediaBorderThickness: value / 16
                                                }
                                              }
                                            });
                                          }}
                                        />
                                        <Select
                                          className="dropdown-small text-background__border-type"
                                          placeholder="Type"
                                          value={
                                            highlightedBlock.block.blockProperties.mediaBorderType
                                          }
                                          onChange={(value) => {
                                            dispatch({
                                              type: EDIT_INNER_BLOCK_IN_LAYOUT,
                                              payload: {
                                                blockId: highlightedBlock.block._id,
                                                updates: {
                                                  mediaBorderType: value
                                                }
                                              }
                                            });
                                          }}>
                                          <Select.Option value="solid" label="Solid">
                                            Solid
                                          </Select.Option>
                                          <Select.Option value="dotted" label="Dotted">
                                            Dotted
                                          </Select.Option>
                                          <Select.Option value="dashed" label="Dashed">
                                            Dashed
                                          </Select.Option>
                                          <Select.Option value="double" label="Double">
                                            Double
                                          </Select.Option>
                                          <Select.Option value="" label="None">
                                            None
                                          </Select.Option>
                                        </Select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </Panel>
                        </>
                      )}
                  </Collapse>
                )}
              </SideBarInner>
            )}
            <Layout>
              {!isNew && (
                <HeaderInner>
                  <div className="edit-block-inner-header">
                    <BlockTypesList
                      blockTypes={allBlockTypes.data}
                      isNew={isNew}
                      selectedBlockType={selectedBlockType}
                      setSelectedBlockType={setSelectedBlockType}
                      setSelectedBlock={setSelectedBlock}
                      blockOrQooInPreview={blockInPreview}
                      endnoteCallback={(endnoteType) => setAddingEndnote(endnoteType)}
                      highlightedBlock={highlightedBlock}
                    />
                    <div className="edit-block-header-buttons">
                      <div className="edit-block-undo-redo">
                        <UndoIcon
                          className={
                            blockInPreviewHistory.length > 0
                              ? 'edit-block-undo-redo__btn'
                              : 'edit-block-undo-redo__btn edit-block-undo-redo__btn--disabled'
                          }
                          onClick={handleUndo}
                        />
                        <RedoIcon
                          className={
                            blockInPreviewFuture.length > 0
                              ? 'edit-block-undo-redo__btn'
                              : 'edit-block-undo-redo__btn edit-block-undo-redo__btn--disabled'
                          }
                          onClick={handleRedo}
                        />
                      </div>
                      <div className="edit-block-save-cnc">
                        <Button
                          className={
                            previewType === 'phone'
                              ? 'edit-block-save-cnc__preview edit-block-save-cnc__preview--active'
                              : 'edit-block-save-cnc__preview'
                          }
                          onClick={() => {
                            previewType === 'phone'
                              ? setPreviewType('block')
                              : setPreviewType('phone');
                          }}>
                          <span>Preview</span>
                          <Phone />
                        </Button>
                        <Button
                          type="primary"
                          className="edit-block-save-cnc__save"
                          disabled={
                            blockInPreviewHistory.length < 1 ||
                            _.isEqual(blockInPreview, blockInPreviewHistory[0])
                          }
                          onClick={() => onSubmit({ ...blockInPreview }, false, true)}
                          // onClick={() => onSubmit({ ...blockInPreview, ...newBlockForm.getFieldsValue(true) }, false, true)}
                        >
                          <span>Save</span>
                          <Save />
                        </Button>
                        {currentuser.data.role.includes('superadmin') && (
                          <Button
                            type="primary"
                            className="edit-block-save-cnc__save-template"
                            onClick={() => {
                              if (blockInPreview?.status === 'template') {
                                convertToTemplate({ status: 'draft', isQooloTemplate: false });
                              } else {
                                convertToTemplate({ status: 'template', isQooloTemplate: true });
                              }
                            }}
                            disabled={sendingTemplateData}>
                            {blockInPreview?.status === 'template'
                              ? 'Remove from templates'
                              : 'Save as template'}
                          </Button>
                        )}
                        <Popconfirm
                          title="Are you sure you want to leave without saving? All chages will be lost!"
                          onConfirm={cancelFunction}>
                          <div className="edit-block-save-cnc__cnc-btn">
                            <Close className="edit-block-save-cnc__cancel" />
                          </div>
                        </Popconfirm>
                      </div>
                    </div>
                  </div>
                </HeaderInner>
              )}

              <Content className="edit-block-content">
                {!isNew && (
                  <BlockBuilder
                    blockInPreview={blockInPreview}
                    dispatch={dispatch}
                    previewType={previewType}
                    setPreviewType={setPreviewType}
                    blockType={selectedBlockType}
                    setSelectedBlockType={setSelectedBlockType}
                    selectedBlock={selectedBlock}
                    setSelectedBlock={setSelectedBlock}
                    gridOptions={gridOptions}
                    setGridOptions={setGridOptions}
                    deleteModalVisible={deleteModalVisible}
                    setDeleteModalVisible={setDeleteModalVisible}
                    highlightedBlock={highlightedBlock}
                    setEndnoteForEdit={setEndnoteForEdit}
                  />
                )}
              </Content>
              {deleteModalVisible && (
                <DeleteModal
                  visible={deleteModalVisible}
                  closeModal={() => setDeleteModalVisible(false)}
                  title={`${highlightedBlock.block.name}`}
                  deleteCallback={() => {
                    setDeleteModalVisible(false);
                    // dispatch({ type: REMOVE_BLOCK_FROM_LAYOUT, payload: { _id: selectedBlock } });
                    removeElementWithResize(selectedBlock);
                  }}
                />
              )}
              {selectedTemplate && (
                <TemplateModal
                  visible={selectedTemplate}
                  closeModal={() => setSelectedTemplate(null)}
                  block={selectedTemplate}
                  useTemplate={() => getLayoutFromTemplate(selectedTemplate)}
                />
              )}
              {(addingEndnote || endnoteForEdit) && (
                <EndnoteModal
                  addingEndnote={addingEndnote}
                  closeModal={() => {
                    if (addingEndnote) {
                      setAddingEndnote(null);
                    }
                    if (endnoteForEdit) {
                      setEndnoteForEdit(null);
                    }
                  }}
                  highlightedBlock={highlightedBlock}
                  addEndnotes={(endnotes, endnoteType) => {
                    dispatch({
                      type: ADD_ENDNOTES,
                      payload: {
                        endnotes,
                        endnoteType,
                        blockId: highlightedBlock.block._id
                      }
                    });
                    setAddingEndnote(null);
                  }}
                  endnoteForEdit={endnoteForEdit}
                  editCallback={(endnote, endnoteType) => {
                    dispatch({
                      type: EDIT_ENDNOTE,
                      payload: {
                        endnote,
                        endnoteType
                      }
                    });
                    setEndnoteForEdit(null);
                  }}
                />
              )}
            </Layout>
          </Layout>
        )}
      </Content>
    </Layout>
  );
};

export default EditBlock;
