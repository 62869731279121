import { Button, Modal, Form, Input, notification } from 'antd';
import React, { useState } from 'react';
import Axios from 'axios';
import { notificationSuccess } from '../../config/notificationOptions';
import { notificationError } from '../../config/notificationOptions';
import ExportQoolectionPdfModal from './ExportQoolectionPdfModal';

const ApprovalModal = ({ qoolection, visible, closeModal, SERVER_URL, currentuser, setLoading, setStateForRefetchingData, setQoolectionForExport, setQoolectionForApproval, setApproveQoolection, setVeevaConnected, triggeredBy }) => {
  const [vaultDNS, setVaultDNS] = useState('');
  const [vaultUsername, setVaultUsername] = useState('');
  const [processStatus, setProcessStatus] = useState(null);

  const checkVeevaConnectionStatus = async () => {
    console.log('Checking Veeva connection status');
    try {
      const response = await Axios.get(`${SERVER_URL}/veeva-check-connection-status/${currentuser.data.id}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      console.log('Veeva connection status: ', response.data.status);
      if (response.data.status === 'SUCCESS') {
        setVeevaConnected(true);
        // testUploadQoolectionToVeeva();
        setQoolectionForExport(qoolection);
      } else {
        setVaultDNS(response.data.vaultDNS || '');
        setVaultUsername(response.data.username || '');
        setVeevaConnected(false);
        setProcessStatus('connect to Veeva');
      }
    } catch (e) {
      console.log(e);
      setVeevaConnected(false);
    }
  };

  const testUploadQoolectionToVeeva = async () => {
    const qoolectionId = qoolection._id;
    try {
      const response = await Axios.get(
        `${SERVER_URL}/export-qoolection-to-veeva/${qoolectionId}/${currentuser?.data?.id}/${currentuser?.data?.client?._id}`,
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      if (response?.data?.responseStatus === 'SUCCESS') {
        notification.success({
          message: `Sucessfuly uploaded pdf to veeva vault, document id in veeva is: ${response?.data?.id}`,
          placement: 'bottomRight',
          icon: notificationSuccess.icon,
          className: notificationSuccess.className,
          duration: 3,
        });
      }
      if (response.data.responseStatus === 'FAILURE') {
        notification.error({
          message: response?.data?.errors[0]?.message,
          placement: 'bottomRight',
          duration: 3,
        });
      }
    } catch (err) {
      notification.error({
        message: `Problem with exporting. Please try later, ${err}`,
        placement: 'bottomRight',
        duration: 3,
      });
    }
    closeModal();
  };

  const handlSubmitForVeevaLogin = async (values) => {
    if (!currentuser.data.id) {
      notification.error({
        message: 'No logged in user info.',
        placement: 'bottomRight',
        icon: notificationError.icon,
        className: notificationError.className,
        duration: notificationError.duration,
      });
    }
    try {
      const response = await Axios.post(`${SERVER_URL}/veeva/${currentuser.data.id}/connect`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response.data.status === 'SUCCESS') {
        notification.success({
          message: 'Connected to veeva vault.',
          placement: 'bottomRight',
          icon: notificationSuccess.icon,
          className: notificationSuccess.className,
          duration: notificationSuccess.duration,
        });
        setVeevaConnected(true);
        // testUploadQoolectionToVeeva();
        setQoolectionForApproval(null);
        setQoolectionForExport(qoolection);
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Problem with log in to veeva vault.',
        placement: 'bottomRight',
        duration: 3,
      });
    }
  };

  const manualApprovalExportToPdf = async () => {
    setLoading(true);
    closeModal();
    try {
      const response = await Axios.post(`${SERVER_URL}/export-qoolection-to-pdf/${qoolection._id}`, {}, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      if (response.data && response.data.fileUrl) {
        setLoading(false);
        const filepath = response.data.fileUrl;
        window.open(filepath, '_blank');
      }
    } catch (err) {
      setLoading(false);
      notification.error({
        message: 'Problem with exporting. Please try later.',
        placement: 'bottomRight',
      });
    }
    setStateForRefetchingData(previous => previous + 1);
  };

  return (
    <>
      <Modal
        visible={visible}
        centered
        closable
        onCancel={closeModal}
        footer={null}
        bodyStyle={{ padding: '0' }}
        destroyOnClose={true}
        width={560}
      >
        <div className="approval-modal">
          {!processStatus && // KAD BUDE FUNKCIONALNO 'STATUS' PROPERTY U QOOLECTION, DODATI ' qoolection.status === "draft" &&'
            <>
              <h1 className="approval-modal__title">Initiate approval</h1>
              {/*<Button*/}
              {/*  type='primary'*/}
              {/*  block={true}*/}
              {/*  className="approval-modal__btn"*/}
              {/*  onClick={() => checkVeevaConnectionStatus()}*/}
              {/*>*/}
              {/*  Approve with Veeva*/}
              {/*</Button>*/}
              {/* <Button
                type='primary'
                block={true}
                className='approval-modal__btn approve-manual-btn'
                onClick={manualApprovalExportToPdf}
              >
                Approve manually
              </Button> */}
              {triggeredBy && triggeredBy === 'EditQoolection' ? null : (
                <Button
                  type='primary'
                  block={true}
                  className='approval-modal__btn approve-manual-btn'
                  onClick={() => {
                    setApproveQoolection(true);
                    setQoolectionForExport(qoolection);
                    setQoolectionForApproval(null);
                  }}
                >
                  Approve manually
                </Button>
              )}
            </>
          }
          {processStatus && processStatus === 'connect to Veeva' &&
            <>
              <h1 className="approval-modal__title">Connect to Veeva Vault</h1>
              <p className="approval-modal__text">Vault Connections are Veeva-delivered integrations that seamlessly transfer data and documents between Vault Clinical Operations and Vault Quality.</p>
              <div className="approval-modal__btns-container">
                <Button
                  className='approval-modal__btn'
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  type='primary'
                  className='approval-modal__btn'
                  onClick={() => setProcessStatus('Veeva connection form')}
                >
                  Connect
                </Button>
              </div>
            </>
          }
          {processStatus && processStatus === 'Veeva connection form' &&
            <>
              <h1 className="approval-modal__title">Login to Veeva Vault</h1>
              <Form
                layout='vertical'
                className='approval-modal__form'
                initialValues={{ username: vaultUsername, vaultDNS }}
                onFinish={(values) => handlSubmitForVeevaLogin(values)}
              >
                <Form.Item
                  label='Vault'
                  rules={[{ required: true, message: 'Please enter vault!' }]}
                  name='vaultDNS'
                  labelAlign='left'
                >
                  <Input placeholder='Vault' />
                </Form.Item>
                <Form.Item
                  label='User name'
                  rules={[{ required: true, message: 'Please enter username!' }]}
                  name='username'
                  labelAlign='left'
                >
                  <Input placeholder='username' />
                </Form.Item>
                <Form.Item
                  label='Password'
                  rules={[{ required: true, message: 'Please enter password!' }]}
                  name='password'
                  labelAlign='left'
                >
                  <Input type='password' placeholder='Password' />
                </Form.Item>
                <Button type='primary' htmlType='submit' block>
                  Continue
                </Button>
              </Form>
            </>
          }
          {processStatus && processStatus === 'approve manually' &&
            <>
              <h1 className="approval-modal__title">Export Qoolection</h1>
              <p className="approval-modal__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui ut ornare lectus sit. Libero justo laoreet sit amet.</p>
              <div className="approval-modal__btns-container">
                <Button
                  className='approval-modal__btn'
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  type='primary'
                  className='approval-modal__btn'
                >
                  Download PDF
                </Button>
              </div>
            </>
          }
        </div>
      </Modal>
    </>
  );
};

export default ApprovalModal;