import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Modal, message } from 'antd';
import Axios from 'axios';
import { SERVER_URL, SESSION_ACTIVITY_TIMEOUT } from './config';
import Layout from './components/base/Layout';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import PublishedQoolection from './pages/published/PublishedViewer';
import './App.scss';
import Routes from './components/routes/Routes';

export const UserContext = createContext(null);

const handleLogin = async (user) => {
  const allowedRoles = ['superadmin', 'creator', 'monitor', 'agency'];
  try {
    const userResponse = await Axios.post(
        `${SERVER_URL}/login`,
        { email: user.email, password: user.password},
        { withCredentials: true }
    );
    if (
        userResponse.data.message === 'Auth successful' &&
        allowedRoles.some((role) => userResponse.data.role.includes(role))
    ) {
      sessionStorage.setItem('user', JSON.stringify(userResponse.data));
      window.location.reload();
    } else if (
        userResponse.data.message === 'Auth successful' &&
        !allowedRoles.some((role) => userResponse.data.role.includes(role))
    ) {
      // return message.info("You don't have access to dashboard", 3);
      return `You don't have access to dashboard`;
    }
  } catch (err) {
    console.log('\n Login error = ', err?.response?.data?.message);
    return err?.response?.data?.message;
    // return message.warning(err?.response?.data?.message, 3);
  }
};

const App = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [language, setLanguage] = useState();
 
  const [injectedFontFaceRulesIds, setInjectedFontFaceRulesIds] = useState([]);
  const [isNavMenuCollapsed, setIsNavMenuCollapsed] = useState(false);
  const [fonts, setFonts] = useState([]);
  
  let user = sessionStorage.getItem('user');
  if (user) user = JSON.parse(user);

  const [selectedTeam, setSelectedTeam] = useState(user?.selectedTeam?._id);
  const [study, setStudy] = useState(user?.selectedStudy);
  const isForgot = window.location.pathname === '/admin/forgot-password';
  const isReset =
    window.location.pathname.includes('/admin/reset-password') ||
    window.location.pathname.includes('/email-verified');
  const isViewPublished = window.location.pathname.includes('/published-qoolection');
  
  const [tickActivity, setTickActivity] = useState();
  const [activityExpired, setActivityExpired] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const checkIsLoggedIn = () => {
    if (!activityExpired) {
      if (user && new Date() > new Date(user.expires)) {
        console.log('Session expired!');
        setLoggedIn(false);
        return false;
      } else if (user === null) {
        setLoggedIn(false);
        return false;
      } else {
        setLoggedIn(true);
        return true;
      }
    } else {
      setLoggedIn(false);
      sessionStorage.removeItem('user');
      window.location.href = '/login';
    }
  };

  useEffect(() => {
    if (user && new Date() > new Date(user.expires)) {
      console.log('Session expired!');
      setLoggedIn(false);
    } else if (user === null) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [setLoggedIn, user]);

  // useEffect(() => {
  //  if(user && new Date() > new Date(sessionExpires)){
  //     window.addEventListener('load', logout)
  //  }
  //  return () => {
  //   window.removeEventListener('load', logout);
  //  }
  // }, [user]);

  const handleRefreshToken = async() => {
    let user = sessionStorage.getItem('user');
    if(user) user = JSON.parse(user);
    const sessionExpires = user?.expires;
    if(sessionExpires && new Date(sessionExpires) > new Date()){
      try{
        const response = await Axios.get(`${SERVER_URL}/refresh-token`, { withCredentials: true,
          headers: { Authorization: `Bearer ${user.token}` }});
          const data = response.data;
          sessionStorage.setItem('user', JSON.stringify({...user, expires: data?.expires, token: data?.token}));
      } catch(err){
        console.log('handle refresh token err: ', err.message);
      }
    }
  }

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      let user = sessionStorage.getItem('user');
      if(user) user = JSON.parse(user);
      const sessionExpires = user?.expires;
      if(sessionExpires && new Date() > new Date(sessionExpires)){
        setLoggedIn(false);
        setModalVisible(true);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    }
  }, [user]);

  // useEffect(() => {
  //   checkIsLoggedIn();
  // }, [setLoggedIn, user]);

  // Automatically check if no actions on the screen, after .env SESSION_ACTIVITY_TIMEOUT, logout

  // useEffect(() => {
  //   const timerCheckActivity = setTimeout(() => {
  //     if (!activityExpired) {
  //       setActivityExpired(true);
  //       checkIsLoggedIn();
  //     }
  //   }, SESSION_ACTIVITY_TIMEOUT);
  //   return () => {
  //     clearTimeout(timerCheckActivity);
  //   }
  // }, [tickActivity])

  // useEffect(() => {
  //   document.addEventListener("click", () => setTickActivity(t => !t));
  //   return () => {
  //     document.removeEventListener("click", () => setTickActivity(t => !t));
  //   }
  // }, [])

  useEffect(() => {
    Axios.get(`${SERVER_URL}/languages-selected`)
      .then((resp) => {
        const defaultLanguage = resp.data.find((l) => l.default);
        setLanguage({
          selected: defaultLanguage,
          list: resp.data,
          default: defaultLanguage
        });
      })
      .catch((err) => console.log(err.response ? err.response.data.message : err.message));
  }, [setLanguage]);

  const getUserSettings = () => {
    try {
      return Axios.get(`${SERVER_URL}/user-settings/${user.id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` }
      });
     
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      getUserSettings()
        .then((resp) => {
          setSelectedTeam(resp?.data?.team);
          setStudy(resp?.data?.study);
        })
        .catch((error) => {
          if (error.response && error.response.data.message === 'User settings does not exist.') {
            Axios.post(
              `${SERVER_URL}/user-settings`,
              {
                user: user.id,
                team: user.team[0]?._id,
                study: user.team[0]?.studies[0]?._id
              },
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.token}` }
              }
            ).then(() => {
              getUserSettings().then((resp) => {
                setSelectedTeam(resp.data.team);
                setStudy(resp.data.study);
              });
            });
          }
        });
    }
  }, []);

  const fetchFonts = async () => {
    // if (!user || !user.token) {
    //   return;
    // }

    try {
      // const res = await Axios.get(`${SERVER_URL}/fonts`, {
      //   withCredentials: false,
      //   headers: { Authorization: `Bearer ${user.token}` },
      // });

      const res = await Axios.get(`${SERVER_URL}/fonts`);

      if (res.status === 200 && res.data.length > 0) {
        const allFonts = res.data;
        const addedFontFaceRulesIds = [];

        const availableFonts = allFonts.map((font) => {
          const newFont = {
            _id: font._id,
            family: font.fontFamily,
            name: font.name,
            style: font.fontType.toLowerCase().includes('italic') ? 'italic' : 'normal',
            weight: font.fontType.toLowerCase().includes('light')
              ? 300
              : font.fontType.toLowerCase().includes('semibold')
              ? 600
              : font.fontType.toLowerCase().includes('bold')
              ? 700
              : font.fontType.toLowerCase().includes('black')
              ? 900
              : 400,
            importUrl: font.location
          };
          // Mozda je nepotrebna ova provera sad posto je fetchFonts nije vise TypographyTab vec u App (root) komponenti ? PROVERITI
          if (!injectedFontFaceRulesIds.includes(font._id)) {
            window.document.styleSheets[0].insertRule(`
          @font-face {
            font-family: ${newFont.name};
            src: url(${font.location});
            font-weight: ${newFont.weight};
            font-style: ${newFont.style};
          }
        `);
            addedFontFaceRulesIds.push(font._id);
          }
          return newFont;
        });

        setInjectedFontFaceRulesIds((prev) => [...prev, ...addedFontFaceRulesIds]);
        setFonts((prev) => [...prev, ...availableFonts]);
      }
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
    }
  };

  const logout = async () => {
    await Axios.get(`${SERVER_URL}/logout`, { withCredentials: true, headers: { Authorization: `Bearer ${user.token}` }});
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  }

  useEffect(() => {
    fetchFonts();
  }, []);

  return (
    <UserContext.Provider
      value={{
        handleRefreshToken,
        checkIsLoggedIn,
        isLoggedIn,
        setTickActivity,
        handleLogin,
        data: user,
        language,
        selectedTeam,
        setSelectedTeam,
        study,
        setStudy,
        setLanguage,
        injectedFontFaceRulesIds,
        setInjectedFontFaceRulesIds,
        isNavMenuCollapsed,
        setIsNavMenuCollapsed,
        fonts,
        setFonts,
        getUserSettings
      }}>
        {user && 
        <Modal 
           title={<h2 style={{textAlign: 'center', fontSize: '24px', fontWeight: '600', fontFamily: 'Titillium Web'}}>Your session has expired</h2>} 
           open={modalVisible} 
           onOk={logout} 
           cancelButtonProps={{ style: { display: 'none' } }}
           closable={false}
           >
          <p style={{ textAlign: 'center' }}>Please reload the page.</p>
        </Modal>}
      <Router>
        <Switch>
          <Routes />
          {/*isViewPublished && <Route path="/published-qoolection/:qoolectionToken" render={(props) => <PublishedQoolection {...props} />} />}
        {!isViewPublished && isLoggedIn && <Layout />}
        { {!isViewPublished && !isLoggedIn && !isForgot && !isReset && <Login />}
        {!isLoggedIn && isForgot && !isReset && <ForgotPassword />}
        {!isLoggedIn && !isForgot && isReset && <ResetPassword />} */}
        </Switch>
      </Router>
    </UserContext.Provider>
  );
};

export default App;
