import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { calculateHF } from '../../utils/calculateHF';
import QooHeader from '../qoos/QooHeader';
import QooFooter from '../qoos/QooFooter';
import QooBlocksLayout from '../qoos/QooBlocksLayout';
import viewTypes from '../../params/viewTypes';
import PublishedSideBarMenu from '../../components/elements/PublishedSideBarMenu';
import _ from 'lodash';
import publishedMenuItemsTypes from '../../params/publishedMenuItemsTypes';
import { openUrlInNewTab } from '../../utils/common';
import CookieOptInModal from './CookieOptInModal';

const PublishedQoolection = (props) => {
  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };

  const { qoolectionToken } = useParams();
  const history = useHistory();
  const [qooDimensions, setQooDimensions] = useState({});
  const [qooInPreview, setQooInPreview] = useState(null);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [qoolectionInPreview, setQoolectionInPreview] = useState(null);
  const [qooInPreviewIndex, setQooInPreviewIndex] = useState(0);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;
  let swipeDisabled = false;

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    if (windowSize.innerHeight / windowSize.innerWidth < 2.2) {
      setQooDimensions({
        height: 0.85 * windowSize.innerHeight,
        width: (0.85 * windowSize.innerHeight) / 1.88,
      });
    } else {
      setQooDimensions({
        height: 1.88 * windowSize.innerWidth,
        width: windowSize.innerWidth,
      });
    }
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const getPublishedQoolection = async () => {
    try {
      const res = await Axios.get(`${SERVER_URL}/published-qoolection/${qoolectionToken}`);
      const qoolection = await res?.data;
      if (qoolection) {
        setQoolectionInPreview(qoolection);
        setQooInPreview(qoolection.qoos[qooInPreviewIndex]);
      }
      else history.push('/404');
    } catch (error) {
      history.push('/404');
      console.log(error);
    }
  };

  useEffect(() => {
    if (qoolectionToken) {
      getPublishedQoolection();
    }
  }, []);

  let gridAlign, headerHeight, footerHeight;
  if (qooInPreview && qooInPreview.theme) {
    ({ gridAlign, headerHeight, footerHeight } = calculateHF(qooInPreview?.noHeaderFooter, {
      ...qooInPreview?.theme.headerFooter,
      header: {
        ...qooInPreview?.theme.headerFooter.header,
        noHeader: !qooInPreview.showHeader,
      },
      footer: {
        ...qooInPreview?.theme.headerFooter.footer,
        noISI: !qooInPreview.showISI,
        noFooter: !qooInPreview.showFooter,
        shownISI: qooInPreview.ISISize === 'S' ? 'title-only' : qooInPreview.ISISize === 'M'
          ? 'less-text'
          : qooInPreview.ISISize === 'L' ? 'more-text' : qooInPreview?.theme.headerFooter.footer.shownISI,
      },
    }));
  }

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right');
    // add your conditional logic here
    if (isLeftSwipe && qooInPreviewIndex < qoolectionInPreview.qoos.length - 1) {
      const newIndex = qooInPreviewIndex + 1;
      setQooInPreview(qoolectionInPreview.qoos[newIndex]);
      setQooInPreviewIndex(newIndex);
    }
    if (isRightSwipe && qooInPreviewIndex > 0) {
      const newIndex = qooInPreviewIndex - 1;
      setQooInPreview(qoolectionInPreview.qoos[newIndex]);
      setQooInPreviewIndex(newIndex);
    }
  };

  const getQooIndexByQooId = (qooId, qoolectionInPreview) => {
    const qoos = _.get(qoolectionInPreview, 'qoos', []);

    if (qoos.length < 1) {
      return 0;
    }

    return _.findIndex(qoos, (qoo) => qoo._id === qooId);
  };

  const onMenuClick = () => {
    setIsMenuOpened(true);
  };

  const onMenuItemClick = (menuItem) => {
    const menuItemType = _.get(menuItem, 'linkTo', '');
    const menuItemReference = _.get(menuItem, 'link', '');

    if (!(menuItemType && menuItemReference)) {
      // Invalid menu item data. Just close the menu.
      setIsMenuOpened(false);
      return false;
    }

    switch (menuItemType) {
      case publishedMenuItemsTypes.qoo.key:
        const qooIndex = getQooIndexByQooId(menuItemReference, qoolectionInPreview);

        setQooInPreview(qoolectionInPreview.qoos[qooIndex]);
        setQooInPreviewIndex(qooIndex);
        setIsMenuOpened(false);

        break;

      case publishedMenuItemsTypes.webLink.key:
        setIsMenuOpened(false);
        openUrlInNewTab(menuItemReference, 350);

        break;

      // TODO: handle case with menu item type of 'endnote' (currently called 'footnotes').

      default:
        setIsMenuOpened(false);

        break;
    }
  };

  const onMenuCloseClick = () => {
    setIsMenuOpened(false);
  };

  const onButtonClick = (qooId) => {
    const qooIndex = getQooIndexByQooId(qooId, qoolectionInPreview);

    setQooInPreview(qoolectionInPreview.qoos[qooIndex]);
    setQooInPreviewIndex(qooIndex);
  };

  swipeDisabled = isMenuOpened || _.get(qoolectionInPreview, 'publishedSwipeDisabled', false);

  return (
    <div
      onTouchStart={swipeDisabled ? null : onTouchStart} onTouchMove={swipeDisabled ? null : onTouchMove}
      onTouchEnd={swipeDisabled ? null : onTouchEnd}
      className="published-qoolection"
    >
      <CookieOptInModal />
      {qooDimensions && qooInPreview &&
        <div
          style={{
            position: 'relative',
            width: window.innerWidth,
            height: window.innerHeight,
            display: 'flex',
            alignItems: gridAlign ? gridAlign : 'center',
            backgroundColor: qooInPreview?.qooBackgroundImage
              ? ''
              : qooInPreview?.qooBackgroundColor
                ? qooInPreview?.qooBackgroundColor?.hex
                : qooInPreview.theme.colorAssignment.qooBackground
                  ? qooInPreview.theme.colorAssignment.qooBackground.hex
                  : '#fff',
            backgroundImage: qooInPreview.qooBackgroundImage ? `url(${qooInPreview?.qooBackgroundImage})` : '',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            border:
              qooInPreview.qooBorderColor && qooInPreview.qooBorderThickness && qooInPreview.qooBorderType
                ? `${qooInPreview.qooBorderThickness}em ${qooInPreview.qooBorderType} ${qooInPreview.qooBorderColor.hex}`
                : '1px solid #D5DFE8',
            overflow: 'hidden',
            justifyContent: 'center'
          }}
        >
          {qooInPreview.blocksLayout && qooInPreview.blocksLayout.length > 0 &&
            <QooBlocksLayout
              layout={qooInPreview.blocksLayout}
              gridWidth={qooDimensions.width}
              gridRowHeight={qooDimensions.width / 12}
              viewType={viewTypes.published.key}
              onButtonClickCallback={onButtonClick}
            />
          }
          {qooInPreview.showHeader && qooInPreview.theme.headerFooter &&
            !qooInPreview.theme.headerFooter.header.noHeader && (
              <QooHeader
                headerHeight={headerHeight}
                header={qooInPreview.theme.headerFooter.header}
                gridRowHeight={qooDimensions.width / 12}
                gridWidth={qooDimensions.width}
                viewType={viewTypes.published.key}
                onMenuClickCallback={onMenuClick}
              />
            )}
          {(qooInPreview.showISI || qooInPreview.showFooter) && qooInPreview.theme.headerFooter &&
            !qooInPreview.theme.headerFooter.footer.noFooter && (
              <QooFooter
                footerHeight={footerHeight}
                footer={qooInPreview.theme.headerFooter.footer}
                gridRowHeight={qooDimensions.width / 12}
                gridWidth={qooDimensions.width}
                dontShowISI={!qooInPreview.showISI}
                dontShowFooter={!qooInPreview.showFooter}
                ISISizeInQoo={qooInPreview.ISISize}
              />
            )}
          {qooInPreview.showHeader && qooInPreview.theme.headerFooter &&
            !qooInPreview.theme.headerFooter.header.noHeader && (
              <PublishedSideBarMenu
                qoolectionInPreview={qoolectionInPreview}
                qooInPreview={qooInPreview}
                onMenuItemClick={onMenuItemClick}
                onMenuCloseClick={onMenuCloseClick}
                isMenuOpened={isMenuOpened}
              />
            )}
        </div>
      }
    </div>
  );
};

export default PublishedQoolection;