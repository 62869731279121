import React from 'react';
import { Modal } from 'antd';

const DeleteModal = ({ visible, closeModal }) => {
  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      footer={null}
      className='delete-modal'
      width={494}
      closable={true}
    >
      <h2 className="delete-modal__title">Deleting a Theme with assets!</h2>
      <p className="delete-modal__subtitle">Note that you have one or more Blocks, Qoos, and/or Qoolections that are using this Theme. If you delete this Theme, all of those assets will be deleted.</p>
      <p className="delete-modal__subtitle">There are three options available to you.</p>
      <p className="delete-modal__subtitle">First, you can inactivate this Theme by activating another Theme. Second, you can go into Manage Blocks, Manage Qoos, and Manage Qoolections to delete all of the assets in this Theme, and then return here to delete this Theme. If you would like to initiate a bulk delete of all assets associated with this Theme, please reach out to our Technical Support.</p>
    </Modal>
  );
};

export default DeleteModal;