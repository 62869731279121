import React from 'react';
import { Slider } from 'antd';

const TransparencySlider = ({ inputValue, callback, disabled }) => {
  return (
    <div className={disabled ? "transparency-slider transparency-slider--disabled" : "transparency-slider"}>
      <div className="transparency-slider__label">
        <span>Transparency</span>
      </div>
      <div className="transparency-slider__slider-container">
        <Slider
          min={0}
          max={100}
          disabled={disabled}
          tooltipVisible={false}
          value={inputValue}
          onChange={(value) => callback(value)}
        />
        <span>{Math.round(inputValue)}</span>
      </div>
    </div>
  );
};

export default TransparencySlider;