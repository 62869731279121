import { Tooltip } from 'antd';
import React from 'react';

const Shapes = ({ addEmptyBlock }) => {
  const shapes = [
    {
      key: 'Basic shapes',
      name: 'Basic shapes',
      group: 'Basic shapes',
      children: [
        {
          name: 'Rectangle',
          value: '<rect x="0" y="0" width="100%" height="100%" fill="#333" />'
        },
        {
          name: 'Rounded rectangle',
          value: '<rect x="0" y="0" width="100%" height="100%" rx="15%" fill="#333" />'
        },
        {
          name: 'Circle',
          value: '<circle cx="50%" cy="50%" r="45%" fill="#333" />'
        },
        {
          name: 'Ellipse',
          value: '<ellipse rx="25%" ry="40%" cx="50%" cy="50%" fill="#333" />'
        },
        {
          name: 'Triangle',
          value: '<polygon points="50 0, 0 100, 100 100"  fill="#333" />'
        },
        {
          name: 'Right triangle',
          value: '<polygon points="0 0, 0 100, 100 100"  fill="#333" />'
        },
        {
          name: 'Trapezoid',
          value: '<polygon points="20 0, 80 0, 100 100, 0 100" fill="#333" />'
        },
        {
          name: 'Parallelogram',
          value: '<polygon points="25 0, 100 0, 75 100, 0 100" fill="#333" />'
        },
        {
          name: 'Rhombus',
          value: '<polygon points="50 0, 100 50, 50 100, 0 50" fill="#333" />'
        },
        {
          name: 'Pentagon',
          value: '<polygon points="50 0, 100 38, 82 100, 18 100, 0 38" fill="#333" />'
        },
        {
          name: 'Hexagon',
          value: '<polygon points="25 0, 75 0, 100 50, 75 100, 25 100, 0 50" fill="#333" />'
        },

        {
          name: 'Heptagon',
          value: '<polygon points="50 0, 90 20, 100 60, 75 100, 25 100, 0 60, 10 20" fill="#333" />'
        },
        {
          name: 'Octagon',
          value:
            '<polygon points="30 0, 70 0, 100 30, 100 70, 70 100, 30 100, 0 70, 0 30" fill="#333" />'
        },
        {
          name: 'Nonagon',
          value:
            '<polygon points="50 0, 83 12, 100 43, 94 78, 68 100, 32 100, 6 78, 0 43, 17 12" fill="#333" />'
        },
        {
          name: 'Decagon',
          value:
            '<polygon points="50 0, 80 10, 100 35, 100 70, 80 90, 50 100, 20 90, 0 70, 0 35, 20 10" fill="#333" />'
        }
      ]
    },
    {
      key: 'Arrows',
      name: 'Arrows',
      group: 'Arrows',
      children: [
        {
          name: 'Left arrow',
          value: '<polygon points="40 0, 40 20, 100 20, 100 80, 40 80, 40 100, 0 50" fill="#333" />'
        },
        {
          name: 'Right arrow',
          value: '<polygon points="0 20, 60 20, 60 0, 100 50, 60 100, 60 80, 0 80" fill="#333" />'
        },
        {
          name: 'Down arrow',
          value: '<polygon points="20 0, 20 40, 0 40, 50 100, 100 40, 80 40, 80 0" fill="#333" />'
        },
        {
          name: 'Up arrow',
          value: '<polygon points="20 100, 20 40, 0 40, 50 0, 100 40, 80 40, 80 100" fill="#333" />'
        },
        {
          name: 'Left point',
          value: '<polygon points="25 0, 100 0, 100 100, 25 100, 0 50" fill="#333" />'
        },
        {
          name: 'Right point',
          value: '<polygon points="0 0, 75 0, 100 50, 75 100, 0 100" fill="#333" />'
        },
        {
          name: 'Left chevron',
          value: '<polygon points="100 0, 75 50, 100 100, 25 100, 0 50, 25 0" fill="#333" />'
        },
        {
          name: 'Right chevron',
          value: '<polygon points="75 0, 100 50, 75 100, 0 100, 25 50, 0 0" fill="#333" />'
        }
      ]
    },
    {
      key: 'Equation shapes',
      name: 'Equation shapes',
      group: 'Equation shapes',
      children: [
        {
          name: 'Plus',
          value:
            '<polygon points="35 0, 65 0, 65 35, 100 35, 100 60, 65 60, 65 100, 35 100, 35 60, 0 60, 0 35, 35 35" fill="#333" />'
        },
        {
          name: 'Minus',
          value: '<polygon points="100 35, 100 60, 0 60, 0 35" fill="#333" />'
        },
        {
          name: 'Close',
          value:
            '<polygon points="20 0, 0 20, 30 50, 0 80, 20 100, 50 70, 80 100, 100 80, 70 50, 100 20, 80 0, 50 30" fill="#333" />'
        }
      ]
    },
    {
      key: 'Other shapes',
      name: 'Other shapes',
      group: 'Other shapes',
      children: [
        {
          name: 'Bevel',
          value:
            '<polygon points="20 0, 80 0, 100 20, 100 80, 80 100, 20 100, 0 80, 0 20" fill="#333" />'
        },
        {
          name: 'Rabbet',
          value:
            '<polygon points="0 15, 15 15, 15 0, 85 0, 85 15, 100 15, 100 85, 85 85, 85 100, 15 100, 15 85, 0 85" fill="#333" />'
        },
        {
          name: 'Star',
          value:
            '<polygon points="50 0, 61 35, 98 35, 68 57, 79 91, 50 70, 21 91, 32 57, 2 35, 39 35" fill="#333" />'
        },
        {
          name: 'Lightning',
          value: '<polygon points="25 0, 70 0, 40 35, 95 35, 20 100, 40 55, 0 55" fill="#333" />'
        },
        {
          name: 'Frame',
          value:
            '<polygon points="0 0, 0 100, 25 100, 25 25, 75 25, 75 75, 25 75, 25 100, 100 100, 100 0" fill="#333" />'
        },
        {
          name: 'Message',
          value: '<polygon points="0 0, 100 0, 100 75, 75 75, 75 100, 50 75, 0 75" fill="#333" />'
        },
        {
          name: 'Home',
          value:
            '<polygon points="50 0, 70 15, 70 2, 90 2, 90 30, 100 40, 100 100, 65 100, 65 65, 35 65, 35 100, 0 100, 0 40" fill="#333" />'
        },
        {
          name: 'Diamond',
          value: '<polygon points="25 0, 75 0, 100 25, 50 100, 0 25" fill="#333" />'
        }
      ]
    }
  ];

  return (
    <div
      style={{
        height: '300px',
        overflowY: 'scroll',
        overflowX: 'hidden'
      }}>
      {shapes.map((group, groupIndex) => {
        return (
          <div
            key={`shapes_group_${groupIndex}`}
            style={{
              width: '100%',
              marginBottom: '24px'
            }}>
            <div style={{ marginBottom: '24px' }}>
              <span
                style={{
                  color: '#AEAEAE',
                  fontFamily: 'Noto Sans',
                  fontSize: '12px',
                  textTransform: 'uppercase'
                }}>
                {group.group}
              </span>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '8px'
              }}>
              {group?.children?.map((member, memberIndex) => {
                return (
                  <Tooltip color={'#4962E2'} placement="top" title={member.name}>
                    <div
                      key={`shapes_member_${memberIndex}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        addEmptyBlock('shape', {
                          initialShape: member.value,
                          clipPath: member.value,
                          shapeColor: {
                            hex: '#333',
                            rgb: { r: 73, g: 98, b: 226, a: 1 }
                          },
                          shapeHasShadow: false,
                          shapeShadowX: '0em',
                          shapeShadowY: '0.3em',
                          shapeShadowBlur: '0.5em',
                          shapeShadowOpacity: 0.3,
                          shapeShadowColor: {
                            hex: '#000000',
                            rgb: { r: 0, g: 0, b: 0, a: 1 }
                          },
                          shapeInnerShadow: false,
                          shapeBorderThickness: '0.0625em',
                          shapeBorderType: 'solid'
                        });
                      }}>
                      <svg
                        height="40px"
                        width="40px"
                        viewBox="0 0 100 100"
                        dangerouslySetInnerHTML={{ __html: member.value }}></svg>
                      {/* <span className="tooltiptext tooltip-top">{member.name}</span> */}
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Shapes;
