import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import QooDisplay from '../qoos/QooDisplay';

const PreviewDrawer = ({ qoolection, setQoolectionInDrawerPreview, addQoosFromAnotherQoolection, showPreviewModal, setShowPreviewModal, duplicateQoolection }) => {

  return (
    <Modal
      visible={qoolection && showPreviewModal}
      onCancel={() => setQoolectionInDrawerPreview(null)}
      footer={null}
      width={1240}
      centered={true}
      maskStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
      }}
      bodyStyle={{
        padding: '0'
      }}
    >


      <div className="preview-drawer">
        <div className="preview-drawer__content-container">
          <div className="preview-drawer__content">
            <h2 className="preview-drawer__title">{qoolection.title}</h2>
            <div className="preview-drawer__qoos-list">
              {qoolection.qoos.length > 0 && qoolection.qoos.map((qoo, index) => {
                return (
                  <div
                    key={qoo._id}
                    className={qoolection.qoos.length - 1 === index ? 'preview-drawer__qoo-card preview-drawer__qoo-card--last' : 'preview-drawer__qoo-card'}
                  >
                    <QooDisplay
                      qooInPreview={qoo}
                      width={96}
                      height={182}
                    />
                    <span className='preview-drawer__qoo-title'>{qoo.title}</span>
                  </div>
                );
              })}
            </div>
            <Button
              className="preview-drawer__add-btn"
              type='primary'
              // onClick={() => {addQoosFromAnotherQoolection(qoolection.qoos); setShowPreviewModal(false)}}
                onClick={() => {
                  duplicateQoolection(qoolection)
                  setShowPreviewModal(false);
                }}
            >
              Add Qoolection
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewDrawer;