import React, { useState, useEffect, useContext } from 'react';
import { SERVER_URL } from '../../config';
import useAxios from '../../hooks/useAxios';
import { Layout, Menu } from 'antd';
import { UserContext } from '../../App';
import SideBarInner from '../../components/elements/SideBarInner';
import HeaderComponent from '../../components/base/HeaderComponent';
import { ReactComponent as ReferencesIcon } from '../../icons/references.svg';
import { ReactComponent as FootnotesIcon } from '../../icons/Footnotes.svg';
import { ReactComponent as AbbreviationsIcon } from '../../icons/Abbreviations.svg';
import { ReactComponent as MenuChevron } from '../../icons/library-chevron.svg';
import { ReactComponent as AddButton } from '../../icons/add-button.svg';
import { ReactComponent as MediaIcon } from '../../icons/block-types-icons/image-square-light.svg';
import { ReactComponent as PlayIcon } from '../../icons/ph_play-light.svg';
import { ReactComponent as AudioIcon } from '../../icons/audio.svg';
import { ReactComponent as PdfIcon } from '../../icons/pdf.svg';
import { ReactComponent as QuestionsIcon } from '../../icons/questions-icon.svg';
import { ReactComponent as EProIcon } from '../../icons/epro.svg';
import { ReactComponent as RemindersIcon } from '../../icons/ph_clock-light.svg';
import References from './References';
import Reference from '../../components/endnotes/Reference';
import Footnotes from './Footnotes';
import Footnote from '../../components/endnotes/Footnote';
import Abbreviations from './Abbreviations';
import Abbreviation from '../../components/endnotes/Abbreviation';
import StockImages from './StockImages';
import StockVideos from './StockVideos';
import MyImages from './MyImages';
import MyVideos from './MyVideos';
import MyFiles from './MyFiles';
import MyAudio from './MyAudio';
import Questions from './Questions';
import QuestionsForm from '../../components/forms/QuestionsForm';
import Questioneers from './Questioneers';
import QuestioneersForm from '../../components/forms/QuestioneersForm';
import Reminders from './Reminders';
import RemindersForm from '../../components/forms/RemindersForm';
import MediaDragger from '../../components/forms/MediaDragger';

const { SubMenu } = Menu;

const Library = () => {
  const currentuser = useContext(UserContext);
  const [selectedMenuItem, setSelectedMenuItem] = useState('references');
  const [showAddBtn, setShowAddBtn] = useState('');
  const [showForm, setShowForm] = useState('');
  const [stateForRefetchingReferences, setStateForRefetchingReferences] = useState(1);
  const [stateForRefetchingFootnotes, setStateForRefetchingFootnotes] = useState(1);
  const [stateForRefetchingAbbreviations, setStateForRefetchingAbbreviations] = useState(1);
  const [stateForRefetchingImages, setStateForRefetchingImages] = useState(1);
  const [stateForRefetchingVideoFiles, setStateForRefetchingVideoFiles] = useState(1);
  const [stateForRefetchingFiles, setStateForRefetchingFiles] = useState(1);
  const [stateForRefetchingAudioFiles, setStateForRefetchingAudioFiles] = useState(1);
  const [stateForRefetchingQuestions, setStateForRefetchingQuestions] = useState(1);
  const [stateForRefetchingQuestioneers, setStateForRefetchingQuestioneers] = useState(1);
  const [stateForRefetchingReminders, setStateForRefetchingReminders] = useState(1);
  const [references, fetchReferences] = useAxios(
    '',
    null,
    currentuser.data.token,
    'get',
    stateForRefetchingReferences
  );
  const [footnotes, fetchFootnotes] = useAxios(
    '',
    null,
    currentuser.data.token,
    'get',
    stateForRefetchingFootnotes
  );
  const [abbreviations, fetchAbbreviations] = useAxios(
    '',
    null,
    currentuser.data.token,
    'get',
    stateForRefetchingAbbreviations
  );
  const [images, fetchImages] = useAxios(
    '',
    null,
    currentuser.data.token,
    'get',
    stateForRefetchingImages
  );
  const [videoFiles, fetchVideoFiles] = useAxios(
    '',
    null,
    currentuser.data.token,
    'get',
    stateForRefetchingVideoFiles
  );
  const [files, fetchFiles] = useAxios(
    '',
    null,
    currentuser.data.token,
    'get',
    stateForRefetchingFiles
  );
  const [audioFiles, fetchAudioFiles] = useAxios(
    '',
    null,
    currentuser.data.token,
    'get',
    stateForRefetchingAudioFiles
  );
  const [questions, fetchQuestions] = useAxios(
    '',
    null,
    currentuser.data.token,
    'get',
    stateForRefetchingQuestions
  );
  const [questioneers, fetchQuestioneers] = useAxios(
    [],
    null,
    currentuser.data.token,
    'get',
    stateForRefetchingQuestioneers
  );
  const [reminders, fetchReminders] = useAxios(
    '',
    null,
    currentuser.data.token,
    'get',
    stateForRefetchingReminders
  );

  const [sourceType, setSourceType] = useState();

  useEffect(() => {
    fetchReferences(
      `${SERVER_URL}/references?user=${currentuser.data.id}`,
      null,
      currentuser.data.token,
      'get',
      stateForRefetchingReferences
    );
  }, [fetchReferences, stateForRefetchingReferences, currentuser.data.token]);

  useEffect(() => {
    fetchAbbreviations(
      `${SERVER_URL}/abbreviations?user=${currentuser.data.id}`,
      null,
      currentuser.data.token,
      'get',
      stateForRefetchingAbbreviations
    );
  }, [fetchAbbreviations, stateForRefetchingAbbreviations, currentuser.data.token]);

  useEffect(() => {
    fetchFootnotes(
      `${SERVER_URL}/footnotes?user=${currentuser.data.id}`,
      null,
      currentuser.data.token,
      'get',
      stateForRefetchingFootnotes
    );
  }, [fetchFootnotes, stateForRefetchingFootnotes, currentuser.data.token]);

  useEffect(() => {
    fetchImages(
      `${SERVER_URL}/images?user=${currentuser.data.id}`,
      null,
      currentuser.data.token,
      'get',
      stateForRefetchingImages
    );
  }, [fetchImages, stateForRefetchingImages, currentuser.data.token]);

  useEffect(() => {
    let filter = { type: 'video' };
    fetchVideoFiles(
      `${SERVER_URL}/files?filter=${JSON.stringify(filter)}&user=${currentuser.data.id}`,
      null,
      currentuser.data.token,
      'get',
      stateForRefetchingVideoFiles
    );
  }, [fetchVideoFiles, stateForRefetchingVideoFiles, currentuser.data.token]);

  useEffect(() => {
    let filter = { type: 'common' };
    fetchFiles(
      `${SERVER_URL}/files?filter=${JSON.stringify(filter)}&user=${currentuser.data.id}`,
      null,
      currentuser.data.token,
      'get',
      stateForRefetchingFiles
    );
  }, [fetchFiles, stateForRefetchingFiles, currentuser.data.token]);

  useEffect(() => {
    let filter = { type: 'audio' };
    fetchAudioFiles(
      `${SERVER_URL}/files?filter=${JSON.stringify(filter)}&user=${currentuser.data.id}`,
      null,
      currentuser.data.token,
      'get',
      stateForRefetchingAudioFiles
    );
  }, [fetchAudioFiles, stateForRefetchingAudioFiles, currentuser.data.token]);

  useEffect(() => {
    fetchQuestions(
      `${SERVER_URL}/questions`,
      null,
      currentuser.data.token,
      'get',
      stateForRefetchingQuestions
    );
  }, [fetchQuestions, stateForRefetchingQuestions, currentuser.data.token]);

  useEffect(() => {
    fetchQuestioneers(
      `${SERVER_URL}/questioneers`,
      [],
      currentuser.data.token,
      'get',
      stateForRefetchingQuestioneers
    );
  }, [fetchQuestioneers, stateForRefetchingQuestioneers, currentuser.data.token]);

  useEffect(() => {
    fetchReminders(
      `${SERVER_URL}/reminders`,
      null,
      currentuser.data.token,
      'get',
      stateForRefetchingReminders
    );
  }, [fetchReminders, stateForRefetchingReminders, currentuser.data.token]);

  return (
    <Layout className="manage-layout library">
      <div
        style={{
          textAlign: 'center',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}>
        <HeaderComponent text="Library" />
        <div className="library">
          <SideBarInner width={360}>
            <Menu
              mode="inline"
              expandIcon={<MenuChevron />}
              className="library__menu"
              defaultopenkeys={['submenu-1']}>
              <SubMenu
                title="Endnotes"
                className="library__submenu"
                key="submenu-1"
                defaultopenkeys={['1']}>
                <Menu.Item
                  icon={
                    <div className="library__menu-item-icon">
                      <ReferencesIcon />
                    </div>
                  }
                  className="library__menu-item"
                  onClick={() => {
                    setSelectedMenuItem('references');
                    showForm === 'referencesForm' && setShowForm('');
                  }}
                  onMouseEnter={() => setShowAddBtn('referencesBtn')}
                  onMouseLeave={() => setShowAddBtn('')}
                  key="1">
                  References
                  {showAddBtn === 'referencesBtn' && (
                    <AddButton onClick={() => setShowForm('referencesForm')} />
                  )}
                </Menu.Item>
                {showForm === 'referencesForm' && (
                  <div style={{ padding: '10px 15px' }}>
                    <Reference
                      token={currentuser.data.token}
                      source={sourceType}
                      setSource={setSourceType}
                      setShowForm={setShowForm}
                      setStateForRefetchingReferences={setStateForRefetchingReferences}
                      library={true}
                    />
                  </div>
                )}
                <Menu.Item
                  icon={
                    <div className="library__menu-item-icon">
                      <FootnotesIcon />
                    </div>
                  }
                  className="library__menu-item"
                  onClick={() => {
                    setSelectedMenuItem('footnotes');
                    showForm === 'footnotesForm' && setShowForm('');
                  }}
                  onMouseEnter={() => setShowAddBtn('footnotesBtn')}
                  onMouseLeave={() => setShowAddBtn('')}
                  key="2">
                  Footnotes
                  {showAddBtn === 'footnotesBtn' && (
                    <AddButton onClick={() => setShowForm('footnotesForm')} />
                  )}
                </Menu.Item>
                {showForm === 'footnotesForm' && (
                  <Footnote
                    token={currentuser.data.token}
                    library={true}
                    setStateForRefetchingFootnotes={setStateForRefetchingFootnotes}
                  />
                )}
                <Menu.Item
                  icon={
                    <div className="library__menu-item-icon">
                      <AbbreviationsIcon />
                    </div>
                  }
                  className="library__menu-item"
                  onClick={() => {
                    setSelectedMenuItem('abbreviations');
                    showForm === 'abbreviationsForm' && setShowForm('');
                  }}
                  onMouseEnter={() => setShowAddBtn('abbreviationsBtn')}
                  onMouseLeave={() => setShowAddBtn('')}
                  key="3">
                  Abbreviations
                  {showAddBtn === 'abbreviationsBtn' && (
                    <AddButton onClick={() => setShowForm('abbreviationsForm')} />
                  )}
                </Menu.Item>
                {showForm === 'abbreviationsForm' && (
                  <Abbreviation
                    token={currentuser.data.token}
                    library={true}
                    setStateForRefetchingAbbreviations={setStateForRefetchingAbbreviations}
                  />
                )}
              </SubMenu>
              <SubMenu title="My Media" className="library__submenu" key="submenu-2">
                <Menu.Item
                  icon={
                    <div className="library__menu-item-icon">
                      <MediaIcon />
                    </div>
                  }
                  className="library__menu-item"
                  onClick={() => {
                    setSelectedMenuItem('myImages');
                    showForm === 'myImagesForm' && setShowForm('');
                  }}
                  onMouseEnter={() => setShowAddBtn('myImagesBtn')}
                  onMouseLeave={() => setShowAddBtn('')}
                  key="4">
                  Images
                  {showAddBtn === 'myImagesBtn' && (
                    <AddButton onClick={() => setShowForm('myImagesForm')} />
                  )}
                </Menu.Item>
                {showForm === 'myImagesForm' && (
                  <div className="media-dragger">
                    <MediaDragger
                      setStateForRefetchingMedia={setStateForRefetchingImages}
                      config={{
                        cardTitle: 'Add Images',
                        uploadRoute: 'upload-image',
                        accept: '.jpg,.jpeg,.png,.svg',
                        name: 'image',
                        showUploadList: true,
                        headers: {
                          type: 'common',
                          resize: true,
                          resizemob: false,
                          Authorization: `Bearer ${currentuser.data.token}`,
                          uri: 'public/images/'
                        },
                        allowedFormats: ['image/jpeg', 'image/png'],
                        formatErrorMessage: 'You can only upload JPG or PNG images!',
                        maxAllowedSize: 5,
                        sizeErrorMessage: 'Image must smaller than 5MB!',
                        browseLabel: 'Images'
                      }}
                    />
                  </div>
                )}
                <Menu.Item
                  icon={
                    <div className="library__menu-item-icon">
                      <PlayIcon />
                    </div>
                  }
                  className="library__menu-item"
                  onClick={() => {
                    setSelectedMenuItem('myVideos');
                    showForm === 'myVideosForm' && setShowForm('');
                  }}
                  onMouseEnter={() => setShowAddBtn('myVideosBtn')}
                  onMouseLeave={() => setShowAddBtn('')}
                  key="5">
                  Videos
                  {showAddBtn === 'myVideosBtn' && (
                    <AddButton onClick={() => setShowForm('myVideosForm')} />
                  )}
                </Menu.Item>
                {showForm === 'myVideosForm' && (
                  <div className="media-dragger">
                    <MediaDragger
                      setStateForRefetchingMedia={setStateForRefetchingVideoFiles}
                      config={{
                        cardTitle: 'Add Videos',
                        uploadRoute: 'upload-video',
                        accept: '.mp4',
                        name: 'video',
                        showUploadList: true,
                        headers: {
                          type: 'video',
                          resize: true,
                          resizemob: false,
                          Authorization: `Bearer ${currentuser.data.token}`,
                          uri: 'public/videos/'
                        },
                        allowedFormats: ['video/mp4'],
                        formatErrorMessage: 'You can only upload MP4 videos!',
                        maxAllowedSize: 50,
                        sizeErrorMessage: 'Video must be smaller than 50MB!',
                        browseLabel: 'Videos'
                      }}
                    />
                  </div>
                )}
                <Menu.Item
                  icon={
                    <div className="library__menu-item-icon">
                      <AudioIcon />
                    </div>
                  }
                  className="library__menu-item"
                  onClick={() => {
                    setSelectedMenuItem('myAudio');
                    showForm === 'myAudioForm' && setShowForm('');
                  }}
                  onMouseEnter={() => setShowAddBtn('myAudioBtn')}
                  onMouseLeave={() => setShowAddBtn('')}
                  key="6">
                  Audio
                  {showAddBtn === 'myAudioBtn' && (
                    <AddButton onClick={() => setShowForm('myAudioForm')} />
                  )}
                </Menu.Item>
                {showForm === 'myAudioForm' && (
                  <div className="media-dragger">
                    <MediaDragger
                      setStateForRefetchingMedia={setStateForRefetchingAudioFiles}
                      config={{
                        cardTitle: 'Add Audio',
                        uploadRoute: 'upload-audio',
                        accept: '.mp3, .wav',
                        name: 'audio',
                        showUploadList: true,
                        headers: {
                          type: 'audio',
                          Authorization: `Bearer ${currentuser.data.token}`,
                          uri: 'public/files/'
                        },
                        allowedFormats: ['audio/mpeg', 'audio/x-wav'],
                        formatErrorMessage: 'You can only upload MP3 or WAV audio files!',
                        maxAllowedSize: 5,
                        sizeErrorMessage: 'Audio file must be smaller than 5MB!',
                        browseLabel: 'Audio'
                      }}
                    />
                  </div>
                )}
                <Menu.Item
                  icon={
                    <div className="library__menu-item-icon">
                      <PdfIcon />
                    </div>
                  }
                  className="library__menu-item"
                  onClick={() => {
                    setSelectedMenuItem('myFiles');
                    showForm === 'myPdfsForm' && setShowForm('');
                  }}
                  onMouseEnter={() => setShowAddBtn('myPdfsBtn')}
                  onMouseLeave={() => setShowAddBtn('')}
                  key="7">
                  PDF
                  {showAddBtn === 'myPdfsBtn' && (
                    <AddButton onClick={() => setShowForm('myPdfsForm')} />
                  )}
                </Menu.Item>
                {showForm === 'myPdfsForm' && (
                  <div className="media-dragger">
                    <MediaDragger
                      setStateForRefetchingMedia={setStateForRefetchingFiles}
                      config={{
                        cardTitle: 'Add PDF',
                        uploadRoute: 'upload-file',
                        accept: '.pdf',
                        name: 'file',
                        showUploadList: true,
                        headers: {
                          type: 'common',
                          Authorization: `Bearer ${currentuser.data.token}`,
                          uri: 'public/files/'
                        },
                        allowedFormats: ['application/pdf'],
                        formatErrorMessage: 'You can only upload PDF files!',
                        maxAllowedSize: 5,
                        sizeErrorMessage: 'PDF file must be smaller than 5MB!',
                        browseLabel: 'PDFs'
                      }}
                    />
                  </div>
                )}
              </SubMenu>
              <SubMenu title="Stock Media" className="library__submenu" key="submenu-3">
                <Menu.Item
                  icon={
                    <div className="library__menu-item-icon">
                      <MediaIcon />
                    </div>
                  }
                  className="library__menu-item"
                  onClick={() => setSelectedMenuItem('stockImages')}
                  key="8">
                  Images
                </Menu.Item>
                <Menu.Item
                  icon={
                    <div className="library__menu-item-icon">
                      <PlayIcon />
                    </div>
                  }
                  className="library__menu-item"
                  onClick={() => setSelectedMenuItem('stockVideos')}
                  key="9">
                  Videos
                </Menu.Item>
              </SubMenu>
              <SubMenu title="Events" className="library__submenu" key="submenu-4">
                <Menu.Item
                  icon={
                    <div className="library__menu-item-icon">
                      <EProIcon />
                    </div>
                  }
                  className="library__menu-item"
                  onClick={() => {
                    setSelectedMenuItem('questioneers');
                    showForm === 'questioneersForm' && setShowForm('');
                  }}
                  onMouseEnter={() => setShowAddBtn('questioneersBtn')}
                  onMouseLeave={() => setShowAddBtn('')}
                  key="10">
                  ePRO
                  {showAddBtn === 'questioneersBtn' && (
                    <AddButton onClick={() => setShowForm('questioneersForm')} />
                  )}
                </Menu.Item>
                {showForm === 'questioneersForm' && (
                  <div>
                    <QuestioneersForm
                      isNew={true}
                      setStateForRefetchingQuestioneers={setStateForRefetchingQuestioneers}
                    />
                  </div>
                )}
                <Menu.Item
                  icon={
                    <div className="library__menu-item-icon">
                      <QuestionsIcon />
                    </div>
                  }
                  className="library__menu-item"
                  onClick={() => {
                    setSelectedMenuItem('questions');
                    showForm === 'questionsForm' && setShowForm('');
                  }}
                  onMouseEnter={() => setShowAddBtn('questionsBtn')}
                  onMouseLeave={() => setShowAddBtn('')}
                  key="11">
                  Questions
                  {showAddBtn === 'questionsBtn' && (
                    <AddButton onClick={() => setShowForm('questionsForm')} />
                  )}
                </Menu.Item>
                {showForm === 'questionsForm' && questioneers?.data?.items && (
                  <div>
                    <QuestionsForm
                      isNew={true}
                      questioneers={questioneers?.data?.items}
                      setStateForRefetchingQuestions={setStateForRefetchingQuestions}
                    />
                  </div>
                )}
                <Menu.Item
                  icon={
                    <div className="library__menu-item-icon">
                      <RemindersIcon />
                    </div>
                  }
                  className="library__menu-item"
                  onClick={() => {
                    setSelectedMenuItem('reminders');
                    showForm === 'remindersForm' && setShowForm('');
                  }}
                  onMouseEnter={() => setShowAddBtn('remindersBtn')}
                  onMouseLeave={() => setShowAddBtn('')}
                  key="12">
                  Reminders
                  {showAddBtn === 'remindersBtn' && (
                    <AddButton onClick={() => setShowForm('remindersForm')} />
                  )}
                </Menu.Item>
                {showForm === 'remindersForm' && (
                  <div>
                    <RemindersForm
                      setStateForRefetchingReminders={setStateForRefetchingReminders}
                      isNew
                    />
                  </div>
                )}
              </SubMenu>
            </Menu>
          </SideBarInner>
          <div className="content-wrapper" style={{ width: '100%' }}>
            <div className="content">
              {selectedMenuItem === 'references' && (
                <References
                  references={references}
                  setStateForRefetchingReferences={setStateForRefetchingReferences}
                />
              )}
              {selectedMenuItem === 'footnotes' && (
                <Footnotes
                  footnotes={footnotes}
                  setStateForRefetchingFootnotes={setStateForRefetchingFootnotes}
                />
              )}
              {selectedMenuItem === 'abbreviations' && (
                <Abbreviations
                  abbreviations={abbreviations}
                  setStateForRefetchingAbbreviations={setStateForRefetchingAbbreviations}
                />
              )}
              {selectedMenuItem === 'stockImages' && <StockImages />}
              {selectedMenuItem === 'stockVideos' && <StockVideos />}
              {selectedMenuItem === 'myImages' && (
                <MyImages
                  images={images}
                  user={currentuser}
                  setStateForRefetchingImages={setStateForRefetchingImages}
                />
              )}
              {selectedMenuItem === 'myVideos' && (
                <MyVideos
                  videos={videoFiles}
                  user={currentuser}
                  setStateForRefetchingVideoFiles={setStateForRefetchingVideoFiles}
                />
              )}
              {selectedMenuItem === 'myFiles' && (
                <MyFiles
                  files={files}
                  stateForRefetchingFiles={stateForRefetchingFiles}
                  setStateForRefetchingFiles={setStateForRefetchingFiles}
                />
              )}
              {selectedMenuItem === 'myAudio' && (
                <MyAudio
                  files={audioFiles}
                  setStateForRefetchingAudioFiles={setStateForRefetchingAudioFiles}
                />
              )}
              {selectedMenuItem === 'questions' && questioneers && (
                <Questions
                  questions={questions}
                  questioneers={questioneers?.data?.items}
                  setStateForRefetchingQuestions={setStateForRefetchingQuestions}
                />
              )}
              {selectedMenuItem === 'questioneers' && (
                <Questioneers
                  questioneers={questioneers}
                  setStateForRefetchingQuestioneers={setStateForRefetchingQuestioneers}
                />
              )}
              {selectedMenuItem === 'reminders' && (
                <Reminders
                  reminders={reminders}
                  setStateForRefetchingReminders={setStateForRefetchingReminders}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Library;
